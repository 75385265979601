import api from 'common/api';
import logdown from 'logdown';

import { onlyNumbers } from 'common/utils/string';

const logger = logdown('VALIDATE');

const objectError = {
  postal_code: 'CEP não encontrado.',
  _error: 'cep-invalid',
};

const asyncValidateCEP = async (values, dispatch, props) => {
  const {
    postal_code: value,
  } = values;

  const {
    profile: {
      postal_code: actualValue,
    },
    change,
  } = props;

  const onlyValue = onlyNumbers(value);

  if (onlyValue.length !== 8
      || onlyValue === actualValue) return Promise.resolve();

  try {
    const response = await api.cep.getValidateCep(onlyValue);
    const { data } = response;

    if (data.erro) {
      throw objectError;
    }

    const map = {
      logradouro: 'address',
      bairro: 'neighborhood',
      numero: 'number',
      complemento: 'complement',
      localidade: 'city',
      uf: 'state',
    };

    Object.keys(map).forEach((key) => {
      if (!data[key]) {
        dispatch(change(map[key], ''));
        return;
      }

      dispatch(change(map[key], data[key]));
    });
  } catch (error) {
    logger.error('Failed check cep.', error);
    throw objectError;
  }

  return Promise.resolve();
};

const shouldAsyncValidate = (params) => {
  const { syncValidationPasses, trigger } = params;

  if (!syncValidationPasses) {
    return false;
  }

  switch (trigger) {
    case 'blur':
      return true;
    case 'submit':
      return false;
    default:
      return false;
  }
};

export { shouldAsyncValidate, asyncValidateCEP };
