import { withStyles } from '@material-ui/core';
import palette from 'common/palette';
import InvestTransaction from './InvestTransaction';

const styles = {
  title: {
    marginBottom: 15,
  },
  investment: {
    color: palette.primary.main,
  },
  withdrawal: {
    color: palette.secondary.main,
  },
  text: {
    fontSize: 14,
  },
  button: {
    textTransform: 'uppercase',
  },
};

export default withStyles(styles)(InvestTransaction);
