import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { Typography, Card } from '@material-ui/core';

import Link from 'common/components/Link';

const AuthorCard = ({ author, classes }) => (
  <div className={classes.itemCard}>
    <Typography variant="h3" className={classes.cardTitle}>
      Sobre o autor
    </Typography>
    <Card className={classes.cardSpacing} id="authorcard">
      { author.title
        && (
        <Typography variant="h3" className={classNames(classes.authorTitle, classes.cardTitle)}>
          {author.title}
        </Typography>
        )}

      { author.about
        && author.about.split('\n').map((text) => (
          <Typography paragraph key={text} className={classNames('cardText', classes.cardText)}>{text}</Typography>
        ))}

      { author.name
        && (
        <Typography variant="h6" paragraph className={classes.authorInfo}>
          <span>Analista: </span>
          <b>{author.name}</b>
        </Typography>
        )}
      { author.certification
        && (
        <Typography variant="h6" paragraph className={classes.authorInfo}>
          <span>Certificação: </span>
          <b>{author.certification}</b>
        </Typography>
        )}
      { author.url
        && (
        <Typography variant="h6" paragraph className={classes.authorInfo}>
          <span>Site: </span>
          <Link
            path={`http://${author.url}`}
            target="_blank"
            underlineDecoration
            fontWeight={600}
          >
            {author.url}
          </Link>
        </Typography>
        )}
    </Card>
  </div>
);

AuthorCard.propTypes = {
  author: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
};

export default AuthorCard;
