import logdown from 'logdown';

import api from 'common/api';
import { RUNNING_STATES } from 'common/utils/constants';
import { getInstanceByStrategyIdAndState } from './instance';

export const CARDS_VIEW_TEXT = 'cards';
export const ROWS_VIEW_TEXT = 'rows';
export const MAP_SIMULATION_TYPE = {
  1: 'Pessimista',
  0: 'Otimista',
};

const logger = logdown('reloadInstancePositionsAndPendingOrders');

export const getRunningInstances = (instances) => {
  return instances.filter((instance) => RUNNING_STATES.includes(instance?.state));
};

export const isFollowingSomeInstance = (instances) => {
  if (instances.length === 0) return false;

  return instances.some((inst) => RUNNING_STATES.includes(inst.state));
};

export const isFollowingInstance = ({ instances, strategyId }) => {
  const instance = getInstanceByStrategyIdAndState({ instances, strategyId });

  return RUNNING_STATES.includes(instance?.state);
};

export const reloadInstancePositionsAndPendingOrders = async (
  id,
  reloadInstancePositions,
) => {
  let positions = null;
  try {
    const response = await reloadInstancePositions();
    positions = response.data;
    logger.log(`Instance ${id} positions loaded`, response, positions);
  } catch (error) {
    logger.error(`Could not reload instance ${id} positions.`, error);
  }

  let pendingOrders = null;
  try {
    const response = await api.strategies.instances.orders.getOrders(id, {
      params: { only_pending: 1 },
    });
    pendingOrders = response.data.orders;
    logger.log(`Instance ${id} pending orders loaded`, response, pendingOrders);
  } catch (error) {
    logger.error(`Could not reload instance ${id} pending orders.`, error);
  }

  return { positions, pendingOrders };
};

export const isStoreInstanceFollowed = ({ instances, storeInstance }) => (
  Object.values(instances).some((instance) => {
    const resultInstance = instance.strategy_id === storeInstance.strategy_id ? instance : [];

    return RUNNING_STATES.includes(resultInstance.state);
  })
);

export const isPerformanceDone = (itemType) => {
  const { request } = itemType;

  return request && (Object.keys(request).length === 0 || (request && request.loading === true));
};

export default null;
