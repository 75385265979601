import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Field, FieldArray } from 'formik';
import { remove } from 'lodash';

import {
  IconButton,
  Box,
  InputAdornment,
  Paper,
  Grow,
  MenuItem,
  Select,
  InputLabel,
  FormControl,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';

import FontAwesomeIcon from '@fortawesome/react-fontawesome';
import faPlusCircle from '@fortawesome/fontawesome-free-solid/faPlusCircle';

import { ERROR } from 'common/utils/constants';
import {
  MAX_POSITIONS_FOLLOW_INSTANCE,
  POSITION_PROPS_INITIAL_VALUES,
} from 'common/utils/constants/positions';
import { getFadeTimeoutByIndex, concatenateListsWithoutRepetition } from 'common/utils/functions';
import { isWhiteboxInstance } from 'common/utils/instance';
import {
  getFormattedValue,
  orderFormatters,
  validatePrice,
  isInsertionMode,
} from 'common/utils/orders';
import {
  getListStocksWithStartString,
  getPositionIndex,
  updatePositionStock,
  getStockError,
  getStockTouched,
  renderFieldsPositionOrder,
} from 'common/utils/positions';
import { validateDateBefore } from 'common/utils/validation';

import ButtonDialog from 'common/components/Buttons/ButtonDialog';
import { InputTextField } from 'common/components/ReduxForm';
import TitleTooltip from 'common/components/TitleTooltip';

const FillPositionsForm = ({
  classes,
  values,
  errors,
  status,
  handleSubmit,
  handleChange,
  resetForm,
  notifyError,
  setStatus,
  setFieldValue,
  positionBody,
  checkValidateStockCode,
  validStocks,
  storeItemStocks,
  instancePositions,
  strategyId,
  variant,
  isExternalOrder,
}) => {
  const isInsertion = isInsertionMode(variant);
  const handleBlurPosition = async ({ index }) => {
    const { positions } = values;
    const itemPosition = positions[index];
    const stock = itemPosition.stock.toUpperCase();

    if (stock === '') return false;

    const newStock = isInsertion
      ? stock.replace(/F$/, '')
      : stock;
    const isPositionDuplicated = getListStocksWithStartString({
      stock: newStock,
      positions,
    }).length > 1;

    if (isPositionDuplicated) {
      const nextInitialState = remove(positions, (_, curIndex) => {
        return curIndex !== index;
      });
      notifyError({
        title: ERROR,
        message: 'Este ativo já foi adicionado',
      });
      return resetForm({ positions: nextInitialState });
    }

    const finalPosition = {
      ...itemPosition,
      stock: newStock,
    };

    const indexCurrentStock = getPositionIndex({
      positions,
      stock,
    });

    const updatedPositions = updatePositionStock({
      finalPosition,
      positions,
      indexCurrentStock,
    });

    resetForm({ positions: updatedPositions });

    if (!validStocks[newStock]) {
      const { stockCodeError } = await checkValidateStockCode(newStock);

      if (stockCodeError) {
        const oldStatus = status || { cachedStocks: [] };

        return setStatus({
          ...oldStatus,
          errors: {
            ...oldStatus.errors,
            [newStock]: stockCodeError,
          },
        });
      }
    }

    return setStatus({
      ...status,
    });
  };

  const onChangeField = (e, name) => {
    e.target.value = getFormattedValue(e.target.value, name);

    handleChange(e);
  };

  const onChangeSelect = (event, index, type) => {
    setFieldValue(`positions[${index}].${type}`, event.target.value);
  };

  const isCustomInstance = isWhiteboxInstance(strategyId);

  const getFinalStockList = (position) => {
    const storeItemStocksList = Object.keys(storeItemStocks);
    const instancePositionsList = Object.keys(instancePositions);

    return instancePositionsList.length && position?.orderType === '1'
      ? concatenateListsWithoutRepetition(storeItemStocksList, instancePositionsList)
      : storeItemStocksList;
  };

  return (
    <form onSubmit={handleSubmit}>
      <FieldArray
        name="positions"
        render={(arrayPositions) => (
          <>
            <div className={classes.wrapper}>
              {values.positions.map((position, index) => {
                const hasOrderType = Object.keys(position).includes('orderType');
                const fieldsPositionOrder = renderFieldsPositionOrder(
                  hasOrderType, isExternalOrder,
                );
                return (
                  <Grow
                    key={index} //eslint-disable-line
                    in
                    style={{ transformOrigin: '50% 100%' }}
                    timeout={{
                      enter: getFadeTimeoutByIndex({
                        index,
                        divisor: 1,
                        minMillisecond: 100,
                      }),
                    }}
                  >
                    <Paper id="position-card" className={classes.paper} elevation={4}>
                      <Box
                        spacing={16}
                        data-testid="position-item"
                        className={classes.positionBox}
                      >
                        {hasOrderType && (
                        <Field
                          as="select"
                          render={({ form: { touched } }) => {
                            return (
                              <FormControl className={classNames(
                                classes.formControl,
                                classes.input,
                                classes.inputColor,
                              )}
                              >
                                <InputLabel
                                  className={classNames(
                                    classes.label,
                                    classes.inputLabel,
                                  )}
                                  shrink
                                >
                                  Compra/Venda
                                </InputLabel>
                                <Select
                                  className={classNames(
                                    classes.input,
                                    classes.selectInput,
                                  )}
                                  value={position.orderType}
                                  meta={{
                                    error: getStockError({
                                      errors, status, index, position,
                                    }),
                                    touched: getStockTouched({
                                      touched, status, index, position,
                                    }),
                                  }}
                                  onChange={(event) => onChangeSelect(
                                    event, index, 'orderType',
                                  )}
                                >
                                  <MenuItem value="0">Compra</MenuItem>
                                  <MenuItem value="1">Venda</MenuItem>
                                </Select>
                              </FormControl>
                            );
                          }}
                          name={`positions.${index}.orderType`}
                        />
                        )}

                        {isCustomInstance && (
                        <Field
                          render={({ field, form: { touched } }) => {
                            return (
                              <InputTextField
                                {...field}
                                autoFocus
                                className={classNames(
                                  classes.input,
                                  classes.inputColor,
                                )}
                                inputProps={{
                                  className: classes.label,
                                  maxLength: 7,
                                }}
                                InputLabelProps={{ className: classes.label }}
                                label={(
                                  <TitleTooltip
                                    label={fieldsPositionOrder.stock.label}
                                    tooltip={{
                                      text: fieldsPositionOrder.stock.tooltip,
                                    }}
                                    variant="h5"
                                  />
                          )}
                                meta={{
                                  error: getStockError({
                                    errors, status, index, position,
                                  }),
                                  touched: getStockTouched({
                                    touched, status, index, position,
                                  }),
                                }}
                                onBlur={() => handleBlurPosition({ index })}
                              />
                            );
                          }}
                          name={`positions.${index}.stock`}
                        />
                        ) }

                        {!isCustomInstance
                        && (
                        <Field
                          as="select"
                          render={({ form: { touched } }) => {
                            return (
                              <FormControl className={classNames(
                                classes.formControl,
                                classes.input,
                                classes.inputColor,
                              )}
                              >
                                <InputLabel
                                  className={classes.label}
                                  shrink
                                >
                                  <TitleTooltip
                                    label={fieldsPositionOrder.stock.label}
                                    tooltip={{
                                      text: fieldsPositionOrder.stock.tooltip,
                                    }}
                                    variant="h5"
                                  />
                                </InputLabel>
                                <Select
                                  className={classNames(
                                    classes.input,
                                    classes.selectInput,
                                  )}
                                  value={position.stock}
                                  meta={{
                                    error: getStockError({
                                      errors, status, index, position,
                                    }),
                                    touched: getStockTouched({
                                      touched, status, index, position,
                                    }),
                                  }}
                                  onChange={(event) => onChangeSelect(
                                    event, index, 'stock',
                                  )}
                                  onBlur={() => handleBlurPosition({ index })}
                                >
                                  {getFinalStockList(position).map((item) => (
                                    <MenuItem
                                      key={item}
                                      value={item}
                                    >
                                      {item}
                                    </MenuItem>
                                  ))}
                                </Select>
                              </FormControl>
                            );
                          }}
                          name={`positions.${index}.stock`}
                        />
                        )}

                        <Field
                          render={({ field, form: { touched } }) => {
                            return (
                              <InputTextField
                                {...field}
                                className={classNames(
                                  classes.input,
                                  classes.inputColor,
                                )}
                                InputProps={{ className: classes.label }}
                                InputLabelProps={{ className: classes.label }}
                                label={(
                                  <TitleTooltip
                                    label={fieldsPositionOrder.number_of_stocks.label}
                                    tooltip={{
                                      text: fieldsPositionOrder.number_of_stocks.tooltip,
                                    }}
                                    variant="h5"
                                  />
                          )}
                                meta={{
                                  error: errors.positions
                                    ? errors.positions[index]?.number_of_stocks
                                    : false,
                                  touched: touched.positions
                                    ? touched?.positions[index]?.number_of_stocks
                                    : false,
                                }}
                                value={orderFormatters.number_of_stocks(position.number_of_stocks || '')}
                                onChange={(e) => onChangeField(e, 'number_of_stocks')}
                              />
                            );
                          }}
                          name={`positions.${index}.number_of_stocks`}
                        />

                        {isExternalOrder
                          && (
                          <>
                            <Field
                              render={({ field, form: { touched } }) => {
                                return (
                                  <InputTextField
                                    {...field}
                                    className={classNames(
                                      classes.input,
                                      classes.inputColor,
                                    )}
                                    InputProps={{
                                      className: classes.label,
                                      classes: {
                                        root: classes.rootAdornment,
                                      },
                                      startAdornment: (
                                        <InputAdornment position="start">R$</InputAdornment>
                                      ),
                                    }}
                                    InputLabelProps={{
                                      className: classNames(
                                        classes.label,
                                        classes.labelTooltipFixed,
                                      ),
                                    }}
                                    label={(
                                      <TitleTooltip
                                        label={fieldsPositionOrder.price.label}
                                        tooltip={{
                                          text: fieldsPositionOrder.price.tooltip,
                                        }}
                                        variant="h5"
                                      />
                                    )}
                                    meta={{
                                      error: errors.positions
                                        ? errors.positions[index]?.price
                                        : false,
                                      touched: touched.positions
                                        ? touched?.positions[index]?.price
                                        : false,
                                    }}
                                    value={orderFormatters.price(position.price || '')}
                                    onChange={(e) => onChangeField(e, 'price')}
                                  />
                                );
                              }}
                              name={`positions.${index}.price`}
                              validate={() => validatePrice(position.price)}
                            />

                            <Field
                              render={({ field, form: { touched } }) => {
                                return (
                                  <InputTextField
                                    {...field}
                                    className={classes.input}
                                    InputProps={{ className: classes.label }}
                                    InputLabelProps={{ className: classes.label }}
                                    label={(
                                      <TitleTooltip
                                        label={fieldsPositionOrder.date.label}
                                        tooltip={{
                                          text: fieldsPositionOrder.date.tooltip,
                                        }}
                                        variant="h5"
                                      />
                                    )}
                                    meta={{
                                      error: errors.positions
                                        ? errors.positions[index]?.date
                                        : false,
                                      touched: touched.positions
                                        ? touched?.positions[index]?.date
                                        : false,
                                    }}
                                    onChange={(e) => onChangeField(e, 'date')}
                                  />
                                );
                              }}
                              name={`positions.${index}.date`}
                              validate={() => validateDateBefore()(position.date)}
                            />
                          </>
                          )}

                        {values.positions.length > 1 && (
                        <IconButton
                          data-testid="delete-position"
                          size="small"
                          className={classes.closeIcon}
                          edge="start"
                          color="inherit"
                          onClick={() => arrayPositions.remove(index)}
                          aria-label="Excluir ativo"
                        >
                          <CloseIcon />
                        </IconButton>
                        )}
                      </Box>
                    </Paper>
                  </Grow>
                );
              })}
            </div>

            {isInsertion && (
            <Box data-testid="add-position-box" position="relative" mt={2.5}>
              <ButtonDialog
                disabled={values.positions.length
                  === MAX_POSITIONS_FOLLOW_INSTANCE}
                align="left"
                weight="bold"
                showBorders
                onClick={() => {
                  arrayPositions.push(positionBody);
                }}
              >
                <FontAwesomeIcon
                  className={classes.addCostIcon}
                  icon={faPlusCircle}
                  size="lg"
                />
                Adicionar novo ativo
              </ButtonDialog>
            </Box>
            )}
          </>
        )}
      />
    </form>
  );
};

FillPositionsForm.propTypes = {
  classes: PropTypes.object.isRequired,
  values: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
  resetForm: PropTypes.func.isRequired,
  notifyError: PropTypes.func.isRequired,
  setStatus: PropTypes.func.isRequired,
  setFieldValue: PropTypes.func.isRequired,
  status: PropTypes.object,
  checkValidateStockCode: PropTypes.func.isRequired,
  validStocks: PropTypes.object.isRequired,
  positionBody: PropTypes.object,
  variant: PropTypes.string.isRequired,
  storeItemStocks: PropTypes.object.isRequired,
  instancePositions: PropTypes.object.isRequired,
  strategyId: PropTypes.number.isRequired,
  isExternalOrder: PropTypes.bool.isRequired,
};

FillPositionsForm.defaultProps = {
  status: {
    cachedStocks: [],
    errors: {},
    lotSizes: {},
  },
  positionBody: POSITION_PROPS_INITIAL_VALUES,
};

export default FillPositionsForm;
