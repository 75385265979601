import React from 'react';
import PropTypes from 'prop-types';

import difference from 'lodash/difference';
import size from 'lodash/size';

import axios from 'axios';
import moment from 'moment';

import {
  getMDSStreamerConnection,
  resetStreamerCredentials,
} from 'common/utils/streamer';

import DisplayNumber from 'common/components/DisplayNumber';
import { Box, Typography } from '@material-ui/core';
import palette from 'common/palette';
import { ERROR_GET_LAST_TRADE_STOCK_MSG } from 'common/utils/constants';
import { getLatestUpdatedPriceDate } from 'common/utils/date';
import Placeholder from '../Placeholder';

const { CancelToken } = axios;

class PositionResult extends React.Component {
  state = {
    requestSource: CancelToken.source(),
    lastUpdatedAt: moment().toISOString(),
    streamer: {
      socket: null,
      stockCodes: [],
    },
    position: {},
    loading: false,
    error: null,
  };

  componentDidMount = async () => {
    const { position } = this.props;
    const { onChangePositionPrice } = this.props;

    this.setState({ position });
    onChangePositionPrice({ value: position.price });

    await this.handleStreamerConnection();
  }

  componentDidUpdate = async (prevProps, prevState) => {
    if (difference(
      prevState.streamer.stockCodes,
      this.state.streamer.stockCodes,
    ).length || (
      size(prevState.position) !== size(this.state.position)
    )
    ) {
      await this.handleStreamerConnection();
    }
  }

  componentWillUnmount() {
    const { streamer, requestSource } = this.state;

    requestSource.cancel('Cancel stock');

    if (streamer.socket) {
      streamer.socket.close();
      resetStreamerCredentials();
    }
  }

  handleStreamerConnection = async () => {
    const { loading, streamer } = this.state;

    if (loading) {
      return;
    }

    if (streamer.socket) {
      streamer.socket.close();
    }

    if (!streamer.stockCodes.length) {
      return;
    }

    this.setState({ loading: true });
    try {
      const result = await getMDSStreamerConnection(
        streamer.stockCodes,
        this.handleMarketDataEvent,
      );

      this.setState({
        streamer: {
          ...streamer,
          socket: result.socket,
        },
        loading: false,
      });
    } catch (error) {
      this.props.onChangePositionPrice({ error: true });
      this.setState({
        streamer: {
          ...streamer,
          socket: null,
        },
        loading: false,
        error: true,
      });
    }
  }

  handleMarketDataEvent = (event) => {
    const data = JSON.parse(event.data);
    const { price } = data;
    const { position } = this.state;
    const { onChangePositionPrice } = this.props;

    if ((data.type === 0 || data.type === 1) && size(position)) {
      if (!moment()
        .isBefore(moment(this.state.lastUpdatedAt).add(1, 'seconds'))
      ) {
        onChangePositionPrice({ value: price, error: false });
        this.setState({
          lastUpdatedAt: moment().toISOString(),
          position: {
            ...position,
            price,
          },
        });
      }
    }
  }

  render = () => {
    const { position, loading, error } = this.state;

    const currentTimeText = getLatestUpdatedPriceDate();

    return (
      <>
        {error ? (
          <Typography variant="h6" component="div">
            <Box fontWeight="bold" color={palette.secondary.main}>
              {ERROR_GET_LAST_TRADE_STOCK_MSG}
            </Box>
          </Typography>
        ) : (
          <>
            { (loading || !position.price) && (
            <Box maxWidth={100}>
              <Placeholder height={48} />
            </Box>
            )}

            {!loading && position.price && (
              <>
                <Box mb={1} fontWeight="bold">
                  <DisplayNumber
                    currency
                    colorful={false}
                    color="textSecondaryBold"
                    value={Number.parseFloat(position.price)}
                    scale={1.25}
                    symbolScale={1}
                    valueScale={1}
                  />
                </Box>
                <Typography component="div" color="textSecondary">
                  <Box fontWeight={400} fontSize={11}>
                    {currentTimeText}
                  </Box>
                </Typography>
              </>
            )}
          </>
        )}
      </>
    );
  };
}

PositionResult.getDerivedStateFromProps = (
  { position },
  prevState,
) => {
  const stockCode = position.code || position.stock_code;

  return {
    streamer: {
      ...prevState.streamer,
      stockCodes: stockCode ? [stockCode] : [],
    },
  };
};

PositionResult.propTypes = {
  id: PropTypes.number.isRequired,
  position: PropTypes.object.isRequired,
  onChangePositionPrice: PropTypes.func,
};

PositionResult.defaultProps = {
  onChangePositionPrice: () => {},
};

export default PositionResult;
