import React from 'react';
import PropTypes from 'prop-types';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';

const CheckboxField = ({
  label,
  value,
  handleChange,
  name,
  classes,
}) => (
  <FormControlLabel
    classes={{
      root: classes.labelRoot,
      label: classes.labelText,
    }}
    control={(
      <Checkbox
        name={name}
        checked={value}
        onChange={handleChange}
        color="primary"
        classes={{ root: classes.root }}
      />
    )}
    label={label}
  />
);
CheckboxField.propTypes = {
  classes: PropTypes.object.isRequired,
  label: PropTypes.any,
  value: PropTypes.bool,
  name: PropTypes.string,
  handleChange: PropTypes.func,
};
CheckboxField.defaultProps = {
  label: '',
  value: false,
  name: '',
  handleChange: () => {},
};

export default CheckboxField;
