import moment from 'moment';
import { WEEKEND_DAYS } from 'common/utils/constants';
import api from '../api';

export const DUCK_NAME = 'holidays';

export const INITIAL_STATE = {
  loading: false,
  data: [],
  error: null,
};

export const GET_HOLIDAYS_STARTED = `${DUCK_NAME}/GET_HOLIDAYS_STARTED`;
export const GET_HOLIDAYS_SUCCEED = `${DUCK_NAME}/GET_HOLIDAYS_SUCCEED`;
export const GET_HOLIDAYS_FAILED = `${DUCK_NAME}/GET_HOLIDAYS_FAILED`;

export const getHolidaysStarted = () => ({
  type: GET_HOLIDAYS_STARTED,
});

export const getHolidaysSucceed = (data) => ({
  type: GET_HOLIDAYS_SUCCEED,
  data,
});

export const getHolidaysFailed = (error) => ({
  type: GET_HOLIDAYS_FAILED,
  error,
});

export const getHolidays = () => async (dispatch) => {
  dispatch(getHolidaysStarted());

  try {
    const holidays = await api.holidays.getHolidays();
    const data = Object.keys(holidays.data).map((holiday) => moment(holiday, 'YYYY-MM-DD', true));
    dispatch(getHolidaysSucceed(data));
    return Promise.resolve(data);
  } catch (error) {
    dispatch(getHolidaysFailed(error));
    return Promise.reject(error);
  }
};

export const isHolidayOrWeekendDay = (date) => (dispatch, getState) => {
  const holidayList = getState()[DUCK_NAME].data;
  const isHoliday = !!holidayList.find((holiday) => holiday.isSame(moment(date), 'day'));

  return WEEKEND_DAYS.includes(moment(date).day()) || isHoliday;
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GET_HOLIDAYS_STARTED:
      return {
        ...state,
        loading: true,
      };
    case GET_HOLIDAYS_SUCCEED:
      return {
        ...state,
        loading: false,
        data: action.data,
      };
    case GET_HOLIDAYS_FAILED:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    default:
      return state;
  }
};
