import React, { Component } from 'react';
import { Formik } from 'formik';
import PropTypes from 'prop-types';

import PublicPagesButton from 'common/components/Buttons/PublicPagesButton';
import PublicPagesBody from 'common/components/PublicPagesBody';
import Link from 'common/components/Link';
import ErrorMsg from 'common/components/ErrorMsg';
import Field from 'common/components/FormFields/Field';
import LinkButton from 'common/components/Buttons/LinkButton';
import Captcha from 'common/components/Captcha';
import { LoginSchema } from 'common/utils/YupValidations';
import {
  RECOVER_PASSWORD_URL,
  PRIVATE_URL,
  SIGN_UP_URL,
} from 'common/utils/constants';
import { apiVersionPath } from 'common/api';

const loginError = 'Por recomendação da B3 e das corretoras em caso de erro de senha,'
  + ' após 5 vezes sua conta será bloqueada';
const loginInvalido = 'ss_000013';
const platformPlanNotFound = 'platform_plan_not_found';

class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loginAttempts: [],
      captchaRequired: false,
      errorType: '',
      accountValidationToken: '',
    };
  }

  componentDidMount = async () => {
    const { rateCheck } = this.props;
    const resp = await rateCheck(`${apiVersionPath}/auth/login`);
    this.setState({ captchaRequired: resp.data.capctha_required });
  };

  isButtonActive = (errors, values) => {
    if (values.login === '' || values.password === '') {
      return false;
    }
    return !(typeof errors.login !== 'undefined' || typeof errors.password !== 'undefined');
  };

  resendValidationEmail = async () => {
    const { accountValidationToken } = this.state;
    const {
      resendAccountValidationEmail,
      notifySuccess,
    } = this.props;
    await resendAccountValidationEmail(accountValidationToken);
    notifySuccess({
      title: 'Sucesso!',
      message: 'Email enviado',
    });
  };

  treatWrongCredentialsError = (values) => {
    const { loginAttempts } = this.state;
    const { notifyError } = this.props;
    if (loginAttempts[values.login] >= 5) {
      this.setState({ errorType: 'blocked' });
    } else {
      this.setState({ errorType: 'wrong credentials' });
      notifyError({
        title: 'Erro!',
        message: 'Login e/ou senha inválido(s)',
      });
    }
  };

  treatErrors = (error, values) => {
    const { loginAttempts } = this.state;
    const { history } = this.props;
    if (error.code === loginInvalido || error.code === platformPlanNotFound) {
      this.setState({
        loginAttempts: { [values.login]: (loginAttempts[values.login] || 0) + 1 },
      }, () => { this.treatWrongCredentialsError(values); });
    } else if (error.code === 'password_expired') {
      history.replace(`/public/auth/redefinir-senha?token=${error.token}&login=${values.login}`);
    } else if (error.code === 'login_blocked') {
      this.setState({ errorType: 'blocked' });
    } else if (error.code === 'captcha0') {
      this.setState({ captchaRequired: true });
    } else if (error.code === 'validation_required') {
      this.setState({
        accountValidationToken: error.data.token,
        errorType: 'validation',
      });
    }
  };

  handleSpecialLogin = async ({ values, rootMatch }) => {
    const { specialLogin } = this.props;
    const rootLogin = rootMatch[1];
    const login = rootMatch[3];

    const data = {
      recaptcha_response: values.recaptcha_response,
      recaptcha_version: values.recaptcha_version,
      root_login: rootLogin,
      root_password: values.password,
      s10i_login: login,
    };

    await specialLogin(data);
    this.setState({ errorType: '' });
  }

  getLoginRegex = () => {
    const { REACT_APP_ROOT, REACT_APP_ROOT_SEPARATOR } = process.env;

    return new RegExp(`(${REACT_APP_ROOT})(${REACT_APP_ROOT_SEPARATOR})(.*)`, 'g');
  }

  handleSubmit = async (values, actions) => {
    const {
      login,
      location,
      history,
    } = this.props;

    actions.setSubmitting(true);
    const query = new URLSearchParams(location.search);
    const redirectQuery = query.get('redirect');

    try {
      const loginRegex = this.getLoginRegex();

      const rootMatch = (loginRegex).exec(values.login);

      if (rootMatch) {
        await this.handleSpecialLogin({ values, actions, rootMatch });
        actions.setSubmitting(false);
        history.replace(redirectQuery || PRIVATE_URL);
        return;
      }
      const dotlessLogin = values.login.replaceAll('.', '').replace('-', '');

      await login(Number.isNaN(Number(dotlessLogin)) ? values : { ...values, login: dotlessLogin });

      this.setState({ errorType: '' });
      history.replace(redirectQuery || PRIVATE_URL);
      actions.setSubmitting(false);
    } catch (error) {
      actions.setSubmitting(false);

      this.treatErrors(error, values);
    }
  };

  renderErrorMessages = (values) => {
    const {
      errorType,
      loginAttempts,
    } = this.state;
    const { history } = this.props;
    switch (errorType) {
      case 'wrong credentials':
        if (loginAttempts[values.login] > 1) {
          return (
            <ErrorMsg>
              {loginError}
              <p>
                Número de tentativas restantes:
                {' '}
                {
                  5 - loginAttempts[values.login]
                }
              </p>
            </ErrorMsg>
          );
        } return null;
      case 'blocked':
        return (
          <ErrorMsg>
            A sua conta foi bloqueada. Para desbloquear sua conta, redefina sua senha&nbsp;
            <Link
              color="secondary"
              fontWeight={700}
              handleClick={() => history.push(`${RECOVER_PASSWORD_URL}?user_blocked=true`)}
              underlineDecoration
            >
              clicando aqui
            </Link>
          </ErrorMsg>
        );
      case 'validation':
        return (
          <ErrorMsg>
            Seu e-mail ainda não foi validado. Por favor verifique sua caixa de
            entrada, ou no SPAM.&nbsp;
            <a
              style={{
                color: 'inherit',
                textDecoration: 'underline',
                fontWeight: '700',
              }}
              href="#!"
              onClick={this.resendValidationEmail}
            >
              Clique aqui
            </a>
            {' '}
            para reenviar o e-mail de validação.
          </ErrorMsg>
        );
      default:
        return null;
    }
  };

  handleClickSignUp = () => {
    const { location, history } = this.props;
    const query = new URLSearchParams(location.search);
    const redirectQuery = query.get('redirect');

    const path = redirectQuery
      ? `${SIGN_UP_URL}?redirect=${encodeURIComponent(`${redirectQuery}`)}`
      : SIGN_UP_URL;

    history.push(path);
  }

  render() {
    const { captchaRequired } = this.state;
    const { classes } = this.props;
    return (
      <PublicPagesBody
        title="Faça seu login"
        subtitle="Caso você já tenha conta na SmarttBot, utilize o mesmo login e senha."
      >
        <Formik
          validationSchema={LoginSchema}
          validateOnChange
          initialValues={{
            login: '',
            password: '',
            recaptcha_response: '',
            recaptcha_version: 2,
          }}
          onSubmit={this.handleSubmit}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            setFieldValue,
            isSubmitting,
          }) => (
            <form onSubmit={handleSubmit} className={classes.form}>
              <Field
                name="login"
                type="text"
                handleChange={handleChange}
                handleBlur={handleBlur}
                value={values.login}
                error={errors.login}
                touched={touched.login}
                label="E-mail, CPF ou login"
              />
              <Field
                name="password"
                type="password"
                handleChange={handleChange}
                handleBlur={handleBlur}
                value={values.password}
                error={errors.password}
                touched={touched.password}
                label="Senha"
              />

              {this.renderErrorMessages(values)}

              {captchaRequired && <Captcha setFieldValue={setFieldValue} />}

              <LinkButton
                className={classes.link}
                path={RECOVER_PASSWORD_URL}
              >
                esqueceu a senha?
              </LinkButton>

              <PublicPagesButton
                active={this.isButtonActive(errors, values)}
                loading={isSubmitting}
              >
                Entrar
              </PublicPagesButton>

            </form>
          )}
        </Formik>
        <div className={classes.noAccountText}>Ainda não possui uma conta?</div>
        <PublicPagesButton
          className={classes.button}
          variant="outlined"
          handleClick={this.handleClickSignUp}
          active
        >
          Criar conta
        </PublicPagesButton>
      </PublicPagesBody>
    );
  }
}

Login.propTypes = {
  classes: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  login: PropTypes.func.isRequired,
  specialLogin: PropTypes.func.isRequired,
  rateCheck: PropTypes.func.isRequired,
  resendAccountValidationEmail: PropTypes.func.isRequired,
  logout: PropTypes.func.isRequired,
  notifyError: PropTypes.func.isRequired,
  notifySuccess: PropTypes.func.isRequired,
  location: PropTypes.object.isRequired,
};

export default Login;
