import { withStyles } from '@material-ui/core/styles';
import palette from 'common/palette';

import ButtonGroup from './ButtonGroup';

const primaryBackground = 'rgba(36, 188, 113, .15)';
const secondaryBackground = 'rgba(233, 48, 86, 0.21)';

const styles = {
  wrapper: {
    whiteSpace: 'nowrap',
  },
  button: {
    border: '1px solid',
    borderRadius: 0,
    fontSize: '0.75rem',
    fontWeight: 'bold',
    marginLeft: -1,
    minWidth: 40,
    padding: '8px 16px',
    '&:hover': {
      boxShadow: 'none',
    },
    '&:first-of-type': {
      marginLeft: 0,
      borderTopLeftRadius: 3,
      borderBottomLeftRadius: 3,
    },
    '&:last-of-type': {
      borderTopRightRadius: 3,
      borderBottomRightRadius: 3,
    },
  },
  primary: {
    backgroundColor: primaryBackground,
    color: palette.primary.main,
  },
  secondary: {
    backgroundColor: secondaryBackground,
    color: palette.secondary.main,
  },
  primaryDefault: {
    borderColor: palette.primary.main,
    color: palette.primary.main,
    '&:hover': {
      backgroundColor: primaryBackground,
      color: palette.primary.main,
    },
  },
  secondaryDefault: {
    borderColor: palette.secondary.main,
    color: palette.secondary.main,
    '&:hover': {
      backgroundColor: secondaryBackground,
      color: palette.secondary.main,
    },
  },
};

export default withStyles(styles)(ButtonGroup);
