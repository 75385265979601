import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { logged } from 'common/ducks/auth';
import Pages from './Pages';

const mapStateToProps = ({ notifications, auth }) => ({
  notifications,
  logged: auth.logged,
});

const mapDispatchToProps = (dispatch) => ({
  checkLogged: () => dispatch(logged()),
});

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps,
)(Pages));
