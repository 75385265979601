import React from 'react';
import PropTypes from 'prop-types';
import arrowIcon from 'common/assets/images/arrow_right_alt.svg';
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Box,
  Chip,
  IconButton,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { TABLET_WIDTH_BREAKPOINT } from 'common/utils/constants';
import ChangedStocksListing from 'common/components/ChangedStocksListing';

const StoreItemChangedStocksDialog = ({
  classes,
  onCancelClick,
  open,
  onClose,
  stocks,
  oldStocks,
  validity,
  leftLabelValue,
  rightLabelValue,
  reverseColumns,
}) => {
  const isMobile = window.innerWidth <= TABLET_WIDTH_BREAKPOINT;

  return (
    <Dialog
      className={classes.dialog}
      maxWidth="md"
      fullScreen={isMobile}
      classes={{ paper: classes.dialogWidth }}
      open={open}
      onClose={onCancelClick ? () => onCancelClick(onClose) : onClose}
    >
      <DialogTitle disableTypography className={classes.dialogTitle}>
        Mudanças na carteira
      </DialogTitle>
      <DialogContent className={classes.dialogContent}>
        <IconButton
          size="small"
          className={classes.closeIcon}
          color="inherit"
          onClick={onClose}
          aria-label="close"
        >
          <CloseIcon />
        </IconButton>

        {Object.keys(validity).length > 0 && (
          <Box className={classes.wrapperTags} mb={2}>
            <Chip
              className={classes.tag}
              label={validity.PORTFOLIO_old_validity}
            />
            <Box mx={2}>
              <img className={classes.arrow} src={arrowIcon} alt="arrow" />
            </Box>
            <Chip className={classes.tag} label={validity.PORTFOLIO_validity} />
          </Box>
        )}

        <ChangedStocksListing
          stocks={stocks}
          oldStocks={oldStocks}
          leftLabelValue={leftLabelValue}
          rightLabelValue={rightLabelValue}
          reverseColumns={reverseColumns}
        />
      </DialogContent>
    </Dialog>
  );
};

StoreItemChangedStocksDialog.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  validity: PropTypes.object,
  stocks: PropTypes.object.isRequired,
  oldStocks: PropTypes.object.isRequired,
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onCancelClick: PropTypes.func,
  leftLabelValue: PropTypes.string,
  rightLabelValue: PropTypes.string,
  reverseColumns: PropTypes.bool,
};

StoreItemChangedStocksDialog.defaultProps = {
  onCancelClick: null,
  validity: {},
  leftLabelValue: 'Anterior',
  rightLabelValue: 'Atual',
  reverseColumns: false,
};

export default StoreItemChangedStocksDialog;
