import { connect } from 'react-redux';
import { withStyles } from '@material-ui/styles';
import { openDialog } from 'common/ducks/dialogs';
import theme from 'common/theme';
import { STORE_ITEM_CHANGED_STOCKS_DIALOG_CODE } from '../Dialogs/StoreItemChangedStocksDialog';
import StocksView from './StocksView';

const styles = {
  card: {
    boxShadow: '0 3px 6px 0 rgba(0, 0, 0, 0.16)',
  },
  cardChart: {
    padding: '25px 15px',
  },
  totalPercentText: {
    fontSize: 14,
    fontWeight: 'bold',
  },
  totalPercentValue: {
    fontWeight: 'bold',
    fontSize: 30,
    marginTop: 10,
  },
  wrapperStocks: {
    [`@media(max-width: ${theme.breakpoints.values.md}px)`]: {
      padding: '15px 0',
    },
  },
  borderWrapperStocks: {
    [`@media(max-width: ${theme.breakpoints.values.md}px)`]: {
      borderBottom: '1px solid #c3c3c3',
    },
  },
  validityTag: {
    color: '#676767',
    fontWeight: 500,
    fontSize: 13,
  },
  wrapperBlockStocks: {
    margin: '0 auto',
    maxWidth: 290,
    textAlign: 'center',
  },
};

const mapDispatchToProps = (dispatch) => ({
  openStocksChangesDialog: (props) => dispatch(
    openDialog(STORE_ITEM_CHANGED_STOCKS_DIALOG_CODE, { ...props }),
  ),
});

export default connect(null, mapDispatchToProps)(withStyles(styles)(StocksView));
