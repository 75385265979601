import React, { Component } from 'react';
import PropTypes from 'prop-types';

import Dialog from '@material-ui/core/Dialog';
import withMobileDialog from '@material-ui/core/withMobileDialog';
import DialogContentText from '@material-ui/core/DialogContentText';
import Typography from '@material-ui/core/Typography';

import DialogTitle from '@material-ui/core/DialogTitle';
import CloseDialogButton from 'common/components/Buttons/CloseDialogButton';
import { EMPTY_SETUP_DATA } from 'common/utils/setup';

import { DialogContent } from '@material-ui/core';
import OperationalCostsPanel from './OperationalCostsPanel';

class OperationalCostsDialog extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedSetup: props.instanceSetupCode,
      expanded: '',
    };
  }

  handlePanelChange = (setupCode) => this.setState((prevState) => {
    this.setState({
      expanded: prevState.expanded === setupCode ? '' : setupCode,
    });
  });

  updateSelectedSetup = (setupCode) => this.setState({ selectedSetup: setupCode });

  renderSetups = (array) => {
    const { instance } = this.props;
    const {
      expanded,
      selectedSetup,
    } = this.state;
    return array.map((setup) => (
      <OperationalCostsPanel
        id={`${setup.code}-panel`}
        key={setup.code}
        expand={expanded === setup.code}
        isAssociated={selectedSetup === setup.code}
        associateCost
        shouldAssociateCost
        setup={setup}
        instance={instance}
        updateSelectedSetup={this.updateSelectedSetup}
        handlePanelChange={this.handlePanelChange}
      />
    ));
  };

  renderEmptySetups = () => {
    const { classes } = this.props;
    return (
      <Typography
        className={classes.emptyDialogPlaceholder}
        variant="title"
      >
        Você ainda não criou nenhum custo operacional
      </Typography>
    );
  };

  render() {
    const {
      open,
      setups,
      classes,
      onClose,
      fullScreen,
      instance,
    } = this.props;

    const {
      expanded,
    } = this.state;
    return (
      <Dialog
        fullScreen={fullScreen}
        classes={{ paper: classes.dialog }}
        open={open}
        onClose={onClose}
      >
        <CloseDialogButton onClose={onClose} />
        <DialogTitle
          className={classes.dialogTitle}
          classes={{ root: classes.title }}
          disableTypography
        >
          Custos Operacionais
          <DialogContentText className={classes.subTitle}>
            Associe um custo ou crie um novo
          </DialogContentText>
        </DialogTitle>
        <DialogContent className={classes.dialogContent}>
          {setups.length ? this.renderSetups(setups) : this.renderEmptySetups}
          <OperationalCostsPanel
            create
            id="create-new-setup-panel"
            expand={expanded === 'new-setup'}
            setup={{
              description: '',
              ...EMPTY_SETUP_DATA,
            }}
            instance={instance}
            shouldAssociateCost
            updateSelectedSetup={this.updateSelectedSetup}
            handlePanelChange={this.handlePanelChange}
          />
        </DialogContent>
      </Dialog>
    );
  }
}

OperationalCostsDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  setups: PropTypes.array.isRequired,
  instance: PropTypes.object.isRequired,
  instanceSetupCode: PropTypes.string.isRequired,
  classes: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired,
  fullScreen: PropTypes.bool.isRequired,
};

export const OperationalCostsDialogComponent = OperationalCostsDialog;
export default withMobileDialog()(OperationalCostsDialog);
