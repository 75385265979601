import React from 'react';
import PropTypes from 'prop-types';
import logdown from 'logdown';

import CircularProgress from '@material-ui/core/CircularProgress';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Radio from '@material-ui/core/Radio';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import LabelAction from 'common/components/LabelAction';
import Placeholder from 'common/components/Placeholder';

import {
  validateCardData,
  getCreditCardImage,
} from 'common/utils/paymentMethods';

import { SIGNATURES_URL } from 'common/utils/constants';

export const ALTER_PLAN_PAYMENT_DIALOG_CODE = 'ALTER_PLAN_PAYMENT_DIALOG_CODE';
const logger = logdown('AlterPlanPaymentDialog');

class AlterPlanPaymentDialog extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedCardId: '',
      checkedAutomaticInvoices: false,
    };
  }

  componentDidMount = () => this.props.loadUserPaymentMethods();

  getDialogTitle = () => {
    const {
      subscriptionType,
      plan,
      isReactivateSubscription,
    } = this.props;

    if (isReactivateSubscription) {
      if (subscriptionType === 'plan') {
        return `Reativar assinatura do plano ${plan.name}`;
      }
      return `Reativar assinatura da estratégia ${plan.name}`;
    }

    return `Alterar forma de pagamento de ${plan.name}`;
  };

  handleChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  handleCheckbox = (event) => {
    // Since setState is asynchronous, I have to use the event target name from a variable
    // because of React's event pooling.
    const eventTargetName = event.target.name;
    this.setState((prevState) => ({
      [eventTargetName]: !prevState[eventTargetName],
    }));
  };

  submit = async () => {
    const { selectedCardId, checkedAutomaticInvoices } = this.state;

    const {
      updateSubscription,
      notifySuccess,
      subscriptions,
      notifyError,
      plan,
      history,
    } = this.props;

    try {
      await validateCardData(selectedCardId, checkedAutomaticInvoices, false);
      const data = { payment_method: selectedCardId };
      const planSubscription = subscriptions.data.find((s) => s.code === plan.subscription.code);
      const result = await updateSubscription(planSubscription.code, data);

      notifySuccess({
        title: 'Sucesso!',
        message: 'Sua assinatura foi atualizada! A partir de agora a cobrança será feita no cartão selecionado.',
      });
      logger.info('Meio de pagamento alterado com sucesso.', result);

      history.push(SIGNATURES_URL, { planCode: plan.code, paymentMethod: selectedCardId });
    } catch (error) {
      notifyError({
        title: 'Erro!',
        message:
          error.codeMessage || 'Houve um erro ao atualizar sua assinatura. Tente novamente.',
      });
      logger.error('Falha ao alterar o meio de pagamento.', error);
    }
  };

  renderCards = () => {
    const { classes, paymentMethods } = this.props;

    const { selectedCardId } = this.state;

    if (!paymentMethods.data) return null;

    if (paymentMethods.loading) {
      return (
        <Placeholder height={55} width="100%" />
      );
    }

    return paymentMethods.data.map((paymentMethod) => (
      <LabelAction
        key={`cards-${paymentMethod.id}`}
        logo={(
          <img
            className={classes.logo}
            src={getCreditCardImage(paymentMethod.brand) || null}
            alt={`Bandeira do cartão de crédito ${paymentMethod.brand}`}
          />
          )}
        description={paymentMethod.display_number}
        action={(
          <Radio
            checked={selectedCardId === paymentMethod.id}
            onChange={this.handleChange}
            color="primary"
            value={paymentMethod.id}
            name="selectedCardId"
            id={`radio-button-card-${paymentMethod.id}`}
            aria-label={`Cartão de crédito de que contém: ${
              paymentMethod.display_number
            }`}
          />
        )}
      />
    ));
  };

  render = () => {
    const {
      classes,
      onCancelClick,
      open,
      onClose,
      confirmButtonContent,
      cancelButtonContent,
      subscriptions,
      subscriptionType,
    } = this.props;

    const { checkedAutomaticInvoices } = this.state;

    return (
      <Dialog open={open} onClose={onClose}>
        <DialogTitle
          className={classes.title}
          disableTypography
        >
          {this.getDialogTitle()}
        </DialogTitle>
        <DialogContent className={classes.content}>
          <DialogContentText component="div">
            <div className={classes.paymentContainers}>
              <h3 className={classes.cards}>Cartões</h3>
              {this.renderCards()}
            </div>
            <div className={classes.paymentContainers}>
              <FormControlLabel
                control={(
                  <Checkbox
                    name="checkedAutomaticInvoices"
                    checked={checkedAutomaticInvoices}
                    onChange={(e) => this.handleCheckbox(e)}
                    value="checkedAutomaticInvoices"
                    color="primary"
                  />
                )}
                label={`Estou ciente de que as faturas de renovação desta
                assinatura serão cobradas automaticamente no cartão selecionado.
                Caso queira impedir a renovação automática a sua assinatura deve
                ser cancelada na tela de "Minha Conta" na aba de ${
                  subscriptionType === 'plan'
                    ? 'Planos'
                    : 'Estratégias'
                }.`}
                classes={{ label: classes.label }}
              />
            </div>
          </DialogContentText>
        </DialogContent>
        <DialogActions className={classes.content}>
          <Button
            id="strategy-alter-payment-cancel-click"
            onClick={onCancelClick || onClose}
            color="primary"
            autoFocus
          >
            {cancelButtonContent || 'Cancelar'}
          </Button>
          <div className={classes.wrapper}>
            <Button
              id="strategy-alter-payment-confirm-click"
              variant="contained"
              disabled={subscriptions.loading}
              onClick={() => this.submit()}
              color="primary"
              autoFocus
            >
              {confirmButtonContent || 'Confirmar'}
            </Button>
            {subscriptions.loading && (
              <CircularProgress size={24} className={classes.buttonProgress} />
            )}
          </div>
        </DialogActions>
      </Dialog>
    );
  };
}

AlterPlanPaymentDialog.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onCancelClick: PropTypes.func,
  cancelButtonContent: PropTypes.any,
  confirmButtonContent: PropTypes.any,
  loadUserPaymentMethods: PropTypes.func.isRequired,
  paymentMethods: PropTypes.object.isRequired,
  plan: PropTypes.object.isRequired,
  updateSubscription: PropTypes.func.isRequired,
  subscriptions: PropTypes.object.isRequired,
  subscriptionType: PropTypes.oneOf(['plan', 'strategy']).isRequired,
  isReactivateSubscription: PropTypes.bool,
  notifySuccess: PropTypes.func.isRequired,
  notifyError: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
};

AlterPlanPaymentDialog.defaultProps = {
  isReactivateSubscription: false,
  onCancelClick: null,
  cancelButtonContent: null,
  confirmButtonContent: null,
};

export default AlterPlanPaymentDialog;
