import { withStyles } from '@material-ui/core/styles';
import PasswordConfirmDialog, { PASSWORD_CONFIRM_DIALOG_CODE } from './PasswordConfirmDialog';

export { PASSWORD_CONFIRM_DIALOG_CODE };

const styles = {
  cancelButton: {},
  dialog: {
    margin: '0 auto',
    maxWidth: '520px',
  },
  dialogTitle: {
    fontSize: 22,
    fontWeight: 700,
    paddingBottom: 0,
    paddingTop: '24px',
  },
  dialogContent: {
    paddingTop: 10,
  },
  icon: {
    color: '#939798',
    marginLeft: '-2px',
  },
};

export default withStyles(styles)(PasswordConfirmDialog);
