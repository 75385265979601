import api from 'common/api';
import logdown from 'logdown';

export const INITIAL_STATE = { loading: false, data: null, error: null };

export const DUCK_NAME = 'setups';
export const NAMESPACE = `user/${DUCK_NAME}`;
const logger = logdown(NAMESPACE);

// Actions
export const LOAD_USER_SETUPS_STARTED = `${NAMESPACE}/LOAD_USER_SETUPS_STARTED`;
export const LOAD_USER_SETUPS_SUCCEED = `${NAMESPACE}/LOAD_USER_SETUPS_SUCCEED`;
export const LOAD_USER_SETUPS_FAILED = `${NAMESPACE}/LOAD_USER_SETUPS_FAILED`;

export const loadUserSetupsStarted = () => ({
  type: LOAD_USER_SETUPS_STARTED,
});
export const loadUserSetupsSucceed = (data) => ({
  data,
  type: LOAD_USER_SETUPS_SUCCEED,
});
export const loadUserSetupsFailed = (error) => ({
  error,
  type: LOAD_USER_SETUPS_FAILED,
});
export const loadUserSetups = (options = { reload: false }) => async (dispatch, getState) => {
  const setups = getState().user[DUCK_NAME];
  if (
    !options.reload
    && setups
    && setups.data
    && !setups.loading
    && !setups.error
  ) {
    logger.log('Using loaded Setup', setups);
    return Promise.resolve(setups.data);
  }

  dispatch(loadUserSetupsStarted());

  let results = null;
  try {
    results = await api.user.getUserSetups();
  } catch (error) {
    logger.error('Could not get user Setup.', error);
    dispatch(loadUserSetupsFailed(error));
    return Promise.reject(error);
  }

  dispatch(loadUserSetupsSucceed(results.data));
  return Promise.resolve(results.data);
};

export const UPDATE_SETUP_STARTED = `${NAMESPACE}/UPDATE_SETUP_STARTED`;
export const UPDATE_SETUP_SUCCEED = `${NAMESPACE}/UPDATE_SETUP_SUCCEED`;
export const UPDATE_SETUP_FAILED = `${NAMESPACE}/UPDATE_SETUP_FAILED`;

export const updateSetupStarted = () => ({
  type: UPDATE_SETUP_STARTED,
});
export const updateSetupSucceed = (setup) => ({
  setup,
  type: UPDATE_SETUP_SUCCEED,
});
export const updateSetupFailed = (error) => ({
  error,
  type: UPDATE_SETUP_FAILED,
});
export const updateSetup = (setup) => async (dispatch) => {
  dispatch(updateSetupStarted());
  try {
    await api.user.updateSetup(setup);
  } catch (error) {
    logger.error('Could not get user Setup.', error);
    dispatch(updateSetupFailed(error));
    return Promise.reject(error);
  }

  dispatch(updateSetupSucceed(setup));
  return Promise.resolve(setup);
};

export const CREATE_SETUP_STARTED = `${NAMESPACE}/CREATE_SETUP_STARTED`;
export const CREATE_SETUP_SUCCEED = `${NAMESPACE}/CREATE_SETUP_SUCCEED`;
export const CREATE_SETUP_FAILED = `${NAMESPACE}/CREATE_SETUP_FAILED`;

export const createSetupStarted = () => ({
  type: CREATE_SETUP_STARTED,
});
export const createSetupSucceed = (setup) => ({
  setup,
  type: CREATE_SETUP_SUCCEED,
});
export const createSetupFailed = (error) => ({
  error,
  type: CREATE_SETUP_FAILED,
});
export const createSetup = (setup) => async (dispatch) => {
  dispatch(createSetupStarted());

  try {
    await api.user.insertSetup(setup);
  } catch (error) {
    logger.error('Could not get user Setup.', error);
    dispatch(createSetupFailed(error));
    return Promise.reject(error);
  }

  dispatch(createSetupSucceed(setup));
  return Promise.resolve(setup);
};

// Reducer
export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case LOAD_USER_SETUPS_STARTED:
      return {
        ...state,
        loading: true,
      };
    case LOAD_USER_SETUPS_SUCCEED:
      return {
        ...state,
        loading: false,
        error: null,
        data: action.data,
      };
    case LOAD_USER_SETUPS_FAILED:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    case UPDATE_SETUP_STARTED:
      return {
        ...state,
        loading: true,
      };
    case UPDATE_SETUP_SUCCEED:
      return {
        ...state,
        data: state.data.map((setup) => (
          setup.code === action.setup.code
            ? {
              ...setup,
              ...action.setup,
            }
            : setup)),
        loading: false,
      };
    case UPDATE_SETUP_FAILED:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    case CREATE_SETUP_STARTED:
      return {
        ...state,
        loading: true,
      };
    case CREATE_SETUP_SUCCEED:
      return {
        ...state,
        loading: false,
        data: state
          .data
          .concat(action.setup),
      };
    case CREATE_SETUP_FAILED:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    default:
      return state;
  }
};
