import React, { useState } from 'react';
import { reduxForm, Field } from 'redux-form';
import PropTypes from 'prop-types';

import {
  FormControl,
  MenuItem,
  Typography,
  InputLabel,
  Box,
} from '@material-ui/core';

import { required, instanceNameValidation } from 'common/utils/validation';
import { InputTextField, SelectField } from 'common/components/ReduxForm';
import { BROKERAGE_STATUS } from 'common/utils/constants';
import { NAME } from 'common/utils/constants/brokerages';
import { orderByString } from 'common/utils/array';
import { getFullDataBrokerages } from 'common/utils/brokerage';

const ChooseBrokerageForm = ({
  classes,
  brokerages: {
    brokerages,
  },
  allowedBrokerages,
  storeInstance,
  isWhiteBox,
  templateName,
}) => {
  const [nameFieldValue, setNameFieldValue] = useState(templateName);
  const { APPROVED } = BROKERAGE_STATUS;
  const fullBrokerages = getFullDataBrokerages({
    notFullBrokerages: allowedBrokerages,
    fullBrokerages: brokerages,
  });

  const orderedBrokerages = orderByString(fullBrokerages, NAME);

  const renderMenuItem = (option) => (
    <MenuItem key={option.id} value={option.id}>
      {option.name}
    </MenuItem>
  );

  const renderBrokerages = () => {
    return orderedBrokerages.map((option) => (
      option?.status === APPROVED ? renderMenuItem(option) : null
    ));
  };

  return (
    <>
      {!isWhiteBox ? (
        <>
          <Typography
            className={classes.label}
            color="textSecondary"
            variant="h6"
          >
            Nome da carteira
          </Typography>
          <Typography
            className={classes.instance}
            color="textPrimary"
            variant="h3"
          >
            {storeInstance.name}
          </Typography>
        </>
      ) : (
        <Field
          id="wallet-name"
          type="text"
          name="name"
          component={InputTextField}
          validate={instanceNameValidation}
          label="Nome da carteira"
          format={() => nameFieldValue}
          normalize={() => nameFieldValue}
          onChange={(event) => setNameFieldValue(event.target.value)}
          autoFocus
        />
      )}

      <Box mt={2}>
        <FormControl
          classes={{ marginNormal: classes.fieldSpacingTitle }}
          margin="normal"
          fullWidth
        >
          <InputLabel shrink id="label-brokerage">
            Em qual corretora você quer seguir a carteira?
          </InputLabel>
          <Field
            id="profile-state"
            name="brokerage"
            select
            component={SelectField}
            validate={required}
          >
            {renderBrokerages()}
          </Field>
        </FormControl>
      </Box>
    </>
  );
};

ChooseBrokerageForm.propTypes = {
  classes: PropTypes.object.isRequired,
  brokerages: PropTypes.object.isRequired,
  storeInstance: PropTypes.object.isRequired,
  allowedBrokerages: PropTypes.array.isRequired,
  isWhiteBox: PropTypes.bool,
  templateName: PropTypes.string,
};

ChooseBrokerageForm.defaultProps = {
  isWhiteBox: false,
  templateName: '',
};

export default reduxForm({
  form: 'follow_action_choose_brokerage',
})(ChooseBrokerageForm);
