import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

// Material-UI
import CircularProgress from '@material-ui/core/CircularProgress';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import Switch from '@material-ui/core/Switch';
import Grid from '@material-ui/core/Grid';

// FontAwesome
import FontAwesomeIcon from '@fortawesome/react-fontawesome';
import faEdit from '@fortawesome/fontawesome-free-solid/faEdit';

import { InputTextField } from 'common/components/ReduxForm';
import ArrowTooltip from 'common/components/ArrowTooltip';
import { EDITAR } from 'common/utils/constants';

import SaveIconButton from './SaveIconButton';

const EditCostsPanelSummary = ({
  expand,
  loading,
  editMode,
  isValid,
  isAssociated,
  setup,
  classes,
  activeEditMode,
  description,
  handleChange,
  handleBlur,
  associateCost,
}) => {
  const renderEditExpand = (
    <InputTextField
      id={`${setup.code}-description-input`}
      name="description"
      value={description.value}
      meta={{
        error: description.error,
        touched: description.touched,
      }}
      onChange={handleChange}
      onBlur={handleBlur}
      type="text"
      label="Nome do custo operacional"
    />
  );
  const renderNotExpanded = (
    <Typography className={classes.setupDescription}>
      {setup.description}
    </Typography>
  );
  const renderSaveButton = (
    <SaveIconButton
      isValid={isValid}
      setupCode={setup.code}
      classes={classes}
      type="submit"
    />
  );
  const renderExpandButton = (
    <ArrowTooltip
      placement="bottom"
      title={EDITAR}
    >
      <IconButton
        id={`${setup.code}-operational-costs-toggle_edition`}
        key="operational-costs-toggle_edition"
        className={classes.expansionPanelIconButton}
        onClick={activeEditMode}
      >
        <FontAwesomeIcon icon={faEdit} />
      </IconButton>
    </ArrowTooltip>
  );
  return (
    <Grid
      container
      justify="space-between"
      alignItems="center"
    >
      <Grid
        item
        className={classNames(
          classes.operationalCostFieldWrapper,
          editMode && classes.fieldEditDescription,
        )}
      >
        {editMode && expand ? renderEditExpand : renderNotExpanded}
      </Grid>
      <Grid className={classes.panelIconGroup} item>
        {loading && (
          <CircularProgress
            className={classes.circularProgress}
            color="primary"
            size={20}
            thickness={7}
          />
        )}
        <ArrowTooltip
          placement="left"
          title={isAssociated ? 'Associado' : 'Associar à carteira'}
        >
          <Switch
            id={`${setup.code}-associate-cost-btn`}
            checked={isAssociated}
            value="isAssociated"
            color="primary"
            onClick={associateCost}
          />
        </ArrowTooltip>
        {editMode && expand ? renderSaveButton : renderExpandButton}
      </Grid>
    </Grid>
  );
};

EditCostsPanelSummary.propTypes = {
  loading: PropTypes.bool.isRequired,
  editMode: PropTypes.bool.isRequired,
  expand: PropTypes.bool.isRequired,
  isAssociated: PropTypes.bool.isRequired,
  isValid: PropTypes.bool.isRequired,
  // classes is received from OperationalCostPanel component
  classes: PropTypes.object.isRequired,
  setup: PropTypes.object.isRequired,
  description: PropTypes.object.isRequired,
  handleChange: PropTypes.func.isRequired,
  handleBlur: PropTypes.func.isRequired,
  activeEditMode: PropTypes.func.isRequired,
  associateCost: PropTypes.func.isRequired,
};

export default EditCostsPanelSummary;
