import React, { useState } from 'react';
import PropTypes from 'prop-types';

import Button from '@material-ui/core/Button';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  CircularProgress,
} from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';

import CloseDialogButton from 'common/components/Buttons/CloseDialogButton';

const ConfirmationDialog = ({
  classes,
  open,
  title,
  content,
  customButton,
  cancelButtonContent,
  confirmButtonContent,
  confirmButtonColor,
  info,
  showButtons,
  onConfirmClick,
  onCancelClick,
  onClose,
  preventClose,
  responsive,
  fullScreen,
  maxWidth,
  showCancelButton,
  customClasses,
  backButton,
  loadingButton,
  closeOnConfirm,
  ...other
}) => {
  const [isLoading, setIsLoading] = useState(false);

  const handleClickPrimaryButton = async () => {
    if (loadingButton) setIsLoading(true);
    await onConfirmClick(onClose);
    if (loadingButton) setIsLoading(false);

    if (!preventClose || closeOnConfirm) {
      onClose();
    }
  };

  return (
    <Dialog
      fullScreen={fullScreen && responsive}
      maxWidth={maxWidth}
      fullWidth
      open={open}
      onClose={!preventClose ? onClose : null}
      classes={customClasses}
      {...other}
    >
      {responsive && <CloseDialogButton onClose={onClose} />}
      {title && <DialogTitle disableTypography className={classes.title}>{title}</DialogTitle>}
      <DialogContent className={classes.dialogContent}>
        <DialogContentText className={classes.contentText} component="div">
          {content}
        </DialogContentText>
      </DialogContent>
      {showButtons && (
      <DialogActions className={classes.relative}>
        {info
          ? (
            <Button
              onClick={async () => {
                let result;
                if (onConfirmClick) {
                  result = await onConfirmClick();
                }
                if (typeof result === 'undefined' || result) {
                  onClose();
                }
              }}
              color="primary"
              autoFocus
            >
              { confirmButtonContent || 'Ok' }
            </Button>
          ) : (
            <div>
              {backButton.show && (
                <div className={classes.backIcon}>
                  <ArrowBackIcon
                    fontSize="large"
                    onClick={backButton.onClick}
                    color="inherit"
                  />
                </div>
              )}
              {showCancelButton
              && (
              <Button
                onClick={(() => {
                  onCancelClick();
                  onClose();
                })}
                color="default"
              >
                {cancelButtonContent || 'Cancelar'}
              </Button>
              )}
              <span className={classes.wrapperPrimaryButton}>
                {customButton || (
                  <Button
                    onClick={handleClickPrimaryButton}
                    className={classes.primaryButton}
                    color={confirmButtonColor}
                    variant="contained"
                    disabled={isLoading}
                  >
                    {isLoading && (
                    <CircularProgress
                      size={20}
                      thickness={7}
                      className={classes.loading}
                    />
                    )}
                    {confirmButtonContent || 'Confirmar'}
                  </Button>
                )}
              </span>
            </div>
          )}
      </DialogActions>
      )}
    </Dialog>
  );
};

ConfirmationDialog.propTypes = {
  classes: PropTypes.object.isRequired,
  open: PropTypes.bool.isRequired,
  title: PropTypes.any,
  content: PropTypes.any.isRequired,
  customButton: PropTypes.any,
  cancelButtonContent: PropTypes.any,
  confirmButtonContent: PropTypes.any,
  confirmButtonColor: PropTypes.string,
  info: PropTypes.bool,
  showButtons: PropTypes.bool,
  onConfirmClick: PropTypes.func,
  onCancelClick: PropTypes.func,
  onClose: PropTypes.func.isRequired,
  responsive: PropTypes.bool,
  fullScreen: PropTypes.bool.isRequired,
  preventClose: PropTypes.bool,
  maxWidth: PropTypes.string,
  showCancelButton: PropTypes.bool,
  customClasses: PropTypes.object,
  backButton: PropTypes.object,
  loadingButton: PropTypes.bool,
  closeOnConfirm: PropTypes.bool,
};

ConfirmationDialog.defaultProps = {
  info: false,
  onConfirmClick: () => {},
  onCancelClick: () => {},
  title: null,
  showButtons: true,
  responsive: false,
  customButton: null,
  cancelButtonContent: null,
  confirmButtonContent: null,
  confirmButtonColor: 'primary',
  preventClose: false,
  maxWidth: 'md',
  showCancelButton: true,
  customClasses: {},
  backButton: {
    show: false,
    onClick: () => {},
  },
  loadingButton: false,
  closeOnConfirm: false,
};

export default ConfirmationDialog;
