import React, { Component } from 'react';
import PropTypes from 'prop-types';
import logdown from 'logdown';
import moment from 'moment';
import { Formik } from 'formik';
import classNames from 'classnames';
// Material-UI
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import IconButton from '@material-ui/core/IconButton';

// FontAwesome
import FontAwesomeIcon from '@fortawesome/react-fontawesome';
import faAngleDown from '@fortawesome/fontawesome-free-solid/faAngleDown';
import faTimes from '@fortawesome/fontawesome-free-solid/faTimes';

import { EMPTY_SETUP_DATA, DEFAULT_SETUP_DATA } from 'common/utils/setup';
import OperationalCostsForm, {
  operationalCostsValidation,
} from 'common/components/Forms/OperationalCostsForm';

import EditCostsPanelSummary from './EditCostsPanelSummary';
import CreateCostsPanelSummary from './CreateCostsPanelSummary';

const logger = logdown('OperationalCostsDialog');

class OperationalCostsPanel extends Component {
  constructor(props) {
    super(props);
    this.state = {
      editMode: false,
      loading: false,
    };
  }

  associateCost = async ({ code }) => {
    const {
      setup,
      notifyError,
      instance,
      notifySuccess,
      updateSelectedSetup,
      updateInvestment,
      isAssociated,
      shouldAssociateCost,
    } = this.props;

    try {
      this.setState({ loading: true });

      const associatedSetupCost = isAssociated ? DEFAULT_SETUP_DATA.code : setup.code;

      if (shouldAssociateCost) {
        const associatedMessage = `A partir de agora a carteira ${instance.id}
        não possui nenhum custo associado`;
        const notAssociatedMessage = `O custo operacional ${setup.description}
        foi associado à carteira #${instance.id}.`;

        await updateInvestment({
          setup_code: associatedSetupCost || code,
          code: `instance_${instance.id}`,
          brokerage_id: instance.brokerage_id,
        });

        notifySuccess({
          title: 'Sucesso!',
          message: isAssociated ? associatedMessage : notAssociatedMessage,
        });
      }

      this.setState({ loading: false });

      updateSelectedSetup(associatedSetupCost || code);
    } catch (error) {
      notifyError({
        title: 'Erro!',
        message: `Algo de inesperado ocorreu e o custo operacional não
        foi associado à carteira. Por favor, tente novamente.`,
      });
      this.setState({ loading: false });
    }
  };

  updateOperationalCost = async (setupData) => {
    const {
      updateSetup,
      notifySuccess,
      notifyError,
    } = this.props;
    this.setState({ loading: true });

    try {
      await updateSetup(setupData);
      notifySuccess({
        title: 'Sucesso!',
        message: `Custo operacional atualizado. A partir de agora as carteiras associadas a este
        custo utilizarão os novos custos operacionais configurados.`,
      });

      this.setState({ loading: false, editMode: false });
    } catch (error) {
      notifyError({
        title: 'Erro!',
        message: `Algo de inesperado ocorreu e o custo operacional não foi atualizado.
        Por favor, tente novamente.`,
      });
      this.setState({ loading: false });
      logger.log(`Failed to update ${setupData.code} costs`, error);
    }
  };

  createOperationalCost = async (setupData) => {
    const {
      createSetup,
      notifyError,
      notifySuccess,
      handlePanelChange,
      updateSelectedSetup,
      shouldAssociateCost,
    } = this.props;

    this.setState({ loading: true });

    try {
      const newSetupCode = `setup_${moment().format('YYYYMMDD-HHmmSS')}`;
      updateSelectedSetup(newSetupCode);
      await createSetup({
        ...EMPTY_SETUP_DATA,
        ...setupData,
        code: newSetupCode,
      });

      if (shouldAssociateCost) {
        await this.associateCost({ code: newSetupCode });
      }

      notifySuccess({
        title: 'Sucesso!',
        message: 'O custo operacional foi criado.',
      });

      this.setState({ loading: false, editMode: false });
      handlePanelChange('new-setup');
    } catch (error) {
      notifyError({
        title: 'Erro!',
        message: `Algo de inesperado ocorreu e o custo operacional não foi criado.
        Por favor, tente novamente.`,
      });
      logger.log('Failed to create costs', error);
      this.setState({ loading: false, editMode: false });
    }
  };

  renderOperationalCostsPanel = ({
    errors,
    values,
    touched,
    handleBlur,
    handleChange,
    handleSubmit,
    resetForm,
  }) => {
    const {
      setup,
      create,
      expand,
      classes,
      isAssociated,
      handlePanelChange,
    } = this.props;
    const {
      loading,
      editMode,
    } = this.state;
    const togglePanel = () => {
      handlePanelChange(setup.code || 'new-setup');
      resetForm();
    };
    const createCostExpandIcon = (
      expand && (
        <IconButton
          id="create-cost-expand_icon"
          className={classes.expansionPanelIconButton}
        >
          <FontAwesomeIcon icon={faTimes} />
        </IconButton>
      )
    );

    const editCostExpandIcon = (
      <IconButton
        id={`${setup.code}-edit-cost-expand_icon`}
        className={classes.expansionPanelIconButton}
      >
        <FontAwesomeIcon icon={faAngleDown} />
      </IconButton>
    );

    const summaryDefaultProps = {
      loading,
      expand,
      classes,
      handleChange,
      description: {
        value: values.description,
        touched: touched.description,
        error: errors.description,
      },
      handleBlur,
      isValid: !Object.keys(errors).length,
    };

    const renderCreateCostPanel = (
      <CreateCostsPanelSummary
        {...summaryDefaultProps}
        togglePanel={togglePanel}
      />
    );

    const renderEditCostPanel = (
      <EditCostsPanelSummary
        {...summaryDefaultProps}
        setup={setup}
        editMode={editMode}
        isAssociated={isAssociated}
        associateCost={this.associateCost}
        activeEditMode={() => this.setState(
          { editMode: true },
          !expand ? togglePanel : () => null,
        )}
      />
    );

    const handleClickExpandIcon = create
      ? () => this.setState({ editMode: false }, togglePanel)
      : togglePanel;

    return (
      <>
        <Accordion
          component="form"
          onSubmit={handleSubmit}
          expanded={expand}
          className={classNames(
            classes.expansionPanel,
            create && classes.expansionPanelMargin,
          )}
        >
          <AccordionSummary
            className={
            classNames(
              classes.setupDescription,
              !create && classes.editPanelSummary,
            )
          }
            classes={{
              expandIcon: classes.expandIcon,
              content: classes.expansionPanelSummaryContent,
            }}
            expandIcon={create ? createCostExpandIcon : editCostExpandIcon}
            IconButtonProps={{ onClick: handleClickExpandIcon }}
          >
            {create ? renderCreateCostPanel : renderEditCostPanel}
          </AccordionSummary>
          <AccordionDetails className={classes.expansionPanelDetails}>
            <OperationalCostsForm
              meta={{
                errors,
                touched,
              }}
              edit={editMode || create}
              values={values}
              handleChange={handleChange}
              handleBlur={handleBlur}
            />
          </AccordionDetails>
        </Accordion>
      </>
    );
  };

  render = () => (
    <Formik
      onSubmit={this.props.create ? this.createOperationalCost : this.updateOperationalCost}
      validate={operationalCostsValidation}
      initialValues={this.props.setup}
      render={this.renderOperationalCostsPanel}
    />
  )
}

OperationalCostsPanel.propTypes = {
  create: PropTypes.bool,
  classes: PropTypes.object.isRequired,
  setup: PropTypes.object.isRequired,
  expand: PropTypes.bool.isRequired,
  notifySuccess: PropTypes.func.isRequired,
  notifyError: PropTypes.func.isRequired,
  updateSetup: PropTypes.func.isRequired,
  createSetup: PropTypes.func.isRequired,
  updateInvestment: PropTypes.func.isRequired,
  isAssociated: PropTypes.bool,
  updateSelectedSetup: PropTypes.func,
  handlePanelChange: PropTypes.func,
  instance: PropTypes.object,
  shouldAssociateCost: PropTypes.bool,
};

OperationalCostsPanel.defaultProps = {
  updateSelectedSetup: null,
  create: false,
  isAssociated: false,
  handlePanelChange: null,
  instance: {},
  shouldAssociateCost: false,
};

export default OperationalCostsPanel;
