import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { reduxForm, Field } from 'redux-form';
import {
  SI_ZENDESK_CATEGORY,
  SI_ZENDESK_ARTICLES_URL,
  INSTANCE_REBALANCE_MSG,
  INSTANCE_NOT_REBALANCE_MSG,
} from 'common/utils/constants';

import {
  FormControl,
  Button,
  Box,
  Typography,
  Switch,
} from '@material-ui/core';

import {
  required,
  validateMinimumInvestment,
  validateLimitInvestment,
} from 'common/utils/validation';
import { InputTextField } from 'common/components/ReduxForm';
import ButtonGroupField from 'common/components/ReduxForm/ButtonGroupField';
import { formatMoney, parseNumber } from 'common/utils/string';
import { getChangePortfolioDate } from 'common/utils/whitebox';

import Link from 'common/components/Link';
import { getMessageMountingPortfolio } from 'common/utils/storeItem';
import { getExecutionTimeByValue } from 'common/utils/instance';
import { useStoreInstanceWithPlanLimit, useValueRolloutDate } from 'common/hooks/storeItem';
import { DEFAULT_CHANGE_PORTFOLIO_DATE } from 'common/utils/constants/instance';

const CustomOptionsForm = ({
  classes,
  dataSteps,
  setDataSteps,
  isWhiteBox,
  templateId,
  startFromZero,
  strategyId,
  isHolidayOrWeekendDay,
}) => {
  const storeInstance = useStoreInstanceWithPlanLimit(templateId, strategyId);
  const { valueParam, setValueParam, paramsMountInstance } = useValueRolloutDate({
    strategyId,
    dataSteps,
  });

  const paramsInstance = {
    ...paramsMountInstance,
    mountNow: {
      ...paramsMountInstance.mountNow,
      forceMountPortfolio: startFromZero,
      change_portfolio_date: getChangePortfolioDate(isHolidayOrWeekendDay),
    },
  };

  const [rebalancePortfolio, setRebalancePortfolio] = useState(
    dataSteps.data.rebalancePortfolio || false,
  );
  const message = rebalancePortfolio
    ? INSTANCE_REBALANCE_MSG
    : INSTANCE_NOT_REBALANCE_MSG;

  const handleChangeButtonGroup = (value) => {
    const executionTimeObj = getExecutionTimeByValue(value);

    const data = {
      ...dataSteps.data,
      selectedDateOption: value,
      force_mount_portfolio: startFromZero
      && value === paramsInstance.mountNow.value,
      change_portfolio_date: executionTimeObj.change_portfolio_date,
    };

    setValueParam(value);
    setDataSteps({ ...dataSteps, data });
  };

  const handleChangeSwitch = (event) => {
    const { checked } = event.target;
    const data = {
      ...dataSteps.data,
      rebalancePortfolio: checked,
      change_portfolio_date: !checked
        ? DEFAULT_CHANGE_PORTFOLIO_DATE
        : getChangePortfolioDate(isHolidayOrWeekendDay),
    };

    setDataSteps({ ...dataSteps, data });
    setRebalancePortfolio(checked);
  };

  return (
    <>
      {startFromZero && (
      <FormControl fullWidth>
        <Field
          data-testid="initial_capital"
          type="text"
          id="profile-name_or_corporate_name"
          name="initial_capital"
          component={InputTextField}
          format={(value) => formatMoney(value, 2)}
          normalize={(value) => parseNumber(value, 2)}
          validate={[required, validateMinimumInvestment, validateLimitInvestment]}
          label="Qual será o investimento inicial?"
          autoFocus
        />
      </FormControl>
      )}

      {!isWhiteBox ? (
        <>
          <Box data-testid="box-button-group" my={2}>
            <FormControl fullWidth>
              <ButtonGroupField
                input={{
                  disabled: false,
                  required: true,
                  color: 'primary',
                  value: valueParam,
                  onChange: handleChangeButtonGroup,
                }}
                label="Quando você quer montar a carteira?"
              >
                {Object.values(paramsInstance).map((item) => (
                  <Button
                    key={item.id}
                    value={item.value}
                    className={classes.textUppercase}
                  >
                    {item.text}
                  </Button>
                ))}
              </ButtonGroupField>
            </FormControl>
          </Box>

          <Box mb={2}>
            <Typography color="textSecondary" variant="h6">
              {getMessageMountingPortfolio({
                storeInstance,
                selectedMountItem: valueParam,
                startFromZero,
              })}
            </Typography>
          </Box>

          <Link
            color="primary"
            fontWeight={500}
            path={`${SI_ZENDESK_ARTICLES_URL}/4419885561239`}
            target="_blank"
          >
            Como escolher quando montar a minha carteira?
          </Link>
        </>
      ) : (
        <>
          {startFromZero && (
            <>
              <Box my={3}>
                <Typography color="textSecondary" variant="h6">
                  Você poderá realizar novos investimentos ou aportes após
                  começar a seguir a carteira.
                </Typography>
              </Box>
              <Box>
                <Typography color="textSecondary" variant="h6">
                  A carteira será montada imediatamente ou na próxima janela de
                  operação.
                  {' '}
                  <Link
                    color="primary"
                    fontWeight={700}
                    path={`${SI_ZENDESK_ARTICLES_URL}/4419885868439`}
                    target="_blank"
                    underlineDecoration
                  >
                    Saiba mais aqui
                  </Link>
                </Typography>
              </Box>
            </>
          )}

          {!startFromZero && (
            <>
              <Box data-testid="rebalance-box" mb={2}>
                <Typography variant="h6" color="textSecondary">
                  Rebalancear a carteira para a composição do template?
                </Typography>
                <Switch
                  color="primary"
                  checked={rebalancePortfolio}
                  onChange={handleChangeSwitch}
                  name="rebalance"
                />
              </Box>
              <Box mb={2}>
                <Typography variant="h6">
                  {message}
                </Typography>
              </Box>

              <Link
                color="primary"
                fontWeight={500}
                path={`${SI_ZENDESK_CATEGORY}`}
                target="_blank"
              >
                Como funciona o rebalancemento da carteira?
              </Link>
            </>
          )}
        </>
      )}
    </>
  );
};

CustomOptionsForm.propTypes = {
  classes: PropTypes.object.isRequired,
  dataSteps: PropTypes.object.isRequired,
  setDataSteps: PropTypes.func.isRequired,
  startFromZero: PropTypes.bool.isRequired,
  strategyId: PropTypes.number.isRequired,
  isWhiteBox: PropTypes.bool,
  templateId: PropTypes.number,
  isHolidayOrWeekendDay: PropTypes.func.isRequired,
};

CustomOptionsForm.defaultProps = {
  isWhiteBox: false,
  templateId: 0,
};

export default reduxForm({ form: 'follow_action_custom_options_form' })(CustomOptionsForm);
