import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { formatNumber } from 'accounting';
import {
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  Typography,
} from '@material-ui/core';

import {
  IS_ERROR, PRIVATE_URL, SIGN_UP_URL, INSTANCES_URL,
} from 'common/utils/constants';
import { INSTANCE_DETAILS_TABS } from 'common/utils/constants/instance';
import { isPlanDowngrade } from 'common/utils/plans';
import DisplayNumber from 'common/components/DisplayNumber';
import Placeholder from 'common/components/Placeholder';

const PlansCards = ({
  classes,
  plans,
  strategyId,
  logged,
  history,
  onClose,
  instanceId,
  instanceReport,
  openProgressDialog,
  isWhiteBox,
}) => {
  const isInstanceReportLoading = instanceReport.loading && !instanceReport.data.equity;

  const signAction = (plan) => {
    if (isPlanDowngrade(plan.text)) {
      const { plan_limit: planLimit } = plan.extra_data;
      const { equity } = instanceReport.data || 0;
      if (equity > planLimit) {
        return openProgressDialog({
          state: IS_ERROR,
          title: 'Erro',
          message: (
            <Typography variant="h5">
              <Box>
                Não foi possível alterar o seu plano. Seu patrimônio atual precisa ser inferior
                ao teto do plano que você deseja adquirir.
              </Box>
              <Box mt={3} display="flex" justifyContent="space-around">
                <Box>
                  Patrimônio atual
                  <br />
                  {`R$ ${formatNumber(equity, 2)}`}
                </Box>
                <Box>
                  Limite do plano
                  <br />
                  {`R$ ${formatNumber(planLimit, 2)}`}
                </Box>
              </Box>
              <Box mt={3}>
                Realize o resgate necessário para assinatura e
                refaça o processo de redução de plano.
              </Box>
            </Typography>
          ),
          closeOnClickBackdrop: false,
          closeAfterClick: true,
          showButton: true,
          buttonMessage: 'Realizar resgate',
          buttonClick: () => history.push(`${INSTANCES_URL}/${instanceId}/${INSTANCE_DETAILS_TABS.MOVEMENTS}`),
        });
      }
    }

    const discount = plan.extra_data.discount_code;
    const discountCode = discount ? `&discount_code=${discount}` : '';

    const checkoutPath = `${PRIVATE_URL}/checkout?code=${plan.code}&id=${strategyId}${discountCode}`;

    const path = logged
      ? checkoutPath
      : `${SIGN_UP_URL}?redirect=${encodeURIComponent(checkoutPath)}`;

    history.replace(path);
    return onClose();
  };

  return (
    <Grid className={classes.gridWrapper}>
      {isInstanceReportLoading
        ? [1, 2, 3, 4, 5, 6].map((key) => <Placeholder width={150} height={200} key={key} />)
        : plans.map((plan) => (
          <Card
              key={`instance-card-${plan.code}`}
              className={classes.card}
              raised={false}
          >
            <CardContent
              className={classNames(classes.cardContent)}
            >
              <span
                className={classes.leftTriangle}
              >
              </span>
              <span
                className={classes.rightTriangle}
              >
              </span>
              <div className={classes.content}>
                {isWhiteBox && (
                  <>
                    <header className={classes.header}>2 carteiras</header>
                    <div className={classes.divider} />
                  </>
                )}
                <div className={classes.title}>Até</div>
                <DisplayNumber
                  value={plan.extra_data.plan_limit}
                  currency
                  colorful={false}
                  symbolScale={0.5}
                  valueScale={1}
                  className={classes.limit}
                />
                <div className={classes.divider} />
                <div className={classes.monthlyValue}>
                  <DisplayNumber
                    value={plan.value}
                    currency
                    colorful={false}
                    scale={1}
                    className={classes.value}
                  />
                  <span className={classes.valueText}>/mês</span>
                </div>
                <div className={classes.divider} />
                <div className={classes.button}>
                  <Button
                    variant={plan.variant || 'contained'}
                    color={plan.color || 'primary'}
                    onClick={() => signAction(plan)}
                    disabled={plan.disabled || false}
                  >
                    {plan.text || 'Assinar'}
                  </Button>
                </div>
              </div>
            </CardContent>
          </Card>
        ))}
    </Grid>
  );
};

PlansCards.propTypes = {
  classes: PropTypes.object.isRequired,
  plans: PropTypes.array.isRequired,
  strategyId: PropTypes.number.isRequired,
  logged: PropTypes.bool.isRequired,
  history: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired,
  instanceId: PropTypes.number,
  instanceReport: PropTypes.object.isRequired,
  openProgressDialog: PropTypes.func.isRequired,
  isWhiteBox: PropTypes.bool,
};

PlansCards.defaultProps = {
  instanceId: null,
  isWhiteBox: false,
};

export default PlansCards;
