import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Checkbox,
  CircularProgress,
  Container,
  Collapse,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControlLabel,
  IconButton,
  InputAdornment,
  Switch,
  TextField,
} from '@material-ui/core';
import FileCopyOutlinedIcon from '@material-ui/icons/FileCopyOutlined';
import ArrowTooltip from 'common/components/ArrowTooltip';

import {
  ERROR, SUCCESS,
} from 'common/utils/constants';
import { getInstancePublicLink } from 'common/utils/instance';
import { INSTANCE_VISIBILITY } from 'common/utils/constants/instance';

import CloseDialogButton from 'common/components/Buttons/CloseDialogButton';

const ShareWalletLinkDialog = ({
  classes,
  instance,
  onClose,
  updateInstance,
  notifyError,
  notifySuccess,
}) => {
  const { PUBLIC, PRIVATE } = INSTANCE_VISIBILITY;

  const [preferences, setPreferences] = useState({
    shareInstance: instance.visibility === PUBLIC,
    shareAllInstanceData: false,
    shareInstanceComposition: instance.visibility_preferences?.composition,
    shareInstanceVolume: instance.visibility_preferences?.financial_volume,
  });

  const [publicLink, setPublicLink] = useState(
    getInstancePublicLink({ id: instance.id, visibility: instance.visibility }),
  );

  useEffect(() => {
    setPreferences((oldPreferences) => ({
      ...oldPreferences,
      shareAllInstanceData:
      preferences.shareInstanceComposition && preferences.shareInstanceVolume,
    }));
  }, [preferences.shareInstanceComposition, preferences.shareInstanceVolume]);

  const successMessageContent = {
    enabledSharing: 'Sua carteira agora é pública e visível através do seu link',
    modifiedSharing: 'Suas configurações de compartilhamento foram alteradas',
    disabledSharing: 'O compartilhamento da sua carteira foi desativado',
  };

  const getSuccessMessage = ({ oldVisibility, newVisibility }) => {
    if (oldVisibility === null || (oldVisibility === PRIVATE && newVisibility)) {
      return successMessageContent.enabledSharing;
    }
    if (oldVisibility !== null && !newVisibility) {
      return successMessageContent.disabledSharing;
    }
    return successMessageContent.modifiedSharing;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const successMessage = getSuccessMessage({
        oldVisibility: instance.visibility, newVisibility: preferences.shareInstance,
      });
      await updateInstance(
        instance.id,
        {
          visibility: preferences.shareInstance ? PUBLIC : PRIVATE,
          visibility_preferences: {
            composition: !!preferences.shareInstanceComposition,
            financial_volume: !!preferences.shareInstanceVolume,
          },
        },
      );
      notifySuccess({
        title: SUCCESS,
        message: successMessage,
      });
      setPublicLink(
        getInstancePublicLink({ id: instance.id, visibility: preferences.shareInstance }),
      );
    } catch {
      notifyError({
        title: ERROR,
        message: 'Não foi possível alterar as configurações de compartilhamento desta carteira no momento',
      });
    }
  };

  const handleShareInstance = () => {
    if (preferences.shareInstance) {
      setPreferences({
        ...preferences,
        shareAllInstanceData: false,
        shareInstanceComposition: false,
        shareInstanceVolume: false,
      });
    }
    setPreferences({ ...preferences, shareInstance: !preferences.shareInstance });
  };

  const handleShareInstanceData = () => {
    setPreferences({
      ...preferences,
      shareAllInstanceData: !preferences.shareAllInstanceData,
      shareInstanceComposition: !preferences.shareAllInstanceData,
      shareInstanceVolume: !preferences.shareAllInstanceData,
    });
  };

  const handleCopyButtonClick = () => {
    navigator.clipboard.writeText(publicLink);
    notifySuccess({
      title: SUCCESS,
      message: 'Link copiado com sucesso para sua área de transferência',
    });
  };

  const copyAdornment = (
    <InputAdornment position="end" className={classes.icon}>
      <ArrowTooltip
        title={(
          <div>
            Clique aqui para copiar o link
          </div>
                  )}
        interactive
        disableFocusListener
        disableTouchListener
      >
        <IconButton
          size="small"
          color="inherit"
          onClick={handleCopyButtonClick}
          aria-label="copy"
        >
          <FileCopyOutlinedIcon />
        </IconButton>
      </ArrowTooltip>
    </InputAdornment>
  );

  return (
    <Dialog
      disableBackdropClick
      open
      fullWidth
      maxWidth={false}
      classes={{ paperWidthFalse: classes.dialog }}
    >
      <CloseDialogButton onClose={onClose} />
      <DialogTitle disableTypography className={classes.dialogTitle}>
        Compartilhar Carteira
      </DialogTitle>
      <DialogContent className={classes.dialogContent}>
        <DialogContentText className={classes.contentText} component="div">
          Você acha que essa carteira é interessante para alguém que você conheça?
        </DialogContentText>
        <form
          onSubmit={handleSubmit}
        >
          <FormControlLabel
            classes={{
              root: classes.formLabelRoot,
              label: classes.formLabelText,
            }}
            control={(
              <Switch
                color="primary"
                id="switch-public"
                onChange={handleShareInstance}
                checked={preferences.shareInstance}
              />
            )}
            label="Tornar minha carteira visível para outros investidores"
          />
          <FormControlLabel
            classes={{
              root: classes.formLabelRoot,
              label: classes.formLabelText,
            }}
            control={(
              <Switch
                color="primary"
                disabled={!preferences.shareInstance}
                checked={preferences.shareAllInstanceData}
                onChange={handleShareInstanceData}
                id="switch-wallet-info"
              />
            )}
            label="Compartilhar todas as informações da carteira"
          />
          <Collapse in={!preferences.shareAllInstanceData}>
            <div className={classes.checkboxesContainer}>
              <FormControlLabel
                classes={{
                  root: classes.formLabelRoot,
                  label: classes.formLabelText,
                }}
                control={(
                  <Checkbox
                    color="primary"
                    checked={preferences.shareInstanceComposition}
                    onChange={() => setPreferences({
                      ...preferences,
                      shareInstanceComposition: !preferences.shareInstanceComposition,
                    })}
                    name="show-composition"
                    disabled={!preferences.shareInstance}
                  />
              )}
                label="Mostrar ativos"
              />
              <FormControlLabel
                classes={{
                  root: classes.formLabelRoot,
                  label: classes.formLabelText,
                }}
                control={(
                  <Checkbox
                    color="primary"
                    checked={preferences.shareInstanceVolume}
                    onChange={() => setPreferences({
                      ...preferences,
                      shareInstanceVolume: !preferences.shareInstanceVolume,
                    })}
                    name="show-volume"
                    disabled={!preferences.shareInstance}
                  />
              )}
                label="Mostrar volume financeiro"
              />
            </div>
          </Collapse>
          <Container classes={{ root: classes.containerRoot }}>
            <Button
              id="share-link-dialog-confirm-click"
              type="submit"
              color="primary"
              variant="contained"
              autoFocus
              disabled={instance.reloadInstance}
            >
              Confirmar
              {instance.reloadInstance && (
              <CircularProgress size={24} className={classes.buttonLoading} />
              )}
            </Button>
          </Container>
          <div className={classes.contentText}>
            Compartilhe o link abaixo e eles poderão investir também!
          </div>
          <TextField
            id="share-dialog-link"
            name="share-dialog-link"
            placeholder="Link para compartilhar"
            value={publicLink}
            className={classes.textField}
            variant="outlined"
            InputProps={{
              endAdornment: copyAdornment,
              readOnly: true,
            }}
            disabled
            fullWidth
          />
        </form>
      </DialogContent>
    </Dialog>
  );
};

ShareWalletLinkDialog.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  instance: PropTypes.object.isRequired,
  notifyError: PropTypes.func.isRequired,
  notifySuccess: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  updateInstance: PropTypes.func.isRequired,
};

export default ShareWalletLinkDialog;
