import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Box, Typography } from '@material-ui/core';
import iconHelp from 'common/assets/images/icon-help-tooltip.svg';
import ArrowTooltip from '../ArrowTooltip';

const TitleTooltip = ({
  classes,
  label,
  fontWeight,
  tooltip,
  tooltipSide,
  color,
  variant,
}) => {
  const isTooltipRightSide = tooltipSide === 'right';

  const renderTooltipIcon = () => (
    <ArrowTooltip
      className={classes.tooltip}
      title={(
        <div style={{ textAlign: 'center' }}>
          {tooltip.text}
        </div>
      )}
      placement="top"
    >
      <img
        className={classNames('tooltipIcon', classes.tooltipIcon)}
        src={iconHelp}
        alt="Tooltip"
      />
    </ArrowTooltip>
  );

  return (
    <Box display="inline-block">
      <Typography
        className={classNames(
          classes.label,
          classes[fontWeight],
        )}
        color={color}
        variant={variant}
      >
        {tooltip.text && !isTooltipRightSide && renderTooltipIcon()}
        {label}
        {tooltip.text && isTooltipRightSide && renderTooltipIcon()}
      </Typography>
    </Box>
  );
};

TitleTooltip.propTypes = {
  classes: PropTypes.object.isRequired,
  label: PropTypes.any,
  fontWeight: PropTypes.string,
  textAlign: PropTypes.string,
  tooltipSide: PropTypes.string,
  tooltip: PropTypes.object,
  color: PropTypes.string,
  variant: PropTypes.string,
};

TitleTooltip.defaultProps = {
  label: '',
  fontWeight: 'medium',
  tooltipSide: 'right',
  textAlign: 'left',
  tooltip: {},
  color: 'textSecondary',
  variant: 'h6',
};

export default TitleTooltip;
