export const DEFAULT_SETUP_DATA = {
  code: 'default_setup',
  description: 'Custos zerados',
  operational_limit: 10000000,
  other_absolute_brokerage_tax: 0,
  other_percentual_brokerage_tax: 0,
  spot_absolute_brokerage_tax: 0,
  spot_percentual_brokerage_tax: 0.000300,
  option_absolute_brokerage_tax: 0,
  option_percentual_brokerage_tax: 0,
  ibov_contract_day_trade_brokerage_tax: 0,
  ibov_mini_day_trade_brokerage_tax: 0,
  ibov_contract_position_brokerage_tax: 0,
  ibov_mini_position_brokerage_tax: 0,
  dollar_contract_position_brokerage_tax: 0,
  dollar_contract_day_trade_brokerage_tax: 0,
  dollar_mini_position_brokerage_tax: 0,
  dollar_mini_day_trade_brokerage_tax: 0,
  position_trading_tax: 0,
  position_liquidation_tax: 0,
  position_register_tax: 0,
  position_other_taxes: 0,
  day_trade_trading_tax: 0,
  day_trade_liquidation_tax: 0,
  day_trade_register_tax: 0,
  day_trade_other_taxes: 0,
  iss_tax: 0,
  custody_tax: 0,
};

export const EMPTY_SETUP_DATA = {
  operational_limit: 10000000,
  other_absolute_brokerage_tax: 0,
  other_percentual_brokerage_tax: 0,
  spot_absolute_brokerage_tax: 0,
  spot_percentual_brokerage_tax: 0.000300,
  option_absolute_brokerage_tax: 0,
  option_percentual_brokerage_tax: 0,
  ibov_contract_day_trade_brokerage_tax: 0,
  ibov_mini_day_trade_brokerage_tax: 0,
  ibov_contract_position_brokerage_tax: 0,
  ibov_mini_position_brokerage_tax: 0,
  dollar_contract_position_brokerage_tax: 0,
  dollar_contract_day_trade_brokerage_tax: 0,
  dollar_mini_position_brokerage_tax: 0,
  dollar_mini_day_trade_brokerage_tax: 0,
  position_trading_tax: 0,
  position_liquidation_tax: 0,
  position_register_tax: 0,
  position_other_taxes: 0,
  day_trade_trading_tax: 0,
  day_trade_liquidation_tax: 0,
  day_trade_register_tax: 0,
  day_trade_other_taxes: 0,
  iss_tax: 0,
  custody_tax: 0,
};

export const getSetupByCode = ({ code, setups }) => {
  return setups.find((setup) => setup.code === code);
};

export default null;
