import isEmpty from 'lodash/isEmpty';
import last from 'lodash/last';
import api from 'common/api';
import { isStatusActive } from './plan';
import { isSamePlanCode } from './validation';

import { PLAN_UPDATE_BUTTON_PARAMS } from './constants';

export const getMoreCheaperPlanValue = (plans = [0]) => {
  const { value } = plans[0];

  return plans.reduce((prevValue, nextValue) => {
    if (nextValue.value < prevValue) {
      return nextValue.value;
    }

    return prevValue;
  }, value);
};

export const getPlanByType = ({ type, subscriptions }) => {
  return subscriptions.find((sub) => sub.type.toLowerCase() === type.toLowerCase());
};

export const getPlanCodeByType = ({ type, subscriptions }) => {
  const result = getPlanByType({ type, subscriptions });

  return result ? result.plan_code : '';
};

export const getCorrectPlanByCode = ({ planCode, listPlans = [] }) => {
  return listPlans.find((plan) => plan.code.toLowerCase() === planCode.toLowerCase());
};

export const getPlanLimit = ({ planCode, listPlans }) => {
  const plan = getCorrectPlanByCode({ planCode, listPlans });

  return plan && parseFloat(plan.extra_data.plan_limit);
};

export const getPlanFromSubscriptions = ({ planCode, subscriptions }) => {
  return subscriptions.find((subscription) => isSamePlanCode(planCode, subscription.plan_code));
};

export const getPlansByStrategy = ({ plans, strategyId }) => (
  Object.values(plans).filter((plan) => plan.extra_data.strategy_id === strategyId));

export const getCorrectCouponPrice = (coupon) => {
  return (coupon.price_cents * -1) / 100;
};

export const getDiscountValue = (coupon) => {
  return coupon.price_cents * -1;
};

export const getDiscountCoupon = (subscription) => {
  if (isEmpty(subscription.items)) {
    return null;
  }
  return subscription.items[0];
};

export const getAllUserPlansData = async (subscriptions, plans, getPlan) => {
  const planSubscriptions = subscriptions.filter((sub) => {
    return Object.values(plans).find((plan) => {
      return sub.type === plan.type;
    });
  });

  if (!isEmpty(planSubscriptions)) {
    const toRenderPlans = [];
    const plansData = await Promise.all(planSubscriptions.map((plan) => getPlan(plan.plan_code)));
    planSubscriptions.forEach((plan) => {
      const data = plansData.find((item) => item.type === plan.type);
      toRenderPlans.push({ ...data, subscription: plan });
    });

    return toRenderPlans;
  }
  return [];
};

export const getActivePlans = (plans) => (
  plans.filter((plan) => isStatusActive(plan.subscription.status))
);

export const getRenewPlans = (plans) => (
  plans.filter((plan) => plan.subscription.last_renew_date !== null)
);

export const findActivePlan = (plans) => {
  const activePlans = getActivePlans(plans);
  const renewPlans = getRenewPlans(plans);

  return isEmpty(activePlans) ? [last(renewPlans)] : activePlans;
};

export const getSubscriptionByPlanCode = ({ subscriptions, planCode }) => {
  return subscriptions.filter((subscription) => {
    return typeof planCode === 'object'
      ? planCode.includes(subscription.plan_code)
      : subscription.plan_code === planCode;
  });
};

export const signPlan = (plan) => api.plans.signPlan(plan);

export const removePlanByStrategyId = ({ plans, strategyId }) => {
  return plans.filter((plan) => {
    const { strategy_id } = plan.extra_data;

    return typeof strategyId === 'object'
      ? !strategyId.includes(strategy_id)
      : strategyId !== strategy_id;
  });
};

export const isPlanDowngrade = (planText) => planText === PLAN_UPDATE_BUTTON_PARAMS.downgrade.text;

export default null;
