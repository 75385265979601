import accounting from 'accounting';

export const onlyNumbers = (value) => (
  value
    ? String(value).replace(/[^\d]/g, '')
    : '');

export const isNegativeValue = (value) => {
  return /^-/.test(value);
};

export const parseNumber = (value, precision) => {
  if (value === '' || value === '-') return value;

  const negative = isNegativeValue(value);

  const parsedV = onlyNumbers(value).split('');
  if (precision === 0 || parsedV.length === 0) {
    return `${negative ? '-' : ''}${parsedV.join('')}`;
  }

  if (parsedV.length < precision + 1) {
    const padding = [];
    for (let i = 0; i < (precision + 1) - parsedV.length; i += 1) {
      padding.push('0');
    }
    parsedV.splice(0, 0, ...padding);
  }

  parsedV.splice(-(precision), 0, '.');
  return `${negative ? '-' : ''}${parsedV.join('')}`;
};

export const formatNumber = (value, precision = 0) => {
  if (value === '' || value === '-') return value;

  const negative = /^[^-+]*-(--)*[^-+]*$/.test(value);
  let formatedValue = String((Number.parseInt(onlyNumbers(value), 10)));
  if (!precision) {
    return accounting
      .formatNumber(`${negative ? '-' : ''}${formatedValue}`);
  }

  if (formatedValue === '0') {
    return accounting.formatNumber(value);
  }

  if (formatedValue.length <= precision) {
    const numZeros = precision - formatedValue.length;
    formatedValue = `0,${'0'.repeat(numZeros)}${formatedValue}`;
  } else {
    const integerSlice = formatedValue
      .slice(0, formatedValue.length - precision);
    const integerPart = accounting.formatNumber(integerSlice);
    const decimalPart = formatedValue.slice(-precision);
    formatedValue = `${integerPart},${decimalPart}`;
  }

  return `${negative ? '-' : ''}${formatedValue}`;
};

export const formatMoney = (value, precision) => (
  value
    ? accounting.formatMoney(formatNumber(value, precision), 'R$ ')
    : '');

export const getFormattedNamesByComma = (names) => (
  names.join(', ').replace(/, ([^,]*)$/, ' e $1')
);

export const formatPercent = (value, precicion) => {
  return accounting.formatNumber(value, precicion, '%');
};

export const getPublicImage = (logo, path) => {
  return `${process.env.PUBLIC_URL}/${path}/${logo}`;
};

export const getFormattedCurrency = ({ value }) => {
  if (value === 0) return '0,00';
  return parseNumber(formatMoney(value * 100, 2), 2);
};

export const getCapitalizedText = (text) => {
  return text.charAt(0).toUpperCase() + text.substring(1).toLowerCase();
};
