import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';

import { Button } from '@material-ui/core';
import { RemoteFormSubmit } from '../ReduxForm';
import StepButton from './StepButton';
import Steps from './Steps';

const styles = {
  footerContainer: {
    position: 'relative',
    width: '100%',
    lineHeight: 3,
    margin: '20px 0 0 0',
    textAlign: 'center',
  },
  nextStepButton: {
    top: '50%',
    display: 'inline-block',
    position: 'absolute',
    right: '0',
    transform: 'translateY(-50%)',
  },
  previousStepButton: {
    top: '50%',
    left: '0',
    position: 'absolute',
    transform: 'translateY(-50%)',
  },
  customConfirmStyle: {
    '@media(max-width: 768px)': {
      top: '160%',
    },
  },
  steppedDialogFooter: {
    position: 'relative',
  },
};

const SteppedDialogFooter = ({
  classes,
  displayPreviousStep,
  displayNextStep,
  onSetStep,
  onConfirmSteps,
  submittingForm,
  StepsProps,
  formName,
  disableNextButton,
  disableConfirmButton,
  onClickConfirm,
  showDotsSteps,
  NextCustomButton,
  NextCustomConfirmButton,
}) => {
  const { numberOfSteps, currentStep } = StepsProps;
  const isLastStep = numberOfSteps === currentStep;

  const ConfirmButton = (
    <div className={classes.buttonPositioning}>
      <Button
        color="primary"
        variant="contained"
        onClick={onClickConfirm}
        disabled={disableConfirmButton}
      >
        Confirmar
      </Button>
    </div>
  );

  let NextButton = (
    <StepButton
      disableNextButton={disableNextButton}
      submitting={submittingForm}
      action="next"
      {...StepsProps}
      onClick={submittingForm ? onConfirmSteps : onSetStep}
    />
  );
  NextButton = isLastStep
    ? NextCustomConfirmButton || ConfirmButton
    : NextCustomButton || NextButton;

  if (submittingForm) {
    NextButton = (
      <RemoteFormSubmit formName={formName}>
        {NextButton}
      </RemoteFormSubmit>
    );
  }

  return (
    <div className={classNames([
      classes.footerContainer,
      classes.steppedDialogFooter,
    ])}
    >
      {
        displayPreviousStep
          ? (
            <StepButton
              action="previous"
              {...StepsProps}
              onClick={onSetStep}
              className={classes.previousStepButton}
            />
          ) : null
      }
      {showDotsSteps && <Steps {...StepsProps} />}

      <div className={
        classNames(
          classes.nextStepButton,
          isLastStep && classes.customConfirmStyle,
        )
      }
      >
        {
          displayNextStep && NextButton
        }
      </div>
    </div>
  );
};

SteppedDialogFooter.propTypes = {
  classes: PropTypes.any.isRequired,
  displayPreviousStep: PropTypes.bool,
  displayNextStep: PropTypes.bool,
  submittingForm: PropTypes.bool,
  onConfirmSteps: PropTypes.func,
  onSetStep: PropTypes.func.isRequired,
  StepsProps: PropTypes.shape({
    currentStep: PropTypes.number,
    numberOfSteps: PropTypes.number,
  }).isRequired,
  formName: PropTypes.string,
  disableNextButton: PropTypes.bool,
  disableConfirmButton: PropTypes.bool,
  onClickConfirm: PropTypes.func,
  showDotsSteps: PropTypes.bool,
  NextCustomButton: PropTypes.node,
  NextCustomConfirmButton: PropTypes.node,
};

SteppedDialogFooter.defaultProps = {
  formName: '',
  displayPreviousStep: false,
  submittingForm: false,
  displayNextStep: true,
  onConfirmSteps: () => null,
  disableNextButton: false,
  disableConfirmButton: false,
  onClickConfirm: () => null,
  showDotsSteps: true,
  NextCustomButton: null,
  NextCustomConfirmButton: null,
};

export default withStyles(styles)(SteppedDialogFooter);
