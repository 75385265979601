import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Box, Typography } from '@material-ui/core';
import WarningIcon from '@material-ui/icons/Warning';

import {
  TRANSACTION_LOADING_TITLE,
  DEFAULT_LOADING_MSG,
  IS_LOADING,
  IS_SUCCESS,
  IS_ERROR,
  ERROR,
  CONTINUE_TO_REVISION_BUTTON_TEXT,
  SI_ZENDESK_ARTICLES_URL,
  INSTANCES_URL,
} from 'common/utils/constants';
import {
  SENT_ORDER_SUCCESS_TITLE,
  SENT_ORDER_SUCCESS_MSG,
  SENT_ORDER_REJECTED_TITLE,
  SENT_ORDER_REJECTED_MSG_TOP,
  SENT_ORDER_ERROR_MSG,
  CHECK_ORDER,
  ORDER_STATUS,
  STEPS_MANUAL_OPERATION_INITIAL_STATE, MARKET_BOVESPA,
  ORDER_TYPE,
} from 'common/utils/constants/orders';
import { BLOCKED_MOVEMENT, UPDATE_MY_INSTANCE_COMPOSITION } from 'common/utils/constants/positions';
import { useClosedMarket } from 'common/hooks/stocks';
import { INSTANCE_DETAILS_TABS, SECONDARY_TABS_MAP } from 'common/utils/constants/instance';
import { getBlockedMovementDialogText } from 'common/utils/dialog';
import { isWhiteboxInstance } from 'common/utils/instance';
import { concatenateObjects } from 'common/utils/object';
import { onCreateOrder, findRejectedOrder, isInvalidSellOperation } from 'common/utils/orders';
import { checkIfFractionalStock } from 'common/utils/stocks';

import BlueButton from 'common/components/Buttons/BlueButton';
import ClosedMarket from 'common/components/ClosedMarket';
import Link from 'common/components/Link';
import SearchStocks from 'common/components/SearchStocks';
import { SteppedDialogFooter } from 'common/components/Steps';
import StockCardList from 'common/components/StockCardList';
import StockOperationConfirmation from 'common/components/StockOperationConfirmation';
import StockOperationDetails from 'common/components/StockOperationDetails';
import MainSideDialog from '../MainSideDialog';

const ManualOperation = ({
  classes,
  open,
  instance,
  positions,
  stockCode,
  storeItems,
  onClose,
  onSideDialogClose,
  openProgressDialog,
  openPasswordConfirmDialog,
  loadInstanceOrders,
  loadInstanceOrderEvents,
  summarySubTab,
  setSummarySubTab,
  getStock,
}) => {
  const [dataSteps, setDataSteps] = useState(STEPS_MANUAL_OPERATION_INITIAL_STATE);
  const [stockToSearch, setStockToSearch] = useState('');
  const [shouldBlockSellOperation, setShouldBlockSellOperation] = useState(false);
  const className = classes.cardClickable;
  const history = useHistory();
  const isCustomInstance = isWhiteboxInstance(instance?.strategy_id);
  const storeInstance = !isCustomInstance && storeItems.data?.items[instance?.strategy_id];
  const clickedStock = (isCustomInstance
    ? instance?.stocks[stockCode]
    : storeInstance?.stocks[stockCode]) || (positions && positions[stockCode]);
  const {
    currentStep, numberOfSteps, payload, selectedStock,
  } = dataSteps;
  const { isMarketClosed, marketMessage } = useClosedMarket();
  useEffect(() => {
    if (clickedStock && open) {
      const loadStock = async () => {
        let loadedStock;
        if (!clickedStock.trading_lot_size) {
          loadedStock = await getStock(clickedStock.code || clickedStock.stock_code);
        }

        const tradingLotSizeObj = loadedStock ? {
          trading_lot_size: loadedStock.trading_lot_size,
        } : {};

        setDataSteps((oldDataSteps) => ({
          ...oldDataSteps,
          selectedStock: {
            ...clickedStock,
            ...tradingLotSizeObj,
          },
        }));
      };

      setDataSteps({
        ...STEPS_MANUAL_OPERATION_INITIAL_STATE,
        currentStep: 2,
        selectedStock: clickedStock,
      });
      loadStock();
    }
  }, [clickedStock, getStock, open]);

  const setStep = ({
    step,
    stock = selectedStock || {},
    dataStepsParam = dataSteps,
  }) => {
    const isBackToSecondStep = currentStep === 3 && step === 2;
    const resetPayloadValue = isBackToSecondStep && payload.bestAvailablePriceToggled;

    setDataSteps({
      ...dataStepsParam,
      currentStep: step,
      selectedStock: stock,
      payload: {
        ...dataStepsParam.payload,
        value: resetPayloadValue ? null : dataStepsParam.payload.value,
      },
    });

    if (isBackToSecondStep && shouldBlockSellOperation) setShouldBlockSellOperation(false);
  };

  const resetStep = () => {
    setTimeout(() => setDataSteps(STEPS_MANUAL_OPERATION_INITIAL_STATE), 200);
  };

  const onSetStep = (step) => setStep({ step });

  const onStockOrderSubmit = async () => {
    const extraButton = (
      <Box
        mt={2}
        onClick={() => {
          setDataSteps(STEPS_MANUAL_OPERATION_INITIAL_STATE);
          onClose();
        }}
      >
        <Typography
          className={classes.extraButton}
        >
          Enviar nova ordem
        </Typography>
      </Box>
    );

    const onFinishOperationClick = async (isOrderError = false) => {
      setDataSteps(STEPS_MANUAL_OPERATION_INITIAL_STATE);
      onSideDialogClose();
      if (!isOrderError) {
        if (summarySubTab !== SECONDARY_TABS_MAP.detailedPosition.name) {
          setSummarySubTab({
            tab: SECONDARY_TABS_MAP.detailedPosition.name,
            isScroll: true,
          });
        }
      }
    };

    const handleOpenLoadingDialog = () => {
      openProgressDialog({
        state: IS_LOADING,
        title: TRANSACTION_LOADING_TITLE,
        message: DEFAULT_LOADING_MSG,
        showCloseIcon: false,
        closeOnClickBackdrop: false,
        onClose,
      });
    };

    const handleOpenSuccessOrderDialog = () => {
      openProgressDialog({
        state: IS_SUCCESS,
        title: SENT_ORDER_SUCCESS_TITLE,
        message: SENT_ORDER_SUCCESS_MSG,
        showButton: true,
        buttonMessage: CHECK_ORDER,
        buttonClick: onFinishOperationClick,
        closeAfterClick: true,
        showCloseIcon: false,
        closeOnClickBackdrop: false,
        onClose,
        extraButton,
      });
    };

    const handleOpenErrorDialog = () => {
      openProgressDialog({
        state: IS_ERROR,
        title: ERROR,
        message: SENT_ORDER_ERROR_MSG,
        showButton: true,
        buttonClick: () => onFinishOperationClick(true),
        closeAfterClick: true,
        onClose,
        extraButton,
      });
    };

    const handleOpenRejectedOrderDialog = async (orderId) => {
      const { data } = await loadInstanceOrderEvents(instance.id, orderId);
      const rejectionReason = findRejectedOrder(data.orders_events)?.description;

      openProgressDialog({
        state: IS_ERROR,
        title: SENT_ORDER_REJECTED_TITLE,
        showButton: true,
        buttonMessage: CHECK_ORDER,
        buttonClick: onFinishOperationClick,
        closeAfterClick: true,
        message: (
          <Box my={1}>
            <Typography variant="h5">{SENT_ORDER_REJECTED_MSG_TOP}</Typography>
            <Box my={2} className={classes.description}>{rejectionReason}</Box>
            <Typography variant="h5">
              Acesse este
              {' '}
              <Link
                color="primary"
                fontWeight={700}
                path={`${SI_ZENDESK_ARTICLES_URL}/4419884194327`}
                target="_blank"
                fontSize={16}
              >
                artigo
              </Link>
              {' '}
              para entender todos os motivos de rejeição e como resolver cada um.
            </Typography>
          </Box>
        ),
        onClose,
        extraButton,
      });
    };

    const onConfirmClick = async () => {
      const orderData = {
        entry_exit_or_reversal: ORDER_TYPE[payload.orderType].entry_exit_or_reversal,
        is_market_order: payload.isMarketOrder.toString(),
        order_type: ORDER_TYPE[payload.orderType].key,
        market_name: MARKET_BOVESPA,
        number_of_stocks: payload.numberOfStocks,
        price: payload.isMarketOrder ? 0 : Number(payload.value),
        stock_code: payload.code,
        ticket_order: true,
        is_position: true,
      };

      handleOpenLoadingDialog();

      try {
        const sentOrderId = await onCreateOrder(instance.id, orderData);
        const ordersData = await loadInstanceOrders(instance.id, {}, { reload: true });
        const sentOrder = ordersData.orders.find((order) => order.order_id === sentOrderId);
        if (sentOrder.status === ORDER_STATUS.rejected) {
          handleOpenRejectedOrderDialog(sentOrderId);
        } else {
          handleOpenSuccessOrderDialog();
        }
      } catch (error) {
        handleOpenErrorDialog();
      }
    };

    openPasswordConfirmDialog({ open: true, onConfirmClick });
  };

  const getStockCode = (values) => {
    const code = selectedStock.code || selectedStock.stock_code;
    const isFractionalCode = checkIfFractionalStock({
      numberOfStocks: values.numberOfStocks,
      tradingLotSize: selectedStock.trading_lot_size,
    });
    return isFractionalCode ? `${code}F` : code;
  };

  const onStockOperationsSubmit = (values) => {
    if (isCustomInstance
        && isInvalidSellOperation({
          instance,
          positions,
          values: [{
            number_of_stocks: values.numberOfStocks,
            orderType: ORDER_TYPE[payload.orderType].key,
            stock: selectedStock.code || selectedStock.stock_code,
          }],
        })
    ) setShouldBlockSellOperation(true);

    setDataSteps({
      ...dataSteps,
      payload: {
        ...payload,
        ...values,
        code: getStockCode(values),
      },
      currentStep: currentStep + 1,
    });
  };

  const stepsMap = {
    1: isCustomInstance
      ? (
        <SearchStocks
          className={className}
          instanceId={instance.id}
          onStockClick={(stock) => setStep({ step: 2, stock })}
          stockToSearch={stockToSearch}
          setStockToSearch={setStockToSearch}
          textEmptyState="Selecione o ativo que deseja operar"
        />
      )
      : (
        <StockCardList
          className={classNames(
            className,
            classes.stocksCard,
          )}
          stocks={concatenateObjects(
            positions,
            storeInstance?.stocks,
          )}
          onStockClick={(stock) => setStep({ step: 2, stock })}
        />
      ),
    2: <StockOperationDetails
      instanceId={instance.id}
      onBackButtonClick={setStep}
      stock={selectedStock}
      dataSteps={dataSteps}
      setDataSteps={setDataSteps}
      onSubmit={onStockOperationsSubmit}
    />,
    3: shouldBlockSellOperation ? (
      <Box mb={2} textAlign="center" display="flex" flexDirection="column" alignItems="center">
        <WarningIcon className={classes.icon} />
        <Box mt={1} mb={3}>
          <Typography variant="h3"><b>{BLOCKED_MOVEMENT}</b></Typography>
        </Box>
        {getBlockedMovementDialogText(selectedStock?.code)}
      </Box>
    ) : (
      <StockOperationConfirmation
        data={dataSteps}
        brokerageId={instance.brokerage_id}
      />
    ),
  };

  return (
    <MainSideDialog open={open} onCloseDialog={resetStep}>
      <Box pb="80px">
        <Box mb={1}>
          <Typography variant="h2">Operação manual</Typography>
        </Box>

        { isMarketClosed
          && (
          <Box mt={5}>
            <ClosedMarket message={marketMessage} />
          </Box>
          )}

        {!isMarketClosed && currentStep === 1 && (
          <Box mb={2}>
            <Typography variant="h6">
              Escolha qual ativo
              { isCustomInstance ? ' ' : ' da carteira '}
              você deseja enviar ordens de compra ou venda
            </Typography>
          </Box>
        )}

        {!isMarketClosed
          && (
          <Box my={3}>
            { stepsMap[currentStep] }
          </Box>
          )}

        {!isMarketClosed && currentStep !== 1 && (
          <Box pt={2}>
            <SteppedDialogFooter
              displayPreviousStep={currentStep === numberOfSteps}
              onSetStep={onSetStep}
              StepsProps={{ currentStep, numberOfSteps }}
              showDotsSteps={false}
              NextCustomButton={(
                <BlueButton
                  variant="contained"
                  color="primary"
                  form="manual-operation-form"
                  type="submit"
                  disabled={dataSteps.error || !payload?.value}
                >
                  {CONTINUE_TO_REVISION_BUTTON_TEXT}
                </BlueButton>
              )}
              NextCustomConfirmButton={(
                <BlueButton
                  variant="contained"
                  color="primary"
                  onClick={shouldBlockSellOperation
                    ? () => {
                      onSideDialogClose();
                      history.replace(`${INSTANCES_URL}/${instance.id}/${INSTANCE_DETAILS_TABS.SETTINGS}`);
                      setShouldBlockSellOperation(false);
                      setDataSteps(STEPS_MANUAL_OPERATION_INITIAL_STATE);
                    }
                    : onStockOrderSubmit}
                >
                  {shouldBlockSellOperation ? UPDATE_MY_INSTANCE_COMPOSITION : 'Continuar para senha'}
                </BlueButton>
              )}
            />
          </Box>
        )}
      </Box>
    </MainSideDialog>
  );
};

ManualOperation.propTypes = {
  classes: PropTypes.object.isRequired,
  open: PropTypes.bool.isRequired,
  instance: PropTypes.object,
  positions: PropTypes.object.isRequired,
  stockCode: PropTypes.string,
  storeItems: PropTypes.object,
  onClose: PropTypes.func.isRequired,
  openProgressDialog: PropTypes.func.isRequired,
  openPasswordConfirmDialog: PropTypes.func.isRequired,
  loadInstanceOrders: PropTypes.func.isRequired,
  loadInstanceOrderEvents: PropTypes.func.isRequired,
  onSideDialogClose: PropTypes.func.isRequired,
  getStock: PropTypes.func.isRequired,
  summarySubTab: PropTypes.string,
  setSummarySubTab: PropTypes.func,
};

ManualOperation.defaultProps = {
  instance: {},
  stockCode: '',
  storeItems: {},
  summarySubTab: '',
  setSummarySubTab: null,
};

export default ManualOperation;
