import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import Dialog from '@material-ui/core/Dialog';
import withMobileDialog from '@material-ui/core/withMobileDialog';
import DialogContent from '@material-ui/core/DialogContent';
import Typography from '@material-ui/core/Typography';
import CloseDialogButton from 'common/components/Buttons/CloseDialogButton';
import { PLAN_UPDATE_BUTTON_PARAMS, SI_ZENDESK_ARTICLES_URL, RUNNING_STATE } from 'common/utils/constants';
import { getInstanceByStrategyIdAndState } from 'common/utils/instance';
import { getPlanFromSubscriptions, getPlanLimit } from 'common/utils/plans';
import PlansCards from './PlansCards';

const subscribeText = `Os limites são referentes ao valor financeiro máximo
para começar a seguir a carteira. `;

const subscribeSubtitle = 'Quanto você deseja investir na carteira?';

const updateSubtitle = 'Quanto você deseja investir de capital inicial na carteira?';

const WHITE_BOX_DATA = {
  title: 'Carteira personalizada',
  paragraph: 'Com a ferramenta para criação de carteiras customizadas você tem uma infinidade de combinações para facilitar os seus investimentos. Você pode criar quantas carteiras quiser e operar (de acordo com sua assinatura) em modo real ao mesmo tempo, com limite de até 50.000 reais de investimentos.',
};

const PlanSubscriptionDialog = ({
  classes,
  onClose,
  fullScreen,
  update,
  subscriptions,
  strategyId,
  storeItems,
  instances,
  loadInstanceReport,
  isWhiteBox,
}) => {
  const instanceId = getInstanceByStrategyIdAndState({
    instances: Object.values(instances),
    strategyId,
    state: RUNNING_STATE,
  })?.id;

  useEffect(() => {
    if (instanceId) loadInstanceReport(instanceId);
  }, [instanceId, loadInstanceReport]);

  const storeItem = storeItems[strategyId];
  const { plans } = storeItem.extra_data;

  const getButtonUpgradeParams = (plan) => {
    const currentPlan = getPlanFromSubscriptions({
      planCode: storeItem.plan_code,
      subscriptions,
    }) || { plan_code: '' };
    const signedPlanLimit = getPlanLimit({ planCode: currentPlan.plan_code, listPlans: plans });

    if (plan.extra_data.plan_limit < signedPlanLimit) return PLAN_UPDATE_BUTTON_PARAMS.downgrade;

    if (plan.extra_data.plan_limit > signedPlanLimit) return PLAN_UPDATE_BUTTON_PARAMS.upgrade;

    return PLAN_UPDATE_BUTTON_PARAMS.current;
  };

  const getPlansWithCustomParams = () => {
    const plansData = plans.map((plan) => {
      const params = getButtonUpgradeParams(plan);

      return { ...plan, ...params };
    });

    return plansData;
  };

  return (
    <Dialog
      open
      onClose={onClose}
      maxWidth="md"
      fullScreen={fullScreen}
    >
      <CloseDialogButton onClose={onClose} />
      <DialogContent className={classes.content}>
        <Typography variant="h2" className={classes.title}>
          {isWhiteBox ? WHITE_BOX_DATA.title : 'Opções de assinatura'}
        </Typography>
        {isWhiteBox ? (
          <Typography className={classes.subtitle}>
            {WHITE_BOX_DATA.paragraph}
          </Typography>
        ) : (
          <Typography className={classes.subtitle}>
            {update ? updateSubtitle : subscribeSubtitle}
          </Typography>
        )}
        <PlansCards
          plans={update ? getPlansWithCustomParams() : plans}
          onClose={onClose}
          strategyId={strategyId}
          instanceId={instanceId}
          isWhiteBox={isWhiteBox}
        />
        <Typography className={classes.text}>
          <span>{subscribeText}</span>
          {!update && (
          <a
            href={`${SI_ZENDESK_ARTICLES_URL}/4419872312343`}
            target="_blank"
            rel="noopener noreferrer"
            className={classes.link}
          >
            Saiba mais sobre as opções de assinatura.
          </a>
          )}
        </Typography>
      </DialogContent>
    </Dialog>
  );
};

PlanSubscriptionDialog.propTypes = {
  classes: PropTypes.object.isRequired,
  strategyId: PropTypes.number.isRequired,
  instances: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired,
  fullScreen: PropTypes.bool.isRequired,
  update: PropTypes.bool,
  subscriptions: PropTypes.array.isRequired,
  storeItems: PropTypes.object.isRequired,
  loadInstanceReport: PropTypes.func.isRequired,
  isWhiteBox: PropTypes.bool,
};

PlanSubscriptionDialog.defaultProps = {
  update: false,
  isWhiteBox: false,
};

export default withMobileDialog()(PlanSubscriptionDialog);
