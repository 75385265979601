import React from 'react';
import { connect } from 'react-redux';
import { reduxForm, Field } from 'redux-form';
// Material UI
import {
  MenuItem, FormControl,
} from '@material-ui/core';

import {
  required,
  validateDate,
  validatePhonecall,
  dateLessThanCurrent,
} from 'common/utils/validation';
import { maskPhonecall, maskDate } from 'common/utils/normalize';
import { GENDERS } from 'common/utils/constants';
import { InputTextField, SelectField }
  from '../../ReduxForm';

const AccountInfoPersonalForm = () => {
  return (
    <>
      <FormControl fullWidth>
        <Field
          type="text"
          id="profile-name_or_corporate_name"
          name="name_or_corporate_name"
          component={InputTextField}
          validate={required}
          label="Nome"
          disabled
          autoFocus
        />
      </FormControl>
      <FormControl fullWidth>
        <Field
          id="profile-gender"
          name="gender"
          component={SelectField}
          validate={required}
          label="Sexo"
        >
          {GENDERS.map((gender) => (
            <MenuItem key={gender.value} value={gender.value}>
              {gender.label}
            </MenuItem>
          ))}
        </Field>
      </FormControl>
      <FormControl fullWidth>
        <Field
          type="text"
          name="birthday"
          id="profile-birthday"
          component={InputTextField}
          validate={[required, validateDate, dateLessThanCurrent]}
          normalize={maskDate}
          label="Nascimento"
        />
      </FormControl>
      <FormControl fullWidth>
        <Field
          type="text"
          id="profile-main_phone"
          name="main_phone"
          component={InputTextField}
          validate={validatePhonecall}
          {...maskPhonecall}
          label="Telefone"
        />
      </FormControl>
    </>
  );
};

const mapStateToProps = (
  { user: { profile } },
  { onSubmit, initialValues },
) => {
  return {
    initialValues: {
      birthday: profile.data.birthday || '',
      name_or_corporate_name: profile.data.name_or_corporate_name || '',
      main_phone: profile.data.main_phone || '',
      gender: profile.data.gender || '',
      ...initialValues,
    },
    onSubmit,
  };
};

export default connect(mapStateToProps)(reduxForm({
  form: 'personal_info_form',
})(AccountInfoPersonalForm));
