import moment from 'moment';
import groupBy from 'lodash/groupBy';
import {
  DESKTOP,
  MOBILE,
  LOWER_MOBILE,
} from './constants';
import {
  START,
  PRE_SI_PERFORMANCE_ZONE_GROUP_LABEL,
  POST_SI_PERFORMANCE_ZONE_GROUP_LABEL,
  LAUNCH,
} from './constants/chart';
import { ISO_DATE_FORMAT } from './constants/date';

import { findItemByDate, findIndexByDate } from './date';
import { getInitialValue, getNetReturn } from './dailyCumulativePerformance';
import { getValuesMonth } from './instance';

export const getInitialBenchmarkNetReturn = (benchmark) => {
  return benchmark.length ? 0 : null;
};

export const getOnlyWorkDays = (data) => {
  return data.filter((tuple) => (tuple.isWorkday));
};

export const afterStartDateFilter = (index, data) => {
  return data.slice(index, data.length);
};

export const beforeStartDateFilter = (ibov, filterIndex, isHolidayOrWeekendDay) => {
  let finalIbov = ibov;

  for (let i = filterIndex - 1; i >= 0; i -= 1) {
    const currentDate = moment(finalIbov[0].date).subtract(1, 'day');
    finalIbov = [
      {
        dailyCumulativePerformance: 1,
        date: currentDate.format(ISO_DATE_FORMAT),
        isWorkday: !isHolidayOrWeekendDay(currentDate.toDate()),
      },
      ...finalIbov,
    ];
  }

  return { data: finalIbov, initialValue: 1 };
};

export const getFilteredData = (
  data,
  date,
  isHolidayOrWeekendDay,
  isIbov = false,
) => {
  if (!data.length) return { data: [] };
  const { filterIndex, initialValue } = getInitialValue(date, data);
  if (filterIndex > 0) {
    const filteredData = afterStartDateFilter(filterIndex, data);
    return { data: filteredData, initialValue };
  }
  if (filterIndex < 0 && isIbov) {
    return beforeStartDateFilter(data, Math.abs(filterIndex), isHolidayOrWeekendDay);
  }
  return { data, initialValue };
};

export const getChartNetReturn = (data, initialValue) => {
  return data.map((tuple) => ({
    ...tuple,
    netReturn: getNetReturn(tuple.dailyCumulativePerformance, initialValue),
  }));
};

export const getDailyTransactions = (transactions) => {
  const groupedTransactions = groupBy(transactions, (item) => (
    moment(item.datetime).format(ISO_DATE_FORMAT)));

  Object.keys(groupedTransactions).forEach((key) => {
    groupedTransactions[key] = groupedTransactions[key].reduce((total, investment) => {
      if (parseInt(investment.contribution_or_withdrawal, 10)) {
        return total - parseFloat(investment.value);
      }
      return total + parseFloat(investment.value);
    }, 0);
  });
  return groupedTransactions;
};

export const getStartingPoint = (transaction, rawData, initialCapital) => {
  const { date } = rawData[0];
  const dailyPerformance = rawData[0].dailyCumulativePerformance;
  return [
    {
      date: START,
      capital: initialCapital,
      investedCapital: initialCapital,
      isWorkday: true,
    },
    {
      date,
      capital: initialCapital * dailyPerformance + (transaction[date] || 0),
      investedCapital: initialCapital + (transaction[date] || 0),
      isWorkday: true,
    },
  ];
};

export const getCapitalLineData = (array, dailyTransactions, rawData) => {
  for (let index = 1; index < rawData.length; index += 1) {
    const todayTransactions = dailyTransactions[rawData[index].date] || 0;
    const yesterdayCapital = array[index].capital;
    const yesterdayInvestedCapital = array[index].investedCapital;
    const dailyPerformanceFactor = (
      rawData[index].dailyCumulativePerformance / rawData[index - 1].dailyCumulativePerformance
    );
    array.push({
      date: rawData[index].date,
      capital: (yesterdayCapital * dailyPerformanceFactor) + todayTransactions,
      investedCapital: yesterdayInvestedCapital + todayTransactions,
      isWorkday: rawData[index].isWorkday,
    });
  }
  return array.filter((tuple) => (tuple.isWorkday));
};

export const getBaseData = (date, data) => {
  return data.find((item, index) => (
    index < data.length - 1
    && moment(data[index + 1].date).isSame(date)));
};

export const getYTextCenter = ({
  value,
  decreaseLowerMobile = 60,
  screen = DESKTOP,
}) => {
  const textValues = {
    [DESKTOP]: value,
    [MOBILE]: value,
    [LOWER_MOBILE]: value - decreaseLowerMobile,
  };

  return textValues[screen];
};

export const getDefaultChartData = ({ size, screen = 'desktop' }) => {
  const sizes = {
    large: {
      lowerMobile: {
        innerRadius: 105,
        outerRadius: 125,
        height: 260,
      },
      mobile: {
        innerRadius: 125,
        outerRadius: 150,
        height: 330,
      },
      desktop: {
        innerRadius: 125,
        outerRadius: 150,
        height: 330,
      },
    },
    small: {
      lowerMobile: {
        innerRadius: 80,
        outerRadius: 95,
        height: 220,
      },
      mobile: {
        innerRadius: 80,
        outerRadius: 95,
        height: 260,
      },
      desktop: {
        innerRadius: 80,
        outerRadius: 95,
        height: 260,
      },
    },
  };

  return sizes[size][screen];
};

export const getNetReturnGroupedByMonth = (instanceData, yearsWithLastDayMonthObj) => {
  const { data, initialValue } = instanceData;
  if (!data.length) return [];

  const instanceYearMonthsValues = getValuesMonth(data, yearsWithLastDayMonthObj);
  const instanceNetReturnByYear = Object.values(instanceYearMonthsValues).map((year) => {
    return getChartNetReturn(Object.values(year.months), initialValue);
  });
  return instanceNetReturnByYear.reduce((acc, item) => acc.concat(item), []);
};

export const mixInstanceAndBenchmarksData = ({
  instance,
  ibov = [],
  ifix = [],
  smll = [],
  cdi = [],
}) => {
  const data = instance.map((item, index) => {
    return {
      ...item,
      ibovNetReturn: ibov[index]?.netReturn,
      ifixNetReturn: ifix[index]?.netReturn,
      smllNetReturn: smll[index]?.netReturn,
      cdiNetReturn: cdi[index]?.netReturn,
    };
  });

  return [
    {
      date: START,
      netReturn: 0,
      isWorkday: true,
      ibovNetReturn: getInitialBenchmarkNetReturn(ibov),
      ifixNetReturn: getInitialBenchmarkNetReturn(ifix),
      smllNetReturn: getInitialBenchmarkNetReturn(smll),
      cdiNetReturn: getInitialBenchmarkNetReturn(cdi),
    },
    ...data,
  ];
};

export const addTargetWeightToPositionedStockItems = ({ stocks, theoreticalStocks }) => {
  stocks.forEach((stock) => { stock.target = theoreticalStocks[stock.stock_code]?.weight || 0; });
};

export const getWeightlessItems = ({ stocks, theoreticalStocks }) => (
  Object.values(theoreticalStocks).reduce((acc, item) => {
    return (!stocks.find((stock) => stock.stock_code === item.code))
      ? [...acc, { ...item, target: item.weight, weight: 0 }]
      : acc;
  }, [])
);
export const getPerformanceChartZoneLabel = ({ date, launchDate, group }) => {
  if (date === launchDate) return LAUNCH;
  return (date === START || date < launchDate)
    ? PRE_SI_PERFORMANCE_ZONE_GROUP_LABEL[group].label
    : POST_SI_PERFORMANCE_ZONE_GROUP_LABEL;
};

export const getStoreInstanceChartExtraData = ({
  chartData,
  instanceCumulativePerformance,
  launchDate,
  roundedLaunchDate,
}) => {
  const instanceLaunchDateIndex = findIndexByDate({ data: chartData, date: roundedLaunchDate });

  const {
    date,
    dailyCumulativePerformance,
    isWorkday,
  } = findItemByDate({ data: instanceCumulativePerformance, date: launchDate });
  const updatedChartData = chartData.slice();
  updatedChartData[instanceLaunchDateIndex] = {
    ...chartData[instanceLaunchDateIndex],
    date,
    dailyCumulativePerformance,
    isWorkday,
    netReturn: getNetReturn(dailyCumulativePerformance, 1),
  };

  return {
    launchDateRatio: (instanceLaunchDateIndex + 1) / updatedChartData.length,
    launchMonthNetReturn: updatedChartData?.find((entry) => entry.date === launchDate)?.netReturn,
    updatedChartData,
  };
};
