import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import palette from 'common/palette';

const styles = () => ({
  placeholder: {
    borderRadius: 8,
    backgroundColor: palette.default.light,
    animationName: '$pulse',
    animationDuration: '1s',
    animationIterationCount: 'infinite',
  },
  '@keyframes pulse': {
    '0%': { backgroundColor: palette.default.light },
    '50%': { backgroundColor: palette.default.main },
    '100%': { backgroundColor: palette.default.light },
  },
});

const Placeholder = ({
  classes,
  className,
  height,
  width,
}) => (
  <div
    data-testid="placeholder"
    className={classNames(classes.placeholder, className)}
    style={{ height, width }}
  />
);

Placeholder.propTypes = {
  classes: PropTypes.object.isRequired,
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  className: PropTypes.string,
};

Placeholder.defaultProps = {
  className: '',
  width: '100%',
};

export default withStyles(styles)(Placeholder);
