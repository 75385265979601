import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Formik } from 'formik';

import {
  Dialog,
  Typography,
  DialogContent,
  Button,
  Box,
  Grid,
  IconButton,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';

import LoadingButton from 'common/components/Buttons/LoadingButton';
import { checkHasBrokerageNotice } from 'common/utils/brokerage';
import RegisterBrokerageForm, {
  registerBrokerageValidation,
} from 'common/components/Forms/RegisterBrokerageForm';
import Link from 'common/components/Link';
import {
  STEP_STATUS,
  STEP_TYPES,
  SI_ZENDESK_ARTICLES_URL,
} from 'common/utils/constants';
import { BROKERAGE_NOTICES } from 'common/utils/constants/brokerages';

class RegisterBrokerageDialog extends Component {
  handleInsertBrokerage = async (data) => {
    const { onConfirmSubmit, insertBrokerage } = this.props;
    await insertBrokerage(data);

    onConfirmSubmit(STEP_STATUS.ANALYSIS, STEP_TYPES.BROKERAGE);
  };

  submit = async (values) => {
    const {
      brokerages,
      updateStatusRegisterStep,
      openConfirmationDialog,
      openRegisterBrokerageDialog,
    } = this.props;
    const { brokerage, bmfcode } = values;

    const data = {
      brokerage_id: brokerage,
      cblc_bmf_code: bmfcode,
      cblc_bovespa_code: bmfcode,
    };

    const hasBrokerageNotice = checkHasBrokerageNotice(brokerage);

    if (hasBrokerageNotice) {
      const brokerageName = brokerages.data.brokerages[brokerage].name;
      const { hasFreeBrokeragePlanWarn } = BROKERAGE_NOTICES[brokerage];
      return openConfirmationDialog({
        open: true,
        title: `Importante - ${
          hasFreeBrokeragePlanWarn ? 'Corretagem gratuita' : 'Ação necessária'
        }`,
        maxWidth: 'sm',
        responsive: true,
        onConfirmClick: () => this.handleInsertBrokerage(data),
        preventClose: true,
        showCancelButton: false,
        backButton: {
          show: true,
          onClick: () => openRegisterBrokerageDialog(updateStatusRegisterStep, data),
        },
        loadingButton: true,
        content: (
          <div>
            <Box mb={2}>
              <Typography variant="h5" paragraph>
                {hasFreeBrokeragePlanWarn
                  ? 'Caso queira ativar a gratuidade da corretagem na'
                  : 'Para enviar ordens através da corretora'}
                {' '}
                {brokerageName}
                {hasFreeBrokeragePlanWarn
                  ? ', é necessário realizar uma alteração em sua conta na'
                  : ' também é necessário realizar a autorização do roteamento na área logada da'}
                {' '}
                corretora. Para isso, siga os seguintes passos:
              </Typography>

              {BROKERAGE_NOTICES[brokerage].enableBrokerageSteps.map((step) => (
                <Box key={step} mb={0.3}>
                  <Typography variant="h5">
                    &gt;&nbsp;
                    {step}
                  </Typography>
                </Box>
              ))}

              {hasFreeBrokeragePlanWarn && (
                <Box mt={2}>
                  <Typography variant="h5" paragraph>
                    Feito isso, sua corretagem será gratuita. Caso persista
                    alguma dúvida sobre o processo, este
                    {' '}
                    <Link
                      color="primary"
                      fontWeight={700}
                      path={`${SI_ZENDESK_ARTICLES_URL}/1500008005782`}
                      target="_blank"
                    >
                      artigo
                    </Link>
                    {' '}
                    poderá te auxiliar.
                  </Typography>
                </Box>
              )}
            </Box>
          </div>
        ),
      });
    }

    return this.handleInsertBrokerage(data);
  };

  renderForm = ({
    errors,
    values,
    touched,
    handleChange,
    handleBlur,
    handleSubmit,
  }) => {
    const { brokerages, classes, onClose } = this.props;

    const allBrokerages = brokerages.data.brokerages;

    return (
      <form onSubmit={handleSubmit}>
        <Grid item xs={12}>
          <RegisterBrokerageForm
            meta={{
              errors,
              touched,
            }}
            values={values}
            handleChange={handleChange}
            handleBlur={handleBlur}
            brokerages={allBrokerages}
          />
        </Grid>
        <Box my={2}>
          <Typography color="textSecondary" className={classes.subtitle}>
            Me ajude!
            {' '}
            <Link
              color="primary"
              fontWeight={700}
              path={`${SI_ZENDESK_ARTICLES_URL}/4419893550103`}
              target="_blank"
            >
              Qual o número da minha conta na corretora?
            </Link>
          </Typography>
        </Box>

        <Typography color="textSecondary" className={classes.subtitle}>
          O prazo para a integração de sua conta é de até 48 horas úteis.
        </Typography>
        <div className={classes.footer}>
          <Button
            id="register-brokerage-dialog-cancel_button"
            variant="text"
            className={classes.cancelButton}
            onClick={onClose}
          >
            Cancelar
          </Button>
          <LoadingButton
            id="register-brokerage-dialog-confirm_button"
            className={classes.confirmButton}
            loading={brokerages.loading}
            type="submit"
            confirmButtonContent="Confirmar"
          />
        </div>
      </form>
    );
  };

  render() {
    const {
      classes, open, onClose, fullScreen, brokerageData,
    } = this.props;

    const initialValues = {
      ...brokerageData,
      brokerage: brokerageData?.brokerage_id || '',
      bmfcode: brokerageData?.cblc_bmf_code || '',
      bmfcode_confirm: brokerageData?.cblc_bovespa_code || '',
    };

    return (
      <Dialog
        disableBackdropClick
        open={open}
        onClose={onClose}
        classes={{
          paper: classes.dialogPaper,
        }}
        maxWidth="md"
        fullScreen={fullScreen}
      >
        <DialogContent className={classes.dialogContent}>
          <IconButton
            size="small"
            className={classes.closeIcon}
            edge="start"
            color="inherit"
            onClick={onClose}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
          <Box mb={0.5}>
            <Typography variant="h2">Adicionar Corretora</Typography>
          </Box>
          <Typography color="textSecondary" className={classes.subtitle}>
            Atenção! É necessário que a sua conta na SmarttBot tenha o mesmo CPF
            da sua conta na corretora.
          </Typography>
          <div>
            <Formik
              onSubmit={this.submit}
              validate={registerBrokerageValidation}
              initialValues={initialValues}
              render={this.renderForm}
            />
          </div>
        </DialogContent>
      </Dialog>
    );
  }
}

RegisterBrokerageDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
  brokerages: PropTypes.object.isRequired,
  insertBrokerage: PropTypes.func.isRequired,
  fullScreen: PropTypes.bool.isRequired,
  onConfirmSubmit: PropTypes.func.isRequired,
  updateStatusRegisterStep: PropTypes.func.isRequired,
  openConfirmationDialog: PropTypes.func.isRequired,
  openRegisterBrokerageDialog: PropTypes.func.isRequired,
  brokerageData: PropTypes.object,
};

RegisterBrokerageDialog.defaultProps = {
  brokerageData: {},
};

export default RegisterBrokerageDialog;
