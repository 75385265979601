export const whiteboxStyle = {
  width: '100%',
  '@media(min-width: 1201px)': {
    maxWidth: 420,
    marginRight: 15,
  },
};

export const wrapperWhiteboxStyle = {
  '@media(min-width: 1201px)': {
    display: 'flex',
  },
};
