import React from 'react';
import accounting from 'accounting';
import {
  BROKERAGE_STATUS,
  PROFITABILITY,
  NOW_TEXT,
  INSUFFICIENT_BALANCE_MSG,
  INSUFFICIENT_NUMBER_OF_STOCKS_MSG,
  REQUIRED_FIELD_TEXT,
  WEBAPP_1_URL,
  INSTANCES_URL,
  STOPPED_TAB,
  EXECUTING_TAB,
} from 'common/utils/constants';
import { brokeragesHasAtLeastOneStatus, getRealBrokerages } from 'common/utils/brokerage';
import moment from 'moment';
import range from 'lodash/range';
import omit from 'lodash/omit';
import { Box } from '@material-ui/core';
import { getInstanceStocks } from './whitebox';
import { getCurrentDate, getCustomFormattedDate, getYearsFromDates } from './date';
import { getMaxValue, getMinValue, getTotalItems } from './array';
import { BENCHMARKS } from './constants/benchmarks';
import { MONTH_KEYS, ISO_DATE_FORMAT } from './constants/date';
import {
  INSTANCE, INSTANCE_VISIBILITY, NEXT_ROLLOUT_REBALANCE_TEXT, PARAMS_MOUNT_INSTANCE,
} from './constants/instance';
import { required, isBuyOrderType } from './validation';
import { WHITEBOX_STRATEGY_IDS } from './constants/whitebox';

const getConfirmationDialogProps = (title, content) => ({
  open: true,
  title,
  showButtons: false,
  maxWidth: 'sm',
  showCancelButton: false,
  responsive: true,
  content: (
    <Box mb={2}>
      {content}
    </Box>
  ),
});

export const followInstance = ({
  strategyId,
  strategies,
  brokerages: {
    realUserBrokerages,
    matchedUserBrokeragesWithStrategy,
  },
  openFollowInstanceDialog,
  openConfirmationDialog,
  isWhiteBox = false,
  templateId = null,
  templateName = null,
  startFromZero,
}) => {
  const { APPROVED } = BROKERAGE_STATUS;
  const currentStrategy = strategies[strategyId];
  const allowedBrokerages = matchedUserBrokeragesWithStrategy[currentStrategy.id];
  const hasAllowedBrokerages = allowedBrokerages.length > 0;

  if (!hasAllowedBrokerages) {
    openConfirmationDialog(
      getConfirmationDialogProps('Permissão necessária', 'Essa carteira não é permitida para o tipo de corretora autorizada.'),
    );
    return;
  }

  const hasApprovedBrokerages = brokeragesHasAtLeastOneStatus({
    brokerages: allowedBrokerages,
    status: APPROVED,
  });

  if (!hasApprovedBrokerages) {
    openConfirmationDialog(
      getConfirmationDialogProps('Aprovação necessária', 'Aguarde a aprovação da sua corretora para iniciar uma carteira.'),
    );
    return;
  }

  if (Object.values(realUserBrokerages).length > 0) {
    const strategyAllowedBrokerages = getRealBrokerages(currentStrategy.brokerages);

    openFollowInstanceDialog({
      strategyId,
      allowedBrokerages: strategyAllowedBrokerages,
      isWhiteBox,
      templateId,
      templateName,
      startFromZero,
    });
  }
};

export const setStocksToInstance = ({ instance, params }) => {
  const instanceStocks = params.PORTFOLIO_stocks.split(',');
  const instanceWeights = params.PORTFOLIO_weights.split(',');
  const instanceLotSizes = params.lot_sizes?.split(',');

  instance.stocks = getInstanceStocks({
    stockList: instanceStocks,
    weightList: instanceWeights,
    lotSizes: instanceLotSizes,
  });

  return instance;
};

export const getMonthByNumberKey = (monthNumber) => {
  return Object.keys(MONTH_KEYS).find((itemMonth) => (
    MONTH_KEYS[itemMonth].key === monthNumber
  ));
};

export const getNumberMonths = ({ months, positiveValue = true }) => {
  return months.reduce((totalNumber, currentMonth) => {
    if (!currentMonth.profitability) return totalNumber;

    if (positiveValue && currentMonth.profitability >= 0) return totalNumber + 1;

    if (!positiveValue && currentMonth.profitability < 0) return totalNumber + 1;

    return totalNumber;
  }, 0);
};

export const getPercentMonthsBetterThanBenchmark = ({
  instanceData = {},
  benchmarkData = {},
  monthsRunning,
}) => {
  const objKeysInstanceData = Object.keys(instanceData);
  const objKeysBenchmarkData = Object.keys(benchmarkData);
  if (objKeysInstanceData.length === 0 || objKeysBenchmarkData.length === 0) {
    return 0;
  }

  const totalMonths = Object.keys(instanceData.years).reduce((total, year) => {
    const yearInstanceObj = instanceData.years[year];
    const yearBenchmarkObj = benchmarkData.years[year];

    const amountMonths = Object.keys(yearInstanceObj.months)
      .reduce((totalMonthsYear, month) => {
        const monthInstanceObj = yearInstanceObj.months[month];
        const monthBenchmarkObj = yearBenchmarkObj?.months[month];

        if (monthInstanceObj?.profitability > monthBenchmarkObj?.profitability) {
          return totalMonthsYear + 1;
        }

        return totalMonthsYear;
      }, 0);

    return total + amountMonths;
  }, 0);

  return totalMonths / Number(monthsRunning);
};

export const getProfitabilityDataWithoutCurrentMonth = (profitabilityData) => {
  const currentYear = moment().year();
  const currentMonth = moment().month();
  const monthName = getMonthByNumberKey(currentMonth);

  const monthsWithoutCurrentMonth = omit(profitabilityData.years[currentYear]?.months, monthName);

  const fulldataWithoutCurrentMonth = {
    ...profitabilityData,
    years: {
      ...profitabilityData.years,
      [currentYear]: {
        ...profitabilityData.years[currentYear],
        months: monthsWithoutCurrentMonth,
      },
    },
  };

  return fulldataWithoutCurrentMonth;
};

export const getInstanceItemYears = (instanceItem, isStore = true) => {
  const currentDate = getCurrentDate();
  const firstYear = moment(isStore
    ? instanceItem?.extra_data?.start_date
    : instanceItem?.createdAt).year();
  const lastYear = currentDate.year();

  return range(firstYear, lastYear + 1);
};

export const getObjectBodyYearMonth = ({
  acc,
  year,
  month,
  monthData,
}) => {
  return {
    ...acc,
    [year]: {
      ...acc[year],
      months: {
        ...acc[year]?.months,
        [month]: monthData,
      },
    },
  };
};

export const getMonthsWithLastDayGroupByYear = ({
  yearsList,
  enableCurrentMonthDate = false,
  currentDayLastMonth = false,
}) => {
  return yearsList.reduce((accYear, year) => {
    const lastDayMonthsYear = Object.keys(MONTH_KEYS).reduce((accMonth, month) => {
      let lastDay = moment(new Date(year, MONTH_KEYS[month].key + 1, 0));

      if (enableCurrentMonthDate) {
        const currentDate = getCurrentDate();
        const isCurrentMonth = MONTH_KEYS[month].key === currentDate.month();

        if (isCurrentMonth && lastDay.isAfter(currentDate)) {
          lastDay = currentDayLastMonth ? currentDate : currentDate.clone().subtract(1, 'day');
        }
      }

      return getObjectBodyYearMonth({
        acc: accMonth,
        year,
        month,
        monthData: moment(lastDay).format(ISO_DATE_FORMAT),
      });
    }, {});

    return { ...accYear, ...lastDayMonthsYear };
  }, {});
};

export const getLastDayDate = (arrayYearsMonths, index, itemDailyCumulative) => {
  return Object.values(arrayYearsMonths[index].months).find((monthValue) => {
    return monthValue === itemDailyCumulative.date;
  });
};

export const getValuesMonth = (dailyCumulativeData, objYearsMonths) => {
  return dailyCumulativeData.reduce((acc, itemDailyCumulative) => {
    const arrayYearsMonths = Object.values(objYearsMonths);
    let date;

    for (let index = 0; index < arrayYearsMonths.length; index += 1) {
      const lastDayDate = getLastDayDate(arrayYearsMonths, index, itemDailyCumulative);

      if (lastDayDate) {
        date = lastDayDate;
      }
    }

    if (date) {
      const monthNumber = moment(date).month();
      const month = getMonthByNumberKey(monthNumber);
      const year = moment(date).year();

      return getObjectBodyYearMonth({
        acc,
        year,
        month,
        monthData: itemDailyCumulative,
      });
    }

    return acc;
  }, {});
};

export const setProfitability = ({ month, previousMonth, firstMonth = false }) => {
  month.profitability = firstMonth
    ? month.dailyCumulativePerformance - 1
    : month.dailyCumulativePerformance / previousMonth.dailyCumulativePerformance - 1;
};

export const calculateTotalYearProfitability = (months) => {
  const total = Object.values(months).reduce((acc, month) => {
    const value = month.profitability ?? 0;

    return acc * (value + 1);
  }, 1);

  return total - 1;
};

export const setProfitabilityToMonths = ({
  instanceYearMonthsValues,
  considerFirstMonth = false,
}) => {
  return Object.keys(instanceYearMonthsValues).reduce((accYear, yearItem, indexYear) => {
    const currentYearValues = instanceYearMonthsValues[yearItem];
    const { months } = currentYearValues;

    const monthValues = Object.keys(months).reduce(
      (acc, month, index, array) => {
        const currentMonthData = { ...months[array[index]] };
        const isFirstMonth = index === 0;
        const isFirstYear = indexYear === 0;

        if (isFirstMonth && !isFirstYear) {
          const previousYear = Number(yearItem - 1);
          const monthsPreviousYear = Object.values(instanceYearMonthsValues[previousYear].months);
          const lengthObj = Object.keys(monthsPreviousYear).length;
          const previousYearMonthData = monthsPreviousYear[lengthObj - 1];

          setProfitability({ month: currentMonthData, previousMonth: previousYearMonthData });
        }

        if (!isFirstMonth) {
          const previousMonthData = months[array[index - 1]];
          setProfitability({ month: currentMonthData, previousMonth: previousMonthData });
        }

        if (considerFirstMonth && isFirstMonth && isFirstYear) {
          setProfitability({ month: currentMonthData, firstMonth: considerFirstMonth });
        }

        return {
          ...acc,
          [month]: {
            ...currentMonthData,
          },
        };
      }, {},
    );

    const objMonthValues = Object.values(monthValues);
    const yearProfitability = calculateTotalYearProfitability(monthValues);

    const bestMonth = getMaxValue({
      items: objMonthValues,
      value: PROFITABILITY.name,
      initialValue: 0,
    });
    const worstMonth = getMinValue({
      items: objMonthValues,
      value: PROFITABILITY.name,
      initialValue: 0,
    });

    const numberPositiveMonths = getNumberMonths({ months: objMonthValues });
    const numberNegativeMonths = getNumberMonths({ months: objMonthValues, positiveValue: false });

    return {
      ...accYear,
      [yearItem]: {
        months: monthValues,
        yearProfitability,
        bestMonth,
        worstMonth,
        numberPositiveMonths,
        numberNegativeMonths,
      },
    };
  }, {});
};

export const getPerformanceGeneralData = (profitabilityMonthData) => {
  const totalPositiveMonths = getTotalItems({
    items: profitabilityMonthData,
    value: 'numberPositiveMonths',
  });
  const totalNegativeMonths = getTotalItems({
    items: profitabilityMonthData,
    value: 'numberNegativeMonths',
  });
  const bestMonthGeneral = getMaxValue({
    items: profitabilityMonthData,
    value: 'bestMonth',
  });
  const worstMonthGeneral = getMinValue({
    items: profitabilityMonthData,
    value: 'worstMonth',
  });
  const positiveMonthsPercent = totalPositiveMonths / (totalPositiveMonths + totalNegativeMonths);

  return {
    bestMonthGeneral,
    worstMonthGeneral,
    positiveMonthsPercent,
  };
};

export const getOnlyYearsWithAtLeastOneMonth = (
  { profitabilityMonthData },
  considerFirstMonth,
  isBenchmark,
) => {
  return Object.keys(profitabilityMonthData).reduce((
    acc,
    currentYearData,
    index,
  ) => {
    const currentMonthsYear = profitabilityMonthData[currentYearData].months;

    if (
      index === 0
      && !considerFirstMonth
      && !isBenchmark
      && Object.keys(currentMonthsYear).length <= 1
    ) return acc;

    return {
      ...acc,
      [currentYearData]: profitabilityMonthData[currentYearData],
    };
  }, {});
};

export const getProfitabilityData = ({
  data = [],
  yearsList = [],
  isCustomBacktest = false,
  considerFirstMonth = false,
  isBenchmark = false,
}) => {
  if (yearsList.length === 0 || data.length === 0) return {};

  const yearsWithLastDayMonthObj = getMonthsWithLastDayGroupByYear({
    yearsList,
    enableCurrentMonthDate: true,
    currentDayLastMonth: !isCustomBacktest,
  });
  const instanceYearMonthsValues = getValuesMonth(data, yearsWithLastDayMonthObj);
  const profitabilityMonthData = setProfitabilityToMonths({
    instanceYearMonthsValues,
    considerFirstMonth,
  });

  const finalProfitability = getOnlyYearsWithAtLeastOneMonth(
    { profitabilityMonthData }, considerFirstMonth, isBenchmark,
  );

  const performanceGeneralData = getPerformanceGeneralData(Object.values(profitabilityMonthData));

  return {
    years: finalProfitability,
    ...performanceGeneralData,
  };
};

export const getInstanceByStrategyIdAndState = ({
  instances, strategyId, state = null,
}) => instances.find(
  (instance) => (state
    ? instance.strategy_id === strategyId && instance.state === state
    : instance.strategy_id === strategyId
  ),
);

export const getExecutionTimeByValue = (value) => {
  return Object.values(PARAMS_MOUNT_INSTANCE).find((item) => item.value === value);
};

export const getExecutionTimeText = (executionTime) => {
  return executionTime === PARAMS_MOUNT_INSTANCE.mountNow.value
    ? NOW_TEXT
    : NEXT_ROLLOUT_REBALANCE_TEXT;
};

export const validateIfInstancePortfolio = (type) => type === INSTANCE;

export const getTextMinOperation = (equity) => {
  const maxOperationValue = Math.floor(equity * 0.02);
  const formattedValue = accounting.formatNumber(parseFloat(maxOperationValue), 2);

  return `Valor mínimo de R$ 5,00 e máximo de R$ ${formattedValue} (2% do patrimônio)`;
};

export const getOrderValueRangeText = (value) => {
  const minValueString = accounting.formatNumber(parseFloat(0.98 * value), 2);
  const maxValueString = accounting.formatNumber(parseFloat(1.02 * value), 2);

  return `Valor deve estar entre R$ ${minValueString} e R$ ${maxValueString}`;
};

export const validateMinOperation = (values, equity) => {
  const errors = {};
  const value = Number(values.value);

  if (required(value) || (value < 5 || value > Math.floor(equity * 0.02))) {
    errors.value = true;
  }

  return errors;
};

export const getNumberOfStocksError = ({
  position,
  orderType,
  totalOrderVolume,
  balance,
  numberOfStocks,
}) => {
  const positionNumberOfStocks = Number(position?.number_of_stocks || 0);
  let message = '';

  if (numberOfStocks === '') {
    message = REQUIRED_FIELD_TEXT;
  }

  if (isBuyOrderType(orderType) && totalOrderVolume > balance) {
    message = INSUFFICIENT_BALANCE_MSG;
  }

  if (!isBuyOrderType(orderType) && Number(numberOfStocks) > positionNumberOfStocks) {
    message = INSUFFICIENT_NUMBER_OF_STOCKS_MSG;
  }

  return message;
};

export const validateOrderValueRange = ({
  values,
  currentStockPrice,
  position,
  orderType,
  totalOrderVolume,
  balance,
}) => {
  const errors = {};
  const value = Number(values.value);
  const numberCurrentStockPrice = Number(currentStockPrice);

  if (required(value)
      || (value < 0.98 * numberCurrentStockPrice || value > 1.02 * numberCurrentStockPrice)
  ) {
    errors.value = true;
    errors.message = getOrderValueRangeText(numberCurrentStockPrice);
  }

  const numberOfStocksErrorMessage = getNumberOfStocksError({
    position,
    orderType,
    totalOrderVolume,
    balance,
    numberOfStocks: values.numberOfStocks,
  });

  if (numberOfStocksErrorMessage) {
    errors.numberOfStocks = numberOfStocksErrorMessage;
  }

  return errors;
};

export const isWhiteboxInstance = (strategyId) => WHITEBOX_STRATEGY_IDS.includes(strategyId);

export const getCalculatedVFM = ({
  brokerageTax,
  orderVolume,
}) => (brokerageTax * 100) / (7 - orderVolume * 6);

export const getStocksAndWeightsListFromInstance = (instance) => {
  const stockList = instance.params.PORTFOLIO_stocks.split(',');
  const weightList = instance.params.PORTFOLIO_weights.split(',');

  return { stockList, weightList };
};

export const getInstancePublicLink = ({ id, visibility }) => {
  const { PRIVATE } = INSTANCE_VISIBILITY;
  return (visibility === PRIVATE || visibility === null)
    ? ''
    : `${WEBAPP_1_URL}${INSTANCES_URL}/${id}`;
};

export const isInstanceOwnedByUser = (userLogin, instanceLogin) => userLogin === instanceLogin;
export const checkIsExecutingTab = (tab) => tab === EXECUTING_TAB;
export const checkIsStoppedTab = (tab) => tab === STOPPED_TAB;

export const getYearProfitability = ({ data, year }) => data.years[year]?.yearProfitability;

export const reduceAnnualAlphaProfitability = (data) => data.reduce(
  (yearlyAcc, month) => yearlyAcc * (1 + month.profitability), 1,
);

export const getAccumulatedProfitability = ({ data, date }) => {
  const launchYear = moment(date).year().toString();
  const accumulatedYearsKeys = Object.keys(data.years).filter((year) => year >= launchYear);
  return accumulatedYearsKeys.reduce((totalAcc, year) => {
    if (year === launchYear) {
      const accumulatedMonthsData = Object
        .values(data.years[year].months)
        .filter((month) => month.date >= date);
      return reduceAnnualAlphaProfitability(accumulatedMonthsData);
    }

    return reduceAnnualAlphaProfitability(Object.values(data.years[year].months)) * totalAcc;
  }, 1);
};

export const getInstanceRelativeAnnualProfitabilityText = (benchmarkName) => `Rentabilidade no ano relativa ao ${benchmarkName} no mesmo período. É calculado pela diferença entre a rentabilidade da sua carteira e a rentabilidade do ${benchmarkName}.`;
export const getInstanceRelativeLaunchProfitabilityText = (benchmarkName) => `Rentabilidade da carteira relativa ao ${benchmarkName} no período de execução. É calculado pela diferença entre a rentabilidade da carteira desde o lançamento e a rentabilidade do ${benchmarkName} no mesmo período.`;
export const getInstanceRelativeStartProfitabilityText = (benchmarkName) => `Rentabilidade da carteira relativa ao ${benchmarkName} desde o início da execução. É a diferença entre a rentabilidade da sua carteira e a rentabilidade do ${benchmarkName}.`;

export const calculateAlphaMetrics = ({ profitabilityData, benchmarkData, date }) => {
  const currentYear = moment().year();
  const currentYearProfitability = getYearProfitability({
    data: profitabilityData,
    year: currentYear,
  });
  const currentYearBenchmark = getYearProfitability({ data: benchmarkData, year: currentYear });
  const postLaunchProfitability = getAccumulatedProfitability({
    data: profitabilityData,
    date,
  });
  const postLaunchBenchmark = getAccumulatedProfitability({
    data: benchmarkData,
    date,
  });
  return {
    currentAnualAlphaValue: (currentYearProfitability - currentYearBenchmark) * 100,
    currentPostLaunchAlphaValue: (postLaunchProfitability - postLaunchBenchmark) * 100,
  };
};

export const checkIfItsStoreItem = (instance) => !!instance?.extra_data;

export const getAlphaMetricsData = ({
  instance,
  dailyCumulativePerformance,
  benchmark = BENCHMARKS.ibov,
}) => {
  if (!checkIfItsStoreItem(instance)) return {};
  const formattedLaunchDate = getCustomFormattedDate(new Date(instance?.launch), ISO_DATE_FORMAT);
  const instanceDataCumulative = dailyCumulativePerformance[instance.instance_id]?.data;
  const benchmarkDataCumulative = dailyCumulativePerformance[benchmark.instanceId]?.data;

  const yearsList = getYearsFromDates({
    startDate: moment(instanceDataCumulative[0]?.date),
    endDate: moment(),
  });

  return {
    formattedLaunchDate,
    ...calculateAlphaMetrics({
      profitabilityData: getProfitabilityData({
        data: instanceDataCumulative,
        yearsList,
        considerFirstMonth: true,
      }),
      benchmarkData: getProfitabilityData({
        data: benchmarkDataCumulative,
        yearsList,
        isBenchmark: true,
      }),
      date: formattedLaunchDate,
    }),
  };
};
