import api from 'common/api';

export const DUCK_NAME = 'billing';

export const INITIAL_STATE = {
  loading: false,
  discountData: {},
  userDiscountCode: {},
  credit: {},
  checkoutData: {},
  invoiceData: {},
  error: null,
};

export const GET_DISCOUNT_STARTED = `${DUCK_NAME}/GET_DISCOUNT_STARTED`;
export const GET_DISCOUNT_SUCCEED = `${DUCK_NAME}/GET_DISCOUNT_SUCCEED`;
export const GET_DISCOUNT_FAILED = `${DUCK_NAME}/GET_DISCOUNT_FAILED`;

export const getDiscountStarted = () => ({
  type: GET_DISCOUNT_STARTED,
});
export const getDiscountSucceed = (data) => ({
  type: GET_DISCOUNT_SUCCEED,
  data,
});
export const getDiscountFailed = (error) => ({
  type: GET_DISCOUNT_FAILED,
  error,
});

export const getDiscount = (data) => async (dispatch) => {
  dispatch(getDiscountStarted());
  try {
    const response = await api.billing.getDiscount(data.plan, data.code);
    dispatch(getDiscountSucceed(response));
    return Promise.resolve(response);
  } catch (error) {
    dispatch(getDiscountFailed(error));
    return Promise.reject(error);
  }
};

export const GET_CREDIT_STARTED = `${DUCK_NAME}/GET_CREDIT_STARTED`;
export const GET_CREDIT_SUCCEED = `${DUCK_NAME}/GET_CREDIT_SUCCEED`;
export const GET_CREDIT_FAILED = `${DUCK_NAME}/GET_CREDIT_FAILED`;

export const getCreditStarted = () => ({
  type: GET_CREDIT_STARTED,
});
export const getCreditSucceed = (data) => ({
  type: GET_CREDIT_SUCCEED,
  data,
});
export const getCreditFailed = (error) => ({
  type: GET_CREDIT_FAILED,
  error,
});

export const getCredit = (type) => async (dispatch) => {
  dispatch(getCreditStarted());
  try {
    const data = await api.billing.credit({ params: { type } });
    dispatch(getCreditSucceed(data));
    return Promise.resolve(data);
  } catch (error) {
    dispatch(getCreditFailed(error));
    return Promise.reject(error);
  }
};

export const GET_CANCELLATION_CREDIT_STARTED = `${DUCK_NAME}/GET_CANCELLATION_CREDIT_STARTED`;
export const GET_CANCELLATION_CREDIT_SUCCEED = `${DUCK_NAME}/GET_CANCELLATION_CREDIT_SUCCEED`;
export const GET_CANCELLATION_CREDIT_FAILED = `${DUCK_NAME}/GET_CANCELLATION_CREDIT_FAILED`;

export const getCancellationCreditStarted = () => ({
  type: GET_CANCELLATION_CREDIT_STARTED,
});
export const getCancellationCreditSucceed = (data) => ({
  type: GET_CANCELLATION_CREDIT_SUCCEED,
  data,
});
export const getCancellationCreditFailed = (error) => ({
  type: GET_CANCELLATION_CREDIT_FAILED,
  error,
});

export const getCancellationCredit = (type) => async (dispatch) => {
  dispatch(getCreditStarted());
  try {
    const data = await api.billing.cancellation({ params: { type } });
    dispatch(getCreditSucceed(data));
    return Promise.resolve(data);
  } catch (error) {
    dispatch(getCreditFailed(error));
    return Promise.reject(error);
  }
};

export const GET_INVOICES_STARTED = `${DUCK_NAME}/GET_INVOICES_STARTED`;
export const GET_INVOICES_SUCCEED = `${DUCK_NAME}/GET_INVOICES_SUCCEED`;
export const GET_INVOICES_FAILED = `${DUCK_NAME}/GET_INVOICES_FAILED`;

export const getInvoicesStarted = () => ({
  type: GET_INVOICES_STARTED,
});
export const getInvoicesSucceed = (data) => ({
  type: GET_INVOICES_SUCCEED,
  data,
});
export const getInvoicesFailed = (error) => ({
  type: GET_INVOICES_FAILED,
  error,
});

export const getInvoices = () => async (dispatch) => {
  dispatch(getInvoicesStarted());
  try {
    const data = await api.invoices.getAllInvoices();
    dispatch(getInvoicesSucceed(data));
    return Promise.resolve(data);
  } catch (error) {
    dispatch(getInvoicesFailed(error));
    return Promise.reject(error);
  }
};

export const APPLY_DISCOUNT_CODE_STARTED = `${DUCK_NAME}/APPLY_DISCOUNT_CODE_STARTED`;
export const APPLY_DISCOUNT_CODE_SUCCEED = `${DUCK_NAME}/APPLY_DISCOUNT_CODE_SUCCEED`;
export const APPLY_DISCOUNT_CODE_FAILED = `${DUCK_NAME}/APPLY_DISCOUNT_CODE_FAILED`;
export const applyDiscountCodeStarted = () => ({
  type: APPLY_DISCOUNT_CODE_STARTED,
});
export const applyDiscountCodeSucceed = (data) => ({
  type: APPLY_DISCOUNT_CODE_SUCCEED,
  data,
});
export const applyDiscountCodeFailed = (error) => ({
  type: APPLY_DISCOUNT_CODE_FAILED,
  error,
});
export const applyDiscount = (code, data) => async (dispatch) => {
  dispatch(applyDiscountCodeStarted());
  try {
    await api.user.applyDiscountCode(code, data);
    dispatch(applyDiscountCodeSucceed(code));
    return Promise.resolve();
  } catch (error) {
    dispatch(applyDiscountCodeFailed(error));
    return Promise.reject(error);
  }
};

export const CHECKOUT_STARTED = `${DUCK_NAME}/CHECKOUT_STARTED`;
export const CHECKOUT_SUCCEED = `${DUCK_NAME}/CHECKOUT_SUCCEED`;
export const CHECKOUT_FAILED = `${DUCK_NAME}/CHECKOUT_FAILED`;

export const checkoutStarted = () => ({
  type: CHECKOUT_STARTED,
});
export const checkoutSucceed = (data) => ({
  type: CHECKOUT_SUCCEED,
  data,
});
export const checkoutFailed = (error) => ({
  type: CHECKOUT_FAILED,
  error,
});

export const checkout = (plan, data) => async (dispatch) => {
  dispatch(checkoutStarted());
  try {
    const response = await api.billing.checkout(plan, data);
    dispatch(checkoutSucceed(response));
    return Promise.resolve(response);
  } catch (error) {
    dispatch(checkoutFailed(error));
    return Promise.reject(error);
  }
};

const reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GET_DISCOUNT_STARTED:
      return {
        ...state,
        loading: true,
      };
    case GET_DISCOUNT_SUCCEED:
      return {
        ...state,
        loading: false,
        discountData: action.data,
      };
    case GET_DISCOUNT_FAILED:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    case GET_CREDIT_STARTED:
      return {
        ...state,
        loading: true,
      };
    case GET_CREDIT_SUCCEED:
      return {
        ...state,
        loading: false,
        credit: action.data,
      };
    case GET_CREDIT_FAILED:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    case GET_CANCELLATION_CREDIT_STARTED:
      return {
        ...state,
        loading: true,
      };
    case GET_CANCELLATION_CREDIT_SUCCEED:
      return {
        ...state,
        loading: false,
        credit: action.data,
      };
    case GET_CANCELLATION_CREDIT_FAILED:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    case CHECKOUT_STARTED:
      return {
        ...state,
        loading: true,
      };
    case CHECKOUT_SUCCEED:
      return {
        ...state,
        loading: false,
        checkoutData: action.data,
      };
    case CHECKOUT_FAILED:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    case GET_INVOICES_STARTED:
      return {
        ...state,
        loading: true,
      };
    case GET_INVOICES_SUCCEED:
      return {
        ...state,
        loading: false,
        invoiceData: action.data,
      };
    case GET_INVOICES_FAILED:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    case APPLY_DISCOUNT_CODE_STARTED:
      return {
        ...state,
        loading: true,
      };
    case APPLY_DISCOUNT_CODE_SUCCEED:
      return {
        ...state,
        loading: false,
        userDiscountCode: action.data,
      };
    case APPLY_DISCOUNT_CODE_FAILED:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    default: return INITIAL_STATE;
  }
};

export default reducer;
