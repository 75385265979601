export const TODAY = 0;
export const XS_6 = 491;
export const SM_6 = 767;
export const MD_6 = 875;
export const MD_9 = 1199;
export const SEVEN_DAYS = 7;
export const THIRTY_DAYS = 30;
export const TOTAL_CALENDAR_DAYS = 42;

export const DAYS = 'days';
export const END_DATE = 'end';
export const DAY_FORMAT = 'DD';
export const MONTHS = 'months';
export const EMPTY_STRING = '';
export const CLASS_HIDE = 'hide';
export const CLASS_SHOW = 'show';
export const START_DATE = 'start';
export const SMALL_ICON = 'small';
export const DATE_FILTER = 'date';
export const END_DATE_LABEL = 'até';
export const START_DATE_LABEL = 'de';
export const STATUS_FILTER = 'status';
export const SELECT_FILTER = 'select';
export const SELECTED_CLASS = 'selected';
export const OUT_OF_LIMIT = 'out-of-limit';
export const OUT_OF_ORDER = 'out-of-order';
export const MONTH_YEAR_FORMAT = 'MMMM YYYY';
export const INSTANCES_VARIANT = 'instances';
export const BACKTESTS_VARIANT = 'backtests';
export const DAY_MONTH_YEAR_FORMAT = 'DD/MM/YYYY';
export const DATE_PICKER_KEY_PREFIX = 'date-picker';

export const DAYS_OF_WEEK = {
  SUNDAY: 'dom',
  MONDAY: 'seg',
  FRIDAY: 'sex',
  TUESDAY: 'ter',
  THURSDAY: 'qui',
  SATURDAY: 'sáb',
  WEDNESDAY: 'qua',
};
export const RANGE_BUTTONS_KEYS = {
  [TODAY]: `${TODAY}-days`,
  [SEVEN_DAYS]: `${SEVEN_DAYS}-days`,
  [THIRTY_DAYS]: `${THIRTY_DAYS}-days`,
};
export const TABS_KEYS = {
  [START_DATE]: `${START_DATE}-date`,
  [END_DATE]: `${END_DATE}-date`,
};

export const SIGNATURES_LIST_FILTER = [
  { label: 'Não assinadas', value: 'notSigned' },
  { label: 'Todas', value: 'all' },
];

export const FREQUENCY_LIST_FILTER = [
  { label: 'Diária', value: 'daily' },
  { label: 'Semanal', value: 'weekly' },
  { label: 'Quinzenal', value: 'biweekly' },
  { label: 'Mensal', value: 'monthly' },
  { label: 'Trimestral', value: 'trimestral' },
  { label: 'Semestral', value: 'semiannual' },
];

export const CATEGORY_FILTERS = {
  SIGNATURE: 'signature',
  MINIMUM: 'minimum',
  FREQUENCY: 'frequency',
};

export const SCORE_BOARD_FILTERS = {
  ...CATEGORY_FILTERS,
  RISK: 'risk',
  CATEGORY: 'category',
  VOLUME: 'volume',
};

export const RISK_LIST_FILTER = {
  low: {
    label: 'Baixo',
    value: 'low',
  },
  moderate: {
    label: 'Moderado',
    value: 'medium',
  },
  high: {
    label: 'Alto',
    value: 'high',
  },
  veryHigh: {
    label: 'Muito alto',
    value: 'veryHigh',
  },
};

export const VOLUME_LIST_FILTER = {
  low: {
    label: 'Baixo',
    value: 'low',
  },
  average: {
    label: 'Médio',
    value: 'medium',
  },
  high: {
    label: 'Alto',
    value: 'high',
  },
};

export const CATEGORY_LIST_FILTER = [
  { label: 'Carteiras recomendadas', value: 'recommended' },
  { label: 'Investimento Geral', value: 'general' },
];

export const STEP_FILTER_DEFAULT = 500;
export const LABEL_ANY_VALUE = 'Qualquer valor';
export const MINIMUM_LABEL = 'Mínimo';

export const FILTER_TITLES = {
  RISK: 'Risco',
  CATEGORY: 'Categoria',
  SIGNATURE: 'Assinatura',
  FREQUENCY: 'Frequência',
  MINIMUM: 'Mínimo',
  VOLUME: 'Volume',
};
