import { withStyles } from '@material-ui/core';

import LoadingButton from './LoadingButton';

const styles = {
  root: {
    display: 'flex',
    alignItems: 'center',
  },
  wrapper: {
    position: 'relative',
  },
  buttonProgress: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
};

export default withStyles(styles)(LoadingButton);
