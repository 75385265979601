import { useState } from 'react';
import { useSelector } from 'react-redux';
import { DEFAULT_SETUP_DATA } from 'common/utils/setup';

const { code } = DEFAULT_SETUP_DATA;

export const useOperationalCosts = ({
  instanceId = 0,
  storedSetup = '',
} = {}) => {
  const instance = useSelector((state) => state.instances.data[instanceId]) || {};
  const [expanded, setExpanded] = useState('');
  const setup = storedSetup || instance.setup || code;
  const [selectedSetup, setSelectedSetup] = useState(setup);

  const updateSelectedSetup = (setupCode) => setSelectedSetup(setupCode);
  const handlePanelChange = (setupCode) => {
    setExpanded(expanded === setupCode ? '' : setupCode);
  };

  return {
    instance,
    expanded,
    selectedSetup,
    updateSelectedSetup,
    handlePanelChange,
  };
};

export default null;
