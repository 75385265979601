import moment from 'moment';
import logdown from 'logdown';
import { LOGOUT_SUCCEED } from 'common/ducks/auth';
import api from 'common/api';

export const DUCK_NAME = 'plans';
const logger = logdown(DUCK_NAME);

export const INITIAL_STATE = {
  lastUpdateDatetime: null,
  loading: false,
  data: {},
  plan: {},
  error: null,
};

// Action Types
export const LOAD_PLANS_STARTED = `${DUCK_NAME}/LOAD_PLANS_STARTED`;
export const LOAD_PLANS_SUCCEED = `${DUCK_NAME}/LOAD_PLANS_SUCCEED`;
export const LOAD_PLANS_FAILED = `${DUCK_NAME}/LOAD_PLANS_FAILED`;

// Action Creators
export const loadPlansStarted = () => ({ type: LOAD_PLANS_STARTED });
export const loadPlansSucceed = (data) => ({ type: LOAD_PLANS_SUCCEED, data });
export const loadPlansFailed = (error) => ({ type: LOAD_PLANS_FAILED, error });

export const loadPlans = (options = { reload: false }) => async (dispatch, getState) => {
  const state = getState()[DUCK_NAME];
  if (
    !options.reload
    && state
    && !state.error
    && !state.loading
    && Object.keys(state.data).length > 0
  ) {
    logger.log('Using loaded plans', state);
    return Promise.resolve({ data: state.data });
  }

  dispatch(loadPlansStarted());

  try {
    const { data: { results } } = await api.plans.getPlans();
    const plans = results.filter((plan) => plan?.extra_data?.smarttinvest);

    dispatch(loadPlansSucceed(plans));
    return Promise.resolve(plans);
  } catch (error) {
    logger.error('Could not get plans.', error);
    dispatch(loadPlansFailed(error));
    return Promise.reject(error);
  }
};

// Action Types
export const GET_PLAN_STARTED = `${DUCK_NAME}/GET_PLAN_STARTED`;
export const GET_PLAN_SUCCEED = `${DUCK_NAME}/GET_PLAN_SUCCEED`;
export const GET_PLAN_FAILED = `${DUCK_NAME}/GET_PLAN_FAILED`;

// Action Creators
export const getPlanStarted = () => ({ type: GET_PLAN_STARTED });
export const getPlanSucceed = (data) => ({ type: GET_PLAN_SUCCEED, data });
export const getPlanFailed = (error) => ({ type: GET_PLAN_FAILED, error });

export const getPlan = (plan) => async (dispatch) => {
  dispatch(getPlanStarted());
  try {
    const { data } = await api.plans.getPlan(plan);
    dispatch(getPlanSucceed(data));
    return Promise.resolve(data);
  } catch (error) {
    logger.error('Could not get plan.', error);
    dispatch(getPlanFailed(error));
    return Promise.reject(error);
  }
};

// Reducer
const reducer = (state = INITIAL_STATE, action) => {
  // Logout cleaning
  if (action.type === LOGOUT_SUCCEED) {
    return INITIAL_STATE;
  }

  switch (action.type) {
    case LOAD_PLANS_STARTED:
      return {
        ...state,
        loading: true,
      };
    case LOAD_PLANS_SUCCEED:
      return {
        ...state,
        loading: false,
        lastUpdateDatetime: moment().toISOString(),
        data: {
          ...action.data,
        },
      };
    case LOAD_PLANS_FAILED:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    case GET_PLAN_STARTED:
      return {
        ...state,
        loading: true,
      };
    case GET_PLAN_SUCCEED:
      return {
        ...state,
        loading: false,
        plan: action.data,
      };
    case GET_PLAN_FAILED:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    default: return state;
  }
};

export default reducer;
