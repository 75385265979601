import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { get } from 'lodash';
import { withStyles } from '@material-ui/core/styles';

import { logout } from 'common/ducks/auth';
import palette from 'common/palette';
import { TERMS_OF_USE } from 'common/utils/constants';
import theme from 'common/theme';

import UserActionsList from './UserActionsList';

const styles = {
  listItemText: {
    fontSize: 13,
    fontFamily: 'Roboto',
    color: palette.textSecondary,
  },
  listItemPadding: {
    lineHeight: '1.8',
    padding: '0px',
  },
  listItemIcon: {
    [`@media (min-width: calc(${theme.breakpoints.values.md}px + 1px))`]: {
      minWidth: 25,
    },
  },
  icon: {
    marginRight: 0,
  },
  menuItem: {
    paddingLeft: 10,
    [`@media (max-width: ${theme.breakpoints.values.md}px)`]: {
      padding: '10px 16px',
    },
  },
};

const mapStateToProps = ({ user, route }) => ({
  latestTermUrl: get(user, 'terms.latestTermUrl', TERMS_OF_USE),
  route,
});

const mapDispatchToProps = (dispatch) => ({
  logout: () => dispatch(logout()),
});

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(withStyles(styles)(UserActionsList)),
);
