import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { success, error } from 'react-notification-system-redux';

import { recoverPassword, rateCheck } from 'common/ducks/auth';
import RecoverPassword from './RecoverPassword';

const mapDispatchToProps = (dispatch) => ({
  recoverPassword: (data) => dispatch(recoverPassword(data)),
  rateCheck: (data) => dispatch(rateCheck(data)),
  notifyError: (data) => dispatch(error(data)),
  notifySuccess: (data) => dispatch(success(data)),
});

const mapStateToProps = ({ auth: { logged, forced_external_logout } }) => ({
  logged,
  forcedExternalLogout: forced_external_logout,
});

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps,
)(RecoverPassword));
