const instanceErrorInterpreter = (code, profile) => {
  switch (code) {
    case 'instance0':
      return 'Este robô não existe';
    case 'instance1':
      return 'Essa estratégia não está mais disponível em sua conta.';
    case 'instance2':
      return 'Não foi possível processar esta requisição.';
    case 'instance3':
      return `Você atingiu o limite de robôs em execução para o seu plano.(${
        profile.max_running_instances})`;
    case 'instance4':
      return 'A corretora vinculada a esta instância é inválida';
    case 'instance5':
      return 'O código do ativo ou contrato é inválido';
    case 'instance6':
      return `Você atingiu o máximo de robôs permitido em sua conta (${
        profile.max_instances}). Para criar mais robôs, exclua algum existente.`;
    case 'instance7':
      return 'É necessário especificar o nome do mercado.';
    case 'instance8':
      return 'É necessário especificar código do contrato.';
    case 'instance11':
      return 'Capital inicial inválido.';
    case 'instance12':
      return 'O Nome do robô é inválido.';
    case 'instance_permission0':
      return 'Você não tem permissão para executar esta ação.';
    case 'instance_blocked':
      return 'Este robô está bloqueado.';
    case 'instance_strategy_not_specified':
      return 'Não foi possível processar esta requisição.';
    case 'instance_strategy_not_available_for_brokerage':
      return 'Esta estratégia não permite operações com a corretora selecionada. Selecione outra corretora e tente novamente.';
    case 'instance_invalid_simulation_type':
      return 'Tipo de simulador inválido.';
    default:
      return null;
  }
};

export default instanceErrorInterpreter;
