import React from 'react';
import PropTypes from 'prop-types';
import {
  PieChart,
  Pie,
  Tooltip,
  ResponsiveContainer,
} from 'recharts';
import { renderCellComponent } from 'common/utils/whitebox';
import {
  DEFAULT_CHART_COLORS,
  DEFAULT_EMPTY_CHART_COLOR,
  DEFAULT_PRIVATE_CHART_COLOR,
  EMPTY_CHART_VALUE,
  PRIVATE_CHART_VALUE,
} from 'common/utils/constants';
import { getCurrentScreen } from 'common/utils/screen';
import { getDefaultChartData } from 'common/utils/chart';
import { SMALL } from 'common/utils/constants/size';
import DonutsChartTooltip from '../DonutsChartTooltip';

const DonutsChart = ({
  chartTexts,
  data,
  size,
  showData,
}) => {
  const screen = getCurrentScreen({ mobile: 1200, lowerMobile: 370 });

  const correctChartData = getDefaultChartData({ size, screen });

  const hasData = data.length > 0;

  const getChartData = () => {
    if (!showData) return PRIVATE_CHART_VALUE;
    if (!hasData) return EMPTY_CHART_VALUE;
    return data;
  };

  const renderChartTexts = () => {
    return Object.values(chartTexts).map((item) => {
      if (!item.isVisible) return false;

      return (
        <text
          key={item.id}
          textAnchor="middle"
          alignmentBaseline="central"
          y={item.y}
          x="50%"
          className={item.className}
          id={item.id}
        >
          {item.text}
        </text>
      );
    });
  };

  return (
    <ResponsiveContainer
      id="data-donuts"
      width="100%"
      minHeight={correctChartData.height}
    >
      <PieChart>
        {showData && renderChartTexts()}
        <Pie
          data={getChartData()}
          innerRadius={correctChartData.innerRadius}
          outerRadius={correctChartData.outerRadius}
          blendStroke
          paddingAngle={0}
          dataKey="weight"
        >
          {(showData && hasData) && renderCellComponent(data, DEFAULT_CHART_COLORS)}

          {(showData && !hasData)
              && renderCellComponent(EMPTY_CHART_VALUE, DEFAULT_EMPTY_CHART_COLOR)}

          {(!showData)
            && renderCellComponent(PRIVATE_CHART_VALUE, DEFAULT_PRIVATE_CHART_COLOR)}
        </Pie>
        {(showData && hasData) && <Tooltip content={<DonutsChartTooltip />} />}
      </PieChart>
    </ResponsiveContainer>
  );
};

DonutsChart.propTypes = {
  data: PropTypes.array.isRequired,
  showData: PropTypes.bool,
  chartTexts: PropTypes.array,
  size: PropTypes.string,
};

DonutsChart.defaultProps = {
  size: SMALL,
  chartTexts: [],
  showData: true,
};

export default DonutsChart;
