import React, { memo, useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import accounting from 'accounting';
import {
  Card,
  Grid,
  Box,
  Typography,
} from '@material-ui/core';
import { isString } from 'lodash';

import { useBenchmarkFilter } from 'common/hooks/benchmark';
import { getInitialValue, getNetReturn, getLastDailyCumulativeValue } from 'common/utils/dailyCumulativePerformance';
import { findItemByDate } from 'common/utils/date';
import {
  getProfitabilityDataWithoutCurrentMonth,
  getPercentMonthsBetterThanBenchmark,
  getProfitabilityData,
  getInstanceRelativeAnnualProfitabilityText,
  getInstanceRelativeLaunchProfitabilityText,
  getInstanceRelativeStartProfitabilityText,
  checkIfItsStoreItem,
} from 'common/utils/instance';
import { getAnnualizedReturn } from 'common/utils/report';
import { moment } from 'common/utils/validation';

import BenchmarkFilter from 'common/components/BenchmarkFilter';
import DisplayNumber from 'common/components/DisplayNumber';
import TitleTooltip from 'common/components/TitleTooltip';

const { formatNumber } = accounting;

const getPerformanceItems = ({
  metrics, items, classes, isStoreInstance,
}) => {
  const itemsKeys = Object.keys(items);
  return (isStoreInstance ? itemsKeys : itemsKeys.slice(1)).map((performanceItem) => {
    if (metrics.length > 0 && !metrics.includes(performanceItem)) return false;

    const currentPerformanceItem = items[performanceItem];

    return (
      <Box
        id={performanceItem}
        key={currentPerformanceItem.label}
        className={classes.performanceItem}
      >
        <Typography variant="h6">{currentPerformanceItem.label}</Typography>

        {!currentPerformanceItem.text
          ? (
            <DisplayNumber
              colorful
              percentual
              scale={0.875}
              symbol="%"
              value={Number.parseFloat(currentPerformanceItem.value)}
            />
          )
          : (
            <Typography id="typography-value" className={classes.bold} variant="h6">
              {currentPerformanceItem.value}
            </Typography>
          )}
      </Box>
    );
  });
};

const getProfitabilityComparison = (instanceNetReturn, benchmarkNetReturn) => {
  if (instanceNetReturn < 0 || benchmarkNetReturn < 0) return '-';
  return (instanceNetReturn / benchmarkNetReturn) * 100;
};

const getRunningMonthsFromYearsObject = ({ years }) => {
  return Object.values(years).reduce((totalNumber, currentYear) => {
    return totalNumber + Object.values(currentYear.months).length;
  }, 0);
};

const InstanceMetricsCard = ({
  classes,
  report,
  instance,
  instanceId,
  profitabilityData,
  dailyCumulativePerformance,
  backtestDailyPerformance,
  metrics,
  arrayYearsBacktest,
  updateInstanceReport,
}) => {
  const mergedDailyCumulativeData = { ...dailyCumulativePerformance, ...backtestDailyPerformance };
  const { selectedBenchmark, handleChangeBenchmarkFilter } = useBenchmarkFilter();
  const instanceData = mergedDailyCumulativeData[instanceId];
  const benchmarkDataCumulative = mergedDailyCumulativeData[selectedBenchmark.instanceId].data;
  const isStoreInstance = checkIfItsStoreItem(instance);

  useEffect(() => {
    updateInstanceReport(instanceId);
  }, [updateInstanceReport, instanceId]);

  const benchmarkData = getProfitabilityData({
    data: benchmarkDataCumulative,
    yearsList: arrayYearsBacktest,
    isBenchmark: true,
  });

  const {
    data,
    isCustomBacktest,
    endDate,
    startDates,
  } = instanceData;
  const firstInstanceDate = data[0].date;
  const { initialValue: instanceInitialValue } = getInitialValue(startDates.month, data);
  const { initialValue: benchmarkInitialValue } = getInitialValue(
    firstInstanceDate,
    benchmarkDataCumulative,
  );
  const launchDateCumulativeValue = isStoreInstance
    && findItemByDate({
      data,
      date: report[instanceId].data?.instance_launch_date,
    })?.dailyCumulativePerformance;
  const lastInstanceValue = getLastDailyCumulativeValue(data);
  const lastBenchmarkValue = getLastDailyCumulativeValue(benchmarkDataCumulative);
  const instanceLastNetReturn = getNetReturn(lastInstanceValue, 1);
  const benchmarkLastNetReturn = getNetReturn(lastBenchmarkValue, benchmarkInitialValue);
  const profitabilityComparison = getProfitabilityComparison(
    instanceLastNetReturn, benchmarkLastNetReturn,
  );

  const startInstanceDate = moment(firstInstanceDate);
  const profitabilityInstanceValue = lastInstanceValue - 1;

  const updatedProfitabilityData = getProfitabilityDataWithoutCurrentMonth(
    profitabilityData,
  );

  const percentMonthsBetterThanBench = getPercentMonthsBetterThanBenchmark({
    instanceData: isStoreInstance ? updatedProfitabilityData : profitabilityData,
    benchmarkData,
    monthsRunning: isStoreInstance
      ? instance.periods_running || 0
      : getRunningMonthsFromYearsObject({ years: profitabilityData.years }),
  });

  const performanceMetricLeft = {
    preLaunchProfitability: {
      label: 'Rentabilidade pré-lançamento',
      value: getNetReturn(launchDateCumulativeValue, data[0].dailyCumulativePerformance),
    },
    annualizedReturn: {
      label: 'Rentabilidade anualizada',
      value: getAnnualizedReturn({
        startDate: startInstanceDate,
        endDate,
        profitabilityValue: profitabilityInstanceValue,
        report: report[instanceId],
        isCustomBacktest,
      }),
    },
    profitability: {
      label: 'Rentabilidade (Desde o início)',
      value: isCustomBacktest
        ? profitabilityInstanceValue * 100
        : report[instanceId].data?.percentual_net_return * 100,
    },
    annualAlpha: {
      label: (
        <TitleTooltip
          color="textPrimary"
          label="Rentabilidade Relativa (No ano)"
          tooltipSide="right"
          tooltip={{ text: getInstanceRelativeAnnualProfitabilityText(selectedBenchmark.name) }}
        />),
      value: report[instanceId].data?.current_year_alpha_profitability,
    },
    monthlyWalletReturn: {
      label: 'No mês atual',
      value: getNetReturn(lastInstanceValue, instanceInitialValue),
    },
    operatingPeriod: {
      label: 'Meses operando',
      value: isStoreInstance
        ? `${instance.periods_running} meses`
        : `${getRunningMonthsFromYearsObject({ years: profitabilityData.years })} meses`,
      text: true,
    },
    profitabilityBenchComparison: {
      label: `Rentabilidade / ${selectedBenchmark.name}`,
      value: profitabilityComparison,
      text: isString(profitabilityComparison),
    },
  };

  const performanceMetricRight = {
    postLaunchProfitability: {
      label: 'Rentabilidade pós-lançamento',
      value: getNetReturn(lastInstanceValue, launchDateCumulativeValue),
    },
    bestMonth: {
      label: 'Melhor mês',
      value: profitabilityData.bestMonthGeneral * 100,
    },
    worstMonth: {
      label: 'Pior mês',
      value: profitabilityData.worstMonthGeneral * 100,
    },
    accumulatedAlpha: {
      label: (
        <TitleTooltip
          color="textPrimary"
          label={`Rentabilidade Relativa (Desde o ${isStoreInstance ? 'lançamento' : 'início'})`}
          tooltipSide="right"
          tooltip={{
            text: isStoreInstance
              ? getInstanceRelativeLaunchProfitabilityText(selectedBenchmark.name)
              : getInstanceRelativeStartProfitabilityText(selectedBenchmark.name),
          }}
        />),
      value: report[instanceId].data?.accumulated_alpha_profitability,
    },
    positivePeriod: {
      label: 'Meses positivos',
      value: `${formatNumber(profitabilityData.positiveMonthsPercent * 100, 2)}% dos meses`,
      text: true,
    },
    biggestConsecutiveLoss: {
      label: 'Maior perda consecutiva',
      value: `${formatNumber(Number(report[instanceId].data.percentual_maximum_drawdown) * 100, 2)}%`,
      text: true,
    },
    betterThanBenchmarkMonths: {
      label: `Melhor que o ${selectedBenchmark.name}`,
      value: `${formatNumber(percentMonthsBetterThanBench * 100, 2)}% dos meses`,
      text: true,
    },
  };

  return (
    <>
      <Box
        className={classes.filterBox}
        mb={1}
        data-testid="instance-metric-card"
      >
        <Typography variant="h3" className={classes.cardTitle}>
          Métricas de risco
        </Typography>

        <BenchmarkFilter onChange={handleChangeBenchmarkFilter} />
      </Box>
      <Card className={classNames(classes.card, classes.cardSpacing)}>
        <Grid container spacing={2} className={classes.performanceGridWrapper}>
          <Grid xs={12} md={6} item>
            {getPerformanceItems({
              metrics, items: performanceMetricLeft, classes, isStoreInstance,
            })}
          </Grid>
          <Grid xs={12} md={6} item>
            {getPerformanceItems({
              metrics, items: performanceMetricRight, classes, isStoreInstance,
            })}
          </Grid>
        </Grid>
      </Card>
    </>
  );
};

InstanceMetricsCard.propTypes = {
  classes: PropTypes.object.isRequired,
  instance: PropTypes.object,
  instanceId: PropTypes.number.isRequired,
  profitabilityData: PropTypes.object.isRequired,
  report: PropTypes.object.isRequired,
  dailyCumulativePerformance: PropTypes.object.isRequired,
  backtestDailyPerformance: PropTypes.object,
  metrics: PropTypes.array,
  arrayYearsBacktest: PropTypes.array.isRequired,
  updateInstanceReport: PropTypes.func.isRequired,
};

InstanceMetricsCard.defaultProps = {
  backtestDailyPerformance: {},
  instance: {},
  metrics: [],
};

export default memo(InstanceMetricsCard);
