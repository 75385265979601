import { withStyles } from '@material-ui/core';

import palette from 'common/palette';
import ArrowTooltip from './ArrowTooltip';

const styles = {
  arrow: {
    color: palette.darkPrimary.main,
  },
  textCenter: {
    textAlign: 'center',
  },
};

export default withStyles(styles)(ArrowTooltip);
