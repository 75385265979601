import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { openDialog } from 'common/ducks/dialogs';
import { setIsPreventChangeRoute } from 'common/ducks/route';
import { logout } from 'common/ducks/auth';
import PageAbandonment from './PageAbandonment';
import { CONFIRMATION_DIALOG_CODE } from '../Dialogs/ConfirmationDialog';

const mapStateToProps = ({ route }) => ({ route });

const mapDispatchToProps = (dispatch) => ({
  setIsPreventChangeRoute: (value) => dispatch(setIsPreventChangeRoute(value)),
  openConfirmationDialog: (props) => dispatch(openDialog(
    CONFIRMATION_DIALOG_CODE,
    { ...props },
  )),
  logout: () => dispatch(logout()),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(PageAbandonment));
