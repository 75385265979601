import React from 'react';
import PropTypes from 'prop-types';
import { Switch, Route } from 'react-router-dom';
import Login from './Login';
import RecoverPassword from './RecoverPassword';
import RedefinePassword from './RedefinePassword';

const AuthPages = ({ match }) => (
  <Switch>
    <Route path={`${match.url}/login`} component={Login} />
    <Route path={`${match.url}/recuperar-senha`} component={RecoverPassword} />
    <Route path={`${match.url}/redefinir-senha`} component={RedefinePassword} />
  </Switch>
);

AuthPages.propTypes = {
  match: PropTypes.shape({ url: PropTypes.string }).isRequired,
};

export default AuthPages;
