import React from 'react';
import PropTypes from 'prop-types';

import CircularProgress from '@material-ui/core/CircularProgress';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import CloseDialogButton from 'common/components/Buttons/CloseDialogButton';
import { cancelPlan } from 'common/utils/plan';
import { getCorrectPlanByCode } from 'common/utils/plans';
import { CANCELLATION_URL } from 'common/utils/constants';

import Placeholder from 'common/components/Placeholder';

export const CARD_REMOVE_CONFIRM_DIALOG = 'CARD_REMOVE_CONFIRM_DIALOG';

class CardRemoveConfirmDialog extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      loadingCancellation: false,
    };
  }

  componentDidMount = async () => {
    this.setState({ loading: false });
  };

  cancelRedirect = (code) => {
    this.props.history.push(CANCELLATION_URL, { planCode: code });
  };

  getCardSubscription = (id, subscriptions) => subscriptions
    .filter((subscription) => (
      id === subscription.payment_method
      && subscription.status === 'ACTIVE'
    ));

  getCardLastFourDigits = (number) => number.slice(-4);

  getSmarrtinvestPlan = (planCode) => {
    const { plans } = this.props;

    return getCorrectPlanByCode({ planCode, listPlans: Object.values(plans) });
  };

  getDialogMessage = (cardHasSubscription, cardSubscription) => {
    const {
      number,
      issuer,
    } = this.props;

    const formattedNumber = this.getCardLastFourDigits(number);

    let dialogMessage = `Você tem certeza que deseja remover o cartão
    ${issuer} com o final ${formattedNumber}?`;

    if (cardHasSubscription) {
      const currentPlan = this.getSmarrtinvestPlan(cardSubscription.plan_code);

      if (currentPlan) {
        dialogMessage = `O seu cartão com o final ${formattedNumber} está vinculado à assinatura
        do seu plano ${currentPlan.name}. Para remover o cartão é necessário
        que antes você cancele a renovação da assinatura.`;
      } else {
        dialogMessage = `O seu cartão com o final ${formattedNumber} está vinculado à assinatura
        do seu plano na SmarttBot. Para remover o cartão é necessário
        que antes você cancele a renovação da assinatura na plataforma SmarttBot`;
      }
    }

    return dialogMessage;
  };

  getConfirmButton = (cardHasSubscription, cardSubscription) => {
    const { onClose } = this.props;
    const { loadingCancellation } = this.state;

    if (cardHasSubscription) {
      if (this.getSmarrtinvestPlan(cardSubscription.plan_code)) {
        return (
          <Button
            id="keep-card-confirm-click"
            variant="contained"
            onClick={onClose}
            color="primary"
            disabled={loadingCancellation}
            autoFocus
          >
            Manter Cartão
          </Button>
        );
      }
      return (
        <Button
          id="smarttbot-plan-confirm-click"
          variant="contained"
          color="primary"
          onClick={onClose}
          autoFocus
        >
          Ok
        </Button>
      );
    }

    return (
      <Button
        id="card-remove-confirm-click"
        variant="contained"
        onClick={this.removeCreditCard}
        color="primary"
        disabled={loadingCancellation}
        autoFocus
      >
        Remover
      </Button>
    );
  };

  getCancelButton = (cardHasSubscription, cardSubscription) => {
    const {
      onClose,
      loadInstances,
      openProgressDialog,
    } = this.props;

    if (cardHasSubscription) {
      const currentPlan = this.getSmarrtinvestPlan(cardSubscription.plan_code);
      if (currentPlan) {
        const strategyId = currentPlan.extra_data.strategy_id;

        return (
          <Button
            id="card-remove-confirm-click"
            onClick={async () => {
              const isRedirect = await cancelPlan({
                loadInstances,
                strategyId,
                openProgressDialog,
                redirect: () => this.cancelRedirect(cardSubscription.code),
              });

              return isRedirect ? onClose() : null;
            }}
            color="secondary"
            autoFocus
          >
            Cancelar Renovação
          </Button>
        );
      }
      return null;
    }

    return (
      <Button
        id="card-remove-cancel-click"
        onClick={onClose}
        color="secondary"
        autoFocus
      >
        Cancelar
      </Button>
    );
  };

  getDialogTemplate = () => {
    const {
      classes,
      open,
      onClose,
      cardId,
      subscriptions,
    } = this.props;

    const { loadingCancellation } = this.state;

    const [cardSubscription] = this.getCardSubscription(cardId, subscriptions.data);
    const cardHasSubscription = !!cardSubscription;

    const dialogMessage = this.getDialogMessage(
      cardHasSubscription,
      cardSubscription,
    );
    const confirmButton = this.getConfirmButton(cardHasSubscription, cardSubscription);
    const cancelButton = this.getCancelButton(cardHasSubscription, cardSubscription);

    return (
      <Dialog open={open} onClose={onClose}>
        <CloseDialogButton onClose={onClose} />
        <DialogTitle
          className={classes.title}
          disableTypography
        >
          Remover Cartão
        </DialogTitle>
        <DialogContent className={classes.messageContainer}>
          <DialogContentText>
            {dialogMessage}
          </DialogContentText>
        </DialogContent>
        <DialogActions className={classes.actions}>
          {cancelButton}
          <div className={classes.actionsWrapper}>
            {confirmButton}
            {
              loadingCancellation
              && (
                <CircularProgress size={24} className={classes.buttonProgress} />
              )
            }
          </div>
        </DialogActions>
      </Dialog>
    );
  };

  removeCreditCard = async () => {
    const { onClose } = this.props;

    this.setState({ loadingCancellation: true });
    try {
      await this.props.onConfirmClick();
      this.setState({ loadingCancellation: false });
    } catch (error) {
      this.setState({ loadingCancellation: false });
    }

    onClose();
  };

  render = () => {
    const { open, onClose } = this.props;

    const { loading } = this.state;

    return loading
      ? (
        <Dialog open={open} onClose={onClose}>
          <Placeholder height={200} width={450} />
        </Dialog>
      )
      : this.getDialogTemplate();
  }
}

CardRemoveConfirmDialog.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  open: PropTypes.bool.isRequired,
  onConfirmClick: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  issuer: PropTypes.string.isRequired,
  number: PropTypes.string.isRequired,
  cardId: PropTypes.string.isRequired,
  subscriptions: PropTypes.object.isRequired,
  plans: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  loadInstances: PropTypes.func.isRequired,
  openProgressDialog: PropTypes.func.isRequired,
};

export default CardRemoveConfirmDialog;
