import React from 'react';
import PropTypes from 'prop-types';

import Menu from '@material-ui/core/Menu';

import UserMenuItem from './UserMenuItem';
import UserActionsList from './UserActionsList';

const UserMenu = ({
  menuAnchorEl, handleMenu, classes, disableActions,
}) => (
  <Menu
    id="dropdown-menu"
    anchorEl={menuAnchorEl}
    open={Boolean(menuAnchorEl)}
    onClose={() => handleMenu({ currentTarget: null })}
    MenuListProps={{
      className: classes.dropdownMenuList,
    }}
  >
    <UserMenuItem handleMenu={handleMenu} />
    <UserActionsList disableActions={disableActions} handleMenu={handleMenu} />
  </Menu>
);

UserMenu.propTypes = {
  menuAnchorEl: PropTypes.any,
  handleMenu: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
  disableActions: PropTypes.bool,
};

UserMenu.defaultProps = {
  disableActions: false,
  menuAnchorEl: null,
};

export default UserMenu;
