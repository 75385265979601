import { SI_PLATFORM_ID } from 'common/utils/constants/platform';
import moment from 'moment';
import api from '../api';

export const DUCK_NAME = 'auth';

export const CACHE_TOLERANCE_TIME = { amount: 1, unit: 'day' };

export const INITIAL_STATE = {
  lastUpdateDatetime: null,
  logged: false,
  forced_external_logout: false,
  loggedLoading: false,
  loggedError: null,
  loginLoading: false,
  loginError: null,
  logoutLoading: false,
  logoutError: null,
  rateCheckLoading: false,
  rateCheckError: null,
  recoverPasswordLoading: false,
  recoverPasswordError: null,
  resendAccountValidationEmailLoading: false,
  resendAccountValidationEmailError: null,
  resetPasswordLoading: false,
  resetPasswordError: null,
  resetExpiredPasswordLoading: false,
  resetExpiredPasswordError: null,
  signUpLoading: false,
  signUpError: null,
};

export const LOGGED_STARTED = `${DUCK_NAME}/LOGGED_STARTED`;
export const LOGGED_SUCCEED = `${DUCK_NAME}/LOGGED_SUCCEED`;
export const LOGGED_FAILED = `${DUCK_NAME}/LOGGED_FAILED`;

export const loggedStarted = () => ({ type: LOGGED_STARTED });
export const loggedFailed = (error) => ({ type: LOGGED_FAILED, error });
export const loggedSucceed = (data) => ({
  type: LOGGED_SUCCEED,
  data,
});
export const logged = () => async (dispatch) => {
  dispatch(loggedStarted());
  try {
    const response = await api.auth.logged();
    dispatch(loggedSucceed(response.data));
    return Promise.resolve(response);
  } catch (error) {
    dispatch(loggedFailed(error));
    return Promise.reject(error);
  }
};

export const LOGIN_STARTED = `${DUCK_NAME}/LOGIN_STARTED`;
export const LOGIN_SUCCEED = `${DUCK_NAME}/LOGIN_SUCCEED`;
export const LOGIN_FAILED = `${DUCK_NAME}/LOGIN_FAILED`;

export const loginStarted = () => ({ type: LOGIN_STARTED });
export const loginFailed = (error) => ({ type: LOGIN_FAILED, error });
export const loginSucceed = (data) => ({ type: LOGIN_SUCCEED, data });
export const login = (data) => async (dispatch) => {
  dispatch(loginStarted());
  try {
    const response = await api.auth.login({
      ...data,
      platform_id: SI_PLATFORM_ID,
    });
    dispatch(loginSucceed(response.data));
    return Promise.resolve(response);
  } catch (error) {
    dispatch(loginFailed(error));
    return Promise.reject(error);
  }
};

export const RATE_CHECK_STARTED = `${DUCK_NAME}/RATE_CHECK_STARTED`;
export const RATE_CHECK_SUCCEED = `${DUCK_NAME}/RATE_CHECK_SUCCEED`;
export const RATE_CHECK_FAILED = `${DUCK_NAME}/RATE_CHECK_FAILED`;

export const rateCheckStarted = () => ({ type: RATE_CHECK_STARTED });
export const rateCheckSucceed = (data) => ({ type: RATE_CHECK_SUCCEED, data });
export const rateCheckFailed = (error) => ({ type: RATE_CHECK_FAILED, error });
export const rateCheck = (params) => async (dispatch) => {
  dispatch(rateCheckStarted());
  try {
    const response = await api.user.rateCheck({ params: { endpoint: params } });
    dispatch(rateCheckSucceed(response.data));
    return Promise.resolve(response);
  } catch (error) {
    dispatch(rateCheckFailed(error));
    return Promise.reject(error);
  }
};

export const SPECIAL_LOGIN_STARTED = `${DUCK_NAME}/SPECIAL_LOGIN_STARTED`;
export const SPECIAL_LOGIN_FAILED = `${DUCK_NAME}/SPECIAL_LOGIN_FAILED`;
export const SPECIAL_LOGIN_SUCCEED = `${DUCK_NAME}/SPECIAL_LOGIN_SUCCEED`;

export const specialLoginStarted = () => ({ type: SPECIAL_LOGIN_STARTED });
export const specialLoginFailed = (error) => ({ type: SPECIAL_LOGIN_FAILED, error });
export const specialLoginSucceed = (data) => ({ type: SPECIAL_LOGIN_SUCCEED, data });
export const specialLogin = (data) => async (dispatch) => {
  dispatch(specialLoginStarted());
  try {
    const response = await api.auth.specialLogin({
      ...data,
      platform_id: SI_PLATFORM_ID,
    });
    dispatch(specialLoginSucceed(response.data));
    return Promise.resolve(response);
  } catch (error) {
    dispatch(specialLoginFailed(error));
    return Promise.reject(error);
  }
};

export const LOGOUT_STARTED = `${DUCK_NAME}/LOGOUT_STARTED`;
export const LOGOUT_FAILED = `${DUCK_NAME}/LOGOUT_FAILED`;
export const LOGOUT_SUCCEED = `${DUCK_NAME}/LOGOUT_SUCCEED`;
export const USER_UNLOGGED = `${DUCK_NAME}/USER_UNLOGGED`;

export const logoutStarted = () => ({ type: LOGOUT_STARTED });
export const logoutFailed = (error) => ({ type: LOGOUT_FAILED, error });
export const logoutSucceed = (data) => ({ type: LOGOUT_SUCCEED, data });
export const forcedExternalLogout = () => ({
  type: LOGOUT_SUCCEED,
  forced_external_logout: true,
});
export const checkForcedExternalLogout = () => (dispatch, getState) => {
  const { logged: isLogged } = getState()[DUCK_NAME];
  if (isLogged) {
    dispatch(forcedExternalLogout());
  }
};
export const logout = (data) => async (dispatch) => {
  dispatch(logoutStarted());
  try {
    localStorage.clear();
    const response = await api.auth.logout(data);
    dispatch(logoutSucceed(response.data));
    window.stop();
    return Promise.resolve(response);
  } catch (error) {
    if (error.code === 'unlogged') {
      return dispatch(checkForcedExternalLogout());
    }
    dispatch(logoutFailed(error));
    return Promise.reject(error);
  }
};

export const RECOVER_PASSWORD_STARTED = `${DUCK_NAME}/RECOVER_PASSWORD_STARTED`;
export const RECOVER_PASSWORD_SUCCEED = `${DUCK_NAME}/RECOVER_PASSWORD_SUCCEED`;
export const RECOVER_PASSWORD_FAILED = `${DUCK_NAME}/RECOVER_PASSWORD_FAILED`;

export const recoverPasswordStarted = () => ({ type: RECOVER_PASSWORD_STARTED });
export const recoverPasswordSucceed = (data) => ({ type: RECOVER_PASSWORD_SUCCEED, data });
export const recoverPasswordFailed = (error) => ({ type: RECOVER_PASSWORD_FAILED, error });
export const recoverPassword = (data) => async (dispatch) => {
  dispatch(recoverPasswordStarted());
  try {
    const response = await api.auth.requestPasswordReset(data);
    dispatch(recoverPasswordSucceed(response.data));
    return Promise.resolve(response);
  } catch (error) {
    dispatch(recoverPasswordFailed(error));
    return Promise.reject(error);
  }
};

export const RESEND_ACCOUNT_VALIDATION_EMAIL_STARTED = `${DUCK_NAME}/RESEND_ACCOUNT_VALIDATION_EMAIL_STARTED`;
export const RESEND_ACCOUNT_VALIDATION_EMAIL_SUCCEED = `${DUCK_NAME}/RESEND_ACCOUNT_VALIDATION_EMAIL_SUCCEED`;
export const RESEND_ACCOUNT_VALIDATION_EMAIL_FAILED = `${DUCK_NAME}/RESEND_ACCOUNT_VALIDATION_EMAIL_FAILED`;

export const resendAccountValidationEmailStarted = () => (
  { type: RESEND_ACCOUNT_VALIDATION_EMAIL_STARTED });
export const resendAccountValidationEmailSucceed = (data) => (
  { type: RESEND_ACCOUNT_VALIDATION_EMAIL_SUCCEED, data });
export const resendAccountValidationEmailFailed = (error) => (
  { type: RESEND_ACCOUNT_VALIDATION_EMAIL_FAILED, error });
export const resendAccountValidationEmail = (token) => async (dispatch) => {
  dispatch(resendAccountValidationEmailStarted());
  try {
    const response = await api.auth.resendAccountValidationEmail({ token });
    dispatch(resendAccountValidationEmailSucceed(response.data));
    return Promise.resolve(response);
  } catch (error) {
    dispatch(resendAccountValidationEmailFailed(error));
    return Promise.reject(error);
  }
};

export const RESET_PASSWORD_STARTED = `${DUCK_NAME}/RESET_PASSWORD_STARTED`;
export const RESET_PASSWORD_SUCCEED = `${DUCK_NAME}/RESET_PASSWORD_SUCCEED`;
export const RESET_PASSWORD_FAILED = `${DUCK_NAME}/RESET_PASSWORD_FAILED`;

export const resetPasswordStarted = () => ({ type: RESET_PASSWORD_STARTED });
export const resetPasswordSucceed = (data) => ({ type: RESET_PASSWORD_SUCCEED, data });
export const resetPasswordFailed = (error) => ({ type: RESET_PASSWORD_FAILED, error });
export const resetPassword = (data) => async (dispatch) => {
  dispatch(resetPasswordStarted());
  try {
    const response = await api.auth.resetPassword(data);
    dispatch(resetPasswordSucceed(response.data));
    return Promise.resolve(response);
  } catch (error) {
    dispatch(resetPasswordFailed(error));
    return Promise.reject(error);
  }
};

export const RESET_EXPIRED_PASSWORD_STARTED = `${DUCK_NAME}/RESET_EXPIRED_PASSWORD_STARTED`;
export const RESET_EXPIRED_PASSWORD_SUCCEED = `${DUCK_NAME}/RESET_EXPIRED_PASSWORD_SUCCEED`;
export const RESET_EXPIRED_PASSWORD_FAILED = `${DUCK_NAME}/RESET_EXPIRED_PASSWORD_FAILED`;

export const resetExpiredPasswordStarted = () => ({ type: RESET_EXPIRED_PASSWORD_STARTED });
export const resetExpiredPasswordSucceed = (data) => (
  { type: RESET_EXPIRED_PASSWORD_SUCCEED, data });
export const resetExpiredPasswordFailed = (error) => (
  { type: RESET_EXPIRED_PASSWORD_FAILED, error });
export const resetExpiredPassword = (data) => async (dispatch) => {
  dispatch(resetExpiredPasswordStarted());
  try {
    const response = await api.auth.resetExpiredPassword(data);
    dispatch(resetExpiredPasswordSucceed(response.data));
    return Promise.resolve(response);
  } catch (error) {
    dispatch(resetExpiredPasswordFailed(error));
    return Promise.reject(error);
  }
};

export const SIGN_UP_STARTED = `${DUCK_NAME}/SIGN_UP_STARTED`;
export const SIGN_UP_SUCCEED = `${DUCK_NAME}/SIGN_UP_SUCCEED`;
export const SIGN_UP_FAILED = `${DUCK_NAME}/SIGN_UP_FAILED`;

export const signUpStarted = () => ({ type: SIGN_UP_STARTED });
export const signUpSucceed = (data) => ({ type: SIGN_UP_SUCCEED, data });
export const signUpFailed = (error) => ({ type: SIGN_UP_FAILED, error });
export const signUp = (data) => async (dispatch) => {
  dispatch(signUpStarted());
  try {
    const response = await api.auth.signUp(data);
    dispatch(signUpSucceed(response.data));
    return Promise.resolve(response);
  } catch (error) {
    dispatch(signUpFailed(error));
    return Promise.reject(error);
  }
};

const reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
  // Cache policy
    case LOGGED_STARTED:
      return {
        ...state,
        loggedLoading: true,
      };
    case LOGGED_SUCCEED:
      return {
        ...state,
        lastUpdateDatetime: moment().toISOString(),
        logged: action.data.logged,
        forced_external_logout: false,
        loggedLoading: false,
        loggedError: null,
      };
    case LOGGED_FAILED:
      return {
        ...state,
        logged: false,
        loggedLoading: false,
        loggedError: action.error,
      };

    case LOGIN_STARTED:
    case SPECIAL_LOGIN_STARTED:
      return {
        ...state,
        loginLoading: true,
        loginError: null,
      };
    case LOGIN_SUCCEED:
    case SPECIAL_LOGIN_SUCCEED:
      return {
        ...state,
        lastUpdateDatetime: moment().toISOString(),
        logged: action.data.success,
        forced_external_logout: false,
        loginLoading: false,
        loginError: null,
      };
    case LOGIN_FAILED:
    case SPECIAL_LOGIN_FAILED:
      return {
        ...state,
        logged: false,
        loginLoading: false,
        loginError: action.error,
      };

    case RATE_CHECK_STARTED:
      return {
        ...state,
        rateCheckLoading: true,
        rateCheckError: null,
      };
    case RATE_CHECK_SUCCEED:
      return {
        ...state,
        lastUpdateDatetime: moment().toISOString(),
        forced_external_logout: false,
        rateCheckLoading: false,
        rateCheckError: null,
      };
    case RATE_CHECK_FAILED:
      return {
        ...state,
        rateCheckLoading: false,
        rateCheckError: action.error,
      };

    case LOGOUT_STARTED:
      return {
        ...state,
        logoutLoading: true,
        logoutError: null,
      };
    case LOGOUT_SUCCEED:
      return {
        ...state,
        lastUpdateDatetime: moment().toISOString(),
        forced_external_logout: action.forced_external_logout || false,
        logged: false,
        logoutLoading: false,
        logoutError: null,
      };
    case LOGOUT_FAILED:
      return {
        ...state,
        logoutLoading: false,
        logoutError: action.error,
      };

    case RECOVER_PASSWORD_STARTED:
      return {
        ...state,
        recoverPasswordLoading: true,
        recoverPasswordError: null,
      };
    case RECOVER_PASSWORD_SUCCEED:
      return {
        ...state,
        lastUpdateDatetime: moment().toISOString(),
        forced_external_logout: false,
        recoverPasswordLoading: false,
        recoverPasswordError: null,
      };
    case RECOVER_PASSWORD_FAILED:
      return {
        ...state,
        recoverPasswordLoading: false,
        recoverPasswordError: action.error,
      };

    case RESEND_ACCOUNT_VALIDATION_EMAIL_STARTED:
      return {
        ...state,
        resendAccountValidationEmailLoading: true,
        resendAccountValidationEmailError: null,
      };
    case RESEND_ACCOUNT_VALIDATION_EMAIL_SUCCEED:
      return {
        ...state,
        lastUpdateDatetime: moment().toISOString(),
        forced_external_logout: false,
        resendAccountValidationEmailLoading: false,
        resendAccountValidationEmailError: null,
      };
    case RESEND_ACCOUNT_VALIDATION_EMAIL_FAILED:
      return {
        ...state,
        resendAccountValidationEmailLoading: false,
        resendAccountValidationEmailError: action.error,
      };

    case RESET_PASSWORD_STARTED:
      return {
        ...state,
        resetPasswordLoading: true,
        resetPasswordError: null,
      };
    case RESET_PASSWORD_SUCCEED:
      return {
        ...state,
        lastUpdateDatetime: moment().toISOString(),
        forced_external_logout: false,
        resetPasswordLoading: false,
        resetPasswordError: null,
      };
    case RESET_PASSWORD_FAILED:
      return {
        ...state,
        resetPasswordLoading: false,
        resetPasswordError: action.error,
      };

    case RESET_EXPIRED_PASSWORD_STARTED:
      return {
        ...state,
        resetExpiredPasswordLoading: true,
        resetExpiredPasswordError: null,
      };
    case RESET_EXPIRED_PASSWORD_SUCCEED:
      return {
        ...state,
        lastUpdateDatetime: moment().toISOString(),
        forced_external_logout: false,
        resetExpiredPasswordLoading: false,
        resetExpiredPasswordError: null,
      };
    case RESET_EXPIRED_PASSWORD_FAILED:
      return {
        ...state,
        resetExpiredPasswordLoading: false,
        resetExpiredPasswordError: action.error,
      };

    case SIGN_UP_STARTED:
      return {
        ...state,
        signUpLoading: true,
        signUpError: null,
      };
    case SIGN_UP_SUCCEED:
      return {
        ...state,
        lastUpdateDatetime: moment().toISOString(),
        forced_external_logout: false,
        signUpLoading: false,
        signUpError: null,
      };
    case SIGN_UP_FAILED:
      return {
        ...state,
        signUpLoading: false,
        signUpError: action.error,
      };
    default: return state;
  }
};

export default reducer;
