import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import { isHolidayOrWeekendDay } from 'common/ducks/holidays';

import NetReturnChart from './NetReturnChart';

const styles = {
  chart: {
    paddingLeft: 10,
    paddingRight: 10,
    paddingTop: 20,
    width: '100%',
  },
  tooltip: {
    position: 'absolute',
    right: 12,
  },
};

const mapDispatchToProps = (dispatch) => ({
  isHolidayOrWeekendDay: (date) => dispatch(isHolidayOrWeekendDay(date)),
});

export default connect(null, mapDispatchToProps)(withStyles(styles)(NetReturnChart));
