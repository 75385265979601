import React from 'react';
import PropTypes from 'prop-types';
import logdown from 'logdown';
import { Field } from 'redux-form';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Typography from '@material-ui/core/Typography';

import {
  InputTextField,
  PasswordField,
} from 'common/components/ReduxForm';

import {
  passwordRestrictions,
  required,
  confirmPassword,
} from 'common/utils/validation';
import { TABLET_WIDTH_BREAKPOINT } from 'common/utils/constants';
import PasswordTips from 'common/components/PasswordTips';

export const ALTER_PASSWORD_DIALOG_CODE = 'ALTER_PASSWORD_DIALOG_CODE';

const logger = logdown('AlterPasswordDialog');

class AlterPasswordDialog extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount = () => {
    this.props.resetProfilePassword();
  };

  submit = async (values) => {
    const {
      password,
      s10i_password: actualPass,
    } = values;

    const {
      resetProfilePassword,
      notifySuccess,
      notifyError,
      updateUserProfile,
      closeDialog,
    } = this.props;

    try {
      const result = await updateUserProfile({
        s10i_password: actualPass,
        new_s10i_password: password,
      });

      logger.info(`updateUserProfile #${result}`);
      notifySuccess({
        title: 'Sucesso!',
        message: 'Sua senha foi alterada! A partir de agora utilize sua nova senha para acessar a plataforma.',
      });
      closeDialog();
      resetProfilePassword();
    } catch (error) {
      logger.error(`updateUserProfile #${error}`);

      notifyError({
        title: 'Erro!',
        message:
          error.codeMessage || 'Houve um erro ao atualizar seu perfil. Tente novamente.',
      });
    }
  };

  render = () => {
    const {
      classes,
      onCancelClick,
      open,
      onClose,
      confirmButtonContent,
      cancelButtonContent,
      handleSubmit,
      pristine,
      submitting,
      password,
    } = this.props;
    const isMobile = window.innerWidth <= TABLET_WIDTH_BREAKPOINT;

    return (
      <Dialog open={open} onClose={onClose}>
        <DialogContent>
          <Typography variant="h3">
            Alterar sua senha
          </Typography>
          <form className={classes.formControl}>

            <Field
              id="new_password"
              name="password"
              component={PasswordField}
              validate={[required, passwordRestrictions]}
              type="password"
              label="Nova senha"
              helperText="A senha não pode conter os caracteres $ e ;"
            />
            {isMobile && (
              <PasswordTips
                mobile
                values={{ password }}
                className={classes.passwordTips}
              />
            )}
            <Field
              id="password_confirm"
              name="confirm_s10i_password"
              component={InputTextField}
              type="password"
              validate={[required, confirmPassword]}
              label="Confirmar senha"
            />
            <Field
              id="password"
              name="s10i_password"
              component={InputTextField}
              type="password"
              validate={[required, passwordRestrictions]}
              label="Senha atual"
            />
            {!isMobile && <PasswordTips values={{ password }} className={classes.passwordTips} />}
          </form>
        </DialogContent>

        <DialogActions className={classes.actions}>
          <Button
            className={classes.cancelButton}
            onClick={onCancelClick || onClose}
            autoFocus
          >
            {cancelButtonContent || 'Cancelar'}
          </Button>
          <Button
            type="submit"
            disabled={pristine || submitting}
            onClick={handleSubmit(this.submit) || onClose}
            color="primary"
            autoFocus
          >
            {confirmButtonContent || 'Confirmar'}
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

AlterPasswordDialog.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onCancelClick: PropTypes.func,
  cancelButtonContent: PropTypes.any,
  confirmButtonContent: PropTypes.any,
  handleSubmit: PropTypes.func.isRequired,
  pristine: PropTypes.any,
  submitting: PropTypes.any,
  resetProfilePassword: PropTypes.func.isRequired,
  notifySuccess: PropTypes.func.isRequired,
  notifyError: PropTypes.func.isRequired,
  updateUserProfile: PropTypes.func.isRequired,
  closeDialog: PropTypes.func.isRequired,
  password: PropTypes.string,
};

AlterPasswordDialog.defaultProps = {
  onCancelClick: null,
  cancelButtonContent: null,
  confirmButtonContent: null,
  pristine: null,
  submitting: null,
  password: '',
};

export default AlterPasswordDialog;
