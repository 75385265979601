import { withStyles } from '@material-ui/styles';
import LoadingGif from './LoadingGif';

const styles = {
  icon: {
    position: 'absolute',
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    width: 205,
    height: 205,
    margin: 'auto',
  },
};

export default withStyles(styles)(LoadingGif);
