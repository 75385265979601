import React from 'react';
import PropTypes from 'prop-types';
import accounting from 'accounting';

import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles';
import { InputTextField } from 'common/components/ReduxForm';
import InputAdornment from '@material-ui/core/InputAdornment';
import iconHelp from 'common/assets/images/icon-help-tooltip.svg';
import {
  maxValue,
  required,
  maxLength,
  validateNumber,
  fieldRestrictions,
} from 'common/utils/validation';

import { parseNumber } from 'common/utils/string';
import { DEFAULT_SETUP_DATA } from 'common/utils/setup';
import ArrowTooltip from '../ArrowTooltip';

const styles = {
  inputText: {
    marginBottom: 16,
    width: '100%',
    '@media(max-width: 500px)': {
      margin: 0,
    },
  },
  gridWrapper: {
    '@media(max-width: 500px)': {
      marginTop: 10,

      '& > .MuiGrid-item': {
        padding: '5px 16px',
        width: '100%',
      },
    },
  },
  tooltipIcon: {
    paddingLeft: 5,
    height: 15,
  },
  label: {
    width: 'max-content',
  },
};

const OperationalCostsForm = ({
  values: {
    spot_absolute_brokerage_tax: spotAbsoluteBrokerageTax,
    spot_percentual_brokerage_tax: spotPercentualBrokerageTax,
  },
  handleChange,
  handleBlur,
  edit,
  meta: {
    errors,
    touched,
  },
  classes,
}) => {
  const getInputAdornment = (prefix) => <InputAdornment position="start">{prefix}</InputAdornment>;

  const onChange = (field) => (e) => {
    e.target.value = parseNumber(e.target.value, field.size || 2);
    if (field.adornment === '%') {
      e.target.value /= 100;
    }
    handleChange(e);
  };

  const fields = [
    {
      name: 'spot_absolute_brokerage_tax',
      adornment: 'R$',
      label: 'Corretagem fixa por ordem',
      tooltipText: 'Valor pago à corretora por ordem executada. Informado em valor absoluto (R$)',
      helperText: 'Valor pago à sua corretora por ordem executada',
      value: accounting.formatNumber(
        Number.parseFloat(spotAbsoluteBrokerageTax),
        2,
      ),
    },
    {
      name: 'spot_percentual_brokerage_tax',
      adornment: '%',
      label: 'Taxas percentuais por ordem',
      tooltipText: `Outras taxas para operação. Lembre-se de incluir as taxas pagas à bolsa
      (aproximadamente 0,0307% para o investidor pessoa física)`,
      helperText: 'Valor % de taxas pagas à bolsa',
      value: accounting.formatNumber(
        Number.parseFloat(spotPercentualBrokerageTax) * 100,
        4,
      ),
      size: 4,
    },
  ];
  const renderTooltipLabel = (label, text) => (
    <div>
      {label}
      <ArrowTooltip
        title={(
          <div style={{ textAlign: 'center' }}>
            {text}
          </div>
        )}
        placement="top"
      >
        <img src={iconHelp} alt="Tooltip" className={classes.tooltipIcon} />
      </ArrowTooltip>
    </div>
  );
  const renderInputField = (field) => (
    <InputTextField
      className={classes.inputText}
      key={field.name}
      name={field.name}
      id={`operational-costs-form${field.name}`}
      value={field.value}
      disabled={!edit}
      InputProps={{
        startAdornment: getInputAdornment(field.adornment),
      }}
      meta={{
        error: errors[field.name],
        touched: touched[field.name],
      }}
      onChange={onChange(field)}
      onBlur={handleBlur}
      type="text"
      fullWidth={false}
      label={renderTooltipLabel(field.label, field.tooltipText)}
      InputLabelProps={{ className: classes.label }}
      helperText={field.helperText}

    />
  );
  return (
    <Grid container spacing={4} className={classes.gridWrapper}>
      {fields.map((field) => (
        <Grid key={field.name} sm={6} className={classes.gridItemField} item>
          {renderInputField(field)}
        </Grid>
      ))}
    </Grid>
  );
};

OperationalCostsForm.propTypes = {
  handleChange: PropTypes.func.isRequired,
  handleBlur: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
  values: PropTypes.object,
  edit: PropTypes.bool,
  meta: PropTypes.object,
};

OperationalCostsForm.defaultProps = {
  values: {
    spot_absolute_brokerage_tax: 0,
    spot_percentual_brokerage_tax: DEFAULT_SETUP_DATA.spot_percentual_brokerage_tax,
  },
  edit: true,
  meta: {},
};

export const operationalCostsValidation = (values) => Object.keys(values)
  .reduce(
    (acm, currentKey) => {
      if (currentKey === 'code' || currentKey === 'operational_limit') {
        return acm;
      }
      const value = values[currentKey];

      let errorMessage = required(value) || validateNumber(value);

      if (currentKey === 'spot_percentual_brokerage_tax') {
        errorMessage = errorMessage || maxValue(100)(Number.parseFloat(value * 100));
      } else {
        errorMessage = errorMessage || maxValue(200, true)(Number.parseFloat(value));
      }

      if (currentKey === 'description') {
        errorMessage = required(value)
          || fieldRestrictions(value)
          || maxLength(50)(value);
      }

      if (errorMessage) {
        return {
          ...acm,
          [currentKey]: errorMessage,
        };
      }

      return acm;
    },
    {},
  );

export default withStyles(styles)(OperationalCostsForm);
