import React from 'react';
import loadingGif from 'common/assets/images/progress_dialog/loading.gif';
import PropTypes from 'prop-types';

const LoadingGif = ({
  classes,
}) => (
  <img
    src={loadingGif}
    alt="Carregando"
    className={classes.icon}
  />
);

LoadingGif.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default LoadingGif;
