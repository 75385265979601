import Axios from 'axios';
import { DEVELOPMENT } from 'common/utils/constants';

const axios = Axios.create({
  withCredentials: true,
});

axios.interceptors.response.use((response) => {
  return response;
}, (error) => {
  return Promise.reject(error.response ? error.response.data.error : error);
});

export const apiVersionPath = '/api/v2';
export const apiPath = process.env?.NODE_ENV === DEVELOPMENT
  ? 'http://localhost:8089/api/v2'
  : 'https://api.smarttinvest.com/api/v2';
export const activeCampaignApiPath = 'https://api-ac.smarttinvest.com/api/3';
export const smarttServerPrefix = '/ss';
export const MDSHost = 'marketdata-streamer.smarttinvest.com';
export const adminPath = `${apiPath}/admin`;
export const optimizationApi = 'https://api.smarttbot.com/portfolio-optimization/api/v1/optimize';

export default {
  activeCampaign: {
    createContact: (contact) => axios.post(
      `${activeCampaignApiPath}/contact/sync`, { contact },
    ),
    tagContact: (contactTag) => axios.post(
      `${activeCampaignApiPath}/contactTags`, { contactTag },
    ),
    listContact: (contactList) => axios.post(
      `${activeCampaignApiPath}/contactLists`, { contactList },
    ),
  },
  auth: {
    login: (config) => axios.post(`${apiPath}/auth/login`, config),
    validation: (config) => axios.post(`${apiPath}/auth/validation`, config),
    resendAccountValidationEmail: (config) => axios.post(`${apiPath}/auth/validation/resendEmail`, config),
    specialLogin: (config) => axios.post(`${apiPath}/auth/specialLogin`, config),
    logged: () => axios.get(`${apiPath}/auth/logged`),
    logout: () => axios.post(`${apiPath}/auth/logout`),
    signUp: (config) => axios.post(`${apiPath}/auth/registration/register`, config),
    rootSpecialLogin: (config) => axios.post(`${apiPath}/auth/rootSpecialLogin`, config),
    loginReturnAuthkey: (config) => axios.post(`${apiPath}/auth/login_return_auth_key`, config),
    requestPasswordReset: (payload) => axios.post(`${apiPath}/auth/passwordReset/request`, payload, {
      headers: {
        'X-App-Host': window.location.host,
      },
    }),
    resetExpiredPassword: (config) => axios.post(`${apiPath}/auth/passwordReset/resetExpired`, config),
    resetPassword: (config) => axios.post(`${apiPath}/auth/passwordReset/reset`, config),
    checkPassword: (data) => axios.post(`${apiPath}/auth/checkPassword`, data),
    getSessionTokenValidity: () => axios.get(`${apiPath}/auth/token2fa/valid`),
    generateToken2FA: (override = false) => axios.post(`${apiPath}/auth/token2fa/generate?override=${override}`),
    validateToken2FA: (config) => axios.post(`${apiPath}/auth/token2fa/validate`, config),
  },
  user: {
    getDayTradePerformance: (config) => axios.get(`${apiPath}/user/daytrade_performance`, config),
    rateCheck: (config) => axios.get(`${apiPath}/ratecheck`, config),
    getUserProfile: () => axios.get(`${apiPath}/user/profile`),
    updateUserProfile: (config) => axios.put(`${apiPath}/user/profile`, config),
    getUserStats: () => axios.get(`${apiPath}/user/stats`),
    getUserPreferences: () => axios.get(`${apiPath}/user/profile/preferences`),
    updateUserPreferences: (data) => axios.put(`${apiPath}/user/profile/preferences`, data),
    updateUserExtraData: (data) => axios.put(`${apiPath}/user/profile/extra`, data),
    getUserSetups: (config) => axios.get(`${apiPath}/${smarttServerPrefix}/getSetups`, config),
    insertSetup: (data) => axios.post(`${apiPath}/${smarttServerPrefix}/insertSetup`, data),
    updateSetup: (data) => axios.post(`${apiPath}/${smarttServerPrefix}/updateSetup`, data),
    getUserPaymentMethods: () => axios.get(`${apiPath}/billing/payment_methods/`),
    postUserPaymentMethod: (data) => axios.post(`${apiPath}/billing/payment_methods/`, data),
    deleteUserPaymentMethod: (id) => axios.delete(`${apiPath}/billing/payment_methods/${id}`),
    getUserSubscriptions: () => axios.get(`${apiPath}/billing/subscriptions`),
    applyDiscountCode: (code, data, config) => axios.post(`${apiPath}/billing/subscriptions/${code}/apply_discount_code`, data, config),
    updateSubscription: (code, data, config) => axios.put(`${apiPath}/billing/subscriptions/${code}`, data, config),
    cancelSubscriptionRenewal: (code, data) => axios.put(`${apiPath}/billing/subscriptions/${code}/cancel_renewal`, data),
    terms: {
      getLatestSignedTermVersion: () => axios.get(`${apiPath}/user/terms`),
      getLatestTermVersion: () => axios.get(`${apiPath}/user/terms/latest`),
      updateLatestSignedTermVersion: (data) => axios.post(`${apiPath}/user/terms`, data),
    },
    getUserAccess: (limit) => axios.get(`${apiPath}/user/events/login?limit=${limit}`),
  },

  stock: {
    getStocks: (config) => axios.get(`${apiPath}/${smarttServerPrefix}/getStocks`, config),
    getCurrentStockPrice: (stockCode, config) => axios.get(`https://${MDSHost}/data/candles/one/${stockCode}/`, config),
  },

  market: {
    getOperationsPeriod: () => axios.get(`${apiPath}/market/current_times`),
  },

  strategies: {
    getStrategy: (strategyId, config) => axios.get(`${apiPath}/strategies/${strategyId}`, config),
    getStrategies: (config) => axios.get(`${apiPath}/strategies`, config),
    instances: {
      createInstance: (data, config, variant = 'instances') => axios.post(`${apiPath}/strategies/${variant}`, data, config),
      getInstances: (config, variant = 'instances') => axios.get(`${apiPath}/strategies/${variant}`, config),
      getInstancesByStrategyId: (strategyId, variant = 'instances') => axios.get(`${apiPath}/strategies/${variant}?strategy_id=${strategyId}`),
      getInstanceState: (instanceId, config, variant = 'instances') => axios.get(`${apiPath}/strategies/${variant}/${instanceId}/state`, config),
      getInstance: (instanceId, config, variant = 'instances') => axios.get(`${apiPath}/strategies/${variant}/${instanceId}?force_update=true`, config),
      getInstanceSetup: (instanceId, config = { params: { return_attributes: 'code' } }, variant = 'instances') => axios.get(`${apiPath}/strategies/${variant}/${instanceId}/setup`, config),
      updateInstance: (instanceId, data, variant = 'instances') => axios.put(`${apiPath}/strategies/${variant}/${instanceId}`, data),
      deleteInstance: (instanceId, config) => axios.delete(`${apiPath}/strategies/instances/${instanceId}`, config),
      getInstanceDailyCumulativePerformance: (instanceId, config, variant = 'instances') => axios.get(`${apiPath}/strategies/${variant}/${instanceId}/daily_cumulative_performance`, config),
      getInstanceReport: (instanceId, config) => axios.get(`${apiPath}/strategies/instances/${instanceId}/report`, config),
      stopInstance: (instanceId, config) => axios.post(`${apiPath}/strategies/instances/${instanceId}/stop`, config),
      startInstance: (instanceId, config) => axios.post(`${apiPath}/strategies/instances/${instanceId}/start`, config),
      orders: {
        getOrders: (instanceId, config, variant = 'instances') => axios.get(`${apiPath}/strategies/${variant}/${instanceId}/orders`, config),
        cancelOrder: (instanceId, orderId, config) => axios.post(`${apiPath}/strategies/instances/${instanceId}/cancelOrder/${orderId}`, config),
        createOrder: (instanceId, data) => axios.post(`${apiPath}/strategies/instances/${instanceId}/createOrder`, data),
        cancelAllOrders: (instanceId, config) => axios.put(`${apiPath}/strategies/instances/${instanceId}/cancelAllOrders`, config),
        getOrderEvents: (instanceId, config, variant = 'instances') => axios.get(`${apiPath}/strategies/${variant}/${instanceId}/orders_events`, config),
        insertExternalOrder: (instanceId, data) => axios.post(`${apiPath}${smarttServerPrefix}/insertExternalOrder`, data),
        updateExternalOrder: (data) => axios.post(`${apiPath}${smarttServerPrefix}/updateExternalOrder`, data),
      },
      positions: {
        getPositions: (instanceId, config, variant = 'instances') => axios.get(`${apiPath}/strategies/${variant}/${instanceId}/portfolio`, config),
        resetAllPositions: (instanceId, config) => axios.put(`${apiPath}/strategies/instances/${instanceId}/resetAllPositions`, config),
        resetPosition: (instanceId, config) => axios.put(`${apiPath}/strategies/instances/${instanceId}/resetPosition`, config),
      },
    },
    scoreBoard: {
      getScoreBoard: () => axios.get(`${apiPath}/strategies/scoreboards/ranking-smarttinvest?report_periods=6,12`),
    },
  },

  investments: {
    updateInvestment: (data) => axios.post(`${apiPath}/${smarttServerPrefix}/updateInvestment`, data),
    getFinancialTransactions: (config) => axios.get(`${apiPath}/${smarttServerPrefix}/getFinancialTransactions`, config),
    insertFinancialTransaction: (data) => axios.post(`${apiPath}/${smarttServerPrefix}/insertFinancialTransaction`, data),
  },

  brokerages: {
    getActivatedBrokerages: (config) => axios.get(`${apiPath}/${smarttServerPrefix}/getActivatedBrokerages`, config),
    getClientBrokerages: (config) => axios.get(`${apiPath}/${smarttServerPrefix}/getClientBrokerages`, config),
    insertClientBrokerage: (data) => axios.post(`${apiPath}/${smarttServerPrefix}/insertClientBrokerage`, data),
  },

  plans: {
    getPlans: (config) => axios.get(`${apiPath}/billing/plans`, config),
    getPlan: (plan, config) => axios.get(`${apiPath}/billing/plans/${plan}`, config),
    signPlan: (plan) => axios.post(`${apiPath}/billing/checkout/${plan}`),
  },

  invoices: {
    getAllInvoices: (config) => axios.get(`${apiPath}/billing/invoices`, config),
    chargeInvoice: (id, data) => axios.put(`${apiPath}/billing/invoices/${id}/charge`, data),
  },

  billing: {
    getDiscount: (plan, code, config) => axios.get(`${apiPath}/billing/discountcodes/${plan}/${code}/`, config),
    checkout: (plan, data) => axios.post(`${apiPath}/billing/checkout/${plan}`, data),
    cancellation: (type) => axios.get(`${apiPath}/billing/credit/cancellation`, type),
    credit: (type) => axios.get(`${apiPath}/billing/credit`, type),
    recurrency: () => axios.get(`${apiPath}/billing/invoices/discountRecurrency`),
  },

  cep: {
    getValidateCep: (cep) => axios.get(`https://api.smarttinvest.com/api/cep/${cep}/json`, {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
      },
    }),
  },

  store: {
    getStoreItems: (isPublic) => axios.get(`${apiPath}/strategies/store_items${isPublic ? '/public?platform_id=5900' : ''}`),
  },

  suggestedWallets: {
    leastRisk: (data) => axios.post(`${optimizationApi}/sb_1`, data),
    bestReturnRisk: (data) => axios.post(`${optimizationApi}/sb_2`, data),
    greatestReturn: (data) => axios.post(`${optimizationApi}/sb_3`, data),
  },

  chart: {
    // MDS is a short for Market Data Streamer
    getMDSSocket: () => new WebSocket(`wss://${MDSHost}/rtdata`),
    getCredentials: () => axios.get(`${apiPath}/chart/credentials`),
    getStockCode: (stockCode, config) => axios.get(`https://${MDSHost}/data/stocks/${stockCode}/`, config),
    getStockCandle: (stockCode, config) => axios.get(`https://${MDSHost}/data/candles/day_without_after/${stockCode}/`, config),
  },

  holidays: {
    getHolidays: () => Axios.create({
      withCredentials: false,
    }).get(' https://public-market-data.smarttbot.com/b3/holidays.json'),
  },
};
