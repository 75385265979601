import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { withStyles } from '@material-ui/styles';
import { success, error } from 'react-notification-system-redux';

import {
  login, rateCheck, resendAccountValidationEmail, logout, specialLogin,
} from 'common/ducks/auth';

import Login from './Login';

const styles = {
  form: {
    display: 'flex',
    flexDirection: 'column',
    maxWidth: 300,
  },
  button: {
    padding: '2px 23px',
    margin: '20px 0',
  },
  noAccountText: {
    color: '#9b9a9f',
  },
};

const mapDispatchToProps = (dispatch) => ({
  login: (data) => dispatch(login(data)),
  specialLogin: (data) => dispatch(specialLogin(data)),
  rateCheck: (data) => dispatch(rateCheck(data)),
  resendAccountValidationEmail: (data) => dispatch(resendAccountValidationEmail(data)),
  logout: () => dispatch(logout()),
  notifyError: (data) => dispatch(error(data)),
  notifySuccess: (data) => dispatch(success(data)),
});

const mapStateToProps = ({ auth: { logged, forced_external_logout } }) => ({
  logged,
  forcedExternalLogout: forced_external_logout,
});

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps,
)(withStyles(styles)(Login)));
