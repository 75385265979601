import React, { memo } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useSelector } from 'react-redux';
import {
  Card,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Typography,
  Box,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import DisplayNumber from 'common/components/DisplayNumber';
import { MONTH_KEYS } from 'common/utils/constants/date';
import { isNotUndefinedAndNotNull } from 'common/utils/functions';
import { getProfitabilityData, validateIfInstancePortfolio } from 'common/utils/instance';
import { INSTANCE } from 'common/utils/constants/instance';
import { BENCHMARK } from 'common/utils/constants/benchmarks';
import { useBenchmarkFilter } from 'common/hooks/benchmark';
import BenchmarkFilter from 'common/components/BenchmarkFilter';
import { getBenchmarkDataFromSameInstancePeriod } from 'common/utils/benchmarks';

const InstanceAbsoluteProfitability = ({
  classes,
  profitabilityData,
  isMobile,
  arrayYearsBacktest,
  backtestInstance,
  hasCustomInstanceBenchmark,
}) => {
  const dailyCumulativePerformance = useSelector((state) => state.dailyCumulativePerformance);
  const { selectedBenchmark, handleChangeBenchmarkFilter } = useBenchmarkFilter();
  const benchmarkDataCumulative = dailyCumulativePerformance[selectedBenchmark.instanceId].data;

  const finalBenchmarkData = getBenchmarkDataFromSameInstancePeriod({
    instanceDataCumulative: backtestInstance,
    benchmarkDataCumulative,
    isCustomBacktest: hasCustomInstanceBenchmark,
  });

  const benchmarkData = getProfitabilityData({
    data: finalBenchmarkData,
    yearsList: arrayYearsBacktest,
    isBenchmark: true,
  });

  const renderTableCellYear = () => (
    <TableCell
      align="center"
      className={classNames(
        classes.tableCell,
        classes.yearTableCell,
        classes.borderRadiusTop,
        classes.tableHead,
      )}
    >
      ano
    </TableCell>
  );

  const mobileContent = Object.keys(profitabilityData.years).map((year, indexYear) => {
    const { months, yearProfitability } = profitabilityData.years[year];
    const {
      months: monthsBenchmark,
      yearProfitability: yearBenchmark,
    } = benchmarkData.years[year];

    return (
      <Accordion data-testid="expansion-panel" key={year} className={classes.expansionPanel}>
        <AccordionSummary
          classes={{
            content: classNames(
              classes.alignFlexBetween,
              classes.panelSummaryContent,
            ),
            root: classes.expansionPanelRoot,
          }}
          expandIcon={<ExpandMoreIcon />}
        >
          <Typography variant="h5" className={classes.heading}>
            Carteira
            {' '}
            {year}
          </Typography>
          <DisplayNumber
            colorful
            percentual
            precision={2}
            scale={1}
            symbol="%"
            value={parseFloat(yearProfitability) * 100}
          />
        </AccordionSummary>
        <AccordionDetails classes={{ root: classes.displayBlock }}>
          <Box pr={4} display="flex" alignItems="center" justifyContent="space-between" mb={1}>
            <Typography variant="h5" className={classes.heading}>
              {selectedBenchmark.name}
            </Typography>
            <DisplayNumber
              colorful={false}
              percentual
              precision={2}
              scale={1}
              symbol="%"
              value={parseFloat(yearBenchmark) * 100}
            />
          </Box>
          {Object.keys(months).map((month, indexMonth) => {
            return [INSTANCE, BENCHMARK].map((item) => {
              const isInstancePortfolio = validateIfInstancePortfolio(item);
              const monthsParam = isInstancePortfolio
                ? months
                : monthsBenchmark;

              const value = monthsParam[month]?.profitability;
              const { fullname } = MONTH_KEYS[month];

              return isNotUndefinedAndNotNull(value) && (
              <div
                className={classNames(classes.alignFlexBetween, classes.itemMonth)}
                key={`${item}-${month.key}-${indexYear}-${indexMonth}`}//eslint-disable-line
              >
                <Typography className={classes.monthName} variant="h6">
                  {isInstancePortfolio ? fullname : selectedBenchmark.name}
                </Typography>
                <DisplayNumber
                  colorful={isInstancePortfolio}
                  percentual
                  precision={2}
                  scale={0.875}
                  symbol="%"
                  value={parseFloat(value) * 100}
                />
              </div>
              );
            });
          })}
        </AccordionDetails>
      </Accordion>
    );
  });

  const desktopContent = (
    <>
      <Card data-testid="card-table" className={classNames(classes.card, classes.cardSpacing)}>
        <Table>
          <TableHead>
            <TableRow>
              {renderTableCellYear()}
              {Object.values(MONTH_KEYS).map((item) => (
                <TableCell
                  key={item.key}
                  align="center"
                  className={classNames(
                    classes.tableCell,
                    classes.tableHead,
                  )}
                >
                  {item.shortname}
                </TableCell>
              ))}
              {renderTableCellYear()}
            </TableRow>
          </TableHead>

          <TableBody>
            {Object.keys(profitabilityData.years).map((year, indexYear) => {
              const { months, yearProfitability } = profitabilityData.years[year];
              const {
                months: monthsBenchmark,
                yearProfitability: yearBenchmark,
              } = benchmarkData.years[year];

              return [INSTANCE, BENCHMARK].map((item) => {
                const isInstancePortfolio = validateIfInstancePortfolio(item);

                const yearProfitabilityValue = isInstancePortfolio
                  ? yearProfitability
                  : yearBenchmark;

                return (
                  <TableRow
                    key={isInstancePortfolio
                      ? year
                      : `${selectedBenchmark.name}-${indexYear}`}
                    className={classes.tableRow}
                    data-testid="yearRow"
                  >
                    <TableCell
                      align="center"
                      className={classNames(
                        classes.tableCell,
                        classes.yearTableCell,
                      )}
                      data-testid="tableBodyColumn"
                    >
                      {isInstancePortfolio ? year : selectedBenchmark.name}
                    </TableCell>
                    {Object.keys(MONTH_KEYS).map((month, indexMonth) => {
                      const monthsParam = isInstancePortfolio
                        ? months
                        : monthsBenchmark;

                      const value = monthsParam[month]?.profitability;

                      return (
                        <TableCell
                          key={`${month.key}-${indexYear}-${indexMonth}`} // eslint-disable-line
                          align="center"
                          className={classes.tableCell}
                          data-testid="tableBodyColumn"
                        >
                          {isNotUndefinedAndNotNull(value) ? (
                            <DisplayNumber
                              colorful={isInstancePortfolio}
                              percentual
                              precision={2}
                              scale={0.75}
                              value={parseFloat(value) * 100}
                            />
                          ) : ''}
                        </TableCell>
                      );
                    })}
                    <TableCell
                      align="center"
                      className={classNames(
                        classes.tableCell,
                        classes.yearTableCell,
                      )}
                      data-testid="tableBodyColumn"
                    >
                      <DisplayNumber
                        colorful={isInstancePortfolio}
                        percentual
                        precision={2}
                        scale={0.75}
                        value={parseFloat(yearProfitabilityValue) * 100}
                      />
                    </TableCell>
                  </TableRow>
                );
              });
            })}
          </TableBody>
        </Table>

        <Box mt={2}>
          <Typography color="textSecondary" variant="h6">
            A rentabilidade apresentada é baseada em simulações com dados históricos. Não há
            qualquer garantia em relação aos valores aqui exibidos, nem garantia de qualquer
            resultado futuro.
          </Typography>
        </Box>
      </Card>
    </>
  );

  const content = isMobile ? mobileContent : desktopContent;

  return (
    <>
      <Box
        className={classes.filterBox}
        mb={2}
      >
        <Typography variant="h3" className={classes.cardTitle}>
          Rentabilidade absoluta %
        </Typography>

        <BenchmarkFilter onChange={handleChangeBenchmarkFilter} />
      </Box>

      {content}
    </>
  );
};

InstanceAbsoluteProfitability.propTypes = {
  classes: PropTypes.object.isRequired,
  profitabilityData: PropTypes.object.isRequired,
  arrayYearsBacktest: PropTypes.array.isRequired,
  isMobile: PropTypes.bool.isRequired,
  backtestInstance: PropTypes.array,
  hasCustomInstanceBenchmark: PropTypes.bool,
};

InstanceAbsoluteProfitability.defaultProps = {
  hasCustomInstanceBenchmark: false,
  backtestInstance: [],
};

export default memo(InstanceAbsoluteProfitability);
