import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import FontAwesomeIcon from '@fortawesome/react-fontawesome';
import faPlusCircle from '@fortawesome/fontawesome-free-solid/faPlusCircle';

import IconButton from '@material-ui/core/IconButton';

const LabelAction = ({
  classes,
  logo,
  action,
  description,
  className,
}) => (
  <div className={classes.label}>
    <div className={classes.logo}>
      {logo}
    </div>
    <div className={classes.descriptionContainer}>
      <div className={classNames(classes.description, className)}>
        {description}
      </div>
    </div>
    <div>
      <div>{action}</div>
    </div>
  </div>
);

LabelAction.propTypes = {
  classes: PropTypes.object.isRequired,
  logo: PropTypes.any,
  action: PropTypes.any,
  description: PropTypes.string.isRequired,
  className: PropTypes.string,
};

LabelAction.defaultProps = {
  className: '',
  logo: '',
  action: (
    <IconButton>
      <FontAwesomeIcon icon={faPlusCircle} size="xs" />
    </IconButton>
  ),
};

export default LabelAction;
