import * as Yup from 'yup';
import { REQUIRED_TEXT } from '../constants';

export const getPositionSchemaValidation = (initialValues) => {
  const schema = Object.keys(initialValues).reduce((acc, cur) => ({
    ...acc,
    [cur]: Yup.string().required(REQUIRED_TEXT),
  }), {});

  const positionValidationSchema = Yup.object().shape(schema);
  const validationSchema = Yup.object().shape({
    positions: Yup.array().of(positionValidationSchema),
  });

  return validationSchema;
};

export default null;
