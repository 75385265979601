import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { error as notifyError } from 'react-notification-system-redux';
import {
  InputAdornment,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
  CircularProgress,
} from '@material-ui/core';
import LockIcon from '@material-ui/icons/Lock';
import api from 'common/api';
import { INVALID_PASSWORD_TEXT, ERROR } from 'common/utils/constants';
import { isLoginError } from 'common/utils/validation';
import Field from 'common/components/FormFields/Field';

export const PASSWORD_CONFIRM_DIALOG_CODE = 'PASSWORD_CONFIRM_DIALOG_CODE';

const PasswordConfirmDialog = ({
  classes,
  onCancelClick,
  open,
  onClose,
  onConfirmClick,
  contentText,
  confirmButtonContent,
  cancelButtonContent,
}) => {
  const dispatch = useDispatch();
  const [password, setPassword] = useState('');
  const [loading, setLoading] = useState(false);

  const handleInputChange = (event) => {
    setPassword(event.target.value);
  };

  const triggerConfirmClick = async () => {
    setLoading(true);
    if (onConfirmClick) {
      try {
        await api.auth.checkPassword({ password });
        await onConfirmClick(password, onClose);
      } catch (error) {
        setLoading(false);
        if (isLoginError(error)) {
          dispatch(notifyError({
            title: ERROR,
            message: INVALID_PASSWORD_TEXT,
          }));
        }
      }
    } else {
      onClose();
    }

    setLoading(false);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    triggerConfirmClick();
  };

  return (
    <Dialog
      disableBackdropClick
      className={classes.dialog}
      open={open}
      onClose={onCancelClick ? () => onCancelClick(onClose) : onClose}
    >
      <DialogTitle disableTypography className={classes.dialogTitle}>
        Confirme sua senha
      </DialogTitle>
      <DialogContent className={classes.dialogContent}>
        <Typography variant="h6" color="textSecondary" paragraph>
          {contentText
            || 'Para sua segurança confirme sua senha para executar a ação.'}
        </Typography>
        <form
          onSubmit={handleSubmit}
        >
          <Field
            name="password"
            fullWidth
            autoFocus
            id="password-confirm-dialog-login-password"
            type="password"
            placeholder="Senha"
            value={password}
            handleChange={handleInputChange}
            startAdornment={(
              <InputAdornment
                position="start"
                className={classes.icon}
              >
                <LockIcon fontSize="small" />
              </InputAdornment>
            )}
          />
        </form>
      </DialogContent>
      <DialogActions>
        <Button
          id="password-confirm-dialog-cancel-click"
          onClick={onCancelClick ? () => onCancelClick(onClose) : onClose}
          autoFocus
        >
          {cancelButtonContent || 'Cancelar'}
        </Button>

        <Button
          id="password-confirm-dialog-confirm-click"
          onClick={triggerConfirmClick}
          disabled={password.length < 6 || loading}
          color="primary"
          variant="contained"
          autoFocus
        >
          {loading
            && (
              <CircularProgress
                size={20}
                thickness={7}
                className={classes.loading}
              />
            )}
          {confirmButtonContent || 'Confirmar'}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

PasswordConfirmDialog.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  open: PropTypes.bool.isRequired,
  onConfirmClick: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  onCancelClick: PropTypes.func,
  contentText: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.node,
  ]),
  cancelButtonContent: PropTypes.any,
  confirmButtonContent: PropTypes.any,
};

PasswordConfirmDialog.defaultProps = {
  onCancelClick: null,
  contentText: null,
  cancelButtonContent: null,
  confirmButtonContent: null,
};

export default PasswordConfirmDialog;
