import { withStyles } from '@material-ui/styles';
import { connect } from 'react-redux';

import { updateInstanceReport } from 'common/ducks/report';
import { generalFilter } from 'common/utils/styles/filter';
import InstanceMetricsCard from './InstanceMetricsCard';

const styles = {
  card: {
    boxShadow: '0 3px 6px 0 rgba(0, 0, 0, 0.16)',
  },
  cardSpacing: {
    padding: 25,
  },
  performanceGridWrapper: {
    '@media(max-width: 768px)': {
      '& > .MuiGrid-item': {
        paddingBottom: 0,
        paddingTop: 0,
      },
    },
  },
  performanceItem: {
    alignItems: 'center',
    borderBottom: '1px solid #d1d1d1',
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: 8,
    paddingBottom: 8,
  },
  bold: {
    fontWeight: 'bold',
  },
  filterBox: generalFilter,
};

const mapStateToProps = ({ dailyCumulativePerformance, report }) => ({
  dailyCumulativePerformance: dailyCumulativePerformance || {},
  report,
});

const mapDispathToProps = (dispatch) => ({
  updateInstanceReport: (instanceId) => dispatch(updateInstanceReport(instanceId)),
});

export default connect(mapStateToProps, mapDispathToProps)(withStyles(styles)(InstanceMetricsCard));
