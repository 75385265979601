export const INITIAL_STATE = {
  currentTab: 'executing',
  executing: {
    // The number that appears at the right side of the label
    badgeCounter: 0,
    // Array of instance id's that was recently changed on the tab
    recentlyMoved: [],
  },
  stopped: {
    // The number that appears at the right side of the label
    badgeCounter: 0,
    // Array of instance id's that was recently changed on the tab
    recentlyMoved: [],
  },
};

export const DUCK_NAME = 'tabs';
export const NAMESPACE = `user/${DUCK_NAME}`;

// Actions
export const SET_CURRENT_TAB = `${NAMESPACE}/SET_CURRENT_TAB`;
export const CLEAN_TAB_BADGE_COUNTER = `${NAMESPACE}/CLEAN_TAB_BADGE_COUNTER`;
export const ADD_TAB_BADGE_COUNTER = `${NAMESPACE}/ADD_TAB_BADGE_COUNTER`;
export const CLEAN_TAB_RECENTLY_MOVED = `${NAMESPACE}/CLEAN_TAB_RECENTLY_MOVED`;
export const ADD_TAB_RECENTLY_MOVED = `${NAMESPACE}/ADD_TAB_RECENTLY_MOVED`;
export const REMOVE_TAB_RECENTLY_MOVED = `${NAMESPACE}/REMOVE_TAB_RECENTLY_MOVED`;

// Action Creators
export const setCurrentTab = (tab) => ({
  tab,
  type: SET_CURRENT_TAB,
});
export const cleanTabBadgeCounter = (tab) => ({
  tab,
  type: CLEAN_TAB_BADGE_COUNTER,
});
export const addTabBadgeCounter = (tab) => ({
  tab,
  type: ADD_TAB_BADGE_COUNTER,
});

export const cleanTabRecentlyMoved = (tab) => ({
  tab,
  type: CLEAN_TAB_RECENTLY_MOVED,
});
export const addTabRecentlyMoved = (tab, id) => ({
  tab,
  id,
  type: ADD_TAB_RECENTLY_MOVED,
});
export const removeTabRecentlyMoved = (tab, id) => ({
  tab,
  id,
  type: REMOVE_TAB_RECENTLY_MOVED,
});

// Reducer
export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SET_CURRENT_TAB:
      return {
        ...state,
        [action.mode]: {
          ...state[action.mode],
          currentTab: action.tab,
        },
      };
    default:
      return state;
  }
};
