import { withStyles } from '@material-ui/styles';
import palette from 'common/palette';
import { generalFilter } from 'common/utils/styles/filter';
import InstanceAbsoluteProfitability from './InstanceAbsoluteProfitability';

const styles = {
  alignFlexBetween: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'space-between',
  },
  borderRadiusTop: {
    borderTopLeftRadius: 6,
    borderTopRightRadius: 6,
  },
  card: {
    boxShadow: '0 3px 6px 0 rgba(0, 0, 0, 0.16)',
  },
  cardSpacing: {
    padding: 15,
  },
  displayBlock: {
    display: 'block',
  },
  expansionPanel: {
    borderRadius: 8,
    boxShadow: '0 3px 6px 0 rgba(146, 146, 146, 0.36)',
    marginBottom: 15,
    '&:before': {
      display: 'none',
    },
  },
  expansionPanelRoot: {
    '&.Mui-expanded': {
      minHeight: 45,
    },
  },
  heading: {
    fontWeight: 'bold',
  },
  itemMonth: {
    padding: '10px 32px 10px 0',

    '&:nth-child(odd):not(:last-of-type)': {
      borderBottom: '1px solid #d1d1d1',
    },
  },
  monthName: {
    fontWeight: '400',
    '&::first-letter': {
      textTransform: 'uppercase',
    },
  },
  panelSummaryContent: {
    '&.Mui-expanded': {
      margin: '12px 0',
    },
  },
  tableCell: {
    borderBottom: 0,
    color: palette.textSecondary.main,
    fontSize: 12,
    padding: '12px 5px',

    '@media(max-width:850px)': {
      padding: '12px 3px',
    },
    '@media(min-width:1300px)': {
      padding: '12px 7px',
    },
    '@media(min-width:1455px)': {
      minWidth: 55,
    },
  },
  tableRow: {
    '&:last-of-type .MuiTableCell-root': {
      borderBottom: 0,
    },

    '&:last-of-type td:first-of-type': {
      borderBottomLeftRadius: 6,
      borderBottomRightRadius: 6,
    },

    '&:last-of-type td:last-of-type': {
      borderBottomLeftRadius: 6,
      borderBottomRightRadius: 6,
    },

    '&:nth-child(even)': {
      '& td': {
        borderBottom: '1px solid #d8d8d8',
      },
    },

  },
  yearTableCell: {
    backgroundColor: '#eee',
  },
  tableHead: {
    borderBottom: '1px solid #d8d8d8',
  },
  filterBox: generalFilter,
};

export default withStyles(styles)(InstanceAbsoluteProfitability);
