import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Box, Icon } from '@material-ui/core';
import ArrowTooltip from 'common/components/ArrowTooltip';
import {
  getIsRebalanceButtonActive,
  getIsStocksEquals,
  getRebalanceButtonTooltip,
} from 'common/utils/whitebox';
import { useHandleOpenOrdersDialog } from 'common/hooks/orders';
import { getStocksAndOldStocksFromPositions } from 'common/utils/stocks';
import BlueButton from '../BlueButton';

const RebalanceButton = ({
  classes,
  instance,
  whitebox,
  positions,
  openRebalanceStocksDialog,
}) => {
  const objValuesStocks = Object.values(whitebox.stocks);
  const handleOpenOrdersDialog = useHandleOpenOrdersDialog();

  const isStocksEquals = useMemo(() => {
    return getIsStocksEquals({
      instanceStocks: Object.values(instance.stocks),
      whiteboxStocks: objValuesStocks,
    });
  }, [objValuesStocks, instance]);

  const handleClickButton = () => {
    const { stocks, oldStocks } = getStocksAndOldStocksFromPositions({
      positions,
      instance,
      whitebox: whitebox.stocks,
    });

    openRebalanceStocksDialog({
      instanceId: instance.id,
      stocks,
      oldStocks,
      handleOpenOrdersDialog,
    });
  };

  return (
    <ArrowTooltip
      title={(
        <Box textAlign="center">
          {getRebalanceButtonTooltip({
            instanceStocks: Object.values(instance.stocks),
            whiteboxStocks: objValuesStocks,
            totalWeight: whitebox.totalWeight,
          })}
        </Box>
  )}
      placement="bottom"
      interactive
    >
      <span>
        <BlueButton
          className={classes.rebalanceButton}
          id="rebalance-instance"
          variant="contained"
          color="primary"
          onClick={handleClickButton}
          disabled={!getIsRebalanceButtonActive({
            stocks: objValuesStocks,
            totalWeight: whitebox.totalWeight,
          })}
        >
          <Icon className={classes.iconButton}>
            {isStocksEquals ? 'donut_small' : 'swap_horiz'}
          </Icon>
          <span>
            {isStocksEquals ? 'Rebalancear' : 'Aplicar'}
          </span>
        </BlueButton>
      </span>
    </ArrowTooltip>
  );
};

RebalanceButton.propTypes = {
  classes: PropTypes.object.isRequired,
  instance: PropTypes.object.isRequired,
  whitebox: PropTypes.object.isRequired,
  positions: PropTypes.object.isRequired,
  openRebalanceStocksDialog: PropTypes.func.isRequired,
  rebalanceInstance: PropTypes.func.isRequired,
  openConfirmationDialog: PropTypes.func.isRequired,
};

export default RebalanceButton;
