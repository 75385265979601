import { createNumberMask, createTextMask } from 'redux-form-input-masks';
import accounting from 'accounting';

const currencyMask = (
  prefix = `${accounting.settings.currency.symbol} `,
  suffix = '',
  decimalPlaces = accounting.settings.currency.precision,
  locale = 'pt-BR',
) => createNumberMask({
  prefix,
  suffix,
  decimalPlaces,
  locale,
});

const numberMask = () => createNumberMask();

const textMask = (pattern, guide = false, stripMask = false) => createTextMask({
  pattern,
  guide,
  stripMask,
});

export { currencyMask, numberMask, textMask };
export default null;
