import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import accounting from 'accounting';
import { Box, Typography } from '@material-ui/core';

import { SI_ZENDESK_ARTICLES_URL } from 'common/utils/constants';
import { BUY } from 'common/utils/constants/stocks';
import iconHelp from 'common/assets/images/help-icon.svg';
import { getOrderTypeName } from 'common/utils/orders';
import ArrowTooltip from 'common/components/ArrowTooltip';
import ConfirmValues from 'common/components/ConfirmValues';

const StockOperationConfirmation = ({
  classes,
  data,
  brokerageId,
}) => {
  const { payload } = data;
  const brokerageName = useSelector(
    ({ brokerages }) => brokerages.data?.allBrokerages[brokerageId]?.name,
  );
  const orderType = getOrderTypeName(payload.orderType);

  const confirmationFields = {
    brokerage: {
      id: 'brokerage',
      label: 'Corretora:',
      value: brokerageName,
    },
    stock: {
      id: 'stock',
      label: 'Ativo:',
      value: (
        <Box>
          {payload.code}
          &nbsp;
          <ArrowTooltip
            title={(
              <div>
                O código do ativo pode variar em negociações no mercado cheio e fracionário.&nbsp;
                <a
                  href={`${SI_ZENDESK_ARTICLES_URL}/4419869870103`}
                  target="_blank"
                  rel="noopener noreferrer"
                  className={classes.link}
                >
                  Saiba mais.
                </a>
              </div>
                  )}
            disableFocusListener
            disableTouchListener
            interactive
          >
            <img src={iconHelp} alt="tooltip" />
          </ArrowTooltip>
        </Box>),
    },
    quantity: {
      id: 'quantity',
      label: 'Quantidade:',
      value: (
        <Box display="flex">
          <div className={orderType === BUY ? classes.buy : classes.sell}>
            {orderType}
            &nbsp;
          </div>
          |
          {' '}
          {payload.numberOfStocks}
        </Box>),
    },
    price: {
      id: 'price',
      label: 'Preço de envio:',
      value: payload.bestAvailablePriceToggled ? 'Melhor preço disponível' : `R$ ${accounting.formatNumber(Number.parseFloat(payload.value), 2)}`,
    },
    volume: {
      id: 'volume',
      label: 'Volume total:',
      value: `R$ ${accounting.formatNumber(Number.parseFloat(payload.totalOrderVolume), 2)}`,
    },
  };

  return (
    <Box>
      <Typography color="textSecondary" variant="h6" data-testid="confirmation-text">
        Você está enviando uma ordem de
        {' '}
        { orderType.toLowerCase() }
        {' '}
        para a sua corretora.
      </Typography>
      <Box mt={4}>
        <ConfirmValues
          hasTitle={false}
          values={confirmationFields}
        />
      </Box>
    </Box>
  );
};

StockOperationConfirmation.propTypes = {
  classes: PropTypes.object.isRequired,
  data: PropTypes.object.isRequired,
  brokerageId: PropTypes.number.isRequired,
};

export default StockOperationConfirmation;
