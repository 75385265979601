import React from 'react';
import PropTypes from 'prop-types';

import { useStoreInstanceWithPlanLimit } from 'common/hooks/storeItem';
import { useCurrentTotalEquity } from 'common/hooks/stocks';
import FinancialValueForm from './FinancialValueForm';

const FinancialValue = ({
  dataSteps,
  onSubmit,
  strategyId,
  templateId,
  setDataSteps,
}) => {
  const { data: { positions } } = dataSteps;
  const { equity, loadingEquity } = useCurrentTotalEquity(positions,
    setDataSteps, dataSteps?.data?.contribution);
  const storeInstance = useStoreInstanceWithPlanLimit(templateId, strategyId);

  return (
    <FinancialValueForm
      equity={equity}
      loadingEquity={loadingEquity}
      onSubmit={onSubmit}
      templateId={templateId}
      strategyId={strategyId}
      storeInstance={storeInstance}
      dataSteps={dataSteps}
      setDataSteps={setDataSteps}
    />
  );
};

FinancialValue.propTypes = {
  dataSteps: PropTypes.object.isRequired,
  onSubmit: PropTypes.func.isRequired,
  strategyId: PropTypes.number.isRequired,
  setDataSteps: PropTypes.func.isRequired,
  templateId: PropTypes.number,
};

FinancialValue.defaultProps = {
  templateId: null,
};

export default FinancialValue;
