import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import {
  reloadInstanceState,
  stopInstance,
  restartInstance,
} from 'common/ducks/instance';
import { cancelInstanceOrders } from 'common/ducks/orders';

import { openDialog, closeDialog } from 'common/ducks/dialogs';
import { PROGRESS_DIALOG_CODE } from 'common/components/Dialogs/ProgressDialog';
import PendingOrdersDialog from './PendingOrdersDialog';
import { CANCEL_ORDER_DIALOG_CODE } from './CancelOrderDialog';
import { PASSWORD_CONFIRM_DIALOG_CODE } from '../../PasswordConfirmDialog';

const mapStateToProps = ({ instances }, { id }) => ({
  instance: instances.data[id],
});

const mapDispatchToProps = (dispatch, { id, history }) => ({
  reloadInstanceState: () => dispatch(reloadInstanceState(id)),
  cancelInstanceOrders: (...args) => dispatch(cancelInstanceOrders(id, ...args)),
  stopInstance: (options) => dispatch(stopInstance(id, history, null, options)),
  restartInstance: (options) => dispatch(restartInstance(id, history, options)),
  openProgressDialog: (props) => dispatch(openDialog(PROGRESS_DIALOG_CODE, { ...props })),
  openCancelOrderDialog: (props) => dispatch(openDialog(CANCEL_ORDER_DIALOG_CODE, { ...props })),
  openPasswordConfirmDialog: (props) => dispatch(
    openDialog(PASSWORD_CONFIRM_DIALOG_CODE, { ...props }),
  ),
  onCloseDialog: () => dispatch(closeDialog()),
});

export const PENDING_ORDERS_DIALOG_CODE = 'PENDING_ORDERS_DIALOG_CODE';
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(PendingOrdersDialog));
