export const generalFilter = {
  display: 'flex',
  alignItems: 'flex-start',

  '@media(min-width:768px)': {
    alignItems: 'center',
    justifyContent: 'space-between',
  },

  '@media(max-width:767px)': {
    flexDirection: 'column',
  },
};

export default null;
