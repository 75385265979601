import { connect } from 'react-redux';
import { warning as notifyWarning } from 'react-notification-system-redux';
import { withStyles } from '@material-ui/styles';

import theme from 'common/theme';
import palette from 'common/palette';
import StockOperationDetails from './StockOperationDetails';

const triangleSize = '10px';
const transparentBorder = `${triangleSize} solid transparent`;
const coloredBorder = `${triangleSize} solid #b4b6b8`;

const styles = {
  button: {
    textTransform: 'uppercase',
  },
  triangle: {
    borderLeft: transparentBorder,
    borderRight: transparentBorder,
    cursor: 'pointer',
    height: 0,
    width: 0,
  },
  triangleBottom: {
    borderTop: coloredBorder,
    marginTop: 5,
  },
  triangleTop: {
    borderBottom: coloredBorder,
  },
  errorNumberOfStocks: {
    width: 335,
    fontSize: 13,
    [theme.breakpoints.up('md')]: {
      width: 390,
    },
    '@media(max-width: 350px)': {
      width: 280,
    },
  },
  valuesBox: {
    backgroundColor: '#f0f0f0',
    borderRadius: 10,
    padding: 20,
  },
  numberOfStocksBox: {
    display: 'flex',
    flexDirection: 'column',
    marginRight: 16,

    '@media(min-width: 371px)': {
      alignItems: 'flex-end',
    },
  },
  numberOfStocks: {
    fontSize: 13,
    color: palette.textSecondary.main,
  },
  closeBox: {
    display: 'flex',
    height: 40,
    width: 40,
    justifyContent: 'center',
    alignItems: 'center',
    border: `1px solid ${palette.secondary.main}`,
    borderRadius: 6,
    marginRight: 6,
    cursor: 'pointer',
  },
  closeIcon: {
    fontSize: 24,
    color: palette.secondary.main,
  },
  helperText: {
    marginTop: 8,
    whiteSpace: 'nowrap',
  },
};

const mapStateToProps = ({
  report,
  positions,
}, { instanceId }) => ({
  report: report[instanceId].data,
  positions,
});

const mapDispatchToProps = (dispatch) => ({
  notifyWarning: (data) => dispatch(notifyWarning(data)),
  closedMarketStatus: {
    width: 18,
    height: 18,
    borderRadius: 6,
    backgroundColor: 'red',
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(
  withStyles(styles)(StockOperationDetails),
);
