import { withStyles } from '@material-ui/styles';
import palette from 'common/palette';

import HiddenList from './HiddenList';

const styles = {
  arrow: {
    height: '6px',
    position: 'absolute',
    right: '-15px',
    top: '35%',
    transform: 'rotate(-45deg) translateY(-35%)',
    width: '6px',

    '&:before': {
      content: '""',
      width: '100%',
      height: '100%',
      borderWidth: '0 0 .6vmin .6vmin',
      borderStyle: 'solid',
      borderColor: palette.textSecondary.main,
      transition: '.2s ease',
      display: 'block',
      transformOrigin: '100% 0',
    },
  },
  arrowActive: {
    top: '40%',
    transform: 'rotate(135deg) translateY(-40%)',
  },
  textShowHideStocks: {
    cursor: 'pointer',
    position: 'relative',
  },
  paper: {
    background: 'transparent',
    boxShadow: 'none',
  },
};

export default withStyles(styles)(HiddenList);
