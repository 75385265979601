import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import { reset } from 'redux-form';
import { success, error as notifierError } from 'react-notification-system-redux';
import { updateUserProfile } from 'common/ducks/user/profile';
import { openDialog } from 'common/ducks/dialogs';
import { REGISTER_BROKERAGE_DIALOG_CODE } from 'common/components/Dialogs/RegisterBrokerageDialog';
import { PASSWORD_CONFIRM_DIALOG_CODE } from 'common/components/Dialogs/PasswordConfirmDialog';
import CompletePersonalDataDialog, { COMPLETE_PERSONAL_DATA_DIALOG } from './CompletePersonalDataDialog';

export { COMPLETE_PERSONAL_DATA_DIALOG };

const styles = {
  cancelButton: {},
  icon: {
    color: '#939798',
    margin: '0 10px',
  },
  formControl: {
    padding: '20px',
  },
  buttonContainer: {
    marginTop: '10px',
    float: 'right',
  },
};

const mapStateToProps = ({ user: { profile } }) => ({
  userPlan: profile.data.plan || {},
});

const mapDispatchToProps = (dispatch) => ({
  resetForm: () => {
    dispatch(reset('completeRegistration'));
  },
  openRegisterBrokerageDialog: (args) => dispatch(openDialog(
    REGISTER_BROKERAGE_DIALOG_CODE,
    args,
  )),
  updateUserProfile: (data) => dispatch(updateUserProfile(data)),
  notifySuccess: (data) => dispatch(success(data)),
  notifyError: (data) => dispatch(notifierError(data)),
  openPasswordDialog: (args) => dispatch(openDialog(
    PASSWORD_CONFIRM_DIALOG_CODE,
    args,
  )),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withStyles(styles)(CompletePersonalDataDialog));
