import { withStyles } from '@material-ui/core/styles';

import palette from 'common/palette';
import Tab from '../SecondaryTab';

export default withStyles(() => ({
  root: {
    '&.Mui-selected': {
      background: palette.default.dark,
    },
  },
}))(Tab);
