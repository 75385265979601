import {
  error as notifierError,
  success as notifierSuccess,
} from 'react-notification-system-redux';
import { connect } from 'react-redux';
import { reset, reduxForm, formValueSelector } from 'redux-form';
import { withStyles } from '@material-ui/core/styles';
import { updateUserProfile } from 'common/ducks/user/profile';
import { closeDialog } from 'common/ducks/dialogs';
import palette from 'common/palette';

import AlterPasswordDialog, { ALTER_PASSWORD_DIALOG_CODE } from './AlterPasswordDialog';

export { ALTER_PASSWORD_DIALOG_CODE };

const styles = (theme) => ({
  cancelButton: {
    color: palette.textSecondary.main,
  },
  icon: {
    color: '#939798',
    margin: '0 10px',
  },
  formControl: {
    padding: '20px 20px 20px 20px',
    width: '70%',
    position: 'relative',
    '@media (max-width: 550px)': {
      padding: '20px 15px 20px 20px',
      width: '100%',
    },
  },
  passwordTips: {
    position: 'absolute',
    top: 37,
    right: -118,
    '@media (max-width: 768px)': {
      position: 'initial',
      right: -2,
      marginBottom: -15,
    },
  },
  textField: {
    margin: theme.spacing(1),
  },
  actions: {
    paddingTop: 0,
  },
});

const mapDispatchToProps = (dispatch) => ({
  resetProfilePassword: () => {
    dispatch(reset('profile-password'));
  },
  notifySuccess: (data) => dispatch(notifierSuccess(data)),
  notifyError: (data) => dispatch(notifierError(data)),
  updateUserProfile: (data) => dispatch(updateUserProfile(data)),
  closeDialog: () => dispatch(closeDialog()),
});

const selector = formValueSelector('profile-password');
const AlterPasswordDialogForm = connect((state) => {
  const password = selector(state, 'password');
  return { password };
})(AlterPasswordDialog);

export default connect(null, mapDispatchToProps)(reduxForm({
  form: 'profile-password',
})(withStyles(styles)(AlterPasswordDialogForm)));
