import { makeStyles } from '@material-ui/core';
import { connect } from 'react-redux';

import theme from 'common/theme';
import palette from 'common/palette';
import Sidebar from './Sidebar';

const useStyles = makeStyles({
  dashboardSide: {
    background: '#fff',
    boxShadow: '0 3px 6px 0 rgba(0, 0, 0, 0.16)',
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    left: 0,
    overflow: 'hidden',
    position: 'fixed',
    top: 0,
    transition: 'all 225ms cubic-bezier(0.4, 0, 0.6, 1) 0ms',
    width: 65,
    zIndex: 100,
    [`@media(max-width: ${theme.breakpoints.values.md}px)`]: {
      transform: 'translateX(calc(65px * -1))',
    },

    [`@media (min-width: calc(${theme.breakpoints.values.lg}px + 1px))`]: {
      display: 'flex',
      paddingTop: 16,
    },
  },
  open: {
    width: 200,

    [`@media(max-width: ${theme.breakpoints.values.md}px)`]: {
      transform: 'translateX(0)',
    },

    '& > span': {
      opacity: 1,
    },
  },
  logo: {
    width: 161,
  },
  dashboardLogo: {
    display: 'none',
    marginBottom: 30,
    paddingLeft: 13,
    paddingRight: 13,

    [`@media (min-width: calc(${theme.breakpoints.values.lg}px + 1px))`]: {
      display: 'block',
    },

    '& > img': {
      height: 33.48,
      paddingLeft: 9,
    },
  },
  dashboardListItem: {
    '& span': {
      display: 'block',
      fontSize: 14,
      whiteSpace: 'nowrap',

      '@media(min-width: 1201)': {
        marginLeft: 10,
      },
    },
  },
  menuListItem: {
    flex: 1,
    padding: 0,
  },
  listItemText: {
    opacity: 0,
  },
  listitemTextActive: {
    opacity: 1,
  },
  listItemIcon: {
    color: 'inherit',
  },
  menuIcon: {
    [`@media (min-width: calc(${theme.breakpoints.values.md}px + 1px))`]: {
      margin: '0 auto',
    },
  },
  itemLink: {
    color: palette.textSecondary.main,
    display: 'block',
    position: 'relative',
    textDecoration: 'none !important',
    '&::before': {
      backgroundColor: 'transparent',
      content: '""',
      left: 0,
      height: '100%',
      position: 'absolute',
      top: 0,
      width: '7px',
    },
  },
  itemLinkActive: {
    transition: 'background .5s ease-in-out',
    color: theme.palette.primary.main,
    '&::before': {
      backgroundColor: theme.palette.primary.main,
    },
  },
  userMenu: {
    bottom: 30,
    marginLeft: 7,
    position: 'absolute',
    '@media screen and (min-width: 769px)': {
      height: 25,
      display: 'flex',
      alignItems: 'center',
    },
  },
  userMenuText: {
    width: 150,
    cursor: 'pointer',
    color: palette.textSecondary.main,
    display: 'inline-block',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  userMenuIcon: { fontSize: 30 },
  avatarImage: {
    height: 24,
    width: 24,
  },
});

const mapStateToProps = ({
  user: {
    profile: { data: user },
  },
}) => ({
  user,
});

export default connect(mapStateToProps)(Sidebar);
export { useStyles };
