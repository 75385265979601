import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { withStyles } from '@material-ui/core/styles';
import Checkbox from '@material-ui/core/Checkbox';

const styles = {
  container: { alignItems: 'center' },
};
const CheckboxField = ({
  classes,
  input,
  meta: { error, touched },
  checkBoxClassName,
  containerClassName,
  label,
  ...other
}) => (
  <div className={classNames([classes.container, containerClassName])}>
    <Checkbox
      {...other}
      disabled={input.disabled}
      classes={{ checked: checkBoxClassName }}
      onChange={input.onChange}
      checked={Boolean(input.value)}
    />
    {label}
    <div style={{
      fontSize: 12,
      position: 'absolute',
      left: '100%',
      width: 150,
      color: 'red',
    }}
    >
      {touched && error }
    </div>
  </div>
);

CheckboxField.propTypes = {
  classes: PropTypes.object.isRequired,
  input: PropTypes.object,
  meta: PropTypes.object,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  checkBoxClassName: PropTypes.string,
  containerClassName: PropTypes.string,
};

CheckboxField.defaultProps = {
  input: {},
  meta: {},
  checkBoxClassName: '',
  containerClassName: '',
  label: '',
};
export default withStyles(styles)(CheckboxField);
