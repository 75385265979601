import { CENTER, LEFT } from '.';

export const POSITION_PROPS_INITIAL_VALUES = {
  stock: '',
  number_of_stocks: '',
  price: '',
  date: '',
};

export const STOCK_TICKER_REGEX = /[A-Z]{4}(\d|10|11)F?$/;

export const MAX_POSITIONS_FOLLOW_INSTANCE = 30;

export const POSITIONS_TABLE_HEAD = [
  {
    label: 'Ativo',
    align: LEFT,
  },
  {
    label: 'Qtd.',
    align: CENTER,
  },
  {
    label: 'Preço médio',
    align: CENTER,
  },
  {
    label: 'Cotação',
    align: CENTER,
  },
  {
    label: 'Volume',
    align: CENTER,
  },
  {
    label: 'Resultado',
    align: CENTER,
  },
  {
    label: '',
    align: CENTER,
  },
];

export const POSITIONS_VALUES = {
  number_of_stocks: {
    id: 'number_of_stocks',
    align: CENTER,
  },
  average_price: {
    id: 'average_price',
    align: CENTER,
  },
  price: {
    id: 'price',
    align: CENTER,
  },
  financial_volume: {
    id: 'financial_volume',
    align: CENTER,
  },
};

export const RESET_POSITION_ERROR_MESSAGE = 'Ocorreu um erro ao zerar a posição. Por favor, tente novamente.';
export const MARKET_AVAILABILITY_ERROR_MESSAGE = 'Não foi possível verificar a abertura do mercado. Por favor, tente novamente.';

export const BLOCKED_MOVEMENT = 'Movimento Bloqueado';
export const UPDATE_MY_INSTANCE_COMPOSITION = 'Atualizar minha composição';

export default null;
