import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { PASSWORD_TIPS } from 'common/utils/constants';
import { passwordSteps } from 'common/utils/validation';

const PasswordTips = ({
  mobile,
  values,
  classes,
  className,
}) => {
  const isPasswordStepValid = (item) => {
    if (passwordSteps(values.password).includes(item.id)) {
      return classes.valid;
    }
    return classes.invalid;
  };

  return (
    <div className={classNames(classes.passwordTip, className)}>
      {PASSWORD_TIPS.map((item) => (
        <span
          key={item.id}
          className={isPasswordStepValid(item)}
        >
          {item.text}
          {item.id < PASSWORD_TIPS.length && !mobile ? ' / ' : ''}
        </span>
      ))}
    </div>
  );
};

PasswordTips.propTypes = {
  mobile: PropTypes.bool,
  values: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
  className: PropTypes.string,
};

PasswordTips.defaultProps = {
  mobile: false,
  className: '',
};

export default PasswordTips;
