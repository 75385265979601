import React from 'react';
import PropTypes from 'prop-types';

import Tooltip from '@material-ui/core/Tooltip';

const ArrowTooltip = ({
  children,
  classes,
  tooltipClasses = {},
  title,
  ...other
}) => {
  return (
    <Tooltip
      arrow
      interactive
      {...other}
      classes={{
        arrow: classes.arrow,
        ...tooltipClasses,
      }}
      title={(
        <div className={classes.textCenter}>
          {title}
        </div>
      )}
    >
      {children}
    </Tooltip>
  );
};

ArrowTooltip.propTypes = {
  tooltipClasses: PropTypes.object,
  classes: PropTypes.object.isRequired,
  children: PropTypes.node.isRequired,
  title: PropTypes.node.isRequired,
};

ArrowTooltip.defaultProps = {
  tooltipClasses: {},
};

export default ArrowTooltip;
