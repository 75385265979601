import transform from 'lodash/transform';
import isObject from 'lodash/isObject';
import isEqual from 'lodash/isEqual';
import mergeWith from 'lodash/mergeWith';
import isArray from 'lodash/isArray';

const changes = (object, base) => transform(object, (result, value, key) => {
  if (!isEqual(value, base[key])) {
    // eslint-disable-next-line no-param-reassign
    result[key] = (isObject(value) && isObject(base[key]))
      ? this.changes(value, base[key])
      : value;
  }
});

export const differenceBetweenObjs = (object, base) => changes(object, base);

const customizer = (objValue, srcValue) => {
  if (isArray(objValue)) {
    return objValue.concat(srcValue);
  }
  return undefined; // Let lodash handle the merge
};
export const deepMerge = (...args) => mergeWith(...args, customizer);

/**
 *  Joins two collections.
 *  Adds a property called 'joinedPropName' on each item from 'joinOn'
 *  and assigns it to an object from 'joinFrom' where the 'joinFunc' criterium is met.
 * */
export const join = (joinOn, joinFrom, joinedPropName, joinFunc) => {
  if (joinFrom) { // Teste
    return joinOn.map((obj) => ({
      ...obj,
      [joinedPropName]: joinFrom.filter(joinFunc(obj))[0],
    }));
  }

  return joinOn;
};

export const setValuesObject = ({ obj, value = 0, ignoreFields = [] }) => {
  const emptyObject = Object.keys(obj).reduce((acc, prop) => {
    if (ignoreFields.includes(prop)) {
      return {
        ...acc,
        [prop]: obj[prop],
      };
    }

    return {
      ...acc,
      [prop]: value,
    };
  }, {});

  return emptyObject;
};

export const concatenateObjects = (objA, objB) => ({ ...objA, ...objB });

export default null;
