import get from 'lodash/get';

import {
  loadInstancePositions,
  DUCK_NAME as POSITIONS_DUCK_NAME,
} from 'common/ducks/positions';
import {
  loadInstanceReport,
  DUCK_NAME as REPORT_DUCK_NAME,
} from 'common/ducks/report';

const instancesIntervalIds = {
  instances: {},
  backtests: {},
};

const instancesTimeoutIds = {};

export const updateInstanceInfo = (id) => async (dispatch, getState) => {
  const instancePositionsState = get(getState()[POSITIONS_DUCK_NAME], `${id}`, {});
  const instanceReportState = get(getState()[REPORT_DUCK_NAME], `${id}`, {});

  if (!instancePositionsState.loading) {
    dispatch(loadInstancePositions(id, { reload: true }));
  }
  if (!instanceReportState.loading) {
    dispatch(loadInstanceReport(id, {}, { reload: true }));
  }
};

export const stopInstanceUpdater = (id, variant = 'instances') => {
  const timeoutId = instancesTimeoutIds[id];
  const intervalId = get(instancesIntervalIds, `${variant}.${id}`, 0);
  if (intervalId) {
    window.clearInterval(intervalId);
    delete instancesIntervalIds[variant][id];
  }
  if (timeoutId) {
    window.clearTimeout(timeoutId);
    delete instancesTimeoutIds[id];
  }
};

export default null;
