import React from 'react';
import PropTypes from 'prop-types';
import logdown from 'logdown';
import { Field } from 'redux-form';
import ReCaptcha from 'react-recaptcha';

import CircularProgress from '@material-ui/core/CircularProgress';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';

import 'react-credit-cards/es/styles-compiled.css';
import Cards from 'react-credit-cards';

import { closeDialog } from 'common/ducks/dialogs';
import { InputTextField } from 'common/components/ReduxForm';
import {
  required,
  nameOrCorporateName,
  validateCreditCard,
  validateCvv,
  validateExpiry,
  extraCreditCardValidation,
} from 'common/utils/validation';

import { maskNumber, maskValidThru } from 'common/utils/normalize';
import { RECAPTCHA_SITEKEY } from 'common/utils/constants';

export const ADD_CREDIT_CARD_DIALOG_CODE = 'ADD_CREDIT_CARD_DIALOG_CODE';

const logger = logdown('AddCreditCardDialog');

class AddCreditCardDialog extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      focused: 'number',
      card: {
        issuer: 'unknown',
        maxLength: 16,
      },
      loading: false,
      reCaptchResponse: '',
    };
  }

  componentDidMount = () => {
    this.props.resetForm();
  };

  submit = (card) => async (values, dispatch) => {
    const { reCaptchResponse } = this.state;

    const {
      resetForm,
      addPaymentMethod,
      notifySuccess,
      notifyError,
    } = this.props;

    const {
      name,
      number,
      expiry,
      cvc,
    } = values;

    const {
      issuer,
    } = card;

    const data = {
      description: issuer,
      first_name: name.split(' ').slice(0, 1).join(''),
      last_name: name.split(' ').slice(1).join(' '),
      month: expiry.split('/').splice(0, 1).join(''),
      number,
      verification_value: cvc,
      year: expiry.split('/').splice(1).join(''),
      recaptcha_response: reCaptchResponse,
      recaptcha_version: 2,
    };

    try {
      this.setState({ loading: true });
      const result = await addPaymentMethod(data);

      notifySuccess({
        title: 'Sucesso!',
        message: 'Método de pagamento inserido! Você pode removê-lo a qualquer momento na aba "Financeiro" da página "Minha Conta".',
      });

      logger.info('Método de pagamento inserido com sucesso.', result);
      dispatch(closeDialog());
      resetForm();
    } catch (error) {
      if (error.code && error.code === 'paymentmethod0') {
        notifyError({
          title: 'Erro!',
          message: 'Número informado no cartão de crédito está inválido. Tente novamente.',
        });
      } else {
        notifyError({
          title: 'Erro!',
          message:
            error.codeMessage
            || 'Falha ao inserir um novo método de pagamento. Tente novamente.',
        });
      }

      logger.error('Falha ao inserir um novo método de pagamento.', error);
      this.setState({ loading: false });
    }
  };

  handleInputFocus = ({ target }) => {
    const { card } = this.state;
    const { formValueSelector, untouchNumberField } = this.props;

    if (target.name === 'name' && extraCreditCardValidation(card, formValueSelector.number)) {
      untouchNumberField();
    }

    this.setState({
      focused: target.name,
    });
  };

  cardCallback = (card) => {
    this.setState({ card });
  };

  validateCard = (value) => validateCreditCard(this.state.card)(value);

  shouldDisableSubmitButton = () => {
    const { loading, reCaptchResponse } = this.state;
    const {
      pristine,
      submitting,
    } = this.props;

    return pristine || submitting || loading
        || !reCaptchResponse;
  }

  handleReCaptchaResponse = (reCaptchResponse) => {
    this.setState({ reCaptchResponse });
  };

  render = () => {
    const {
      classes,
      onCancelClick,
      open,
      onClose,
      confirmButtonContent,
      cancelButtonContent,
      handleSubmit,
      formValueSelector,
    } = this.props;

    const {
      focused,
      card,
      loading,
    } = this.state;

    const placeholders = {
      name: 'SEU NOME AQUI',
    };

    return (
      <Dialog
        classes={{
          paper: classes.dialogPaper,
        }}
        open={open}
        onClose={onClose}
      >
        <DialogTitle
          className={classes.title}
          disableTypography
        >
          Adicionar Cartão de Crédito
        </DialogTitle>
        <DialogContent>
          <div className={classes.cards}>
            <Cards
              number={formValueSelector.number || ''}
              name={formValueSelector.name || ''}
              expiry={formValueSelector.expiry || ''}
              cvc={formValueSelector.cvc || ''}
              focused={focused}
              placeholders={placeholders}
              callback={this.cardCallback}
            />
          </div>
          <form className={classes.formControl}>
            <Field
              id="add-credit-card_number"
              name="number"
              component={InputTextField}
              type="text"
              validate={[required, this.validateCard]}
              normalize={maskNumber}
              label="Número"
              onFocus={this.handleInputFocus}
            />
            <Field
              id="add-credit-card_name"
              name="name"
              component={InputTextField}
              type="text"
              validate={[required, nameOrCorporateName]}
              label="Nome no cartão"
              onFocus={this.handleInputFocus}
            />
            <Field
              id="add-credit-card_expiry"
              name="expiry"
              component={InputTextField}
              type="text"
              validate={[required, validateExpiry]}
              normalize={maskValidThru}
              label="Expiração MM/YYYY"
              onFocus={this.handleInputFocus}
            />
            <Field
              id="add-credit-card_cvc"
              name="cvc"
              component={InputTextField}
              type="text"
              validate={[required, validateCvv]}
              normalize={maskNumber}
              label="CVV"
              onFocus={this.handleInputFocus}
            />
          </form>
        </DialogContent>

        <div className={classes.reCaptchaContainer}>
          <ReCaptcha
            sitekey={RECAPTCHA_SITEKEY}
            verifyCallback={this.handleReCaptchaResponse}
            hl="pt-BR"
          />
        </div>

        <DialogActions>
          <Button
            className={classes.cancelButton}
            onClick={onCancelClick || onClose}
            autoFocus
          >
            {cancelButtonContent || 'Cancelar'}
          </Button>
          <div className={classes.wrapper}>
            <Button
              type="submit"
              disabled={this.shouldDisableSubmitButton()}
              onClick={handleSubmit(this.submit(card)) || onClose}
              color="primary"
              variant="contained"
              autoFocus
            >
              {confirmButtonContent || 'Cadastrar'}
            </Button>
            {
              loading
              && <CircularProgress size={24} className={classes.buttonProgress} />
            }
          </div>
        </DialogActions>
      </Dialog>
    );
  }
}

AddCreditCardDialog.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onCancelClick: PropTypes.func,
  cancelButtonContent: PropTypes.any,
  confirmButtonContent: PropTypes.any,
  handleSubmit: PropTypes.func.isRequired,
  pristine: PropTypes.any,
  submitting: PropTypes.any,
  resetForm: PropTypes.func.isRequired,
  formValueSelector: PropTypes.object.isRequired,
  addPaymentMethod: PropTypes.func.isRequired,
  notifyError: PropTypes.func.isRequired,
  notifySuccess: PropTypes.func.isRequired,
  untouchNumberField: PropTypes.func.isRequired,
};

AddCreditCardDialog.defaultProps = {
  onCancelClick: null,
  cancelButtonContent: null,
  confirmButtonContent: null,
  pristine: null,
  submitting: null,
};

export default AddCreditCardDialog;
