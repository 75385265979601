import React from 'react';
import { connect } from 'react-redux';
import { reduxForm, Field } from 'redux-form';

// Material UI
import {
  FormControl, MenuItem,
} from '@material-ui/core';

import { maskCEP, maskNumber } from 'common/utils/normalize';
import brazilianStates from 'common/assets/mocks/brazilianStates.json';
import { shouldAsyncValidate, asyncValidateCEP as asyncValidate } from 'common/utils/asyncValidation';
import {
  validateCEP,
  maxLength,
  required,
} from 'common/utils/validation';
import { InputTextField, SelectField }
  from '../../ReduxForm';

const AccountInfoAddressForm = () => {
  const { data: states } = brazilianStates;
  return (
    <>
      <FormControl fullWidth>
        <Field
          type="text"
          id="profile-postal_code"
          name="postal_code"
          component={InputTextField}
          label="CEP"
          validate={[required, validateCEP]}
          normalize={maskCEP}
          format={maskCEP}
        />
      </FormControl>
      <FormControl fullWidth>
        <Field
          type="text"
          id="profile-address"
          name="address"
          component={InputTextField}
          validate={[required, maxLength(50)]}
          label="Rua/Avenida"
        />
      </FormControl>
      <FormControl fullWidth>
        <Field
          type="text"
          id="profile-number"
          name="number"
          component={InputTextField}
          validate={required}
          normalize={maskNumber}
          label="Número"
        />
      </FormControl>
      <FormControl fullWidth>
        <Field
          type="text"
          id="profile-complement"
          name="complement"
          component={InputTextField}
          validate={[maxLength(30)]}
          label="Complemento"
        />
      </FormControl>
      <FormControl fullWidth>
        <Field
          type="text"
          id="profile-neighborhood"
          name="neighborhood"
          component={InputTextField}
          validate={[required, maxLength(90)]}
          label="Bairro"
        />
      </FormControl>
      <FormControl fullWidth>
        <Field
          type="text"
          id="profile-city"
          name="city"
          component={InputTextField}
          validate={[required, maxLength(50)]}
          label="Cidade"
        />
      </FormControl>
      <FormControl fullWidth>
        <Field
          id="profile-state"
          name="state"
          select
          component={SelectField}
          validate={required}
          label="Estado"
        >
          {states.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.name}
            </MenuItem>
          ))}
        </Field>
      </FormControl>
    </>
  );
};

const mapStateToProps = (
  { user: { profile } },
  { onSubmit },
) => ({
  initialValues: {
    postal_code: profile.data.postal_code || '',
    address: profile.data.address || '',
    number: profile.data.number || '',
    complement: profile.data.complement || '',
    neighborhood: profile.data.neighborhood || '',
    city: profile.data.city || '',
    state: profile.data.state || '',
  },
  onSubmit,
  profile: profile.data,
});

export default connect(mapStateToProps)(reduxForm({
  form: 'profile_address_form',
  asyncBlurFields: ['postal_code'],
  shouldAsyncValidate,
  asyncValidate,
})(AccountInfoAddressForm));
