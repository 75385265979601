import React from 'react';
import PropTypes from 'prop-types';
import accounting from 'accounting';
import Box from '@material-ui/core/Box';
import InputAdornment from '@material-ui/core/InputAdornment';

import { parseNumber } from 'common/utils/string';
import { TextField } from '@material-ui/core';
import classNames from 'classnames';

const CurrencyField = ({
  classes,
  values: {
    value,
  },
  handleChange,
  handleBlur,
  meta: {
    errors,
  },
  label,
  autoFocus,
  helperText,
  helperTextProps,
}) => {
  const getInputAdornment = (adornment) => (
    <InputAdornment className={classes.adornment} position="start">{adornment}</InputAdornment>);

  const onChange = (field) => (e) => {
    if (field.name === 'value') {
      e.target.value = parseNumber(e.target.value, 2);
    }
    handleChange(e);
  };

  const field = {
    name: 'value',
    adornment: 'R$',
    label,
    value: accounting.formatNumber(
      Number.parseFloat(value),
      2,
    ),
  };

  const error = errors[field.name];

  return (
    <Box className={classes.wrapper}>
      <TextField
        autoFocus={autoFocus}
        className={classNames(classes.inputMargin)}
        fullWidth
        type="text"
        label={field.label}
        value={field.value}
        name={field.name}
        id={`currency-field-${field.name}`}
        aria-label="currency-field-input"
        InputProps={{
          className: classes.label,
          startAdornment: field.adornment && getInputAdornment(field.adornment),
        }}
        InputLabelProps={{
          className: classes.label,
        }}
        onChange={onChange(field)}
        onBlur={handleBlur}
        error={!!error}
        helperText={helperText || errors.message || error}
        FormHelperTextProps={helperTextProps}
      />
    </Box>
  );
};

CurrencyField.propTypes = {
  label: PropTypes.string.isRequired,
  handleChange: PropTypes.func.isRequired,
  handleBlur: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
  values: PropTypes.object,
  meta: PropTypes.object,
  autoFocus: PropTypes.bool,
  helperText: PropTypes.string,
  helperTextProps: PropTypes.object,
};

CurrencyField.defaultProps = {
  values: {
    value: 1,
  },
  meta: {},
  autoFocus: false,
  helperText: '',
  helperTextProps: {},
};

export default CurrencyField;
