import { useState } from 'react';
import { useSelector } from 'react-redux';
import { getPlanFromSubscriptions, getPlanLimit } from 'common/utils/plans';
import { WHITEBOX_MAX_CAPITAL, WHITEBOX_MIN_CAPITAL, WHITEBOX_STRATEGY_IDS } from 'common/utils/constants/whitebox';
import { PARAMS_MOUNT_INSTANCE, NEXT_REBALANCE_TEXT } from 'common/utils/constants/instance';

export const useStoreInstance = (strategyId) => {
  const storeInstance = useSelector((state) => {
    return state.storeItems.data.items[strategyId] || {};
  });

  return storeInstance;
};

export const useStoreInstanceWithPlanLimit = (templateId, strategyId) => {
  const storeInstance = useStoreInstance(strategyId);
  const subscriptions = useSelector((state) => state.user.subscriptions.data);

  const { plans } = storeInstance.extra_data;

  if (!templateId) {
    const currentPlan = getPlanFromSubscriptions({
      planCode: storeInstance.plan_code,
      subscriptions,
    });
    const planLimit = getPlanLimit({ planCode: currentPlan.plan_code, listPlans: plans });
    storeInstance.planLimit = planLimit;
    storeInstance.minimum = storeInstance.extra_data.tags.minimum;
  }

  if (templateId) {
    storeInstance.planLimit = WHITEBOX_MAX_CAPITAL;
    storeInstance.minimum = WHITEBOX_MIN_CAPITAL;
  }

  return storeInstance;
};

export const useValueRolloutDate = ({
  strategyId,
  dataSteps = {
    data: {},
  },
}) => {
  const storeInstance = useStoreInstance(strategyId);
  const isWhiteboxInstance = WHITEBOX_STRATEGY_IDS.includes(storeInstance.strategy_id);

  const paramsMountInstance = {
    ...PARAMS_MOUNT_INSTANCE,
    nextRollout: {
      ...PARAMS_MOUNT_INSTANCE.nextRollout,
      text: isWhiteboxInstance
        ? NEXT_REBALANCE_TEXT
        : PARAMS_MOUNT_INSTANCE.nextRollout.text,
    },
    mountNow: {
      ...PARAMS_MOUNT_INSTANCE.mountNow,
      forceMountPortfolio: true,
    },
  };

  const [valueParam, setValueParam] = useState(
    dataSteps.data.selectedDateOption || paramsMountInstance.nextRollout.value,
  );

  return { valueParam, setValueParam, paramsMountInstance };
};
