import React from 'react';
import PropTypes from 'prop-types';
import orderBy from 'lodash/orderBy';
import { Box } from '@material-ui/core';

import { STOCK_MODES } from 'common/utils/constants';
import StockCard from 'common/components/StockCard';

const StockCardList = ({
  className,
  stocks,
  onStockClick,
}) => {
  const sortedStocks = orderBy(Object.values(stocks), 'code');
  return (
    <Box data-testid="stocks-card-list">
      {sortedStocks.length > 0 && sortedStocks.map(
        (stock) => (
          <StockCard
            key={stock.code || stock.stock_code}
            className={className}
            mode={STOCK_MODES.VIEW}
            stock={stock}
            onStockClick={() => onStockClick(stock)}
            basicVersion
          />
        ),
      )}
    </Box>
  );
};

StockCardList.propTypes = {
  className: PropTypes.string.isRequired,
  stocks: PropTypes.object,
  onStockClick: PropTypes.func.isRequired,
};

StockCardList.defaultProps = {
  stocks: {},
};

export default StockCardList;
