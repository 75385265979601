import { connect } from 'react-redux';
import { withMobileDialog } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

import { PROGRESS_DIALOG_CODE } from 'common/components/Dialogs/ProgressDialog';
import { openDialog } from 'common/ducks/dialogs';
import {
  insertExternalOrdersAction,
  updateExternalOrder,
} from 'common/ducks/orders';
import palette from 'common/palette';
import ExternalOrderDialog, { EXTERNAL_ORDER_DIALOG_CODE } from './ExternalOrderDialog';

const styles = ((theme) => ({
  title: {
    color: palette.textPrimary.main,
    marginBottom: 10,
  },
  text: {
    color: palette.textSecondary.dark,
    fontSize: theme.typography.h6.fontSize,
    letterSpacing: 0.43,
    lineHeight: 1.31,
  },
  textBold: {
    fontWeight: 'bold',
  },
  spaceTop: {
    marginTop: 0,
  },
  closeIcon: {
    position: 'absolute',
    right: '10px',
    top: '5px',
  },
  dialog: {
    maxWidth: 820,
  },
}));

const mapStateToProps = ({ instances, positions, stocks }, { id }) => ({
  instance: instances.data[id],
  positions: positions[id]?.data.mergedPositions || {},
  validStocks: stocks.validStocks,
});

const mapDispatchToProps = (dispatch) => ({
  insertExternalOrdersAction: (id, data) => dispatch(insertExternalOrdersAction(id, data)),
  updateExternalOrder: (id, data) => dispatch(updateExternalOrder(id, data)),
  openProgressDialog: (props) => dispatch(openDialog(PROGRESS_DIALOG_CODE, { ...props })),
});

export { EXTERNAL_ORDER_DIALOG_CODE };

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withStyles(styles)(withMobileDialog()(ExternalOrderDialog)));
