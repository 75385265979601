import React from 'react';
import PropTypes from 'prop-types';
import { Box, Typography } from '@material-ui/core';

import StockCard from 'common/components/StockCard';
import DisplayNumber from 'common/components/DisplayNumber';
import { getTotalItems } from 'common/utils/array';
import { addTargetWeightToPositionedStockItems, getWeightlessItems } from 'common/utils/chart';
import { STOCK_MODES } from 'common/utils/constants';

const SCALE = 0.875;
const RIGHT = 'right';

const DetailedChartLegend = ({
  classes, stocks, theoreticalStocks, showCurrentAndTargetWeights,
}) => {
  let items = stocks;
  const totalWeights = getTotalItems({ items, value: 'weight' });

  if (showCurrentAndTargetWeights) {
    addTargetWeightToPositionedStockItems({ stocks, theoreticalStocks });
    const weightlessItems = getWeightlessItems({ stocks, theoreticalStocks });
    items = [...items, ...weightlessItems];
  }

  return (
    <>
      {showCurrentAndTargetWeights && (
        <Box className={classes.cardsLegendContainer}>
          <Typography variant="h6">Ativo</Typography>
          <Typography variant="h6">
            <b>Atual</b>
            {' '}
            / Alvo
          </Typography>
        </Box>
      )}
      {items.length > 0
        && items.map((stock, index) => {
          const {
            code, stock_code, weight, target,
          } = stock;
          return (
            <StockCard
              className={classes.stockCards}
              key={code || stock_code}
              isTotalGreaterZero={totalWeights > 0}
              mode={STOCK_MODES.VIEW}
              stock={stock}
              stockIndex={index}
              customRightComponent={showCurrentAndTargetWeights ? (
                <Box className={classes.weightBox} data-testid="weight-box">
                  <DisplayNumber
                    value={weight}
                    color="textPrimary"
                    symbol="%"
                    symbolLocation={RIGHT}
                    scale={SCALE}
                    precision={Number.isInteger(weight) ? 0 : 2}
                    colorful={false}
                  />
                  <DisplayNumber
                    value={target}
                    symbol="%"
                    symbolLocation={RIGHT}
                    scale={SCALE}
                    precision={0}
                    colorful={false}
                  />
                </Box>
              ) : null}
            />
          );
        })}
    </>
  );
};

DetailedChartLegend.propTypes = {
  classes: PropTypes.object.isRequired,
  stocks: PropTypes.array.isRequired,
  showCurrentAndTargetWeights: PropTypes.bool,
  theoreticalStocks: PropTypes.object.isRequired,
};

DetailedChartLegend.defaultProps = {
  showCurrentAndTargetWeights: false,
};

export default DetailedChartLegend;
