import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Box, Select, MenuItem, Typography,
} from '@material-ui/core';

const GeneralFilter = ({
  classes,
  items,
  initialItem,
  label,
  onChange,
}) => {
  const [item, setItem] = useState(initialItem);

  const handleChangeSelect = (e) => {
    const { value } = e.target;
    setItem(value);
    onChange(value);
  };

  return (
    <>
      {label
      && (
      <Box mr={1}>
        <Typography variant="h6" className={classes.bold}>{label}</Typography>
      </Box>
      )}
      <Select
        className={classes.select}
        value={item}
        onChange={handleChangeSelect}
      >
        {items.map((currentItem) => (
          <MenuItem
            key={currentItem.id}
            value={currentItem.id}
          >
            {currentItem.name}
          </MenuItem>
        ))}
      </Select>
    </>
  );
};

GeneralFilter.propTypes = {
  classes: PropTypes.object.isRequired,
  initialItem: PropTypes.string.isRequired,
  items: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
  })).isRequired,
  label: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default GeneralFilter;
