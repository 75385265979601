import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import InternalPageHeader from 'common/components/InternalPageHeader';
import {
  Box,
  Typography,
  Card,
} from '@material-ui/core';
import Placeholder from 'common/components/Placeholder';
import {
  STORE_CATEGORIES_IMAGES_PATH,
  SHOWCASE_URL,
  TABLET_WIDTH_BREAKPOINT,
} from 'common/utils/constants';
import { getPublicImage } from 'common/utils/string';
import AuthorCard from 'common/components/AuthorCard';
import BackButton from 'common/components/Buttons/BackButton';
import Disclaimer from 'common/components/Disclaimer';
import WhiteBox from 'common/components/WhiteBox';
import isEmpty from 'lodash/isEmpty';
import StoreInstanceTags from 'common/components/StoreInstanceTags';
import InstanceMetricsCard from 'common/components/InstanceCard/InstanceMetricsCard';
import { getProfitabilityData, getInstanceItemYears } from 'common/utils/instance';
import InstanceAbsoluteProfitability from 'common/components/InstanceCard/InstanceAbsoluteProfitability';
import StocksView from 'common/components/StocksView';
import BenchmarkReturnChart from 'common/components/InstanceReturnCharts/BenchmarkReturnChart';
import { useLoadBenchmarksPerformance } from 'common/hooks/benchmark';
import { BENCHMARKS } from 'common/utils/constants/benchmarks';
import DEFAULT_DISCLAIMER from 'common/utils/constants/disclaimer';

const ShowcaseInstancePage = ({
  classes,
  getStoreItem,
  categories,
  match,
  location,
  loadInstanceDailyCumulativePerformance,
  dailyCumulativePerformance,
  loadInstanceReport,
  isPublic,
}) => {
  const isMobile = window.innerWidth <= TABLET_WIDTH_BREAKPOINT;
  const [isLoading, setIsLoading] = useState(true);
  const [storeInstance, setStoreInstance] = useState({});
  const [category, setCategory] = useState({ backgroundInstance: '' });
  const instanceId = storeInstance.instance_id;
  const instanceChartData = dailyCumulativePerformance[instanceId];
  const benchmarkData = dailyCumulativePerformance[BENCHMARKS.ibov.instanceId];
  const { isBenchmarksLoaded } = useLoadBenchmarksPerformance({ isPublic });
  const cardItems = {
    summary: {
      cardTitle: 'Sobre a carteira',
      showTags: true,
    },
    description: {
      cardTitle: 'Metodologia',
      showTags: false,
    },
  };

  const storeItemYears = getInstanceItemYears(storeInstance);
  const profitabilityData = getProfitabilityData({
    data: instanceChartData?.data,
    yearsList: storeItemYears,
  });

  const getPathUrlBackButton = () => {
    return location.state?.prevPath;
  };

  useEffect(() => {
    const mount = async () => {
      const instance = await getStoreItem(Number(match.params.id));

      const currentCategory = categories[instance.extra_data.category_id] || {};

      currentCategory.backgroundInstance = !isEmpty(currentCategory) ? getPublicImage(
        currentCategory['background-page'],
        STORE_CATEGORIES_IMAGES_PATH,
      ) : '';

      setStoreInstance(instance);
      setCategory(currentCategory);
      setIsLoading(false);
    };

    mount();
  }, [categories, getStoreItem, match.params.id]);

  useEffect(() => {
    const loadInstancePerformance = async () => {
      if (instanceId) {
        await loadInstanceReport(instanceId);
        await loadInstanceDailyCumulativePerformance(instanceId);
      }
    };

    loadInstancePerformance();
  }, [loadInstanceReport, loadInstanceDailyCumulativePerformance, instanceId]);

  const objValuesStoreInstance = Object.values(storeInstance);

  const author = storeInstance?.strategy?.extra_data?.author;
  return (
    <Box mt={4}>
      {isLoading && <Placeholder height={167} />}
      {!isLoading && (
        storeInstance.id === 55 ? (
          <>
            <div className={classes.whiteboxWrapper}>
              <div className={classes.wbTitle}>
                <BackButton path={`${SHOWCASE_URL}/${category.route}`} text="" />
                <Typography variant="h2">
                  Criar sua Carteira
                </Typography>
              </div>
              <Typography variant="h5" className={classes.createWalletSubtitle}>
                Para começar, escolha quais ativos irão compor a sua carteira:
              </Typography>
            </div>
            <WhiteBox
              instanceId={storeInstance.instance_id}
              strategyId={storeInstance.strategy_id}
            />
          </>
        ) : (
          <>
            <InternalPageHeader
              backgroundUrl={category.backgroundInstance}
              icon={(
                <img
                  data-testid="instance-logo"
                  className={classes.instanceLogo}
                  src={storeInstance.image_url}
                  alt={`Carteira ${storeInstance.name}`}
                />
              )}
              pathUrlBackButton={getPathUrlBackButton()}
              title={storeInstance.name}
              showBackButton={!isPublic}
            />

            <Box className={classes.wrapper} mt={2}>
              {objValuesStoreInstance.length > 0 && (
              <Box className={classes.whitebox}>
                <Box mb={2}>
                  <Typography variant="h3" className={classes.cardTitle}>
                    Composição da carteira
                  </Typography>
                </Box>

                <StocksView strategyId={storeInstance.strategy_id} />
              </Box>
              )}
              <Box className={classes.itemCard}>
                {Object.keys(cardItems).map((key) => {
                  if (!storeInstance[key]) return null;

                  return (
                    <div key={key} className={classes.itemCard}>
                      <Box mb={2}>
                        <Typography variant="h3" className={classes.cardTitle}>{cardItems[key].cardTitle}</Typography>
                      </Box>

                      {cardItems[key].showTags && (
                        <StoreInstanceTags
                          tags={{
                            ...storeInstance.strategy.extra_data.tags,
                            next_rollout: storeInstance.next_rollout,
                          }}
                        />
                      )}

                      <Card className={classNames(classes.card, classes.cardSpacing)} id={key}>
                        {storeInstance[key].split('\n').map((text) => (
                          <p key={text} className={classNames('cardText', classes.cardText)}>{text}</p>
                        ))}
                      </Card>
                    </div>
                  );
                })}

                <div className={classes.itemCard}>
                  <Box mb={2}>
                    <Typography variant="h3" className={classes.cardTitle}>Desempenho</Typography>
                  </Box>
                  <Card className={classes.card}>
                    <Box>
                      {isBenchmarksLoaded && benchmarkData?.data?.length ? (
                        <BenchmarkReturnChart
                          instanceId={instanceId}
                          yearsList={storeItemYears}
                          strategyId={storeInstance.strategy_id}
                        />
                      ) : (
                        <Placeholder height={240} />
                      )}
                    </Box>
                  </Card>
                </div>

                <div className={classes.itemCard}>
                  {instanceChartData?.isDataReady && isBenchmarksLoaded ? (
                    <InstanceMetricsCard
                      profitabilityData={profitabilityData}
                      instance={storeInstance}
                      instanceId={instanceId}
                      arrayYearsBacktest={storeItemYears}
                    />
                  ) : (
                    <Placeholder height={206} />
                  )}
                </div>

                <div className={classes.itemCard}>
                  {instanceChartData?.isDataReady && isBenchmarksLoaded ? (
                    <InstanceAbsoluteProfitability
                      isMobile={isMobile}
                      arrayYearsBacktest={storeItemYears}
                      profitabilityData={profitabilityData}
                      backtestInstance={instanceChartData?.data}
                    />
                  ) : (
                    <Placeholder height={230} />
                  )}
                </div>

                {!isLoading && author
                  ? <AuthorCard author={author} />
                  : <Placeholder height={180} />}

                <Disclaimer text={DEFAULT_DISCLAIMER} />
              </Box>
            </Box>
          </>
        )
      )}
    </Box>
  );
};

ShowcaseInstancePage.propTypes = {
  classes: PropTypes.object.isRequired,
  getStoreItem: PropTypes.func.isRequired,
  categories: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  loadInstanceDailyCumulativePerformance: PropTypes.func.isRequired,
  dailyCumulativePerformance: PropTypes.object.isRequired,
  loadInstanceReport: PropTypes.func.isRequired,
  isPublic: PropTypes.bool,
};

ShowcaseInstancePage.defaultProps = {
  isPublic: false,
};

export default ShowcaseInstancePage;
