import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core';
import { reduxForm } from 'redux-form';
import { isHolidayOrWeekendDay } from 'common/ducks/holidays';
import ConfirmFollowInstance from './ConfirmFollowInstance';

const styles = {
  bold: {
    fontWeight: 700,
  },
  dialogTitle: {
    fontSize: 24,
    paddingBottom: 0,
  },
  dialogSubtitle: {
    fontSize: 20,
    paddingBottom: 0,
  },
  dialogText: {
    marginTop: 8,
    marginBottom: 8,
  },
  wrapperDate: {
    display: 'flex',
  },
  buttonBox: {
    display: 'flex',
    justifyContent: 'flex-end',
    gridGap: 8,
  },
};

const mapStateToProps = ({ brokerages, storeItems, user: { setups } },
  { onSubmit, strategyId }) => {
  const storeInstance = storeItems.data.items[strategyId] || {};

  return {
    setups: setups.data,
    brokerages: brokerages.data.brokerages,
    storeInstance,
    onSubmit,
  };
};

const mapDispatchToProps = (dispatch) => ({
  isHolidayOrWeekendDay: (date) => dispatch(isHolidayOrWeekendDay(date)),
});

export default connect(mapStateToProps, mapDispatchToProps)(reduxForm({ form: 'follow_confirm_form' })(
  withStyles(styles)(ConfirmFollowInstance),
));
