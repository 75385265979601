import { withStyles } from '@material-ui/styles';
import theme from 'common/theme';
import ChangedStocksListing from './ChangedStocksListing';

const styles = {
  stocksListing: {
    paddingRight: 8,
    [theme.breakpoints.up('mdx')]: {
      height: 285,
      overflow: 'auto',
    },
  },
  labelsBox: {
    paddingRight: 16,
  },
  spacing: {
    textAlign: 'center',
    minWidth: 60,
  },
  largeSpacing: {
    minWidth: 95,
  },
};

export default withStyles(styles)(ChangedStocksListing);
