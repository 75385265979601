import { withStyles } from '@material-ui/core/styles';
import palette from 'common/palette';

import CheckboxField from './CheckboxField';

const styles = (theme) => ({
  labelText: {
    color: palette.textSecondary.main,
    fontSize: theme.typography.h6.fontSize,
    paddingTop: 12,
    textAlign: 'left',
    wordWrap: 'break-word',
  },
  labelRoot: {
    width: '290px',
    alignItems: 'flex-start',
  },
  root: {
    color: '#24bc71',
  },
});

export default withStyles(styles)(CheckboxField);
