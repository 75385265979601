import moment from 'moment';
import { getBusinessDay } from './date';

export const generateChartData = (data) => data
  .split(',')
  .reduce((accumulator, date, index, array) => {
    if ((index + 1) % 3 === 0 && index > 0) {
      accumulator.push({
        date: array[index - 2],
        isWorkday: array[index - 1] === '1',
        dailyCumulativePerformance: Number.parseFloat(array[index]),
      });
    }
    return accumulator;
  }, []);

export const getInitialValue = (startDate, data) => {
  const instanceStartDate = moment(data[0].date);
  const differenceInDays = moment(startDate).diff(instanceStartDate, 'days');
  if (differenceInDays === 0) return { filterIndex: 0, initialValue: 1 };
  if (differenceInDays < 0) return { filterIndex: differenceInDays, initialValue: 1 };
  return {
    filterIndex: differenceInDays,
    initialValue: data[differenceInDays - 1]?.dailyCumulativePerformance,
  };
};

export const getNetReturn = (value, initialValue) => {
  return ((value / initialValue) - 1) * 100;
};

export const adjustDataStartPosition = (data) => {
  if (data.length === 1) return data;
  const dayOne = moment(data[0].date);
  const dayTwo = moment(data[1].date);
  if (dayOne.isSame(dayTwo)) {
    data.shift();
    return data;
  }
  return data;
};

export const getFirstDayOfPeriod = (period, isHolidayOrWeekendDay) => {
  const today = moment();
  const startOfPeriod = moment().startOf(period);
  const dateStartOfPeriod = startOfPeriod.toDate();

  if (isHolidayOrWeekendDay(dateStartOfPeriod)) {
    const firstWorkDayInPeriod = moment(getBusinessDay({
      period,
      todayCounts: true,
      isHolidayOrWeekendDay,
    }), 'DD-MM-YYYY');

    if (firstWorkDayInPeriod.isAfter(today)) {
      return startOfPeriod.subtract(1, period);
    }

    return firstWorkDayInPeriod;
  }

  return startOfPeriod;
};

export const getLastDailyCumulativeValue = (data) => (
  data[data.length - 1]?.dailyCumulativePerformance
);

export const isInstanceCumulativePerformanceLoaded = (instance) => instance
  && (!instance?.loading && instance?.isDataReady);

export default null;
