import { withStyles } from '@material-ui/styles';
import { connect } from 'react-redux';
import { getStoreItem } from 'common/ducks/storeItem';
import { withRouter } from 'react-router-dom';
import { loadInstanceDailyCumulativePerformance } from 'common/ducks/dailyCumulativePerformance';
import { loadInstanceReport } from 'common/ducks/report';
import { whiteboxStyle, wrapperWhiteboxStyle } from 'common/utils/styles/whitebox';
import ShowcaseInstancePage from './ShowcaseInstancePage';

const styles = {
  card: {
    boxShadow: '0 3px 6px 0 rgba(0, 0, 0, 0.16)',
  },
  cardSpacing: {
    padding: 25,
  },
  cardText: {
    fontSize: 14,
    '&:not(:last-of-type)': {
      marginBottom: 15,
    },
  },
  cardTitle: {
    fontWeight: 500,
  },
  gridItemChart: {
    '@media(max-width: 1200px)': {
      flexGrow: 0,
      maxWidth: '100%',
      flexBasis: '100%',
      marginBottom: 30,
    },
  },
  instanceLogo: {
    width: 45,
    height: 45,
    marginRight: 8,
  },
  itemCard: {
    marginBottom: 30,
    flex: 1,
  },
  wrapperContent: {
    marginTop: 22,
    '@media(min-width: 1200px)': {
      flexWrap: 'nowrap',
    },
  },
  wrapper: wrapperWhiteboxStyle,
  whitebox: whiteboxStyle,
  wbTitle: {
    display: 'flex',
    width: 245,
    justifyContent: 'space-between',
  },
  createWalletSubtitle: {
    color: '#747c88',
    paddingTop: 5,
  },
  whiteboxWrapper: {
    margin: '35px 0',
  },
};

const mapStateToProps = ({ categories, dailyCumulativePerformance }) => ({
  categories: categories.data.categories,
  dailyCumulativePerformance,
});

const mapDispatchToProps = (dispatch, { variant, isPublic }) => ({
  getStoreItem: (id) => dispatch(getStoreItem(id)),
  loadInstanceDailyCumulativePerformance: (id, options) => dispatch(
    loadInstanceDailyCumulativePerformance(id, options, variant, isPublic),
  ),
  loadInstanceReport: (id) => dispatch(loadInstanceReport(
    id,
    {},
    { reload: false },
    true,
    'instances',
    isPublic,
  )),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(
  withStyles(styles)(ShowcaseInstancePage),
));
