import api from 'common/api';
import { getScoreBoardInstancesData } from 'common/utils/scoreBoard';
import { DUCK_NAME as CATEGORIES_DUCK_NAME } from './categories';

export const DUCK_NAME = 'scoreBoard';

export const INITIAL_STATE = {
  loading: false,
  data: null,
  instances: [],
  strategies: [],
  scoreBoard: [],
  error: null,
};

export const GET_SCORE_BOARD_STARTED = `${DUCK_NAME}/GET_SCORE_BOARD_STARTED`;
export const GET_SCORE_BOARD_SUCCEED = `${DUCK_NAME}/GET_SCORE_BOARD_SUCCEED`;
export const GET_SCORE_BOARD_FAILED = `${DUCK_NAME}/GET_SCORE_BOARD_FAILED`;
export const getScoreBoardStarted = () => ({
  type: GET_SCORE_BOARD_STARTED,
});
export const getScoreBoardSucceed = (data, scoreBoard) => ({
  type: GET_SCORE_BOARD_SUCCEED,
  data,
  scoreBoard,
});
export const getScoreBoardFailed = (error) => ({
  type: GET_SCORE_BOARD_FAILED,
  error,
});

export const getScoreBoard = (options = { reload: false }) => async (dispatch, getState) => {
  const prevScoreBoard = getState()[DUCK_NAME];

  if (!options.reload && (
    prevScoreBoard
    && !prevScoreBoard.error
    && prevScoreBoard.instances.length
    && prevScoreBoard.strategies.length
  )) {
    return Promise.resolve(prevScoreBoard.scoreBoard);
  }

  dispatch(getScoreBoardStarted());

  try {
    const { data: { categories } } = getState()[CATEGORIES_DUCK_NAME];
    const { data } = await api.strategies.scoreBoard.getScoreBoard();

    const scoreBoard = data.instances.map((instance) => (
      getScoreBoardInstancesData(instance, categories)
    ));

    dispatch(getScoreBoardSucceed(data, scoreBoard));
    return Promise.resolve(scoreBoard);
  } catch (error) {
    dispatch(getScoreBoardFailed(error));
    return Promise.reject(error);
  }
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GET_SCORE_BOARD_STARTED:
      return {
        ...state,
        loading: true,
      };
    case GET_SCORE_BOARD_SUCCEED:
      return {
        ...state,
        loading: false,
        data: action.data,
        instances: action.data.instances,
        strategies: action.data.strategies,
        scoreBoard: action.scoreBoard,
      };
    case GET_SCORE_BOARD_FAILED:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    default:
      return state;
  }
};
