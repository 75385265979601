import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

import { loadUserProfile } from 'common/ducks/user/profile';
import { checkUserRegistration } from 'common/utils/profile';
import { loadBrokerages } from 'common/ducks/brokerages';

import { getHolidays } from 'common/ducks/holidays';
import { loadMarketOperationPeriod } from 'common/ducks/market';
import { loadPlans } from 'common/ducks/plans';
import { loadStoreItems } from 'common/ducks/storeItems';
import { loadStrategies } from 'common/ducks/strategies';
import { loadUserSetups } from 'common/ducks/user/setups';
import { getUserStats } from 'common/ducks/user/stats';
import { loadUserSubscriptions } from 'common/ducks/user/subscriptions';
import PrivatePages from './PrivatePages';

const mapDispatchToProps = (dispatch) => ({
  getHolidays: () => dispatch(getHolidays()),
  getUserStats: () => dispatch(getUserStats()),
  loadUserProfile: () => dispatch(loadUserProfile()),
  loadStrategies: () => dispatch(loadStrategies()),
  loadBrokerages: () => dispatch(loadBrokerages()),
  loadMarketOperationPeriod: () => dispatch(loadMarketOperationPeriod()),
  loadPlans: () => dispatch(loadPlans()),
  loadUserSubscriptions: () => dispatch(loadUserSubscriptions()),
  loadStoreItems: () => dispatch(loadStoreItems()),
  loadUserSetups: () => dispatch(loadUserSetups()),
  validateUserRegistration: (props) => checkUserRegistration(props, dispatch),
});

export default withRouter(connect(
  null,
  mapDispatchToProps,
)(PrivatePages));
