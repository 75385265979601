import { connect } from 'react-redux';

import PositionResult from './PositionResult';

const mapStateToProps = ({
  positions,
}, { id, position }) => {
  const stockCode = position.code || position.stock_code;

  return {
    position: positions[id]
      ? positions[id].data.mergedPositions[stockCode] || position || {}
      : {},
  };
};

export default connect(mapStateToProps)(PositionResult);
