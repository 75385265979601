import { createTextMask } from 'redux-form-input-masks';
import { onlyNumbers } from './string';

const maskCPF = (value) => {
  if (!value) {
    return value;
  }

  const onlyNums = onlyNumbers(value);
  if (onlyNums.length <= 3) {
    return onlyNums;
  }
  if (onlyNums.length <= 7) {
    return `${onlyNums.slice(0, 3)}.${onlyNums.slice(3)}`;
  }
  if (onlyNums.length <= 10) {
    return `${onlyNums.slice(0, 3)}.${onlyNums.slice(3, 6)}.${onlyNums.slice(6)}`;
  }
  return `${onlyNums.slice(0, 3)}.${onlyNums.slice(3, 6)}.${onlyNums.slice(6, 9)}-${onlyNums.slice(9, 11)}`;
};

const maskCNPJ = (value) => {
  if (!value) {
    return value;
  }

  const onlyNums = onlyNumbers(value);
  if (onlyNums.length <= 2) {
    return onlyNums;
  }
  if (onlyNums.length <= 6) {
    return `${onlyNums.slice(0, 2)}.${onlyNums.slice(2)}`;
  }
  if (onlyNums.length <= 9) {
    return `${onlyNums.slice(0, 2)}.${onlyNums.slice(2, 5)}.${onlyNums.slice(5)}`;
  }
  if (onlyNums.length <= 13) {
    return `${
      onlyNums.slice(0, 2)
    }.${
      onlyNums.slice(2, 5)
    }.${
      onlyNums.slice(5, 8)
    }/${
      onlyNums.slice(8)
    }`;
  }
  return `${
    onlyNums.slice(0, 2)
  }.${
    onlyNums.slice(2, 5)
  }.${
    onlyNums.slice(5, 8)
  }/${
    onlyNums.slice(8, 12)
  }-${onlyNums.slice(12, 14)}`;
};

const maskPhonecall = createTextMask({
  pattern: '(99) 99999-9999',
});

const maskDate = (value) => {
  if (!value) {
    return value;
  }

  const onlyNums = onlyNumbers(value);

  if (onlyNums.length <= 2) {
    return onlyNums;
  }
  if (onlyNums.length <= 4) {
    return `${onlyNums.slice(0, 2)}/${onlyNums.slice(2)}`;
  }
  if (onlyNums.length <= 6) {
    return `${onlyNums.slice(0, 2)}/${onlyNums.slice(2, 4)}/${onlyNums.slice(4)}`;
  }
  return `${onlyNums.slice(0, 2)}/${onlyNums.slice(2, 4)}/${onlyNums.slice(4, 8)}`;
};

const maskTime = (value) => {
  if (!value) {
    return value;
  }

  const onlyNums = onlyNumbers(value);

  if (onlyNums.length <= 2) {
    return onlyNums;
  }
  if (onlyNums.length <= 4) {
    return `${onlyNums.slice(0, 2)}:${onlyNums.slice(2)}`;
  }
  return `${onlyNums.slice(0, 2)}:${onlyNums.slice(2, 4)}`;
};

const maskDatetime = (value) => {
  if (!value) {
    return value;
  }

  const onlyNums = onlyNumbers(value);

  if (onlyNums.length <= 2) {
    return onlyNums;
  }
  if (onlyNums.length <= 4) {
    return `${onlyNums.slice(0, 2)}/${onlyNums.slice(2)}`;
  }
  if (onlyNums.length <= 6) {
    return `${onlyNums.slice(0, 2)}/${onlyNums.slice(2, 4)}/${onlyNums.slice(4)}`;
  }

  if (onlyNums.length <= 8) {
    return `${onlyNums.slice(0, 2)}/${onlyNums.slice(2, 4)}/${onlyNums.slice(4, 6)} ${onlyNums.slice(6, 8)}`;
  }
  if (onlyNums.length <= 10) {
    return `${onlyNums.slice(0, 2)}/${onlyNums.slice(2, 4)}/${onlyNums.slice(4, 6)} ${onlyNums.slice(6, 8)}:${onlyNums.slice(8, 10)}`;
  }

  return `${onlyNums.slice(0, 2)}/${onlyNums.slice(2, 4)}/${onlyNums.slice(4, 6)} ${onlyNums.slice(6, 8)}:${onlyNums.slice(8, 10)}`;
};

const maskCEP = (value) => {
  if (!value) {
    return value;
  }

  const onlyNums = onlyNumbers(value);

  if (onlyNums.length <= 2) {
    return onlyNums;
  }
  if (onlyNums.length <= 5) {
    return `${onlyNums.slice(0, 2)} ${onlyNums.slice(2)}`;
  }
  return `${onlyNums.slice(0, 2)} ${onlyNums.slice(2, 5)} ${onlyNums.slice(5, 8)}`;
};

const maskNumber = (value) => (onlyNumbers(value));

export const maskValidThru = (value) => {
  if (!value) {
    return value;
  }

  const onlyNums = onlyNumbers(value);

  if (onlyNums.length <= 2) {
    return onlyNums;
  }

  return `${onlyNums.slice(0, 2)}/${onlyNums.slice(2, 6)}`;
};

export {
  maskCPF as default,
  maskCNPJ,
  maskPhonecall,
  maskDate,
  maskTime,
  maskDatetime,
  maskCEP,
  maskNumber,
};
