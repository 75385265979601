import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import {
  Button,
  CircularProgress,
  Input,
  InputAdornment,
  Typography,
} from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import SearchIcon from '@material-ui/icons/Search';
import { STOCK_MODES } from 'common/utils/constants';
import iconSearch from 'common/assets/images/icon-search.svg';
import { checkInstanceIsTemplate } from 'common/utils/template';
import useDebounce from 'common/hooks/debounce';
import StockCard from '../StockCard';
import EmptyState from '../EmptyState';

const SearchStocks = ({
  classes,
  className,
  stocks: stockSearch,
  instance,
  onClickBackButton,
  onStockClick,
  getStocks,
  stockToSearch,
  setStockToSearch,
  textEmptyState,
  showBackButton,
  clearSearch,
  limitStocks,
  isStockClickButton,
}) => {
  const [shouldDisplayLoading, setShouldDisplayLoading] = useState(false);
  const debouncedSearch = useDebounce(stockToSearch, 500);
  const isTemplate = checkInstanceIsTemplate(instance);
  const stockToSearchRef = useRef(stockToSearch);

  useEffect(() => {
    stockToSearchRef.current = stockToSearch;
  }, [stockToSearch]);

  useEffect(() => {
    const search = async (searchTerm) => {
      try {
        setShouldDisplayLoading(true);
        await getStocks(searchTerm, limitStocks);
        setShouldDisplayLoading(false);
      } catch (error) {
        setShouldDisplayLoading(false);
      }
    };

    if (debouncedSearch) {
      search(debouncedSearch);
    }
  }, [debouncedSearch, limitStocks, getStocks]);

  useEffect(() => {
    return () => {
      if (!showBackButton || !stockToSearchRef.current) {
        setStockToSearch('');
        clearSearch();
      }
    };
  }, [showBackButton, clearSearch, setStockToSearch]);

  return (
    <>
      <div
        className={classNames(
          classes.cardContentItems,
          classes.cardContentTemplate,
          limitStocks && classes.limitItemsHeight,
        )}
        data-testid="search-stocks"
      >
        <div className={classes.stocksHeader}>
          {showBackButton && (
          <Button
            className={classes.backButton}
            startIcon={<ArrowBackIcon />}
            onClick={onClickBackButton}
          >
            Voltar
          </Button>
          )}
          <Input
            id="search-input"
            className={classes.searchBar}
            autoFocus
            startAdornment={(
              <InputAdornment position="start">
                {shouldDisplayLoading ? <CircularProgress size={16} /> : <SearchIcon />}
              </InputAdornment>
          )}
            value={stockToSearch}
            onChange={(e) => setStockToSearch(e.target.value)}
            placeholder="Código do ativo"
          />
        </div>

        {stockSearch.isDataReady && stockSearch.searchResult.length > 0
        && stockSearch.searchResult.map((stock) => (
          <StockCard
            className={classNames(className, classes.stockCards)}
            key={stock.code}
            mode={STOCK_MODES.VIEW}
            stock={stock}
            onStockClick={() => onStockClick(stock)}
            isStockClickButton={isStockClickButton}
            basicVersion
          />
        ))}

        {(!stockSearch.isDataReady) && (
        <div className={classNames(
          classes.emptyStocks,
          isTemplate ? classes.justifyCenter : classes.emptyStocksSearch,
        )}
        >
          <EmptyState
            icon={iconSearch}
            text={textEmptyState}
          />
        </div>
        )}

        {stockSearch.isDataReady && stockSearch.searchResult.length === 0 && (
          <div className={classes.noResults}>
            <Typography
              color="textSecondary"
              variant="h6"
            >
              Nenhum resultado encontrado!
            </Typography>
          </div>
        )}

      </div>
    </>
  );
};

SearchStocks.propTypes = {
  classes: PropTypes.object.isRequired,
  stocks: PropTypes.object.isRequired,
  instance: PropTypes.object.isRequired,
  getStocks: PropTypes.func.isRequired,
  onStockClick: PropTypes.func.isRequired,
  onClickBackButton: PropTypes.func,
  stockToSearch: PropTypes.string.isRequired,
  textEmptyState: PropTypes.string.isRequired,
  setStockToSearch: PropTypes.func.isRequired,
  clearSearch: PropTypes.func.isRequired,
  limitStocks: PropTypes.bool,
  showBackButton: PropTypes.bool,
  className: PropTypes.string,
  isStockClickButton: PropTypes.bool,
};

SearchStocks.defaultProps = {
  showBackButton: false,
  limitStocks: false,
  onClickBackButton: () => {},
  className: '',
  isStockClickButton: false,
};

export default SearchStocks;
