import logdown from 'logdown';
import get from 'lodash/get';
import {
  warning as notifyWarn,
  error as notifyError,
} from 'react-notification-system-redux';
import api from 'common/api';
import clockIcon from 'common/assets/images/clock.svg';
// Utils
import {
  validateClosedMarket,
  resetPositions,
  getMergedFractionalAndFullPositions,
} from 'common/utils/positions';
import {
  loadInstanceOrders,
  checkOrdersFinalization,
  cancelInstanceOrders,
} from 'common/ducks/orders';
import {
  IS_ERROR,
  ERROR,
  IS_LOADING,
  SELLING_LOADING_TITLE,
  SELLING_LOADING_MSG,
  SELLING_ERROR_MSG,
  IS_WARN,
  ERROR_SELL_POSITION_MSG,
  CLOSED_MARKET_TITLE,
  INVALID_PASSWORD_TEXT,
} from 'common/utils/constants';

import { LOGOUT_SUCCEED } from 'common/ducks/auth';
import { openDialog } from 'common/ducks/dialogs';
import { updateInstanceSucceed } from 'common/ducks/instance';

import { PROGRESS_DIALOG_CODE } from 'common/components/Dialogs/ProgressDialog';
import { isLoginError } from 'common/utils/validation';
import { reduceObjectsArrayToObject } from 'common/utils/array';
import { DUCK_NAME as HOLIDAYS_DUCK_NAME } from './holidays';
import { DUCK_NAME as MARKET_DUCK_NAME } from './market';
import { DUCK_NAME as REPORT_DUCK_NAME } from './report';

export const DUCK_NAME = 'positions';
const logger = logdown(DUCK_NAME);

export const INITIAL_STATE = {};

// Actions
export const LOAD_INSTANCE_POSITIONS_STARTED = `${DUCK_NAME}/LOAD_INSTANCE_POSITIONS_STARTED`;
export const LOAD_INSTANCE_POSITIONS_SUCCEED = `${DUCK_NAME}/LOAD_INSTANCE_POSITIONS_SUCCEED`;
export const LOAD_INSTANCE_POSITIONS_FAILED = `${DUCK_NAME}/LOAD_INSTANCE_POSITIONS_FAILED`;
export const loadInstancePositionsStarted = (id) => ({
  id,
  type: LOAD_INSTANCE_POSITIONS_STARTED,
});
export const loadInstancePositionsSucceed = (id, data) => ({
  id,
  data,
  type: LOAD_INSTANCE_POSITIONS_SUCCEED,
});
export const loadInstancePositionsFailed = (id, error) => ({
  id,
  error,
  type: LOAD_INSTANCE_POSITIONS_FAILED,
});
export const loadInstancePositions = (
  id,
  options = { reload: false, reloadOrdersOnChange: false },
  variant = 'instances',
) => async (dispatch, getState) => {
  const state = getState()[DUCK_NAME];
  const instancePositions = state[id];

  if (
    instancePositions
      && !instancePositions.loading
      && !instancePositions.error
      && !options.reload
      && instancePositions.data
  ) {
    return Promise.resolve({ data: instancePositions.data });
  }

  dispatch(loadInstancePositionsStarted(id));

  try {
    const result = await api.strategies.instances
      .positions.getPositions(
        id,
        {
          params: {
            return_attributes: [
              'market_name',
              'stock_code',
              'position_type',
              'financial_volume',
              'average_price',
              'number_of_stocks',
              'is_market_open',
              'has_pending_reset_orders',
              'result',
              'variation',
            ],
          },
          ...options.config,
        },
        variant,
      );
    const { data: { equity } } = getState()[REPORT_DUCK_NAME][id];

    const mergedPositions = getMergedFractionalAndFullPositions(result.data, equity);

    const positions = {
      originalPositions: result.data,
      mergedPositions: mergedPositions.data,
      totalResult: mergedPositions.totalResult,
      totalFinancialVol: mergedPositions.totalFinancialVol,
    };

    dispatch(loadInstancePositionsSucceed(id, positions));
    return Promise.resolve({ data: positions });
  } catch (error) {
    dispatch(loadInstancePositionsFailed(id, error));
    return Promise.reject(error);
  }
};

export const ELIMINATE_INSTANCE_POSITIONS_STARTED = `${DUCK_NAME}/ELIMINATE_INSTANCE_POSITIONS_STARTED`;
export const ELIMINATE_INSTANCE_POSITIONS_SUCCEED = `${DUCK_NAME}/ELIMINATE_INSTANCE_POSITIONS_SUCCEED`;
export const ELIMINATE_INSTANCE_POSITIONS_FAILED = `${DUCK_NAME}/ELIMINATE_INSTANCE_POSITIONS_FAILED`;

export const eliminateInstancePositionsStarted = (id, positions) => ({
  id,
  positions,
  type: ELIMINATE_INSTANCE_POSITIONS_STARTED,
});

export const eliminateInstancePositionsSucceed = (id, positions, data) => ({
  id,
  positions,
  data,
  type: ELIMINATE_INSTANCE_POSITIONS_SUCCEED,
});

export const eliminateInstancePositionsFailed = (id, positions, error) => ({
  id,
  positions,
  error,
  type: ELIMINATE_INSTANCE_POSITIONS_FAILED,
});

/**
 * This function resets the portfolio of the given instance, cancelling the
 * pending orders and reseting it's positions.
 *
 * TODO:
 * - [ ] Passar cancelamento de ordens pendentes para o duck orders, quando
 *       for criado
 * - [ ] Fazer botão "Tentar novamente" caso não consiga checar a finalização
 *       das ordens pendentes
 * - [ ] Mostrar mensagem caso alguma ordem de cancelamento esteja com o
 *       status 'received'. Pode ser que o cliente esteja sem limite na
 *       corretora.
 * - [ ] Fazer botão "Tentar novamente" caso não consiga checar a finalização
 *       das ordens pendentes de eliminação de posição
 * - [ ] Mostrar mensagem caso alguma ordem de eliminação de posição esteja
 *       com o status 'received'. Pode ser que o cliente esteja sem limite na
 *       corretora.
 *
 * @param {Number} id The instance id
 */
export const eliminateInstancePositions = (
  id,
  data,
  config = { resetAllPositions: true },
) => async (dispatch, getState) => {
  // Cancel orders and check if all orders were finalized
  try {
    await api.auth.checkPassword(data);
    const { data: { order_ids: pendingOrders } } = await dispatch(cancelInstanceOrders(
      id,
      data,
      { notifyAction: false },
    ));
    logger.info(
      '(eliminateInstancePositions)',
      `Instance #${id} pending orders were cancelled and checked.`,
      pendingOrders,
    );

    dispatch(openDialog(
      PROGRESS_DIALOG_CODE,
      {
        state: IS_LOADING,
        title: SELLING_LOADING_TITLE,
        message: SELLING_LOADING_MSG,
        closeOnClickBackdrop: false,
      },
    ));
  } catch (error) {
    logger.error(
      '(eliminateInstancePositions)',
      `Could not cancel instance #${id} pending orders.`,
      error,
    );
    if (isLoginError(error)) {
      dispatch(notifyError({
        title: 'Erro!',
        message: INVALID_PASSWORD_TEXT,
      }));
    } else {
      dispatch(openDialog(
        PROGRESS_DIALOG_CODE,
        {
          state: IS_ERROR,
          title: ERROR,
          message: SELLING_ERROR_MSG,
          showButton: true,
        },
      ));
    }
    return Promise.reject(error);
  }
  // Now that we have cancelled all the pending orders, we will try to get the
  // current instance positions
  let positions = null;
  try {
    (
      { data: { originalPositions: positions } } = await dispatch(
        loadInstancePositions(id, { reload: true }),
      )
    );
    logger.info(
      '(eliminateInstancePositions)',
      `Instance #${id} positions loaded.`,
    );
  } catch (error) {
    // Finish with error if we cannot get the current position
    dispatch(openDialog(
      PROGRESS_DIALOG_CODE,
      {
        state: IS_ERROR,
        title: ERROR,
        message: SELLING_ERROR_MSG,
      },
    ));
    logger.error(
      '(eliminateInstancePositions)',
      `Could not reload instance #${id} positions.`,
      error,
    );
    return Promise.reject(error);
  }

  // If the instance dont have any position we can finish with success
  if (positions.length === 0) {
    dispatch(notifyWarn({
      title: 'Atenção!',
      message: `O robô #${id} não possui posições abertas ou acabou de encerrar suas posições.
      Portanto, nenhuma posição foi zerada.`,
    }));
    logger.info(
      '(eliminateInstancePositions)',
      `Instance #${id} is not positioned. Finishing positions reset.`,
    );
    return Promise.resolve({ success: true });
  }

  // Can only eliminate position if it's in trading time.
  const holidays = getState()[HOLIDAYS_DUCK_NAME].data;
  const marketOperationData = getState()[MARKET_DUCK_NAME];
  const marketClosedErrors = await Promise.all(
    positions.map((position) => validateClosedMarket({
      holidays, marketOperationData, position, resetMode: true,
    })),
  );
  const errorMarketClosed = marketClosedErrors.find((e) => e);

  if (errorMarketClosed) {
    const error = {
      type: 'market_closed',
      message: errorMarketClosed,
    };
    dispatch(openDialog(
      PROGRESS_DIALOG_CODE,
      {
        state: IS_WARN,
        title: CLOSED_MARKET_TITLE,
        customIcon: clockIcon,
        message: errorMarketClosed || ERROR_SELL_POSITION_MSG,
        showButton: true,
      },
    ));

    logger.error(
      '(eliminateInstancePositions)',
      'Out of trading time.',
    );
    return Promise.reject(error);
  }

  // After cancelling the instance pending orders and checking if the instance
  // is positioned, we can start the positions reset

  const positionsStockCodes = positions.map((position) => position.stock_code);
  dispatch(eliminateInstancePositionsStarted(id, positionsStockCodes));

  // Here we will try to reset the instance positions
  let pendingResetOrders = null;
  try {
    (
      { data: { order_ids: pendingResetOrders } } = await resetPositions(id, positions,
        Object.assign(config, data))
    );
    logger.log(
      '(eliminateInstancePositions)',
      `Instance #${id} positions reset orders sent.`,
      pendingResetOrders,
    );
  } catch (error) {
    // Finish with error if we cannot reset the positions
    dispatch(openDialog(
      PROGRESS_DIALOG_CODE,
      {
        state: IS_ERROR,
        title: ERROR,
        message: SELLING_ERROR_MSG,
      },
    ));
    dispatch(eliminateInstancePositionsFailed(
      id,
      positionsStockCodes,
      error,
    ));
    logger.error(
      '(eliminateInstancePositions)',
      `Could not reset instance #${id} positions.`,
      error,
    );
    return Promise.reject(error);
  }

  // Then we have to check if the reset orders were finalized
  try {
    await dispatch(checkOrdersFinalization(id, pendingResetOrders, []));
    logger.log(
      '(eliminateInstancePositions)',
      `Instance #${id} positions reset orders finalized.`,
      pendingResetOrders,
    );
  } catch (error) {
    // Finish with error if we cannot check the reset orders finalization
    dispatch(eliminateInstancePositionsFailed(
      id,
      positionsStockCodes,
      error,
    ));
    logger.error(
      '(eliminateInstancePositions)',
      `Could not check if instance #${id} position reset orders were finalized.`,
      error,
    );
    return Promise.reject(error);
  }

  // And finally we finish after cancelling the pending orders and reseting the
  // positions
  logger.info(
    '(eliminateInstancePositions)',
    `Instance #${id} positions reset finished.`,
  );

  // Reload instance positioned status to false
  dispatch(updateInstanceSucceed(id, { positioned: false }));

  // Update instance orders with the last order created
  // from the position elimination
  await dispatch(loadInstanceOrders(
    id,
    {},
    { reload: true },
  ));

  // instance position state
  dispatch(eliminateInstancePositionsSucceed(id, positionsStockCodes));

  return Promise.resolve({ data: { success: true } });
};

export const UPDATE_POSITION_RESULT = `${DUCK_NAME}/UPDATE_POSITION_RESULT`;
export const updatePositionResult = (id, stockCode, result) => (dispatch, getState) => {
  const isPositionValid = get(getState()[DUCK_NAME], `${id}.data.${stockCode}`, false);
  if (isPositionValid) {
    return dispatch({
      type: UPDATE_POSITION_RESULT,
      result,
      id,
      stockCode,
    });
  }

  return null;
};

export const instancePositionsReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case LOAD_INSTANCE_POSITIONS_STARTED:
      return {
        ...state,
        loading: true,
      };
    case LOAD_INSTANCE_POSITIONS_SUCCEED:
      return {
        ...state,
        loading: false,
        error: null,
        data: {
          originalPositions: reduceObjectsArrayToObject(action.data.originalPositions, 'stock_code'),
          mergedPositions: action.data.mergedPositions,
        },
        totalResult: action.data.totalResult || 0,
        totalFinancialVol: action.data.totalFinancialVol || 0,
      };
    case LOAD_INSTANCE_POSITIONS_FAILED:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    default: return state;
  }
};

// Reducer
export default (state = INITIAL_STATE, action) => {
  const { id } = action;

  // Logout cleaning
  if (action.type === LOGOUT_SUCCEED) {
    return INITIAL_STATE;
  }

  if (id) {
    return {
      ...state,
      [id]: instancePositionsReducer(state[id], action),
    };
  }

  return state;
};
