import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Button } from '@material-ui/core';
import { capitalize } from 'lodash';

const ButonDialog = ({
  classes,
  align,
  color,
  children,
  onClick,
  disabled,
  fullWidth,
  hover,
  weight,
  showBorders,
}) => (
  <Button
    className={classNames(
      classes.button,
      showBorders && classes.borders,
      classes[`align${capitalize(align)}`],
      classes[`weight${capitalize(weight)}`],
      !hover && classes.noneHover,
    )}
    fullWidth={fullWidth}
    color={color}
    onClick={onClick}
    disabled={disabled}
  >
    {children}
  </Button>
);

ButonDialog.propTypes = {
  classes: PropTypes.object.isRequired,
  color: PropTypes.string,
  children: PropTypes.any,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  fullWidth: PropTypes.bool,
  align: PropTypes.string,
  hover: PropTypes.bool,
  weight: PropTypes.string,
  showBorders: PropTypes.bool,
};

ButonDialog.defaultProps = {
  color: 'primary',
  children: '',
  onClick: () => {},
  disabled: false,
  fullWidth: true,
  align: 'center',
  hover: false,
  weight: 'normal',
  showBorders: false,
};

export default ButonDialog;
