import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';

import PerformanceChart from './PerformanceChart';

const styles = {
  container: {
    paddingRight: 5,
    paddingLeft: 5,
    paddingTop: 25,
    '& .recharts-tooltip-item-name': {
      display: 'none',
    },
    '& .recharts-surface': {
      overflow: 'visible',
    },
  },
  legend: {
    display: 'flex',
    '@media(max-width: 768px)': {
      flexDirection: 'column',
    },
  },
  legendColor: {
    width: 16,
    height: 16,
  },
  mobile: {
    '@media(max-width: 768px)': {
      fontSize: 12,
    },
  },
};

const mapStateToProps = ({
  storeItems,
  dailyCumulativePerformance,
}, { strategyId, instanceId }) => ({
  storeInstance: storeItems?.data?.items[strategyId] || {},
  instanceCumulativePerformance: dailyCumulativePerformance[instanceId]?.data || {},
});

export default connect(mapStateToProps)(withStyles(styles)(PerformanceChart));
