import { connect } from 'react-redux';
import { error } from 'react-notification-system-redux';

import { updateUserExtraData } from 'common/ducks/user/profile';
import InvestorProfile from './InvestorProfile';

export const INVESTOR_PROFILE_SIDE_DIALOG = 'INVESTOR_PROFILE_SIDE_DIALOG';

const mapStateToProps = ({ user: { profile } }) => ({
  profileData: profile.data,
});

const mapDispatchToProps = (dispatch) => ({
  notifyError: (data) => dispatch(error(data)),
  updateUserExtraData: (data) => dispatch(updateUserExtraData(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(InvestorProfile);
