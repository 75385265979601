import logdown from 'logdown';
import moment from 'moment';
import get from 'lodash/get';
import isEqual from 'lodash/isEqual';
import values from 'lodash/values';
import isUndefined from 'lodash/isUndefined';
import { OPEN_FOLLOW_INSTANCE_DIALOG_CODE } from 'common/components/Dialogs/FollowInstanceDialog';

import { success as notifySuccess, error as notifyError } from 'react-notification-system-redux';

// Ducks
import { LOGOUT_SUCCEED } from 'common/ducks/auth';
import { openDialog, closeDialog } from 'common/ducks/dialogs';
import { updateInvestment } from 'common/ducks/investments';
import { cancelInstanceOrders } from 'common/ducks/orders';
import { eliminateInstancePositions, loadInstancePositions } from 'common/ducks/positions';

// Common
import api from 'common/api';
import { loadBrokerages } from 'common/ducks/brokerages';
import {
  IS_LOADING,
  DEFAULT_LOADING_MSG,
  DEFAULT_LOADING_TITLE,
  IS_ERROR,
  ERROR,
  IS_SUCCESS,
  ERROR_DEFAULT_MSG,
  FOLLOWING_INSTANCE_TEXT,
  ERROR_FOLLOW_INSTANCE_MSG,
  DEFAULT_PARAMS_INSTANCE,
  INVALID_PASSWORD_TEXT,
  CREATING_TEMPLATE_TEXT,
  INSTANCE_CREATOR_URL,
  ERROR_MAX_INSTANCES_REACHED,
  MAX_RUNNING_INSTANCES_ERROR_CODE,
} from 'common/utils/constants';
import { DEFAULT_SETUP_DATA } from 'common/utils/setup';
import {
  getUpdateInstanceData,
  getFullAndFractionalStocksList,
} from 'common/utils/whitebox';

import { PENDING_ORDERS_DIALOG_CODE } from 'common/components/Dialogs/InstanceDialogs/PendingOrdersDialog';
import { PROGRESS_DIALOG_CODE } from 'common/components/Dialogs/ProgressDialog';
import { PASSWORD_CONFIRM_DIALOG_CODE } from 'common/components/Dialogs/PasswordConfirmDialog';

import { getUniqueItemsArray } from 'common/utils/array';
import { DEFAULT_CHANGE_PORTFOLIO_DATE, INSTANCE_DETAILS_TABS } from 'common/utils/constants/instance';
import { getObjectKeysInArrayList } from 'common/utils/functions';
import { setStocksToInstance } from 'common/utils/instance';
import { reloadInstancePositionsAndPendingOrders } from 'common/utils/instances';
import { insertExternalOrders, onCheckForOpenOrders } from 'common/utils/orders';
import { isLoginError } from 'common/utils/validation';
import { TOKEN_2FA_DIALOG_CODE } from 'common/components/Dialogs/Token2FADialog';
import { DUCK_NAME as INSTANCES_DUCK_NAME, createInstance } from './instances';
import { getStocks } from './stocks';
import { isHolidayOrWeekendDay } from './holidays';

const { PERFORMANCE } = INSTANCE_DETAILS_TABS;
const logger = logdown('ducks/instance');

export const DUCK_NAME = 'instance';

export const INITIAL_STATE = {
  /*
   * The instance state should have these fields:
   * - id
   * - strategy_id
   * - brokerage_id
   * - state
   * - reloadingState
   * - reloadStateError
   * - reloadInstance
   * - reloadInstanceError
   * - stopping
   * - stopError
   * - name
   * - params
   */
};

export const GET_INSTANCE_SETUP_STARTED = `${DUCK_NAME}/GET_INSTANCE_SETUP_STARTED`;
export const GET_INSTANCE_SETUP_SUCCEED = `${DUCK_NAME}/GET_INSTANCE_SETUP_SUCCEED`;
export const GET_INSTANCE_SETUP_FAILED = `${DUCK_NAME}/GET_INSTANCE_SETUP_FAILED`;
export const getInstanceSetupStarted = (id) => ({
  id,
  type: GET_INSTANCE_SETUP_STARTED,
});
export const getInstanceSetupSucceed = (id, data) => ({
  id,
  data,
  type: GET_INSTANCE_SETUP_SUCCEED,
});
export const getInstanceSetupFailed = (id, error) => ({
  id,
  error,
  type: GET_INSTANCE_SETUP_FAILED,
});
export const getInstanceSetup = (id) => async (dispatch) => {
  dispatch(getInstanceSetupStarted(id));
  try {
    const { data: { code } } = await api.strategies.instances.getInstanceSetup(id);
    dispatch(getInstanceSetupSucceed(id, code));
    return Promise.resolve(code);
  } catch (error) {
    dispatch(getInstanceSetupFailed(id, error));
    return Promise.reject(error);
  }
};

export const RELOAD_INSTANCE_STATE_STARTED = `${DUCK_NAME}/RELOAD_INSTANCE_STATE_STARTED`;
export const RELOAD_INSTANCE_STATE_SUCCEED = `${DUCK_NAME}/RELOAD_INSTANCE_STATE_SUCCEED`;
export const RELOAD_INSTANCE_STATE_FAILED = `${DUCK_NAME}/RELOAD_INSTANCE_STATE_FAILED`;

export const reloadInstanceStateStarted = (id) => ({
  id,
  type: RELOAD_INSTANCE_STATE_STARTED,
});

export const reloadInstanceStateSucceed = (id, state) => ({
  id,
  state,
  type: RELOAD_INSTANCE_STATE_SUCCEED,
});

export const reloadInstanceStateFailed = (id, error) => ({
  id,
  error,
  type: RELOAD_INSTANCE_STATE_FAILED,
});

export const reloadInstanceState = (id) => async (dispatch) => {
  dispatch(reloadInstanceStateStarted(id));
  try {
    const result = await api.strategies.instances.getInstanceState(id);

    logger.info(`Instance #${id} state reloaded.`, result);

    dispatch(reloadInstanceStateSucceed(id, result.data.state));
    return Promise.resolve(result);
  } catch (error) {
    logger.error(`Failed to reload instance #${id} state.`, error);
    dispatch(reloadInstanceStateFailed(id, error));
    return Promise.reject(error);
  }
};

export const GET_INSTANCE_STARTED = `${DUCK_NAME}/GET_INSTANCE_STARTED`;
export const GET_INSTANCE_SUCCEED = `${DUCK_NAME}/GET_INSTANCE_SUCCEED`;
export const GET_INSTANCE_FAILED = `${DUCK_NAME}/GET_INSTANCE_FAILED`;

export const getInstanceStarted = (id) => ({
  id,
  type: GET_INSTANCE_STARTED,
});

export const getInstanceSucceed = (id, data) => ({
  id,
  data,
  type: GET_INSTANCE_SUCCEED,
});

export const getInstanceFailed = (id, error) => ({
  id,
  error,
  type: GET_INSTANCE_FAILED,
});

export const getInstance = (
  id,
  params,
  options = { reload: false },
  commit = true,
  variant = 'instances',
) => async (dispatch, getState) => {
  const instance = get(
    getState()[INSTANCES_DUCK_NAME],
    `data[${id}]`,
    null,
  );

  if (
    !options.reload
    && instance
    && !instance.loading
    && !instance.error
  ) {
    return Promise.resolve(instance);
  }
  if (commit) {
    dispatch(getInstanceStarted(id));
  }
  try {
    const result = await api.strategies.instances.getInstance(
      id, {
        params,
      },
      variant,
    );
    const instanceSetupCode = await dispatch(getInstanceSetup(id));

    const updatedInstance = {
      ...setStocksToInstance({
        instance: result.data,
        params: result.data.params,
      }),
      setup: instanceSetupCode,
    };

    logger.info(`get Instance #${id}.`, result);
    if (commit) {
      dispatch(getInstanceSucceed(id, {
        ...updatedInstance,
        lastUpdated: moment(),
      }));
    }
    return Promise.resolve(updatedInstance);
  } catch (error) {
    logger.error(`Failed to get Instance #${id}.`, error);
    if (commit) {
      dispatch(getInstanceFailed(id, error));
    }
    return Promise.reject(error);
  }
};

export const DELETE_INSTANCE_STARTED = `${DUCK_NAME}/DELETE_INSTANCE_STARTED`;
export const DELETE_INSTANCE_SUCCEED = `${DUCK_NAME}/DELETE_INSTANCE_SUCCEED`;
export const DELETE_INSTANCE_FAILED = `${DUCK_NAME}/DELETE_INSTANCE_FAILED`;

export const deleteInstanceStarted = (id) => ({
  id,
  type: DELETE_INSTANCE_STARTED,
});

export const deleteInstanceSucceed = (id) => ({
  id,
  type: DELETE_INSTANCE_SUCCEED,
});

export const deleteInstanceFailed = (id, error) => ({
  id,
  error,
  type: DELETE_INSTANCE_FAILED,
});

export const deleteInstance = (instance, params) => async (dispatch) => {
  const { id, name } = instance;
  dispatch(deleteInstanceStarted(id));

  try {
    dispatch(openDialog(
      PROGRESS_DIALOG_CODE,
      {
        state: IS_LOADING,
        title: 'Excluindo template...',
        message: DEFAULT_LOADING_MSG,
        closeOnClickBackdrop: false,
      },
    ));

    const result = await api.strategies.instances.deleteInstance(id, { params });

    dispatch(openDialog(
      PROGRESS_DIALOG_CODE,
      {
        state: IS_SUCCESS,
        title: 'Template excluído!',
        message: `O template <strong>${name}</strong> foi excluído com sucesso.`,
        showButton: true,
      },
    ));

    logger.info(`delete Instance #${id}.`, result);
    dispatch(deleteInstanceSucceed(id));

    return Promise.resolve(result.data);
  } catch (error) {
    logger.error(`Failed to delete Instance #${id}.`, error);
    dispatch(deleteInstanceFailed(id, error));
    dispatch(openDialog(
      PROGRESS_DIALOG_CODE,
      {
        state: IS_ERROR,
        title: ERROR,
        message: `Não foi possível excluir o template <strong>${name}</strong> ${ERROR_DEFAULT_MSG}`,
        showButton: true,
      },
    ));

    return Promise.reject(error);
  }
};

export const UPDATE_INSTANCE_STARTED = `${DUCK_NAME}/UPDATE_INSTANCE_STARTED`;
export const UPDATE_INSTANCE_SUCCEED = `${DUCK_NAME}/UPDATE_INSTANCE_SUCCEED`;
export const UPDATE_INSTANCE_RELATED_SUCCEED = `${DUCK_NAME}/UPDATE_INSTANCE_RELATED_SUCCEED`;
export const UPDATE_INSTANCE_FAILED = `${DUCK_NAME}/UPDATE_INSTANCE_FAILED`;

export const updateInstanceStarted = (id) => ({
  id,
  type: UPDATE_INSTANCE_STARTED,
});

export const updateInstanceSucceed = (id, data) => ({
  id,
  data,
  type: UPDATE_INSTANCE_SUCCEED,
});

export const updateInstanceRelatedSucceed = (id) => ({
  id,
  type: UPDATE_INSTANCE_RELATED_SUCCEED,
});

export const updateInstanceFailed = (id, error) => ({
  id,
  error,
  type: UPDATE_INSTANCE_FAILED,
});

export const updateInstance = (id, data, params, variant) => async (dispatch) => {
  dispatch(updateInstanceStarted(id));

  try {
    const result = await api.strategies.instances.updateInstance(id, data, variant);
    logger.info(`update Instance #${id}.`, result);
  } catch (error) {
    logger.error(`Failed to update Instance #${id}.`, error);
    dispatch(updateInstanceFailed(id, error));
    return Promise.reject(error);
  }

  // Reload instance state
  if (variant === 'instances') {
    try {
      await dispatch(reloadInstanceState(id, variant));
      logger.info(`Instance #${id} state reloaded.`);
    } catch (error) {
      logger.error(`Failed to reload instance #${id} state.`, error);
      dispatch(updateInstanceFailed(id, error));
      return Promise.reject(error);
    }
  }

  dispatch(updateInstanceSucceed(id, data));
  return Promise.resolve(data);
};

export const UPDATE_INSTANCE_WITHOUT_SIDE_EFFECT = `${DUCK_NAME}/UPDATE_INSTANCE_WITHOUT_SIDE_EFFECT`;

export const updateInstanceWithoutSideEffect = ({ id, updating }) => ({
  id,
  updating,
  type: UPDATE_INSTANCE_WITHOUT_SIDE_EFFECT,
});

export const STOP_INSTANCE_STARTED = `${DUCK_NAME}/STOP_INSTANCE_STARTED`;
export const STOP_INSTANCE_SUCCEED = `${DUCK_NAME}/STOP_INSTANCE_SUCCEED`;
export const STOP_INSTANCE_FAILED = `${DUCK_NAME}/STOP_INSTANCE_FAILED`;

export const stopInstanceStarted = (id) => ({
  id,
  type: STOP_INSTANCE_STARTED,
});
export const stopInstanceSucceed = (id) => ({
  id,
  type: STOP_INSTANCE_SUCCEED,
});
export const stopInstanceFailed = (id, error) => ({
  id,
  error,
  type: STOP_INSTANCE_FAILED,
});

export const getDiff = (currentPositions, positions) => {
  const diff = [];
  for (let i = 0; i < currentPositions.length; i += 1) {
    if (!isEqual(currentPositions[i].number_of_stocks, positions[i].number_of_stocks)
    || !isEqual(currentPositions[i].position_type, positions[i].position_type)) {
      diff.push(positions[i]);
    }
  }
  if (currentPositions.length < positions.length) {
    diff.push(...positions.slice(currentPositions.length));
  }
  return diff;
};

export const verifyPositionsAndOpenOrders = async (
  dispatch,
  id,
  promises,
) => {
  const reloadInstancePositions = () => dispatch(loadInstancePositions(
    id,
    { reload: true },
  ));
  const { pendingOrders } = await reloadInstancePositionsAndPendingOrders(
    id,
    reloadInstancePositions,
  );

  if (pendingOrders.length > 0) {
    try {
      await dispatch(openDialog(
        PENDING_ORDERS_DIALOG_CODE,
        {
          id,
          closeButtonLabel: 'Manter ordens',
          positionsAfterStop: true,
          warn: true,
        },
      ));
    } catch (error) {
      logger.error(`Could not reload instance ${id} opened positions.`, error);
      dispatch(stopInstanceFailed(id, error));
      promises.push(Promise.reject(error));
    }
  }
};

export const stopInstance = (
  id,
  history,
  data,
  options = {
    ignoreModals: false,
    eliminatePositions: false,
    positioned: true,
    cancelPendingOrders: false,
  },
  config = {},
) => async (dispatch, getState) => {
  logger.log(`stop instance #${id}`);
  dispatch(stopInstanceStarted(id));

  /* promises array is used to get intermediate errors while still
  making sure this thunk will reach reloadInstanceState's try/catch */
  const promises = [];

  // Stop instance
  try {
    await api.auth.checkPassword(data);
    await api.strategies.instances.stopInstance(id, Object.assign(config, data));
    logger.info(`Instance #${id} stoped.`);

    if (!options.ignoreModals) {
      dispatch(openDialog(
        PROGRESS_DIALOG_CODE,
        {
          state: IS_LOADING,
          title: DEFAULT_LOADING_TITLE,
          message: DEFAULT_LOADING_MSG,
          closeOnClickBackdrop: false,
        },
      ));
    }
  } catch (error) {
    dispatch(stopInstanceFailed(id, error));
    if (isLoginError(error)) {
      dispatch(notifyError({
        title: 'Erro!',
        message: INVALID_PASSWORD_TEXT,
      }));

      return Promise.reject(error);
    }

    if (!options.ignoreModals) {
      dispatch(openDialog(
        PROGRESS_DIALOG_CODE,
        {
          state: IS_ERROR,
          title: ERROR,
          message: `Não foi possível parar de seguir a carteira. ${ERROR_DEFAULT_MSG}`,
          showButton: true,
        },
      ));
    }

    logger.error(`Failed to stop instance #${id}.`, error);
    return Promise.reject(error);
  }

  // Eliminate instance positions
  if (options.eliminatePositions) {
    try {
      await dispatch(eliminateInstancePositions(
        id,
        // prevent eliminateInstacePositions action from requesting
        // the password again
        { password: data.password, is_position: true },
        { resetAllPositions: true },
      ));
      logger.info(`Instance #${id} positions eliminated.`);
    } catch (error) {
      logger.error(`Failed to eliminate instance #${id} positions.`, error);
      dispatch(stopInstanceFailed(id, error));
      dispatch(notifyError({
        title: 'Erro!',
        message: `Ocorreu um erro ao vender os ativos da carteira #${id}. Tente novamente`,
      }));
      promises.push(Promise.reject(error));
    }
  }
  const currentPositions = values(getState().positions[id].data);

  // Cancel instance pending orders
  if (options.cancelPendingOrders) {
    try {
      await dispatch(cancelInstanceOrders(id, data));
    } catch (error) {
      logger.error(`Failed to cancel pending orders of instance #${id}.`, error);
      dispatch(stopInstanceFailed(id, error));
      promises.push(Promise.reject(error));
    }
  }

  // Reload instance state
  try {
    await dispatch(reloadInstanceState(id));
    logger.info(`Instance #${id} state reloaded.`);
  } catch (error) {
    logger.error(`Failed to reload instance #${id} state.`, error);
    dispatch(stopInstanceFailed(id, error));
    promises.push(Promise.reject(error));
  }

  // this is a flag that tells the instance has modified parameters on a running instance
  // it must go away if instance is stopped by any means
  const pendingRestart = localStorage.getItem(`pendingRestartInstance${id}`);
  if (pendingRestart) {
    localStorage.removeItem(`pendingRestartInstance${id}`);
  }

  dispatch(stopInstanceSucceed(id));

  // Verify if an instance has any pending orders or positions after stopped
  verifyPositionsAndOpenOrders(
    dispatch,
    id,
    promises,
    currentPositions,
  );

  promises.push(Promise.resolve({ success: true }));
  return Promise.all(promises);
};

export const START_INSTANCE_STARTED = `${DUCK_NAME}/START_INSTANCE_STARTED`;
export const START_INSTANCE_SUCCEED = `${DUCK_NAME}/START_INSTANCE_SUCCEED`;
export const START_INSTANCE_FAILED = `${DUCK_NAME}/START_INSTANCE_FAILED`;

export const startInstanceStarted = (id) => ({
  id,
  type: START_INSTANCE_STARTED,
});

export const startInstanceSucceed = (id, state) => ({
  id,
  state,
  type: START_INSTANCE_SUCCEED,
});

export const startInstanceFailed = (id, error) => ({
  id,
  error,
  type: START_INSTANCE_FAILED,
});

export const startInstanceErrors = (id) => ({
  instance: `Start instance #${id} failed, instance is not configured`,
  plan: `Start instance #${id} failed due to plan restriction`,
  brokerages: `Start instance #${id} failed, user don't have any approved brokerage`,
  orders: `Start instance #${id} failed, instance has pending orders`,

  stocks: [
    `Start instance #${id} failed, stockCode is disabled`,
    `Start instance #${id} failed, stockCode has expired`,
    `Start instance #${id} failed, trading_lot_size is invalid`,
    `Start instance #${id} failed, error getting instance state`,
  ],
});
/**
 * This function receives an instance id
 * and before starting it, the method should validate if:
 *   > the user plan is not free
 *   > the instance brokerage is approved
 * - instance has ongoing pending orders
 * - instance stock:
 *   > trading_lot_size is greater than instance numberOfStocks
 *     or if it's not a multiple of trading_lot_size
 *   > is expired
 *   > stock is invalid, that is if is_still_trading = 0
 * @param {number} id
 * @return {Promise}
 */

export const startInstance = ({
  id,
  config = {},
  data,
  options = {
    ignoreModals: false,
  },
}) => async (dispatch, getState) => {
  logger.log(`starting instance #${id}`);

  dispatch(startInstanceStarted(id));

  const instance = getState().instances.data[id];

  if (!get(instance, 'params.__types__.stockCode', false)
  && !get(instance, 'params.__types__.stockCodesList', false)) {
    dispatch(startInstanceFailed(id, startInstanceErrors(id).instance));
    logger.log(startInstanceErrors(id).instance);

    dispatch(notifyError({
      title: 'Erro!',
      message: `Ocorreu um erro inesperado ao iniciar a carteira #${id}. Tente novamente`,
    }));

    return Promise.reject(startInstanceErrors(id).instance);
  }

  // Check if instance brokerage status is approved
  try {
    const { data: { brokerages } } = await dispatch(loadBrokerages());

    if (brokerages[instance.brokerage_id].status !== 'approved') {
      dispatch(startInstanceFailed(id, startInstanceErrors(id).brokerages));
      logger.log(startInstanceErrors(id).brokerages);
      dispatch(notifyError({
        title: 'Erro!',
        message: 'Para iniciar esta carteira a corretora associada deve estar aprovada.',
      }));
      return Promise.reject(startInstanceErrors(id).brokerages);
    }
  } catch (error) {
    dispatch(startInstanceFailed(id, error));
    logger.log(`Start instance #${id} failed, error loading brokerages`);
    dispatch(notifyError({
      title: 'Erro!',
      message: `Ocorreu um erro inesperado ao iniciar a carteira #${id}. Tente novamente`,
    }));
    return Promise.reject(error);
  }

  // Check if instance has any ongoing pending orders
  try {
    const { data: { orders: pendingOrders } } = await api
      .strategies
      .instances
      .orders.getOrders(id, { params: { only_pending: 1 } });

    if (pendingOrders.length > 0) {
      dispatch(startInstanceFailed(id, startInstanceErrors(id).orders));
      logger.log(startInstanceErrors(id).orders);
      dispatch(notifyError({
        title: 'Erro!',
        message: `Existem ordens abertas no robô #${id}. Você tem duas opções para poder iniciá-lo: 1) zerar a posição atual cancelando as ordens em aberto, ou 2) atualizar a posição para informar uma alteração de posição feita através de outra plataforma ou corretora.`,
        autoDismiss: 0,
      }));
      return Promise.reject(startInstanceErrors(id).orders);
    }
  } catch (error) {
    dispatch(startInstanceFailed(id, error));
    logger.log(`Start instance #${id} failed, error loading pending orders`);
    dispatch(notifyError({
      title: 'Erro!',
      message: `Ocorreu um erro inesperado ao iniciar a carteira #${id}. Tente novamente`,
    }));
    return Promise.reject(error);
  }

  const { params } = instance;
  const numberOfStocksToTrade = Number.parseInt(
    (
      params.numberOfStocksToTrade
        || params.POS_numberOfStocksToTrade
        || params._numberOfStocksToTrade || // eslint-disable-line
        0
    ),
    10,
  );
    // Check if instance and instance stock is valid
  if (params.stockCode) {
    try {
      const { data: stocks } = await api.stock.getStocks({
        params: {
          stock_code: params.stockCode,
          market_name: params.marketName,
          attributes: 'trading_lot_size,is_still_trading,expiration_date',
        },
      });

      // Check if stock is still trading
      if (
        stocks.find((stock) => stock.is_still_trading === 0)
      ) {
        dispatch(startInstanceFailed(id, startInstanceErrors(id).stocks[0]));
        logger.log(startInstanceErrors(id).stocks[0]);
        dispatch(notifyError({
          title: 'Erro!',
          message: `Código não negociado na ${params.marketName}.`,
        }));
        return Promise.reject();
      }

      // Check if stock is not expired
      if (
        stocks
          .find((stock) => moment(stock.expiration_date)
            .isBefore(moment()))
      ) {
        logger.log(startInstanceErrors(id).stocks[1]);
        dispatch(startInstanceFailed(id, startInstanceErrors(id).stocks[1]));
        dispatch(notifyError({
          title: 'Erro!',
          message: `Código ${params.stockCode} expirado.`,
        }));
        return Promise.reject();
      }

      // If quantity type is not FIXED_CAPITAL,
      // check if instance number of stocks is greater than
      // stock trading lot size and multiple of it
      const instanceStock = stocks
        .find((stock) => !isUndefined(stock.trading_lot_size));
      const tradingLotSize = Number.parseInt(
        instanceStock.trading_lot_size,
        10,
      );
      if (
        (
          !params.POS_quantity_type
            || params.POS_quantity_type !== 'FIXED_CAPITAL'
        )
          && (
            numberOfStocksToTrade < tradingLotSize
            || numberOfStocksToTrade % tradingLotSize !== 0
          )
      ) {
        dispatch(startInstanceFailed(id, startInstanceErrors(id).stocks[2]));
        logger.log(startInstanceErrors(id).stocks[2]);
        dispatch(notifyError({
          message: `Quantidade por ordem incorreta! Escolha um valor múltiplo do lote mínimo do papel (${tradingLotSize}).`,
        }));
        return Promise.reject();
      }
    } catch (error) {
      logger.log(`Start instance #${id} failed, error validating instance stocks`);
      dispatch(startInstanceFailed(id, error));
      dispatch(notifyError({
        title: 'Erro!',
        message: `Ocorreu um erro inesperado ao iniciar a carteira #${id}. Tente novamente`,
      }));
      return Promise.reject(error);
    }
  }

  // Finally start instance and treat it's error codes
  // that are mapped in axios interceptor
  try {
    await api.strategies.instances.startInstance(id, Object.assign(config, data));

    if (!options.ignoreModals) {
      dispatch(openDialog(
        PROGRESS_DIALOG_CODE,
        {
          state: IS_LOADING,
          title: `${FOLLOWING_INSTANCE_TEXT}...`,
          message: DEFAULT_LOADING_MSG,
          closeOnClickBackdrop: false,
        },
      ));
    }
  } catch (error) {
    dispatch(startInstanceFailed(id, error));
    logger.log(`Start instance #${id} failed`);
    if (!options.ignoreModals) {
      const isMaxInstancesError = error.code === MAX_RUNNING_INSTANCES_ERROR_CODE;

      dispatch(openDialog(
        PROGRESS_DIALOG_CODE,
        {
          state: IS_ERROR,
          title: ERROR,
          message: isMaxInstancesError ? ERROR_MAX_INSTANCES_REACHED : ERROR_FOLLOW_INSTANCE_MSG,
          showButton: true,
        },
      ));
    }

    return Promise.reject(error);
  }

  // reload instance state
  try {
    await dispatch(reloadInstanceState(id));
  } catch (error) {
    logger.log(`Start instance #${id} failed, error getting instance state`);
    dispatch(notifyError({
      title: 'Erro!',
      message: `Erro ao atualizar o estado da carteira #${id}.`,
    }));
  }

  dispatch(startInstanceSucceed(id));

  return Promise.resolve();
};

export const restartInstance = (
  id,
  history,
  config = {},
  data,
) => async (dispatch) => {
  const options = {
    ignoreModals: true,
    eliminatePositions: false,
    positioned: true,
    cancelPendingOrders: false,
  };

  try {
    logger.log(`restart instance ${id} (stopping)`);
    await dispatch(stopInstance(id, history, data, options, config));
  } catch (error) {
    logger.log(`restart instance ${id} (stopping) failed: `, error);
    return Promise.reject(error);
  }

  try {
    logger.log(`restart instance (starting) ${id}`);
    await dispatch(startInstance({
      id, config, data, options: { ignoreModals: true },
    }));
  } catch (error) {
    logger.log(`restart instance ${id} (starting) failed: `, error);
    return Promise.reject(error);
  }

  logger.log(`restart instance ${id} succeeded`);
  return Promise.resolve();
};

export const restartUpdateInstance = ({
  id,
  data,
  successParams = {},
  loadingParams = {},
  errorParams = {},
  showSuccessDialog = true,
  showLoadingDialog = false,
  showErrorDialog = false,
  password,
}) => async (dispatch) => {
  const onConfirmClick = async (confirmPassword) => {
    const dataPassword = {
      password: confirmPassword,
    };

    const options = {
      ignoreModals: true,
      eliminatePositions: false,
      positioned: true,
      cancelPendingOrders: false,
    };
    try {
      if (showLoadingDialog) {
        dispatch(openDialog(PROGRESS_DIALOG_CODE, {
          state: IS_LOADING,
          title: loadingParams.title || DEFAULT_LOADING_TITLE,
          message: DEFAULT_LOADING_MSG,
          showButton: true,
          onClose: () => dispatch(closeDialog()),
        }));
      }
      await dispatch(updateInstanceWithoutSideEffect({ id, updating: true }));
      await dispatch(stopInstance(id, false, dataPassword, options));
      await dispatch(updateInstance(id, data));
      await dispatch(startInstance({
        id,
        config: {},
        data: dataPassword,
        options: { ignoreModals: true },
      }));
      dispatch(updateInstanceWithoutSideEffect({ id, updating: false }));
    } catch (error) {
      dispatch(updateInstanceWithoutSideEffect({ id, updating: false }));
      if (showErrorDialog) {
        dispatch(openDialog(PROGRESS_DIALOG_CODE, {
          state: IS_ERROR,
          title: errorParams.title || ERROR,
          message: errorParams.message,
          showButton: true,
          onClose: () => dispatch(closeDialog()),
        }));
      }
      return Promise.reject(error);
    }

    if (showSuccessDialog) {
      dispatch(openDialog(PROGRESS_DIALOG_CODE, {
        state: IS_SUCCESS,
        title: successParams.title,
        message: successParams.message,
        showButton: true,
        onClose: () => dispatch(closeDialog()),
      }));
    }

    return Promise.resolve();
  };
  return password
    ? onConfirmClick(password)
    : dispatch(openDialog(PASSWORD_CONFIRM_DIALOG_CODE, {
      open: true,
      onConfirmClick,
      onClose: () => dispatch(closeDialog()),
    }));
};

export const GET_INSTANCE_STATE_STARTED = `${DUCK_NAME}/GET_INSTANCE_STATE_STARTED`;
export const GET_INSTANCE_STATE_SUCCEED = `${DUCK_NAME}/GET_INSTANCE_STATE_SUCCEED`;
export const GET_INSTANCE_STATE_FAILED = `${DUCK_NAME}/GET_INSTANCE_STATE_FAILED`;
export const getInstanceStateStarted = (id) => ({
  id,
  type: GET_INSTANCE_STATE_STARTED,
});
export const getInstanceStateSucceed = (id, data) => ({
  id,
  data,
  type: GET_INSTANCE_STATE_SUCCEED,
});
export const getInstanceStateFailed = (id, error) => ({
  id,
  error,
  type: GET_INSTANCE_STATE_FAILED,
});
export const getInstanceState = (id) => async (dispatch) => {
  dispatch(getInstanceStateStarted(id));
  try {
    const response = await api.strategies.instances.getInstanceState(id);
    dispatch(getInstanceStateSucceed(id, response));
    return Promise.resolve(response);
  } catch (error) {
    dispatch(getInstanceStateFailed(id, error));
    return Promise.reject(error);
  }
};

export const followInstance = ({
  submitData,
  forcePortfolio,
  history,
  dataSteps,
  isWhiteBox = false,
  templateId,
  startFromZero,
  minOperationValue,
}) => async (dispatch, getState) => {
  const onConfirmClick = async (token) => {
    const data = {};

    try {
      dispatch(openDialog(
        PROGRESS_DIALOG_CODE,
        {
          state: IS_LOADING,
          title: `${FOLLOWING_INSTANCE_TEXT}...`,
          message: DEFAULT_LOADING_MSG,
          closeOnClickBackdrop: false,
        },
      ));

      const hasPositions = !!dataSteps.data.positions;
      const initialInstanceData = !hasPositions
        ? submitData
        : { ...submitData, setup_code: DEFAULT_SETUP_DATA.code };

      const { id } = await dispatch(createInstance(initialInstanceData));

      const changePortfolioDate = dataSteps.data.change_portfolio_date
      || DEFAULT_CHANGE_PORTFOLIO_DATE;
      let stocksFullAndFractionalCodes = [];

      if (hasPositions) {
        stocksFullAndFractionalCodes = getFullAndFractionalStocksList(
          dataSteps.data.positions,
        );
      }

      if (isWhiteBox) {
        const template = getState()[INSTANCES_DUCK_NAME].data[templateId];
        const {
          params: {
            PORTFOLIO_number_of_assets,
            PORTFOLIO_stocks,
            PORTFOLIO_weights,
            stockCodesList,
            force_mount_portfolio,
            lot_sizes,
          },
        } = template;

        const finalStockCodeList = getUniqueItemsArray([
          ...stockCodesList.split(','),
          ...stocksFullAndFractionalCodes,
        ]);

        const whiteBoxParams = {
          PORTFOLIO_number_of_assets,
          PORTFOLIO_stocks,
          PORTFOLIO_weights,
          stockCodesList: finalStockCodeList.toString(),
          change_portfolio_date: changePortfolioDate,
          force_mount_portfolio,
          lot_sizes,
        };

        await dispatch(updateInstance(id, {
          params: {
            ...DEFAULT_PARAMS_INSTANCE,
            ...whiteBoxParams,
            execution_time: dataSteps.data.selectedDateOption,
            __types__: {
              ...DEFAULT_PARAMS_INSTANCE.__types__,
              change_portfolio_date: 'string',
            },
          },
        }));
      } else {
        const finalStockCodeList = getUniqueItemsArray([
          ...DEFAULT_PARAMS_INSTANCE.stockCodesList.split(','),
          ...stocksFullAndFractionalCodes,
        ]);

        await dispatch(updateInstance(id, {
          params: {
            ...DEFAULT_PARAMS_INSTANCE,
            PORTFOLIO_min_operation_value: minOperationValue,
            execution_time: dataSteps.data.selectedDateOption,
            force_mount_portfolio: `${forcePortfolio}`,
            change_portfolio_date: changePortfolioDate,
            stockCodesList: finalStockCodeList.toString(),
            __types__: {
              ...DEFAULT_PARAMS_INSTANCE.__types__,
            },
          },
        }));
      }
      if (hasPositions) {
        await insertExternalOrders({
          instanceId: id,
          brokerageId: submitData.brokerage_id,
          orderData: {
            reason: 'Iniciando carteira com posição',
          },
          positions: dataSteps.data.positions,
        });

        await dispatch(updateInvestment({
          setup_code: submitData.setup_code,
          code: `instance_${id}`,
          brokerage_id: submitData.brokerage_id,
        }));
      }

      if (token) {
        data.token2fa = token;
      }

      await dispatch(startInstance({ id, data }));

      dispatch(openDialog(
        PROGRESS_DIALOG_CODE,
        {
          state: IS_SUCCESS,
          title: FOLLOWING_INSTANCE_TEXT,
          message: `Você começou a seguir a carteira. Lembre-se de alocar o dinheiro na sua
          corretora considerando todos os custos da montagem/rolagem.`,
          showButton: true,
          buttonMessage: 'Ver carteira',
          buttonClick: () => {
            dispatch(closeDialog());
            history.replace(`/private/carteiras/${id}/${PERFORMANCE}`);
          },
          closeOnClickBackdrop: !isWhiteBox,
          showCloseIcon: !isWhiteBox,
        },
      ));
    } catch (error) {
      logger.log('error updating user profile');

      if (isLoginError(error)) {
        dispatch(notifyError({
          title: 'Erro!',
          message: INVALID_PASSWORD_TEXT,
        }));

        return Promise.reject(error);
      }

      const isMaxInstancesError = error.code === MAX_RUNNING_INSTANCES_ERROR_CODE;

      dispatch(openDialog(
        PROGRESS_DIALOG_CODE,
        {
          state: IS_ERROR,
          title: ERROR,
          message: isMaxInstancesError ? ERROR_MAX_INSTANCES_REACHED : ERROR_FOLLOW_INSTANCE_MSG,
          showButton: true,
        },
      ));

      return Promise.reject(error);
    }

    return Promise.resolve();
  };

  const { valid: isSessionValidated } = (await api.auth.getSessionTokenValidity()).data;

  if (!isSessionValidated) {
    dispatch(openDialog(TOKEN_2FA_DIALOG_CODE, {
      open: true,
      onConfirmClick,
      onClose: () => dispatch(openDialog(
        OPEN_FOLLOW_INSTANCE_DIALOG_CODE,
        {
          storedData: { ...dataSteps },
          allowedBrokerages: dataSteps.data.allowedBrokerages,
          strategyId: dataSteps.data.storeInstance.strategy_id,
          storedSubmitData: submitData,
          isWhiteBox,
          startFromZero,
          templateId,
        },
      )),
    }));
  } else {
    onConfirmClick();
  }
};

export const createSimulatedInstance = (name, history) => async (dispatch) => {
  dispatch(openDialog(
    PROGRESS_DIALOG_CODE,
    {
      state: IS_LOADING,
      title: `${CREATING_TEMPLATE_TEXT}...`,
      message: DEFAULT_LOADING_MSG,
      closeOnClickBackdrop: false,
    },
  ));

  try {
    const submitData = {
      name,
      strategy_id: 620,
      initial_capital: '1000.00',
      simulation_type: 0,
      brokerage_id: 1000,
      trading_system_code: 'default_trading_system',
      setup_code: 'default_setup',
    };

    const saveData = {
      PORTFOLIO_number_of_assets: '1',
      PORTFOLIO_stocks: 'BOVA11',
      stockCodesList: 'BOVA11, BOVA11F',
      PORTFOLIO_weights: '100',
      lot_sizes: '10',
      change_portfolio_date: DEFAULT_CHANGE_PORTFOLIO_DATE,
      force_mount_portfolio: true,
    };

    const { id } = await dispatch(createInstance(submitData));

    await dispatch(updateInstance(id, {
      params: {
        ...saveData,
        __types__: {
          ...DEFAULT_PARAMS_INSTANCE.__types__,
        },
      },
      archived: true,
    }));

    dispatch(closeDialog());
    dispatch(notifySuccess({
      title: 'Sucesso!',
      message: 'O template foi criado',
    }));
    history.push(`${INSTANCE_CREATOR_URL}/meus-templates/${id}`);
  } catch (error) {
    dispatch(notifyError({
      title: 'Erro!',
      message: 'Algum erro aconteceu, tente novamente',
    }));

    return Promise.reject(error);
  }
  return Promise.resolve();
};

export const rebalanceInstance = ({
  instanceId,
  handleOpenOrdersDialog,
}) => async (dispatch, getState) => {
  const onConfirmClick = async (password) => {
    const data = {
      password,
    };
    await onCheckForOpenOrders(instanceId, handleOpenOrdersDialog);

    try {
      dispatch(updateInstanceWithoutSideEffect({ id: instanceId, updating: true }));

      dispatch(openDialog(
        PROGRESS_DIALOG_CODE,
        {
          state: IS_LOADING,
          title: 'Alterando a carteira',
          message: DEFAULT_LOADING_MSG,
          closeOnClickBackdrop: false,
        },
      ));

      const { whitebox: { stocks }, positions } = getState();
      const instance = get(getState()[INSTANCES_DUCK_NAME], `data[${instanceId}]`, null);
      const instancePositions = positions[instanceId].data.mergedPositions;
      const positionsStockList = getObjectKeysInArrayList(instancePositions);

      const whiteBoxParams = await getUpdateInstanceData({
        instanceParams: instance.params,
        stockList: Object.values(stocks),
        positions: positionsStockList,
        isRebalance: true,
        getStocks: (searchTerm) => dispatch(getStocks(searchTerm)),
        isHolidayOrWeekendDay: (date) => dispatch(isHolidayOrWeekendDay(date)),
      });

      const { params } = await dispatch(updateInstance(instanceId, {
        params: {
          ...DEFAULT_PARAMS_INSTANCE,
          ...whiteBoxParams,
          __types__: {
            ...DEFAULT_PARAMS_INSTANCE.__types__,
            change_portfolio_date: 'string',
          },
        },
      }));

      await dispatch(restartInstance(instanceId, null, {}, data));

      const updatedInstance = setStocksToInstance({ instance, params });

      dispatch(getInstanceSucceed(instanceId, {
        ...updatedInstance,
        lastUpdated: moment(),
      }));

      dispatch(updateInstanceWithoutSideEffect({ id: instanceId, updating: false }));

      dispatch(openDialog(
        PROGRESS_DIALOG_CODE,
        {
          state: IS_SUCCESS,
          title: 'Carteira alterada!',
          message: `Você alterou os ativos da carteira. A carteira irá realizar as operações o mais
          breve possível. Você poderá realizar modificações até lá.`,
          showButton: true,
        },
      ));
    } catch (error) {
      logger.log('error updating user profile');

      if (isLoginError(error)) {
        dispatch(notifyError({
          title: 'Erro!',
          message: INVALID_PASSWORD_TEXT,
        }));

        return Promise.reject(error);
      }

      dispatch(updateInstanceWithoutSideEffect({ id: instanceId, updating: false }));

      dispatch(openDialog(
        PROGRESS_DIALOG_CODE,
        {
          state: IS_ERROR,
          title: ERROR,
          message: `Não foi possível alterar a carteira. ${ERROR_DEFAULT_MSG}`,
          showButton: true,
        },
      ));

      return Promise.reject(error);
    }

    return Promise.resolve();
  };

  dispatch(openDialog(PASSWORD_CONFIRM_DIALOG_CODE, {
    open: true,
    onConfirmClick,
    onClose: () => dispatch(closeDialog()),
  }));
};

// Reducer
export default (state = INITIAL_STATE, action) => {
  // Logout cleaning
  if (action.type === LOGOUT_SUCCEED) {
    return INITIAL_STATE;
  }

  switch (action.type) {
    case GET_INSTANCE_STARTED:
      return {
        ...state,
        reloadInstance: true,
      };
    case GET_INSTANCE_SUCCEED:
      return {
        ...state,
        reloadInstance: false,
        ...action.data,
      };
    case GET_INSTANCE_FAILED:
      return {
        ...state,
        reloadInstance: false,
        reloadInstanceError: action.error,
      };
    case UPDATE_INSTANCE_STARTED:
      return {
        ...state,
        reloadInstance: true,
      };
    case UPDATE_INSTANCE_SUCCEED:
      return {
        ...state,
        reloadInstance: false,
        ...action.data,
        params: {
          ...state.params,
          ...action.data.params,
        },
      };
    case UPDATE_INSTANCE_RELATED_SUCCEED:
      return {
        ...state,
        reloadInstance: false,
      };
    case UPDATE_INSTANCE_FAILED:
      return {
        ...state,
        reloadInstance: false,
        reloadInstanceError: action.error,
      };
    case DELETE_INSTANCE_STARTED:
      return {
        ...state,
        reloadInstance: true,
      };
    case DELETE_INSTANCE_FAILED:
      return {
        ...state,
        reloadInstance: false,
        error: action.error,
      };
    case RELOAD_INSTANCE_STATE_STARTED:
      return {
        ...state,
        reloadingState: true,
      };

    case RELOAD_INSTANCE_STATE_SUCCEED:
      return {
        ...state,
        state: action.state,
        reloadingState: false,
        reloadStateError: null,
      };

    case RELOAD_INSTANCE_STATE_FAILED:
      return {
        ...state,
        reloadingState: false,
        reloadStateError: action.error,
      };

    case STOP_INSTANCE_STARTED:
      return {
        ...state,
        stopping: true,
      };

    case STOP_INSTANCE_SUCCEED:
      return {
        ...state,
        stopping: false,
        stopError: null,
      };

    case STOP_INSTANCE_FAILED:
      return {
        ...state,
        stopping: false,
        stopError: action.error,
      };

    case START_INSTANCE_STARTED:
      return {
        ...state,
        reloadingState: true,
        starting: true,
      };
    case START_INSTANCE_FAILED:
      return {
        ...state,
        reloadingState: false,
        starting: false,
        error: action.error,
      };
    case START_INSTANCE_SUCCEED:
      return {
        ...state,
        starting: false,
        reloadingState: false,
      };
    case UPDATE_INSTANCE_WITHOUT_SIDE_EFFECT:
      return {
        ...state,
        updating: action.updating,
      };

    case GET_INSTANCE_STATE_STARTED:
      return {
        ...state,
        reloadingState: true,
      };
    case GET_INSTANCE_STATE_SUCCEED:
      return {
        ...state,
        reloadingState: false,
        state: action.data,
      };
    case GET_INSTANCE_STATE_FAILED:
      return {
        ...state,
        reloadingState: false,
        reloadStateError: action.error,
      };
    case GET_INSTANCE_SETUP_STARTED:
      return {
        ...state,
        loading: true,
      };
    case GET_INSTANCE_SETUP_SUCCEED:
      return {
        ...state,
        loading: false,
        setup: action.data,
      };
    case GET_INSTANCE_SETUP_FAILED:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    default: return state;
  }
};
