import logdown from 'logdown';

const logger = logdown('utils/async');

export const sleep = (time) => new Promise((resolve) => setTimeout(resolve, time));

export const retryUntil = async (
  conditionFn,
  promiseFn,
  args = [],
  timeoutMillis = 1000,
  maxAttempts = 10,
) => {
  try {
    const result = await promiseFn(...args);
    return !!(conditionFn(result) || maxAttempts === 0);
  } catch (error) {
    logger.log('promiseFn error', error);
  }

  await sleep(timeoutMillis);

  return retryUntil(
    conditionFn,
    promiseFn,
    args,
    timeoutMillis,
    maxAttempts - 1,
  );
};

export const isFulfilledPromise = (status) => status === 'fulfilled';

export const checkAllPromisesAreRejecteds = (values) => {
  const hasFulfilledPromise = values.find((value) => (
    isFulfilledPromise(value.status)));

  return !hasFulfilledPromise;
};

export default null;
