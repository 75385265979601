import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { withStyles } from '@material-ui/styles';
import { success, error } from 'react-notification-system-redux';
import { createContact, listContact } from 'common/ducks/activeCampaign';

import { signUp, rateCheck, login } from 'common/ducks/auth';
import SignUp from './SignUp';

const styles = {
  form: {
    display: 'flex',
    flexDirection: 'column',
    maxWidth: 300,
  },
};

const mapDispatchToProps = (dispatch) => ({
  signUp: (data) => dispatch(signUp(data)),
  login: (data) => dispatch(login(data)),
  rateCheck: (data) => dispatch(rateCheck(data)),
  notifySuccess: (data) => dispatch(success(data)),
  notifyError: (data) => dispatch(error(data)),
  createContact: (data) => dispatch(createContact(data)),
  listContact: (id, list) => dispatch(listContact(id, list)),
});

const mapStateToProps = ({ auth: { logged, forced_external_logout } }) => ({
  logged,
  forcedExternalLogout: forced_external_logout,
});

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps,
)(withStyles(styles)(SignUp)));
