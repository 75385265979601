import React, { forwardRef, useEffect } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Formik } from 'formik';
import { Box, Button, FormControl } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';

import Link from 'common/components/Link';
import CurrencyField from 'common/components/Forms/CurrencyField';
import ButtonGroupField from 'common/components/ReduxForm/ButtonGroupField';
import { useValueRolloutDate } from 'common/hooks/storeItem';
import { SI_ZENDESK_ARTICLES_URL } from 'common/utils/constants';
import { PARAMS_MOUNT_INSTANCE } from 'common/utils/constants/instance';
import { TRANSACTION_NOW_MESSAGE } from 'common/utils/constants/investments';
import { WHITEBOX_STRATEGY_IDS } from 'common/utils/constants/whitebox';
import { currencyMask } from 'common/utils/inputMasks';
import { getMessageMountingPortfolio } from 'common/utils/storeItem';
import { maxLength, minValue, required } from 'common/utils/validation';

const NewInvestmentMessage = (
  <>
    <strong>IMPORTANTE:&nbsp;</strong>
    Disponibilize o valor na corretora além do valor reservado em saldo.&nbsp;
    <Link
      color="primary"
      fontWeight={700}
      path={`${SI_ZENDESK_ARTICLES_URL}/4419886030103`}
      target="_blank"
    >
      Saiba mais aqui
    </Link>
  </>
);

const NewWithDrawalMessage = (
  <>
    <strong>IMPORTANTE: </strong>
    O valor do resgate será aproximado dependendo das ações da
    carteira. Para garantir o valor desejado, resgate um valor de aproximadamente R$ 200,00
    a mais.
    <br />
    Não é possível resgatar valores que resulte em uma carteira com um patrimônio menor que
    50% do valor mínimo.
  </>
);

const InvestTransaction = forwardRef(({
  classes,
  isInvestment,
  instanceId,
  strategyId,
  dataSteps,
  setDataSteps,
  onSubmit,
}, ref) => {
  const storeInstance = useSelector((state) => state.storeItems.data.items[strategyId]);
  const report = useSelector((state) => state.report[instanceId].data);
  const { valueParam, setValueParam, paramsMountInstance } = useValueRolloutDate({
    strategyId,
    dataSteps,
  });
  const isWhiteboxInstance = WHITEBOX_STRATEGY_IDS.includes(storeInstance.strategy_id);
  const initialValues = {
    value: dataSteps.data?.value || '1.00',
    description: dataSteps.data?.description || null,
  };
  const isNextRollout = valueParam === PARAMS_MOUNT_INSTANCE.nextRollout.value;

  const handleChangeButtonGroup = (value) => {
    setValueParam(value);
  };

  useEffect(() => {
    setDataSteps((oldDataSteps) => ({
      ...oldDataSteps,
      data: {
        ...oldDataSteps.data,
        selectedDateOption: valueParam,
      },
    }));
  }, [valueParam, setDataSteps]);

  const getWithdrawLimitErrorMessage = (values) => {
    const insertedValue = parseFloat(values.value);
    const { equity } = report;
    const newEquityValue = equity - insertedValue;
    const minimumBalanceLeft = Number(storeInstance.extra_data.tags.minimum) / 2;
    return newEquityValue < minimumBalanceLeft
      ? `O valor máximo de retirada é ${currencyMask().format(equity - minimumBalanceLeft)}.`
      : null;
  };

  const transactionsValidation = (values) => {
    const errors = {};
    let errorMsg = required(values.value)
      || (!isInvestment && getWithdrawLimitErrorMessage(values));
    if (errorMsg) {
      errors.value = errorMsg;
    } else {
      const min = 1;
      errorMsg = minValue(min, true)(values.value);
      if (errorMsg) {
        errors.value = errorMsg;
      }
    }
    errorMsg = maxLength(50)(values.description);
    if (errorMsg) {
      errors.description = errorMsg;
    }
    return errors;
  };

  const renderForm = ({
    errors,
    values,
    touched,
    handleBlur,
    handleChange,
  }) => (
    <>
      <CurrencyField
        label="Valor (R$)"
        autoFocus
        meta={{
          errors,
          touched,
        }}
        values={values}
        handleChange={handleChange}
        handleBlur={handleBlur}
      />
      <Box my={2}>
        <FormControl fullWidth>
          <ButtonGroupField
            input={{
              value: dataSteps.data.selectedDateOption || valueParam,
              onChange: handleChangeButtonGroup,
            }}
            label="Quando você quer realizar a movimentação?"
          >
            {Object.values(paramsMountInstance).map((item) => (
              <Button
                id={item.id}
                className={classes.button}
                key={item.id}
                value={item.value}
              >
                {item.text}
              </Button>
            ))}
          </ButtonGroupField>
        </FormControl>

        <Box my={2}>
          <Typography
            data-testid="message-selected-button"
            color="textPrimary"
            variant="h6"
          >
            {isWhiteboxInstance && isNextRollout && (
              <>
                A carteira só irá realizar a movimentação após você rebalancear ou
                alterar a sua carteira teórica.
              </>
            )}
            {(!isWhiteboxInstance || !isNextRollout)
            && getMessageMountingPortfolio({
              storeInstance,
              selectedMountItem: valueParam,
              startFromZero: true,
              customMessageNowValue: TRANSACTION_NOW_MESSAGE,
            })}
          </Typography>
        </Box>
      </Box>

      <Typography className={classes.text}>
        {isInvestment ? NewInvestmentMessage : NewWithDrawalMessage}
      </Typography>
    </>
  );
  return (
    <>
      <Typography
        variant="h2"
        className={classNames(
          classes.title,
          isInvestment ? classes.investment : classes.withdrawal,
        )}
      >
        {isInvestment ? 'Novo Investimento' : 'Novo Resgate'}
      </Typography>
      <Formik
        ref={ref}
        onSubmit={onSubmit}
        validateOnBlur
        validate={transactionsValidation}
        initialValues={initialValues}
        render={renderForm}
      />
    </>
  );
});

InvestTransaction.propTypes = {
  classes: PropTypes.object.isRequired,
  isInvestment: PropTypes.bool.isRequired,
  instanceId: PropTypes.number.isRequired,
  strategyId: PropTypes.number.isRequired,
  dataSteps: PropTypes.object.isRequired,
  setDataSteps: PropTypes.func.isRequired,
  errors: PropTypes.object,
  values: PropTypes.object,
  touched: PropTypes.object,
  handleBlur: PropTypes.func,
  handleChange: PropTypes.func,
  onSubmit: PropTypes.func.isRequired,
};

InvestTransaction.defaultProps = {
  errors: {},
  values: {},
  touched: {},
  handleBlur: () => {},
  handleChange: () => {},
};

export const InvestTransactionComponent = InvestTransaction;
export default InvestTransaction;
