import { connect } from 'react-redux';
import { withStyles } from '@material-ui/styles';

import { getUniqueBrokerageId } from 'common/utils/brokerage';
import ChooseBrokerageForm from './ChooseBrokerageForm';

const styles = ((theme) => ({
  label: {
    fontWeight: 400,
  },
  instance: {
    fontWeight: 'bold',
  },
  fieldSpacingTitle: {
    margin: 0,
    [`@media(max-width: ${theme.breakpoints.values.sm}px)`]: {
      paddingTop: 15,
    },
  },
}));

const mapStateToProps = ({ storeItems, brokerages }, {
  allowedBrokerages, strategyId, initialValues, onSubmit,
}) => {
  const { data: { realUserBrokerages, brokerages: allBrokerages } } = brokerages;

  const uniqueBrokerageId = getUniqueBrokerageId({
    allowedBrokerages,
    realUserBrokerages,
    allBrokerages,
  });

  return {
    storeInstance: storeItems.data.items[strategyId] || {},
    brokerages: brokerages.data,
    initialValues: {
      brokerage: initialValues.data.brokerage || uniqueBrokerageId || '',
      name: initialValues.data.name || '',
    },
    onSubmit,
  };
};

export default connect(mapStateToProps)(withStyles(styles)(ChooseBrokerageForm));
