import { Box, Typography } from '@material-ui/core';
import { SI_ZENDESK_CATEGORY } from 'common/utils/constants';
import React from 'react';
import Link from '../Link';

const RebalanceStocksConfirmation = () => (
  <>
    <Box mb={2}>
      <Typography color="textSecondary" variant="h5">
        Você tem certeza que deseja realizar alterações na sua carteira?
      </Typography>
    </Box>

    <Box mb={2}>
      <Typography color="textSecondary" variant="h5">
        A carteira irá realizar as operações de compra e venda para atingir os pesos
        informados na próxima janela de negociação.
      </Typography>
    </Box>

    <Link
      color="primary"
      fontWeight={700}
      fontSize={16}
      path={SI_ZENDESK_CATEGORY}
      target="_blank"
      underlineDecoration
    >
      Saiba mais sobre alterações na sua carteira.
    </Link>
  </>
);

export default RebalanceStocksConfirmation;
