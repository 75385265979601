import { withStyles } from '@material-ui/styles';
import withMobileDialog from '@material-ui/core/withMobileDialog';
import palette from 'common/palette';
import ConfirmationDialog from './ConfirmationDialog';

export const CONFIRMATION_DIALOG_CODE = 'CONFIRMATION_DIALOG_CODE';

const styles = {
  title: {
    fontSize: 22,
    fontWeight: 700,
  },
  primaryButton: {
    minWidth: 115,
  },
  wrapperPrimaryButton: {
    marginLeft: 15,
  },
  dialogContent: {
    paddingTop: 0,
    paddingBottom: 0,
  },
  contentText: {
    marginBottom: 0,
  },
  relative: {
    position: 'relative',
  },
  backIcon: {
    color: palette.textSecondary.main,
    cursor: 'pointer',
    fontSize: 32,
    left: 25,
    position: 'absolute',
    top: '50%',
    transform: 'translateY(-35%)',
  },
};

export default withStyles(styles)(withMobileDialog()(ConfirmationDialog));
