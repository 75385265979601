import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import forOwn from 'lodash/forOwn';
import logdown from 'logdown';
import moment from 'moment-timezone';
import { LOGOUT_SUCCEED } from 'common/ducks/auth';
import api from 'common/api';
import { deepMerge } from 'common/utils/object';
import { STOPPED_STATES } from 'common/utils/constants';
import { getAlphaMetricsData } from 'common/utils/instance';
import {
  REPORT_RETURN_ATTRIBUTES,
  checkIsStoppedInstanceOrEmptyData,
  getReportInstanceIds,
  checkIsAllReportsLoaded,
  getReportTotalItem,
  getReportRunningInstances,
} from 'common/utils/report';
import { getStoreItemByInstanceId } from 'common/utils/storeItem';

export const DUCK_NAME = 'report';
const logger = logdown(DUCK_NAME);

export const INITIAL_STATE = {
  // -- Expected structure --
  // [the instance id]: {
  //   see INITIAL_REPORT_STATE for the content of the instance report object
  // }
  totalEquity: 0,
  totalProfitability: 0,
  isLoading: true,
};

export const INITIAL_REPORT_STATE = {
  loading: false,
  data: {},
  error: null,
  lastUpdateDatetime: null,
};

// CLEAR REPORT
export const CLEAR_REPORT_TOTAL_DATA = `${DUCK_NAME}/CLEAR_REPORT_TOTAL_DATA`;
export const clearReportTotalData = () => ({
  type: CLEAR_REPORT_TOTAL_DATA,
});

// CALCULATE REPORT
export const CALCULATE_REPORT_SUCCESS = `${DUCK_NAME}/CALCULATE_REPORT_SUCCESS`;
export const CALCULATE_REPORT_TOTAL_DATA = `${DUCK_NAME}/CALCULATE_REPORT_TOTAL_DATA`;

export const calculateReportSuccess = (instanceId, totalEquity, totalProfitability, isLoading) => ({
  type: CALCULATE_REPORT_SUCCESS,
  instanceId,
  totalEquity,
  totalProfitability,
  isLoading,
});

export const calculateReportTotalData = (instanceId, data, instanceState) => (
  dispatch, getState,
) => {
  const { instances, report } = getState();
  const action = { instanceState, data: { ...data } };

  const reportInstances = getReportInstanceIds(report);
  const runningInstancesReport = getReportRunningInstances({ report: reportInstances, instances });

  const isStoppedInstanceOrEmptyData = checkIsStoppedInstanceOrEmptyData(action);

  const totalEquity = isStoppedInstanceOrEmptyData
    ? report.totalEquity
    : getReportTotalItem(instanceId, runningInstancesReport, 'equity');
  const totalProfitability = isStoppedInstanceOrEmptyData
    ? report.totalProfitability
    : getReportTotalItem(instanceId, runningInstancesReport, 'profitability');

  const isLoading = !checkIsAllReportsLoaded({ report: runningInstancesReport });

  dispatch(calculateReportSuccess(instanceId, totalEquity, totalProfitability, isLoading));
  return Promise.resolve({ totalEquity, totalProfitability, isLoading });
};

// LOAD REPORT
export const LOAD_INSTANCE_REPORT_STARTED = `${DUCK_NAME}/LOAD_INSTANCE_REPORT_STARTED`;
export const LOAD_INSTANCE_REPORT_SUCCEED = `${DUCK_NAME}/LOAD_INSTANCE_REPORT_SUCCEED`;
export const LOAD_INSTANCE_REPORT_FAILED = `${DUCK_NAME}/LOAD_INSTANCE_REPORT_FAILED`;
export const loadInstanceReportStarted = (instanceId) => ({
  type: LOAD_INSTANCE_REPORT_STARTED,
  instanceId,
});
export const loadInstanceReportSucceed = (instanceId, data, instanceState) => ({
  type: LOAD_INSTANCE_REPORT_SUCCEED,
  instanceId,
  data,
  instanceState,
});
export const loadInstanceReportFailed = (instanceId, error) => ({
  type: LOAD_INSTANCE_REPORT_FAILED,
  instanceId,
  error,
});

export const loadInstanceReport = (
  instanceId,
  config = {},
  options = { reload: false },
  commit = true,
  variant = 'instances',
  isPublic = false,
) => async (dispatch, getState) => {
  const state = getState()[DUCK_NAME];
  const { storeItems, dailyCumulativePerformance } = getState();
  const prevStateReport = state[instanceId];

  const instanceState = get(getState().instances, `data.${instanceId}.state`);

  if (
    !options.reload
    && (
      (
        prevStateReport
        && !isEmpty(prevStateReport.data)
        && !prevStateReport.error
      ) || (
        prevStateReport
        && !isEmpty(prevStateReport.data)
        && STOPPED_STATES.includes(instanceState)
      ) || (
        prevStateReport
        && prevStateReport.loading
      )
    )
  ) {
    dispatch(calculateReportTotalData(instanceId, prevStateReport.data, instanceState));
    return Promise.resolve({ data: prevStateReport.data });
  }

  if (commit) {
    dispatch(loadInstanceReportStarted(instanceId));
  }

  try {
    let report;

    if (isPublic) {
      const currentStoreItem = getStoreItemByInstanceId(
        instanceId,
        Object.values(storeItems.data.itemsWithPrivate),
      );

      report = currentStoreItem.instance.report;
    } else {
      const { data } = await api.strategies.instances.getInstanceReport(
        instanceId,
        deepMerge({}, config, {
          params: {
            return_attributes: REPORT_RETURN_ATTRIBUTES,
          },
        }),
        variant,
      );

      report = data;
    }

    report.percentual_net_return = report.net_return;

    report.net_return = report.percentual_net_return * report.initial_capital;

    report.profitability = Number(report.equity) + Number(report.total_withdraws)
    - Number(report.initial_capital) - Number(report.total_contributions);

    if (options.shouldAddReportMetrics) {
      const instance = getStoreItemByInstanceId(instanceId, Object.values(storeItems.data.items));
      const {
        formattedLaunchDate: instance_launch_date,
        currentAnualAlphaValue: current_year_alpha_profitability,
        currentPostLaunchAlphaValue: accumulated_alpha_profitability,
      } = getAlphaMetricsData({
        instance,
        dailyCumulativePerformance,
      });

      report = {
        ...report,
        instance_launch_date,
        accumulated_alpha_profitability,
        current_year_alpha_profitability,
      };
    }

    forOwn(report, (value, key) => {
      report[key] = Number.parseFloat(value);
      if (Number.isNaN(report[key])
          || report[key] >= 9999999999
          || report[key] <= -9999999999
      ) {
        report[key] = 0;
      }
    });

    if (commit) {
      dispatch(loadInstanceReportSucceed(
        instanceId,
        report,
        instanceState,
      ));
      dispatch(calculateReportTotalData(instanceId, report, instanceState));
    }
    return Promise.resolve({ data: report });
  } catch (error) {
    if (commit) {
      dispatch(loadInstanceReportFailed(
        instanceId,
        error,
      ));
    }

    logger.error(`Failed to get instance #${instanceId} report`);
    return Promise.reject(error);
  }
};

export const instanceReportLoad = (
  instanceId,
  state = INITIAL_REPORT_STATE,
  action,
) => {
  const { type, error, data } = action;

  if (instanceId !== action.instanceId) {
    return state;
  }

  switch (type) {
    case LOAD_INSTANCE_REPORT_STARTED:
      return {
        ...state,
        loading: true,
      };
    case LOAD_INSTANCE_REPORT_SUCCEED:
      return {
        ...state,
        data,
        lastUpdateDatetime: moment().toISOString(),
        loading: false,
        error: null,
      };
    case LOAD_INSTANCE_REPORT_FAILED:
      return {
        ...state,
        error,
        loading: false,
      };
    default: return state;
  }
};

// UPDATE REPORT
export const UPDATE_INSTANCE_REPORT_SUCCESS = `${DUCK_NAME}/UPDATE_INSTANCE_REPORT_SUCCESS`;
export const UPDATE_INSTANCE_REPORT_FAILED = `${DUCK_NAME}/UPDATE_INSTANCE_REPORT_FAILED`;

export const updateInstanceReportSucceed = (instanceId, data) => ({
  type: UPDATE_INSTANCE_REPORT_SUCCESS,
  instanceId,
  data,
});
export const updateInstanceReportFailed = (instanceId, error) => ({
  type: UPDATE_INSTANCE_REPORT_FAILED,
  instanceId,
  error,
});

export const updateInstanceReport = (instanceId) => async (dispatch, getState) => {
  const { storeItems, dailyCumulativePerformance } = getState();

  try {
    const instance = getStoreItemByInstanceId(instanceId, Object.values(storeItems.data.items));
    const {
      formattedLaunchDate,
      currentAnualAlphaValue,
      currentPostLaunchAlphaValue,
    } = getAlphaMetricsData({
      instance,
      dailyCumulativePerformance,
    });

    const data = {
      instance_launch_date: formattedLaunchDate,
      accumulated_alpha_profitability: currentPostLaunchAlphaValue,
      current_year_alpha_profitability: currentAnualAlphaValue,
    };

    return dispatch(updateInstanceReportSucceed(
      instanceId,
      data,
    ));
  } catch (error) {
    dispatch(updateInstanceReportFailed(
      instanceId,
      error,
    ));

    logger.error(`Failed to update instance #${instanceId} report`);
    return Promise.reject(error);
  }
};

export const instanceReportUpdate = (
  instanceId,
  state = INITIAL_REPORT_STATE,
  action,
) => {
  const { data, error, type } = action;

  if (instanceId !== action.instanceId) {
    return state;
  }

  switch (type) {
    case UPDATE_INSTANCE_REPORT_SUCCESS:
      return {
        ...state,
        data: {
          ...state.data,
          ...data,
        },
        lastUpdateDatetime: moment().toISOString(),
        error: null,
      };
    case UPDATE_INSTANCE_REPORT_FAILED:
      return {
        ...state,
        error,
      };
    default: return state;
  }
};

// Reducer
export default (state = INITIAL_STATE, action) => {
  const { type, instanceId } = action;

  // Logout cleaning
  if (action.type === LOGOUT_SUCCEED) {
    return INITIAL_STATE;
  }

  switch (type) {
    case LOAD_INSTANCE_REPORT_STARTED:
    case LOAD_INSTANCE_REPORT_SUCCEED:
    case LOAD_INSTANCE_REPORT_FAILED:
      return {
        ...state,
        [instanceId]: instanceReportLoad(instanceId, state[instanceId], action),
      };
    case UPDATE_INSTANCE_REPORT_SUCCESS:
    case UPDATE_INSTANCE_REPORT_FAILED:
      return {
        ...state,
        [instanceId]: instanceReportUpdate(instanceId, state[instanceId], action),
      };
    case CLEAR_REPORT_TOTAL_DATA:
      return {
        ...state,
        isLoading: false,
        totalEquity: 0,
        totalProfitability: 0,
      };
    case CALCULATE_REPORT_SUCCESS:
      return {
        ...state,
        isLoading: action.isLoading,
        totalEquity: action.totalEquity,
        totalProfitability: action.totalProfitability,
      };

    default: return state;
  }
};
