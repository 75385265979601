import React from 'react';
import PropTypes from 'prop-types';

import ManualOperation, {
  MANUAL_OPERATION_SIDE_DIALOG,
} from './ManualOperation';

import InvestorProfile, {
  INVESTOR_PROFILE_SIDE_DIALOG,
} from './InvestorProfile';

const SideDialogs = ({
  currentSideDialogOpened,
  sideDialogProps,
  onSideDialogClose,
}) => {
  const sideDialogs = {
    [MANUAL_OPERATION_SIDE_DIALOG]: ManualOperation,
    [INVESTOR_PROFILE_SIDE_DIALOG]: InvestorProfile,
  };

  return Object.keys(sideDialogs).map((key) => {
    const Component = sideDialogs[key];

    return (
      <Component
        key={key}
        onSideDialogClose={onSideDialogClose}
        open={key === currentSideDialogOpened}
        {...sideDialogProps}
      />
    );
  });
};

SideDialogs.propTypes = {
  currentSideDialogOpened: PropTypes.string,
  sideDialogProps: PropTypes.object.isRequired,
  onSideDialogClose: PropTypes.func.isRequired,
};

SideDialogs.defaultProps = {
  currentSideDialogOpened: '',
};

export default SideDialogs;
