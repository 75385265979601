import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import { withRouter } from 'react-router-dom';

import palette from 'common/palette';
import UserMenuItem from './UserMenuItem';

const styles = (theme) => ({
  listItemIcon: {
    marginRight: 0,
    color: palette.darkPrimary.main,
  },
  menuItem: {
    [`@media (max-width: ${theme.breakpoints.values.md}px)`]: {
      padding: '10px 16px',
    },
  },
  userNameText: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    marginTop: 8,
    fontFamily: 'Roboto',
    color: '#063535',
    fontWeight: 500,
    fontSize: 14,
  },
  secondaryUserNameText: {
    fontFamily: 'Roboto',
    color: palette.textSecondary,
    fontSize: 13,
  },
});

const mapStateToProps = ({ user }) => ({ profile: user.profile });

export default
withRouter(connect(mapStateToProps)(withStyles(styles)(UserMenuItem)));
