import { LOGOUT_SUCCEED } from 'common/ducks/auth';

export const INITIAL_STATE = {
  isPreventChangeRoute: false,
};

export const DUCK_NAME = 'route';
export const NAMESPACE = `user/${DUCK_NAME}`;

export const SET_IS_PREVENT_CHANGE_ROUTE = `${NAMESPACE}/SET_IS_PREVENT_CHANGE_ROUTE`;

export const setIsPreventChangeRoute = (value) => ({
  type: SET_IS_PREVENT_CHANGE_ROUTE,
  value,
});

export default (state = INITIAL_STATE, action) => {
  if (action.type === LOGOUT_SUCCEED) {
    return INITIAL_STATE;
  }
  switch (action.type) {
    case SET_IS_PREVENT_CHANGE_ROUTE:
      return {
        ...state,
        isPreventChangeRoute: action.value,
      };
    default:
      return state;
  }
};
