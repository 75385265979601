import React from 'react';
import PropTypes from 'prop-types';
import { Typography, Box } from '@material-ui/core';
import { BACK_TEXT } from 'common/utils/constants';
import BackButton from '../Buttons/BackButton';

const InternalPageHeader = ({
  backgroundUrl,
  classes,
  icon,
  pathUrlBackButton,
  subtitle,
  title,
  showBackButton,
}) => {
  return (
    <div
      style={{ background: `url(${backgroundUrl}) center no-repeat` }}
      className={classes.header}
    >
      <div className={classes.headerContent} style={{ justifyContent: `${showBackButton ? 'space-between' : 'center'}` }}>
        {showBackButton && (
          <BackButton
            color="white"
            fontSize={16}
            path={pathUrlBackButton}
            text={BACK_TEXT}
          />
        )}

        <div>
          {icon && (
            <Box data-testid="icon" mb={1}>{icon}</Box>
          )}
          <Typography
            id="title"
            className={classes.title}
            variant="h2"
          >
            {title}
          </Typography>
          {subtitle && (
            <Box mt={1.2}>
              <Typography
                id="subtitle"
                variant="h6"
              >
                {subtitle}
              </Typography>
            </Box>
          )}
        </div>
      </div>
    </div>
  );
};

InternalPageHeader.propTypes = {
  backgroundUrl: PropTypes.string.isRequired,
  classes: PropTypes.object.isRequired,
  icon: PropTypes.node,
  pathUrlBackButton: PropTypes.string.isRequired,
  subtitle: PropTypes.string,
  title: PropTypes.string.isRequired,
  showBackButton: PropTypes.bool,
};

InternalPageHeader.defaultProps = {
  subtitle: '',
  icon: null,
  showBackButton: true,
};

export default InternalPageHeader;
