import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import { withRouter } from 'react-router-dom';
import palette from 'common/palette';

import NavigationPages from './NavigationPages';

const styles = (theme) => ({
  appFrame: {
    display: 'flex',
    width: '100%',
    minHeight: '100vh',
  },
  backdrop: {
    zIndex: 1,
  },
  content: {
    overflowY: 'auto',
    overflowX: 'hidden',
    width: '100%',
    flexGrow: 1,
    minHeight: '100vh',
    backgroundColor: '#F5F5F5',
    [`@media (min-width: calc(${theme.breakpoints.values.md}px + 1px))`]: {
      marginLeft: '65px',
      width: 'calc(100% - 65px)',
    },
  },
  helpButtonDesk: {
    fontSize: '18px',
    maxWidth: '108px',
    display: 'none',
    [`@media (min-width: calc(${theme.breakpoints.values.md}px + 1px))`]: {
      display: 'block',
      bottom: 0,
      marginBottom: '10px',
      position: 'fixed',
      right: '15px',
    },
  },
  sideBarSmarttBotIcon: {
    marginBottom: -3,
    marginRight: -1,
    width: 16,
    height: 16,
  },
  sidebarItemIcon: {
    width: 16,
    height: 16,
  },
  pageHeaderSmarttBotIcon: {
    width: '1.2em',
    height: '1.2em',
    marginBottom: '-0.2em',
  },
  sideBarChartIcon: {
    marginBottom: -3,
    marginRight: -1,
    width: 20,
    height: 20,
  },
  pageHeaderChartIcon: {
    width: '1.2em',
    height: '1.2em',
    marginBottom: '-0.2em',
  },
  primaryColor: {
    color: palette.primary.main,
  },
  link: {
    color: palette.primary.main,
    textDecoration: 'none',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
});

const mapStateToProps = ({ auth, user: { stats } }) => ({
  forcedExternalLogout: auth.forced_external_logout,
  stats: stats.data,
});

export default withRouter(connect(
  mapStateToProps,
)(withStyles(styles)(NavigationPages)));
