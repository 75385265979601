import { connect } from 'react-redux';
import { error as notifierError } from 'react-notification-system-redux';
import { withStyles } from '@material-ui/styles';
import { checkValidateStockCode } from 'common/ducks/stocks';
import FillPositionsForm from './FillPositionsForm';

const baseMaxWidth = '580px';

const styles = {
  addCostIcon: {
    marginRight: 10,
  },
  closeIcon: {
    flexBasis: 'auto',
    [`@media(max-width: ${baseMaxWidth})`]: {
      position: 'absolute',
      right: 0,
      top: 0,
    },
  },
  formControl: {
    width: '100%',
  },
  input: {
    marginTop: 5,
    [`@media(max-width: ${baseMaxWidth})`]: {
      flex: '1 0 calc(50% - 15px)',
      '&:nth-child(odd)': {
        marginRight: 15,
      },
    },

    [`@media(min-width: calc(${baseMaxWidth} + 1px))`]: {
      '&:not(:last-of-type)': {
        marginRight: 15,
      },
    },
  },
  inputColor: {
    color: '#b4b6b8',
  },
  selectInput: {
    minWidth: 100,
  },
  label: {
    fontSize: 16,
    '& .tooltipIcon': {
      display: 'none',
    },
    '&.Mui-focused .tooltipIcon': {
      display: 'inline-block',
    },
    '&.MuiFormLabel-filled .tooltipIcon': {
      display: 'inline-block',
    },
  },
  labelTooltipFixed: {
    '& .tooltipIcon': {
      display: 'inline-block',
    },
  },
  inputLabel: {
    top: 4,
    fontWeight: 500,
  },
  positionBox: {
    display: 'flex',
    alignItems: 'baseline',
    position: 'relative',
    [`@media(max-width: ${baseMaxWidth})`]: {
      flexWrap: 'wrap',
      marginBottom: 15,
    },
  },
  wrapper: {
    maxHeight: 300,
    overflow: 'auto',
  },
  paper: {
    boxShadow: 'none',
  },
  rootAdornment: {
    '& .MuiTypography-root': {
      fontSize: 16,
    },
  },
};

const mapStateToProps = (({ stocks, storeItems, positions }, { instanceId, strategyId }) => ({
  validStocks: stocks.validStocks,
  storeItemStocks: storeItems?.data?.items[strategyId]?.stocks || {},
  instancePositions: positions[instanceId]?.data.mergedPositions || {},
}));

const mapDispatchToProps = (dispatch) => ({
  notifyError: (data) => dispatch(notifierError(data)),
  checkValidateStockCode: (stockCode) => dispatch(checkValidateStockCode(stockCode)),
});

export default connect(mapStateToProps, mapDispatchToProps)(
  withStyles(styles)(FillPositionsForm),
);
