import { withStyles } from '@material-ui/styles';
import StockLogoFinder from './StockLogoFinder';

const styles = {
  logo: {
    height: 35,
    width: 35,
  },
};

export default withStyles(styles)(StockLogoFinder);
