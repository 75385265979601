import React from 'react';
import PropTypes from 'prop-types';
import { Typography } from '@material-ui/core';

const SelectedItemsCounter = ({
  color,
  selectedItems,
  maxItems,
}) => {
  return (
    <Typography color={color} data-testid="selected-items" variant="h6">
      {selectedItems}
      /
      {maxItems}
    </Typography>
  );
};

SelectedItemsCounter.propTypes = {
  color: PropTypes.oneOf(['textPrimary', 'textSecondary']),
  selectedItems: PropTypes.number.isRequired,
  maxItems: PropTypes.number.isRequired,
};

SelectedItemsCounter.defaultProps = {
  color: 'textPrimary',
};

export default SelectedItemsCounter;
