import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  TextField,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@material-ui/core';
import { fieldRestrictions, maxLength } from 'common/utils/validation';
import CloseDialogButton from 'common/components/Buttons/CloseDialogButton';

export const CREATE_TEMPLATE_DIALOG_CODE = 'CREATE_WALLET_DIALOG_CODE';

const CreateTemplateDialog = ({
  classes,
  open,
  onClose,
  createSimulatedInstance,
  history,
}) => {
  const [name, setName] = useState('');

  const handleInputChange = (event) => {
    setName(event.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    createSimulatedInstance(name, history);
  };

  const errorText = fieldRestrictions(name) || maxLength(30)(name);

  return (
    <Dialog
      open={open}
      onClose={onClose}
      className={classes.dialog}
      fullWidth
    >
      <CloseDialogButton onClose={onClose} />
      <DialogTitle disableTypography className={classes.dialogTitle}>
        Criar novo template
      </DialogTitle>
      <DialogContent className={classes.dialogContent}>
        <form
          onSubmit={handleSubmit}
        >
          <TextField
            fullWidth
            autoFocus
            id="create-custom-wallet-name"
            type="text"
            label="Nome da carteira"
            value={name}
            helperText={errorText}
            error={Boolean(errorText)}
            required
            onChange={(event) => handleInputChange(event)}
          />
        </form>
      </DialogContent>
      <DialogActions>
        <Button
          id="password-confirm-dialog-confirm-click"
          className={classes.button}
          onClick={handleSubmit}
          color="primary"
          variant="contained"
          autoFocus
          disabled={!name.length || Boolean(errorText)}
        >
          Criar
        </Button>
      </DialogActions>
    </Dialog>
  );
};

CreateTemplateDialog.propTypes = {
  classes: PropTypes.object.isRequired,
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  createSimulatedInstance: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
};

export default CreateTemplateDialog;
