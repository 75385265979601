import React from 'react';
import PropTypes from 'prop-types';
import logdown from 'logdown';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Typography from '@material-ui/core/Typography';
import { IconButton } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import {
  IS_SUCCESS,
  RUNNING_STATES,
  SELLING_SUCCESS_MSG,
  SELLING_SUCCESS_TITLE,
} from 'common/utils/constants';

export const SELL_ALL_DIALOG_CODE = 'SELL_ALL_DIALOG_CODE';

const logger = logdown('StopInstanceDialog');

const SellAllDialog = ({
  instance,
  openPasswordConfirmDialog,
  openProgressDialog,
  stopInstance,
  eliminateInstancePositions,
  classes,
  onClose,
  fullScreen,
  stockCodesAndNumber,
}) => {
  const isInstanceRunning = RUNNING_STATES.includes(instance.state);

  const renderIconButton = (
    <IconButton
      size="small"
      className={classes.closeIcon}
      edge="start"
      color="inherit"
      onClick={onClose}
      aria-label="close"
    >
      <CloseIcon />
    </IconButton>
  );
  const renderCloseButton = (
    <Button
      onClick={onClose}
    >
      Cancelar
    </Button>
  );

  const eliminatePositionsAction = async (willStopInstance, data) => {
    try {
      if (willStopInstance) {
        await stopInstance({ eliminatePositions: true }, data);
      } else {
        await eliminateInstancePositions(
          instance.id,
          { ...data, is_position: true },
          { resetAllPositions: true },
        );
      }

      openProgressDialog({
        state: IS_SUCCESS,
        title: SELLING_SUCCESS_TITLE,
        message: SELLING_SUCCESS_MSG,
        showButton: true,
        onClose,
      });
    } catch (error) {
      Promise.reject();
      logger.error(
        `(onEliminatePositionsButtonClick) Failed to stop instance #${instance.id}`, error,
      );
    }
  };

  const onEliminatePositionsButtonClick = async (willStopInstance) => {
    const data = {};

    const onCancelClick = () => {
      onClose();
    };

    const onConfirmClick = async (password) => {
      data.password = password;
      await eliminatePositionsAction(willStopInstance, data);

      return Promise.resolve();
    };

    openPasswordConfirmDialog({
      open: true,
      onConfirmClick,
      onCancelClick,
      onClose,
    });
  };

  return (
    <Dialog
      open
      onClose={onClose}
      maxWidth="sm"
    >
      <DialogContent>
        {fullScreen ? renderIconButton : null}
        <Typography variant="title" className={classes.title}>
          Vender Tudo
        </Typography>
        <Typography className={classes.text}>
          A Carteira está posicionada em:
          {stockCodesAndNumber.map((item) => (
            <div className={classes.list}>
              &bull;&nbsp;
              {item.number}
              &nbsp;
              {item.code}
            </div>
          ))}
          Tem certeza que deseja encerrar todas estas posições?
        </Typography>
        <Typography className={classes.text}>
          <b>Atenção: </b>
          serão enviadas ordens para a sua corretora.
          Caso tenha encerrado esta posição por outra plataforma cancele essa ação e clique em
          &quot;+ Adicionar Ordem&quot; acima da Lista de ordens.
        </Typography>
        <DialogActions className={classes.buttons}>
          {!fullScreen ? renderCloseButton : null}
          <Button
            id="confirm-button"
            variant="contained"
            color="secondary"
            onClick={() => onEliminatePositionsButtonClick(isInstanceRunning)}
          >
            Confirmar
          </Button>
        </DialogActions>
      </DialogContent>
    </Dialog>
  );
};

SellAllDialog.propTypes = {
  classes: PropTypes.object.isRequired,
  instance: PropTypes.object.isRequired,
  openPasswordConfirmDialog: PropTypes.func.isRequired,
  openProgressDialog: PropTypes.func.isRequired,
  stopInstance: PropTypes.func.isRequired,
  eliminateInstancePositions: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  fullScreen: PropTypes.bool.isRequired,
  stockCodesAndNumber: PropTypes.array,
};

SellAllDialog.defaultProps = {
  stockCodesAndNumber: [],
};

export default SellAllDialog;
