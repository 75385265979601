import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import FormHelperText from '@material-ui/core/FormHelperText';
import FormLabel from '@material-ui/core/FormLabel';

import ButtonGroup from 'common/components/Buttons/ButtonGroup';

const ButtonGroupField = ({
  classes,
  input,
  meta: { touched, error },
  children,
  className,
  label,
}) => {
  return (
    <div className={classes.wrapper}>
      <FormLabel
        error={touched && Boolean(error)}
        className={classes.label}
      >
        {label}
      </FormLabel>
      <ButtonGroup input={input} className={className}>
        {
          React.Children.map(
            children,
            (child) => React.cloneElement(child, {
              disabled: input.disabled,
              className: classNames(child.props.className, classes.button),
              onClick: () => input.onChange(child.props.value),
              color: input.color,
              variant: input.value && input.value === child.props.value ? 'contained' : 'outlined',
            }),
          )
        }
      </ButtonGroup>
      {
        touched && Boolean(error) ? (
          <FormHelperText
            error={Boolean(error)}
          >
            {error}
          </FormHelperText>
        ) : null
      }
    </div>
  );
};

ButtonGroupField.propTypes = {
  classes: PropTypes.object.isRequired,
  children: PropTypes.node.isRequired,
  input: PropTypes.object,
  meta: PropTypes.object,
  className: PropTypes.string,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
};

ButtonGroupField.defaultProps = {
  input: {},
  meta: {},
  className: '',
  label: '',
};

export default ButtonGroupField;
