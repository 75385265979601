import { withStyles } from '@material-ui/core';
import { connect } from 'react-redux';

import { loadInstanceReport } from 'common/ducks/report';
import PlanSubscriptionDialog from './PlanSubscriptionDialog';

const styles = {
  content: {
    paddingInline: '3.6rem',
  },
  title: {
    display: 'flex',
    justifyContent: 'center',
    marginBottom: '1rem',
  },
  subtitle: {
    display: 'flex',
    justifyContent: 'center',
    fontSize: 14,
    color: '#747c88',
    padding: '5px 0 20px 0',
  },
  text: {
    fontSize: 14,
    color: '#747c88',
    paddingBlock: 20,
    textAlign: 'center',
  },
  link: {
    textDecoration: 'underline',
    color: '#747c88',
  },
};

const mapStateToProps = ({ user, storeItems, instances }) => ({
  subscriptions: user.subscriptions.data || [],
  storeItems: storeItems.data.itemsWithPrivate,
  instances: instances.data,
});

const mapDispatchToProps = (dispatch) => ({
  loadInstanceReport: (id) => dispatch(loadInstanceReport(id)),
});

export const PLAN_SUBSCRIPTION_DIALOG_CODE = 'PLAN_SUBSCRIPTION_DIALOG_CODE';
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withStyles(styles)(PlanSubscriptionDialog));
