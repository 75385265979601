import moment from 'moment';
import logdown from 'logdown';
import {
  error as notifierError,
  success as notifierSuccess,
} from 'react-notification-system-redux';

import get from 'lodash/get';
import size from 'lodash/size';
import orderBy from 'lodash/orderBy';

import api from 'common/api';
import ssErrorInterpreter from 'common/interceptor/error/ss';
import { LOGOUT_SUCCEED } from '../auth';
import { DEFAULT_PREFERENCES } from '../../utils/constants';

export const INITIAL_STATE = { loading: false, data: null, error: null };

export const DUCK_NAME = 'profile';
export const NAMESPACE = `user/${DUCK_NAME}`;
const logger = logdown(NAMESPACE);

// Actions
export const LOAD_USER_PROFILE_STARTED = `${NAMESPACE}/LOAD_USER_PROFILE_STARTED`;
export const LOAD_USER_PROFILE_SUCCEED = `${NAMESPACE}/LOAD_USER_PROFILE_SUCCEED`;
export const LOAD_USER_PROFILE_FAILED = `${NAMESPACE}/LOAD_USER_PROFILE_FAILED`;
export const SUBTRACT_BACKTESTS_QUANTITY = `${NAMESPACE}/SUBTRACT_BACKTESTS_QUANTITY`;

// Action creators
export const loadUserProfileStarted = () => ({
  type: LOAD_USER_PROFILE_STARTED,
});
export const loadUserProfileSucceed = (data) => ({
  data,
  type: LOAD_USER_PROFILE_SUCCEED,
});
export const loadUserProfileFailed = (error) => ({
  error,
  type: LOAD_USER_PROFILE_FAILED,
});

const zendeskIdentifyUser = (profile) => ((window.zE && window.zE.identify) ? window.zE.identify({
  email: profile.email,
  name: profile.name_or_corporate_name,
}) : null);

export const loadUserProfile = (options = { reload: false }) => async (dispatch, getState) => {
  const profile = get(getState(), `user.${DUCK_NAME}`, null);
  if (
    !options.reload
    && profile
    && size(profile.data)
    && !profile.loading
    && !profile.error
  ) {
    zendeskIdentifyUser(profile.data);
    logger.log('Using loaded profile', profile);
    return Promise.resolve(profile.data);
  }

  dispatch(loadUserProfileStarted());

  let userPlan = null;
  let userProfile = null;
  let userSubscription = null;

  try {
    ({ data: userProfile } = await api.user.getUserProfile());

    const subscriptions = get(getState(), 'user.subscriptions.data', []);

    // Returned user profile plan code is just the raw plan name
    // so we should test if, for example, lite_annual includes lite
    userSubscription = orderBy(subscriptions, 'expires_at', 'desc')
      .find((s) => s.plan_code.includes(userProfile.plan)
        && s.status === 'ACTIVE'
        && s.type === 'PLAN') || null;

    if (userSubscription) {
      ({ data: userPlan } = await api.plans.getPlan(userSubscription.plan_code));
    } else {
      ({ data: userPlan } = await api.plans.getPlan(userProfile.plan));
    }
  } catch (error) {
    logger.error('Could not get user profile.', error);
    dispatch(loadUserProfileFailed(error));
    return Promise.reject(error);
  }

  userProfile.plan = userPlan;
  userProfile.plan.subscription = userSubscription;
  userProfile.preferences = {
    ...DEFAULT_PREFERENCES,
    ...userProfile.preferences,
  };
  zendeskIdentifyUser(userProfile);

  dispatch(loadUserProfileSucceed(userProfile));
  return Promise.resolve(userProfile);
};

export const UPDATE_USER_PROFILE_STARTED = `${NAMESPACE}/UPDATE_USER_PROFILE_STARTED`;
export const UPDATE_USER_PROFILE_SUCCEED = `${NAMESPACE}/UPDATE_USER_PROFILE_SUCCEED`;
export const UPDATE_USER_PROFILE_FAILED = `${NAMESPACE}/UPDATE_USER_PROFILE_FAILED`;

export const updateUserProfileStarted = () => ({
  type: UPDATE_USER_PROFILE_STARTED,
});

export const updateUserProfileSucceed = (data) => ({
  data,
  type: UPDATE_USER_PROFILE_SUCCEED,
});

export const updateUserProfileFailed = (error) => ({
  error,
  type: UPDATE_USER_PROFILE_FAILED,
});

export const updateUserProfile = (data) => async (dispatch) => {
  dispatch(updateUserProfileStarted());
  try {
    const result = await api.user.updateUserProfile(data);

    logger.info('update profile', result);
    dispatch(updateUserProfileSucceed(data));

    dispatch(notifierSuccess({
      title: 'Sucesso!',
      message: 'Perfil alterado com sucesso.',
    }));

    return Promise.resolve(data);
  } catch (error) {
    logger.error('Failed to update profile.', error);
    dispatch(updateUserProfileFailed(error));
    dispatch(notifierError({
      title: 'Erro!',
      message:
        ssErrorInterpreter(error.code)
        || 'Houve um erro ao atualizar seu perfil. Tente novamente.',
    }));

    return Promise.reject(error);
  }
};

export const updateUserExtraData = (data) => async (dispatch, getState) => {
  const profile = getState().user[DUCK_NAME];
  dispatch(updateUserProfileStarted());

  try {
    const result = await api.user.updateUserExtraData({
      extra_data: {
        ...data,
      },
    });
    logger.info('extra data updated', result);
    dispatch(updateUserProfileSucceed({
      extra_data: {
        ...get(profile, 'data.extra_data', {}),
        ...data,
      },
    }));
    return Promise.resolve({ extra_data: data });
  } catch (error) {
    logger.error('Failed to update extra data.', error);
    dispatch(updateUserProfileFailed(error));
    return Promise.reject(error);
  }
};

export const subtractBacktestsQuantity = () => ({
  type: SUBTRACT_BACKTESTS_QUANTITY,
});

// Reducer
export default (state = INITIAL_STATE, action) => {
  if (action.type === LOGOUT_SUCCEED) {
    return INITIAL_STATE;
  }

  const data = {
    ...state.data,
    ...action.data,
  };

  data.birthday = data.birthday ? moment(data.birthday, ['DD-MM-YYYY', 'YYYY-MM-DD']).format('DD/MM/YYYY') : data.birthday;

  delete data.s10i_password;

  switch (action.type) {
    case LOAD_USER_PROFILE_STARTED:
      return {
        ...state,
        loading: true,
      };
    case LOAD_USER_PROFILE_SUCCEED:
      return {
        ...state,
        loading: false,
        error: null,
        data,
      };
    case LOAD_USER_PROFILE_FAILED:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    case UPDATE_USER_PROFILE_STARTED:
      return {
        ...state,
        loading: true,
      };
    case UPDATE_USER_PROFILE_SUCCEED:
      return {
        ...state,
        data,
        loading: false,
        error: null,
      };
    case UPDATE_USER_PROFILE_FAILED:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    case SUBTRACT_BACKTESTS_QUANTITY:
      return {
        ...state,
        data: {
          ...state.data,
          backtests_quantity: state.data.backtests_quantity - 1,
        },
      };
    default:
      return state;
  }
};
