import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import {
  Box,
  Typography,
  Grow,
  Paper,
} from '@material-ui/core';

const HiddenList = ({
  classes,
  children,
  initialShow,
  openText,
  closeText,
}) => {
  const [showList, setShowList] = useState(initialShow);
  return (
    <Box>
      <Box display="flex" justifyContent="center" mb={2}>
        <Typography
          color="textSecondary"
          variant="h6"
          onClick={() => setShowList((state) => !state)}
          className={classes.textShowHideStocks}
        >
          {!showList ? openText : closeText}
          <div className={classNames(
            classes.arrow,
            showList && classes.arrowActive,
          )}
          >
          </div>
        </Typography>
      </Box>

      <Grow
        in={showList}
        timeout={200}
      >
        <Paper className={classes.paper} elevation={4}>
          {showList && children}
        </Paper>
      </Grow>
    </Box>
  );
};

HiddenList.propTypes = {
  classes: PropTypes.object.isRequired,
  children: PropTypes.any.isRequired,
  initialShow: PropTypes.bool.isRequired,
  openText: PropTypes.string,
  closeText: PropTypes.string,
};

HiddenList.defaultProps = {
  openText: '',
  closeText: '',
};

export default HiddenList;
