import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import {
  Box,
  Card,
  Chip,
  Typography,
} from '@material-ui/core';
import {
  SHOW_CHANGES,
  SI_ZENDESK_ARTICLES_URL,
  STORE_VISIBILITY,
  SUBSCRIBERS_SEE_STOCKS_MESSAGE,
  TABLET_WIDTH_BREAKPOINT,
} from 'common/utils/constants';
import { PORTFOLIO_COMPOSITION_TABS } from 'common/utils/constants/tabs';
import { getTotalItems } from 'common/utils/array';
import { getYTextCenter } from 'common/utils/chart';
import { getCurrentScreen } from 'common/utils/screen';
import { useSelector } from 'react-redux';
import { SMALL } from 'common/utils/constants/size';
import Tabs from '../Tabs';
import DonutsChart from '../Graphics/DonutsChart';
import DetailedChartLegend from '../Graphics/DetailedChartLegend';
import HiddenList from '../HiddenList';
import EmptyChartDefault from '../Images/EmptyChartDefault';
import ShowcaseInstanceButton from '../Buttons/ShowcaseInstanceButton';
import Link from '../Link';

const { currentWallet } = PORTFOLIO_COMPOSITION_TABS;

const StocksView = ({
  classes,
  strategyId,
  isInstanceSettings,
  openStocksChangesDialog,
}) => {
  const storeInstance = useSelector((state) => state.storeItems.data.itemsWithPrivate[strategyId]);
  const isMobile = window.innerWidth <= TABLET_WIDTH_BREAKPOINT;
  const [currentTab, setCurrentTab] = useState(currentWallet.name);
  const isCurrentWallet = currentWallet.name === currentTab;
  const objValuesStocks = Object.values(isCurrentWallet
    ? storeInstance.stocks
    : storeInstance.oldStocks);
  const totalWeights = getTotalItems({ items: objValuesStocks, value: 'weight' });
  const screen = getCurrentScreen({ mobile: 768, lowerMobile: 370 });

  const isSubscriber = storeInstance.signed;
  const notSubscriberShouldViewStocks = !isCurrentWallet && !storeInstance.signed;
  const notSubscriberBlockView = isCurrentWallet && !storeInstance.signed;
  const subscriberView = isCurrentWallet && isSubscriber;

  const validity = storeInstance.id && isCurrentWallet
    ? storeInstance?.extra_data?.PORTFOLIO_validity
    : storeInstance?.extra_data?.PORTFOLIO_old_validity;

  const chartTexts = [
    {
      id: 'totalText',
      y: getYTextCenter({ value: 110, screen, decreaseLowerMobile: 20 }),
      className: classes.totalPercentText,
      text: 'Total:',
      isVisible: true,
    },
    {
      id: 'totalValue',
      y: getYTextCenter({ value: 140, screen, decreaseLowerMobile: 20 }),
      className: classes.totalPercentValue,
      text: `${totalWeights}%`,
      isVisible: true,
    },
  ];

  const ShowcaseInstanceButtonBox = () => (
    <Box pt={2} display="flex" flexDirection="column" alignItems="flex-end">
      <ShowcaseInstanceButton strategyId={storeInstance.strategy_id} />
      {storeInstance.extra_data.visibility === STORE_VISIBILITY.CLOSED && (
        <div className={classes.closedMessage}>
          <i className={classNames('material-icons', classes.lockIcon)}>lock</i>
          &nbsp;A carteira está fechada para novos assinantes.&nbsp;
          <Link
            color="primary"
            fontSize={12}
            path={`${SI_ZENDESK_ARTICLES_URL}/4419894057879`}
            target="_blank"
          >
            Saiba mais.
          </Link>
        </div>
      )}
    </Box>
  );

  return (
    <Card
      data-testid="stock-view-card"
      className={classNames(classes.card, classes.cardChart)}
    >
      <Box mb={4}>
        <Tabs
          currentTab={currentTab}
          setCurrentTab={setCurrentTab}
          tabsMap={PORTFOLIO_COMPOSITION_TABS}
        />
      </Box>

      {validity && (
      <Box mb={3} textAlign="center">
        <Chip
          className={classes.validityTag}
          label={isCurrentWallet
            ? storeInstance.extra_data.PORTFOLIO_validity
            : storeInstance.extra_data.PORTFOLIO_old_validity}
          size="small"
        />
      </Box>
      )}

      <Box height={16} textAlign="center">
        {subscriberView && (
        <Link
          color="textSecondary"
          target="_blank"
          handleClick={() => openStocksChangesDialog({
            stocks: storeInstance.stocks,
            oldStocks: storeInstance.oldStocks,
            validity: {
              PORTFOLIO_validity: storeInstance.extra_data.PORTFOLIO_validity,
              PORTFOLIO_old_validity: storeInstance.extra_data.PORTFOLIO_old_validity,
            },
          })}
          underlineDecoration
        >
          {SHOW_CHANGES}
        </Link>
        )}
      </Box>

      {(isSubscriber || notSubscriberShouldViewStocks)
    && (
      <>
        <DonutsChart
          data={objValuesStocks}
          chartTexts={chartTexts}
          size={SMALL}
        />

        {isInstanceSettings && (
        <Box textAlign="center">
          <Typography variant="h6" paragraph>
            Não é possível alterar a composição dessa carteira
          </Typography>
        </Box>
        )}

        <Box className={classNames(
          classes.wrapperStocks,
          !isInstanceSettings && classes.borderWrapperStocks,
        )}
        >
          {isMobile && (
          <HiddenList
            initialShow={!isMobile}
            openText="Ver lista de ativos"
            closeText="Esconder lista de ativos"
          >
            <DetailedChartLegend stocks={objValuesStocks} />
          </HiddenList>
          )}

          {!isMobile && <DetailedChartLegend stocks={objValuesStocks} />}
        </Box>

        {!isInstanceSettings && <ShowcaseInstanceButtonBox items={objValuesStocks} />}
      </>
    )}
      {notSubscriberBlockView && (
        <>
          <Box className={classes.wrapperBlockStocks}>
            <Box mb={3}>
              <EmptyChartDefault
                alt={SUBSCRIBERS_SEE_STOCKS_MESSAGE}
              />
            </Box>

            <Typography variant="h6">
              {SUBSCRIBERS_SEE_STOCKS_MESSAGE}
            </Typography>
          </Box>

          {!isInstanceSettings && <ShowcaseInstanceButtonBox />}
        </>
      )}
    </Card>
  );
};

StocksView.propTypes = {
  classes: PropTypes.object.isRequired,
  strategyId: PropTypes.number.isRequired,
  isInstanceSettings: PropTypes.bool,
  openStocksChangesDialog: PropTypes.func.isRequired,
};

StocksView.defaultProps = {
  isInstanceSettings: false,
};

export default StocksView;
