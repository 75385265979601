import logdown from 'logdown';
import {
  error as notifierError,
  success as notifierSuccess,
} from 'react-notification-system-redux';

import { closeDialog } from 'common/ducks/dialogs';
import api from 'common/api';
import { LOGOUT_SUCCEED } from 'common/ducks/auth';

import { isDuckAlreadyLoaded } from '../../utils/validation';

const ADD_PAYMENT_METHOD_MAX_RETRIES = 3;

export const INITIAL_STATE = {
  loading: true,
  data: [],
  error: null,
  shouldAskCaptcha: false,
  retries: ADD_PAYMENT_METHOD_MAX_RETRIES,
};

export const DUCK_NAME = 'paymentMethods';
export const NAMESPACE = `user/${DUCK_NAME}`;
const logger = logdown(DUCK_NAME);

// Actions
export const LOAD_USER_PAYMENT_METHODS_STARTED = `${NAMESPACE}/LOAD_USER_PAYMENT_METHODS_STARTED`;
export const LOAD_USER_PAYMENT_METHODS_SUCCEED = `${NAMESPACE}/LOAD_USER_PAYMENT_METHODS_SUCCEED`;
export const LOAD_USER_PAYMENT_METHODS_FAILED = `${NAMESPACE}/LOAD_USER_PAYMENT_METHODS_FAILED`;
export const ADD_PAYMENT_METHOD_STARTED = `${NAMESPACE}/ADD_PAYMENT_METHOD_STARTED`;
export const ADD_PAYMENT_METHOD_SUCCEED = `${NAMESPACE}/ADD_PAYMENT_METHOD_SUCCEED`;
export const ADD_PAYMENT_METHOD_FAILED = `${NAMESPACE}/ADD_PAYMENT_METHOD_FAILED`;

// Action creators
export const loadUserPaymentMethodsStarted = () => ({
  type: LOAD_USER_PAYMENT_METHODS_STARTED,
});
export const loadUserPaymentMethodsSucceed = (data) => ({
  data,
  type: LOAD_USER_PAYMENT_METHODS_SUCCEED,
});
export const loadUserPaymentMethodsFailed = (error) => ({
  error,
  type: LOAD_USER_PAYMENT_METHODS_FAILED,
});
export const loadUserPaymentMethods = (options = { reload: false }) => async (
  dispatch,
  getState,
) => {
  const paymentMethods = getState().user[DUCK_NAME];
  if (!options.reload && isDuckAlreadyLoaded(paymentMethods)) {
    return Promise.resolve(paymentMethods.data);
  }
  dispatch(loadUserPaymentMethodsStarted());

  let results = null;
  try {
    results = await api.user.getUserPaymentMethods();
  } catch (error) {
    dispatch(loadUserPaymentMethodsFailed(error));
    return Promise.reject(error);
  }
  dispatch(loadUserPaymentMethodsSucceed(results.data.results));
  return Promise.resolve(results);
};

export const deleteUserPaymentMethod = (id) => async (dispatch) => {
  try {
    const result = await api.user.deleteUserPaymentMethod(id);

    dispatch(loadUserPaymentMethods({ reload: true }));
    logger.info('Método de pagamento removido com sucesso.', result);
    dispatch(notifierSuccess({
      title: 'Sucesso!',
      message: 'Método de pagamento removido com sucesso.',
    }));
    return Promise.resolve(result);
  } catch (error) {
    dispatch(notifierError({
      title: 'Erro!',
      message: error.codeMessage || 'Falha ao remover o método de pagamento.',
    }));
    logger.error(`Falha ao remover o método de pagamento #${id}`, error);
    return Promise.reject(error);
  }
};

export const addPaymentMethodStarted = () => ({
  type: ADD_PAYMENT_METHOD_STARTED,
});
export const addPaymentMethodSucceed = (data) => ({
  data,
  type: ADD_PAYMENT_METHOD_SUCCEED,
});
export const addPaymentMethodFailed = () => ({
  type: ADD_PAYMENT_METHOD_FAILED,
});

export const addPaymentMethod = (data) => async (dispatch) => {
  dispatch(addPaymentMethodStarted());
  try {
    const result = await api.user.postUserPaymentMethod(data);

    logger.info('add payment method', result);
    dispatch(addPaymentMethodSucceed(result.data));

    dispatch(closeDialog());
    return Promise.resolve(data);
  } catch (error) {
    logger.error('Failed to add payment method', error);

    dispatch(addPaymentMethodFailed());
    return Promise.reject(error);
  }
};

const validateAddPaymentMethodMaxRetries = (state) => {
  const retries = state.retries
    ? state.retries - 1
    : state.retries;

  return {
    retries,
    shouldAskCaptcha: !retries,
  };
};

export default (state = INITIAL_STATE, action) => {
  // Logout cleaning
  if (action.type === LOGOUT_SUCCEED) {
    return INITIAL_STATE;
  }

  switch (action.type) {
    case LOAD_USER_PAYMENT_METHODS_STARTED:
      return {
        ...state,
        loading: true,
      };
    case LOAD_USER_PAYMENT_METHODS_SUCCEED:
      return {
        ...state,
        loading: false,
        error: null,
        data: action.data,
      };
    case LOAD_USER_PAYMENT_METHODS_FAILED:
      return {
        ...state,
        error: action.error,
        loading: false,
      };
    case ADD_PAYMENT_METHOD_STARTED:
      return {
        ...state,
        loading: true,
      };
    case ADD_PAYMENT_METHOD_SUCCEED:
      return {
        ...state,
        loading: false,
        error: null,
        data: [
          ...state.data,
          {
            brand: action.data.data.brand,
            display_number: action.data.data.display_number,
            id: action.data.id,
          },
        ],
        retries: ADD_PAYMENT_METHOD_MAX_RETRIES,
        shouldAskCaptcha: false,
      };
    case ADD_PAYMENT_METHOD_FAILED:
      return {
        ...state,
        ...validateAddPaymentMethodMaxRetries(state),
        error: action.error,
        loading: false,
      };
    default:
      return state;
  }
};
