export const MONTH_KEYS = {
  january: {
    key: 0,
    shortname: 'jan',
    fullname: 'janeiro',
  },
  february: {
    key: 1,
    shortname: 'fev',
    fullname: 'fevereiro',
  },
  march: {
    key: 2,
    shortname: 'mar',
    fullname: 'março',
  },
  april: {
    key: 3,
    shortname: 'abr',
    fullname: 'abril',
  },
  may: {
    key: 4,
    shortname: 'mai',
    fullname: 'maio',
  },
  june: {
    key: 5,
    shortname: 'jun',
    fullname: 'junho',
  },
  july: {
    key: 6,
    shortname: 'jul',
    fullname: 'julho',
  },
  august: {
    key: 7,
    shortname: 'ago',
    fullname: 'agosto',
  },
  september: {
    key: 8,
    shortname: 'set',
    fullname: 'setembro',
  },
  october: {
    key: 9,
    shortname: 'out',
    fullname: 'outubro',
  },
  november: {
    key: 10,
    shortname: 'nov',
    fullname: 'novembro',
  },
  december: {
    key: 11,
    shortname: 'dez',
    fullname: 'dezembro',
  },
};

export const ISO_DATE_FORMAT = 'YYYY-MM-DD';
export const DATETIME_FORMAT = 'YYYY-MM-DD HH:mm:ss';
export const DATETIME_LOCAL_FORMAT = 'YYYY-MM-DDTHH:mm:ss';
export const DAY_MONTH_FORMAT = 'DD/MM';
export const BR_TIME_FORMAT = 'HH:mm:ss';

export const REGEX_ISO_DATE_FORMAT = /[0-9]{4}-(0[1-9]|1[0-2])-(0[1-9]|[1-2][0-9]|3[0-1])/;

export const DATETIME_ZERO_OBJECT = { hour: 0, minute: 0, second: 0 };
