import api from 'common/api';
import logdown from 'logdown';
import {
  error as notifierError,
} from 'react-notification-system-redux';

import { LOGOUT_SUCCEED } from 'common/ducks/auth';

export const INITIAL_STATE = {
  loading: false,
  recurrent: false,
  discount: null,
  error: false,
};
export const DUCK_NAME = 'recurrency';
export const NAMESPACE = `user/${DUCK_NAME}`;
const logger = logdown(NAMESPACE);

// Actions
export const GET_PLAN_RECURRENCY_STARTED = `${NAMESPACE}/GET_PLAN_RECURRENCY_STARTED`;
export const GET_PLAN_RECURRENCY_SUCCEED = `${NAMESPACE}/GET_PLAN_RECURRENCY_SUCCEED`;
export const GET_PLAN_RECURRENCY_FAILED = `${NAMESPACE}/GET_PLAN_RECURRENCY_FAILED`;

// Action creators
export const getPlanRecurrencyStarted = () => ({
  type: GET_PLAN_RECURRENCY_STARTED,
});

export const getPlanRecurrencySucceed = (recurrent, discountPercent) => ({
  type: GET_PLAN_RECURRENCY_SUCCEED,
  recurrent,
  discount: discountPercent,
});

export const getPlanRecurrencyFailed = (error) => ({
  error,
  type: GET_PLAN_RECURRENCY_FAILED,
});

export const getPlanRecurrency = () => async (dispatch) => {
  dispatch(getPlanRecurrencyStarted());

  try {
    const {
      data: {
        isRecurrent,
        discountPercent,
      },
    } = await api.billing.recurrency();
    logger.info('get plan recurrency');
    dispatch(getPlanRecurrencySucceed(isRecurrent, discountPercent));
    return Promise.resolve(isRecurrent, discountPercent);
  } catch (error) {
    logger.error('Failed to get plan recurrency.', error);
    dispatch(getPlanRecurrencyFailed(error));

    dispatch(notifierError({
      title: 'Erro!',
      message:
          error.codeMessage
          || 'Houve um erro ao obter a Recorrência do Plano. Tente novamente.',
    }));
    return Promise.reject(error);
  }
};

// Reducer
export default (state = INITIAL_STATE, action) => {
  if (action.type === LOGOUT_SUCCEED) {
    return INITIAL_STATE;
  }

  switch (action.type) {
    case GET_PLAN_RECURRENCY_STARTED:
      return {
        ...state,
        loading: true,
      };
    case GET_PLAN_RECURRENCY_SUCCEED:
      return {
        ...state,
        loading: false,
        recurrent: action.recurrent,
        discount: action.discount,
        error: null,
      };
    case GET_PLAN_RECURRENCY_FAILED:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    default:
      return state;
  }
};
