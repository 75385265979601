import { withStyles } from '@material-ui/core';

import palette from 'common/palette';
import FilledCircleIndicator from './FilledCircleIndicator';

const styles = {
  labelText: {
    fontSize: 13,
    textAlign: 'left',
    color: palette.textSecondary.main,
  },
  circleRow: {
    display: 'flex',
  },
  circle: {
    width: 10,
    height: 10,
    borderRadius: 10,
    marginRight: 3,
  },
};

export default withStyles(styles)(FilledCircleIndicator);
