import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import { closeSideDialog } from 'common/ducks/dialogs';
import MainSideDialog from './MainSideDialog';

const styles = {
  drawer: {
    width: '100%',
    padding: '30px 45px',
    '@media(max-width: 500px)': {
      padding: '15px 25px',
    },
    '@media(min-width: 769px)': {
      width: 465,
    },
  },
};

const mapDispatchToProps = (dispatch, { onCloseDialog }) => ({
  onClose: () => {
    dispatch(closeSideDialog());
    onCloseDialog();
  },
});

export default connect(
  null,
  mapDispatchToProps,
)(withStyles(styles)(MainSideDialog));
