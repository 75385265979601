import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import { withMobileDialog } from '@material-ui/core';

import palette from 'common/palette';

import { error as notifierError } from 'react-notification-system-redux';
import { loadInstancePositions } from 'common/ducks/positions';
import { EXTERNAL_ORDER_DIALOG_CODE } from 'common/components/Dialogs/InstanceDialogs/ExternalOrderDialog';
import { openDialog } from 'common/ducks/dialogs';
import { WAIT_FOR_OPENING_DIALOG_CODE } from '../WaitForOpeningDialog';
import { SELL_ALL_DIALOG_CODE } from '../SellAllDialog';

import CorrectPositionDialog, { CORRECT_POSITION_DIALOG_CODE } from './CorrectPositionDialog';

const styles = (theme) => ({
  [theme.breakpoints.down('sm')]: {
    buttons: {
      flexDirection: 'column',
    },
    button: {
      marginBottom: '15px',
    },
  },
  title: {
    color: '#333333',
    marginBottom: 10,
    fontWeight: 'bold',
    fontSize: 22,
  },
  subtitle: {
    marginTop: 20,
    marginBottom: 10,
    display: 'flex',
    alignItems: 'center',
  },
  text: {
    paddingTop: 10,
    color: palette.textSecondary.main,
    fontSize: 14,
  },
  list: {
    listStyleType: 'disc',
    fontWeight: 'bold',
    listStylePosition: 'inside',
    padding: '3px 0 0 17px',
  },
  link: {
    textDecoration: 'none',
    color: palette.primary.main,
    fontWeight: 'bold',
    fontSize: 12,
  },
  buttons: {
    padding: '15px 10px 0 0',
  },
  closeIcon: {
    position: 'absolute',
    right: '20px',
    top: '5px',
    color: 'black',
  },
  spaceTop: {
    marginTop: 10,
  },
});

const mapDispatchToProps = (dispatch, { id }) => ({
  openWaitForOpeningDialog: () => dispatch(openDialog(WAIT_FOR_OPENING_DIALOG_CODE)),
  openSellAllDialog: (stockCodesAndNumber) => dispatch(openDialog(
    SELL_ALL_DIALOG_CODE,
    { stockCodesAndNumber, instanceId: id },
  )),
  openExternalOrderDialog: (options) => dispatch(openDialog(
    EXTERNAL_ORDER_DIALOG_CODE,
    { id, ...options },
  )),
  loadInstancePositions: () => dispatch(loadInstancePositions(
    id,
    { reload: true },
  )),
  notifyError: (data) => dispatch(notifierError(data)),
});

export { CORRECT_POSITION_DIALOG_CODE };

export default withRouter(
  connect(null, mapDispatchToProps)(
    withStyles(styles)(
      withMobileDialog()(CorrectPositionDialog),
    ),
  ),
);
