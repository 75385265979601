import { withStyles } from '@material-ui/styles';

import AuthorCard from './AuthorCard';

const styles = {
  cardSpacing: {
    padding: 25,
  },
  cardText: {
    fontSize: 14,
    '&:not(:last-of-type)': {
      marginBottom: 15,
    },
  },
  cardTitle: {
    fontWeight: 500,
    marginBottom: 10,
  },
  authorTitle: {
    marginBottom: 16,
  },
  authorInfo: {
    marginBottom: 4,
  },
  itemCard: {
    marginBottom: 30,
    flex: 1,
  },
};

export default withStyles(styles)(AuthorCard);
