import React from 'react';
import PropTypes from 'prop-types';

import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
} from '@material-ui/core';

const CancelOrderDialog = ({
  classes,
  open,
  warn,
  dialogTitle,
  positionsAfterStop,
  instance,
  isInstanceRunning,
  runningInstanceAlert,
  messages,
  cancelOrdersButtonLabel,
  closeButtonLabel,
  preventClose,
  onCloseButtonClick,
  onCancelOrders,
  onClose,
}) => {
  const renderPositionsAfterStopContent = () => (
    <>
      <p className={classes.textContent}>
        {`
        A carteira
        ${instance.id}
        enviou ordens que ainda estão abertas
        enquanto estava sendo parada. Você pode escolher uma das 2
        opções a seguir:
      `}
      </p>

      <div>
        1)
        <strong>Cancelar ordens: </strong>
        as ordens abertas serão canceladas e sua carteira permanecerá parada
        <strong> (recomendado)</strong>
        .
      </div>

      <div>
        2)
        <strong>Manter ordens: </strong>
        as ordens
        permanecerão abertas e sua carteira continuará parada
        sem acompanhá-las.
      </div>
    </>
  );

  const renderDefaultTextContent = () => (
    <>
      Ao confirmar, todas as ordens que não estão
      em estado final serão canceladas,
      como ordens com os status aberta,
      parcialmente executada e alterada.
    </>
  );
  return (
    <Dialog fullWidth maxWidth="md" onClose={!preventClose ? onClose : null} open={open}>
      <DialogTitle className={classes.title} disableTypography>
        {dialogTitle}
      </DialogTitle>
      <DialogContent className={classes.dialogContent}>
        <DialogContentText>
          {!warn
          && 'Você tem certeza que deseja cancelar as ordens que não foram totalmente executadas?'}
        </DialogContentText>
        <DialogContentText>
          {positionsAfterStop ? renderPositionsAfterStopContent() : renderDefaultTextContent()}
        </DialogContentText>
        { isInstanceRunning && !warn ? runningInstanceAlert : null }
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            onCloseButtonClick();
            onClose();
          }}
          color="default"
        >
          { closeButtonLabel }
        </Button>
        <Button
          onClick={() => onCancelOrders(messages)}
          variant="contained"
          color="secondary"
        >
          { cancelOrdersButtonLabel }
        </Button>
      </DialogActions>
    </Dialog>
  );
};

CancelOrderDialog.propTypes = {
  classes: PropTypes.object.isRequired,
  open: PropTypes.bool.isRequired,
  instance: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired,
  onCancelOrders: PropTypes.func.isRequired,
  dialogTitle: PropTypes.string.isRequired,
  cancelOrdersButtonLabel: PropTypes.string,
  isInstanceRunning: PropTypes.bool,
  runningInstanceAlert: PropTypes.any,
  messages: PropTypes.object,
  warn: PropTypes.bool,
  closeButtonLabel: PropTypes.string,
  onCloseButtonClick: PropTypes.func,
  preventClose: PropTypes.bool,
  positionsAfterStop: PropTypes.bool,
};

CancelOrderDialog.defaultProps = {
  warn: false,
  closeButtonLabel: 'Fechar',
  cancelOrdersButtonLabel: 'Cancelar Ordens',
  isInstanceRunning: false,
  runningInstanceAlert: '',
  messages: {},
  onCloseButtonClick: () => {},
  preventClose: false,
  positionsAfterStop: false,
};

export default CancelOrderDialog;
