import { withStyles } from '@material-ui/core';

import { openDialog } from 'common/ducks/dialogs';
import { OPEN_ACCOUNT_INFO_DIALOG_CODE } from 'common/components/Dialogs/AccountInfoDialog';
import { REGISTER_BROKERAGE_DIALOG_CODE } from 'common/components/Dialogs/RegisterBrokerageDialog';
import { PLAN_SUBSCRIPTION_DIALOG_CODE } from 'common/components/Dialogs/PlanSubscriptionDialog';
import { updateRegisterSteps } from 'common/ducks/registerSteps';
import { OPEN_FOLLOW_INSTANCE_DIALOG_CODE } from 'common/components/Dialogs/FollowInstanceDialog';
import { CONFIRMATION_DIALOG_CODE } from 'common/components/Dialogs/ConfirmationDialog';
import { PROGRESS_DIALOG_CODE } from 'common/components/Dialogs/ProgressDialog';
import { START_FOLLOW_INSTANCE_DIALOG_CODE } from 'common/components/Dialogs/StartFollowInstanceDialog';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import ShowcaseInstanceButton from './ShowcaseInstanceButton';

const styles = {
  blueButton: {
    backgroundColor: '#025ab9',
    color: '#fff',

    '&:hover': {
      backgroundColor: '#002f7d',
    },
  },
};

const mapStateToProps = (({
  instances,
  storeItems,
  registerSteps: { accountInfo },
  auth: { logged },
  user,
},
{ strategyId }) => {
  const storeInstance = storeItems.data.itemsWithPrivate[strategyId];

  return {
    instances: instances.data,
    accountInfo,
    storeInstance: storeInstance || {},
    logged,
    userInvestorProfileId: user.profile.data?.extra_data?.si_investor_profile?.id,
  };
});

const mapDispatchToProps = (dispatch, { id }) => ({
  openAccountInfoDialog: (onConfirmSubmit) => dispatch(openDialog(
    OPEN_ACCOUNT_INFO_DIALOG_CODE, { onConfirmSubmit },
  )),
  openRegisterBrokerageDialog: (onConfirmSubmit) => dispatch(openDialog(
    REGISTER_BROKERAGE_DIALOG_CODE, { onConfirmSubmit },
  )),
  openPlanSubscriptionDialog: ({ strategyId, update = false }) => dispatch(openDialog(
    PLAN_SUBSCRIPTION_DIALOG_CODE, {
      id, strategyId, update,
    },
  )),
  updateStatusRegisterStep: (status, type) => {
    dispatch(updateRegisterSteps({
      [type]: { status },
    }));
  },
  openFollowInstanceDialog: ({ strategyId, allowedBrokerages }) => dispatch(openDialog(
    OPEN_FOLLOW_INSTANCE_DIALOG_CODE, { strategyId, allowedBrokerages },
  )),
  openConfirmationDialog: (props) => dispatch(openDialog(
    CONFIRMATION_DIALOG_CODE,
    { ...props },
  )),
  openProgressDialog: (props) => dispatch(openDialog(PROGRESS_DIALOG_CODE, { ...props })),
  openStartFollowInstance: (props) => dispatch(openDialog(
    START_FOLLOW_INSTANCE_DIALOG_CODE,
    { ...props },
  )),
});

export default connect(mapStateToProps, mapDispatchToProps)(
  withRouter((withStyles(styles)(ShowcaseInstanceButton))),
);
