export const PORTFOLIO_COMPOSITION_TABS = {
  previousWallet: {
    name: 'previousWallet',
    label: 'Anterior',
  },
  currentWallet: {
    name: 'currentWallet',
    label: 'Atual',
  },
};

export default null;
