import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import logdown from 'logdown';

import { DialogContentText } from '@material-ui/core';
import {
  RUNNING_STATES,
  IS_LOADING,
  IS_ERROR,
  IS_SUCCESS,
  DEFAULT_LOADING_MSG,
  ERROR,
  ERROR_VERIFY_INSTANCE_MSG,
} from 'common/utils/constants';

const logger = logdown('PendingOrdersDialog');

const PendingOrdersDialog = ({
  id,
  onClose,
  open,
  instance,
  warn,
  isRestart,
  closeButtonLabel,
  onCloseButtonClick,
  preventClose,
  positionsAfterStop,
  openCancelOrderDialog,
  stopInstance,
  cancelInstanceOrders,
  restartInstance,
  reloadInstanceState,
  openProgressDialog,
  openPasswordConfirmDialog,
}) => {
  useEffect(() => {
    const cancelOrders = async (data) => {
      try {
        await cancelInstanceOrders(data);
        logger.log(['cancelOrder success']);
      } catch (error) {
        logger.log(['Error cancelling instance orders', error]);
      }

      return Promise.resolve();
    };

    const onCancelOrders = async ({
      loadingMessage,
      errorMessage,
      successMessage,
    }) => {
      const isInstanceRunning = RUNNING_STATES.includes(instance.state);
      const observation = `Você pode fechar esta janela que avisaremos quando o processo for
      finalizado.`;

      openProgressDialog({
        state: IS_LOADING,
        title: loadingMessage,
        message: observation,
      });

      if (isInstanceRunning) {
        try {
          if (isRestart) {
            await restartInstance({ cancelPendingOrders: true });
          } else {
            await stopInstance({ cancelPendingOrders: true });
          }
        } catch (e) {
          const errorSource = isRestart ? 'restarting' : 'stopping';

          logger.log([`Error ${errorSource} instance`, e]);
          return openProgressDialog({
            state: IS_ERROR,
            title: ERROR,
            message: errorMessage,
            showButton: true,
            onClose,
          });
        }
      } else {
        const data = {};

        const onCancelClick = () => {
          onClose();
        };

        const onConfirmClick = (password) => {
          data.password = password;
          cancelOrders(data);
        };

        return openPasswordConfirmDialog({
          open: true,
          onConfirmClick,
          onCancelClick,
          onClose,
        });
      }

      return openProgressDialog({
        state: IS_SUCCESS,
        title: successMessage,
        showButton: true,
        onClose,
      });
    };

    const getMessages = (isInstanceRunning) => {
      let loadingMessage = 'Cancelando ordens pendentes';
      if (isInstanceRunning) {
        if (isRestart) {
          loadingMessage = 'Reiniciando carteiras e cancelando ordens pendentes';
        } else {
          loadingMessage = 'Parando carteiras e cancelando ordens pendentes';
        }
      }

      let errorMessage = 'Erro na tentativa de cancelar ordens. Por favor, tente novamente';
      if (isInstanceRunning) {
        if (isRestart) {
          errorMessage = `Erro na tentativa de reiniciar carteira e cancelar ordens. Por favor, tente
          novamente`;
        } else {
          errorMessage = `Erro na tentativa de parar carteira e cancelar ordens. Por favor, tente
          novamente`;
        }
      }

      let successMessage = 'Ordens pendentes canceladas com sucesso!';
      if (isInstanceRunning) {
        if (isRestart) {
          successMessage = `A carteira foi reiniciada e suas ordens pendentes canceladas com
          sucesso!`;
        } else {
          successMessage = 'A carteira foi parada e suas ordens pendentes canceladas com sucesso!';
        }
      }

      const messages = {
        loadingMessage,
        errorMessage,
        successMessage,
      };

      return messages;
    };

    const renderCancelOrdersDialog = (isInstanceRunning) => {
      const runningInstanceAlert = (
        <DialogContentText>
          <b>
            A carteira atualmente está executando. Ao cancelar suas ordens, a carteira será parado.
          </b>
        </DialogContentText>
      );

      const messages = getMessages(isInstanceRunning);

      let cancelOrdersButtonLabel = 'Cancelar ordens';
      if (isInstanceRunning) {
        if (isRestart) {
          cancelOrdersButtonLabel = 'Cancelar Ordens e Reiniciar';
        } else {
          cancelOrdersButtonLabel = 'Parar e Cancelar ordens';
        }
      }

      let dialogTitle = '';
      if (positionsAfterStop) {
        dialogTitle = `Atenção: Carteira #${instance.id} ${instance.name} com ordens abertas`;
      } else if (warn) {
        dialogTitle = `Atenção! Carteira #${instance.id} ${instance.name} com ordens pendentes`;
      } else {
        dialogTitle = `Cancelar ordens da carteira #${instance.id} ${instance.name}`;
      }
      return openCancelOrderDialog({
        open,
        warn,
        dialogTitle,
        positionsAfterStop,
        instance,
        isInstanceRunning,
        runningInstanceAlert,
        messages,
        cancelOrdersButtonLabel,
        closeButtonLabel,
        preventClose,
        onCloseButtonClick,
        onCancelOrders,
        onClose,
      });
    };

    const mount = async () => {
      openProgressDialog({
        state: IS_LOADING,
        title: 'Verificando situação da carteira...',
        message: DEFAULT_LOADING_MSG,
        closeOnClickBackdrop: false,
      });

      try {
        const { data: { state } } = await reloadInstanceState(id);
        const isInstanceRunning = RUNNING_STATES.includes(state);

        renderCancelOrdersDialog(isInstanceRunning);
      } catch (error) {
        logger.log(['error reloading instance state', error]);
        openProgressDialog({
          state: IS_ERROR,
          title: ERROR,
          message: ERROR_VERIFY_INSTANCE_MSG,
          showButton: true,
        });
      }
    };

    mount();
  }, [
    cancelInstanceOrders,
    closeButtonLabel,
    id,
    instance,
    isRestart,
    onClose,
    onCloseButtonClick,
    open,
    openCancelOrderDialog,
    openPasswordConfirmDialog,
    openProgressDialog,
    positionsAfterStop,
    preventClose,
    reloadInstanceState,
    restartInstance,
    stopInstance,
    warn,
  ]);

  return null;
};
PendingOrdersDialog.propTypes = {
  id: PropTypes.number.isRequired,
  reloadInstanceState: PropTypes.func.isRequired,
  cancelInstanceOrders: PropTypes.func.isRequired,
  openPasswordConfirmDialog: PropTypes.func.isRequired,
  stopInstance: PropTypes.func.isRequired,
  restartInstance: PropTypes.func.isRequired,
  openProgressDialog: PropTypes.func.isRequired,
  openCancelOrderDialog: PropTypes.func.isRequired,

  warn: PropTypes.bool,
  instance: PropTypes.object.isRequired,
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  isRestart: PropTypes.bool,
  closeButtonLabel: PropTypes.string,
  onCloseButtonClick: PropTypes.func,
  preventClose: PropTypes.bool,
  positionsAfterStop: PropTypes.bool,
};

PendingOrdersDialog.defaultProps = {
  warn: false,
  isRestart: false,
  closeButtonLabel: 'Fechar',
  onCloseButtonClick: () => {},
  preventClose: false,
  positionsAfterStop: false,
};

export default PendingOrdersDialog;
