import { withStyles } from '@material-ui/styles';
import StoreInstanceTags from './StoreInstanceTags';

const styles = {
  tagsContainer: {
    margin: '11px 0',
    overflow: 'auto',
    display: 'flex',
    flexWrap: 'wrap',
  },
  tag: {
    fontSize: 11,
    fontWeight: 500,
    padding: '0 4px',
    marginBottom: 8,
    height: 26,
  },
  detailsTag: {
    marginRight: 14,
    '@media (max-width: 500px)': {
      marginRight: 5,
    },
  },
  low: {
    color: '#24BC71',
    backgroundColor: '#CFFFEA',
  },
  medium: {
    color: '#B98E17',
    backgroundColor: '#F5E0A4',
  },
  high: {
    color: '#E93056',
    backgroundColor: '#FFD8E0',
  },
  veryHigh: {
    color: '#E93056',
    backgroundColor: '#FFD8E0',
  },
};

export default withStyles(styles)(StoreInstanceTags);
