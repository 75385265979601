import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

const ButtonGroup = ({
  classes,
  input,
  children,
}) => (
  <div className={classes.wrapper}>
    {
      React.Children.map(
        children,
        (child) => (
          React.cloneElement(child, {
            className: classNames(
              child.props.className,
              classes.button,
              input.value === child.props.value && (classes[input.color] || classes.primary),
              classes[`${input.color}Default`] || classes.primaryDefault,
            ),
          })
        ),
      )
    }
  </div>
);

ButtonGroup.propTypes = {
  children: PropTypes.any.isRequired,
  classes: PropTypes.object.isRequired,
  input: PropTypes.object.isRequired,
};

export default ButtonGroup;
