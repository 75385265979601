import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import TextField from '@material-ui/core/TextField';

const styles = {
  fieldContainer: { position: 'relative' },
  field: { marginBottom: 5 },
  absoluteContainer: {
    position: 'absolute',
    left: '100%',
    top: '50%',
    paddingLeft: '10px',
    width: '150px',
    fontSize: '12px',
    transform: 'translateY(-50%)',
  },
  stepsMargin: { margin: 4 },
  neutral: { color: '#C0C0C0' },
  red: { color: 'red' },
  accepted: { color: '#00B29C' },
};
const PasswordField = ({
  classes,
  input,
  meta: {
    error = '',
    touched,
    warning,
    submitFailed,
  },
  label,
  type,
  className,
}) => {
  const validationSteps = [
    { desc: 'Seis caracteres', code: 1 },
    { desc: 'Um número', code: 2 },
    { desc: 'Uma letra minúscula', code: 3 },
    { desc: 'Uma letra maiúscula', code: 4 },
  ];

  const includeAnyCode = (errorMessage) => {
    for (let i = 0; i < validationSteps.length; i += 1) {
      if (errorMessage.includes(validationSteps[i].code)) {
        return true;
      }
    }
    return false;
  };

  return (
    <div className={classes.fieldContainer}>
      <TextField
        fullWidth
        type={type}
        error={touched && Boolean(error)}
        margin="dense"
        {...input}
        label={label}
        helperText={touched && !includeAnyCode(error) && error}
        className={classNames([className, classes.field])}
      />
      <div className={classes.absoluteContainer}>
        {
          warning
            ? validationSteps
              .map((step) => {
                let color;
                if (warning.includes(step.code)) {
                  color = 'accepted';
                } else if (!error.includes(step.code) && submitFailed) {
                  color = 'red';
                } else {
                  color = 'neutral';
                }
                return (
                  <div
                    key={step.code}
                    className={classNames([
                      classes.stepsMargin,
                      classes[color],
                    ])}
                  >
                    {step.desc}
                  </div>
                );
              })
            : ''
        }
      </div>
    </div>
  );
};

PasswordField.propTypes = {
  type: PropTypes.string.isRequired,
  input: PropTypes.object,
  classes: PropTypes.object.isRequired,
  custom: PropTypes.object,
  meta: PropTypes.object,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  className: PropTypes.string,
};
PasswordField.defaultProps = {
  input: {},
  custom: {},
  meta: {},
  label: '',
  className: '',
};

export default withStyles(styles)(PasswordField);
