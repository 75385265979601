import React from 'react';
import PropTypes from 'prop-types';
import DisplayNumber from 'common/components/DisplayNumber';
import { RIGHT } from 'common/utils/constants';

const DonutsChartTooltip = ({
  classes,
  active,
  payload,
  formatPercent,
}) => {
  if (active) {
    const correctPayload = payload[0];
    const currentPosition = correctPayload.payload.payload;
    const correctWeight = formatPercent ? correctPayload.value * 100 : correctPayload.value;
    const name = currentPosition.display_name || currentPosition.company_name;

    return (
      <div style={{ borderColor: correctPayload.payload.fill }} className={classes.tooltip}>
        <span className={classes.title}>{name}</span>
        <span>
          (
          <DisplayNumber
            color="textPrimaryBold"
            colorful={false}
            percentual
            scale={0.875}
            symbol="%"
            symbolLocation={RIGHT}
            value={correctWeight}
          />
          )
        </span>
      </div>
    );
  }

  return null;
};

DonutsChartTooltip.propTypes = {
  classes: PropTypes.object.isRequired,
  active: PropTypes.bool.isRequired,
  payload: PropTypes.array.isRequired,
  formatPercent: PropTypes.bool,
};

DonutsChartTooltip.defaultProps = {
  formatPercent: false,
};

export default DonutsChartTooltip;
