import Box from '@material-ui/core/Box';
import { withStyles } from '@material-ui/styles';

const GrayBox = withStyles(() => ({
  root: {
    backgroundColor: '#f0f0f0',
    borderRadius: 10,
    padding: '15px 20px',
  },
}))(Box);

export default GrayBox;
