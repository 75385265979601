import theme from 'common/theme';
import { withStyles } from '@material-ui/core/styles';
import ChangedStock from './ChangedStock';

const styles = {
  tagBox: {
    minWidth: 70,
    marginRight: 16,
    [theme.breakpoints.up('md')]: {
      textAlign: 'center',
    },
  },
  boxItem: {
    display: 'flex',
    padding: '4px 0',

    '&:not(:last-of-type)': {
      borderBottom: '1px solid #c5c5c5',
    },

    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
    [theme.breakpoints.up('md')]: {
      alignItems: 'center',
      justifyContent: 'space-between',
    },
  },
  wrapperWeightsChip: {
    alignItems: 'center',
    display: 'flex',

    [theme.breakpoints.down('sm')]: {
      justifyContent: 'space-between',
      marginTop: 5,
    },
  },
  spacing: {
    textAlign: 'center',
    minWidth: 60,
  },
  largeSpacing: {
    minWidth: 95,
  },
};

export default withStyles(styles)(ChangedStock);
