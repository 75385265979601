import { withStyles } from '@material-ui/styles';
import DonutsChartTooltip from './DonutsChartTooltip';

const styles = ((theme) => ({
  tooltip: {
    backgroundColor: '#fff',
    borderRadius: 10,
    boxShadow: '0 3px 6px 0 rgba(0, 0, 0, 0.16)',
    borderLeft: '9px solid #333',
    fontWeight: 'bold',
    fontSize: theme.typography.h6.fontSize,
    padding: '10px 15px 10px 10px',
  },
  title: {
    display: 'inline-block',
    marginRight: 5,
  },
}));

export default withStyles(styles)(DonutsChartTooltip);
