import { onlyNumbers } from 'common/utils/string';
import { getTotalItems } from './array';

export const getValidNumberInput = ({
  value,
  minValue = 0,
  maxValue = 100,
}) => {
  const validNumber = value ?? minValue;

  const formattedValue = Number(onlyNumbers(validNumber));
  let finalValue = formattedValue > maxValue ? maxValue : formattedValue;
  finalValue = finalValue < minValue ? minValue : finalValue;

  return finalValue;
};

export const getAverageSelectedItems = ({ selectedItems, baseValue }) => {
  const totalItems = selectedItems.length;

  return Math.floor(baseValue / totalItems);
};

export const getEqualizedItemsList = ({
  items,
  selectedItems,
  value,
  maxValue = 100,
}) => {
  const totalSelectedItems = selectedItems.length;
  const isAllItemsSelected = items.length === totalSelectedItems;
  const baseValue = isAllItemsSelected
    ? maxValue
    : getTotalItems({ items: selectedItems, value: 'weight' });

  let remainder = baseValue % totalSelectedItems;

  const correctValue = getAverageSelectedItems({ selectedItems, baseValue });

  return items.map((item) => {
    if (item.checked) {
      const remainderValue = remainder > 0 ? 1 : 0;
      item[value] = correctValue + remainderValue;
      item.checked = false;
      if (remainder > 0) {
        remainder -= 1;
      }
    }

    return item;
  });
};

export default null;
