import { loadInstanceDailyCumulativePerformance } from 'common/ducks/dailyCumulativePerformance';
import {
  IBOV_WALLET_ID,
  SMLL_WALLET_ID,
  IFIX_WALLET_ID,
  CDI_WALLET_ID,
} from 'common/utils/constants';
import { BENCHMARKS } from 'common/utils/constants/benchmarks';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

export const useBenchmarkFilter = () => {
  const [selectedBenchmark, setSelectedBenchmark] = useState(BENCHMARKS.ibov);

  const handleChangeBenchmarkFilter = (benchmark) => {
    setSelectedBenchmark(BENCHMARKS[benchmark]);
  };

  return { selectedBenchmark, handleChangeBenchmarkFilter };
};

export const useLoadBenchmarksPerformance = ({
  isPublic = false,
  benchmarks = [IBOV_WALLET_ID, SMLL_WALLET_ID, IFIX_WALLET_ID, CDI_WALLET_ID],
} = {}) => {
  const [isBenchmarksLoaded, setIsBenchmarksLoaded] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    const loadBenchmarksPerformance = async () => {
      const promises = benchmarks
        .map(async (benchmarkId) => {
          const result = await dispatch(
            loadInstanceDailyCumulativePerformance(benchmarkId, { reload: false }, 'instances', isPublic),
          );

          return result;
        });

      await Promise.all(promises);
      setIsBenchmarksLoaded(true);
    };

    loadBenchmarksPerformance();
  }, [dispatch, isPublic, benchmarks]);

  return { isBenchmarksLoaded };
};

export default null;
