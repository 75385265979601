import { createMuiTheme } from '@material-ui/core/styles';
import createBreakpoints from '@material-ui/core/styles/createBreakpoints';
import palette from './palette';

const breakPointValues = {
  xs: 320,
  sm: 450,
  md: 768,
  mdx: 980,
  lg: 1200,
  xl: 1600,
};

const breakpoints = createBreakpoints({
  values: breakPointValues,
});

const theme = createMuiTheme({
  palette,
  breakpoints,
  typography: {
    fontSize: 16,
    h1: {
      fontSize: '2rem',
      fontWeight: 'bold',
    },
    h2: {
      fontSize: '1.6875rem',
      fontWeight: 'bold',
    },
    h3: {
      fontSize: '1.25rem',
      fontWeight: 500,
      lineHeight: 1.2,
    },
    h4: {
      fontSize: '1.125rem',
    },
    h5: {
      fontSize: '1rem',
    },
    h6: {
      fontSize: '0.875rem',
    },
  },
  overrides: {
    MuiCard: {
      root: {
        borderRadius: 8,
        boxShadow: '0 3px 6px 0 rgba(0, 0, 0, 0.16)',
      },
    },
    MuiDialog: {
      paper: {
        borderRadius: '8px',
      },
    },
    MuiDialogTitle: {
      root: {
        paddingTop: 23,
      },
    },
    MuiDialogContent: {
      root: {
        '&:first-child': {
          paddingTop: '24px',
        },
        padding: '24px',
      },
    },
    MuiDialogContentText: {
      root: {
        fontSize: 14,
      },
    },
    MuiDialogActions: {
      root: {
        padding: '24px',
      },
    },
    MuiButton: {
      root: {
        borderRadius: '31px',
        fontSize: '16px',
        textTransform: 'initial',
        fontWeight: 'normal',
      },
      contained: {
        boxShadow: '0 4px 5px 0 rgba(0, 0, 0, 0.07)',
        '&:hover': {
          boxShadow: '0 4px 5px 0 rgba(0, 0, 0, 0.07)',
        },
        '&$disabled': {
          backgroundColor: '#b4b6b8',
          color: '#fff',
        },
      },
      containedSecondary: {
        boxShadow: 'none',
      },
      outlined: {
        border: '1px solid #413e3e',
        color: '#413e3e',
        transition: 'all 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
        '&:hover': {
          backgroundColor: '#413e3e',
          color: '#fff',
        },
      },
      outlinedPrimary: {
        color: '#616161',
        '&:hover': {
          backgroundColor: palette.primary.main,
          color: '#fff',
        },
      },
      outlinedSecondary: {
        color: '#616161',
        border: `1px solid ${palette.secondary.main}`,
        '&:hover': {
          backgroundColor: palette.secondary.main,
          color: '#fff',
          '@media (hover: none)': {
            backgroundColor: palette.secondary.main,
          },
        },
      },
    },
    MuiIcon: {
      root: {
        fontSize: '1.25rem',
      },
    },
    MuiInputAdornment: {
      root: {
        '& .MuiTypography-root': {
          color: palette.textPrimary.main,
        },
      },
    },
    MuiSvgIcon: {
      root: {
        fontSize: '1.25rem',
      },
      fontSizeLarge: {
        fontSize: '2rem',
      },
    },
    MuiTabs: {
      root: {
        borderColor: palette.primary.main,
        color: palette.primary.main,
        minHeight: '38px',
      },
      indicator: {
        backgroundColor: palette.primary.main,
      },
      scrollable: {
        color: palette.primary.main,
      },
    },
    MuiTab: {
      root: {
        flexGrow: 1,
        flexShrink: 1,
        maxWidth: '264px',
        minHeight: '38px',
        minWidth: '100px',
        width: '100%',
      },
    },
    MuiChip: {
      sizeSmall: {
        fontSize: 11,
        height: 18,
      },
      labelSmall: {
        paddingLeft: 12,
        paddingRight: 12,
      },
    },
    MuiTooltip: {
      tooltip: {
        padding: '8px 15px',
        borderRadius: 4,
        backgroundColor: '#16533a',
        fontSize: 13,
        fontWeight: 'normal',
        fontStyle: 'normal',
        fontStretch: 'normal',
        lineHeight: 1.31,
        letterSpacing: 0.4,
        textAlign: 'left',
        color: '#ffffff',
        maxWidth: 230,
      },
    },
    MuiTypography: {
      colorTextSecondary: {
        color: palette.textSecondary.main,
      },
    },
    MuiListItemIcon: {
      root: {
        minWidth: 35,
      },
    },
    MuiFormHelperText: {
      root: {
        lineHeight: '1.3em',
      },
    },
  },
});

export default theme;
