import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Route, Switch } from 'react-router-dom';
import Notifications from 'react-notification-system-redux';

import Dialogs from 'common/components/Dialogs';
import {
  PUBLIC_URL,
  PRIVATE_URL,
  LOGIN_URL,
  SIGN_UP_URL,
} from 'common/utils/constants';
import SideDialogs from 'common/components/SideDialogs';
import PrivatePages from './PrivatePages';
import PublicPages from './PublicPages';

import notificationsStyle from './NotificationsStyle';

class Pages extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount = async () => {
    const { checkLogged } = this.props;

    await checkLogged();

    this.handleNavigationPages();
  };

  handleNavigationPrivatePages(isPrivatePathName) {
    const { location: { pathname, search }, history } = this.props;

    if (isPrivatePathName) {
      return history.replace(`${pathname}${search}`);
    }

    return history.replace(PRIVATE_URL);
  }

  handleNavigationWithRedirection() {
    const { location: { pathname, search }, history } = this.props;

    const query = new URLSearchParams(search);
    const redirectPage = query.has('create_account')
      ? SIGN_UP_URL
      : LOGIN_URL;

    return history.replace(`${redirectPage}?redirect=${encodeURIComponent(`${pathname}${search}`)}`);
  }

  handleNavigationPages() {
    const { location: { pathname, search }, history, logged } = this.props;

    const isPublicPathName = new RegExp(`^${PUBLIC_URL}`).test(pathname);
    const isPrivatePathName = new RegExp(`^${PRIVATE_URL}`).test(pathname);

    if (isPublicPathName) {
      return history.replace(`${pathname}${search}`);
    }

    if (pathname === '/login/redef') {
      return history.replace(`/public/redef${search}`);
    }

    if (logged) {
      return this.handleNavigationPrivatePages(isPrivatePathName);
    }

    if (!logged && isPrivatePathName) {
      return this.handleNavigationWithRedirection();
    }

    return history.replace(PUBLIC_URL);
  }

  render() {
    const { notifications } = this.props;
    return (
      <>
        <Switch>
          <Route path={PRIVATE_URL} render={() => <PrivatePages />} />
          <Route path={PUBLIC_URL} render={() => <PublicPages />} />
        </Switch>
        <Notifications
          notifications={notifications}
          style={notificationsStyle}
        />
        <Dialogs />
        <SideDialogs />
      </>
    );
  }
}

Pages.propTypes = {
  location: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  checkLogged: PropTypes.func.isRequired,
  logged: PropTypes.bool.isRequired,
  notifications: PropTypes.array,
};

Pages.defaultProps = {
  notifications: [],
};

Pages.defaultProps = {
  notifications: [],
};

export default Pages;
