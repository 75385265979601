import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import { loadUserPaymentMethods } from 'common/ducks/user/paymentMethods';

import {
  error as notifierError,
  success as notifierSuccess,
} from 'react-notification-system-redux';

import InvoicePaymentDialog, { INVOICE_PAYMENT_DIALOG } from './InvoicePaymentDialog';

export { INVOICE_PAYMENT_DIALOG };

const styles = (theme) => ({
  wrapper: {
    margin: theme.spacing(1),
    position: 'relative',
  },
  title: {
    fontSize: '1.3125rem',
    fontWeight: 500,
  },
  buttonProgress: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
  paymentContainers: {
    marginBottom: 15,
  },
  label: {
    fontSize: '0.875rem',
    color: 'black',
  },
  logo: {
    textAlign: 'center',
    width: '40px',
    height: 'auto',
    marginRight: '10px',
  },
  loadingIcon: { position: 'absolute', right: '15px', float: 'right' },
});

const StyledStore = withStyles(styles)(InvoicePaymentDialog);

const mapStateToProps = ({ user }) => ({
  paymentMethods: user.paymentMethods,
});

const mapDispatchToProps = (dispatch) => ({
  loadUserPaymentMethods: () => dispatch(loadUserPaymentMethods()),
  notifySuccess: (data) => dispatch(notifierSuccess(data)),
  notifyError: (data) => dispatch(notifierError(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(StyledStore);
