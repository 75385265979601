import React from 'react';
import PropTypes from 'prop-types';
import { Box } from '@material-ui/core';
import accounting from 'accounting';
import classNames from 'classnames';
import StockLogoFinder from 'common/components/StockLogoFinder';
import { CHANGED_STOCKS_VARIANTS } from 'common/utils/constants/stocks';
import { getStatusChangedStock } from 'common/utils/stocks';
import WrappedChip from 'common/components/WrappedChip';

const getWeight = ({ isRightValue = false, stock, reverseColumns }) => {
  if (!isRightValue) {
    return reverseColumns ? stock.weight : stock.oldWeight;
  }

  return reverseColumns ? stock.oldWeight : stock.weight;
};

const { formatMoney } = accounting;

const getValue = ({
  stock,
  reverseColumns,
  equity,
  isCurrencyMode,
  isRightValue,
}) => {
  const weight = getWeight({ isRightValue, stock, reverseColumns });

  if (!isCurrencyMode) {
    return weight;
  }

  return equity * (weight / 100);
};

const ChangedStock = ({
  classes,
  stock,
  variants,
  reverseColumns,
  equity,
  newEquity,
  isCurrencyMode,
}) => {
  const leftValue = getValue({
    stock,
    reverseColumns,
    equity,
    isCurrencyMode,
  });
  const rightValue = getValue({
    stock,
    reverseColumns,
    equity: newEquity,
    isCurrencyMode,
    isRightValue: true,
  });

  const status = getStatusChangedStock(leftValue, rightValue);

  const leftValueWithDecimal = reverseColumns ? leftValue?.toFixed(2) : leftValue;

  return (
    <Box data-testid="changed-stock-box" key={stock.code} className={classes.boxItem}>
      <Box display="flex" alignItems="center">
        <Box className={classes.logo}>
          <StockLogoFinder stock={stock} />
        </Box>
        <Box minWidth={50} ml={1} mr={2}><strong>{stock.code || stock.stock_code}</strong></Box>
        <span>{stock.company_name}</span>
      </Box>

      <Box className={classes.wrapperWeightsChip}>
        <Box className={classes.tagBox} fontWeight={500}>
          <WrappedChip
            label={variants[status].name}
            size="small"
            color={variants[status].tagColor}
          />
        </Box>
        <Box display="flex" alignItems="center">
          <Box
            className={classNames(
              classes.spacing,
              isCurrencyMode && classes.largeSpacing,
            )}
          >
            <strong data-testid="leftValue">
              {isCurrencyMode
                ? `R$ ${formatMoney(leftValue, '', 2)}`
                : `${leftValueWithDecimal}%`}
            </strong>
          </Box>
          <Box>
            <Box
              display="flex"
              style={{ transform: variants[status].rotate }}
            >
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24">
                <path fill="none" d="M0 0h24v24H0z" />
                <path fill={variants[status].color} d="M16.01 11H4v2h12.01v3L20 12l-3.99-4z" />
              </svg>
            </Box>
          </Box>
          <Box
            className={classNames(
              classes.spacing,
              isCurrencyMode && classes.largeSpacing,
            )}
          >
            <strong data-testid="rightValue">
              {isCurrencyMode
                ? `R$ ${formatMoney(rightValue, '', 2)}`
                : `${Math.floor(rightValue)}%`}
            </strong>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

ChangedStock.propTypes = {
  classes: PropTypes.object.isRequired,
  stock: PropTypes.object.isRequired,
  variants: PropTypes.object,
  reverseColumns: PropTypes.bool,
  equity: PropTypes.number,
  newEquity: PropTypes.number,
  isCurrencyMode: PropTypes.bool,
};

ChangedStock.defaultProps = {
  variants: CHANGED_STOCKS_VARIANTS,
  reverseColumns: false,
  equity: null,
  newEquity: null,
  isCurrencyMode: false,
};

export default ChangedStock;
