import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { NavLink } from 'react-router-dom';

import FontAwesomeIcon from '@fortawesome/react-fontawesome';
import faAngleLeft from '@fortawesome/fontawesome-free-solid/faAngleLeft';
import { BACK_TEXT } from 'common/utils/constants';

const BackButton = ({
  containerClassName,
  className,
  classes,
  path,
  param,
  text,
  color,
  fontSize,
}) => (
  <div className={classNames(containerClassName, classes.container)}>
    <NavLink
      className={classNames(
        className,
        classes.root,
        classes[color],
      )}
      to={{
        pathname: path,
        state: param,
      }}
      style={{ fontSize }}
    >
      <FontAwesomeIcon
        className={classes.icon}
        icon={faAngleLeft}
        size="lg"
      />
      {text}
    </NavLink>
  </div>
);

BackButton.propTypes = {
  classes: PropTypes.object.isRequired,
  className: PropTypes.string,
  containerClassName: PropTypes.string,
  fontSize: PropTypes.number,
  path: PropTypes.string.isRequired,
  text: PropTypes.string,
  param: PropTypes.object,
  color: PropTypes.string,
};

BackButton.defaultProps = {
  className: '',
  containerClassName: '',
  fontSize: 20,
  text: BACK_TEXT,
  color: 'textSecondary',
  param: {},
};

export default BackButton;
