import React from 'react';
import PropTypes from 'prop-types';
import { submit } from 'redux-form';
import { connect } from 'react-redux';

const RemoteFormSubmit = ({
  children,
  submitForm,
  formName,
}) => React.cloneElement(children, { onClick: () => submitForm(formName) });

RemoteFormSubmit.propTypes = {
  children: PropTypes.node.isRequired,
  submitForm: PropTypes.func.isRequired,
  formName: PropTypes.string,
};

const mapDispatchToProps = (dispatch) => ({
  submitForm: (formName) => dispatch(submit(formName)),
});

export default connect(
  null,
  mapDispatchToProps,
)(RemoteFormSubmit);
