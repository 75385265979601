import React from 'react';
import PropTypes from 'prop-types';
import emptyChartDefault from 'common/assets/images/empty-chart-default.svg';

const EmptyChartDefault = ({
  alt,
}) => (
  <img
    data-testid="empty-chart-default"
    src={emptyChartDefault}
    alt={alt}
  />
);

EmptyChartDefault.propTypes = {
  alt: PropTypes.string.isRequired,
};

export default EmptyChartDefault;
