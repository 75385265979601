import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Chip from '@material-ui/core/Chip';
import { STORE_INSTANCE_TAGS as TAGS } from 'common/utils/constants/tags';
import { getLabel, getRiskClass } from 'common/utils/tags';

const StoreInstanceTags = ({
  tags,
  classes,
  ranking,
}) => (
  <>
    {!ranking && (
    <div className={classes.tagsContainer}>
      {Object.keys(TAGS).map((tag) => (
        <Chip
          key={tag}
          id={tag}
          data-testid={tag}
          label={getLabel({ item: tag, tags })}
          className={classNames(
            classes.tag, classes.detailsTag, classes[getRiskClass(tags[tag])],
          )}
        />
      ))}
    </div>
    )}

    {ranking && (
    <Chip
      label={tags.risk}
      className={classNames(classes.tag, classes[getRiskClass(tags.risk)])}
    />
    )}
  </>
);

StoreInstanceTags.propTypes = {
  tags: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
  ranking: PropTypes.bool,
};

StoreInstanceTags.defaultProps = {
  ranking: false,
};

export default StoreInstanceTags;
