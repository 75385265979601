import palette from 'common/palette';

const notificationsStyle = {
  Containers: {
    tr: {
      marginTop: 80,
    },
  },
  NotificationItem: {
    DefaultStyle: {
      padding: 20,
      fontFamily: 'Roboto',
    },
    success: {
      borderTop: 'none',
      borderLeft: `6px solid ${palette.gain.main}`,
      backgroundColor: palette.gain.contrastText,
      color: palette.textSecondary.main,
      WebkitBoxShadow: '0 5px 15px rgba(0, 0, 0, 0.3)',
      MozBoxShadow: '0 5px 15px rgba(0, 0, 0, 0.3)',
      boxShadow: '0 5px 15px rgba(0, 0, 0, 0.3)',
    },
    error: {
      borderTop: 'none',
      borderLeft: `6px solid ${palette.danger.main}`,
      backgroundColor: palette.danger.contrastText,
      color: palette.textSecondary.main,
      WebkitBoxShadow: '0 5px 15px rgba(0, 0, 0, 0.3)',
      MozBoxShadow: '0 5px 15px rgba(0, 0, 0, 0.3)',
      boxShadow: '0 5px 15px rgba(0, 0, 0, 0.3)',
    },
    warning: {
      borderTop: 'none',
      borderLeft: `6px solid ${palette.attention.main}`,
      backgroundColor: palette.attention.contrastText,
      color: palette.textSecondary.main,
      WebkitBoxShadow: '0 5px 15px rgba(0, 0, 0, 0.3)',
      MozBoxShadow: '0 5px 15px rgba(0, 0, 0, 0.3)',
      boxShadow: '0 5px 15px rgba(0, 0, 0, 0.3)',
    },
    info: {
      borderTop: 'none',
      borderLeft: `6px solid ${palette.moody.main}`,
      backgroundColor: palette.moody.contrastText,
      color: palette.textSecondary.main,
      WebkitBoxShadow: '0 5px 15px rgba(0, 0, 0, 0.3)',
      MozBoxShadow: '0 5px 15px rgba(0, 0, 0, 0.3)',
      boxShadow: '0 5px 15px rgba(0, 0, 0, 0.3)',
    },
  },
  Title: {
    DefaultStyle: {
      fontWeight: 600,
    },
  },
  Dismiss: {
    DefaultStyle: {
      top: '8px',
      right: '8px',
      backgroundColor: palette.textSecondary.light,
    },
    success: {
      color: palette.textSecondary.contrastText,
      backgroundColor: palette.textSecondary.light,
    },
    error: {
      color: palette.textSecondary.contrastText,
      backgroundColor: palette.textSecondary.light,
    },
    warning: {
      color: palette.textSecondary.contrastText,
      backgroundColor: palette.textSecondary.light,
    },
    info: {
      color: palette.textSecondary.contrastText,
      backgroundColor: palette.textSecondary.light,
    },
  },
  Action: {
    DefaultStyle: {
      background: 'transparent',
      padding: 0,
      fontWeight: '600',
      margin: 0,
      cursor: 'pointer',
      textTransform: 'uppercase',
      fontSize: 13,
    },
    success: {
      backgroundColor: 'transparent',
      color: palette.primary.main,
    },
    error: {
      backgroundColor: 'transparent',
      color: palette.textSecondary.light,
    },
    warning: {
      backgroundColor: 'transparent',
      color: palette.textSecondary.light,
    },
    info: {
      backgroundColor: 'transparent',
      color: palette.textSecondary.light,
    },
  },
  ActionWrapper: {
    DefaultStyle: {
      float: 'right',
    },
  },
};

export default notificationsStyle;
