import api from 'common/api';
import {
  createBacktest,
  getAdjustedStocksData,
  orderStocksByName,
  removeUnavailableStocks,
} from 'common/utils/stocks';
import { getBusinessDay } from 'common/utils/date';
import { DATETIME_FORMAT, ISO_DATE_FORMAT } from 'common/utils/constants/date';
import { validateStockCode } from 'common/utils/validation';
import { isHolidayOrWeekendDay } from './holidays';
import { LOGOUT_SUCCEED } from './auth';

export const DUCK_NAME = 'stocks';
export const INITIAL_STATE = {
  loading: false,
  isDataReady: false,
  credentials: null,
  searchResult: null,
  lastSearchedTerm: '',
  error: null,
  loadingCandles: false,
  stocksCandle: {
    cachedStocks: {},
  },
  validStocks: {},
  backtests: {},
  allStocks: {},
};

export const GET_CREDENTIALS_STARTED = `${DUCK_NAME}/GET_CREDENTIALS_STARTED`;
export const GET_CREDENTIALS_SUCCEED = `${DUCK_NAME}/GET_CREDENTIALS_SUCCEED`;
export const GET_CREDENTIALS_FAILED = `${DUCK_NAME}/GET_CREDENTIALS_FAILED`;
export const getCredentialsStarted = () => ({
  type: GET_CREDENTIALS_STARTED,
});
export const getCredentialsSucceed = (credentials) => ({
  type: GET_CREDENTIALS_SUCCEED,
  credentials,
});
export const getCredentialsFailed = (error) => ({
  type: GET_CREDENTIALS_FAILED,
  error,
});
export const getCredentials = ({
  options = {
    reload: false,
  },
} = {}) => async (dispatch, getState) => {
  const { stocks } = getState();

  if (!options.reload && stocks?.credentials) {
    return Promise.resolve(stocks.credentials);
  }

  try {
    dispatch(getCredentialsStarted());
    const { data } = await api.chart.getCredentials();
    dispatch(getCredentialsSucceed(data));
    return Promise.resolve(data);
  } catch (error) {
    dispatch(getCredentialsFailed(error));
    return Promise.reject(error);
  }
};

export const GET_STOCK_CANDLE_STARTED = `${DUCK_NAME}/GET_STOCK_CANDLE_STARTED`;
export const GET_STOCK_CANDLE_SUCCEED = `${DUCK_NAME}/GET_STOCK_CANDLE_SUCCEED`;
export const GET_STOCK_CANDLE_FAILED = `${DUCK_NAME}/GET_STOCK_CANDLE_FAILED`;
export const getStockCandleStarted = () => ({
  type: GET_STOCK_CANDLE_STARTED,
});
export const getStockCandleSucceed = ({ backtestType, data, backtest }) => ({
  type: GET_STOCK_CANDLE_SUCCEED,
  backtestType,
  data,
  backtest,
});
export const getStockCandleFailed = (error) => ({
  type: GET_STOCK_CANDLE_FAILED,
  error,
});

export const getStocksCandle = ({
  backtestType,
  stocks,
  startDate,
  endDate,
}) => async (dispatch, getState) => {
  const { stocksCandle } = getState()[DUCK_NAME];
  dispatch(getStockCandleStarted());

  try {
    const startDateIsoFormat = startDate.format(ISO_DATE_FORMAT);
    const endDateIsoFormat = endDate.format(DATETIME_FORMAT);
    const startDateWorkday = dispatch(isHolidayOrWeekendDay(new Date(startDate)))
      ? getBusinessDay({
        baseDate: startDateIsoFormat,
        format: ISO_DATE_FORMAT,
        mode: 'previous',
        isHolidayOrWeekendDay: (date) => dispatch(isHolidayOrWeekendDay(date)),
      })
      : startDate.format(DATETIME_FORMAT);

    const credentials = await dispatch(getCredentials());
    const promises = Object.keys(stocks).map(async (stockCode) => {
      if (!stocksCandle.cachedStocks[stockCode]) {
        const { data } = await api.chart.getStockCandle(stockCode, {
          params: {
            ...credentials,
            adjusted: true,
            start_datetime: startDateWorkday,
            end_datetime: endDateIsoFormat,
          },
        });

        return data;
      }

      return stocksCandle.cachedStocks[stockCode].data;
    });

    const data = await Promise.all(promises)
      .then((result) => {
        return Object.keys(stocks).reduce((acc, stock, index) => ({
          ...acc,
          [stock]: {
            data: result[index],
            code: stocks[stock].code,
            weight: stocks[stock].weight,
          },
        }), {});
      });

    const adjustedData = getAdjustedStocksData({ data });
    const backtest = createBacktest(
      Object.values(adjustedData),
      (date) => dispatch(isHolidayOrWeekendDay(date)),
    );
    backtest.reverse();

    const finalData = { backtestType, data, backtest };

    dispatch(getStockCandleSucceed(finalData));
    return Promise.resolve(finalData);
  } catch (error) {
    dispatch(getStockCandleFailed(error));
    return Promise.reject(error);
  }
};

export const SET_BACKTEST_DATA = `${DUCK_NAME}/SET_BACKTEST_DATA`;
export const setBacktestData = ({ backtestType, data, backtest }) => ({
  type: SET_BACKTEST_DATA,
  backtestType,
  data,
  backtest,
});

export const GET_STOCK_SUCCEED = `${DUCK_NAME}/GET_STOCK_SUCCEED`;
export const GET_STOCK_FAILED = `${DUCK_NAME}/GET_STOCK_FAILED`;
export const getStockSucceed = (data) => ({
  type: GET_STOCK_SUCCEED,
  data,
});
export const getStockFailed = (error) => ({
  type: GET_STOCK_FAILED,
  error,
});
export const getStock = ({ stockCode, credentials = {} }) => async (
  dispatch,
  getState) => {
  const currentState = getState()[DUCK_NAME];
  const storedStock = currentState?.allStocks[stockCode];
  if (storedStock) {
    return Promise.resolve(storedStock);
  }

  try {
    const credentialsObj = {
      ...credentials,
      ...currentState.credentials,
    };

    const { data } = await api.chart.getStockCode(stockCode, {
      params: { ...credentialsObj, trading: true },
    });

    const stock = data.length ? data[0] : {};
    dispatch(getStockSucceed(stock));
    return Promise.resolve(stock);
  } catch (error) {
    dispatch(getStockFailed(error));
    return Promise.reject(error);
  }
};

export const GET_STOCKS_STARTED = `${DUCK_NAME}/GET_STOCKS_STARTED`;
export const GET_STOCKS_SUCCEED = `${DUCK_NAME}/GET_STOCKS_SUCCEED`;
export const GET_STOCKS_FAILED = `${DUCK_NAME}/GET_STOCKS_FAILED`;
export const getStocksStarted = () => ({
  type: GET_STOCKS_STARTED,
});
export const getStocksSucceed = (data, search) => ({
  type: GET_STOCKS_SUCCEED,
  data,
  search,
});
export const getStocksFailed = (error) => ({
  type: GET_STOCKS_FAILED,
  error,
});
export const getStocks = (searchTerm, limitStocks) => async (dispatch, getState) => {
  const currentState = getState()[DUCK_NAME];
  if (searchTerm === currentState.lastSearchedTerm) {
    return Promise.resolve(currentState.searchResult);
  }

  dispatch(getStocksStarted());
  try {
    const credentials = await dispatch(getCredentials());
    const { data } = await api.chart.getStockCode(searchTerm, {
      params: { ...credentials, trading: true },
    });
    let availableStocks = removeUnavailableStocks(data);

    if (limitStocks && availableStocks.length > 5) {
      availableStocks = availableStocks.splice(0, 5);
    }

    const stocksOrderedByName = orderStocksByName(availableStocks);
    dispatch(getStocksSucceed(stocksOrderedByName, searchTerm));
    return Promise.resolve(availableStocks);
  } catch (error) {
    dispatch(getStocksFailed(error));
    return Promise.reject(error);
  }
};

export const CHECK_VALIDATE_STOCK_SUCCEED = `${DUCK_NAME}/CHECK_VALIDATE_STOCK_SUCCEED`;
export const checkValidateStockCodeSucceed = (data) => {
  return {
    type: CHECK_VALIDATE_STOCK_SUCCEED,
    data,
  };
};
export const checkValidateStockCode = (stockCode, dateString) => async (dispatch) => {
  try {
    const {
      trading_lot_size,
      stockCodeError,
      dateError,
      expirationDate,
    } = await validateStockCode(stockCode, dateString);

    if (stockCodeError) {
      throw new Error(stockCodeError);
    }

    const result = {
      [stockCode]: {
        trading_lot_size,
      },
    };

    dispatch(checkValidateStockCodeSucceed(result));

    return Promise.resolve({
      trading_lot_size,
      stockCodeError,
      dateError,
      expirationDate,
    });
  } catch (error) {
    return Promise.resolve({
      stockCodeError: error.message,
    });
  }
};

export const CLEAR_STATE = `${DUCK_NAME}/CLEAR_STATE`;
export const clearSearch = () => ({
  type: CLEAR_STATE,
});

export default (state = INITIAL_STATE, action) => {
  // Logout cleaning
  if (action.type === LOGOUT_SUCCEED) {
    return INITIAL_STATE;
  }

  const currentBacktestType = state.backtests[action.backtestType];
  const stateData = currentBacktestType
    ? currentBacktestType.data
    : {};

  switch (action.type) {
    case GET_CREDENTIALS_STARTED:
      return {
        ...state,
        loading: true,
        isDataReady: false,
      };
    case GET_CREDENTIALS_SUCCEED:
      return {
        ...state,
        loading: false,
        credentials: action.credentials,
      };
    case GET_CREDENTIALS_FAILED:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    case GET_STOCK_CANDLE_STARTED:
      return {
        ...state,
        loadingCandles: true,
      };
    case GET_STOCK_CANDLE_SUCCEED:
      return {
        ...state,
        loadingCandles: false,
        backtests: {
          ...state.backtests,
          [action.backtestType]: {
            data: action.data,
            backtest: action.backtest,
          },
        },
        stocksCandle: {
          cachedStocks: {
            ...state.stocksCandle.cachedStocks,
            ...stateData,
            ...action.data,
          },
        },
      };
    case GET_STOCK_CANDLE_FAILED:
      return {
        ...state,
        loadingCandles: false,
        error: action.error,
      };
    case SET_BACKTEST_DATA:
      return {
        ...state,
        loadingCandles: false,
        backtests: {
          ...state.backtests,
          [action.backtestType]: {
            data: action.data,
            backtest: action.backtest,
          },
        },
      };
    case GET_STOCKS_STARTED:
      return {
        ...state,
        loading: true,
      };
    case GET_STOCKS_SUCCEED:
      return {
        ...state,
        loading: false,
        isDataReady: true,
        searchResult: action.data,
        lastSearchedTerm: action.search,
      };
    case GET_STOCKS_FAILED:
      return {
        ...state,
        loading: false,
        error: action.error,
      };

    case GET_STOCK_SUCCEED:
      return {
        ...state,
        allStocks: {
          ...state.allStocks,
          [action.data.code]: action.data,
        },
      };

    case GET_STOCK_FAILED:
      return {
        ...state,
        error: action.error,
      };

    case CHECK_VALIDATE_STOCK_SUCCEED: {
      return {
        ...state,
        validStocks: {
          ...state.validStocks,
          ...action.data,
        },
      };
    }
    case CLEAR_STATE:
      return {
        ...state,
        loading: false,
        isDataReady: false,
        credentials: state.credentials,
        searchResult: null,
        lastSearchedTerm: '',
        error: null,
      };
    default:
      return state;
  }
};
