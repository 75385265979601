import palette from 'common/palette';

export const NEXT_ROLLOUT = 'next_rollout';
export const NUMBER_OF_STOCKS = 'number_of_stocks';
export const ORDER_VOLUME = 'order_volume';

export const STORE_INSTANCE_TAGS = {
  risk: {
    text: 'Risco',
    position: 'left',
  },
  frequency: {
    text: 'Frequência',
    position: 'left',
  },
  minimum: {
    text: 'Mínimo de R$',
    position: 'left',
  },
  number_of_stocks: {
    text: 'ativos',
    position: 'right',
  },
  order_volume: {
    text: 'Volume de ordens',
    position: 'left',
  },
  benchmark: {
    text: 'Benchmark',
    position: 'left',
  },
  next_rollout: {
    text: 'Próxima rolagem',
    position: 'left',
  },
};

export const CHIP_COLORS = {
  blue: {
    main: 'rgba(2, 90, 185, 0.17)',
    contrastText: '#025ab9',
  },
  success: {
    main: 'rgba(0, 255, 109, 0.17)',
    contrastText: palette.primary.main,
  },
  info: {
    main: '#fff3d3',
    contrastText: '#e8b21d',
  },
  error: {
    main: 'rgba(233, 48, 86, 0.21)',
    contrastText: palette.secondary.main,
  },
  default: {
    main: 'rgba(21, 21, 21, 0.17)',
    contrastText: '#5e5e5e',
  },
};
