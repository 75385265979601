import React from 'react';
import moment from 'moment';
import { Cell } from 'recharts';
import { CHECK_ALL } from './constants';
import { getValidNumberInput } from './slider';
import { getPositionCompany } from './positions';
import {
  getCurrentDate,
  setSpecificDate,
  getBusinessDay,
} from './date';
import { getUniqueItemsArray } from './array';
import { ISO_DATE_FORMAT } from './constants/date';
import {
  DEFAULT_CHANGE_PORTFOLIO_DATE,
  CHANGE_PORTFOLIO_DATE,
  FORCE_CHANGE_PORTFOLIO_DATE,
} from './constants/instance';
import { WHITEBOX_STRATEGY_IDS } from './constants/whitebox';

export const getSelectedItems = (items) => items.filter((item) => item.checked);

const getStartAndEndStocksCompare = ({
  instanceStocks = [], whiteboxStocks = [],
}) => {
  return instanceStocks.length >= whiteboxStocks.length
    ? [instanceStocks, whiteboxStocks]
    : [whiteboxStocks, instanceStocks];
};

export const getIsStocksEquals = ({ instanceStocks = [], whiteboxStocks = [] } = {}) => {
  const [startStocks, endStocks] = getStartAndEndStocksCompare({
    instanceStocks,
    whiteboxStocks,
  });

  return startStocks.every((whiteboxStock) => (
    endStocks.some((instanceStock) => {
      if (instanceStock.code === whiteboxStock.code) {
        return instanceStock.weight === whiteboxStock.weight;
      }

      return false;
    })));
};

export const getUpdatedStocksList = ({ checked, itemName, listStocks }) => {
  return itemName === CHECK_ALL
    ? listStocks.map((item) => ({ ...item, checked }))
    : listStocks.map((item) => (item.code === itemName ? { ...item, checked } : item));
};

export const getCheckAllValue = ({ checked, itemName, listStocks }) => {
  return itemName === CHECK_ALL ? checked : listStocks.every((item) => item.checked);
};

export const setWeightStock = ({
  stocks,
  currentStock,
  newValue,
  prop,
  minValue,
  maxValue,
}) => {
  return stocks.map((item) => {
    if (item.code === currentStock.code) {
      item[prop] = getValidNumberInput({ value: newValue, minValue, maxValue });
    }

    return item;
  });
};

export const renderCellComponent = (items, colors) => {
  return items.map((item, index) => (
    <Cell key={item.code || item.stock || index} fill={colors[index % colors.length]} />
  ));
};

export const isAnyChecked = (stockList) => {
  return stockList.some((stock) => stock.checked);
};

export const getCheckedStocks = (stockList, checkAll) => {
  if (checkAll) {
    return stockList;
  }
  return stockList.filter((stock) => stock.checked);
};

export const getInstanceStocks = ({
  stockList,
  weightList,
  lotSizes,
  oldWeightList = [],
  oldStockList = [],
}) => {
  return stockList.reduce((acc, cur, index) => {
    const { company_name } = getPositionCompany(cur);

    const indexStockOnOldList = oldStockList.findIndex((oldStock) => oldStock === cur);

    const oldWeightObj = oldWeightList.length
      ? {
        oldWeight: indexStockOnOldList !== -1
          ? Number(oldWeightList[indexStockOnOldList])
          : 0,
      }
      : {};

    return {
      ...acc,
      [cur]: {
        code: cur,
        company_name,
        weight: Number(weightList[index]),
        trading_lot_size: lotSizes ? Number(lotSizes[index]) : null,
        minWeight: 10,
        maxWeight: 40,
        ...oldWeightObj,
      },
    };
  }, {});
};

export const isStockInWallet = (stockList, stock) => {
  return Boolean(stockList.find((item) => item.code === stock.code));
};

export const removeCheckedStocks = (stockList, checkAll) => {
  const checkedStocks = getCheckedStocks(stockList, checkAll);
  return stockList.filter((stock) => {
    return checkedStocks.indexOf(stock) === -1;
  });
};

export const isAllItemsGreaterThanZero = (items) => {
  return Object.values(items).every((item) => item.weight > 0);
};

export const getIsRebalanceButtonActive = ({ stocks, totalWeight }) => {
  const isItemsGreaterZero = isAllItemsGreaterThanZero(stocks);

  return stocks.length > 0 && totalWeight <= 100 && isItemsGreaterZero;
};

export const getRebalanceButtonTooltip = ({ instanceStocks, whiteboxStocks, totalWeight }) => {
  if (!getIsRebalanceButtonActive({ stocks: whiteboxStocks, totalWeight })) {
    return 'Confira os valores preenchidos na carteira';
  }

  return getIsStocksEquals({ instanceStocks, whiteboxStocks })
    ? 'Rebalancear a carteira com os mesmos ativos'
    : 'Realizar as mudanças de ativos na carteira';
};

export const isAnyWeightZero = (stockList) => {
  return Boolean(stockList.find((stock) => !stock.weight));
};

export const shouldBeAbleToFollow = (stockList, totalWeight) => {
  return stockList.length > 0 && totalWeight === 100 && !isAnyWeightZero(stockList);
};

export const getNewValueInput = (newValue) => {
  if (Number(newValue) > 100) return 100;

  return newValue === '' ? 0 : Number(newValue);
};

export const getStockLotSize = async (stockList, getStocks) => {
  const stocksWithoutLotSize = stockList.filter((stock) => !stock.trading_lot_size)
    .map((stock) => stock.code);

  const marketDataResponse = await Promise.all(stocksWithoutLotSize.map(getStocks))
    .then((stocks) => {
      return stocks.reduce((acc, [value]) => {
        return { ...acc, [value.code]: value.trading_lot_size };
      }, {});
    });

  return stockList.map((stock) => ({
    ...stock,
    trading_lot_size: marketDataResponse[stock.code] || stock.trading_lot_size,
  }));
};

export const shouldIncludeFractionCode = (array, stock) => {
  if (Number(stock.trading_lot_size) > 1) {
    return [...array, stock.code || stock.stock];
  }
  return array;
};

export const getStocksWeightsAndLots = (stockList) => {
  return stockList.reduce((acc, stock) => ({
    stocks: [...acc.stocks, stock.code],
    weights: [...acc.weights, stock.weight],
    lotSizes: [...acc.lotSizes, stock.trading_lot_size],
    includesFractionStocks: shouldIncludeFractionCode(acc.includesFractionStocks, stock),
  }), {
    stocks: [],
    weights: [],
    lotSizes: [],
    includesFractionStocks: [],
  });
};

export const getFullAndFractionCodes = (stocks) => {
  const fullStocks = stocks.map((stock) => stock.replace(/F$/, ''));
  const uniqueFullStocks = getUniqueItemsArray(fullStocks);

  return uniqueFullStocks.map((stock) => {
    const fractionStock = `${stock}F`;

    return [stock, fractionStock];
  }).reduce((acc, stockPair) => {
    return [...acc, ...stockPair];
  }, []);
};

export const getChangePortfolioDate = (isHolidayOrWeekendDay) => {
  const currentDate = getCurrentDate();

  if (isHolidayOrWeekendDay(currentDate)) {
    return getBusinessDay({ format: ISO_DATE_FORMAT, isHolidayOrWeekendDay });
  }

  const maxTime = setSpecificDate({ hours: 15, minutes: 25, seconds: 0 });

  if (currentDate.valueOf() > maxTime.valueOf()) {
    return getBusinessDay({ format: ISO_DATE_FORMAT, isHolidayOrWeekendDay });
  }

  return moment(currentDate).format(ISO_DATE_FORMAT);
};

export const getPositionsLotSize = async (positions, stocks, getStocks) => {
  const stockSet = new Set(stocks);

  const stocksToBeFractionated = await Promise.all(positions.reduce((acc, position) => {
    if (!stockSet.has(position)) {
      return [...acc, getStocks(position)];
    }
    return acc;
  }, [])).then((result) => result.map((stockArray) => stockArray[0]));

  return stocksToBeFractionated;
};

export const getStocksWithoutFractional = (stocks) => {
  return stocks.filter((stock) => Number(stock.trading_lot_size) === 1);
};

export const getFullAndFractionalStocksList = (stocksWithLotSize) => {
  const shouldGetFullAndFractionCode = stocksWithLotSize.reduce((acc, stock) => {
    return shouldIncludeFractionCode(acc, stock);
  }, []);

  const fullAndFractionCodes = getFullAndFractionCodes(shouldGetFullAndFractionCode);
  const stocksWithoutFractional = getStocksWithoutFractional(stocksWithLotSize)
    .map((stock) => stock.stock || stock.code);

  return getUniqueItemsArray([
    ...stocksWithoutFractional,
    ...fullAndFractionCodes,
  ]);
};

export const getPositionsStockCodesList = async (stocks, positions, getStocks) => {
  const positionsWithLotSize = await getPositionsLotSize(positions, stocks, getStocks);

  const fullAndFractionCodes = getFullAndFractionalStocksList(positionsWithLotSize);
  return getUniqueItemsArray([...positions, ...fullAndFractionCodes]);
};

export const getDataInstanceParamName = (nextPortfolioDateChange) => {
  const currentDate = getCurrentDate();
  const minTime = setSpecificDate({ hours: 10, minutes: 0, seconds: 0 });
  const earlyChange = currentDate.valueOf() < minTime.valueOf();
  const formattedCurrentDate = moment(currentDate).format(ISO_DATE_FORMAT);

  return nextPortfolioDateChange === formattedCurrentDate && !earlyChange
    ? FORCE_CHANGE_PORTFOLIO_DATE
    : CHANGE_PORTFOLIO_DATE;
};

export const getUpdateInstanceData = async ({
  instanceParams = {},
  stockList,
  positions = [],
  isRebalance = false,
  getStocks = () => {},
  isHolidayOrWeekendDay,
}) => {
  const stockListWithLotSizes = await getStockLotSize(stockList, getStocks);

  const PORTFOLIO_number_of_assets = stockList.length.toString();
  const {
    stocks,
    weights,
    lotSizes,
    includesFractionStocks,
  } = getStocksWeightsAndLots(stockListWithLotSizes);

  const positionsStockCodesList = await getPositionsStockCodesList(stocks, positions, getStocks);

  const fullAndFractionCodes = getFullAndFractionCodes(includesFractionStocks);

  const stockCodesList = getUniqueItemsArray([
    ...stocks,
    ...fullAndFractionCodes,
    ...positionsStockCodesList,
  ]).toString();

  const nextPortfolioDateChange = isRebalance
    ? getChangePortfolioDate(isHolidayOrWeekendDay)
    : DEFAULT_CHANGE_PORTFOLIO_DATE;
  const dataInstanceParamName = getDataInstanceParamName(nextPortfolioDateChange);
  return {
    PORTFOLIO_number_of_assets,
    PORTFOLIO_min_operation_value: instanceParams.PORTFOLIO_min_operation_value,
    PORTFOLIO_stocks: stocks.toString(),
    PORTFOLIO_weights: weights.toString(),
    stockCodesList,
    lot_sizes: lotSizes.toString(),
    force_mount_portfolio: true,
    [dataInstanceParamName]: nextPortfolioDateChange,
  };
};

export const isWhiteboxStrategy = (strategyId) => WHITEBOX_STRATEGY_IDS.includes(strategyId);

export const getStocksAndWeightsFromWhitebox = (whitebox) => {
  const stockList = Object.keys(whitebox);
  const weightList = Object.values(whitebox).map((weights) => weights.weight);

  return { stockList, weightList };
};
