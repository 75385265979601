import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import _ from 'lodash';

import { makeStyles } from '@material-ui/styles';
import theme from 'common/theme';

const useStyles = makeStyles(() => ({
  steps: {
    display: 'inline-block',
  },
  activeStep: {
    backgroundColor: `${theme.palette.primary.main} !important`,
  },
  defaultInactiveStep: {
    border: `1px solid ${theme.palette.primary.main}`,
  },
  secondaryInactiveStep: {
    backgroundColor: '#B3B3B3',
  },
  stepDot: {
    borderRadius: '50%',
    display: 'inline-block',
    verticalAlign: 'middle',
  },
  defaultSize: {
    width: 15,
    height: 15,

    '&:not(:first-child)': {
      marginLeft: 10,
    },
  },

  smallSize: {
    width: 6,
    height: 6,

    '&:not(:first-child)': {
      marginLeft: 5,
    },
  },
}));

const getStepClasses = ({ classes, size, inactiveStep }) => {
  const sizeClasses = {
    default: classes.defaultSize,
    small: classes.smallSize,
  };

  const inactiveStepClasses = {
    default: classes.defaultInactiveStep,
    secondary: classes.secondaryInactiveStep,
  };

  return {
    size: sizeClasses[size] || sizeClasses.default,
    inactiveStep: inactiveStepClasses[inactiveStep] || inactiveStepClasses.default,
  };
};

const Steps = ({
  currentStep,
  numberOfSteps,
  className,
  inactiveStep,
  size,
  markAsActiveStepsPassed,
}) => {
  const classes = useStyles();
  const stepClasses = getStepClasses({ classes, size, inactiveStep });

  return (
    <div
      className={classNames([
        className,
        classes.steps,
      ])}
    >
      {
        _.range(1, numberOfSteps + 1).map((step) => {
          const isActiveStep = markAsActiveStepsPassed
            ? currentStep >= step
            : currentStep === step;

          return (
            <div
              key={`step-${step}`}
              className={
              classNames([
                classes.stepDot,
                stepClasses.size,
                isActiveStep
                  ? classes.activeStep
                  : stepClasses.inactiveStep,
              ])
            }
            />
          );
        })
      }
    </div>
  );
};

Steps.propTypes = {
  currentStep: PropTypes.number.isRequired,
  numberOfSteps: PropTypes.number.isRequired,
  className: PropTypes.string,
  size: PropTypes.oneOf(['default', 'small']),
  inactiveStep: PropTypes.oneOf(['default', 'secondary']),
  markAsActiveStepsPassed: PropTypes.bool,
};

Steps.defaultProps = {
  className: '',
  size: 'default',
  inactiveStep: 'default',
  markAsActiveStepsPassed: false,
};

export default Steps;
