const backtestErrorInterpreter = (code, profile) => {
  switch (code) {
    case 'backtest0':
      return 'Este backtest não existe';
    case 'backtest1':
      return 'Essa estratégia não está mais disponível em sua conta.';
    case 'backtest2':
      return 'Não foi possível processar esta requisição.';
    case 'backtest5':
      return 'O capital do backtest é inválido. Por favor, tente novamente.';
    case 'backtest6':
      return 'Você atingiu o máximo de backtests em execução. Espere a finalização deles para criar outro.';
    case 'backtest11':
      return 'O custo operacional é inválido. Por favor, tente novamente.';
    case 'backtest12':
      return 'O nome do backtest é inválido. Por favor, tente novamente.';
    case 'backtest13':
      return 'A data inicial do backtest é inválida. Por favor, tente novamente.';
    case 'backtest14':
      return 'A data final do backtest é inválida. Por favor, tente novamente.';
    case 'backtest_max_total':
      return `Você atingiu o máximo de backtests permitido em sua conta (${profile.backtest_limit}).`;
    case 'backtest_max_concurrent':
      return `Você já possui ${profile.backtest_running_limit} backtests em processamento. Aguarde suas finalizações para criar outro backtest.`;
    case 'backtest_strategy_not_supported':
      return 'A criação do backtest não é permitida para essa estratégia.';
    default:
      return null;
  }
};

export default backtestErrorInterpreter;
