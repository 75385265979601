import { withStyles } from '@material-ui/core/styles';
import CurrencyField from './CurrencyField';

const styles = {
  inputMargin: {
    marginTop: 5,
    color: '#b4b6b8',
  },
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
  },
  label: {
    fontSize: 16,
  },
  adornment: { fontSize: 16 },
};

export default withStyles(styles)(CurrencyField);
