import accounting from 'accounting';
import master from 'common/assets/images/credit_cards/master.png';
import amex from 'common/assets/images/credit_cards/american_express.png';
import diners from 'common/assets/images/credit_cards/diners.png';
import elo from 'common/assets/images/credit_cards/elo.png';
import visa from 'common/assets/images/credit_cards/visa.png';
import maestro from 'common/assets/images/credit_cards/maestro.png';
import mastercard from 'common/assets/images/credit_cards/mastercard.png';
import discover from 'common/assets/images/credit_cards/discover.png';
import api from 'common/api';

const creditCardsImage = {
  master,
  diners,
  dinersclub: diners,
  amex,
  americanexpress: amex,
  elo,
  visa,
  maestro,
  mastercard,
  discover,
};

const creditCardsName = {
  master: 'Mastercard',
  dinersclub: 'Diners Club',
  americanexpress: 'American Express',
  elo: 'Elo',
  visa: 'Visa',
  maestro: 'Maestro',
  mastercard: 'Mastercard',
  discover: 'Discover',
};

export const getCreditCardImage = (brand) => {
  const brandEscaped = brand.replace(/([^A-Za-z])/g, '').toLowerCase();
  return creditCardsImage[brandEscaped];
};

export const getCreditCardName = (brand) => {
  const brandEscaped = brand.replace(/([^A-Za-z])/g, '').toLowerCase();
  return creditCardsName[brandEscaped];
};

export const renderInstallmentsDescribe = (installmentsValue, price, extraPrice) => {
  const value = accounting.formatMoney(
    Number.parseFloat(price / 100 / installmentsValue),
    '',
  );

  let response = `${installmentsValue}x de R$ ${value}`;

  if (extraPrice) {
    response += ` + 1x de R$ ${accounting
      .formatMoney(Number.parseFloat(extraPrice / 100), '')}`;
  }

  return response;
};

export const generateInstallments = (price, maxInstallments = 1, extraPrice) => {
  const response = [];
  const installments = maxInstallments || 1;

  for (let i = 1; i <= installments; i += 1) {
    response.push({
      id: i,
      name: renderInstallmentsDescribe(
        i,
        price,
        extraPrice,
      ),
    });
  }

  return response;
};

export const validateCardData = async (cardId, checked) => {
  const validateError = {
    codeMessage: null,
  };
  if (!cardId) {
    validateError.codeMessage = 'Você deve selecionar um cartão de crédito.';
  }
  if (!checked) {
    validateError.codeMessage = 'Você deve aceitar a condição.';
  }
  if (validateError.codeMessage) {
    return Promise.reject(validateError);
  }
  return null;
};

const validateInstallments = async (installments) => {
  const validateError = {
    codeMessage: null,
  };
  if (!installments && installments < 1) {
    validateError.codeMessage = 'Você deve selecionar um cartão de crédito.';
  }
  if (validateError.codeMessage) {
    return Promise.reject(validateError);
  }
  return null;
};

export const makeAnInvoicePayment = async (
  invoiceid,
  cardid,
  plots,
  checked,
) => {
  let cardValidation = await validateCardData(cardid, checked);
  if (!cardValidation) {
    cardValidation = await validateInstallments(plots);
  }

  if (cardValidation) return Promise.reject(cardValidation);

  try {
    const response = await api.invoices.chargeInvoice(invoiceid, {
      payment_method: cardid,
      months: plots,
      update_payment_method: true,
    });
    return Promise.resolve(response);
  } catch (error) {
    return Promise.reject(error);
  }
};

export default null;
