import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { error as notifyError, success as notifySuccess } from 'react-notification-system-redux';
import {
  InputAdornment,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
  Input,
  Toolbar,
  IconButton,
  DialogContentText,
  FormHelperText,
  CircularProgress,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import api from 'common/api';
import { INVALID_PASSWORD_TEXT, ERROR } from 'common/utils/constants';
import { isLoginError } from 'common/utils/validation';

import FontAwesomeIcon from '@fortawesome/react-fontawesome';
import faLock from '@fortawesome/fontawesome-free-solid/faLock';

export const TOKEN_2FA_DIALOG_CODE = 'TOKEN_2FA_DIALOG_CODE';

const generateNewToken = (override = false) => api.auth.generateToken2FA(override);

const Token2FADialog = ({
  classes,
  open,
  onClose,
  onCancelClick,
  onConfirmClick,
  email,
}) => {
  const dispatch = useDispatch();
  const [token, setToken] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);

  useEffect(() => {
    // Generate token if the user does not yet have one
    (async () => {
      try {
        const { data } = await generateNewToken();

        if (data.generated) {
          dispatch(notifySuccess({
            title: 'Sucesso!',
            message: `Seu código de verificação foi enviado para o e-mail ${email}`,
          }));
        }
      } catch (err) {
        dispatch(notifyError({
          title: 'Erro!',
          message: err.message,
        }));
      }
    })();
  }, [email, dispatch]);

  const handleInputChange = (event) => setToken(event.target.value);

  const handleConfirmButton = async () => {
    if (onConfirmClick) {
      try {
        setIsLoading(true);
        const { data } = await api.auth.validateToken2FA({ token2fa: token });

        if (data.valid) {
          onConfirmClick(token);
          setIsError(false);
        } else {
          setIsError(true);
        }
      } catch (error) {
        if (isLoginError(error)) {
          dispatch(notifyError({
            title: ERROR,
            message: INVALID_PASSWORD_TEXT,
          }));
        } else {
          dispatch(notifyError({
            title: ERROR,
            message: error.message,
          }));
        }
      } finally {
        setIsLoading(false);
      }
    } else {
      onClose();
    }
  };

  const handleResendToken = async () => {
    try {
      await generateNewToken(true);
      dispatch(notifySuccess({
        title: 'Sucesso!',
        message: `Seu código de verificação foi enviado para o e-mail colocar email ${email}`,
      }));
    } catch (err) {
      dispatch(notifyError({
        title: 'Erro!',
        message: err.message,
      }));
    }
  };

  return (
    <Dialog
      disableBackdropClick
      open={open}
      onClose={onCancelClick ? () => onCancelClick(onClose) : onClose}
      PaperProps={{
        style: {
          borderRadius: 8, padding: '2rem', width: 'min(25rem, 80vw)', margin: 0,
        },
      }}
    >
      <DialogTitle
        onClose={onCancelClick ? () => onCancelClick(onClose) : onClose}
        className={classes.dialogTitle}
      >
        <Toolbar className={classes.toolbar}>
          <Typography variant="h2" className={classes.title}>Verificação de segurança</Typography>
          <IconButton
            aria-label="Fechar modal"
            className={classes.closeButton}
            onClick={onCancelClick ? () => onCancelClick(onClose) : onClose}
          >
            <CloseIcon />
          </IconButton>
        </Toolbar>
      </DialogTitle>

      <DialogContent className={classes.dialogContent}>
        <DialogContentText>
          O seu código é único, o e-mail é enviado apenas uma vez.
          Memorize o token recebido no seu e-mail e insira abaixo:
        </DialogContentText>

        <div className={classes.contentContainer}>
          <Input
            fullWidth
            autoFocus
            type="password"
            placeholder="Código de verificação"
            id="token-dialog"
            error={isError}
            value={token}
            onChange={(e) => handleInputChange(e)}
            startAdornment={
              (
                <InputAdornment
                  position="start"
                  className={classes.icon}
                >
                  <FontAwesomeIcon
                    icon={faLock}
                  />
                </InputAdornment>
              )
            }
          />
          {isError && <FormHelperText id="invalid-token-error" error>Token inválido!</FormHelperText>}
        </div>

        <DialogContentText>
          Esqueceu o seu código, ou não encontrou o e-mail?  Clique abaixo e envie um novo:
        </DialogContentText>

        <Button
          id="resend-token"
          className={classes.resendTokenBtn}
          onClick={handleResendToken}
        >
          Enviar novo código
        </Button>
      </DialogContent>

      <DialogActions className={classes.dialogActions}>
        <Button
          id="token-confirm-dialog-cancel-click"
          onClick={onCancelClick ? () => onCancelClick(onClose) : onClose}
          color="secondary"
          className={classes.cancelButton}
        >
          Cancelar
        </Button>

        <Button
          id="confirm-button"
          color="primary"
          className={classes.confirmButton}
          onClick={handleConfirmButton}
          disabled={token.length === 0 || isLoading}
        >
          {isLoading
            && (
              <CircularProgress
                size={20}
                thickness={7}
              />
            )}
          Confirmar
        </Button>
      </DialogActions>
    </Dialog>
  );
};

Token2FADialog.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  open: PropTypes.bool.isRequired,
  onConfirmClick: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  onCancelClick: PropTypes.func,
  email: PropTypes.string.isRequired,
};

Token2FADialog.defaultProps = {
  onCancelClick: null,
};

export default Token2FADialog;
