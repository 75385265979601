import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import CircularProgress from '@material-ui/core/CircularProgress';
import Button from '@material-ui/core/Button';

const LoadingButton = ({
  className,
  classNameButton,
  classes,
  confirmButtonContent,
  loading,
  ...props
}) => (
  <div className={classNames(className, classes.root)}>
    <div className={classes.wrapper}>
      <Button
        variant="contained"
        color="primary"
        className={classNameButton}
        disabled={loading}
        {...props}
      >
        {confirmButtonContent}
      </Button>
      {
        loading
        && <CircularProgress size={24} className={classes.buttonProgress} />
      }
    </div>
  </div>
);

LoadingButton.propTypes = {
  classes: PropTypes.object.isRequired,
  className: PropTypes.string,
  classNameButton: PropTypes.string,
  loading: PropTypes.bool,
  confirmButtonContent: PropTypes.any.isRequired,
};

LoadingButton.defaultProps = {
  className: '',
  classNameButton: '',
  loading: false,
};

export default LoadingButton;
