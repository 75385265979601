import moment from 'moment';
import {
  getChartNetReturn,
  getFilteredData,
  getNetReturnGroupedByMonth,
  getOnlyWorkDays,
} from './chart';
import { BENCHMARKS } from './constants/benchmarks';
import { getMonthsWithLastDayGroupByYear } from './instance';

export const getBenchmarkDataFromSameInstancePeriod = ({
  instanceDataCumulative,
  benchmarkDataCumulative,
  isCustomBacktest,
}) => {
  const instanceStartDate = instanceDataCumulative[0].date;
  const dateOneMonthSubtracted = isCustomBacktest
    ? moment(instanceStartDate).subtract(1, 'month')
    : moment(instanceStartDate);

  const filteredBenchmarkData = benchmarkDataCumulative.filter((item) => {
    return moment(item.date).isSameOrAfter(dateOneMonthSubtracted);
  });

  return filteredBenchmarkData;
};

export const getBenchmarksNetReturn = ({
  benchmarksList,
  benchmarksInitialValues,
  yearsWithLastDayMonthObj,
  benchmarkWorkdays,
}) => {
  if (!benchmarksList.length) return [];

  return benchmarksList.reduce((acc, benchmark) => {
    const currentBenchmarkNetReturn = yearsWithLastDayMonthObj
      ? getNetReturnGroupedByMonth(
        benchmarksInitialValues[benchmark],
        yearsWithLastDayMonthObj,
      )
      : getChartNetReturn(
        benchmarkWorkdays[benchmark],
        benchmarksInitialValues[benchmark].initialValue,
      );

    return {
      ...acc,
      [benchmark]: currentBenchmarkNetReturn,
    };
  }, {});
};

export const getBenchmarksInitialValues = ({
  benchmarksToPlot,
  instanceInitialValues,
  isHolidayOrWeekendDay,
}) => {
  const benchmarksList = Object.keys(benchmarksToPlot);

  if (!benchmarksList.length) return [];

  return benchmarksList.reduce((acc, benchmark) => {
    const currentBenchmarkInitialValues = getFilteredData(
      benchmarksToPlot[benchmark].data.data,
      instanceInitialValues.data[0].date,
      isHolidayOrWeekendDay,
      true,
    );

    return {
      ...acc,
      [benchmark]: currentBenchmarkInitialValues,
    };
  }, {});
};

export const getBenchmarksWorkdays = ({
  benchmarksList,
  benchmarksInitialValues,
}) => {
  if (!benchmarksList.length) return [];

  return benchmarksList.reduce((acc, benchmark) => {
    const currentBenchmarkWorkdays = getOnlyWorkDays(
      benchmarksInitialValues[benchmark].data,
    );

    return {
      ...acc,
      [benchmark]: currentBenchmarkWorkdays,
    };
  }, {});
};

export const getInstanceAndBenchmarksNetReturn = ({
  benchmarksToPlot,
  filter,
  rawData: {
    data: instanceData,
    startDates,
  },
  isHolidayOrWeekendDay,
  yearsList,
}) => {
  const startOfSeries = startDates[filter]
    ? startDates[filter]
    : instanceData[0].date;
  const benchmarksList = Object.keys(benchmarksToPlot);
  const initialValues = getFilteredData(instanceData, startOfSeries, isHolidayOrWeekendDay);
  const benchmarksInitialValues = getBenchmarksInitialValues({
    benchmarksToPlot,
    instanceInitialValues: initialValues,
    isHolidayOrWeekendDay,
  });

  let instanceNetReturn = [];
  let benchmarksNetReturn = [];

  if (yearsList.length) {
    const yearsWithLastDayMonthObj = getMonthsWithLastDayGroupByYear({ yearsList });

    instanceNetReturn = getNetReturnGroupedByMonth(
      initialValues,
      yearsWithLastDayMonthObj,
    );

    benchmarksNetReturn = getBenchmarksNetReturn({
      benchmarksList,
      benchmarksInitialValues,
      yearsWithLastDayMonthObj,
    });
  } else {
    const instanceDataWorkDays = getOnlyWorkDays(initialValues.data);

    instanceNetReturn = getChartNetReturn(
      instanceDataWorkDays,
      initialValues.initialValue,
    );

    const dataBenchmarksWorkdays = getBenchmarksWorkdays({
      benchmarksList,
      benchmarksInitialValues,
    });

    benchmarksNetReturn = getBenchmarksNetReturn({
      benchmarksList,
      benchmarksInitialValues,
      benchmarkWorkdays: dataBenchmarksWorkdays,
    });

    const arrBenchmarks = Object.values(benchmarksNetReturn);

    if (arrBenchmarks.length && arrBenchmarks[0].length) {
      const firstBenchmark = arrBenchmarks[0];
      const instanceLastDate = moment(instanceNetReturn[instanceNetReturn.length - 1].date);
      const benchmarkLastDate = moment(firstBenchmark[firstBenchmark.length - 1].date);
      if (instanceLastDate.isAfter(benchmarkLastDate)) instanceNetReturn.pop();
    }
  }

  return { instanceNetReturn, benchmarksNetReturn };
};

export const getUpdatedLegends = ({
  initialBenchmark = BENCHMARKS.ibov.id,
  instanceId,
}) => {
  const initialData = [
    {
      id: 'instance',
      name: 'Carteira',
      color: '#0079A2',
      instanceId,
      active: true,
    },
    ...Object.values(BENCHMARKS),
  ];

  return initialData.map((item) => {
    return item.id === initialBenchmark
      ? { ...item, active: true }
      : item;
  });
};

export const updateBenchmarkLegend = ({ benchmarkLegendsList, benchmark }) => {
  return benchmarkLegendsList.map((item) => {
    if (item.id !== benchmark.id) return item;

    return {
      ...item,
      active: !item.active,
    };
  });
};
