import React from 'react';
import PropTypes from 'prop-types';
import { Field, formValueSelector, reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import logger from 'redux-logger';
import { isEmpty } from 'lodash';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';

import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { InputTextField } from 'common/components/ReduxForm';

import { required, validateCNPJ, validateCPF } from 'common/utils/validation';

import maskCPF, { maskCNPJ } from 'common/utils/normalize';

export const COMPLETE_PERSONAL_DATA_DIALOG = 'COMPLETE_PERSONAL_DATA_DIALOG';

class CompletePersonalDataDialog extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      naturalPersonOrLegalPerson: 0,
    };
  }

  handleChange = (event, value) => {
    const { naturalPersonOrLegalPerson } = this.state;
    const { resetForm } = this.props;

    if (naturalPersonOrLegalPerson !== value) {
      this.setState({
        naturalPersonOrLegalPerson: value,
      });
      resetForm();
    }
  };

  handleSubmit = async (data) => {
    if (isEmpty(data)) { return; }
    const { document } = data;
    const { naturalPersonOrLegalPerson } = this.state;
    const {
      openPasswordDialog,
      updateUserProfile,
      onUpdate,
    } = this.props;

    const updateDocumentData = {
      document: document.replace(/\D/g, ''),
      natural_person_or_legal_person:
        naturalPersonOrLegalPerson.toString(),
    };
    try {
      updateDocumentData.s10i_password = await new Promise((resolve) => openPasswordDialog({
        onConfirmClick: (p, onClose) => {
          resolve(p);
          onClose();
        },
      }));
      await updateUserProfile(updateDocumentData);
      onUpdate();
    } catch (error) {
      logger.log('Could not get strategies', error);
    }
  };

  render = () => {
    const {
      naturalPersonOrLegalPerson,
    } = this.state;

    const {
      classes,
      onCancelClick,
      open,
      onClose,
      contentText,
      confirmButtonContent,
      cancelButtonContent,
      handleSubmit,
    } = this.props;
    return (
      <Dialog
        open={open}
        onClose={onCancelClick ? () => onCancelClick(onClose) : onClose}
      >
        <DialogTitle>Complete seu cadastro</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {contentText || ''}
          </DialogContentText>
          <Tabs
            value={naturalPersonOrLegalPerson}
            onChange={(e, value) => this.handleChange(e, value)}
            indicatorColor="primary"
            textColor="primary"
            fullWidth
          >
            <Tab label="Pessoa Física" />
            <Tab label="Pessoa Jurídica" />
          </Tabs>
          <form
            className={classes.formControl}
            onSubmit={handleSubmit(this.handleSubmit.bind(this))}
          >
            <Field
              type="text"
              id="completeRegistration-document"
              name="document"
              component={InputTextField}
              validate={[required,
                naturalPersonOrLegalPerson
                  ? validateCNPJ
                  : validateCPF,
              ]}
              label={
                naturalPersonOrLegalPerson
                  ? 'CNPJ'
                  : 'CPF'
              }
              normalize={
                naturalPersonOrLegalPerson
                  ? maskCNPJ
                  : maskCPF
              }
            />
            <div className={classes.buttonContainer}>
              <Button
                id="password-confirm-dialog-cancel-click"
                onClick={onCancelClick ? () => onCancelClick(onClose) : onClose}
                color="secondary"
                autoFocus
              >
                {cancelButtonContent || 'Cancelar'}
              </Button>
              <Button
                type="submit"
                onClick={handleSubmit(this.handleSubmit.bind(this))}
                color="primary"
                autoFocus
              >
                {confirmButtonContent || 'Confirmar'}
              </Button>
            </div>
          </form>
        </DialogContent>

      </Dialog>
    );
  }
}

CompletePersonalDataDialog.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onCancelClick: PropTypes.func,
  contentText: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.node,
  ]),
  cancelButtonContent: PropTypes.any,
  confirmButtonContent: PropTypes.any,
  resetForm: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  openPasswordDialog: PropTypes.func.isRequired,
  updateUserProfile: PropTypes.func.isRequired,
  onUpdate: PropTypes.func.isRequired,
};

CompletePersonalDataDialog.defaultProps = {
  onCancelClick: null,
  contentText: null,
  cancelButtonContent: null,
  confirmButtonContent: null,
};

const selector = formValueSelector('completeRegistration');

export default reduxForm({ form: 'completeRegistration' })(connect((state) => ({
  formValues: selector(
    state,
    'document',
    'password',
  ),
}))(CompletePersonalDataDialog));
