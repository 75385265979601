import React, { memo, useState } from 'react';
import PropTypes from 'prop-types';
import Placeholder from 'common/components/Placeholder';
import { BENCHMARKS } from 'common/utils/constants/benchmarks';
import { getUpdatedLegends, updateBenchmarkLegend } from 'common/utils/benchmarks';
import NetReturnChart from '../NetReturnChart';
import BenchmarkLegends from '../BenchmarkLegends';

const BenchmarkReturnChart = ({
  classes,
  instanceId,
  dailyCumulativePerformance,
  yearsList,
  filter,
  backtests,
  strategyId,
}) => {
  const initialBenchmarkLegends = getUpdatedLegends({
    instanceId,
  });
  const [benchmarkLegend, setBenchmarkLegend] = useState(initialBenchmarkLegends);

  const [benchmarksToPlot, setBenchmarksToPlot] = useState({
    [BENCHMARKS.ibov.id]: {
      color: BENCHMARKS.ibov.color,
      data: dailyCumulativePerformance[BENCHMARKS.ibov.instanceId],
      name: BENCHMARKS.ibov.name,
    },
  });

  const instanceData = dailyCumulativePerformance[instanceId] || backtests;
  const benchmarkRawData = dailyCumulativePerformance[BENCHMARKS.ibov.instanceId];

  const handleBenchMarkSelection = (benchmark) => {
    if (benchmark.instanceId !== instanceId) {
      const updatedBenchmarkLegends = updateBenchmarkLegend({
        benchmarkLegendsList: benchmarkLegend,
        benchmark,
      });
      const currentUpdatedBenchmark = updatedBenchmarkLegends
        .find((item) => item.id === benchmark.id);
      setBenchmarkLegend(updatedBenchmarkLegends);

      if (currentUpdatedBenchmark.active) {
        const newBenchmarksToPlot = {
          ...benchmarksToPlot,
          [benchmark.id]: {
            data: dailyCumulativePerformance[benchmark.instanceId],
            name: benchmark.name,
            color: benchmark.color,
          },
        };
        setBenchmarksToPlot(newBenchmarksToPlot);
      } else {
        const updatedBanchmarksToPlot = { ...benchmarksToPlot };
        delete updatedBanchmarksToPlot[benchmark.id];
        setBenchmarksToPlot(updatedBanchmarksToPlot);
      }
    }
  };

  return (
    <>
      {instanceData?.isDataReady && benchmarkRawData?.isDataReady ? (
        <>
          <NetReturnChart
            instanceId={instanceId}
            rawData={instanceData}
            benchmarksToPlot={benchmarksToPlot}
            height={240}
            yearsList={yearsList}
            filter={filter}
            strategyId={strategyId}
          />

          <div className={classes.container}>
            <BenchmarkLegends
              benchmarks={benchmarkLegend}
              handleBenchMarkSelection={handleBenchMarkSelection}
            />
          </div>
        </>
      ) : (
        <Placeholder height={240} />
      )}
    </>
  );
};

BenchmarkReturnChart.propTypes = {
  classes: PropTypes.object.isRequired,
  instanceId: PropTypes.number,
  dailyCumulativePerformance: PropTypes.object.isRequired,
  yearsList: PropTypes.array,
  filter: PropTypes.string,
  backtests: PropTypes.object,
  strategyId: PropTypes.number,
};

BenchmarkReturnChart.defaultProps = {
  instanceId: null,
  yearsList: [],
  filter: null,
  backtests: {},
  strategyId: null,
};

export default memo(BenchmarkReturnChart);
