import api from 'common/api';
import logdown from 'logdown';
import { unionBy } from 'lodash';
import {
  error as notifierError,
  success as notifierSuccess,
} from 'react-notification-system-redux';

import { closeDialog } from 'common/ducks/dialogs';
import { LOGOUT_SUCCEED } from 'common/ducks/auth';

export const INITIAL_STATE = { loading: false, data: null, error: null };

export const DUCK_NAME = 'subscriptions';
export const NAMESPACE = `user/${DUCK_NAME}`;
const logger = logdown(NAMESPACE);

// Actions
export const LOAD_USER_SUBSCRIPTIONS_STARTED = `${NAMESPACE}/LOAD_USER_SUBSCRIPTIONS_STARTED`;
export const LOAD_USER_SUBSCRIPTIONS_SUCCEED = `${NAMESPACE}/LOAD_USER_SUBSCRIPTIONS_SUCCEED`;
export const LOAD_USER_SUBSCRIPTIONS_FAILED = `${NAMESPACE}/LOAD_USER_SUBSCRIPTIONS_FAILED`;

// Action creators
export const loadUserSubscriptionsStarted = () => ({
  type: LOAD_USER_SUBSCRIPTIONS_STARTED,
});

export const loadUserSubscriptionsSucceed = (data) => ({
  data,
  type: LOAD_USER_SUBSCRIPTIONS_SUCCEED,
});

export const loadUserSubscriptionsFailed = (error) => ({
  error,
  type: LOAD_USER_SUBSCRIPTIONS_FAILED,
});

export const loadUserSubscriptions = (options = {
  reload: false,
  all: true,
  lastSuspendedPlan: false,
}) => async (dispatch, getState) => {
  const subscriptions = getState().user[DUCK_NAME];
  if (
    !options.reload
    && subscriptions
    && subscriptions.data
    && !subscriptions.loading
    && !subscriptions.error
  ) {
    return Promise.resolve(subscriptions.data);
  }

  dispatch(loadUserSubscriptionsStarted());

  let results = null;
  let config = null;
  if (options.all) {
    config = { params: { all: true } };
  } else if (options.lastSuspendedPlan) {
    config = { params: { lastSuspendedPlan: true } };
  }

  try {
    results = await api.user.getUserSubscriptions(config);
  } catch (error) {
    logger.error('Could not get user subscriptions.', error);
    dispatch(loadUserSubscriptionsFailed(error));
    return Promise.reject(error);
  }

  let subscriptionData = results.data.results;
  if (options.lastSuspendedPlan) {
    subscriptionData = unionBy(subscriptions.data, results.data.results, 'code');
  }
  dispatch(loadUserSubscriptionsSucceed(subscriptionData));
  return Promise.resolve(subscriptionData);
};

export const UPDATE_SUBSCRIPTION_STARTED = `${NAMESPACE}/UPDATE_SUBSCRIPTION_STARTED`;
export const UPDATE_SUBSCRIPTION_SUCCEED = `${NAMESPACE}/UPDATE_SUBSCRIPTION_SUCCEED`;
export const UPDATE_SUBSCRIPTION_FAILED = `${NAMESPACE}/UPDATE_SUBSCRIPTION_FAILED`;

export const updateSubscriptionStarted = () => ({
  type: UPDATE_SUBSCRIPTION_STARTED,
});

export const updateSubscriptionSucceed = (code, data) => ({
  code,
  data,
  type: UPDATE_SUBSCRIPTION_SUCCEED,
});

export const updateSubscriptionFailed = (error) => ({
  error,
  type: UPDATE_SUBSCRIPTION_FAILED,
});

export const updateSubscription = (code, data, config) => async (dispatch) => {
  dispatch(updateSubscriptionStarted());
  try {
    const result = await api.user.updateSubscription(code, data, config);

    logger.info('update subscription', result);
    dispatch(updateSubscriptionSucceed(code, data));

    dispatch(closeDialog());
    return Promise.resolve(data);
  } catch (error) {
    logger.error('Failed to update subscription.', error);
    dispatch(updateSubscriptionFailed(error));

    return Promise.reject(error);
  }
};

export const CANCEL_SUBSCRIPTION_RENEWAL_STARTED = `${NAMESPACE}/CANCEL_SUBSCRIPTION_RENEWAL_STARTED`;
export const CANCEL_SUBSCRIPTION_RENEWAL_SUCCEED = `${NAMESPACE}/CANCEL_SUBSCRIPTION_RENEWAL_SUCCEED`;
export const CANCEL_SUBSCRIPTION_RENEWAL_FAILED = `${NAMESPACE}/CANCEL_SUBSCRIPTION_RENEWAL_FAILED`;

export const cancelSubscriptionRenewalStarted = () => ({
  type: CANCEL_SUBSCRIPTION_RENEWAL_STARTED,
});

export const cancelSubscriptionRenewalSucceed = (code, data) => ({
  code,
  data,
  type: CANCEL_SUBSCRIPTION_RENEWAL_SUCCEED,
});

export const cancelSubscriptionRenewalFailed = (error) => ({
  error,
  type: CANCEL_SUBSCRIPTION_RENEWAL_FAILED,
});

export const cancelSubscriptionRenewal = (code, data) => async (dispatch) => {
  dispatch(cancelSubscriptionRenewalStarted());
  try {
    const result = await api.user.cancelSubscriptionRenewal(code, data);

    logger.info('cancel subscription', result);
    dispatch(cancelSubscriptionRenewalSucceed(code));

    dispatch(notifierSuccess({
      title: 'Sucesso!',
      message: 'Assinatura cancelada com sucesso.',
    }));

    dispatch(closeDialog());
    return Promise.resolve();
  } catch (error) {
    logger.error('Failed to cancel subscription.', error);
    dispatch(cancelSubscriptionRenewalFailed(error));

    dispatch(notifierError({
      title: 'Erro!',
      message:
          error.codeMessage
          || 'Houve um erro ao cancelar sua assinatura. Tente novamente.',
    }));

    return Promise.reject(error);
  }
};

// Reducer
export default (state = INITIAL_STATE, action) => {
  if (action.type === LOGOUT_SUCCEED) {
    return INITIAL_STATE;
  }

  switch (action.type) {
    case LOAD_USER_SUBSCRIPTIONS_STARTED:
      return {
        ...state,
        loading: true,
      };
    case LOAD_USER_SUBSCRIPTIONS_SUCCEED:
      return {
        ...state,
        loading: false,
        error: null,
        data: action.data,
      };
    case LOAD_USER_SUBSCRIPTIONS_FAILED:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    case UPDATE_SUBSCRIPTION_STARTED:
      return {
        ...state,
        loading: true,
      };
    case UPDATE_SUBSCRIPTION_SUCCEED:
      return {
        ...state,
        data: state.data
          .map((p) => (p.code === action.code ? { ...p, ...action.data } : p)),
        loading: false,
        error: null,
      };
    case UPDATE_SUBSCRIPTION_FAILED:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    case CANCEL_SUBSCRIPTION_RENEWAL_STARTED:
      return {
        ...state,
        loading: true,
      };
    case CANCEL_SUBSCRIPTION_RENEWAL_SUCCEED:
      return {
        ...state,
        data: state.data.map((p) => (p.code === action.code ? { ...p, payment_method: null } : p)),
        loading: false,
        error: null,
      };
    case CANCEL_SUBSCRIPTION_RENEWAL_FAILED:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    default:
      return state;
  }
};
