import { withRouter } from 'react-router-dom';
import { withStyles } from '@material-ui/styles';
import { connect } from 'react-redux';
import { withMobileDialog } from '@material-ui/core';
import { rebalanceInstance } from 'common/ducks/instance';
import RebalanceStocksDialog from './RebalanceStocksDialog';

const styles = {
  closeIcon: {
    position: 'absolute',
    right: '10px',
    top: '5px',
  },
  title: {
    fontSize: 22,
    fontWeight: 700,
    marginBottom: 16,
  },
};

const mapDispatchToProps = (dispatch, { instanceId, handleOpenOrdersDialog }) => ({
  rebalanceInstance: () => dispatch(
    rebalanceInstance({ instanceId, handleOpenOrdersDialog }),
  ),
});

export const REBALANCE_STOCKS_DIALOG_CODE = 'REBALANCE_STOCKS_DIALOG_CODE';

export default withRouter(connect(
  null,
  mapDispatchToProps,
)(withStyles(styles)(withMobileDialog()(RebalanceStocksDialog))));
