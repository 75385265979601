import React from 'react';
import PropTypes from 'prop-types';

// Material UI
import Paper from '@material-ui/core/Paper';

// Common
import Container from 'common/components/Container';
import Placeholder from 'common/components/Placeholder';

const LoadingPage = ({
  classes,
}) => (
  <div>
    <Paper className={classes.pageHeaderPaper}>
      <Container className={classes.container}>
        <div className={classes.icon}>
          <Placeholder width="100%" height={40} />
        </div>
        <div className={classes.title}>
          <Placeholder width="100%" height={40} />
        </div>
      </Container>
    </Paper>
  </div>
);

LoadingPage.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default LoadingPage;
