import { withStyles } from '@material-ui/core';
import { connect } from 'react-redux';
import { signPlan } from 'common/utils/plans';
import palette from 'common/palette';
import {
  addStock,
  loadStocks,
  removeStocks,
  setCommitChange,
  setMode,
} from 'common/ducks/whitebox';

import { saveTemplate, generateWallets } from 'common/ducks/template';
import { openDialog } from 'common/ducks/dialogs';
import { PLAN_SUBSCRIPTION_DIALOG_CODE } from 'common/components/Dialogs/PlanSubscriptionDialog';
import { START_FOLLOW_INSTANCE_DIALOG_CODE } from 'common/components/Dialogs/StartFollowInstanceDialog';

import { clearSearch } from 'common/ducks/stocks';
import WhiteBox from './WhiteBox';

const styles = {
  button: {
    '@media(max-width: 400px)': {
      fontSize: 14,
    },
    '&:first-of-type': {
      marginRight: 10,
    },
  },
  card: {
    boxShadow: '0 5px 10px 0 rgba(0, 0, 0, 0.15)',
    flexBasis: 'initial',
    justifyContent: 'center',
    maxWidth: '100%',
    minHeight: 495,
    padding: 15,
    position: 'relative',
    '@media screen and (min-width: 1200px)': {
      minHeight: 574,
    },
  },
  cardContent: {
    position: 'relative',
    width: '100%',
  },
  cardStocksContent: {
    position: 'relative',
    maxWidth: 477,
  },
  cardContentItems: {
    '@media (min-width: 1200px)': {
      marginBottom: 20,
      paddingRight: 15,
      maxHeight: 565,
      overflow: 'hidden',
    },
    display: 'flex',
    flexDirection: 'column',
    marginBottom: 15,
  },
  cardContentTemplate: {
    paddingRight: 0,
  },
  cardChartContent: {
    paddingTop: 15,
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  emptyStocks: {
    display: 'flex',
    flexDirection: 'column',
    height: 245,
    justifyContent: 'space-between',
    paddingTop: 15,
    textAlign: 'center',
  },
  justifyCenter: {
    justifyContent: 'center',
  },
  stocksHeader: {
    borderBottom: `1px solid ${palette.primary.main}`,
    display: 'flex',
    justifyContent: 'space-between',
    paddingBottom: 5,
  },
  stockCards: {
    margin: '10px 0',
  },
  selectAllText: {
    fontSize: 14,
    color: palette.textSecondary.main,
  },
  cardShadowScroll: {
    '@media (min-width: 1200px)': {
      background: 'linear-gradient(rgba(255,255,255,0),rgba(255,255,255,0.95),#FFF)',
      bottom: 0,
      display: 'block',
      maxWidth: 450,
      paddingTop: 30,
      position: 'absolute',
      width: '95%',
    },
  },
  stockMapWrapper: {
    overflow: 'auto',
  },
  addButton: {
    maxWidth: 121,
    height: 32,
    fontSize: 14,
    fontWeight: 500,
    lineHeight: 0,
  },
  checkedActions: {
    display: 'flex',
    height: 32,
    minWidth: 190,
    justifyContent: 'flex-end',

    '& > .MuiButton-root:not(:last-of-type)': {
      marginRight: 10,
    },
  },
  actionButtons: {
    color: '#fff',
    maxWidth: 91,
    fontSize: 14,
    fontWeight: 500,
  },
  totalPercentText: {
    fontSize: 14,
    fontWeight: 'bold',
  },
  totalPercentValue: {
    fontWeight: 'bold',
    fontSize: 30,
    marginTop: 10,
  },
  totalPercentError: {
    fill: palette.secondary.main,
    color: palette.secondary.main,
    minHeight: 20,
  },
  disabledInput: {
    backgroundColor: '#f2f2f2',
    border: 'solid 1px #c3c3c3',
    borderRadius: 5,
    color: '#5e5e5e',
    fontWeight: 'bold',
    maxWidth: 70,
    '&:last-child': {
      marginLeft: 10,
    },
    '&.Mui-disabled': {
      color: '#5e5e5e',
    },
    '&:before': {
      display: 'none',
    },
  },
  totalText: {
    marginRight: 15,
  },
  totalInput: {
    textAlign: 'center',
    marginRight: 13,
  },
  inputAdornment: {
    marginLeft: 0,
    position: 'absolute',
    right: 5,
  },
  errorText: {
    color: palette.danger.main,
  },
  wrapperTitlesMinMax: {
    display: 'flex',
    justifyContent: 'flex-end',
    maxWidth: 477,
    padding: '10px 15px 5px 0',
  },
  textMaximum: {
    marginLeft: 15,
  },
};

const mapStateToProps = ({
  instances,
  brokerages,
  whitebox,
  template,
  user: { subscriptions },
}, { instanceId }) => ({
  instance: instances.data[instanceId],
  brokerages: brokerages.data,
  saveLoading: template.loading,
  loadingSuggestedWallets: template.loadingSuggestedWallets,
  chosenSuggestedWallet: template.chosenSuggestedWallet,
  whitebox,
  subscriptions: subscriptions.data,
});

const mapDispatchToProps = (dispatch, { id }) => ({
  signPlan: (plan) => dispatch(signPlan(plan)),
  openPlanSubscriptionDialog: ({ strategyId, update = false, isWhiteBox }) => dispatch(
    openDialog(PLAN_SUBSCRIPTION_DIALOG_CODE, {
      id, strategyId, update, isWhiteBox,
    }),
  ),
  addStock: (stocksList, stock, isTemplate) => dispatch(
    addStock(stocksList, stock, isTemplate),
  ),
  loadStocks: (stocks, commited) => dispatch(loadStocks(stocks, commited)),
  removeStocks: (stocksList, checkAll) => dispatch(
    removeStocks(stocksList, checkAll),
  ),
  setMode: (mode) => dispatch(setMode(mode)),
  saveTemplate: (stockList, instanceId) => dispatch(saveTemplate(stockList, instanceId)),
  openStartFollowInstance: (props) => dispatch(openDialog(
    START_FOLLOW_INSTANCE_DIALOG_CODE,
    { ...props },
  )),
  generateWallets: (stocksList, instanceId) => dispatch(generateWallets(stocksList, instanceId)),
  clearSearch: () => dispatch(clearSearch()),
  setCommitChange: (commited) => dispatch(setCommitChange(commited)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(WhiteBox));
