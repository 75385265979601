import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import accounting from 'accounting';

const DisplayNumber = ({
  classes,
  className,
  value,
  symbol,
  symbolLocation,
  currency,
  percentual,
  color,
  colorful,
  scale,
  valueScale,
  symbolScale,
  precision,
  maxValue,
  fontWeight,
}) => {
  const renderFormattedValue = () => {
    const { formatMoney } = accounting;
    const { formatNumber } = accounting;

    return currency ? formatMoney(value, '', precision) : formatNumber(value, precision);
  };

  const renderRangedValues = (
    <span>
      De&nbsp;
      {value}
      &nbsp;a&nbsp;
      {maxValue}
    </span>
  );

  const getSymbolValue = () => {
    const currentSymbol = currency ? accounting.settings.currency.symbol : '';

    return symbol || currentSymbol;
  };

  const symbolComponent = (
    <span
      style={{ fontSize: `${symbolScale}em` }}
      className={
        classNames(
          'DisplayNumber-symbol',
          classes.symbol,
          symbolLocation === 'left' && classes.symbolLeft,
        )
      }
    >
      {getSymbolValue()}
    </span>
  );

  return (
    <span
      style={{ fontSize: `${scale}rem` }}
      className={
        classNames(
          className,
          classes[color],
          classes[fontWeight],
          (colorful && value < 0) && classes.negative,
          (colorful && value > 0) && classes.positive,
        )
      }
    >
      <span
        style={{ fontSize: `${valueScale}em` }}
        className={classNames('DisplayNumber-value', classes.value)}
      >
        { (!percentual && symbolLocation === 'left') && symbolComponent }
        { maxValue ? renderRangedValues : renderFormattedValue() }
        { (percentual || symbolLocation === 'right') && symbolComponent }
      </span>
    </span>
  );
};

DisplayNumber.propTypes = {
  classes: PropTypes.object.isRequired,
  className: PropTypes.string,
  value: PropTypes.number,
  symbol: PropTypes.string,
  symbolLocation: PropTypes.string,
  currency: PropTypes.bool,
  percentual: PropTypes.bool,
  color: PropTypes.string,
  colorful: PropTypes.bool,
  scale: PropTypes.number,
  valueScale: PropTypes.number,
  symbolScale: PropTypes.number,
  precision: PropTypes.number,
  maxValue: PropTypes.number,
  fontWeight: PropTypes.oneOf(['normal', 'medium', 'bold']),
};

DisplayNumber.defaultProps = {
  className: '',
  value: 0,
  symbol: '',
  symbolLocation: 'left',
  currency: false,
  percentual: false,
  color: 'textSecondary',
  colorful: true,
  scale: 1.25,
  symbolScale: 1,
  valueScale: 1,
  precision: 2,
  maxValue: null,
  fontWeight: 'medium',
};

export default DisplayNumber;
