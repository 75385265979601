import { orderBy } from 'lodash';
import { DESC } from './constants';
import { reduceObjectsArrayToObject } from './array';

export const getFullSmartDataValues = ({ stocks, smartData }) => {
  const weightsData = smartData.weights.map((item) => {
    const currentStock = stocks[item.stock_code];
    const {
      stock_code,
      upper_bound,
      lower_bound,
      ...restItem
    } = item;

    return {
      ...restItem,
      company_name: currentStock.display_name || currentStock.company_name,
      weight: Number((item.weight * 100).toFixed(0)),
      maxWeight: upper_bound,
      minWeight: lower_bound,
      code: stock_code,
    };
  });

  const sortedWeightsData = orderBy(
    weightsData,
    'weight',
    DESC,
  );

  return {
    ...smartData,
    weights: reduceObjectsArrayToObject(sortedWeightsData, 'code'),
  };
};

export default null;
