import React from 'react';
import PropTypes from 'prop-types';
import { Box } from '@material-ui/core';
import classNames from 'classnames';
import { getRemovedStocks } from 'common/utils/stocks';
import { sortByNumber } from 'common/utils/array';
import { DESC } from 'common/utils/constants';
import { CHANGED_STOCKS_VARIANTS } from 'common/utils/constants/stocks';
import ChangedStock from '../Dialogs/StoreItemChangedStocksDialog/ChangedStock';
import TitleTooltip from '../TitleTooltip';

const ChangedStocksListing = ({
  classes,
  stocks,
  oldStocks,
  reverseColumns,
  leftLabel,
  rightLabel,
  variants,
  equity,
  newEquity,
  isCurrencyMode,
}) => {
  const removedStocks = getRemovedStocks({ stocks, oldStocks });

  const allChangedStocks = Object.values({ ...stocks, ...removedStocks })
    .sort(sortByNumber('weight', DESC));

  const hasScroll = allChangedStocks.length >= 6;

  return (
    <>
      <Box
        display="flex"
        justifyContent="flex-end"
        className={classNames(
          hasScroll && classes.labelsBox,
        )}
      >
        <Box display="flex">
          <Box
            mr={2}
            className={classNames(
              classes.spacing,
              isCurrencyMode && classes.largeSpacing,
            )}
          >
            <TitleTooltip
              label={leftLabel.text}
              tooltip={{ text: leftLabel.tooltip }}
              color="textSecondary"
              variant="h6"
            >
              {leftLabel.text}
            </TitleTooltip>
          </Box>
          <Box
            className={classNames(
              classes.spacing,
              isCurrencyMode && classes.largeSpacing,
            )}
          >
            <TitleTooltip
              label={rightLabel.text}
              tooltip={{ text: rightLabel.tooltip }}
              color="textSecondary"
              variant="h6"
            >
              {rightLabel.text}
            </TitleTooltip>
          </Box>
        </Box>
      </Box>
      <div className={classNames(
        hasScroll && classes.stocksListing,
      )}
      >
        {allChangedStocks.map((stock) => (
          <ChangedStock
            key={stock.code || stock.stock_code}
            stock={stock}
            reverseColumns={reverseColumns}
            variants={variants}
            equity={equity}
            newEquity={newEquity}
            isCurrencyMode={isCurrencyMode}
          />
        ))}
      </div>
    </>
  );
};

ChangedStocksListing.propTypes = {
  classes: PropTypes.object.isRequired,
  stocks: PropTypes.object.isRequired,
  oldStocks: PropTypes.object.isRequired,
  reverseColumns: PropTypes.bool,
  leftLabel: PropTypes.shape({
    text: PropTypes.string,
    tooltip: PropTypes.node,
  }),
  rightLabel: PropTypes.shape({
    text: PropTypes.string,
    tooltip: PropTypes.node,
  }),
  variants: PropTypes.object,
  equity: PropTypes.number,
  newEquity: PropTypes.number,
  isCurrencyMode: PropTypes.bool,
};

ChangedStocksListing.defaultProps = {
  reverseColumns: false,
  leftLabel: {
    text: 'Anterior',
    tooltip: '',
  },
  rightLabel: {
    text: 'Atual',
    tooltip: '',
  },
  variants: CHANGED_STOCKS_VARIANTS,
  equity: null,
  newEquity: null,
  isCurrencyMode: false,
};

export default ChangedStocksListing;
