export default {
  special: {
    contrastText: '#FFFFFF',
    dark: 'linear-gradient(45deg, #23CDC0 20%, #00C0D2 90%)',
    main: 'linear-gradient(45deg, #23CDC0 20%, #00C0D2 90%)',
    light: 'linear-gradient(45deg, #a5fcf4 20%, #93e8f2 90%)',
  },
  neutral: {
    main: '#FFFFFF',
    dark: '#000000',
    transparent: 'rgba(0, 0, 0, 0)',
  },
  primary: {
    main: '#24bc71',
    contrastText: '#fff',
    dark: '#1fa061',
  },
  secondary: {
    contrastText: '#FFFFFF',
    main: '#e93056',
    dark: '#e81c38',
  },
  default: {
    contrastText: '#C0BEBF',
    dark: '#C0BEBF',
    main: '#E1E1E1',
    light: '#EDEDED',
  },
  moody: {
    contrastText: '#FFFFFF',
    dark: '#003156',
    main: '#0079A2',
    light: '#0079A2',
  },
  attention: {
    contrastText: '#FFFFFF',
    dark: '#dba200',
    main: '#e8b21d',
    light: '#FFDA66',
  },
  warn: {
    contrastText: '#FFFFFF',
    dark: '#E6AC00',
    main: '#FFC107',
    light: '#fff9a8',
  },
  danger: {
    contrastText: '#FFFFFF',
    dark: '#BF363B',
    main: '#FF3743',
    light: '#FF878E',
  },
  gain: {
    contrastText: '#FFFFFF',
    dark: '#008633',
    main: '#00AF00',
    light: '#66CF66',
  },
  loss: {
    contrastText: '#FFFFFF',
    dark: '#BD1E1E',
    main: '#FF0001',
    light: '#FF6667',
  },
  championship: {
    gold: '#dbc969',
    silver: '#b2b2b2',
    bronze: '#ddae6c',
  },
  links: {
    blue: '#3db1f1',
  },
  textPrimary: {
    main: '#333',
  },
  textSecondary: {
    contrastText: '#FFFFFF',
    dark: '#747C88',
    main: '#8e8e8e',
    light: '#C8CACD',
  },
  darkPrimary: {
    main: '#16533a',
  },
  blue: {
    contrastText: '#FFFFFF',
    dark: '#002f7d',
    main: '#025ab9',
  },
};
