export const START = 'Início';
export const LAUNCH = 'Lançamento';

export const PRE_SI_PERFORMANCE_ZONE_GROUP_LABEL = {
  Quant: {
    label: 'Backtest',
    tooltipText: 'Período de teste da estratégia utilizando dados históricos do mercado com valores simulados.',
  },
  Analog: {
    label: 'Track record',
    tooltipText: 'Histórico de performance da carteira operando em ambiente real',
  },
};

export const POST_SI_PERFORMANCE_ZONE_GROUP_LABEL = 'Operando na SI';
