import { STOPPED_STATES } from './constants';

export const checkInstanceIsTemplate = (instance) => {
  return instance.archived && STOPPED_STATES.includes(instance.state)
  && instance.simulation_type === 0;
};

export const getTemplatesList = (instances) => {
  if (instances.length === 0) return [];

  return instances.filter((instance) => {
    const isTemplate = checkInstanceIsTemplate(instance);

    return isTemplate;
  });
};

export default null;
