import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';

import { updateSubscription } from 'common/ducks/user/subscriptions';
import { loadUserPaymentMethods } from 'common/ducks/user/paymentMethods';

import {
  error as notifierError,
  success as notifierSuccess,
} from 'react-notification-system-redux';

import AlterPlanPaymentDialog, { ALTER_PLAN_PAYMENT_DIALOG_CODE } from './AlterPlanPaymentDialog';

export { ALTER_PLAN_PAYMENT_DIALOG_CODE };

const styles = (theme) => ({
  wrapper: {
    margin: theme.spacing(1),
    position: 'relative',
  },
  buttonProgress: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
  paymentContainers: {
    marginTop: '5%',
    position: 'relative',
  },
  card: {
    marginTop: 0,
    marginBottom: 0,
    backgroundColor: '#ff0000',
  },
  logo: {
    width: '100%',
  },
  title: {
    fontSize: '1.3125rem',
    fontWeight: 500,
  },
  label: {
    fontSize: '0.875rem',
    color: 'black',
  },
  cards: {
    fontSize: 18,
    margin: '18px 0',
  },
  content: {
    paddingTop: 0,
  },
});

const mapStateToProps = ({
  user: {
    subscriptions,
    paymentMethods,
  },
}) => ({
  paymentMethods,
  subscriptions,
});

const mapDispatchToProps = (dispatch) => ({
  updateSubscription: (code, data) => dispatch(updateSubscription(code, data)),
  loadUserPaymentMethods: () => dispatch(loadUserPaymentMethods()),
  notifySuccess: (data) => dispatch(notifierSuccess(data)),
  notifyError: (data) => dispatch(notifierError(data)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withStyles(styles)(AlterPlanPaymentDialog));
