import React from 'react';
import PropTypes from 'prop-types';

import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';

const CloseDialogButton = ({
  onClose,
  classes,
}) => (
  <div className={classes.absoluteContainer}>
    <IconButton
      id="close-dialog-button"
      size="small"
      className={classes.iconButton}
      edge="start"
      color="inherit"
      onClick={onClose}
      aria-label="close"
    >
      <CloseIcon />
    </IconButton>
  </div>
);

CloseDialogButton.propTypes = {
  onClose: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
};

export default CloseDialogButton;
