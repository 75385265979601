import React from 'react';
import PropTypes from 'prop-types';
import {
  Switch, Route, withRouter, Redirect,
} from 'react-router-dom';
import {
  PUBLIC_URL,
  LOGIN_URL,
  OLD_RECOVER_PASSWORD_URL,
  OLD_REDEFINE_PASSWORD_URL,
  EMAIL_REDEFINE_PASSWORD_URL,
  OLD_SIGNUP_URL,
  OLD_BETA_SELECT_URL,
} from 'common/utils/constants';
import AuthPages from './AuthPages';
import SignUp from './SignUp';
import PublicStoreItems from './PublicStoreItems';

const getRedirectURL = (location, url) => {
  switch (location.pathname) {
    case PUBLIC_URL:
      return LOGIN_URL;
    case OLD_SIGNUP_URL:
    case OLD_BETA_SELECT_URL:
      return `${url}/criar-conta`;
    case OLD_REDEFINE_PASSWORD_URL:
    case EMAIL_REDEFINE_PASSWORD_URL:
      return `${url}/auth/redefinir-senha${location.search}`;
    case OLD_RECOVER_PASSWORD_URL:
      return `${url}/auth/recuperar-senha`;
    default:
      return url;
  }
};

const PublicPages = ({ match: { url }, location }) => {
  return (
    <Switch>
      <Route path={`${url}/auth`} component={AuthPages} />
      <Route path={`${url}/criar-conta`} component={SignUp} />
      <Route path={`${url}/carteiras/:id`} component={PublicStoreItems} />

      <Redirect to={getRedirectURL(location, url)} />
    </Switch>
  );
};

PublicPages.propTypes = {
  match: PropTypes.shape({ url: PropTypes.string }).isRequired,
  location: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
};

export default withRouter(PublicPages);
