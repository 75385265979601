import { withRouter } from 'react-router-dom';
import { withStyles } from '@material-ui/styles';
import { connect } from 'react-redux';
import { withMobileDialog } from '@material-ui/core';

import { INITIAL_REPORT_STATE, loadInstanceReport } from 'common/ducks/report';
import { openDialog } from 'common/ducks/dialogs';
import { insertFinancialTransaction } from 'common/ducks/investments';
import { isHolidayOrWeekendDay } from 'common/ducks/holidays';
import { restartUpdateInstance } from 'common/ducks/instance';
import TransactionsDialog from './TransactionsDialog';
import { PROGRESS_DIALOG_CODE } from '../ProgressDialog';
import { PLAN_SUBSCRIPTION_DIALOG_CODE } from '../PlanSubscriptionDialog';
import { PASSWORD_CONFIRM_DIALOG_CODE } from '../PasswordConfirmDialog';

const styles = {
  closeIcon: {
    position: 'absolute',
    right: '10px',
    top: '5px',
  },
  dialogContent: {
    paddingTop: 10,
  },
};

export const OPEN_TRANSACTIONS_DIALOG_CODE = 'OPEN_TRANSACTIONS_DIALOG_CODE';

const mapStateToProps = ({
  user,
  plans,
  report,
  instances,
  storeItems,
  positions,
}, { instanceId, strategyId }) => ({
  subscriptions: user.subscriptions.data,
  plans: plans.data,
  report: report[instanceId].data || INITIAL_REPORT_STATE,
  instance: instances.data[instanceId],
  storeInstance: storeItems.data.items[strategyId] || {},
  positions: positions[instanceId].data.mergedPositions,
});

const mapDispatchToProps = (dispatch) => ({
  openProgressDialog: (props) => dispatch(openDialog(PROGRESS_DIALOG_CODE, props)),
  insertFinancialTransaction: (instanceId, data) => dispatch(
    insertFinancialTransaction(instanceId, data),
  ),
  openPlanSubscriptionDialog: ({ strategyId, update = false }) => dispatch(
    openDialog(PLAN_SUBSCRIPTION_DIALOG_CODE, { strategyId, update }),
  ),
  loadInstanceReport: (id, params) => dispatch(loadInstanceReport(
    id,
    params,
    { reload: true },
    true,
  )),
  isHolidayOrWeekendDay: (date) => dispatch(isHolidayOrWeekendDay(date)),
  restartUpdateInstance: (params) => dispatch(restartUpdateInstance(params)),
  openPasswordConfirmDialog: (props) => dispatch(
    openDialog(PASSWORD_CONFIRM_DIALOG_CODE, { ...props }),
  ),
});

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps,
)(withStyles(styles)(withMobileDialog()(TransactionsDialog))));
