import { withStyles } from '@material-ui/core/styles';
import theme from 'common/theme';
import UserMenu from './UserMenu';

const styles = {
  dropdownMenuList: {
    paddingTop: 0,
    paddingBottom: 0,
    '& h3': {
      maxWidth: 150,
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
    '&:after': {
      top: -5,
      left: '50%',
      transform: 'translateX(-50%)',
      position: 'absolute',
      content: '""',
      display: 'block',
      width: 0,
      height: 0,
      borderLeft: '5px solid transparent',
      borderRight: '5px solid transparent',
      borderBottom: '5px solid #ddd',
      backgroundColor: 'transparent',
      [`@media (min-width: ${theme.breakpoints.values.xl}px)`]: {
        left: '35%',
        transform: 'translateX(-35%)',
      },
    },
  },
};

export default withStyles(styles)(UserMenu);
