import { withStyles } from '@material-ui/core';
import { withRouter } from 'react-router-dom';
import ChoiceDialog from './ChoiceDialog';

const styles = {
  title: {
    fontSize: 27,
    fontWeight: 'bold',
    position: 'relative',
  },
  closeIcon: {
    position: 'absolute',
    top: 15,
    right: 25,
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  cardContainer: {
    display: 'flex',
    justifyContent: 'space-around',
    width: '100%',
    '@media screen and (max-width: 768px)': {
      flexDirection: 'column',
      alignItems: 'center',
      height: '100%',
      justifyContent: 'space-between',
    },
  },
  card: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '33px 15px',
    maxWidth: 249,
    boxShadow: '0 3px 6px 0 rgba(0, 0, 0, 0.16)',
    border: 'solid 1px #efeeee',
    cursor: 'pointer',
    position: 'relative',
  },
  iconContainer: {
    width: 70,
    height: 70,
    borderRadius: 17,
    boxShadow: '0 5px 15px 0 rgba(0, 0, 0, 0.2)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  primary: {
    backgroundImage: 'linear-gradient(129deg, #0fbc9f -19%, #24bc71 117%)',
  },
  secondary: {
    backgroundImage: 'linear-gradient(129deg, #ff416c -19%, #ff4b2b 117%)',
  },
  icon: {
    color: '#fff',
    fontSize: 38,
  },
  choiceTitle: {
    fontSize: 16,
    fontWeight: 'bold',
    marginTop: 22,
    marginBottom: 4,
  },
  description: {
    fontSize: 14,
    textAlign: 'center',
  },
  chip: {
    fontSize: 14,
    color: '#6b6b6b',
    marginTop: 45,
    maxWidth: 460,
    '@media screen and (max-width: 768px)': {
      maxWidth: 290,
      height: 'auto',
      paddingTop: 5,
      paddingBottom: 5,
      borderRadius: 25,
    },
  },
  chipLabel: {
    whiteSpace: 'break-spaces',
    textAlign: 'center',
  },
  tag: {
    maxHeight: 17,
    position: 'absolute',
    top: 7,
    right: 7,
    fontSize: 11,
    fontWeight: 'bold',
  },
};

export const CHOICE_DIALOG_CODE = 'CHOICE_DIALOG_CODE';

export default withRouter(withStyles(styles)(ChoiceDialog));
