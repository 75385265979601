import theme from 'common/theme';
import { withMobileDialog } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import StoreItemChangedStocksDialog from './StoreItemChangedStocksDialog';

export const STORE_ITEM_CHANGED_STOCKS_DIALOG_CODE = 'STORE_ITEM_CHANGED_STOCKS_DIALOG_CODE';

const styles = {
  dialog: {
    margin: '0 auto',
  },
  dialogTitle: {
    fontSize: 22,
    fontWeight: 700,
    paddingBottom: 0,
    paddingTop: '24px',
  },
  dialogContent: {
    paddingTop: 20,
  },
  dialogWidth: {
    '@media (min-width: 769px)': {
      maxWidth: 560,
      width: '100%',
    },
  },
  tag: {
    fontSize: 12,
  },
  wrapperTags: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
  },
  closeIcon: {
    position: 'absolute',
    right: '10px',
    top: '5px',
  },
  arrow: {
    [theme.breakpoints.down('sm')]: {
      transform: 'rotate(90deg)',
    },
  },
};

export default withStyles(styles)(withMobileDialog()(StoreItemChangedStocksDialog));
