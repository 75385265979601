import React from 'react';
import PropTypes from 'prop-types';

import FontAwesomeIcon from '@fortawesome/react-fontawesome';
import faSave from '@fortawesome/fontawesome-free-solid/faSave';

import IconButton from '@material-ui/core/IconButton';
import ArrowTooltip from 'common/components/ArrowTooltip';

const SaveIconButton = ({
  isValid,
  onClick,
  setupCode,
  classes,
  type,
}) => (
  <ArrowTooltip
    placement="bottom"
    title="Salvar"
  >
    <IconButton
      disabled={!isValid}
      id={`${setupCode}-operational-costs-edit_costs`}
      key="operational-costs-edit_costs"
      onClick={onClick}
      className={classes.expansionPanelIconButton}
      type={type}
    >
      <FontAwesomeIcon icon={faSave} />
    </IconButton>
  </ArrowTooltip>
);

SaveIconButton.propTypes = {
  setupCode: PropTypes.string.isRequired,
  // classes is received from OperationalCostPanel component
  // via EditCostsPanelSummary
  classes: PropTypes.object.isRequired,
  isValid: PropTypes.bool.isRequired,
  onClick: PropTypes.func,
  type: PropTypes.string,
};

SaveIconButton.defaultProps = {
  onClick: () => null,
  type: '',
};

export default SaveIconButton;
