import { withRouter } from 'react-router-dom';
import { withStyles } from '@material-ui/styles';
import { connect } from 'react-redux';
import { error } from 'react-notification-system-redux';
import { withMobileDialog } from '@material-ui/core';
import { followInstance } from 'common/ducks/instance';

import { DEFAULT_SETUP_DATA } from 'common/utils/setup';
import FollowInstanceDialog from './FollowInstanceDialog';

const styles = {
  closeIcon: {
    position: 'absolute',
    right: '10px',
    top: '5px',
  },
  dialog: {
    width: '100%',
    '@media(min-width: 769px)': {
      maxWidth: 580,
      margin: '0 auto',
    },
  },
  dialogTitle: {
    fontSize: 24,
    paddingBottom: 0,
    fontWeight: 700,
  },
  dialogContent: {
    paddingTop: 10,
  },
};

export const OPEN_FOLLOW_INSTANCE_DIALOG_CODE = 'OPEN_FOLLOW_INSTANCE_DIALOG_CODE';

const mapStateToProps = (({
  user: { setups },
  stocks,
  storeItems,
},
{ strategyId }) => ({
  userSetups: setups.data.filter((setup) => setup.code !== DEFAULT_SETUP_DATA.code),
  validStocks: stocks.validStocks,
  storeInstance: storeItems.data.items[strategyId] || {},
}));

const mapDispatchToProps = (dispatch) => ({
  followInstance: ({
    submitData,
    forcePortfolio,
    history,
    dataSteps,
    isWhiteBox,
    templateId,
    startFromZero,
    minOperationValue,
  }) => dispatch(
    followInstance({
      submitData,
      forcePortfolio,
      history,
      dataSteps,
      isWhiteBox,
      templateId,
      startFromZero,
      minOperationValue,
    }),
  ),
  notifyError: (props) => dispatch(error(props)),
});

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps,
)(withStyles(styles)(withMobileDialog()(FollowInstanceDialog))));
