import moment from 'moment';
import get from 'lodash/get';
import { reduceObjectsArrayToObject } from 'common/utils/array';
import accounting from 'accounting';
import api from 'common/api';

import { BUY, SELL } from 'common/utils/constants/stocks';
import { getMergedParams } from 'common/ducks/orders';
import { parseNumber, formatNumber } from 'common/utils/string';
import { maskDate, maskTime } from 'common/utils/normalize';
import { isBuyOrderType } from 'common/utils/validation';
import { getArraysIntersection } from './array';
import { getSplitPositionsByFullAndFractional } from './positions';
import { ORDER_TYPE, ORDER_VARIANT, OPEN_ORDERS_TEXT } from './constants/orders';
import { DECREASE } from './constants';
import { DEFAULT_LOT_SIZE } from './constants/stocks';

export const orderEventStateMap = {
  order_received: 'Enviada',
  order_sent: 'Confirmada',
  order_canceled: 'Cancelada',
  order_changed: 'Alterada',
  order_executed: 'Executada',
  order_expired: 'Expirada',
  order_rejected: 'Rejeitada',
  order_suspended: 'Suspensa',
  order_set_to_previous_state: 'Estado anterior',
  order_cancel_rejected: 'Cancelamento rejeitado',
  order_changed_rejected: 'Alteração rejeitada',
};

export const translate = {
  order_type: {
    0: ORDER_TYPE.buy.name,
    1: ORDER_TYPE.sell.name,
  },
  status: {
    received: 'recebida',
    hung: 'aberta',
    pending_hung: 'aberta pendente',
    pending_cancel: 'cancelada pendente',
    canceled: 'cancelada',
    pending_change: 'alterada pendente',
    changed: 'alterada',
    partially_executed: 'executada parcialmente',
    executed: 'executada',
    unknown: 'desconhecido',
    expired: 'expirada',
    rejected: 'rejeitada',
    suspended: 'suspensa',
    triggered: 'disparada',
    previous_final_state: 'estado final anterior',
  },
  entry_exit_or_reversal: {
    entry: 'entrada',
    exit: 'saída',
    reversal: 'reversão',
  },
};

const OPEN_ORDERS = [
  'changed',
  'hung',
  'pending',
  'pending_hung',
  'pending_cancel',
  'pending_change',
  'partially_executed',
];

export const ORDER_STATUS_AVAILABLE_OPTIONS = [
  'canceled',
  'executed',
  'rejected',
  'expired',
];

export const HASH_MAP_ORDERS_ATTRIBUTES = [
  {
    key: 'order_id',
    name: '#',
    format: (value) => value,
  },
  {
    key: 'datetime',
    name: 'Data/Hora',
    format: (value) => moment(value).format('DD/MM/YYYY / HH:mm:ss'),
  },
  {
    key: 'stock_code',
    name: 'Ativo',
    format: (value) => value,
  },
  {
    key: 'order_type',
    name: 'C/V',
    format: (value) => translate.order_type[value].charAt(0),
  },
  {
    key: 'number_of_stocks',
    name: 'Quantidade',
    format: (value) => value,
  },
  {
    key: 'nominal_price',
    name: 'Preço limite',
    format: (value) => accounting.formatNumber(Number.parseFloat(value), 2),
  },
  {
    key: 'status',
    name: 'Status',
    format: (value) => translate.status[value],
  },
  {
    key: 'entry_exit_or_reversal',
    name: 'Tipo',
    format: (value) => translate.entry_exit_or_reversal[value],
  },
  {
    key: 'number_of_traded_stocks',
    name: 'Quantidade executada',
    format: (value) => value,
  },
  {
    key: 'average_nominal_price',
    name: 'Preço médio',
    format: (value) => (
      accounting.formatNumber(Number.parseFloat(value), 4)
    ),
  },
  {
    key: 'absolute_average_simple_profit',
    name: 'Resultado (Abs.)',
    format: (value) => (
      value ? accounting.formatNumber(Number.parseFloat(value), 2) : '-'),
  },
  {
    key: 'percentual_average_simple_profit',
    name: 'Resultado %',
    format: (value) => (
      value ? accounting.formatNumber(Number.parseFloat(value), 2) : '-'
    ),
  },
  {
    key: 'gross_profit',
    name: 'Resultado (R$)',
    format: (value) => (
      value ? accounting.formatNumber(Number.parseFloat(value), 2) : '-'
    ),
  },
];

const defaultFormatter = (v) => v;

export const orderFormatters = {
  order_type: defaultFormatter,
  market_name: defaultFormatter,
  stock_code: defaultFormatter,
  number_of_stocks: (v) => {
    if (v === '0') return '1';
    return formatNumber(v, 0);
  },
  price: (v) => formatNumber(v, 2),
  entry_exit_or_reversal: defaultFormatter,
  date: (v) => maskDate(v),
  time: (v) => maskTime(v),
  reason: defaultFormatter,
};

export const orderParsers = {
  order_type: defaultFormatter,
  market_name: defaultFormatter,
  stock_code: defaultFormatter,
  number_of_stocks: (v) => parseNumber(v, 0),
  price: (v) => parseNumber(v, 2),
  entry_exit_or_reversal: defaultFormatter,
  date: defaultFormatter,
  time: defaultFormatter,
  reason: defaultFormatter,
};

export const getFormattedValue = (value, name) => {
  const formatted = orderFormatters[name](value);

  return orderParsers[name](formatted);
};

export const validatePrice = (value) => {
  let errorMessage;

  if (value === '0.00') {
    errorMessage = 'O preço deve ser maior que 0';
  }

  return errorMessage;
};

// Function utils
export const sortOrdersByDatetime = (
  { datetime: datetime1 },
  { datetime: datetime2 },
) => {
  const moment1 = moment(datetime1);
  const moment2 = moment(datetime2);
  if (moment1.isBefore(moment2)) { return 1; }
  if (moment1.isAfter(moment2)) { return -1; }
  return 0;
};

export const getInstanceLastOrder = (orders) => Object
  .values(orders)
  .sort(sortOrdersByDatetime)[0];

export const mergeCurrentWithResultOrders = (
  state, // current orders in map of ids format
  requestResult, // result orders in list of orders format
  ordersLimit = 100,
) => {
  const stateTotal = Number.parseInt(get(state, 'data.total', 0), 10);
  const stateOrders = get(state, 'data.orders', {});
  const resultTotal = Number.parseInt(get(requestResult, 'data.total', stateTotal), 10);
  const resultOrders = get(requestResult, 'data.orders', []);

  const mergedOrders = {
    ...stateOrders,
    ...reduceObjectsArrayToObject(resultOrders, 'order_id'),
  };

  // Keep just the "ordersLimit" first orders to avoid memory overhead
  const listOrders = Object
    .values(mergedOrders)
    .sort(sortOrdersByDatetime)
    .slice(0, ordersLimit);

  return {
    total: resultTotal,
    orders: reduceObjectsArrayToObject(
      listOrders,
      'order_id',
    ),
  };
};

export const insertExternalOrders = async ({
  instanceId,
  brokerageId,
  orderData,
  positions,
}) => {
  if (!positions) {
    throw new Error('Erro ao cadastrar ordens');
  }

  const positionsOrders = getSplitPositionsByFullAndFractional({
    positions,
  });
  const arrPositionsOrders = Object.values(positionsOrders);

  for (let i = 0; i < arrPositionsOrders.length; i += 1) {
    const position = arrPositionsOrders[i];

    const dataPosition = {
      stock_code: position.stock,
      price: position.price,
      number_of_stocks: position.number_of_stocks,
      brokerage_id: brokerageId,
      investment_code: `instance_${instanceId}`,
      order_type: position.orderType || '0',
      market_name: 'BOVESPA',
      entry_exit_or_reversal: 'entry',
      datetime: `${(position.date).split('/').reverse().join('-')} ${position.time || '10:00:00'}`,
      reason: orderData.reason,
    };

    // eslint-disable-next-line
    await api.strategies.instances.orders
      .insertExternalOrder(instanceId, dataPosition);
  }
};

export const isInsertionMode = (variant) => variant === ORDER_VARIANT.insert;

export const isOrderOpen = (order) => OPEN_ORDERS.includes(order.status);
export const isOrderRejected = (order) => order.status === 'rejected';
export const isBuyOrder = (orderType) => Number(orderType) === 0;
export const getOrderTypeName = (orderType) => (isBuyOrderType(orderType) ? BUY : SELL);

export const instanceHasOpenOrders = (orders) => {
  const instanceOrders = Object.values(orders);
  return instanceOrders.some(isOrderOpen);
};

export const findRejectedOrder = (events) => events.find((event) => event.event_type === 'order_rejected');

export const getNumberStocksToBeAdded = ({ numberOfStocks, stock, type }) => {
  const tradingLotSize = stock.trading_lot_size || DEFAULT_LOT_SIZE;

  if (tradingLotSize === 1
    || Number(numberOfStocks) < tradingLotSize
    || (type === DECREASE && Number(numberOfStocks) === tradingLotSize)) {
    return 1;
  }

  return tradingLotSize;
};

export const onCheckForOrders = async (instanceId) => {
  const mergedParams = getMergedParams();

  try {
    const result = await api.strategies.instances.orders.getOrders(
      instanceId, mergedParams,
    );
    return result.data.orders;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const onCheckForOpenOrders = async (instanceId, openDialog) => {
  const orders = await onCheckForOrders(instanceId);
  const hasOpenOrders = instanceHasOpenOrders(orders);
  if (hasOpenOrders) {
    openDialog();
    throw new Error(OPEN_ORDERS_TEXT);
  }
  return null;
};

export const getTextOrderLegend = ({ isManualOrder, external }) => {
  if (isManualOrder) return '(Ordem interna: adicionada via boleta SmarttInvest)';

  if (external) return '(Externa)';

  return '';
};

export const onCreateOrder = async (instanceId, data) => {
  try {
    const response = await api.strategies.instances.orders.createOrder(instanceId, data);
    return response.data.order_id;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const isInvalidSellOperation = ({ instance, positions, values }) => {
  const positionsAndTheoreticalInstanceCommonItems = getArraysIntersection(
    Object.keys(positions), instance.params.stockCodesList.split(','),
  );
  const sellAllValuesItems = values
    .filter(
      (item) => !isBuyOrder(item.orderType)
        && Number(item.number_of_stocks) >= Number(positions[item.stock]?.number_of_stocks),
    )
    .map((item) => item.stock);

  if (!sellAllValuesItems.length) return false;

  const sellAllValuesAndTheoreticalInstanceCommonItems = getArraysIntersection(
    sellAllValuesItems, instance.params.stockCodesList.split(','),
  );

  return sellAllValuesAndTheoreticalInstanceCommonItems.length
      >= positionsAndTheoreticalInstanceCommonItems.length
      && sellAllValuesAndTheoreticalInstanceCommonItems;
};
