import { withStyles } from '@material-ui/styles';
import palette from 'common/palette';
import Link from './Link';

const styles = {
  link: {
    color: 'inherit',
    cursor: 'pointer',
    display: 'inline-block',
    fontSize: '1em',

    '&:hover': {
      textDecoration: 'underline',
    },
  },
  underlineDecoration: {
    textDecoration: 'underline',
  },
  white: {
    color: '#fff',
  },
  primary: {
    color: palette.primary.main,
  },
  secondary: {
    color: palette.secondary.main,
  },
  textPrimary: {
    color: palette.textPrimary.main,
  },
  textSecondary: {
    color: palette.textSecondary.main,
  },
};

export default withStyles(styles)(Link);
