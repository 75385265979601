export const BROKERAGE_NOTICES = {
  3: {
    enableBrokerageSteps: [
      'Acesse a sua conta da XP',
      'Minha conta',
      'Autorização de Roteamento',
      'Ative a Smarttbot',
    ],
  },
  1982: {
    enableBrokerageSteps: [
      'Acesse a sua conta da modalmais',
      'Bolsa de valores',
      'Lista de plataformas',
      'Plugins e robôs',
      'Habilitar Robô SmarttBot',
    ],
  },
  3701: {
    enableBrokerageSteps: [
      'Acesse a sua conta da Órama',
      'Posicione o mouse em cima do seu nome',
      'Configurações',
      'Renda variável',
      'Habilitar produto',
    ],
  },
  4090: {
    enableBrokerageSteps: [
      'Vá em Menu',
      'Minha Conta',
      'Modelos de cobrança',
      'Selecione a opção "Toro Zero"',
    ],
    hasFreeBrokeragePlanWarn: true,
  },
};

export const BROKERAGES_CHANGE_NAMES = {
  85: {
    name: 'BTG',
  },
};

export const BROKERAGES_FORM_STATUS = {
  processing: 'Em Análise',
};

export const NAME = 'name';

export default null;
