import api from 'common/api';

let credentialsPromise = null;
let credentials = null;

export const getMDSStreamerConnection = async (
  stockCodes,
  messageHandler,
  streamerConfig = {
    output_type: 'compact',
    compress: false,
    binary: false,
    price_change_only: true,
  },
) => {
  if (!credentials) {
    if (!credentialsPromise) {
      credentialsPromise = api.chart.getCredentials();
    }

    ({ data: credentials } = await credentialsPromise);
  }

  const socket = api.chart.getMDSSocket();

  socket.onopen = () => {
    socket.send(JSON.stringify({
      credentials,
      codes: [stockCodes],
      ...streamerConfig,
    }));
  };

  socket.onmessage = messageHandler;

  return {
    socket,
    credentials,
    stockCodes,
  };
};

export const resetStreamerCredentials = () => {
  credentials = null;
  credentialsPromise = null;
};

export default null;
