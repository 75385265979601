import { connect } from 'react-redux';
import { withStyles } from '@material-ui/styles';
import BenchmarkReturnChart from './BenchmarkReturnChart';

const styles = {
  container: {
    display: 'flex',
    flexWrap: 'wrap',
    paddingTop: 20,

    '@media(min-width:500px)': {
      justifyContent: 'center',
    },
  },
};

const mapStateToProps = ({ dailyCumulativePerformance }) => ({
  dailyCumulativePerformance,
});

export default connect(mapStateToProps)(withStyles(styles)(BenchmarkReturnChart));
