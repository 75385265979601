import { connect } from 'react-redux';
import { withStyles } from '@material-ui/styles';

import { isWhiteboxInstance } from 'common/utils/instance';

import DetailedChartLegend from './DetailedChartLegend';

const styles = {
  cardsLegendContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    color: '#5e5e5e',

    '& h6': {
      fontWeight: 'normal',
      padding: '0 16px',
    },
  },
  stockCards: {
    margin: '10px auto',
  },
  weightBox: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
  },
};

const mapStateToProps = ({ instances, storeItems }, { instanceId }) => {
  const strategyId = instances.data[instanceId]?.strategy_id;
  return {
    theoreticalStocks: isWhiteboxInstance(strategyId)
      ? instances.data[instanceId]?.stocks
      : storeItems.data.items[strategyId]?.stocks || {},
  };
};

export default connect(mapStateToProps)(withStyles(styles)(DetailedChartLegend));
