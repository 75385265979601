import React from 'react';
import PropTypes from 'prop-types';
import { HELP_ARTICLE_HOW_TO_START } from 'common/utils/constants';
import { mdiPlay, mdiWallet } from '@mdi/js';
import { followInstance } from 'common/utils/instance';
import Link from 'common/components/Link';
import { Box } from '@material-ui/core';
import ChoiceDialog from '../ChoiceDialog';

const StartFollowInstanceDialog = ({
  isWhiteBox,
  strategies,
  strategyId,
  brokerages: {
    brokerages,
    realUserBrokerages,
    matchedUserBrokeragesWithStrategy,
  },
  updateStatusRegisterStep,
  openProgressDialog,
  openRegisterBrokerageDialog,
  openFollowInstanceDialog,
  openConfirmationDialog,
  onClose,
  templateId,
  templateName,
  checkExclusiveBrokerage,
}) => {
  const followInstanceProps = {
    strategyId,
    strategies,
    brokerages: {
      brokerages,
      realUserBrokerages,
      matchedUserBrokeragesWithStrategy,
    },
    openProgressDialog,
    openRegisterBrokerageDialog,
    updateStatusRegisterStep,
    openFollowInstanceDialog,
    openConfirmationDialog,
    isWhiteBox,
    templateId,
    templateName,
  };

  const title = 'Seguir uma carteira';
  const bottomText = (
    <Link
      color="primary"
      fontWeight={700}
      fontSize={16}
      path={HELP_ARTICLE_HOW_TO_START}
      target="_blank"
    >
      Precisa de ajuda para escolher?
    </Link>
  );

  const handleFollowInstance = ({ startFromZero = false } = {}) => {
    if (Object.values(realUserBrokerages).length === 0) {
      openConfirmationDialog({
        open: true,
        title: 'Corretora necessária',
        confirmButtonContent: 'Cadastrar corretora',
        maxWidth: 'sm',
        showCancelButton: false,
        preventClose: true,
        responsive: true,
        onConfirmClick: () => openRegisterBrokerageDialog(updateStatusRegisterStep),
        content: (
          <>
            <Box mb={2}>
              Para começar a seguir uma carteira você precisa possuir pelo menos
              uma corretora autorizada.
            </Box>

            <Box mb={2}>
              Caso já tenha cadastrado uma corretora é necessário aguardar até
              48 horas úteis para autorização.
              {' '}
              <strong>
                Te avisaremos por e-mail quando estiver autorizado.
              </strong>
            </Box>
          </>
        ),
      });
      return;
    }

    const isExclusiveBrokerage = checkExclusiveBrokerage({
      strategyId,
      customDialogProps: {
        buttonMessage: 'Cadastrar corretora',
        buttonClick: () => openRegisterBrokerageDialog(updateStatusRegisterStep),
      },
    });

    if (!isExclusiveBrokerage) {
      followInstance({
        ...followInstanceProps,
        startFromZero,
      });
    }
  };

  const choices = [
    {
      id: 'start-from-zero',
      title: 'Começar do zero',
      description: 'Quero que a SmarttInvest monte a carteira toda do início.',
      icon: mdiPlay,
      iconBackground: 'linear-gradient(129deg, #99dcfb -19%, #002f7d 117%)',
      tag: {
        color: '#24bc71',
        backgroundColor: '#d5ffea',
        label: 'RECOMENDADO',
      },
      onClick: () => handleFollowInstance({
        startFromZero: true,
      }),
    },
    {
      id: 'start-with-position',
      title: 'Começar com uma posição',
      description: 'Já tenho parte ou toda essa carteira na minha corretora e quero configurar a SmarttInvest para gerenciá-la a partir de agora',
      icon: mdiWallet,
      iconBackground: 'linear-gradient(129deg, #e8461c -19%, #ffd267 117%)',
      tag: {
        color: '#e8b21d',
        backgroundColor: '#ffeec1',
        label: 'NÍVEL AVANÇADO',
      },
      onClick: handleFollowInstance,
    },
  ];

  return (
    <ChoiceDialog
      title={title}
      choices={choices}
      bottomText={bottomText}
      onClose={onClose}
    />
  );
};

StartFollowInstanceDialog.propTypes = {
  strategyId: PropTypes.number.isRequired,
  isWhiteBox: PropTypes.bool,
  templateId: PropTypes.number,
  templateName: PropTypes.string,
  updateStatusRegisterStep: PropTypes.func.isRequired,
  brokerages: PropTypes.object.isRequired,
  strategies: PropTypes.object.isRequired,
  openProgressDialog: PropTypes.func.isRequired,
  openRegisterBrokerageDialog: PropTypes.func.isRequired,
  openFollowInstanceDialog: PropTypes.func.isRequired,
  openConfirmationDialog: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  checkExclusiveBrokerage: PropTypes.func.isRequired,
};

StartFollowInstanceDialog.defaultProps = {
  isWhiteBox: false,
  templateId: null,
  templateName: '',
};

export default StartFollowInstanceDialog;
