import { combineReducers } from 'redux';

// Reducers
import { reducer as formReducer } from 'redux-form';
import { reducer as notifications } from 'react-notification-system-redux';
import user, { DUCK_NAME as USER_DUCK_NAME } from './user';
import dialogs, { DUCK_NAME as DIALOGS_DUCK_NAME } from './dialogs';
import auth, { DUCK_NAME as AUTH_DUCK_NAME } from './auth';
import instances, { DUCK_NAME as INSTANCES_DUCK_NAME } from './instances';
import strategies, { DUCK_NAME as STRATEGIES_DUCK_NAME } from './strategies';
import brokerages, { DUCK_NAME as BROKERAGES_DUCK_NAME } from './brokerages';
import plans, { DUCK_NAME as PLANS_DUCK_NAME } from './plans';
import registerSteps, { DUCK_NAME as REGISTER_STEPS_DUCK_NAME } from './registerSteps';
import report, { DUCK_NAME as REPORT_DUCK_NAME } from './report';
import positions, { DUCK_NAME as POSITIONS_DUCK_NAME } from './positions';
import activeCampaign, { DUCK_NAME as ACTIVE_CAMPAIGN_DUCK_NAME } from './activeCampaign';
import dailyCumulativePerformance from './dailyCumulativePerformance';
import orders, { DUCK_NAME as ORDERS_DUCK_NAME } from './orders';
import investments, { DUCK_NAME as INVESTMENTS_DUCK_NAME } from './investments';
import storeItems, { DUCK_NAME as STORE_ITEMS_DUCK_NAME } from './storeItems';
import billing, { DUCK_NAME as BILLING_DUCK_NAME } from './billing';
import categories, { DUCK_NAME as CATEGORIES_DUCK_NAME } from './categories';
import stocks, { DUCK_NAME as STOCKS_DUCK_NAME } from './stocks';
import route, { DUCK_NAME as ROUTE_DUCK_NAME } from './route';
import whitebox, { DUCK_NAME as WHITEBOX_DUCK_NAME } from './whitebox';
import template, { DUCK_NAME as TEMPLATE_DUCK_NAME } from './template';
import scoreBoard, { DUCK_NAME as SCORE_BOARD_DUCK_NAME } from './scoreBoard';
import holidays, { DUCK_NAME as HOLIDAYS_DUCK_NAME } from './holidays';
import market, { DUCK_NAME as MARKET_DUCK_NAME } from './market';

const reducer = combineReducers({
  [ACTIVE_CAMPAIGN_DUCK_NAME]: activeCampaign,
  [AUTH_DUCK_NAME]: auth,
  [BILLING_DUCK_NAME]: billing,
  [BROKERAGES_DUCK_NAME]: brokerages,
  [CATEGORIES_DUCK_NAME]: categories,
  dailyCumulativePerformance,
  [DIALOGS_DUCK_NAME]: dialogs,
  form: formReducer,
  [HOLIDAYS_DUCK_NAME]: holidays,
  [INSTANCES_DUCK_NAME]: instances,
  [INVESTMENTS_DUCK_NAME]: investments,
  [MARKET_DUCK_NAME]: market,
  notifications,
  [ORDERS_DUCK_NAME]: orders,
  [PLANS_DUCK_NAME]: plans,
  [POSITIONS_DUCK_NAME]: positions,
  [REGISTER_STEPS_DUCK_NAME]: registerSteps,
  report,
  [REPORT_DUCK_NAME]: report,
  [ROUTE_DUCK_NAME]: route,
  [SCORE_BOARD_DUCK_NAME]: scoreBoard,
  [STOCKS_DUCK_NAME]: stocks,
  [STORE_ITEMS_DUCK_NAME]: storeItems,
  [STRATEGIES_DUCK_NAME]: strategies,
  [TEMPLATE_DUCK_NAME]: template,
  [USER_DUCK_NAME]: user,
  [WHITEBOX_DUCK_NAME]: whitebox,
});

export default reducer;
