import React from 'react';
import PropTypes from 'prop-types';

import logo from 'common/assets/images/public-pages-logox2.png';

const PublicPagesBody = ({
  classes,
  title,
  subtitle,
  children,
}) => {
  return (
    <div className={classes.wrapper}>
      <div className={classes.left}>
        <img src={logo} className={classes.logo} alt="logo" />
        <div className={classes.text}>
          O investimento realmente inteligente
        </div>
      </div>
      <div className={classes.formsWrapper}>
        <div className={classes.title}>{title}</div>
        <div className={classes.subtitle}>{subtitle}</div>
        {children}
      </div>
    </div>
  );
};

PublicPagesBody.propTypes = {
  classes: PropTypes.object.isRequired,
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired,
  children: PropTypes.any.isRequired,
};

export default PublicPagesBody;
