import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { withStyles } from '@material-ui/styles';
import { success, error } from 'react-notification-system-redux';

import { resetPassword, resetExpiredPassword, rateCheck } from 'common/ducks/auth';
import RedefinePassword from './RedefinePassword';

const styles = {
  form: {
    display: 'flex',
    flexDirection: 'column',
    maxWidth: 300,
  },
};

const mapDispatchToProps = (dispatch) => ({
  resetPassword: (data) => dispatch(resetPassword(data)),
  resetExpiredPassword: (data) => dispatch(resetExpiredPassword(data)),
  rateCheck: (data) => dispatch(rateCheck(data)),
  notifyError: (data) => dispatch(error(data)),
  notifySuccess: (data) => dispatch(success(data)),
});

const mapStateToProps = ({ auth: { logged, forced_external_logout } }) => ({
  logged,
  forcedExternalLogout: forced_external_logout,
});

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps,
)(withStyles(styles)(RedefinePassword)));
