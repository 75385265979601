import { withStyles } from '@material-ui/styles';
import TitleTooltip from './TitleTooltip';

const styles = () => ({
  label: {
    display: 'inline-block',
  },
  tooltipText: {
    textAlign: 'center',
  },
  tooltipIcon: {
    display: 'inline-block',
    margin: '0 5px',
  },
  normal: {
    fontWeight: 'normal',
  },
  medium: {
    fontWeight: 500,
  },
  bold: {
    fontWeight: 700,
  },
});

export default withStyles(styles)(TitleTooltip);
