import emptyPositionLogo from 'common/assets/images/empty_position_logo.svg';
import palette from 'common/palette';

export const DEVELOPMENT = 'development';

export const SMARTTINVEST_CONTACT_EMAIL = 'contato@smarttinvest.com';
export const SMARTTBOT_SP_PHONE = '+551141180492';
export const SMARTTBOT_MG_PHONE = '+553125730048';
export const WEBAPP_1_URL = 'https://app.smarttinvest.com';
export const OLD_WEBAPP_1_URL = 'https://antigoapp.smarttbot.com';
export const SI_YOUTUBE = 'https://www.youtube.com/channel/UCyk6TclN1yF19YiDz1go1JA';
export const SI_CONTACT = 'https://smarttinvest.com/contato/';
export const SI_SMARTT_FEATURES = 'https://smarttinvest.com/features/';
export const SI_ZENDESK_URL = 'https://ajuda.smarttbot.com/hc/pt-br';
export const SI_ZENDESK_CATEGORY = `${SI_ZENDESK_URL}/categories/4419891395223`;
export const SI_SITE_URL = 'https://smarttinvest.com';
export const SI_PORTFOLIO_SHARING_URL = `${SI_SITE_URL}/compartilhamento-de-carteiras`;
export const SUPORT_SMARTTBOT_URL = 'https://ajuda.smarttbot.com/hc/pt-br';
export const SI_ZENDESK_ARTICLES_URL = 'https://ajuda.smarttbot.com/hc/pt-br/articles';
export const HELP_ARTICLE_BROKERAGES_URL = `${SI_ZENDESK_URL}/hc/pt-br/articles/360040202353`;
export const HELP_ARTICLE_SIGNATURES_URL = `${SI_ZENDESK_URL}/sections/4419891700887`;
export const HELP_ARTICLE_CANCELED_URL = `${SI_ZENDESK_ARTICLES_URL}/4419869908119`;
export const HELP_ARTICLE_REJECTED_URL = `${SI_ZENDESK_ARTICLES_URL}/4419884194327`;
export const HELP_ARTICLE_RECEIVED_URL = `${SI_ZENDESK_ARTICLES_URL}/4419870706967`;
export const HELP_ARTICLE_CORRECT_WALLET = `${SI_ZENDESK_ARTICLES_URL}/4423305584279`;
export const HELP_ARTICLE_HOW_TO_START = `${SI_ZENDESK_ARTICLES_URL}/4419884031895`;
export const TERMS_OF_USE = 'https://smarttinvest.com/wp-content/uploads/2020/02/SmarttInvest-Termos-de-Uso.pdf';
export const PRIVACY_POLICY = 'https://smarttinvest.com/wp-content/uploads/2020/02/SmarttInvest-Política-de-Privacidade.pdf';
export const URL_B3_PUMA_TRADING_HORARIO_NEGOCIACOES = 'http://www.b3.com.br/pt_br/solucoes/plataformas/puma-trading-system/para-participantes-e-traders/horario-de-negociacao/acoes/';
export const PUBLIC_URL = '/public';
export const PRIVATE_URL = '/private';
export const LOGIN_URL = `${PUBLIC_URL}/auth/login`;
export const HOME_PAGE_URL = `${PRIVATE_URL}/home`;
export const RECOVER_PASSWORD_URL = `${PUBLIC_URL}/auth/recuperar-senha`;
export const RESET_PASSWORD_URL = `${PUBLIC_URL}/auth/redefinir-senha`;
export const SIGN_UP_URL = `${PUBLIC_URL}/criar-conta`;
export const MY_ACCOUNT_URL = `${PRIVATE_URL}/conta`;
export const INSTANCES_URL = `${PRIVATE_URL}/carteiras`;
export const PROFILE_URL = `${MY_ACCOUNT_URL}/perfil`;
export const SHOWCASE_URL = `${PRIVATE_URL}/vitrine`;
export const INSTANCE_CREATOR_URL = `${PRIVATE_URL}/criador-de-carteira`;
export const SIGNATURES_URL = `${PRIVATE_URL}/conta/assinaturas`;
export const CANCELLATION_URL = `${SIGNATURES_URL}/cancelamento`;
export const BROKERAGES_URL = `${MY_ACCOUNT_URL}/corretoras`;

export const RENKO_STRATEGY_ID = 385;
export const PONTOS_PIVOT_ID = 417;
export const UNIVERSE_ABERTURA_ID = 321;
export const CAPITAL_WIN_ONTIME_ID = 488;
export const WESTOCK_STRATEGY_ID = 480;
export const WESTOCK_DISCOUNT_CODE = 'WESTOCK-20';
export const RAPTOR_STRATEGY_ID = 494;
export const HORUS_STRATEGY_ID = 465;
export const GAUSS_CONTROL_STRATEGY_ID = 523;
export const SIMULATED_LIMITS = {
  miniContracts: 250,
  fullContracts: 50,
};

export const PATRIMONY = { name: 'patrimony' };
export const PROFITABILITY = { name: 'profitability' };
export const CURRENT_WALLET = { name: 'currentWallet' };
export const MONTHLY_WALLET_RETURN = { name: 'monthlyWalletReturn' };
export const ANNUALIZED_RETURN = { name: 'annualizedReturn' };
export const OPERATIONAL_COSTS = { name: 'operationalCosts' };
export const MINIMUM_CAPITAL = { name: 'minimumCapital' };
export const AMOUNT_POSITIONS = { name: 'amountPositions' };
export const BETTER_PERIOD = { name: 'betterPeriod' };
export const WORST_PERIOD = { name: 'worstPeriod' };
export const OPERATING_PERIODS = { name: 'operatingPeriods' };
export const POSITIVE_PERIODS = { name: 'positivePeriods' };
export const ACCUMULATED_ALPHA = { name: 'accumulatedAlpha' };

export const FREE_PLAN = 'free';
export const PERSONALIZED_WALLET_PLAN_CODE = 'smarttinvest_1203_WB';
export const WHITEBOX_PLAN_CODES = ['smarttinvest_588_WB_plano', 'smarttinvest_588_WB_plus'];
export const TEMPLATE_PLAN_CODE = 'smarttinvest_620_WB';
export const DISABLE_SCOREBOARD_BUTTON = 'grupo-cfm';

export const STRATEGIES_VALIDATION_REQUIRED = [216, 176];
export const MARKET_NAME_MINI = 'BMF';

export const BROKERAGE_NOT_AVAILABLE = 'Não disponível';
export const BACKTEST_BROKERAGE = 'Backtest';

export const FRACTIONAL_MARKET_NUMBER_OF_STOCKS_LIMIT = 50;
export const FUTURES_MARKET_STOCK_CODES = ['WDO%', 'IND%', 'WIN%', 'DOL%'];

export const INTERNAL_EMAILS_REGEX = /(@smarttbot|@smarttbotter|@si2|@s10i|@bussoladoinvestidor)/;
export const PRO_AND_GREATER_PLAN_CODE_REGEX = /(pro|expert|advanced|plus)/;
export const SIGNATURE_STRATEGY_MAX_INTERVAL = 120;
export const DEFAULT_SIMULATION_TYPE = 0;
export const MODERATED_SIMULATION_TYPE = 1;

export const SIGNATURES_TABLE_HEADER = [
  'Assinaturas',
  'Início',
  'Vencimento',
  'Frequência da Renovação',
  'Pagamento',
  'Plano',
  'Valor',
  'Cupom',
];

export const INVOICES_TABLE_HEADER = [
  'Descrição',
  'Vencimento',
  'Valor Final (R$)',
  'Status',
  'Pagamento',
];

export const SIMULATED_BROKERAGE = 1000;
export const SIMULATED_BROKERAGE_LIST = [1000, 1001, 1002];
export const BROKERAGE_NOT_PERMITTED_LIST = [386, 120, 37, 1072, 1039, 1001, 1002];
export const XP_BROKERAGE_ID = 3;
export const CLEAR_BROKERAGE_ID = 308;
export const MODALMAIS_BROKERAGE_ID = 1982;
export const MODALMAIS_CC = 15898;
export const BTG_BROKERAGE_ID = 85;
export const MIRAE_BROKERAGE_ID = 262;
export const NEW_CLEAR = 9994;
export const GENIAL_DAY_TRADE = 9996;
export const GENIAL_SWING_TRADE_OLD = 9997;
export const GENIAL_DAY_TRADE_OLD = 173;
export const NECTON_ID = 23;
export const BROKERAGE_MAP_STATES = {
  pending: 'Em análise na SmarttInvest',
  processing: 'Em análise na Corretora',
  approved: 'Autorizada',
};
export const DISABLED_BROKERAGES = [
  10,
  308,
  NEW_CLEAR,
  MODALMAIS_BROKERAGE_ID,
  GENIAL_DAY_TRADE,
  GENIAL_SWING_TRADE_OLD,
  GENIAL_DAY_TRADE_OLD,
  39,
  1039,
  72,
  1072,
  86, // Walpires
  227, // Gradual
  9999,
  115,
  1099,
];

export const FREE_PLAN_VALUE = 0.00001;

export const PASSWORD_TIPS = [
  { id: '1', text: 'Seis caracteres' },
  { id: '2', text: 'Número' },
  { id: '3', text: 'Letra minúscula' },
  { id: '4', text: 'Letra maiúscula' },
];

export const DATE_FORMAT = 'DD/MM/YYYY';
export const ERROR_STATE = 'ERROR';
export const RUNNING_STATE = 'RUNNING';
export const RUNNING_STATES = [RUNNING_STATE, ERROR_STATE];
export const STOPPED_STATES = ['STOPPED'];
export const PROCESSING_STATES = 'RUNNING,QUEUED,NEW';
export const FINISHED_STATES = 'FINISHED,ERROR';
export const INSTANCE_STATES_DATA = {
  RUNNING: {
    name: 'Executando',
    color: palette.gain.main,
  },
  STOPPED: {
    name: 'Parado',
    color: palette.attention.main,
  },
  ERROR: {
    name: 'Error',
    color: palette.danger.main,
  },
  NEW: {
    name: 'Novo',
    color: palette.default.main,
  },
  QUEUED: {
    name: 'Em espera',
    color: palette.warn.main,
  },
  FINISHED: {
    name: 'Finalizado',
    color: palette.moody.main,
  },
};

export const WEEKLY_FILTER = 'Semanal';
export const MONTHLY_FILTER = 'Mensal';

export const STORE_FILTERS = [
  {
    buttonText: 'Mensais',
    filter: {
      frequency: MONTHLY_FILTER,
      subtitleText: 'mensalmente',
    },
  },
  {
    buttonText: 'Semanais',
    filter: {
      frequency: WEEKLY_FILTER,
      subtitleText: 'semanalmente',
    },
  },
  {
    buttonText: 'Outras',
    filter: {
      frequency: null,
      subtitleText: 'com frequência variada',
    },
  },
];

export const EDITAR = 'Editar';

export const EXECUTING_TAB_LABEL = 'SEGUINDO';
export const STOPPED_TAB_LABEL = 'HISTÓRICO';

export const EXECUTING_TAB = 'executing';
export const STOPPED_TAB = 'stopped';
export const STORE_TAB = 'store';
export const RANKING_TAB = 'ranking';
export const SIGNED_INSTANCES_TAB = 'signedInstances';
export const SIGNED_INSTANCES_ROUTE = 'assinados';
export const ACTIVE_SIGNATURES = 'activeSignatures';
export const PRIVATE_INSTANCES = 'privateInstances';

export const FILTER_TAB_INSTANCES = {
  executing: RUNNING_STATES,
  stopped: STOPPED_STATES,
};

export const VARIANT_INSTANCES = 'instances';

export const INSTANCE_STATES_MESSAGES = {
  error: {
    label: 'Erro na montagem',
    tooltip: {
      __html: `Ocorreu um erro na montagem ou rolagem da carteira.
    É necessário alguma ação manual para corrigir.
    <a href="${HELP_ARTICLE_CORRECT_WALLET}">Saiba mais</a>`,
    },
  },
  runningPositioned: {
    label: 'Posicionado',
    tooltip: {
      __html: `Tudo certo! A carteira está posicionada corretamente.
    Para informações sobre a carteira atual clique em "detalhes"`,
    },
  },
  runningNotPositioned: {
    label: 'Aguardando montagem',
    tooltip: {
      __html: `A carteira ainda não está posicionada.
    Está tudo certo, a carteira será montada em breve!`,
    },
  },
  stoppedPositioned: {
    label: 'Posição aberta',
    tooltip: {
      __html: `A carteira não está sendo seguida mas possui uma posição em aberto.
      <a target="_blank" href="${SI_ZENDESK_ARTICLES_URL}/4419871752343">
      Saiba mais
      </a>`,
    },
  },
};

export const MARKET_TIMEZONE = 'America/Sao_Paulo';
export const MARKET_INITIAL_MOMENT = {
  hours: 9,
  minutes: 0,
  seconds: 0,
  milliseconds: 0,
};

export const MARKET_FINAL_MOMENT = {
  hours: 18,
  minutes: 0,
  seconds: 0,
  milliseconds: 0,
};

export const ZERO_TIME = '00:00:00';

export const FINAL_ORDER_STATES = [
  'canceled', 'executed',
  'expired', 'rejected',
  'suspended', 'previous_final_state',
];

export const TABLET_WIDTH_BREAKPOINT = 768;

export const BUY_POSITION_TYPE = '0';
export const SELL_POSITION_TYPE = '1';

export const CREDIT_CARDS = [
  {
    name: 'visa',
    pattern: /^4/,
  },
  {
    name: 'mastercard',
    pattern: /^5[1-5]/,
  },
];

export const STORE_MAP_FILTER = [
  {
    id: 'offer',
    name: 'Ofertas',
    param: 'ofertas',
    uri: `${PRIVATE_URL}/loja/detalhe?filter=ofertas`,
  },
  {
    id: 'free_strategy',
    name: 'Estratégias gratuitas',
    param: 'estrategias_gratuitas',
    uri: `${PRIVATE_URL}/loja/detalhe?filter=estrategias_gratuitas`,
  },
  {
    id: 'release',
    name: 'Lançamentos',
    param: 'lancamentos',
    uri: `${PRIVATE_URL}/loja/detalhe?filter=lancamentos`,
  },
  {
    id: 'see_all',
    name: 'Ver todas',
    param: 'ver_todas',
    uri: `${PRIVATE_URL}/loja/detalhe?filter=ver_todas`,
  },
];

export const DEFAULT_PREFERENCES = {
  show_warning_to_save_params: true,
  show_confirmation_to_stop: true,
  show_confirmation_to_eliminate_position: true,
  require_password_to_stop: true,
  require_password_to_start: true,
  require_password_to_eliminate_position: true,
  default_simulation_type: DEFAULT_SIMULATION_TYPE,
};

export const PERFORMANCE_VALUES = {
  profitability: {
    name: 'net_return',
    label: 'Rentabilidade',
    tooltip: {
      text: `Sua rentabilidade histórica liquida do início da carteira até agora.
          Inclui o resultado da carteira atual.`,
    },
  },
  currentWallet: {
    name: 'net_return',
    label: 'Carteira Atual',
    tooltip: {
      text: 'Desempenho das posições em aberto. Não inclui posições fechadas.',
    },
  },
  monthlyWalletReturn: {
    name: 'monthly_return',
    label: 'No mês',
    tooltip: {
      text: 'Desempenho da carteira no mês atual',
    },
  },
  patrimony: {
    name: 'balance',
    label: 'Patrimônio',
    tooltip: {
      text: `Patrimônio inicial + rentabilidade líquida da carteira.
          Inclui o resultado da carteira atual.`,
    },
  },
  performance: {
    name: 'performance',
    label: 'Desempenho',
    tooltip: {
      text: 'Entenda mais sobre o cálculo da rentabilidade',
      textLink: 'nesse artigo.',
      path: `${SI_ZENDESK_ARTICLES_URL}/360040836494`,
    },
  },
  ordersList: {
    name: 'orders',
    label: 'Lista de Ordens',
    tooltip: {
      text: 'Entenda mais sobre a lista de ordens',
      textLink: 'nesse artigo.',
      path: `${SI_ZENDESK_ARTICLES_URL}/4419870756759`,
    },
  },
};

export const ACCUMULATED_PROFITABILITY_LABEL = 'Rentabilidade acumulada';

export const GENDERS = [
  { value: 'M', label: 'Masculino' },
  { value: 'F', label: 'Feminino' },
];

export const BROKERAGE_STATUS = {
  APPROVED: 'approved',
  PENDING: 'pending',
  PROCESSING: 'processing',
  NONE: 'none',
};
export const ANALYSIS_BROKERAGES_STATUS = [BROKERAGE_STATUS.PENDING, BROKERAGE_STATUS.PROCESSING];

export const STEP_STATUS = {
  COMPLETE: 'complete',
  INCOMPLETE: 'incomplete',
  ANALYSIS: 'analysis',
};

export const STEP_TYPES = {
  ACCOUNT_INFO: 'accountInfo',
  BROKERAGE: 'brokerage',
};

export const IS_LOADING = 'isLoading';
export const IS_SUCCESS = 'isSuccess';
export const IS_ERROR = 'isError';
export const IS_WARN = 'isWarn';

export const POSITION_PROP_RESULT = 'result';
export const POSITION_PROP_FINANCIAL_VOL = 'financial_volume';

export const ACTIVE_CAMPAIGN_LIST = 7;
export const RECAPTCHA_SITEKEY = '6LdUWMUUAAAAAEH5IGo5TNVzti0Do7qX3OCgvQTF';

export const OLD_SIGNUP_URL = '/public/beta/signUp';
export const OLD_RECOVER_PASSWORD_URL = '/public/auth/recover-password';
export const OLD_REDEFINE_PASSWORD_URL = '/public/auth/redefine-password';
export const EMAIL_REDEFINE_PASSWORD_URL = '/public/redef';
export const OLD_BETA_SELECT_URL = '/public/beta/select';

export const MSG_INVALID_DATE = 'Digite uma data válida.';

export const SIGN = 'sign';
export const FOLLOW = 'follow';
export const START_CUSTOM = 'startCustom';
export const STORE_VISIBILITY = {
  CLOSED: 'closed',
  PUBLIC: 'public',
  PRIVATE: 'private',
};

export const BACK_TEXT = 'Voltar';
export const STOP_TEXT = 'Parar';
export const STOP_AND_SELL_TEXT = 'Parar e vender tudo';
export const STOP_AND_NOT_SELL_TEXT = 'Parar e não vender';
export const ERROR = 'Erro';
export const SUCCESS = 'Sucesso';
export const ALERT = 'Alerta';
export const ALERT_DEFAULT_LOT = 'Alteração para o lote padrão';
export const DEFAULT_LOADING_TITLE = 'Parando de seguir...';
export const DEFAULT_LOADING_MSG = `
Não saia e não feche esta página até a conclusão dessa ação...`;
export const ERROR_DEFAULT_MSG = `Atualize a página e tente novamente. Se o erro persistir entre em
contato com o nosso suporte.`;
export const ERROR_SELL_POSITION_MSG = `A carteira foi parada mas não foi possível vender o ativo.
É necessário que você venda o ativo manualmente. Saiba mais aqui`;
export const ERROR_VERIFY_INSTANCE_MSG = 'Erro ao verificar a situação do robô. Tente novamente.';
export const ERROR_GET_LAST_TRADE_STOCK_MSG = 'Erro ao obter último negócio do ativo';
export const STOP_FOLLOW_INSTANCE_MSG = `Você parou de seguir a carteira. A partir de agora não
serão enviadas mais ordens dessa carteira. `;
export const STOP_SELLING_INSTANCES_LOADING_TITLE = 'Parando e vendendo os ativos...';
export const STOP_SELLING_INSTANCES_LOADING_MSG = `Estamos vendendo os ativos da carteira e parando
de segui-la. Não saia e não feche esta página até a conclusão dessa ação.`;
export const STOP_SELLING_SUCCESS_MSG = `Você parou de seguir a carteira e todos os ativos
foram vendidos.`;
export const SELLING_LOADING_TITLE = 'Vendendo posições...';
export const SELLING_LOADING_MSG = 'Estamos vendendo os ativos da sua carteira.';
export const SELLING_ERROR_MSG = `Não foi possível encerrar todas as posições. Pode ser que existam
posições abertas. Verifique no sumário da carteira.`;
export const SELLING_SUCCESS_TITLE = 'Posição vendida';
export const SELLING_SUCCESS_MSG = 'Todas as suas posições foram vendidas corretamente.';
export const CLOSED_MARKET_TITLE = 'Mercado Fechado';
export const OUT_TRADING_TIME_MAIN_MSG = 'Não é possível realizar ordens com o mercado fechado.';
export const OUT_TRADING_TIME_SECONDARY_MSG = 'Você pode parar a carteira agora escolhendo a opção "Parar e não vender" e encerrar a posição após a abertura do pregão.';
export const FOLLOWING_INSTANCE_TEXT = 'Seguindo carteira';
export const FOLLOWING_INSTANCE_RESTART_MSG = `A carteira está sendo seguida novamente.
Caso ainda esteja na janela para montagem, a carteira tentará montar a posição novamente.`;
export const CREATING_TEMPLATE_TEXT = 'Criando template';
export const ERROR_FOLLOW_INSTANCE_MSG = `Não foi possível seguir a carteira. ${ERROR_DEFAULT_MSG}`;
export const ERROR_MAX_INSTANCES_REACHED = 'Você já está operando com o limite de Carteiras do Plano contratado. Para iniciar uma outra Carteira, você deverá desligar uma das que já estão em operação.';
export const TRANSACTION_LOADING_TITLE = 'Realizando a movimentação';
export const TRANSACTION_ERROR_MESSAGE = 'Não foi possível realizar a movimentação. Atualize a página e tente novamente. Se o erro persistir entre em contato com o nosso suporte.';
export const OVER_LIMIT_TITLE = 'Limite ultrapassado';
export const OVER_LIMIT_BUTTON = 'Alterar plano';
export const WAIT_TILL_TOMORROW_TITLE = 'Aguarde até amanhã';
export const WAIT_TILL_TOMORROW_MESSAGE = `Por questões de segurança não é possível realizar movimentações
na carteira no mesmo dia que ela começou a ser seguida. A partir de amanhã você poderá realizar movimentações normalmente.`;
export const INVESTMENT_SUCCESS_TITLE = 'Investimento realizado';
export const INVESTMENT_SUCCESS_MESSAGE = 'Lembre-se de disponibilizar este valor na sua corretora.';
export const WITHDRAWAL_SUCCESS_TITLE = 'Resgate realizado';
export const WITHDRAWAL_SUCCESS_MESSAGE = 'O valor estará disponível na sua corretora 2 dias úteis após as operações.';
export const EMPTY_INSTANCES_MSG = `Não existem carteiras disponíveis para assinatura nessa
categoria`;
export const EMPTY_SIGNED_INSTANCES_MSG = 'Você não possui nenhuma assinatura ativa';
export const CAPTCHA_ERROR_MSG = 'Mostre que você não é um robô.';
export const GENERATE_WALLETS_ERROR = 'Não foi possível gerar as carteiras, tente novamente.';
export const SUBSCRIBERS_SEE_STOCKS_MESSAGE = 'Somente assinantes da carteira podem ver a sua composição atual.';

export const INSTANCE_REBALANCE_MSG = 'A carteira será rebalanceada agora, ou seja, serão enviadas ordens de compra e venda para atingir a composição teórica da carteira.';
export const INSTANCE_NOT_REBALANCE_MSG = 'A carteira não será rebalanceada agora, ou seja, nenhuma ordem será enviada agora. Posteriormente você poderá definir quando rebalancear ou alterar ativos e pesos.';
export const INSUFFICIENT_BALANCE_MSG = 'O volume da ordem ultrapassa o valor em saldo da sua carteira. Realize um novo aporte para executar essa operação.';
export const INSUFFICIENT_NUMBER_OF_STOCKS_MSG = 'A quantidade da ordem ultrapassa a quantidade de ativos da sua carteira.';

export const TOTAL_EQUITY_LABEL = 'Patrimônio total';
export const TOTAL_EQUITY_TOOLTIP = 'O valor do patrimônio é a soma do patrimônio atual de todas as suas carteiras ativas';

export const LOGIN_CODE_ERROR = ['ss_000013', 'ss_000027'];

export const WEEKEND_DAYS = [0, 6];

export const DEFAULT_PARAMS_INSTANCE = {
  PORTFOLIO_financial_value: '10000.00',
  PORTFOLIO_min_operation_value: '10.00',
  PORTFOLIO_number_of_assets: '1',
  PORTFOLIO_operation_delay: '30',
  PORTFOLIO_prev_stocks: 'BOVA11',
  PORTFOLIO_stocks: 'BOVA11',
  PORTFOLIO_time_to_start: '10:20:00',
  PORTFOLIO_last_time_to_start: '18:00:00',
  PORTFOLIO_weights: '100',
  lot_sizes: '1',
  change_portfolio: false,
  force_mount_portfolio: false,
  marketName: 'BOVESPA',
  stockCodesList: 'BOVA11',
  __types__: {
    marketName: 'buttonGroup',
    change_portfolio: 'switch',
    PORTFOLIO_financial_value: 'number:2',
    force_mount_portfolio: 'switch',
    PORTFOLIO_number_of_assets: 'number:0',
    PORTFOLIO_prev_stocks: 'text',
    PORTFOLIO_stocks: 'text',
    PORTFOLIO_weights: 'text',
    lot_sizes: 'text',
    stockCodesList: 'text',
    PORTFOLIO_operation_delay: 'number:0',
    PORTFOLIO_min_operation_value: 'number:2',
    PORTFOLIO_time_to_start: 'time',
    PORTFOLIO_last_time_to_start: 'time',
  },
};

export const POSITION_PARAMS = {
  average_price: 'average_price',
  company_name: 'company_name',
  financial_volume: 'financial_volume',
  number_of_stocks: 'number_of_stocks',
  price: 'price',
  result: 'result',
  variation: 'variation',
};

export const REQUIRED_FIELD_TEXT = 'Campo obrigatório';
export const INVALID_CPF_TEXT = 'CPF inválido';
export const INVALID_PASSWORD_TEXT = 'Senha inválida';
export const PASSWORD_MIN_SIX_CHAR = 'Senha deve ter no mínimo 6 caracteres';
export const SPECIAL_CHARACTER_TEXT = 'Caracter Special';
export const SPECIAL_CHARACTER_ERROR = 'A senha não pode conter os seguintes caracteres: #^()+=~/`;:"/.>,<';

export const RIGHT = 'right';
export const LEFT = 'left';
export const CENTER = 'center';

export const LOWER_MOBILE = 'lowerMobile';
export const MOBILE = 'mobile';
export const DESKTOP = 'desktop';
export const DEFAULT = 'default';

export const PLAN_UPDATE_BUTTON_PARAMS = {
  current: {
    text: 'Atual',
    variant: 'contained',
    disabled: true,
  },
  downgrade: {
    text: 'Reduzir',
    variant: 'outlined',
    color: 'secondary',
  },
  upgrade: {
    text: 'Aumentar',
    variant: 'contained',
    color: 'primary',
  },
};

export const POSITION_VALUES = {
  number_of_stocks: {
    id: POSITION_PARAMS.number_of_stocks,
    name: 'Quantidade',
    align: LEFT,
  },
  average_price: {
    id: POSITION_PARAMS.average_price,
    name: 'Preço médio',
    align: CENTER,
  },
  price: {
    id: POSITION_PARAMS.price,
    name: 'Cotação',
    align: RIGHT,
  },
};

export const ASC = 'asc';
export const DESC = 'desc';
export const DEFAULT_LOGO = 'DEFAULT.svg';
export const BEST_POSITION_KEY = 'best';
export const WORST_POSITION_KEY = 'worst';

export const COMPANY_IMAGES_PATH = 'companies-images';
export const STORE_CATEGORIES_IMAGES_PATH = 'store-categories';

export const REAL_SYMBOL = 'R$';

export const EMPTY_POSITION_CARD = {
  totalVariation: 0,
  totalResult: 0,
  company: 'Carteira vazia',
  best: true,
  image: emptyPositionLogo,
  key: 'best',
};

export const CHART_TIME_FILTERS = {
  ALL: {
    filter: 'all',
    label: 'TUDO',
  },
  YEAR: {
    filter: 'year',
    label: 'ANO',
  },
  MONTH: {
    filter: 'month',
    label: 'MÊS',
  },
  WEEK: {
    filter: 'week',
    label: 'SEMANA',
  },
};

export const PERFORMANCE_CHART_SELECTION = {
  netReturn: {
    label: '%',
    filter: 'net return',
  },
  financial: {
    label: REAL_SYMBOL,
    filter: 'financial',
  },
};

export const DEFAULT_CHART_COLORS = ['#33d9b2', '#34ace0', '#ff5252', '#706fd3', '#ffb142',
  '#34ace0', '#d1ccc0', '#218c74', '#34ace0', '#b33939', '#2c2c54', '#cd6133', '#84817a', '#cc8e35',
  '#aaa69d', '#ccae62', '#2ef08b', '#2df0f7', '#e83f93', '#718feb'];

export const DEFAULT_EMPTY_CHART_COLOR = ['#caccd1'];
export const EMPTY_CHART_VALUE = [{ code: 'EMPTY', weight: 100 }];

export const DEFAULT_PRIVATE_CHART_COLOR = ['#232826', '#555D5B', '#959C9D', '#C2C5C6'];
export const PRIVATE_CHART_VALUE = [
  { code: 'EMPTY', weight: 100 },
  { code: 'EMPTY', weight: 100 },
  { code: 'EMPTY', weight: 100 },
  { code: 'EMPTY', weight: 100 }];

export const MSG_MOUNTING_INSTANCE = 'As carteiras podem ser montadas até 15:30. Após esse horário, a sua carteira será montada no próximo dia útil às 10:30';
export const NOW_TEXT = 'Agora';
export const TODAY_TEXT = 'Hoje';

export const SELL_ALL = 'sellAll';

export const START_MOUNTING_INSTANCE = { hours: 10, minutes: 30, seconds: 0 };
export const END_MOUNTING_INSTANCE = { hours: 15, minutes: 29, seconds: 45 };

export const IBOV_WALLET_ID = 1396205;
export const SMLL_WALLET_ID = 1861429;
export const IFIX_WALLET_ID = 1861287;
export const CDI_WALLET_ID = 1861420;

export const SHOWCASE_TABS = {
  [STORE_TAB]: {
    name: STORE_TAB,
    label: 'LOJA',
  },
  [RANKING_TAB]: {
    name: RANKING_TAB,
    label: 'COMPARADOR DE CARTEIRAS',
    route: RANKING_TAB,
  },
  [SIGNED_INSTANCES_TAB]: {
    name: SIGNED_INSTANCES_TAB,
    label: 'CARTEIRAS ASSINADAS',
    route: SIGNED_INSTANCES_ROUTE,
  },
};

export const CHECK_ALL = 'checkAll';
export const EQUALIZE_TEXT = 'Equalizar';

export const ALL = 'all';

export const RADIO_TYPE = 'radio';
export const CHECKBOX_TYPE = 'checkbox';
export const CHECKBOX_IMAGE_TYPE = 'checkboxImage';
export const SLIDER_TYPE = 'slider';

// ============================== //
export const CATEGORY_NOT_ENABLED = 0;
export const CATEGORY_ENABLED = 1;
export const CUSTOM_CATEGORY_ID = 3;

export const LOCAL_STORAGE_WHITE_BOX = 'localStorageWhiteBox';
export const LOCALHOST = 'localhost:8089';

export const STOCK_MODES = {
  SEARCH: 'search',
  UPDATE: 'update',
  VIEW: 'view',
};

export const RANKING_TABLE_HEAD = {
  PORTFOLIO: {
    label: 'Carteira',
    align: 'left',
    field: 'name',
    order: DESC,
  },
  FREQUENCY: {
    label: 'Frequência',
    align: 'left',
    field: 'frequency',
    order: DESC,
  },
  MINIMUM_CAPITAL: {
    label: 'Mínimo/ Ativos',
    align: 'left',
    field: 'minimumCapital',
    order: DESC,
  },
  BEST_MONTH: {
    label: 'Melhor mês',
    align: 'left',
    field: 'bestMonth',
    order: DESC,
  },
  WORST_MONTH: {
    label: 'Pior mês',
    align: 'left',
    field: 'worstMonth',
    order: DESC,
  },
  ANNUALIZED_RETURN: {
    label: 'Rentabilidade Anualizada',
    align: 'left',
    field: 'annualizedReturn',
    order: DESC,
  },
  ORDER_VOLUME: {
    label: 'Volume de Ordens',
    align: 'left',
    field: 'orderVolume',
    order: DESC,
  },
  RISK: {
    label: 'Risco',
    align: 'center',
    field: 'risk',
    order: DESC,
  },
  ICON: {
    label: '',
    align: 'left',
  },
};

export const WHITEBOX_INSTANCE_STRATEGY_ID = 588;
export const PERSONALIZED_INSTANCE_STRATEGY_ID = 1203;

export const REQUIRED_TEXT = 'Obrigatório';
export const SUGGESTED_WALLETS = [
  {
    type: 'leastRisk',
    title: 'Menor risco',
    subtitle: 'Carteira projetada para obter a menor volatilidade.',
    link: `${SI_ZENDESK_ARTICLES_URL}/4419892735383`,
  },
  {
    type: 'bestReturnRisk',
    title: 'Melhor relação retorno/risco',
    subtitle: 'Carteira projetada para obter o maior sharpe.',
    link: `${SI_ZENDESK_ARTICLES_URL}/4419870241431`,
  },
  {
    type: 'greatestReturn',
    title: 'Maior rentabilidade',
    subtitle: 'Carteira projetada para obter a maior rentabilidade.',
    link: `${SI_ZENDESK_ARTICLES_URL}/4419892608023`,
  },
];

export const COMPARE_WITH = 'Comparar com:';
export const SHOW_CHANGES = 'Ver mudanças';

export const INCREASE = 'increase';
export const DECREASE = 'decrease';

export const TOTAL_ORDER_VOLUME = 'Volume total da ordem';
export const RESERVED_BALANCE_INSTANCE = 'Reservado em saldo da carteira';
export const CONTINUE_TO_REVISION_BUTTON_TEXT = 'Continuar para revisão';

export const CONTINUE_TEXT = 'Continuar';
export const EXCLUSIVE_WALLET_TEXT = 'Carteira exclusiva';

export const MAX_RUNNING_INSTANCES_ERROR_CODE = 'instance_max_running_limit_strategy';
