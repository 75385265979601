import { RISK_LIST_FILTER } from './filter';

export const QUESTIONNAIRE = 'questionnaire';
export const SI_INVESTOR_PROFILE = 'si_investor_profile';

export const INVESTOR_PROFILE_QUESTIONNAIRE_ERROR = 'Ocorreu um erro ao processar suas respostas. Por favor, tente novamente mais tarde.';

export const STEPS_INVESTOR_PROFILE_QUESTIONNAIRE_INITIAL_STATE = {
  currentStep: 1,
  numberOfSteps: 4,
  answers: {
    1: null,
    2: null,
    3: null,
    4: null,
  },
};

export const QUESTIONNAIRE_DATA = {
  1: {
    question: 'Há quanto tempo você já investe algum destes produtos: Ações, ETFs, FIIs, BDR?',
    answers: {
      text: {
        A: 'Nunca investi em nenhum destes produtos',
        B: 'Menos de 6 meses',
        C: 'Entre 6 meses e 1 ano',
        D: 'Entre 1 e 3 anos',
        E: 'Mais de 5 anos',
      },
      weight: {
        A: 0.1,
        B: 0.3,
        C: 0.5,
        D: 0.8,
        E: 1,
      },
    },
  },
  2: {
    question: 'Qual das opções abaixo representa melhor seu conhecimento de mercado financeiro?',
    answers: {
      text: {
        A: 'Ainda não entendo nem possuo experiência no assunto',
        B: 'Conheço pouco através de vídeos e artigos na internet',
        C: 'Leio relatórios de casas de análise',
        D: 'Já consigo montar minhas próprias carteiras',
      },
      weight: {
        A: 0.1,
        B: 0.5,
        C: 0.8,
        D: 1,
      },
    },
  },
  3: {
    question: `Imagine que você investiu R$10.000 em uma carteira na SmarttInvest. Alguns meses
               depois essa carteira que você escolheu acumula uma perda de 20% e você perdeu
               R$2.000. Qual seria sua reação?`,
    answers: {
      text: {
        A: 'Encerro a carteira e resgato tudo',
        B: 'Sigo com a carteira, mas resgato uma parte',
        C: 'Mantenho como está',
        D: 'Aporto mais dinheiro na carteira',
      },
      weight: {
        A: 0.1,
        B: 0.4,
        C: 0.8,
        D: 1,
      },
    },
  },
  4: {
    question: 'Com qual dos objetivos abaixo você mais se identifica para seus investimentos na SmarttInvest?',
    answers: {
      text: {
        A: 'Pretendo ter uma reserva de emergência',
        B: 'Pretendo realizar um objetivo a curto prazo (1 a 3 anos)',
        C: 'Pretendo realizar um objetivo a médio prazo (3 a 5 anos)',
        D: 'Pretendo realizar um objetivo a longo prazo (mais de 5 anos)',
      },
      weight: {
        A: 0.1,
        B: 0.5,
        C: 0.8,
        D: 1,
      },
    },
  },
};

export const INVESTOR_PROFILE_TYPES = {
  1: {
    id: 1,
    name: 'Muito conservador',
    description: 'Segurança é a palavra de ordem quando se trata da sua vida de investimentos. Seu perfil não tolera qualquer tipo de risco, diminuindo assim a volatilidade e, consequentemente, a rentabilidade dos seus investimentos.\nNão tenha tanto medo de conhecer investimentos diferentes, estude e vá ganhando segurança aos poucos.\nNa SmarttInvest, é recomendado que concentre seus investimentos nas carteiras de risco baixo. Com o passar do tempo e a partir do momento que você começa a ganhar confiança, separe uma pequena parte para as carteiras de risco moderado e alto.',
    articleId: '4419869802263',
    risk: [
      RISK_LIST_FILTER.low,
      RISK_LIST_FILTER.moderate,
    ],
  },
  2: {
    id: 2,
    name: 'Conservador',
    description: 'Você preza por investimentos seguros, reduzindo seu risco de lidar com a desvalorização do seu patrimônio.\nAo limitar seu risco e a volatilidade do seu portfólio, você também está limitando o potencial de rentabilidade da sua carteira. \nUma combinação de tempo e prática com produtos financeiros levemente mais arriscados podem te auxiliar no desenvolvimento de tomadas de decisões mais assertivas, evitando decisões com viés emocional.\nNa SmarttInvest, é recomendado que concentre seus investimentos nas carteiras de risco baixo e risco moderado. Com o passar do tempo e a partir do momento que você começa a ganhar confiança, separe uma pequena parte para as carteiras de risco alto e muito alto.',
    articleId: '4419892256791',
    risk: [
      RISK_LIST_FILTER.low,
      RISK_LIST_FILTER.moderate,
    ],
  },
  3: {
    id: 3,
    name: 'Moderado',
    description: 'Você se encontra no "meio da jornada". Apesar de conhecer e aplicar em produtos com maior volatilidade, seu principal investimento se concentra em baixo risco.\nSua exposição em ativos de maior volatilidade é um complemento essencial para sua carteira, desde que alinhados com uma tomada de decisão sem viés emocional.\nNa SmarttInvest, é recomendado que concentre seus investimentos nas carteiras de risco baixo, moderado e alto. Com o passar do tempo e a partir do momento que você começa a ganhar confiança, separe uma parte para as carteiras de risco muito alto.',
    articleId: '4419892240151',
    risk: [
      RISK_LIST_FILTER.low,
      RISK_LIST_FILTER.moderate,
      RISK_LIST_FILTER.high,
    ],
  },
  4: {
    id: 4,
    name: 'Arrojado',
    description: 'Seu perfil lida bem com a volatilidade de ativos mais arriscados e suas decisões não são facilmente influenciadas pela emoção.\nCom isso o investimento em ativos com maior risco/retorno se torna uma opção interessante para potencializar seu patrimônio no longo prazo.\nNa SmarttInvest, é recomendado que concentre seus investimentos nas carteiras de risco alto e muito alto. De qualquer forma, as carteiras de risco baixo e moderado podem ser bastante complementares na sua carteira.',
    articleId: '4419869722647',
    risk: [
      RISK_LIST_FILTER.low,
      RISK_LIST_FILTER.moderate,
      RISK_LIST_FILTER.high,
      RISK_LIST_FILTER.veryHigh,
    ],
  },
  5: {
    id: 5,
    name: 'Muito arrojado',
    description: 'Investimentos de alto risco não são um impedimento para sua atuação.\nEntretanto, é importante ressaltar: risco pode significar mais retorno, porém, é importante ter uma reserva de dinheiro sempre garantida e à mão para momentos turbulentos.\nNa SmarttInvest, é recomendado que concentre seus investimentos nas carteiras de risco alto e muito alto. De qualquer forma, as carteiras de risco baixo e moderado podem ser bastante complementares na sua carteira.',
    articleId: '4419892205591',
    risk: [
      RISK_LIST_FILTER.low,
      RISK_LIST_FILTER.moderate,
      RISK_LIST_FILTER.high,
      RISK_LIST_FILTER.veryHigh,
    ],
  },
};
