import axios from 'axios';
import { get } from 'lodash';
import { checkForcedExternalLogout } from 'common/ducks/auth';
import logdown from 'logdown';

import {
  backtestInterpreter,
  instanceInterpreter,
  billingInterpreter,
  ssInterpreter,
} from './error';

export default {
  createInterceptor: (store) => axios.interceptors.response.use(
    null,
    (responseError) => {
      const logger = logdown('interceptor', { prefixColor: '#0000FF' });
      // This return expection is for cancel requests error handling that does
      // not return a response attribute
      if (!responseError.response) {
        return Promise.reject(responseError);
      }
      let { response: { data: { error } } } = responseError;
      error = error || {};

      const { response: { status } } = responseError;

      if (error && error.code === 'unlogged') {
        logger.log('unlogged');
        store.dispatch(checkForcedExternalLogout());
      }

      const profile = get(store.getState(), 'user.profile.data', {});
      switch (true) {
        case error.code === 'captcha0':
          error.codeMessage = 'Mostre que você não é um robô.';
          break;
        case error.code === 'captcha1':
          error.codeMessage = 'Erro de captcha.';
          break;
        case error.code === 'password_expired':
          error.codeMessage = 'A sua senha expirou.';
          break;
        case error.code === 'login_blocked':
          error.codeMessage = 'A sua conta foi bloqueada.';
          break;
        case error.code === 'resetpassword0':
          error.codeMessage = 'Seu token expirou. Clique novamente no link \'Esqueci minha senha\' que um novo e-mail será enviado para a sua conta.';
          break;
        case (/^ss/g).test(error.code):
          error.codeMessage = ssInterpreter(error.code);
          break;
        case (/^instance/g).test(error.code):
          error.codeMessage = instanceInterpreter(error.code, profile);
          break;
        case (/^backtest/g).test(error.code):
          error.codeMessage = backtestInterpreter(error.code, profile);
          break;
        case (/^billing/g).test(error.code):
          error.codeMessage = billingInterpreter(error.code);
          break;
        default:
          error.codeMessage = false;
      }

      error.status = status;
      logger.log('Response error', responseError, error, responseError.response);

      return Promise.reject(error);
    },
  ),
};
