import accounting from 'accounting';
import isEmpty from 'lodash/isEmpty';
import { STOPPED_STATES, RUNNING_STATES } from './constants';

export const FULL_REPORT_ATTRIBUTES = [
  'initial_capital',
  'balance',
  'equity',
  'taxes_and_operational_costs',
  'gross_return',
  'gross_daily_return',
  'gross_annualized_return',
  'net_return',
  'net_daily_return',
  'net_annualized_return',
  'profit_factor',
  'expected_payoff',
  'absolute_initial_drawdown',
  'absolute_maximum_drawdown',
  'percentual_initial_drawdown',
  'percentual_maximum_drawdown',
  'number_of_eliminations',
  'gross_profit',
  'gross_loss',
  'total_gross_profit',
  'net_profit',
  'net_loss',
  'total_net_profit',
  'absolute_number_of_profit_eliminations',
  'percentual_number_of_profit_eliminations',
  'average_profit_in_profit_eliminations',
  'absolute_largest_profit_elimination',
  'percentual_largest_profit_elimination',
  'maximum_consecutive_profit_eliminations',
  'total_profit_in_maximum_consecutive_profit_eliminations',
  'absolute_number_of_loss_eliminations',
  'percentual_number_of_loss_eliminations',
  'average_loss_in_loss_eliminations',
  'absolute_largest_loss_elimination',
  'percentual_largest_loss_elimination',
  'maximum_consecutive_loss_eliminations',
  'total_loss_in_maximum_consecutive_loss_eliminations',
  'absolute_number_of_eliminations_of_long_positions',
  'percentual_number_of_eliminations_of_long_positions',
  'absolute_number_of_profit_eliminations_of_long_positions',
  'percentual_number_of_profit_eliminations_of_long_positions',
  'absolute_number_of_loss_eliminations_of_long_positions',
  'percentual_number_of_loss_eliminations_of_long_positions',
  'absolute_number_of_eliminations_of_short_positions',
  'percentual_number_of_eliminations_of_short_positions',
  'absolute_number_of_profit_eliminations_of_short_positions',
  'percentual_number_of_profit_eliminations_of_short_positions',
  'absolute_number_of_loss_eliminations_of_short_positions',
  'percentual_number_of_loss_eliminations_of_short_positions',
  'total_contributions',
  'total_withdraws',
];

export const REPORT_RETURN_ATTRIBUTES = [
  'initial_capital',
  'day_trade_equity',
  'net_return',
  'percentual_maximum_drawdown',
  'number_of_eliminations',
  'net_annualized_return',
  'today_number_of_eliminations',
  'percentual_number_of_profit_eliminations',
  'equity',
  'balance',
  'profit_factor',
  'total_contributions',
  'total_withdraws',
  'taxes_and_operational_costs',
];

export const HASH_MAP_REPORT_ATTRIBUTES = {
  initial_capital: { name: 'Saldo Inicial (R$)', format: (value) => accounting.formatNumber(value, 2) },
  balance: { name: 'Patrimônio (R$)', format: (value) => accounting.formatNumber(value, 2) },
  taxes_and_operational_costs: { name: 'Taxas/Custos Operacionais (R$)', format: (value) => accounting.formatNumber(value, 2) },
  total_contributions: { name: 'Aportes (R$)', format: (value) => accounting.formatNumber(value, 2) },
  total_withdraws: { name: 'Retiradas (R$)', format: (value) => accounting.formatNumber(value, 2) },
  gross_return: { name: 'Retorno Bruto (%)', format: (value) => accounting.formatNumber(value * 100, 2) },
  gross_daily_return: { name: 'Retorno Bruto Dia (%)', format: (value) => accounting.formatNumber(value * 100, 2) },
  gross_annualized_return: { name: 'Retorno Bruto Ano (%)', format: (value) => accounting.formatNumber(value * 100, 2) },
  percentual_net_return: { name: 'Retorno Líquido (%)', format: (value) => accounting.formatNumber(value * 100, 2) },
  net_daily_return: { name: 'Retorno Líquido Dia (%)', format: (value) => accounting.formatNumber(value * 100, 2) },
  net_annualized_return: { name: 'Retorno Líquido Ano (%)', format: (value) => accounting.formatNumber(value * 100, 2) },
  profit_factor: { name: 'Fator de Lucro', format: (value) => accounting.formatNumber(value, 2) },
  expected_payoff: { name: 'Resultado Esperado (R$)', format: (value) => accounting.formatNumber(value, 2) },
  absolute_initial_drawdown: { name: 'Drawdown Inicial (R$)', format: (value) => accounting.formatNumber(value, 2) },
  absolute_maximum_drawdown: { name: 'Drawdown Máximo (R$)', format: (value) => accounting.formatNumber(value, 2) },
  percentual_initial_drawdown: { name: 'Drawdown Inicial Percentual (%)', format: (value) => accounting.formatNumber(value * 100, 2) },
  percentual_maximum_drawdown: { name: 'Drawdown Máximo Percentual (%)', format: (value) => accounting.formatNumber(value * 100, 2) },
  number_of_eliminations: { name: 'Número Total de Trades', format: (value) => accounting.formatNumber(value, 0) },
  gross_profit: { name: 'Lucro Bruto (R$)', format: (value) => accounting.formatNumber(value, 2) },
  gross_loss: { name: 'Prejuízo Bruto (R$)', format: (value) => accounting.formatNumber(value, 2) },
  total_gross_profit: { name: 'Balanço Bruto (R$)', format: (value) => accounting.formatNumber(value, 2) },
  net_profit: { name: 'Lucro Líquido (R$)', format: (value) => accounting.formatNumber(value, 2) },
  net_loss: { name: 'Prejuízo Líquido (R$)', format: (value) => accounting.formatNumber(value, 2) },
  total_net_profit: { name: 'Balanço Líquido (R$)', format: (value) => accounting.formatNumber(value, 2) },
  absolute_number_of_profit_eliminations: { name: 'Número Total de Trades Com Lucro', format: (value) => value },
  percentual_number_of_profit_eliminations: { name: 'Percentual de Trades com Lucro (%)', format: (value) => accounting.formatNumber(value * 100, 2) },
  average_profit_in_profit_eliminations: { name: 'Lucro Médio (R$)', format: (value) => accounting.formatNumber(value, 2) },
  absolute_largest_profit_elimination: { name: 'Lucro Máximo (R$)', format: (value) => accounting.formatNumber(value, 2) },
  percentual_largest_profit_elimination: { name: 'Lucro Máximo Percentual (%)', format: (value) => accounting.formatNumber(value * 100, 2) },
  maximum_consecutive_profit_eliminations: { name: 'Número Máximo de Trades Com Lucros Consecutivos', format: (value) => accounting.formatNumber(value, 0) },
  total_profit_in_maximum_consecutive_profit_eliminations: { name: 'Lucro Total Consecutivo (R$)', format: (value) => accounting.formatNumber(value, 2) },
  absolute_number_of_loss_eliminations: { name: 'Número Total de Trades Com Prejuízo', format: (value) => accounting.formatNumber(value, 0) },
  percentual_number_of_loss_eliminations: { name: 'Percentual de Trades Com Prejuízo (%)', format: (value) => accounting.formatNumber(value * 100, 2) },
  average_loss_in_loss_eliminations: { name: 'Prejuízo Médio (R$)', format: (value) => accounting.formatNumber(value, 2) },
  absolute_largest_loss_elimination: { name: 'Prejuízo Máximo (R$)', format: (value) => accounting.formatNumber(value, 2) },
  percentual_largest_loss_elimination: { name: 'Prejuízo Máximo Percentual (%)', format: (value) => accounting.formatNumber(value * 100, 2) },
  maximum_consecutive_loss_eliminations: { name: 'Número Máximo de Trades Com Prejuízo Consecutivos', format: (value) => accounting.formatNumber(value, 0) },
  total_loss_in_maximum_consecutive_loss_eliminations: { name: 'Prejuízo Total Consecutivo (R$)', format: (value) => accounting.formatNumber(value, 2) },
  absolute_number_of_eliminations_of_long_positions: { name: 'Número Total de Trades Comprados', format: (value) => accounting.formatNumber(value, 0) },
  percentual_number_of_eliminations_of_long_positions: { name: 'Percentual de Trades Comprados (%)', format: (value) => accounting.formatNumber(value * 100, 2) },
  absolute_number_of_profit_eliminations_of_long_positions: { name: 'Trades Com Lucro Em Trades Comprados', format: (value) => accounting.formatNumber(value, 0) },
  percentual_number_of_profit_eliminations_of_long_positions: { name: 'Percentual de Trades Com Lucro Em Trades Comprados (%)', format: (value) => accounting.formatNumber(value * 100, 2) },
  absolute_number_of_loss_eliminations_of_long_positions: { name: 'Trades Com Prejuízo em Trades Comprados', format: (value) => accounting.formatNumber(value, 0) },
  percentual_number_of_loss_eliminations_of_long_positions: { name: 'Percentual de Trades Com Prejuízo Em Trades Comprados (%)', format: (value) => accounting.formatNumber(value * 100, 2) },
  absolute_number_of_eliminations_of_short_positions: { name: 'Número Total de Trades Vendidos', format: (value) => accounting.formatNumber(value, 0) },
  percentual_number_of_eliminations_of_short_positions: { name: 'Percentual de Trades Vendidos (%)', format: (value) => accounting.formatNumber(value * 100, 2) },
  absolute_number_of_profit_eliminations_of_short_positions: { name: 'Trades Com Lucro Em Trades Vendidos', format: (value) => accounting.formatNumber(value, 0) },
  percentual_number_of_profit_eliminations_of_short_positions: { name: 'Precentual de Trades Com Lucro em Trades Vendidos (%)', format: (value) => accounting.formatNumber(value * 100, 2) },
  absolute_number_of_loss_eliminations_of_short_positions: { name: 'Trades Com Prejuízo Em Trades Vendidos', format: (value) => accounting.formatNumber(value, 0) },
  percentual_number_of_loss_eliminations_of_short_positions: { name: 'Percentual de Trades Com Prejuízo em Trades Vendidos (%)', format: (value) => accounting.formatNumber(value * 100, 2) },
};

export const getReportInstanceIds = (report) => {
  return Object.keys(report).reduce((acc, key) => {
    if (Number.isNaN(Number(key))) return acc;

    return {
      ...acc,
      [key]: report[key],
    };
  }, {});
};

export const getReportTotalItem = (instanceId, state, value) => {
  const updatedState = {
    ...state,
    [instanceId]: { ...state[instanceId] },
  };

  const report = getReportInstanceIds(updatedState);

  return Object.values(report).reduce((acc, current) => {
    return acc + (Number(current?.data[value]) ?? 0);
  }, 0);
};

export const getReportRunningInstances = ({ report, instances }) => {
  return Object.keys(report).reduce((acc, key) => {
    if (!RUNNING_STATES.includes(instances.data[key]?.state)) return acc;

    return {
      ...acc,
      [key]: report[key],
    };
  }, {});
};

export const checkIsAllReportsLoaded = ({ report }) => {
  const reportIds = getReportInstanceIds(report);

  return isEmpty(reportIds) ? false : Object.keys(report).every((key) => {
    return !isEmpty(reportIds[key]?.data);
  });
};

export const checkIsStoppedInstanceOrEmptyData = (action) => {
  return !action.instanceState || STOPPED_STATES.includes(action.instanceState)
  || isEmpty(action.data);
};

export const getAnnualizedReturn = ({
  startDate,
  endDate,
  profitabilityValue,
  report,
  isCustomBacktest,
}) => {
  if (!isCustomBacktest) return report.data?.net_annualized_return * 100;

  const diffDays = endDate.diff(startDate, 'days');

  return ((1 + profitabilityValue) ** (1 / (diffDays / 365)) - 1) * 100;
};

export default null;
