import { withStyles } from '@material-ui/core/styles';

import ChartTooltip from './ChartTooltip';

const styles = {
  tooltip: {
    minWidth: 210,
    minHeight: 55,
    padding: 11,
  },
  label: {
    fontSize: 13,
    fontWeight: 'bold',
  },
  content: {
    borderStyle: 'solid',
    borderWidth: '0 0 0 3px',
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: 6,
    height: 12,
    alignItems: 'center',
  },
  key: {
    fontSize: 13,
    marginLeft: 3,
    marginRight: 10,
  },
  extraData: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: 10,
  },
};

export default withStyles(styles)(ChartTooltip);
