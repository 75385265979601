import React from 'react';
import PropTypes from 'prop-types';

import loadingGif from 'common/assets/images/progress_dialog/loading.gif';
import successIcon from 'common/assets/images/checked-icon.svg';
import errorIcon from 'common/assets/images/error.svg';

import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import CloseDialogButton from 'common/components/Buttons/CloseDialogButton';
import { Typography, Button, Box } from '@material-ui/core';

import {
  IS_LOADING,
  IS_SUCCESS,
  IS_ERROR,
  IS_WARN,
} from 'common/utils/constants';

const ProgressDialog = ({
  classes,
  open,
  state,
  title,
  message,
  showButton,
  buttonMessage,
  buttonClick,
  showCloseIcon,
  customIcon,
  onClose,
  closeOnClickBackdrop,
  closeAfterClick,
  secondaryMessage,
  extraButton,
  buttonVariant,
}) => {
  const states = {
    isLoading: {
      id: IS_LOADING,
      icon: loadingGif,
      altLabel: 'Carregando',
      className: classes.iconLoading,
    },
    isSuccess: {
      id: IS_SUCCESS,
      icon: successIcon,
      altLabel: 'Sucesso',
      className: classes.icon,
    },
    isError: {
      id: IS_ERROR,
      icon: errorIcon,
      altLabel: 'Erro',
      className: classes.icon,
    },
    isWarn: {
      id: IS_WARN,
      icon: customIcon,
      altLabel: 'Atenção',
      className: classes.icon,
    },
  };

  const currentState = states[state];
  const stateIsLoading = currentState.id === IS_LOADING;
  const isComposedMessage = message && typeof message !== 'string';

  const onclick = () => {
    buttonClick();
    if (closeAfterClick) onClose();
  };

  return (
    <Dialog
      classes={{ paper: classes.paper }}
      open={open}
      onClose={closeOnClickBackdrop && !stateIsLoading ? onClose : null}
    >
      { !stateIsLoading && showCloseIcon && <CloseDialogButton onClose={onClose} /> }
      <DialogContent className={classes.content}>
        <img
          id="icon"
          src={currentState.icon}
          alt={currentState.altLabel}
          className={currentState.className}
        />
        <Typography className={classes.title} variant="h2" color="textPrimary">{title}</Typography>
        <Box>
          { !isComposedMessage
            && (
            <span
              id="message"
              className={classes.message}
              dangerouslySetInnerHTML={{ __html: message }}  //eslint-disable-line
            >
            </span>
            )}

          { isComposedMessage
            && (
            <span
              id="message"
              className={classes.message}
            >
              {message }
            </span>
            )}

          {secondaryMessage && (
          <span>
            &nbsp;
            {secondaryMessage}
          </span>
          )}
        </Box>

        {!stateIsLoading && showButton && (
          <Button
            variant={buttonVariant}
            color="primary"
            className={classes.button}
            onClick={buttonClick ? onclick : onClose}
          >
            {buttonMessage}
          </Button>
        )}

        {!stateIsLoading && extraButton }
      </DialogContent>
    </Dialog>
  );
};

ProgressDialog.propTypes = {
  classes: PropTypes.object.isRequired,
  open: PropTypes.bool.isRequired,
  state: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  message: PropTypes.any,
  showButton: PropTypes.any,
  buttonMessage: PropTypes.string,
  buttonClick: PropTypes.func,
  showCloseIcon: PropTypes.bool,
  customIcon: PropTypes.any,
  onClose: PropTypes.func,
  closeOnClickBackdrop: PropTypes.bool,
  closeAfterClick: PropTypes.bool,
  secondaryMessage: PropTypes.any,
  extraButton: PropTypes.any,
  buttonVariant: PropTypes.string,
};

ProgressDialog.defaultProps = {
  message: '',
  showButton: false,
  buttonMessage: 'Ok',
  buttonClick: null,
  showCloseIcon: true,
  customIcon: '',
  onClose: () => null,
  closeOnClickBackdrop: true,
  closeAfterClick: false,
  secondaryMessage: '',
  extraButton: null,
  buttonVariant: 'outlined',
};

export default ProgressDialog;
