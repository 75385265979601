import React from 'react';

import FilledCircleIndicator from 'common/components/FilledCircleIndicator';
import { getCustomFormattedDate } from 'common/utils/date';
import {
  STORE_INSTANCE_TAGS as TAGS,
  NUMBER_OF_STOCKS,
  NEXT_ROLLOUT,
  ORDER_VOLUME,
} from 'common/utils/constants/tags';
import { RISK_LIST_FILTER } from './constants/filter';

export const getNumberOfStocksRange = (tags) => {
  if (!tags.max_stocks) {
    return tags.number_of_stocks;
  }
  return `De ${tags.number_of_stocks} a ${tags.max_stocks}`;
};

export const getLabel = ({ item, tags }) => {
  switch (item) {
    case NUMBER_OF_STOCKS:
      return `${getNumberOfStocksRange(tags)} ${TAGS[item].text}`;

    case NEXT_ROLLOUT:
      return `${TAGS[item].text}: ${getCustomFormattedDate(tags[item])}`;

    case ORDER_VOLUME:
      return (
        <span style={{ display: 'inline-flex', alignItems: 'center' }}>
          <span style={{ marginRight: 6 }}>{`${TAGS[item].text}:`}</span>
          <FilledCircleIndicator filledInRate={tags[item]} showLabel={false} />
        </span>
      );

    default:
      return `${TAGS[item].text} ${tags[item]}`;
  }
};

export const getRiskClass = (risk) => {
  const list = Object.values(RISK_LIST_FILTER);
  const riskValues = list.map((entry) => entry.label);

  if (riskValues.includes(risk)) {
    return list.find((entry) => entry.label === risk).value;
  }

  return null;
};
