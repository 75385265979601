import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import palette from 'common/palette';
import { DEFAULT_SETUP_DATA } from 'common/utils/setup';
import OperationalCostsDialog from './OperationalCostsDialog';

const styles = {
  dialogContent: {
    margin: 'unset',
    paddingBottom: 0,
    paddingTop: 0,
  },
  dialog: {
    maxWidth: 565,
    width: '100%',
    position: 'relative',
  },
  emptyDialogPlaceholder: {
    fontSize: 16,
    padding: 25,
    color: palette.secondary.light,
  },
  title: {
    fontSize: 22,
    fontWeight: 'bold',
  },
  dialogTitle: {
    paddingBottom: 0,
  },
  subTitle: {
    fontSize: 13,
    paddingTop: 8,
  },
};

const mapStateToProps = ({
  user: { setups },
}) => ({
  setups: setups.data
    .filter((setup) => setup.code !== DEFAULT_SETUP_DATA.code),
});

export const OPERATIONAL_COSTS_DIALOG = 'OPERATIONAL_COSTS_DIALOG';

export default connect(
  mapStateToProps,
  null,
)(withStyles(styles)(OperationalCostsDialog));
