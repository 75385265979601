import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Box, Typography } from '@material-ui/core';

import { calculateUserProfileScore, getUserProfileId } from 'common/utils/investorProfile';
import {
  SI_INVESTOR_PROFILE,
  INVESTOR_PROFILE_TYPES,
  INVESTOR_PROFILE_QUESTIONNAIRE_ERROR,
  STEPS_INVESTOR_PROFILE_QUESTIONNAIRE_INITIAL_STATE,
} from 'common/utils/constants/investorProfile';

import SiLogoChat from 'common/components/Images/SiLogoChat';
import InvestorProfileQuestionnaire from './InvestorProfileQuestionnaire';
import InvestorProfileStart from './InvestorProfileStart';
import MainSideDialog from '../MainSideDialog';
import InvestorProfileResult from './InvestorProfileResult';

const InvestorProfile = ({
  open,
  profileData,
  notifyError,
  onSideDialogClose,
  updateUserExtraData,
}) => {
  const [startQuestionnaire, setStartQuestionnaire] = useState(false);
  const [
    questionnaireDataSteps,
    setQuestionnaireDataSteps,
  ] = useState(STEPS_INVESTOR_PROFILE_QUESTIONNAIRE_INITIAL_STATE);
  const [userProfileId, setUserProfileId] = useState(null);

  useEffect(() => {
    const profileId = profileData?.extra_data?.si_investor_profile?.id;

    setUserProfileId(profileId);
  }, [profileData, open]);

  const setQuestionnaireInitialState = () => {
    setStartQuestionnaire(false);
    setQuestionnaireDataSteps(STEPS_INVESTOR_PROFILE_QUESTIONNAIRE_INITIAL_STATE);
    setUserProfileId(null);
  };

  const closeDialog = () => {
    onSideDialogClose();
    setTimeout(setQuestionnaireInitialState, 200);
  };

  const handleConfirmQuestionnaire = () => {
    const score = calculateUserProfileScore(Object.values(questionnaireDataSteps.answers));
    const id = getUserProfileId(score.toFixed(2));
    if (id) {
      const investorProfile = {
        [SI_INVESTOR_PROFILE]: {
          id,
          profile: INVESTOR_PROFILE_TYPES[id].name,
          answers: { ...questionnaireDataSteps.answers },
        },
      };
      updateUserExtraData(investorProfile);
      setUserProfileId(id);
    } else {
      notifyError({
        title: 'Erro!',
        message: INVESTOR_PROFILE_QUESTIONNAIRE_ERROR,
      });
    }
  };

  const initialQuestionnaireState = !userProfileId && !startQuestionnaire;

  return (
    <MainSideDialog open={open} onCloseDialog={closeDialog}>
      <Box pb="80px">
        <Box mb={2}>
          <Typography variant="h2">Perfil de Investidor</Typography>
        </Box>

        {initialQuestionnaireState && (
        <Box mb={0.3}>
          <SiLogoChat />
        </Box>
        )}

        {initialQuestionnaireState
        && <InvestorProfileStart setStartQuestionnaire={setStartQuestionnaire} />}

        {!userProfileId && startQuestionnaire && (
          <InvestorProfileQuestionnaire
            questionnaireDataSteps={questionnaireDataSteps}
            handleConfirmQuestionnaire={handleConfirmQuestionnaire}
            setQuestionnaireDataSteps={setQuestionnaireDataSteps}
            setStartQuestionnaire={setStartQuestionnaire}
          />
        )}

        {userProfileId && (
        <InvestorProfileResult
          type={userProfileId}
          setQuestionnaireInitialState={setQuestionnaireInitialState}
        />
        )}
      </Box>
    </MainSideDialog>
  );
};

InvestorProfile.propTypes = {
  open: PropTypes.bool.isRequired,
  profileData: PropTypes.object,
  notifyError: PropTypes.func.isRequired,
  onSideDialogClose: PropTypes.func.isRequired,
  updateUserExtraData: PropTypes.func.isRequired,
};

InvestorProfile.defaultProps = {
  profileData: {},
};

export default InvestorProfile;
