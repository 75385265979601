import { withStyles } from '@material-ui/core/styles';

import palette from 'common/palette';
import DisplayNumber from './DisplayNumber';

const styles = () => ({
  symbol: {
    fontSize: '1em',
    lineHeight: 0,
  },
  symbolLeft: {
    marginRight: 5,
  },
  value: {
    fontSize: '1em',
    lineHeight: '0.95em',
    verticalAlign: 'middle',
  },
  textPrimary: {
    color: palette.textPrimary.main,
    fontWeight: 500,
  },
  textPrimaryBold: {
    color: palette.textPrimary.main,
    fontWeight: 700,
  },
  textSecondary: {
    color: palette.textSecondary.main,
  },
  textSecondaryBold: {
    color: palette.textSecondary.main,
    fontWeight: 700,
  },
  darkGray: {
    color: '#5e5e5e',
    fontWeight: 500,
  },
  white: {
    color: palette.primary.main.contrastText,
  },
  positive: { color: `${palette.primary.main}` },
  negative: { color: `${palette.loss.main}` },
  normal: {
    fontWeight: 'normal',
  },
  medium: {
    fontWeight: 500,
  },
  bold: {
    fontWeight: 'bold',
  },
});

export default withStyles(styles)(DisplayNumber);
