import { withStyles } from '@material-ui/core/styles';
import palette from 'common/palette';
import theme from 'common/theme';

import Tabs from './Tabs';

const styles = {
  tabBadge: {
    '& > span': {
      top: 8,
      right: -18,
      color: palette.neutral.main,
      borderRadius: 4,
      backgroundColor: palette.default.main,
    },
  },
  tabBadgeHide: {
    '& > span': {
      display: 'none',
    },
  },
  badgeIcon: {
    color: palette.attention.main,
    marginRight: 5,
    marginTop: 1,
  },
  indicatorOff: {
    display: 'none',
  },
  small: {
    fontSize: theme.typography.h6.fontSize,
    minHeight: 32,
    padding: '2px 20px',

    '@media(max-width: 500px)': {
      fontSize: 12,
    },
  },
  fitContent: {
    minWidth: 'fit-content',
  },
};

export default withStyles(styles)(Tabs);
