import React, { useState, useMemo } from 'react';
import Loadable from 'react-loadable';
import PropTypes from 'prop-types';
import { Switch, Route, Redirect } from 'react-router-dom';
import classNames from 'classnames';

import { Backdrop } from '@material-ui/core';
import Sidebar from 'common/components/Sidebar';
import LoadingPage from 'common/components/LoadingPage';

import { TABLET_WIDTH_BREAKPOINT, LOGIN_URL, INSTANCE_CREATOR_URL } from 'common/utils/constants';

const Loading = () => <LoadingPage />;

const LoadableHomePage = Loadable({
  loader: () => import('./HomePage'),
  loading: Loading,
  render(loaded, props) {
    const HomePage = loaded.default;
    return <HomePage {...props} />;
  },
});

const LoadableInstancesPages = Loadable({
  loader: () => import('./InstancesPages'),
  loading: Loading,
  render(loaded, props) {
    const InstancesPages = loaded.default;
    return <InstancesPages {...props} />;
  },
});

const LoadableShowcasePage = Loadable({
  loader: () => import('./ShowcasePage'),
  loading: Loading,
  render(loaded, props) {
    const ShowcasePage = loaded.default;
    return <ShowcasePage {...props} />;
  },
});

const LoadableInstanceCreatorPage = Loadable({
  loader: () => import('./InstanceCreatorPage'),
  loading: Loading,
  render(loaded, props) {
    const InstanceCreatorPage = loaded.default;
    return <InstanceCreatorPage {...props} />;
  },
});

const LoadableCheckoutPage = Loadable({
  loader: () => import('./CheckoutPage'),
  loading: Loading,
  render(loaded, props) {
    const CheckoutPage = loaded.default;
    return <CheckoutPage {...props} />;
  },
});

const LoadableAccountPage = Loadable({
  loader: () => import('./AccountPage'),
  loading: Loading,
  render(loaded, props) {
    const AccountPage = loaded.default;
    return <AccountPage {...props} />;
  },
});

const NavigationPages = ({
  match,
  classes,
  forcedExternalLogout,
  history,
  stats,
}) => {
  const [dashboardState, setDashboardState] = useState(false);
  const isMobile = window.innerWidth <= TABLET_WIDTH_BREAKPOINT;

  const memoizedDashboardState = useMemo(() => dashboardState, [dashboardState]);

  const pageItems = [
    {
      name: 'analysis',
      id: 'sidebar-menu-item-analysis',
      text: 'Home',
      isVisible: true,
      onClick: () => setDashboardState(true),
      icon: 'home',
      href: `${match.url}/home`,
      render: (props) => (
        <LoadableHomePage
          {...props}
          toggleSidebar={() => setDashboardState(true)}
          title="Home"
          icon="home"
        />
      ),
    },
    {
      name: 'instances-pages',
      id: 'sidebar-menu-item-instances-pages',
      text: 'Minhas Carteiras',
      isVisible: true,
      onClick: () => setDashboardState(true),
      icon: 'assessment',
      href: `${match.url}/carteiras`,
      render: (props) => (
        <LoadableInstancesPages
          {...props}
          toggleSidebar={() => setDashboardState(true)}
          title="Minhas Carteiras"
          icon="assessment"
        />
      ),
    },
    {
      name: 'showcase',
      id: 'sidebar-menu-item-showcase',
      text: 'Vitrine de Carteiras',
      isVisible: true,
      onClick: () => setDashboardState(true),
      icon: 'store',
      href: `${match.url}/vitrine`,
      render: (props) => (
        <LoadableShowcasePage
          {...props}
          toggleSidebar={() => setDashboardState(true)}
          title="Vitrine de Carteiras"
          icon="store"
        />
      ),
    },
    {
      name: 'instance-creator',
      id: 'sidebar-menu-item-instance-creator',
      text: 'Criador de Carteiras',
      isVisible: true,
      onClick: () => setDashboardState(true),
      icon: 'donut_small',
      href: INSTANCE_CREATOR_URL,
      render: (props) => (
        <LoadableInstanceCreatorPage
          {...props}
          toggleSidebar={() => setDashboardState(true)}
          title="Criador de Carteiras"
          icon="donut_small"
        />
      ),
    },
    {
      name: 'checkout',
      id: 'sidebar-menu-item-checkout',
      text: 'Checkout',
      isVisible: false,
      onClick: () => setDashboardState(true),
      icon: 'layers',
      href: `${match.url}/checkout`,
      render: (props) => (
        <LoadableCheckoutPage
          {...props}
          toggleSidebar={() => setDashboardState(true)}
          title="Assinar"
          icon="layers"
        />
      ),
    },
    {
      name: 'profile',
      id: 'sidebar-menu-item-profile',
      text: 'Minha conta',
      isVisible: true,
      onClick: () => setDashboardState(true),
      icon: 'person',
      href: `${match.url}/conta`,
      render: (props) => (
        <LoadableAccountPage
          {...props}
          toggleSidebar={() => setDashboardState(true)}
          title="Minha conta"
          icon="person"
        />
      ),
    },
  ];

  const startPage = stats.running_instances ? pageItems[1].href : pageItems[0].href;

  if (forcedExternalLogout) {
    history.replace(`${LOGIN_URL}?forced_external_logout=true`);
  }

  return (
    <div className={classes.appFrame}>
      <Sidebar
        dashboardState={memoizedDashboardState}
        setDashboardState={setDashboardState}
        pageItems={pageItems}
        isMobile={isMobile}
      />
      <main
        id="navigation-main-content"
        className={classNames(
          classes.content,
        )}
      >
        <Backdrop
          onClick={
            () => setDashboardState(false)
          }
          className={classes.backdrop}
          open={memoizedDashboardState && isMobile}
        />
        <Switch>
          {
            pageItems.map((pageItem) => (
              <Route
                key={pageItem.href}
                path={pageItem.href}
                render={pageItem.render}
              />
            ))
          }
          <Redirect to={startPage} />
        </Switch>
      </main>
    </div>
  );
};

NavigationPages.propTypes = {
  match: PropTypes.shape({ url: PropTypes.string }).isRequired,
  forcedExternalLogout: PropTypes.bool.isRequired,
  history: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
  stats: PropTypes.object.isRequired,
};

export default NavigationPages;
