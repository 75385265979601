import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import api from 'common/api';
import { isHolidayOrWeekendDay } from 'common/ducks/holidays';
import { getCredentials } from 'common/ducks/stocks';
import { DATETIME_LOCAL_FORMAT } from 'common/utils/constants/date';
import {
  getCurrentTotalEquity,
  validateClosedMarket,
  addDateTimeAndPriceToPositions,
} from 'common/utils/positions';

export const useCurrentTotalEquity = (
  positions,
  setDataSteps,
  contribution,
) => {
  const dispatch = useDispatch();
  const marketOperationPeriod = useSelector((state) => state.market.operationPeriod);
  const [equity, setEquity] = useState({
    totalEquity: 0,
    totalEquityContribution: 0,
  });
  const [loadingEquity, setLoadingEquity] = useState(true);

  useEffect(() => {
    const loadCurrentPricePositions = async () => {
      const credentials = await dispatch(getCredentials());

      const promises = positions.map(async (position) => {
        const result = await api.stock.getCurrentStockPrice(position.stock, {
          params: {
            ...credentials,
            start_datetime: moment()
              .subtract(3, 'day')
              .format(DATETIME_LOCAL_FORMAT),
            end_datetime: moment().format(DATETIME_LOCAL_FORMAT),
          },
        });

        return result;
      });

      setLoadingEquity(true);
      await Promise.all(promises).then((response) => {
        const candles = response.map((stock) => stock.data[0]);

        const totalEquity = getCurrentTotalEquity({
          positions,
          candles,
        });

        const parsedContribution = parseFloat(contribution) || 0;

        const objEquity = {
          totalEquity,
          totalEquityContribution: totalEquity + parsedContribution,
        };
        setEquity(objEquity);
        addDateTimeAndPriceToPositions({
          positions,
          candles,
          marketOperationPeriod,
          isHolidayOrWeekendDay: (date) => dispatch(isHolidayOrWeekendDay(date)),
        });

        setDataSteps((oldData) => {
          return {
            ...oldData,
            data: {
              ...oldData.data,
              equity: objEquity,
            },
          };
        });
        setLoadingEquity(false);
      });
    };

    loadCurrentPricePositions();
  }, [positions, setDataSteps, contribution, marketOperationPeriod, dispatch]);

  return {
    equity,
    loadingEquity,
  };
};

export const useStockPrice = () => {
  const [price, setPrice] = useState({ value: 0, error: false });

  return [price, setPrice];
};

export const useClosedMarket = ({ position = {}, resetMode = false } = {}) => {
  const holidays = useSelector((state) => state.holidays.data);
  const marketOperationData = useSelector((state) => state.market);
  const marketClosedResponse = validateClosedMarket({
    holidays,
    marketOperationData,
    position,
    resetMode,
  });

  return {
    isMarketClosed: !!marketClosedResponse,
    marketMessage: marketClosedResponse,
  };
};

export default null;
