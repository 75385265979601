import { withStyles } from '@material-ui/core';
import { cancelOrder, loadInstanceOrderEvents } from 'common/ducks/orders';
import { connect } from 'react-redux';
import { EXTERNAL_ORDER_DIALOG_CODE } from 'common/components/Dialogs/InstanceDialogs/ExternalOrderDialog';
import { openDialog } from 'common/ducks/dialogs';

import InstanceOrderEvents from './InstanceOrderEvents';

const styles = {
  table: {
    fontSize: '0.8125rem',
  },
  tableCell: {
    fontSize: '0.8125rem',
  },
  errorMessage: {},
  content: {},
  dialogPaper: {
    maxWidth: '100%',
    width: 1000,
  },
};

const mapDispatchToProps = (dispatch, { variant, instanceId, order }) => ({
  loadInstanceOrderEvents: (...args) => dispatch(loadInstanceOrderEvents(...args, variant)),
  openExternalOrderDialog: (options) => dispatch(openDialog(
    EXTERNAL_ORDER_DIALOG_CODE,
    { id: instanceId, ...options },
  )),
  cancelOrder: () => dispatch(cancelOrder(instanceId, order)),
});

export const INSTANCE_ORDER_EVENTS_DIALOG_CODE = 'INSTANCE_ORDER_EVENTS_DIALOG_CODE';
export default connect(null, mapDispatchToProps)(withStyles(styles)(InstanceOrderEvents));
