import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { CHART_TIME_FILTERS } from 'common/utils/constants';
import { mixInstanceAndBenchmarksData } from 'common/utils/chart';
import { chartDateFormatter } from 'common/utils/functions';
import PerformanceChart from 'common/components/PerformanceChart';
import { BENCHMARKS } from 'common/utils/constants/benchmarks';
import {
  getInstanceAndBenchmarksNetReturn,
} from 'common/utils/benchmarks';

const GRADIENT = [
  {
    id: 'colorPv',
    color: '#89AFD9',
    opacity1: 0.3,
    opacity2: 0.05,
  },
  {
    id: 'colorUv',
    color: '#747C88',
    opacity1: 0.0,
    opacity2: 0.00,
  },
  {
    id: 'colorIfix',
    color: BENCHMARKS.ifix.color,
    opacity1: 0.0,
    opacity2: 0.00,
  },
  {
    id: 'colorSmll',
    color: BENCHMARKS.smll.color,
    opacity1: 0.0,
    opacity2: 0.00,
  },
  {
    id: 'colorCdi',
    color: BENCHMARKS.cdi.color,
    opacity1: 0.0,
    opacity2: 0.00,
  },
];

const AREA = [
  {
    dataKey: 'netReturn',
    stroke: '#0079A2',
    fill: 'url(#colorPv)',
    name: 'Rentabilidade Líquida',
    strokeWidth: 2,
  },
  {
    dataKey: 'ibovNetReturn',
    stroke: BENCHMARKS.ibov.color,
    fill: 'url(#colorUv)',
    name: BENCHMARKS.ibov.name,
    strokeWidth: 1.5,
  },
  {
    dataKey: 'ifixNetReturn',
    stroke: BENCHMARKS.ifix.color,
    fill: 'url(#colorIfix)',
    name: BENCHMARKS.ifix.name,
    strokeWidth: 1.5,
  },
  {
    dataKey: 'smllNetReturn',
    stroke: BENCHMARKS.smll.color,
    fill: 'url(#colorSmll)',
    name: BENCHMARKS.smll.name,
    strokeWidth: 1.5,
  },
  {
    dataKey: 'cdiNetReturn',
    stroke: BENCHMARKS.cdi.color,
    fill: 'url(#colorCdi)',
    name: BENCHMARKS.cdi.name,
    strokeWidth: 1.5,
  },
];

const TOOLTIṔ = {
  colorful: true,
  symbol: '%',
  symbolLocation: 'right',
};

const NetReturnChart = ({
  filter,
  rawData,
  benchmarksToPlot,
  height,
  classes,
  yearsList,
  isHolidayOrWeekendDay,
  instanceId,
  strategyId,
}) => {
  const [chartData, setChartData] = useState([]);

  useEffect(() => {
    const {
      instanceNetReturn,
      benchmarksNetReturn,
    } = getInstanceAndBenchmarksNetReturn({
      benchmarksToPlot,
      filter,
      rawData,
      isHolidayOrWeekendDay,
      yearsList,
    });

    const finalChartData = mixInstanceAndBenchmarksData({
      instance: instanceNetReturn,
      ibov: benchmarksNetReturn.ibov,
      ifix: benchmarksNetReturn.ifix,
      smll: benchmarksNetReturn.smll,
      cdi: benchmarksNetReturn.cdi,
    });

    setChartData(finalChartData);
  }, [benchmarksToPlot, filter, rawData, yearsList, isHolidayOrWeekendDay]);

  return (
    <div>
      <PerformanceChart
        className={classes.chart}
        height={height}
        data={chartData}
        area={AREA}
        XAxisDataKey="date"
        gradient={GRADIENT}
        XAxisTickFormatter={(tick) => chartDateFormatter(tick, 'DD MMM YYYY')}
        tooltipSettings={TOOLTIṔ}
        instanceId={instanceId}
        strategyId={strategyId}
      />
    </div>
  );
};

NetReturnChart.propTypes = {
  filter: PropTypes.string,
  instanceId: PropTypes.number.isRequired,
  rawData: PropTypes.object.isRequired,
  height: PropTypes.number.isRequired,
  classes: PropTypes.object.isRequired,
  yearsList: PropTypes.array,
  isHolidayOrWeekendDay: PropTypes.func.isRequired,
  benchmarksToPlot: PropTypes.object.isRequired,
  strategyId: PropTypes.number,
};

NetReturnChart.defaultProps = {
  yearsList: [],
  filter: CHART_TIME_FILTERS.ALL.filter,
  strategyId: null,
};

export default NetReturnChart;
