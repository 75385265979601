import { DATETIME_FORMAT, ISO_DATE_FORMAT } from './constants/date';
import { moment, isSamePlanCode } from './validation';
import { getCorrectPlanByCode, getDiscountValue } from './plans';
import {
  STORE_TAB,
  SIGNED_INSTANCES_TAB,
  ACTIVE_SIGNATURES,
  NOW_TEXT,
  DATE_FORMAT,
  MSG_MOUNTING_INSTANCE,
  START_MOUNTING_INSTANCE,
  END_MOUNTING_INSTANCE,
  INSTANCE_REBALANCE_MSG,
  STORE_VISIBILITY,
  PRIVATE_INSTANCES,
  MARKET_TIMEZONE,
  CUSTOM_CATEGORY_ID,
} from './constants';
import { INVESTOR_PROFILE_TYPES } from './constants/investorProfile';
import { reduceObjectsArrayToObject } from './array';
import {
  getLastDate,
  getCustomFormattedDate,
  getCurrentDate,
  getBusinessDay,
  setSpecificDate,
  getDateWithTimeZero,
} from './date';
import { NOT_REBALANCE_TEXT, PARAMS_MOUNT_INSTANCE } from './constants/instance';
import { getInstanceStocks } from './whitebox';
import { EXTERNAL_BILLING_STRATEGIES } from './constants/billing';
import { CANCELLED_SUBSCRIPTION } from './constants/subscription';

export const getStoreItemByStrategyId = (id, storeItems) => {
  return storeItems.find((storeItem) => storeItem.strategy.id === id);
};

export const getStoreItemByInstanceId = (id, storeItems) => {
  return storeItems.find((storeItem) => storeItem.instance_id === id);
};

export const getCheckboxText = (id, storeItems) => {
  const plan = getStoreItemByStrategyId(id, storeItems);

  return plan?.extra_data.checkbox;
};

export const getItemsFilteredByCategoryId = ({ items, categoryId }) => {
  return items.filter((item) => item.extra_data.category_id === categoryId);
};

export const getItemsFilteredByParam = ({ items, param, value }) => {
  return items.filter((item) => item.extra_data.tags[param] === value);
};

export const getFilteredItemsByFrequency = ({ frequency, items }) => {
  return Object.values(frequency).reduce((acc, item) => {
    return [...acc, ...getItemsFilteredByParam({
      items,
      param: 'frequency',
      value: item.label,
    })];
  }, []);
};

export const getFilteredItemsByMinimum = ({ items, minimum }) => {
  return items.filter((item) => item.extra_data.tags.minimum <= minimum);
};

export const setSignedValueToInstance = (storeItem, subscriptionInstance) => {
  if (!isSamePlanCode(storeItem.plan_code, subscriptionInstance.plan_code)) {
    storeItem.signed = false;
  } else {
    const currentPlan = getCorrectPlanByCode({
      planCode: subscriptionInstance.plan_code,
      listPlans: storeItem.extra_data.plans,
    });

    const strategyId = subscriptionInstance.plan_code.split('_')[1];
    if (subscriptionInstance.payment_method || !currentPlan?.value
      || EXTERNAL_BILLING_STRATEGIES[strategyId]) {
      storeItem.signed = true;
      return;
    }

    if (subscriptionInstance.items.length > 0) {
      const coupon = subscriptionInstance.items[0];
      const discountPrice = getDiscountValue(coupon);

      if (currentPlan?.value <= discountPrice) {
        storeItem.signed = true;
        return;
      }
    }

    if (subscriptionInstance.status !== CANCELLED_SUBSCRIPTION) {
      storeItem.signed = true;
      return;
    }

    storeItem.signed = false;
  }
};

export const setFreeValueToInstance = (storeItem, subscriptionInstance) => {
  if (!isSamePlanCode(storeItem.plan_code, subscriptionInstance.plan_code)) {
    storeItem.free = false;
  } else {
    const currentPlan = getCorrectPlanByCode({
      planCode: subscriptionInstance.plan_code,
      listPlans: storeItem.extra_data.plans,
    });

    if (!currentPlan?.value) {
      storeItem.free = true;
      return;
    }
    storeItem.free = false;
  }
};

export const isSignedStoreItem = ({ subscriptions, storeItem }) => {
  if (subscriptions.length === 0) {
    storeItem.signed = false;
    return storeItem.signed;
  }

  return subscriptions.some((subscriptionInstance) => {
    setSignedValueToInstance(storeItem, subscriptionInstance);
    return storeItem.signed;
  });
};

export const isFreeStoreItem = ({ subscriptions, storeItem }) => {
  if (subscriptions.length === 0) {
    storeItem.free = false;
    return storeItem.free;
  }

  return subscriptions.some((subscriptionInstance) => {
    setFreeValueToInstance(storeItem, subscriptionInstance);
    return storeItem.free;
  });
};

const getSignedStoreItems = ({ storeItems, subscriptions }) => {
  return storeItems.filter((storeItem) => {
    return isSignedStoreItem({ storeItem, subscriptions });
  });
};

const getSignedAndPayedStoreItems = ({ storeItems, subscriptions, categoryId }) => {
  return storeItems.filter((storeItem) => {
    if (categoryId) {
      return isSignedStoreItem({ storeItem, subscriptions })
        && !isFreeStoreItem({ storeItem, subscriptions })
        && (categoryId === storeItem.extra_data.category_id
          || storeItem.extra_data.category_id === CUSTOM_CATEGORY_ID);
    }

    return isSignedStoreItem({ storeItem, subscriptions })
      && !isFreeStoreItem({ storeItem, subscriptions });
  });
};

const getNotSignedOrFreeStoreItems = ({ storeItems, subscriptions, categoryId }) => {
  return storeItems.filter((storeItem) => {
    if (categoryId) {
      return (!isSignedStoreItem({ storeItem, subscriptions })
        || isFreeStoreItem({ storeItem, subscriptions }))
        && (categoryId === storeItem.extra_data.category_id
          || storeItem.extra_data.category_id === CUSTOM_CATEGORY_ID);
    }

    return !isSignedStoreItem({ storeItem, subscriptions })
      || isFreeStoreItem({ storeItem, subscriptions });
  });
};

export const isNotPrivate = (item) => item.extra_data.visibility !== STORE_VISIBILITY.PRIVATE;

export const getMappedStoreItems = ({
  storeItems,
  subscriptions,
  categoryId,
}) => {
  const items = {};

  const storeInstances = getNotSignedOrFreeStoreItems({ storeItems, subscriptions, categoryId });

  const nonPrivateStoreInstances = storeInstances.filter(isNotPrivate);

  items[STORE_TAB] = reduceObjectsArrayToObject(nonPrivateStoreInstances, 'strategy.id');

  const signedInstances = getSignedAndPayedStoreItems({ storeItems, subscriptions, categoryId });

  items[SIGNED_INSTANCES_TAB] = reduceObjectsArrayToObject(signedInstances, 'strategy.id');

  const activeSignatures = getSignedStoreItems({ storeItems, subscriptions });

  items[ACTIVE_SIGNATURES] = reduceObjectsArrayToObject(activeSignatures, 'strategy.id');

  const privateStoreInstances = storeItems.filter((item) => !isNotPrivate(item));

  items[PRIVATE_INSTANCES] = reduceObjectsArrayToObject(privateStoreInstances, 'strategy.id');

  const data = {
    ...items,
    items: {
      ...items[STORE_TAB],
      ...items[SIGNED_INSTANCES_TAB],
      ...items[ACTIVE_SIGNATURES],
    },
    itemsWithPrivate: {
      ...items[STORE_TAB],
      ...items[SIGNED_INSTANCES_TAB],
      ...items[ACTIVE_SIGNATURES],
      ...items[PRIVATE_INSTANCES],
    },
  };

  return data;
};

export const getTimeToMountingInstance = ({
  nextRollout = moment().format(ISO_DATE_FORMAT),
  endTime = false,
}) => {
  const timeMountingInstance = endTime ? END_MOUNTING_INSTANCE : START_MOUNTING_INSTANCE;

  return setSpecificDate({
    ...timeMountingInstance,
    date: moment(nextRollout),
  });
};

export const isAfterMaxTimeToRollout = (nextRollout) => {
  const dateNow = getCurrentDate();
  const maxMountTime = getTimeToMountingInstance({ nextRollout, endTime: true });

  return dateNow.valueOf() > maxMountTime.valueOf();
};

export const isBeforeMinTimeToRollout = (nextRollout) => {
  const dateNow = getCurrentDate();
  const minMountTime = getTimeToMountingInstance({ nextRollout });

  return dateNow.valueOf() < minMountTime.valueOf();
};

const getDateNextRollout = ({ forceMountPortfolio, storeInstance }) => {
  const defaultDateNow = moment().tz(MARKET_TIMEZONE).format(DATETIME_FORMAT);

  return !forceMountPortfolio
    ? storeInstance.next_rollout || defaultDateNow
    : defaultDateNow;
};

export const getDefaultOperationDate = ({
  optionNowSelected,
  storeInstance,
  isHolidayOrWeekendDay,
}) => {
  const minMount = setSpecificDate(START_MOUNTING_INSTANCE);
  const currentDate = getCurrentDate();

  const nextRollout = getDateNextRollout({
    forceMountPortfolio: optionNowSelected,
    storeInstance,
  });
  const nextRolloutDate = moment(nextRollout);

  if (isHolidayOrWeekendDay(minMount)) {
    return optionNowSelected
      ? getBusinessDay({ isHolidayOrWeekendDay })
      : getCustomFormattedDate(storeInstance.next_rollout);
  }

  if (!isAfterMaxTimeToRollout(nextRollout)) {
    if (!(currentDate.date() === nextRolloutDate.date())) {
      return getCustomFormattedDate(storeInstance.next_rollout);
    }

    if (isBeforeMinTimeToRollout(nextRollout)) {
      return `Hoje às ${minMount.hour()}:${minMount.minute()}`;
    }
    return NOW_TEXT;
  }

  return getBusinessDay({ isHolidayOrWeekendDay });
};

export const setNextRolloutStoreItem = ({ storeItem, date }) => {
  storeItem.next_rollout = date.format(ISO_DATE_FORMAT);
};

export const getMessageMountingPortfolio = ({
  storeInstance,
  selectedMountItem,
  startFromZero,
  customMessageNowValue = '',
}) => {
  if (selectedMountItem === PARAMS_MOUNT_INSTANCE.nextRollout.value) {
    return `A próxima rolagem será dia ${moment(storeInstance.next_rollout).format(DATE_FORMAT)}`;
  }

  if (!startFromZero) {
    return INSTANCE_REBALANCE_MSG;
  }

  return customMessageNowValue || MSG_MOUNTING_INSTANCE;
};

export const getPeriodsRunning = ({ storeItem, startDate, currentDate }) => {
  const diffDays = currentDate.diff(startDate, 'days');

  return Math.floor(diffDays / Number(storeItem.extra_data.divider));
};

export const getSmallerRolloutDate = ({ date, currentDate, smallerDate }) => {
  if (date.isSameOrAfter(currentDate) && (!smallerDate || date.isBefore(smallerDate))) {
    return date;
  }

  return smallerDate;
};

export const getStoreItemsRollout = ({ storeItems }) => {
  const currentDate = moment();

  return storeItems.map((storeItem) => {
    const startDate = moment(getDateWithTimeZero(storeItem.extra_data.start_date));
    storeItem.periods_running = getPeriodsRunning({ storeItem, startDate, currentDate });

    if (!storeItem.extra_data.dates || storeItem.extra_data.dates.length === 0) {
      setNextRolloutStoreItem({ storeItem, date: currentDate });
      return storeItem;
    }

    let smallerDate = null;

    storeItem.extra_data.dates.map((dateFormat, index) => {
      const date = moment(getDateWithTimeZero(dateFormat.rollout));
      date.set(END_MOUNTING_INSTANCE);

      smallerDate = getSmallerRolloutDate({ date, currentDate, smallerDate });

      if (index === storeItem.extra_data.dates.length - 1) {
        const lastDate = getLastDate({ dates: storeItem.extra_data.dates, value: 'rollout' });
        const nextRollout = smallerDate || lastDate;

        setNextRolloutStoreItem({ storeItem, date: nextRollout });
      }

      return storeItem;
    });

    return storeItem;
  });
};

export const getStoreItemsWithStocks = (storeItems) => {
  return storeItems.map((storeItem) => {
    const stockList = storeItem.extra_data.PORTFOLIO_stocks.split(',');
    const oldStocksList = storeItem.extra_data.PORTFOLIO_old_stocks;
    const weightList = storeItem.extra_data.PORTFOLIO_weights.split(',');
    const oldWeightsList = storeItem.extra_data.PORTFOLIO_old_weights;

    const oldStockListArr = oldStocksList ? oldStocksList.split(',') : [];
    const oldWeightListArr = oldWeightsList ? oldWeightsList.split(',') : [];

    const stocks = getInstanceStocks({
      stockList,
      weightList,
      oldStockList: oldStockListArr,
      oldWeightList: oldWeightListArr,
    });

    const oldStocks = getInstanceStocks({
      stockList: oldStockListArr,
      weightList: oldWeightListArr,
      oldStockList: oldStockListArr,
      oldWeightList: oldWeightListArr,
    });

    return {
      ...storeItem,
      stocks,
      oldStocks,
    };
  });
};

export const getGreaterStoreItemValue = ({ items, value }) => {
  return items.reduce((max, current) => {
    const currentValue = current.extra_data.tags[value];

    return currentValue > max
      ? currentValue
      : max;
  }, items[0].extra_data.tags[value]);
};

export const getMinStoreItemValue = ({ items, value }) => {
  return items.reduce((min, current) => {
    const currentValue = current.extra_data.tags[value];

    return currentValue < min ? currentValue : min;
  }, items[0].extra_data.tags[value]);
};

export const getMountingNote = (mountingDate) => {
  switch (mountingDate) {
    case NOW_TEXT:
      return 'Após a confirmação sua carteira será criada e seu processo de montagem (compra de ativos) ocorrerá imediatamente e de forma automática.';
    case NOT_REBALANCE_TEXT:
      return 'Após a confirmação sua carteira será criada com os ativos incluídos anteriormente. Como você escolheu por não rebalancear a carteira, sua composição permanecerá a mesma até uma próxima movimentação ou rebalanceamento.';
    default:
      return `Após a confirmação sua carteira será criada e não realizará nenhuma
      atividade até a data programada de rolagem definida acima. Na data definida
      a montagem da sua carteira (compra de ativos) ocorrerá automaticamente.
      `;
  }
};

export const getStocksAndWeightsListFromStoreItem = (storeInstance) => {
  const stockList = storeInstance.extra_data.PORTFOLIO_stocks.split(',');
  const weightList = storeInstance.extra_data.PORTFOLIO_weights.split(',');

  return { stockList, weightList };
};

export const isStoreItemRisky = (storeInstance, userInvestorProfileId) => {
  if (!userInvestorProfileId) return false;
  const storeItemRiskLevel = storeInstance.extra_data.tags?.risk;
  const { risk } = INVESTOR_PROFILE_TYPES[userInvestorProfileId];
  return !risk.find((item) => item.label.toLowerCase() === storeItemRiskLevel.toLowerCase());
};
