import { withStyles } from '@material-ui/core/styles';
import Tab from '@material-ui/core/Tab';

const borderRadius = 50;

export default withStyles((theme) => ({
  root: {
    '&:not(.Mui-selected)': {
      background: '#e5e5e5',
      color: '#b4b6b8',
    },
    '&.Mui-selected': {
      background: theme.palette.primary.main,
      color: theme.palette.primary.contrastText,
    },
    '&:first-of-type': {
      borderTopLeftRadius: borderRadius,
      borderBottomLeftRadius: borderRadius,
    },
    '&:last-of-type': {
      borderTopRightRadius: borderRadius,
      borderBottomRightRadius: borderRadius,
    },
  },
}))(Tab);
