import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Box } from '@material-ui/core';

const BenchmarkLegends = ({
  classes,
  benchmarks,
  handleBenchMarkSelection,
}) => {
  return benchmarks.map((benchmark) => (
    <Box
      data-testid="benchmark-item"
      className={classNames(classes.legend, !benchmark.active && classes.inactive)}
      onClick={() => handleBenchMarkSelection(benchmark)}
      color={benchmark.color}
      key={benchmark.id}
      sx={benchmark.active
        ? { borderColor: benchmark.color }
        : { borderColor: '#b7b7b7' }}
    >
      {benchmark.name}
    </Box>
  ));
};

BenchmarkLegends.propTypes = {
  classes: PropTypes.object.isRequired,
  benchmarks: PropTypes.array.isRequired,
  handleBenchMarkSelection: PropTypes.func.isRequired,
};

export default BenchmarkLegends;
