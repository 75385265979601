import moment from 'moment-timezone';
import range from 'lodash/range';
import {
  DATE_FORMAT,
  ZERO_TIME,
  CHART_TIME_FILTERS,
  MARKET_TIMEZONE,
  MARKET_FINAL_MOMENT,
} from './constants';
import {
  ISO_DATE_FORMAT,
  DATETIME_FORMAT,
  BR_TIME_FORMAT,
} from './constants/date';
import { getFirstDayOfPeriod } from './dailyCumulativePerformance';

export const getCustomFormattedDate = (date, dateFormat = DATE_FORMAT) => {
  return moment(date).format(dateFormat);
};

export const getDateWithTimeZero = (date) => {
  return `${date} ${ZERO_TIME}`;
};

export const getCurrentIsoDate = () => {
  const currentDate = moment();
  currentDate.set({
    hour: 0, minute: 0, second: 0, millisecond: 0,
  });
  currentDate.toISOString();

  return currentDate;
};

const setUTCDate = (date) => {
  date.toUTCString();

  return new Date(
    date.getUTCFullYear(),
    date.getUTCMonth(),
    date.getUTCDate(),
    date.getUTCHours(),
    date.getUTCMinutes(),
    date.getUTCSeconds(),
  );
};

export const getLocalOrUTCDate = ({ date, setUTC }) => {
  return setUTC ? setUTCDate(date) : date;
};

export const getCurrentDate = ({ setUTC = false } = {}) => {
  const date = moment.tz(MARKET_TIMEZONE).format(DATETIME_FORMAT);

  return getLocalOrUTCDate({ date: moment(date), setUTC });
};

export const setSpecificDate = ({
  date = moment.tz(MARKET_TIMEZONE), hours = 0, minutes = 0, seconds = 0, setUTC = false,
}) => {
  date.set({
    hour: hours, minute: minutes, second: seconds,
  });

  return getLocalOrUTCDate({ date: moment(date.format(DATETIME_FORMAT)), setUTC });
};

export const getLastDate = ({ dates, value }) => {
  return moment(
    new Date(Math.max.apply(null, dates.map((date) => moment(getDateWithTimeZero(date[value]))))),
  );
};

export const getBusinessDay = ({
  period = null,
  todayCounts = false,
  format = DATE_FORMAT,
  baseDate,
  previousDay = false,
  isHolidayOrWeekendDay,
} = {}) => {
  let date = baseDate ? moment(getDateWithTimeZero(baseDate)) : getCurrentDate();

  if (period) date = moment(moment(date).startOf(period).toISOString());
  if (todayCounts && !isHolidayOrWeekendDay(date)) return date;

  const getFirstWorkday = () => {
    if (previousDay) {
      date.subtract(1, 'day');
    } else {
      date.add(1, 'day');
    }

    if (isHolidayOrWeekendDay(date)) return getFirstWorkday();

    return moment(date).format(format);
  };

  return getFirstWorkday();
};

export const getYearsFromDates = ({ startDate, endDate }) => {
  return range(startDate.year(), endDate.year() + 1);
};

export const getSortedDataByDate = ({ data, param = 'date' }) => {
  return [...data].sort((dateA, dateB) => moment(dateB[param]) - moment(dateA[param]));
};

export const getDefaultStartDates = (isHolidayOrWeekendDay) => {
  const { YEAR, MONTH, WEEK } = CHART_TIME_FILTERS;

  return {
    year: getFirstDayOfPeriod(YEAR.filter, isHolidayOrWeekendDay).format(ISO_DATE_FORMAT),
    month: getFirstDayOfPeriod(MONTH.filter, isHolidayOrWeekendDay).format(ISO_DATE_FORMAT),
    week: getFirstDayOfPeriod(WEEK.filter, isHolidayOrWeekendDay).format(ISO_DATE_FORMAT),
  };
};

export const formatBrDateFromString = (date) => date.split('-').reverse().join('/');

export const getTimeCurrentDate = () => {
  const currentDate = getCurrentDate();
  const marketTimeClosed = moment(currentDate).set(MARKET_FINAL_MOMENT);

  const correctDate = currentDate.isAfter(marketTimeClosed)
    ? marketTimeClosed
    : currentDate;

  return correctDate.format(BR_TIME_FORMAT);
};

export const getLatestUpdatedPriceDate = () => {
  const currentDate = getCurrentDate();

  const time = getTimeCurrentDate();

  return `Atualizado ${currentDate.format(DATE_FORMAT)} às ${time}`;
};

export const createTimeMoment = (time) => {
  const [hours, minutes, seconds] = time.split(':');

  return setSpecificDate({ hours, minutes, seconds });
};

export const getValidDateAndTime = (
  marketOperationPeriod,
  isHolidayOrWeekendDay,
) => {
  const now = getCurrentDate();
  const isTodayHolidayOrWeekend = isHolidayOrWeekendDay(now);
  const { openTime, closeTime } = marketOperationPeriod;

  if (isTodayHolidayOrWeekend) {
    const lastBusinessDay = getBusinessDay({
      previousDay: true,
      isHolidayOrWeekendDay,
    });
    return { date: lastBusinessDay, time: closeTime };
  }

  let time = now;
  if (now.isBefore(openTime)) time = openTime;
  if (now.isAfter(closeTime)) time = closeTime;

  return { date: getCustomFormattedDate(now), time };
};

export const getLastDayOfTheMonth = (date) => new Date(
  date.getFullYear(), date.getMonth() + 1, 0,
).getDate();

export const getRoundedAndFormattedDate = (date) => ({
  formattedDate: getCustomFormattedDate(date, ISO_DATE_FORMAT),
  roundedAndFormattedDate: getCustomFormattedDate(
    date.setDate(getLastDayOfTheMonth(date)),
    ISO_DATE_FORMAT,
  ),
});

export const findItemByDate = ({ data, date }) => data?.find((item) => item.date === date);

export const findIndexByDate = ({ data, date }) => data?.findIndex((item) => item.date === date);
