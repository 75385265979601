import { withStyles } from '@material-ui/styles';

import Field from './Field';

const styles = {
  input: {
    marginBottom: 10,
  },
  inputField: {
    fontSize: 16,
    margin: '5px 0',
  },
  inputLabel: {
    fontSize: 16,
  },
  helperText: {
    fontSize: 12,
    textAlign: 'left',
  },
};

export default withStyles(styles)(Field);
