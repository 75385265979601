import api from 'common/api';
import {
  size,
  merge,
  get,
  flatten,
  isEqual,
} from 'lodash';
import logdown from 'logdown';

// Ducks
import { LOGOUT_SUCCEED } from 'common/ducks/auth';
import { loadInstancePositions } from 'common/ducks/positions';
import { openDialog } from 'common/ducks/dialogs';
import { loadInstanceReport } from 'common/ducks/report';

// Ducks modules
import { updateInstanceInfo } from 'common/ducks/ducks-modules/instanceUpdater';

import {
  error as notifierError,
  success as notifierSuccess,
} from 'react-notification-system-redux';

// Commons
import { reduceObjectsArrayToObject } from 'common/utils/array';
import {
  FINAL_ORDER_STATES,
  IS_ERROR,
  IS_LOADING,
  IS_SUCCESS,
  ERROR,
  SUCCESS,
  ERROR_DEFAULT_MSG,
  DEFAULT_LOADING_MSG,
} from 'common/utils/constants';
import {
  getInstanceLastOrder,
  mergeCurrentWithResultOrders,
  insertExternalOrders,
} from 'common/utils/orders';
import { PROGRESS_DIALOG_CODE } from 'common/components/Dialogs/ProgressDialog';
import { isLoginError } from 'common/utils/validation';
import { CANCELED_ORDER_TITLE, CANCELED_ORDER_MSG, CANCELING_ORDER_TITLE } from 'common/utils/constants/orders';

export const DUCK_NAME = 'orders';

export const ORDERS_RETURN_ATTRIBUTES = [
  'order_id', 'datetime', 'stock_code', 'order_type',
  'number_of_stocks', 'nominal_price', 'financial_volume', 'status',
  'entry_exit_or_reversal', 'number_of_traded_stocks',
  'average_nominal_price', 'absolute_average_simple_profit',
  'percentual_average_simple_profit', 'gross_profit',
  'sent_by_insert_external_order', 'market_name', 'ticket_order',
];

export const getMergedParams = (config) => {
  const defaultLimitOrders = 100;
  return merge(
    {
      params: {
        return_attributes: ORDERS_RETURN_ATTRIBUTES,
        include_external_orders: '1',
      },
    },
    { params: { limit: defaultLimitOrders }, ...config },
  );
};

export const INITIAL_STATE = {};

export const INITIAL_INSTANCE_STATE = {
  loading: false,
  data: {},
  error: null,
};

export const INITIAL_ORDER_STATE = {
  loading: false,
  data: {},
  error: null,
};

const logger = logdown(DUCK_NAME);

// Actions
export const LOAD_INSTANCE_ORDERS_STARTED = `${DUCK_NAME}/LOAD_INSTANCE_ORDERS_STARTED`;
export const LOAD_INSTANCE_ORDERS_SUCCEED = `${DUCK_NAME}/LOAD_INSTANCE_ORDERS_SUCCEED`;
export const LOAD_INSTANCE_ORDERS_FAILED = `${DUCK_NAME}/LOAD_INSTANCE_ORDERS_FAILED`;

export const loadInstanceOrdersStarted = (id) => ({
  id,
  type: LOAD_INSTANCE_ORDERS_STARTED,
});
export const loadInstanceOrdersSucceed = (id, data) => ({
  id,
  data,
  type: LOAD_INSTANCE_ORDERS_SUCCEED,
});
export const loadInstanceOrdersFailed = (id, error) => ({
  id,
  error,
  type: LOAD_INSTANCE_ORDERS_FAILED,
});

export const loadInstanceOrders = (
  id,
  config,
  options = { reload: false },
  variant = 'instances',
  commit = true,
) => async (dispatch, getState) => {
  const state = getState()[DUCK_NAME];
  const instanceOrders = state[id];

  if (
    !options.reload
    && instanceOrders
    && !instanceOrders.loading
    && !instanceOrders.error
    && size(instanceOrders.data) > 0
    && (
      // if the instance has any pending or hung orders
      // the function should force an update
      !Object.values(instanceOrders.data.orders)
        .find((order) => (/pending|hung/g).test(order.status))
    )
  ) {
    return Promise.resolve({
      ...instanceOrders.data,
      orders: Object.values(instanceOrders.data.orders),
    });
  }

  if (commit) {
    dispatch(loadInstanceOrdersStarted(id));
  }

  try {
    const mergedParams = getMergedParams(config);
    const result = await api.strategies.instances.orders.getOrders(
      id,
      mergedParams,
      variant,
    );

    const currentOrders = get(state, `${id}.data.orders`, {});

    const newOrders = {
      ...currentOrders,
      ...reduceObjectsArrayToObject(result.data.orders, 'order_id'),
    };

    const lastCurrentOrder = getInstanceLastOrder(currentOrders);
    const lastNewOrder = getInstanceLastOrder(newOrders);
    if (lastCurrentOrder && !isEqual(lastCurrentOrder, lastNewOrder)) {
      dispatch(updateInstanceInfo(id));
    }

    if (commit) {
      dispatch(loadInstanceOrdersSucceed(
        id,
        mergeCurrentWithResultOrders(instanceOrders, result),
      ));
    }

    return Promise.resolve(result.data);
  } catch (error) {
    if (commit) {
      dispatch(loadInstanceOrdersFailed(id, error));
    }

    return Promise.reject(error);
  }
};

/**
 * A recursive function that checks if the given orders were finalized.
 *
 * @param {int} id The orders' instance ID.
 * @param {Array} ordersIds The order's IDs that we have to check.
 * @param {Array} finalizedOrders The finalized orders checked.
 * @param {int} intervalTime The time interval between consecutive checks.
 */
export const checkOrdersFinalization = (
  id, ordersIds, finalizedOrders,
  intervalTime = 1000,
) => async (dispatch) => {
  // If the orders array is empty, than we stop the recursion
  if (ordersIds.length > 0) {
    // Preprocess orders list (extract orders_ids attribute, filter
    // empty strings, split on ',')
    const orders = flatten(ordersIds
      .map((x) => (x.order_ids ? x.order_ids : x))
      .filter((x) => x)
      .map((x) => (x.split ? x.split(',') : x)));

    const promises = [];

    // Try to get all the orders states
    for (let i = 0; i < orders.length; i += 1) {
      promises.push(dispatch(loadInstanceOrders(
        id,
        {
          params: {
            order_id: orders[i],
            return_attributes: 'status,order_id,entry_exit_or_reversal,gross_profit',
          },
        },
        { reload: true },
        undefined,
        false,
      )));
    }

    const results = await Promise.all(promises);

    // Then remove from the orders array all the finalized orders
    for (let i = 0; i < results.length; i += 1) {
      const {
        order_id: orderId, status,
        entry_exit_or_reversal: direction, gross_profit: profit,
      } = results[i].orders[0];
      if (
        FINAL_ORDER_STATES.includes(status)
        // If the order is executed, an exit order and profit is null, we
        // wait until profit is not null
        && (status !== 'executed' || direction !== 'exit' || profit !== null)
      ) {
        finalizedOrders.push({ orderId, status });

        if (orders.indexOf(orderId) >= 0) {
          orders.splice(orders.indexOf(orderId), 1);
        }
      }
    }

    if (orders.length === 0) {
      return;
    }

    // And check if the remaining orders were finalized every 1000ms
    await new Promise((resolve) => setTimeout(resolve, intervalTime));

    await checkOrdersFinalization(
      id, orders, finalizedOrders,
      intervalTime,
    )(dispatch);
  }
};

export const cancelOrder = (instanceId, order) => async (dispatch, getState) => {
  let result = {};
  const state = getState()[DUCK_NAME];
  const instanceOrders = state[instanceId];

  try {
    dispatch(openDialog(
      PROGRESS_DIALOG_CODE,
      {
        state: IS_LOADING,
        title: CANCELING_ORDER_TITLE,
        message: DEFAULT_LOADING_MSG,
        showButton: true,
      },
    ));

    result = await api.strategies.instances.orders.cancelOrder(
      instanceId,
      order.order_id,
      { ticket_order: true },
    );

    const canceledOrder = {
      ...order,
      status: 'canceled',
    };
    const updatedInstanceOrders = {
      ...instanceOrders,
      data: {
        ...instanceOrders.data,
        orders: {
          ...instanceOrders.data.orders,
          [order.order_id]: canceledOrder,
        },
      },
    };
    dispatch(loadInstanceOrdersSucceed(
      instanceId,
      updatedInstanceOrders,
    ));
  } catch (error) {
    dispatch(openDialog(
      PROGRESS_DIALOG_CODE,
      {
        state: IS_ERROR,
        title: ERROR,
        message: `Não foi possível cancelar a ordem. ${ERROR_DEFAULT_MSG}`,
        showButton: true,
      },
    ));

    logger.error(`Failed to cancel instance #${instanceId} order`, error);
    return Promise.reject(error);
  }

  dispatch(openDialog(
    PROGRESS_DIALOG_CODE,
    {
      state: IS_SUCCESS,
      title: CANCELED_ORDER_TITLE,
      message: CANCELED_ORDER_MSG,
      showButton: true,
    },
  ));

  return Promise.resolve(result);
};

export const cancelInstanceOrders = (
  id,
  data = {},
  {
    notifyAction = true,
    checkOrdersFinalized = true,
  } = {},
) => async (dispatch) => {
  const { cancelAllOrders } = api.strategies.instances.orders;

  let result = {};
  try {
    result = await cancelAllOrders(id, data);
  } catch (error) {
    if (notifyAction) {
      if (!isLoginError(error)) {
        dispatch(openDialog(
          PROGRESS_DIALOG_CODE,
          {
            state: IS_ERROR,
            title: ERROR,
            message: 'Ocorreu um erro ao cancelar as ordens da carteira.',
            showButton: true,
          },
        ));
      } else {
        dispatch(notifierError({
          title: 'Erro!',
          message: error.codeMessage,
        }));
      }
    }

    logger.error(`Failed to cancel instance #${id} orders`, error);
    return Promise.reject(error);
  }
  const pendingOrders = result.data.order_ids;
  if (checkOrdersFinalized && pendingOrders.length > 0) {
    try {
      await dispatch(checkOrdersFinalization(id, pendingOrders, []));
    } catch (error) {
      const errorMessage = `Erro em verificar se as ordens foram canceladas,
      alguma ordem pode não ter sido cancelada.`;
      if (notifyAction) {
        dispatch(notifierError({
          title: 'Erro!',
          message: errorMessage,
        }));
      }

      logger.error(`Failed to check instance #${id} orders finalization`, error);
      return Promise.reject(error);
    }
  }

  if (notifyAction) {
    dispatch(notifierSuccess({
      title: 'Sucesso!',
      message: `As ordens do robô #${id} foram canceladas com sucesso!`,
    }));
  }

  return Promise.resolve(result);
};

export const LOAD_INSTANCE_ORDER_EVENT_STARTED = `${DUCK_NAME}/LOAD_INSTANCE_ORDER_EVENT_STARTED`;
export const LOAD_INSTANCE_ORDER_EVENT_SUCCEED = `${DUCK_NAME}/LOAD_INSTANCE_ORDER_EVENT_SUCCEED`;
export const LOAD_INSTANCE_ORDER_EVENT_FAILED = `${DUCK_NAME}/LOAD_INSTANCE_ORDER_EVENT_FAILED`;

export const loadInstanceOrderEventStarted = (id) => ({
  id,
  type: LOAD_INSTANCE_ORDER_EVENT_STARTED,
});
export const loadInstanceOrderEventSucceed = (id, data) => ({
  id,
  data,
  type: LOAD_INSTANCE_ORDER_EVENT_SUCCEED,
});
export const loadInstanceOrderEventFailed = (id, error) => ({
  id,
  error,
  type: LOAD_INSTANCE_ORDER_EVENT_FAILED,
});
export const loadInstanceOrderEvents = (
  id,
  orderId,
  variant = 'instances',
) => async (dispatch) => {
  dispatch(loadInstanceOrderEventStarted(id));
  try {
    const data = await api.strategies.instances.orders.getOrderEvents(
      id,
      {
        params: {
          order_id: orderId,
          return_attributes: 'datetime,event_type,description,reason',
        },
      },
      variant,
    );
    dispatch(loadInstanceOrderEventSucceed(id, data));
    return Promise.resolve(data);
  } catch (error) {
    dispatch(loadInstanceOrderEventFailed(id, error));
    return Promise.resolve(error);
  }
};

export const INSERT_EXTERNAL_ORDERS_STARTED = `${DUCK_NAME}/INSERT_EXTERNAL_ORDERS_STARTED`;
export const INSERT_EXTERNAL_ORDERS_SUCCEED = `${DUCK_NAME}/INSERT_EXTERNAL_ORDERS_SUCCEED`;
export const INSERT_EXTERNAL_ORDERS_FAILED = `${DUCK_NAME}/INSERT_EXTERNAL_ORDERS_FAILED`;

export const insertExternalOrdersActionStarted = (id) => ({
  id,
  type: INSERT_EXTERNAL_ORDERS_STARTED,
});

export const insertExternalOrdersActionSucceed = (id) => ({
  id,
  type: INSERT_EXTERNAL_ORDERS_SUCCEED,
});

export const insertExternalOrdersActionFailed = (id, error) => ({
  id,
  error,
  type: INSERT_EXTERNAL_ORDERS_FAILED,
});

export const insertExternalOrdersAction = (id, data) => async (dispatch) => {
  dispatch(insertExternalOrdersActionStarted(id));
  try {
    const result = await insertExternalOrders({
      instanceId: id,
      brokerageId: data.brokerageId,
      orderData: {
        reason: data.orderData.reason,
      },
      positions: data.positions,
    });

    logger.log('Insert External Order result:', result);
    dispatch(insertExternalOrdersActionSucceed(id));
    dispatch(loadInstanceOrders(id, {}, { reload: true }));
    dispatch(loadInstancePositions(id, { reload: true }));
    dispatch(loadInstanceReport(id, {}, { reload: true }));
    dispatch(notifierSuccess({
      title: SUCCESS,
      message: 'Ordens cadastradas com sucesso',
    }));
    return Promise.resolve(data);
  } catch (error) {
    logger.error('Falha ao inserir ordem externa.', error);
    dispatch(insertExternalOrdersActionFailed(id, error));
    dispatch(notifierError({
      title: ERROR,
      message:
          error.codeMessage || error.message
          || 'Houve um erro ao cadastrar a ordem. Tente novamente.',
    }));
    return Promise.reject(error);
  }
};

export const UPDATE_EXTERNAL_ORDER_STARTED = `${DUCK_NAME}/UPDATE_EXTERNAL_ORDER_STARTED`;
export const UPDATE_EXTERNAL_ORDER_SUCCEED = `${DUCK_NAME}/UPDATE_EXTERNAL_ORDER_SUCCEED`;
export const UPDATE_EXTERNAL_ORDER_FAILED = `${DUCK_NAME}/UPDATE_EXTERNAL_ORDER_FAILED`;

export const updateExternalOrderStarted = (id) => ({
  id,
  type: UPDATE_EXTERNAL_ORDER_STARTED,
});

export const updateExternalOrderSucceed = (id) => ({
  id,
  type: UPDATE_EXTERNAL_ORDER_SUCCEED,
});

export const updateExternalOrderFailed = (id, error) => ({
  id,
  error,
  type: UPDATE_EXTERNAL_ORDER_FAILED,
});

export const updateExternalOrder = (id, data) => async (dispatch) => {
  dispatch(updateExternalOrderStarted(id));
  try {
    const result = await api.strategies.instances.orders.updateExternalOrder(data);
    logger.log('Update External Order result:', result);
    dispatch(updateExternalOrderSucceed(id));
    dispatch(loadInstanceOrders(id, {}, { reload: true }));
    dispatch(loadInstancePositions(id, { reload: true }));
    dispatch(loadInstanceReport(id, {}, { reload: true }));
    dispatch(notifierSuccess({
      title: SUCCESS,
      message: 'Ordem atualizada com sucesso',
    }));
    return Promise.resolve(data);
  } catch (error) {
    logger.error('Falha ao atualizar ordem externa.', error);
    dispatch(updateExternalOrderFailed(id, error));
    dispatch(notifierError({
      title: 'Erro',
      message:
          error.codeMessage || 'Houve um erro ao atualizar a ordem. Tente novamente.',
    }));
    return Promise.reject(error);
  }
};

export const instanceOrdersReducer = (state = INITIAL_INSTANCE_STATE, action) => {
  switch (action.type) {
    case LOAD_INSTANCE_ORDERS_STARTED:
      return {
        ...state,
        loading: true,
      };
    case LOAD_INSTANCE_ORDERS_SUCCEED:
      return {
        ...state,
        loading: false,
        error: null,
        data: action.data,
      };
    case LOAD_INSTANCE_ORDERS_FAILED:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    case INSERT_EXTERNAL_ORDERS_STARTED:
      return {
        ...state,
        loading: true,
      };
    case INSERT_EXTERNAL_ORDERS_SUCCEED:
      return {
        ...state,
        loading: false,
      };
    case INSERT_EXTERNAL_ORDERS_FAILED:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    case UPDATE_EXTERNAL_ORDER_STARTED:
      return {
        ...state,
        loading: true,
      };
    case UPDATE_EXTERNAL_ORDER_SUCCEED:
      return {
        ...state,
        loading: false,
      };
    case UPDATE_EXTERNAL_ORDER_FAILED:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    default: return state;
  }
};

export const instanceOrderEventReducer = (state = INITIAL_ORDER_STATE, action) => {
  switch (action.type) {
    case LOAD_INSTANCE_ORDER_EVENT_STARTED:
      return {
        ...state,
        loading: true,
      };
    case LOAD_INSTANCE_ORDER_EVENT_SUCCEED:
      return {
        ...state,
        loading: false,
        data: action.data,
      };
    case LOAD_INSTANCE_ORDER_EVENT_FAILED:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    default: return state;
  }
};

// Reducer
export default (state = INITIAL_STATE, action) => {
  const { id } = action;

  if (action.type === LOGOUT_SUCCEED) {
    return INITIAL_STATE;
  }

  return {
    ...state,
    [id]: instanceOrdersReducer(state[id], action),
  };
};
