import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';

import palette from 'common/palette';
import { openDialog, closeDialog } from 'common/ducks/dialogs';
import { getStock } from 'common/ducks/stocks';
import { loadInstanceOrders, loadInstanceOrderEvents } from 'common/ducks/orders';
import { PROGRESS_DIALOG_CODE } from 'common/components/Dialogs/ProgressDialog';
import { PASSWORD_CONFIRM_DIALOG_CODE } from 'common/components/Dialogs/PasswordConfirmDialog';

import ManualOperation from './ManualOperation';

export const MANUAL_OPERATION_SIDE_DIALOG = 'MANUAL_OPERATION_SIDE_DIALOG';

const styles = {
  cardClickable: {
    cursor: 'pointer',
    '&:hover': {
      boxShadow: '0px 3px 6px 2px rgba(0, 0, 0, 0.2)',
    },
  },
  description: {
    fontSize: 16,
    fontStyle: 'italic',
    fontWeight: 'bold',
  },
  extraButton: {
    fontSize: 16,
    textDecoration: 'underline',
    color: palette.textSecondary.dark,
    cursor: 'pointer',
  },
  icon: {
    color: palette.warn.dark,
    width: 50,
    height: 50,
  },
  stocksCard: {
    margin: '8px auto',
  },
};

const mapStateToProps = ({ instances, storeItems, positions }, { instanceId }) => ({
  instance: instances.data[instanceId],
  positions: positions[instanceId]?.data.mergedPositions || {},
  storeItems,
});

const mapDispatchToProps = (dispatch, { variant }) => ({
  openProgressDialog: (props) => dispatch(openDialog(PROGRESS_DIALOG_CODE, props)),
  openPasswordConfirmDialog: (props) => dispatch(
    openDialog(PASSWORD_CONFIRM_DIALOG_CODE, { ...props }),
  ),
  onClose: () => dispatch(closeDialog()),
  loadInstanceOrders: (...args) => dispatch(loadInstanceOrders(...args, variant)),
  loadInstanceOrderEvents: (...args) => dispatch(loadInstanceOrderEvents(...args, variant)),
  getStock: (stockCode) => dispatch(getStock({ stockCode })),
});

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(ManualOperation));
