import { withStyles } from '@material-ui/core/styles';
import palette from 'common/palette';
import theme from 'common/theme';

import InvestorProfileResult from './InvestorProfileResult';

const styles = {
  resultProfile: {
    color: palette.blue.main,
    marginTop: 10,
    marginBottom: 20,

    [theme.breakpoints.up('md')]: {
      fontSize: 40,
    },
  },
  fontNormal: {
    fontWeight: 'normal',
  },
};

export default withStyles(styles)(InvestorProfileResult);
