import { openDialog, closeDialog } from 'common/ducks/dialogs';
import { REGISTER_BROKERAGE_DIALOG_CODE } from 'common/components/Dialogs/RegisterBrokerageDialog';
import { updateRegisterSteps } from 'common/ducks/registerSteps';
import { OPEN_FOLLOW_INSTANCE_DIALOG_CODE } from 'common/components/Dialogs/FollowInstanceDialog';
import { CONFIRMATION_DIALOG_CODE } from 'common/components/Dialogs/ConfirmationDialog';
import { PROGRESS_DIALOG_CODE } from 'common/components/Dialogs/ProgressDialog';
import { connect } from 'react-redux';

import { checkExclusiveBrokerage } from 'common/ducks/brokerages';
import StartFollowInstanceDialog from './StartFollowInstanceDialog';

const mapStateToProps = (({
  brokerages,
  strategies,
  registerSteps: { accountInfo },
}) => ({
  strategies: strategies.data,
  brokerages: brokerages.data,
  accountInfo,
}));

const mapDispatchToProps = (dispatch) => ({
  openRegisterBrokerageDialog: (onConfirmSubmit) => dispatch(openDialog(
    REGISTER_BROKERAGE_DIALOG_CODE, { onConfirmSubmit },
  )),
  updateStatusRegisterStep: (status, type) => {
    dispatch(updateRegisterSteps({
      [type]: { status },
    }));
  },
  openFollowInstanceDialog: (props) => dispatch(openDialog(
    OPEN_FOLLOW_INSTANCE_DIALOG_CODE, { ...props },
  )),
  openConfirmationDialog: (props) => dispatch(openDialog(
    CONFIRMATION_DIALOG_CODE,
    { ...props },
  )),
  openProgressDialog: (props) => dispatch(openDialog(PROGRESS_DIALOG_CODE, { ...props })),
  onClose: () => dispatch(closeDialog()),
  checkExclusiveBrokerage: ({
    strategyId,
    customDialogProps = {},
  }) => dispatch(checkExclusiveBrokerage({ strategyId, customDialogProps })),
});

export const START_FOLLOW_INSTANCE_DIALOG_CODE = 'START_FOLLOW_INSTANCE_DIALOG_CODE';

export default connect(mapStateToProps, mapDispatchToProps)(StartFollowInstanceDialog);
