import React from 'react';
import PropTypes from 'prop-types';
import { Box, Typography } from '@material-ui/core';

import { CLOSED_MARKET_TITLE } from 'common/utils/constants';

const ClosedMarket = ({
  classes,
  message,
}) => (
  <Box>
    <Box mb={2} display="flex" alignItems="center">
      <Box mr={1} className={classes.closedMarketStatus} />
      <Typography variant="h4"><b>{CLOSED_MARKET_TITLE}</b></Typography>
    </Box>
    <Box>
      <Typography variant="h6">{message}</Typography>
    </Box>
  </Box>
);

ClosedMarket.propTypes = {
  classes: PropTypes.object.isRequired,
  message: PropTypes.string,
};

ClosedMarket.defaultProps = {
  message: '',
};

export default ClosedMarket;
