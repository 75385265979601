import React from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  Typography,
} from '@material-ui/core';
import {
  QUESTIONNAIRE,
  QUESTIONNAIRE_DATA,
} from 'common/utils/constants/investorProfile';

import { SteppedDialogFooter } from 'common/components/Steps';
import SiLogoChat from 'common/components/Images/SiLogoChat';
import GrayBox from '../GrayBox';

const InvestorProfileQuestionnaire = ({
  classes,
  handleConfirmQuestionnaire,
  questionnaireDataSteps,
  setQuestionnaireDataSteps,
  setStartQuestionnaire,
}) => {
  const { currentStep, numberOfSteps, answers } = questionnaireDataSteps;

  const handleAnswerChange = (event) => {
    setQuestionnaireDataSteps({
      ...questionnaireDataSteps,
      answers: {
        ...answers,
        [currentStep]: event.target.value,
      },
    });
  };

  const handleStepChange = (step) => {
    const isNextStepClick = step > currentStep;
    const stepChange = isNextStepClick ? 1 : -1;

    if (!isNextStepClick && currentStep === 1) {
      setStartQuestionnaire(false);
    } else {
      setQuestionnaireDataSteps({
        ...questionnaireDataSteps,
        currentStep: currentStep + stepChange,
      });
    }
  };

  return (
    <Box data-testid="investor-profile-questionnaire">
      <Box mb={0.3}>
        <SiLogoChat />
      </Box>

      <GrayBox data-testid="investor-profile-chatbox">
        <Typography variant="h5">
          <Box fontWeight="normal">
            {QUESTIONNAIRE_DATA[currentStep].question}
          </Box>
        </Typography>
      </GrayBox>
      <Box mt={2} pl={2}>
        <FormControl component="fieldset">
          <RadioGroup
            aria-label={QUESTIONNAIRE}
            name={QUESTIONNAIRE}
            onChange={handleAnswerChange}
            value={answers[currentStep]}
          >
            {Object.keys(QUESTIONNAIRE_DATA[currentStep].answers.text)
              .map((choice) => (
                <FormControlLabel
                  key={choice}
                  className={classes.optionLabel}
                  control={<Radio color="primary" />}
                  label={(
                    <Typography variant="h5">
                      {QUESTIONNAIRE_DATA[currentStep].answers.text[choice]}
                    </Typography>
                        )}
                  value={choice}
                />
              ))}
          </RadioGroup>
        </FormControl>
      </Box>
      <SteppedDialogFooter
        displayPreviousStep
        onSetStep={handleStepChange}
        StepsProps={{ currentStep, numberOfSteps }}
        disableNextButton={!answers[currentStep]}
        disableConfirmButton={!answers[numberOfSteps]}
        onClickConfirm={handleConfirmQuestionnaire}
      />
    </Box>
  );
};

InvestorProfileQuestionnaire.propTypes = {
  classes: PropTypes.object.isRequired,
  handleConfirmQuestionnaire: PropTypes.func.isRequired,
  questionnaireDataSteps: PropTypes.object.isRequired,
  setQuestionnaireDataSteps: PropTypes.func.isRequired,
  setStartQuestionnaire: PropTypes.func.isRequired,
};

export default InvestorProfileQuestionnaire;
