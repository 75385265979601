import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Checkbox from '@material-ui/core/Checkbox';
import Card from '@material-ui/core/Card';
import Slider from '@material-ui/core/Slider';
import Button from '@material-ui/core/Button';
import Input from '@material-ui/core/Input';
import InputAdornment from '@material-ui/core/InputAdornment';
import { getCapitalizedText } from '../../utils/string';
import { DEFAULT_CHART_COLORS, STOCK_MODES } from '../../utils/constants';
import StockLogoFinder from '../StockLogoFinder';

const StockCard = ({
  classes,
  className,
  onClickCheck,
  onStockClick,
  isStockClickButton,
  handleInputSlider,
  stock,
  stockIndex,
  isTotalGreaterZero,
  check,
  mode,
  isTemplate,
  isSmartWeights,
  basicVersion,
  customRightComponent,
}) => {
  const name = stock.display_name || stock.company_name;
  const stockCode = stock.code || stock.stock_code;
  const isSearchMode = mode === STOCK_MODES.SEARCH;
  const isUpdateMode = mode === STOCK_MODES.UPDATE;
  const isViewMode = mode === STOCK_MODES.VIEW;

  const isMinWeightBiggerThanMax = stock.minWeight > stock.maxWeight;
  const smartWeightsMinMax = ['minWeight', 'maxWeight'];
  const baseWeightBorderColor = isSmartWeights ? stock.minWeight : stock.weight;
  const shouldSetBorder = !isSmartWeights
    ? baseWeightBorderColor > 0
    : true;

  return (
    <Card
      className={classNames(
        className,
        classes.container,
        isTotalGreaterZero ? classes.containerSelectedStock : classes.containerDefault,
        isViewMode && classes.containerViewMode,
      )}
      style={{
        borderRightColor: shouldSetBorder
          ? DEFAULT_CHART_COLORS[stockIndex % DEFAULT_CHART_COLORS.length]
          : 'transparent',
      }}
      data-testid="stock-item"
      id={`${mode}-card`}
      onClick={!isStockClickButton ? onStockClick : () => {}}
    >
      <div className={classes.companyInfo}>
        <div className={classes.wrapperLogoCheck}>
          {isUpdateMode && (
          <Checkbox
            id="checkbox"
            inputProps={{ 'data-testid': 'checkbox-item' }}
            name={stockCode}
            className={classes.checkbox}
            color="primary"
            onChange={onClickCheck}
            checked={check}
          />
          )}
          {(isViewMode || isSearchMode) && !isTemplate && (
            <StockLogoFinder stock={stock} className={classes.companyLogo} />
          )}
        </div>
        <div className={classNames(
          classes.wrapperNameSlider,
          isUpdateMode && classes.wrapperNameSliderUpdate,
        )}
        >
          <div
            id="company-name"
            className={classNames(
              classes.companyNames,
              isUpdateMode && classes.companyNamesUpdate,
              !isUpdateMode && classes.namesSearchMobile,
            )}
          >
            <span
              title={getCapitalizedText(name)}
              className={classNames(
                classes.name,
                isUpdateMode && classes.nameUpdate,
              )}
            >
              {name}
            </span>
            <span className={classes.ticker}>{stockCode}</span>
          </div>

          {isUpdateMode && (
            <div className={classNames(classes.wrapperSliderLogo)}>
              <StockLogoFinder stock={stock} className={classes.companyLogo} />
              {!isSmartWeights
              && (
              <Slider
                id="slider"
                value={typeof stock.weight === 'number' ? stock.weight : 0}
                onChange={(event, newValue) => handleInputSlider({
                  event,
                  currentStock: stock,
                  newValue,
                  prop: 'weight',
                })}
                onChangeCommitted={(event, newValue) => handleInputSlider({
                  event,
                  currentStock: stock,
                  newValue,
                  commited: true,
                })}
                color="primary"
                className={classNames(
                  classes.slider,
                )}
                classes={{
                  thumb: classes.sliderThumb,
                  active: classes.sliderThumbActive,
                }}
              />
              )}
            </div>
          )}
        </div>
      </div>

      {!isSearchMode && !isSmartWeights && !basicVersion && !customRightComponent && (
        <Input
          className={classNames(
            classes.materialInput,
            isViewMode && classes.wrapperInput,
            isUpdateMode && classes.inputUpdate,
            !stock.weight && classes.error,
          )}
          classes={{
            input: classNames(
              'weight-input',
              classes.input,
            ),
            underline: !stock.weight && classes.error,
          }}
          value={stock.weight}
          type="text"
          disabled={isViewMode}
          endAdornment={(
            <InputAdornment
              position="end"
              className={!stock.weight ? classes.error : ''}
              classes={{ positionEnd: classes.inputAdornment }}
              disableTypography
            >
              %
            </InputAdornment>
                  )}
          onChange={(event) => handleInputSlider({
            event,
            currentStock: stock,
            commited: true,
            prop: 'weight',
          })}
        />
      )}

      {!isSearchMode && isSmartWeights && !customRightComponent && (
        <>
          {smartWeightsMinMax.map((itemWeight) => (
            <Input
              key={itemWeight}
              className={classNames(
                classes.materialInput,
                isViewMode && classes.wrapperInput,
                isUpdateMode && classes.inputUpdate,
                isMinWeightBiggerThanMax && classes.error,
              )}
              classes={{
                input: classNames(
                  itemWeight,
                  classes.input,
                ),
              }}
              value={stock[itemWeight]}
              type="text"
              disabled={isViewMode}
              endAdornment={(
                <InputAdornment
                  position="end"
                  className={isMinWeightBiggerThanMax ? classes.error : ''}
                  classes={{ positionEnd: classes.inputAdornment }}
                  disableTypography
                >
                  %
                </InputAdornment>
            )}
              onChange={(event) => handleInputSlider({
                event,
                currentStock: stock,
                commited: true,
                prop: itemWeight,
                minValue: itemWeight === 'minWeight' ? 0 : 1,
                maxValue: 99,
              })}
            />
          ))}
        </>
      )}

      {isStockClickButton && (
        <Button
          id="add-button"
          variant="outlined"
          color="primary"
          className={classes.rightAction}
          classes={{ root: classes.buttonRoot }}
          onClick={() => onStockClick(stock)}
        >
          <div className={classes.horizontal} />
          <div className={classes.vertical} />
        </Button>
      )}

      { !basicVersion && customRightComponent }

    </Card>
  );
};

StockCard.propTypes = {
  classes: PropTypes.object.isRequired,
  className: PropTypes.string,
  mode: PropTypes.string,
  onStockClick: PropTypes.func,
  isStockClickButton: PropTypes.bool,
  onClickCheck: PropTypes.func,
  stock: PropTypes.object.isRequired,
  isTemplate: PropTypes.bool,
  stockIndex: PropTypes.number,
  handleInputSlider: PropTypes.func,
  isTotalGreaterZero: PropTypes.bool,
  check: PropTypes.bool,
  isSmartWeights: PropTypes.bool,
  basicVersion: PropTypes.bool,
  customRightComponent: PropTypes.object,
};

StockCard.defaultProps = {
  className: '',
  mode: STOCK_MODES.UPDATE,
  onStockClick: () => {},
  isStockClickButton: false,
  onClickCheck: () => {},
  check: false,
  handleInputSlider: () => {},
  isTotalGreaterZero: false,
  stockIndex: 0,
  isTemplate: false,
  isSmartWeights: false,
  basicVersion: false,
  customRightComponent: null,
};

export default StockCard;
