import api from 'common/api';
import { LOGOUT_SUCCEED } from 'common/ducks/auth';
import { isInvestment } from 'common/utils/validation';

export const DUCK_NAME = 'investments';
export const INITIAL_STATE = {
  // Expected structure:
  // [the instance id]: INVESTMENTS_INITIAL_STATE,
};
export const INVESTMENTS_INITIAL_STATE = {
  loading: false,
  data: null,
  transactions: null,
  error: null,
};

export const UPDATE_INVESTMENT_STARTED = `${DUCK_NAME}/UPDATE_INVESTMENT_STARTED`;
export const UPDATE_INVESTMENT_SUCCEED = `${DUCK_NAME}/UPDATE_INVESTMENT_SUCCEED`;
export const UPDATE_INVESTMENT_FAILED = `${DUCK_NAME}/UPDATE_INVESTMENT_FAILED`;
export const updateInvestmentStarted = () => ({
  type: UPDATE_INVESTMENT_STARTED,
});
export const updateInvestmentSucceed = () => ({
  type: UPDATE_INVESTMENT_SUCCEED,
});
export const updateInvestmentFailed = (error) => ({
  error,
  type: UPDATE_INVESTMENT_FAILED,
});
export const updateInvestment = (data) => async (dispatch) => {
  dispatch(updateInvestmentStarted);
  try {
    await api.investments.updateInvestment(data);
    dispatch(updateInvestmentSucceed());
    return Promise.resolve(data);
  } catch (error) {
    dispatch(updateInvestmentFailed(error));
    return Promise.reject(error);
  }
};

export const GET_FINANCIAL_TRANSACTIONS_STARTED = `${DUCK_NAME}/GET_FINANCIAL_TRANSACTIONS_STARTED`;
export const GET_FINANCIAL_TRANSACTIONS_SUCCEED = `${DUCK_NAME}/GET_FINANCIAL_TRANSACTIONS_SUCCEED`;
export const GET_FINANCIAL_TRANSACTIONS_FAILED = `${DUCK_NAME}/GET_FINANCIAL_TRANSACTIONS_FAILED`;
export const getFinancialTransactionsStarted = (instanceId) => ({
  instanceId,
  type: GET_FINANCIAL_TRANSACTIONS_STARTED,
});
export const getFinancialTransactionsSucceed = (instanceId, data, transactions) => ({
  instanceId,
  data,
  transactions,
  type: GET_FINANCIAL_TRANSACTIONS_SUCCEED,
});
export const getFinancialTransactionsFailed = (instanceId, error) => ({
  instanceId,
  error,
  type: GET_FINANCIAL_TRANSACTIONS_FAILED,
});
export const getFinancialTransactions = (
  instanceId,
  config,
  options = { reload: false },
) => async (dispatch, getState) => {
  const investments = getState()[DUCK_NAME];
  const instanceInvestments = investments[instanceId] ? investments[instanceId] : {};

  if (!options.reload && (investments && !instanceInvestments.error && instanceInvestments.data)) {
    return Promise.resolve(instanceInvestments);
  }

  dispatch(getFinancialTransactionsStarted(instanceId));
  try {
    const { data } = await api.investments.getFinancialTransactions({ params: config });
    const transactions = {
      investments: 0,
      withdrawals: 0,
    };
    data.map((transaction) => {
      if (parseInt(transaction.contribution_or_withdrawal, 10)) {
        transactions.withdrawals += parseFloat(transaction.value);
      } else {
        transactions.investments += parseFloat(transaction.value);
      }
      return transactions;
    });
    dispatch(getFinancialTransactionsSucceed(instanceId, data, transactions));
    return Promise.resolve(transactions);
  } catch (error) {
    dispatch(getFinancialTransactionsFailed(instanceId, error));
    return Promise.reject(error);
  }
};

export const INSERT_FINANCIAL_TRANSACTION_STARTED = `${DUCK_NAME}/INSERT_FINANCIAL_TRANSACTION_STARTED`;
export const INSERT_FINANCIAL_TRANSACTION_SUCCEED = `${DUCK_NAME}/INSERT_FINANCIAL_TRANSACTION_SUCCEED`;
export const INSERT_FINANCIAL_TRANSACTION_FAILED = `${DUCK_NAME}/INSERT_FINANCIAL_TRANSACTION_FAILED`;
export const insertFinancialTransactionStarted = () => ({
  type: INSERT_FINANCIAL_TRANSACTION_STARTED,
});
export const insertFinancialTransactionSucceed = (instanceId, data) => ({
  instanceId,
  data,
  type: INSERT_FINANCIAL_TRANSACTION_SUCCEED,
});
export const insertFinancialTransactionFailed = (error) => ({
  error,
  type: INSERT_FINANCIAL_TRANSACTION_FAILED,
});
export const insertFinancialTransaction = (instanceId, data) => async (dispatch) => {
  dispatch(insertFinancialTransactionStarted());
  try {
    await api.investments.insertFinancialTransaction(data);
    dispatch(insertFinancialTransactionSucceed(instanceId, data));
    return Promise.resolve(data);
  } catch (error) {
    dispatch(insertFinancialTransactionFailed(error));
    return Promise.reject(error);
  }
};

export const investmentsReducer = (state = INVESTMENTS_INITIAL_STATE, action) => {
  switch (action.type) {
    case UPDATE_INVESTMENT_STARTED:
      return {
        ...state,
        loading: true,
      };
    case UPDATE_INVESTMENT_SUCCEED:
      return {
        ...state,
        loading: false,
      };
    case UPDATE_INVESTMENT_FAILED:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    case GET_FINANCIAL_TRANSACTIONS_STARTED:
      return {
        ...state,
        loading: true,
      };
    case GET_FINANCIAL_TRANSACTIONS_SUCCEED:
      return {
        ...state,
        loading: false,
        data: action.data,
        transactions: action.transactions,
      };
    case GET_FINANCIAL_TRANSACTIONS_FAILED:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    case INSERT_FINANCIAL_TRANSACTION_STARTED:
      return {
        ...state,
        loading: true,
      };
    case INSERT_FINANCIAL_TRANSACTION_SUCCEED:
      return {
        ...state,
        loading: false,
        transactions: {
          ...state.transactions,
          investments: isInvestment(action.data.contribution_or_withdrawal)
            ? state.transactions.investments + parseFloat(action.data.value)
            : state.transactions.investments,
          withdrawals: isInvestment(action.data.contribution_or_withdrawal)
            ? state.transactions.withdrawals
            : state.transactions.withdrawals + parseFloat(action.data.value),
        },
        data: [...state.data, action.data],
      };
    case INSERT_FINANCIAL_TRANSACTION_FAILED:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    default:
      return state;
  }
};

export default (state = INITIAL_STATE, action) => {
  // Logout cleaning
  if (action.type === LOGOUT_SUCCEED) {
    return INITIAL_STATE;
  }

  const { instanceId } = action;

  if (instanceId) {
    return {
      ...state,
      [instanceId]: investmentsReducer(
        state[instanceId],
        action,
      ),
    };
  }

  return state;
};
