import logdown from 'logdown';
import { reduceObjectsArrayToObject } from 'common/utils/array';

// Ducks
import { LOGOUT_SUCCEED } from 'common/ducks/auth';
import { getCredentials, getStock } from 'common/ducks/stocks';
import { getStocksWithTradingLotSize } from 'common/utils/stocks';

const logger = logdown('ducks/instance');

export const DUCK_NAME = 'storeItem';

export const INITIAL_STATE = {};

export const GET_STORE_ITEM_STARTED = `${DUCK_NAME}/GET_STORE_ITEM_STARTED`;
export const GET_STORE_ITEM_SUCCEED = `${DUCK_NAME}/GET_STORE_ITEM_SUCCEED`;
export const GET_STORE_ITEM_FAILED = `${DUCK_NAME}/GET_STORE_ITEM_FAILED`;

export const getStoreItemStarted = () => ({ type: GET_STORE_ITEM_STARTED });
export const getStoreItemSucceed = (data) => ({ type: GET_STORE_ITEM_SUCCEED, data });
export const getStoreItemFailed = (error) => ({ type: GET_STORE_ITEM_FAILED, error });

export const getStoreItem = (id) => async (dispatch, getState) => {
  dispatch(getStoreItemStarted());
  const { storeItems: { data: { itemsWithPrivate } } } = getState();

  try {
    const storeItem = Object.values(itemsWithPrivate).find((item) => item.id === id);

    dispatch(getStoreItemSucceed(storeItem));
    return Promise.resolve(storeItem);
  } catch (error) {
    logger.error('Could not get store.', error);
    dispatch(getStoreItemFailed(error));
    return Promise.reject(error);
  }
};

export const UPDATE_STORE_ITEM_SUCCEED = `${DUCK_NAME}/UPDATE_STORE_ITEM_SUCCEED`;

export const updateStoreItemSucceed = (data) => ({ type: UPDATE_STORE_ITEM_SUCCEED, data });

export const updateStoreItemStocks = (storeItem) => async (dispatch) => {
  try {
    const credentials = await dispatch(getCredentials());
    const promises = Object.values(storeItem.stocks)
      .map((stock) => dispatch(getStock({ stockCode: stock.code, credentials })));

    await Promise.all(promises)
      .then((response) => {
        const searchedStocks = response.filter((item) => Object.keys(item).length);

        const stocks = getStocksWithTradingLotSize({
          storeItemStocks: storeItem.stocks,
          searchedStocks,
        });

        if (stocks.length) {
          storeItem.stocks = {
            ...storeItem.stocks,
            ...reduceObjectsArrayToObject(stocks, 'code'),
          };
          dispatch(updateStoreItemSucceed(storeItem));
        }
      });

    return Promise.resolve(storeItem);
  } catch (error) {
    dispatch(getStoreItemFailed(error));
    return Promise.reject(error);
  }
};

// Reducer
export default (state = INITIAL_STATE, action) => {
  // Logout cleaning
  if (action.type === LOGOUT_SUCCEED) {
    return INITIAL_STATE;
  }

  switch (action.type) {
    case GET_STORE_ITEM_STARTED:
      return {
        ...state,
      };
    case GET_STORE_ITEM_SUCCEED:
      return {
        ...state,
        ...action.data,
      };
    case GET_STORE_ITEM_FAILED:
      return {
        ...state,
        error: action.error,
      };
    case UPDATE_STORE_ITEM_SUCCEED:
      return {
        ...state,
        ...action.data,
      };
    default: return state;
  }
};
