import { withStyles } from '@material-ui/core/styles';
import palette from 'common/palette';

import LabelAction from './LabelAction';

const styles = {
  label: {
    width: '100%',
    height: 55,
    border: `1px solid ${palette.default.main}`,
    borderRadius: 3,
    whiteSpace: 'nowrap',
    marginTop: 0,
    marginBottom: 10,
    padding: 5,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  logo: {
    width: 50,
    marginRight: 10,
    display: 'flex',
    alignItems: 'center',
  },
  descriptionContainer: {
    flexGrow: 1,
  },
  description: {
    fontWeight: 700,
    fontSize: 12,
  },
};

export default withStyles(styles)(LabelAction);
