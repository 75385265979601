import React from 'react';
import { Box, Typography } from '@material-ui/core';

export const checkNextButtonIsDisable = ({
  startFromZero,
  currentStep,
  equity,
  storeInstance,
}) => {
  return !startFromZero && currentStep === 4
  && (!equity
  || equity.totalEquityContribution < storeInstance.extra_data.tags.minimum
  || equity.totalEquityContribution > storeInstance.planLimit);
};

export const getBlockedMovementDialogText = (stocks) => (
  <Typography variant="h5">
    <Box>
      O ativo
      {' '}
      <b>{stocks}</b>
      {' '}
      é o último remanescente da composição da sua carteira teórica e, por isso, sua
      posição não pode ser encerrada via boleta ou ordem externa.
    </Box>
    <Box mt={2}>
      Para encerrar essa posição, remova esse ativo de sua carteira teórica na
      aba “Configurações” e adeque sua composição de acordo com os ativos e
      pesos de sua carteira atual.
    </Box>
  </Typography>
);

export const getExternalBillingMessage = ({ brokerage, name }) => (
  <Typography variant="h5">
    Você adquiriu sua carteira
    {' '}
    <b>{name}</b>
    {' '}
    via
    {' '}
    <b>{brokerage}</b>
    . Dessa forma a gestão da sua assinatura (upgrade, downgrade e cancelamento)
    deve ocorrer na página dedicada da plataforma da corretora.
  </Typography>
);
