import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Prompt } from 'react-router-dom';
import { LOGIN_URL } from 'common/utils/constants';

const PageAbandonment = ({
  openConfirmationDialog,
  setIsPreventChangeRoute,
  logout,
  route: { isPreventChangeRoute },
  history,
  hasDataChanges,
}) => {
  const [preventLocation, setPreventLocation] = useState(null);
  const [confirmedNavigation, setConfirmedNavigation] = useState(false);

  useEffect(() => {
    if (confirmedNavigation) {
      history.push(preventLocation.pathname);
    }
  }, [confirmedNavigation, history, preventLocation]);

  useEffect(() => {
    if (preventLocation) {
      const handleConfirmNavigationClick = (onClose) => {
        if (preventLocation) {
          setConfirmedNavigation(true);
          onClose();
          if (preventLocation.pathname === LOGIN_URL) {
            logout();
          }
        }
      };

      openConfirmationDialog({
        title: 'Sair sem salvar',
        content: 'Se você sair agora as alterações serão perdidas. Você tem certeza?',
        maxWidth: 'sm',
        responsive: true,
        preventClose: true,
        onConfirmClick: (onClose) => handleConfirmNavigationClick(onClose),
      });
    }
  }, [preventLocation, openConfirmationDialog, logout]);

  useEffect(() => {
    if (hasDataChanges) {
      setIsPreventChangeRoute(true);
    } else if (isPreventChangeRoute) {
      setIsPreventChangeRoute(false);
    }
  }, [isPreventChangeRoute, hasDataChanges, setIsPreventChangeRoute]);

  const handleBlockedNavigation = (location) => {
    if (!confirmedNavigation && hasDataChanges) {
      setPreventLocation(location);
      return false;
    }

    return true;
  };

  return (
    <Prompt
      when={hasDataChanges}
      message={handleBlockedNavigation}
    />
  );
};

PageAbandonment.propTypes = {
  openConfirmationDialog: PropTypes.func.isRequired,
  setIsPreventChangeRoute: PropTypes.func.isRequired,
  logout: PropTypes.func.isRequired,
  route: PropTypes.shape({
    isPreventChangeRoute: PropTypes.bool,
  }).isRequired,
  history: PropTypes.object.isRequired,
  hasDataChanges: PropTypes.bool.isRequired,
};

export default PageAbandonment;
