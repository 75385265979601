import { withStyles } from '@material-ui/core';
import palette from 'common/palette';

import LinkButton from './LinkButton';

const styles = {
  button: {
    display: 'block',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
  primary: {
    color: palette.primary.main,
  },
  textSecondary: {
    color: palette.textSecondary.main,
  },
  white: {
    color: '#fff',
  },
  inline: {
    display: 'inline-block',
  },
  textDecoration: {
    textDecoration: 'underline',
  },
};

export default withStyles(styles)(LinkButton);
