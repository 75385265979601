import accounting from 'accounting';
import logdown from 'logdown';
import size from 'lodash/size';
import get from 'lodash/get';
import sum from 'lodash/sum';
import multiply from 'lodash/multiply';
import toNumber from 'lodash/toNumber';
import isObject from 'lodash/isObject';
import moment from 'moment-timezone';

import api from 'common/api';
import companies from 'common/assets/mocks/companies-data.json';
import { BR_TIME_FORMAT } from 'common/utils/constants/date';
import { BUY, SELL } from 'common/utils/constants/stocks';
import { getValidDateAndTime } from 'common/utils/date';
import {
  POSITION_PARAMS,
  DEFAULT_LOGO,
  DESC,
  POSITION_PROP_RESULT,
  POSITION_PROP_FINANCIAL_VOL,
} from './constants';
import { RESET_POSITION_ERROR_MESSAGE } from './constants/positions';
import { orderByString, sortByNumber, reduceObjectsArrayToObject } from './array';
import { validateStockCode } from './validation';
import { getStockFraction, checkIfFractionalStock } from './stocks';
import { getCurrentDate } from './date';

const logger = logdown('utils positions');

/**
 *
 * @param {Number} id The instance id
 * @param {Array} positions The positions to be reset
 * @returns {Array} The reset orders id's
 */
export const resetPosition = async (id, positions, config) => {
  const promises = [];

  for (let i = 0; i < positions.length; i += 1) {
    logger.log('(resetAllPositions)', `Sending reset request for position ${i}`, positions[i]);
    promises.push(api.strategies.instances.positions
      .resetPosition(id, {
        ...positions[i],
        ...config,
      }));
  }

  const results = await Promise.all(promises);

  logger.log('(resetAllPositions)', 'Requests results received', results);

  const orderIds = [];
  for (let i = 0; i < positions.length; i += 1) {
    const positionsOrderIds = results[i].data.order_ids;
    for (let j = 0; j < positionsOrderIds.length; j += 1) {
      orderIds.push(positionsOrderIds[j]);
    }
  }

  logger.log('(resetAllPositions)', 'Reset orders array', orderIds);

  return { data: { order_ids: orderIds } };
};

export const resetPositions = async (
  id,
  positions,
  config = {
    resetAllPositions: true,
  }) => {
  if (config.resetAllPositions) {
    const { data: { order_ids } } = await api.strategies.instances.positions
      .resetAllPositions(id, {
        ...config,
      });

    return { data: { order_ids } };
  }

  const result = await resetPosition(id, positions, config);

  return result;
};

/**
 * Receives a list of instances and a hashmap of each instance position
 * and transforms it to a hashmap of stock codes of all positions,
 * accumulating it's number of stocks, average prices, and calculating
 * the final balance for each stock code
 * @param {object} positions
 * @param {array} instancesIds
 */
export const getStockPositions = (positions, instancesIds) => {
  let stockPositions = {};
  let positionsBalance = 0;

  if (size(instancesIds) === 0) {
    return { stockPositions, positionsBalance };
  }
  // Ensures that value is valid numbe
  const assertValidNumber = (value) => (
    Number.isNaN(value)
      ? 0
      : value
  );

  // Iterate through each positions instance id
  instancesIds
    .forEach((instanceId) => {
      if (get(positions[instanceId], 'data', false)) {
        // Iterate on each stockCode and accumulate each atribute value
        // on a single one per stock code.
        Object.keys(positions[instanceId].data).forEach((stockCode) => {
          let {
            result,
            number_of_stocks: numberOfStocks,
            average_price: averagePrice,
            position_type: positionType,
          } = positions[instanceId].data[stockCode];

          // Cast string values to integer or float
          numberOfStocks = assertValidNumber(toNumber(numberOfStocks));
          averagePrice = assertValidNumber(toNumber(averagePrice));
          result = assertValidNumber(toNumber(result));
          positionType = assertValidNumber(toNumber(positionType));

          // Sum the result from all positions
          stockPositions = {
            ...stockPositions,
            [stockCode]:
            // initializes stock code if it's the first found
            stockPositions[stockCode]
              ? {
                ...stockPositions[stockCode],
                // Sum the number of stock if it is BUY
                // Substract the number of stocks if it is SELL
                number_of_stocks:
                  stockPositions[stockCode].number_of_stocks + (
                    (numberOfStocks * (-(positionType - 1)))
                    - (numberOfStocks * positionType)
                  ),
                average_price:
                  stockPositions[stockCode].average_price + averagePrice,
                result:
                  stockPositions[stockCode].result + result,
                // Count each stock code to divide the average price
                count: stockPositions[stockCode].count + 1,
              }
              // Initialize stock code
              : {
                market_name: positions[instanceId].data[stockCode].market_name,
                result,
                average_price: averagePrice,
                count: 1,
                number_of_stocks:
                  (numberOfStocks * (-(positionType - 1)))
                  - (numberOfStocks * positionType),
              },
          };
        });
      }
    });

  // Mount the final object that accumulates all information by stock code
  Object.keys(stockPositions)
    .forEach((stockCode) => {
      positionsBalance += stockPositions[stockCode].result;
      stockPositions[stockCode] = {
        ...stockPositions[stockCode],
        stock_code: stockCode,
        number_of_stocks:
          Math.abs(stockPositions[stockCode].number_of_stocks).toString(),
        average_price: (
          stockPositions[stockCode].average_price
          / stockPositions[stockCode].count
        ).toString(),
        result: stockPositions[stockCode].result.toString(),
        position_type: stockPositions[stockCode].number_of_stocks > 0
          ? '0'
          : '1',
        count: stockPositions[stockCode].count,
      };
    });
  return { stockPositions, positionsBalance };
};

const getClosedMarketMessage = (openTime, closeTime) => {
  return `O horário de negociação no mercado de ações é das ${openTime?.format('HH:mm')} até ${closeTime?.format('HH:mm')} em dias úteis.`;
};

export const validateClosedMarket = ({
  holidays,
  marketOperationData,
  position,
  resetMode,
}) => {
  const { operationPeriod, error } = marketOperationData;
  const openTime = operationPeriod?.openTime;
  const closeTime = operationPeriod?.closeTime;

  if (error) {
    return resetMode ? RESET_POSITION_ERROR_MESSAGE : error;
  }

  const closedMarketMessage = getClosedMarketMessage(openTime, closeTime);

  if (position?.is_market_open === '0') {
    return closedMarketMessage;
  }

  const now = getCurrentDate();

  if (now.day() === 0 || now.day() === 6) {
    return closedMarketMessage;
  }

  const isHoliday = holidays.reduce(
    (accumulator, holiday) => accumulator || holiday.isSame(now, 'day'), false,
  );

  if (isHoliday) {
    return closedMarketMessage;
  }

  return !now.isBetween(openTime, closeTime, 'second', '[]') ? closedMarketMessage : null;
};

export const getPositionsFilter = (positions) => ({
  betterPercentPerformance: {
    id: 'betterPercentPerformance',
    name: 'Melhor desempenho (%)',
    filter: () => positions.sort(sortByNumber(POSITION_PARAMS.variation, DESC)),
  },
  worstPercentPerformance: {
    id: 'worstPercentPerformance',
    name: 'Pior desempenho (%)',
    filter: () => positions.sort(sortByNumber(POSITION_PARAMS.variation)),
  },
  betterCurrencyPerformance: {
    id: 'betterCurrencyPerformance',
    name: 'Melhor desempenho (R$)',
    filter: () => positions.sort(sortByNumber(POSITION_PARAMS.result, DESC)),
  },
  worstCurrencyPerformance: {
    id: 'worstCurrencyPerformance',
    name: 'Pior desempenho (R$)',
    filter: () => positions.sort(sortByNumber(POSITION_PARAMS.result)),
  },
  higherVolume: {
    id: 'higherVolume',
    name: 'Maior volume',
    filter: () => positions.sort(sortByNumber(POSITION_PARAMS.financial_volume, DESC)),
  },
  lowerVolume: {
    id: 'lowerVolume',
    name: 'Menor volume',
    filter: () => positions.sort(sortByNumber(POSITION_PARAMS.financial_volume)),
  },
  alfabeticalOrder: {
    id: 'alfabeticalOrder',
    name: 'Ordem alfabética',
    filter: () => orderByString(positions, 'company_name'),
  },
});

export const filterPositions = ({ value, positionsFilter }) => positionsFilter[value].filter();

export const isNumberStocks = (type) => {
  return type === POSITION_PARAMS.number_of_stocks;
};

export const getOnlyLettersStockCode = (stockCode) => {
  return stockCode.slice(0, 4);
};

export const getPositionCompanyNameOrStockCode = (position) => {
  return position.company_name || position.stock_code;
};

export const getPositionTypeLabel = (numberOfStocks) => {
  return numberOfStocks > 0 ? BUY : SELL;
};

export const getStockCodesAndNumber = (positions) => {
  const positionsArr = isObject(positions) ? Object.values(positions) : positions;

  return positionsArr.map((position) => ({
    number: position.number_of_stocks,
    code: position.stock_code,
  }));
};

export const calcTotalItemPositions = (item, positions) => (
  positions.reduce((accumulator, currentValue) => {
    const value = Number(currentValue[item]);

    return accumulator + value;
  }, 0)
);

export const getCorrectPositions = (positions) => {
  const filteredPositions = Object.values(positions.data).filter((position) => (
    Number(position.number_of_stocks) !== 0
  ));
  const objectPositions = reduceObjectsArrayToObject(filteredPositions, 'stock_code');

  return { ...positions, data: { ...objectPositions } };
};

export const getCorrectFinancialVolume = (financialVolume, result) => {
  return String(Number(financialVolume) + Number(result));
};

export const getCorrectValue = (position, param) => {
  const value = Number(position[param]);
  const positionType = Number(position.position_type);

  return positionType === 1 ? value * -1 : value;
};

export const getTotalNumberStocks = (currentPosition, storedPosition) => {
  const param = POSITION_PARAMS.number_of_stocks;

  return getCorrectValue(currentPosition, param) + getCorrectValue(storedPosition, param);
};

export const getTotalFinancialVol = (currentPosition, storedPosition) => {
  const param = POSITION_PARAMS.financial_volume;

  return getCorrectValue(currentPosition, param) + getCorrectValue(storedPosition, param);
};

export const getCorrectNumberStocks = (position) => {
  return Number(position.position_type) === 0 ? Number(position.number_of_stocks) : 0;
};

export const getTotalAveragePrice = (currentPosition, storedPosition) => {
  const stocksCurrentPosition = getCorrectNumberStocks(currentPosition);
  const stocksStoredPosition = getCorrectNumberStocks(storedPosition);

  const currentAverage = multiply(Number(currentPosition.average_price), stocksCurrentPosition);
  const storedAverage = multiply(Number(storedPosition.average_price), stocksStoredPosition);

  const totalStocks = stocksCurrentPosition + stocksStoredPosition;
  const totalAveragePrice = (currentAverage + storedAverage) / totalStocks;

  return totalAveragePrice;
};

export const getWeightPosition = ({ position, equity }) => {
  const { financial_volume } = position;

  return (Number(financial_volume) / equity) * 100;
};

export const setWeightToPositions = (positions, equity) => {
  Object.values(positions.data).forEach((position) => {
    position.weight = getWeightPosition({ position, equity });
  });

  return {
    ...positions,
    data: {
      ...positions.data,
    },
  };
};

export const getCurrentPrice = ({ totalFinancialVol, totalNumberStocks }) => {
  return totalFinancialVol / totalNumberStocks;
};

export const getPositionCompany = (stockCode) => {
  const lettersStockCode = getOnlyLettersStockCode(stockCode);
  const positionCompany = companies[lettersStockCode];

  return positionCompany || { logo: DEFAULT_LOGO, company_name: stockCode };
};

export const getNewPositionDataCalculated = (currentPosition, storedPosition) => {
  const totalNumberStocks = getTotalNumberStocks(currentPosition, storedPosition);
  const totalResult = sum([Number(currentPosition.result), Number(storedPosition.result)]);
  const totalFinancialVol = getTotalFinancialVol(
    currentPosition,
    storedPosition,
  ) + totalResult;
  const totalAveragePrice = getTotalAveragePrice(currentPosition, storedPosition);
  const totalVariation = (totalResult / (totalAveragePrice * totalNumberStocks)) * 100;
  const totalPrice = getCurrentPrice({ totalFinancialVol, totalNumberStocks });

  return {
    number_of_stocks: String(totalNumberStocks),
    financial_volume: String(totalFinancialVol),
    average_price: String(totalAveragePrice),
    result: String(totalResult),
    variation: String(totalVariation),
    price: String(totalPrice),
  };
};

const getPositionsWithTotalValues = (positions) => {
  const arrPositions = Object.values(positions.data);

  const totalResult = calcTotalItemPositions(POSITION_PROP_RESULT, arrPositions);
  const totalFinancialVol = calcTotalItemPositions(POSITION_PROP_FINANCIAL_VOL, arrPositions);

  return {
    ...positions,
    totalResult,
    totalFinancialVol,
  };
};

export const getPositionByStockCode = ({ stockCode, positions }) => {
  return positions.find((item) => item.stock_code === stockCode);
};

const getFinancialVolumeMergedPositions = ({ currentPosition, positions }) => {
  const formattedStockCode = currentPosition.stock_code.replace(/F$/, '');
  let financialVolume = currentPosition.financial_volume;

  const financialVolumeWithResult = getCorrectFinancialVolume(
    currentPosition.financial_volume,
    currentPosition.result,
  );

  if (checkIfFractionalStock({ stockCode: currentPosition.stock_code })) {
    const hasFullStock = getPositionByStockCode({
      stockCode: formattedStockCode,
      positions,
    });

    financialVolume = hasFullStock
      ? currentPosition.financial_volume
      : financialVolumeWithResult;

    return financialVolume;
  }

  const hasFractionalStock = getPositionByStockCode({
    stockCode: `${formattedStockCode}F`,
    positions,
  });

  financialVolume = hasFractionalStock
    ? currentPosition.financial_volume
    : financialVolumeWithResult;

  return financialVolume;
};

/**
 * Receives a list of positions and make a merge between objects from the same stock_code,
 * like PETR4F with PETR4, making them one position instead of two.
 * @param {array} positions
 */
export const getMergedFractionalAndFullPositions = (positions, equity) => {
  const items = positions.reduce((acc, currentPosition) => {
    const stockCode = currentPosition.stock_code.replace(/F$/, '');
    const storedPosition = acc.data[stockCode];
    const positionCompany = getPositionCompany(stockCode);

    const financialVolume = getFinancialVolumeMergedPositions({
      currentPosition,
      positions,
    });

    let data = {
      stock_code: stockCode,
      price: getCurrentPrice({
        totalFinancialVol: financialVolume,
        totalNumberStocks: Number(currentPosition.number_of_stocks),
      }),
      financial_volume: financialVolume,
    };

    if (storedPosition) {
      data = {
        ...data,
        ...getNewPositionDataCalculated(currentPosition, storedPosition),
      };
    }

    const newPosition = {
      ...positionCompany,
      ...currentPosition,
      ...data,
    };

    return {
      ...acc,
      data: {
        ...acc.data,
        [stockCode]: newPosition,
      },
    };
  }, { data: {} });

  const correctPositions = getCorrectPositions(items);
  const positionsWithTotalValues = getPositionsWithTotalValues(correctPositions);
  const completedPositions = setWeightToPositions(positionsWithTotalValues, equity);

  return completedPositions;
};

export const getTotalFinancialVolumePercentage = (totalFinancialVol, equity) => {
  return accounting.formatNumber((totalFinancialVol / equity) * 100, 2);
};

export const getAvailableBalance = (totalFinancialVol, equity) => {
  return equity - totalFinancialVol;
};

export const getAvailableBalancePercentage = (totalFinancialVol, equity) => {
  return ((equity - totalFinancialVol) / equity) * 100;
};

export const getIsFormValid = ({
  errors,
  dirty,
  computedProps,
  values,
  status,
}) => {
  const hasErrors = Object.keys(errors).length > 0;
  const errorStatusStocks = status
    ? Object.keys(status.errors).map((item) => item)
    : false;
  const hasEmptyValues = Object.values(values.positions).some((position) => {
    const hasEmptyValue = Object.keys(position).some((keyItem) => {
      return position[keyItem] === '';
    });

    return hasEmptyValue;
  });

  const hasStatusError = errorStatusStocks
    ? Object.values(values.positions).some((item) => {
      return errorStatusStocks.includes(item.stock);
    })
    : false;

  if (hasStatusError) {
    return false;
  }

  if (!hasErrors) {
    if (computedProps.isValid || !hasEmptyValues) {
      return true;
    }

    if (!dirty) {
      return false;
    }
  }

  return false;
};

export const getListStocksWithStartString = ({ stock, positions }) => {
  return positions.filter((position) => {
    return position.stock.toUpperCase().startsWith(stock)
    && position.stock.length > 4;
  });
};

export const getPositionIndex = ({ positions, stock }) => {
  return positions.findIndex((item) => item.stock.toUpperCase() === stock);
};

export const updatePositionStock = ({
  finalPosition,
  positions,
  indexCurrentStock,
}) => {
  return positions.map((item, indexPos) => {
    if (indexPos === indexCurrentStock) {
      return finalPosition;
    }

    return item;
  });
};

export const getValidPositions = (positions, arrErrors) => {
  return positions.filter((curPosition) => {
    return !arrErrors.includes(curPosition.stock);
  });
};

export const checkPositionsAreValid = async ({
  values,
  status = {
    errors: {},
  },
  validStocks,
  setStatus,
}) => {
  const promises = values.positions.map(async (item) => {
    if (item.stock !== '' && !validStocks[item.stock]) {
      const result = await validateStockCode(item.stock);

      return result;
    }

    if (status.errors[item.stock]) {
      return {
        stockCodeError: status.errors[item.stock],
      };
    }

    return item;
  });

  const isPromiseResultValid = await Promise.all(promises)
    .then((result) => {
      const positionErrors = result.reduce((acc, item, index) => {
        if (item.stockCodeError) {
          return {
            ...acc,
            [values.positions[index].stock]: item.stockCodeError,
          };
        }

        return acc;
      }, {});

      if (Object.keys(positionErrors).length === 0) {
        return Promise.resolve(true);
      }

      setStatus({
        ...status,
        errors: {
          ...status.errors,
          ...positionErrors,
        },
      });

      return Promise.resolve(false);
    });

  return isPromiseResultValid;
};

export const getStockError = ({
  errors,
  status,
  index,
  position,
}) => {
  if (Object.keys(status.errors).length > 0) {
    if (status.errors[position.stock]) {
      return status.errors[position.stock];
    }
  }

  if (errors.positions) {
    return errors.positions[index]?.stock;
  }

  return false;
};

export const getStockTouched = ({
  touched,
  status,
  index,
  position,
}) => {
  if (Object.keys(status.errors).length > 0
    && status.errors[position.stock]) {
    return !!status.errors[position.stock];
  }

  if (touched.positions) {
    return touched?.positions[index]?.stock;
  }

  return false;
};

export const getCurrentTotalEquity = ({ positions, candles }) => {
  return positions.reduce((acc, position, index) => {
    const price = Number(candles[index].close);
    const positionValue = Number(position.number_of_stocks) * price;

    return acc + positionValue;
  }, 0);
};

export const getStockCodeByAmount = ({ position, numberOfStocks }) => {
  const { stock, trading_lot_size } = position;

  if (Number(numberOfStocks) >= trading_lot_size) return stock.replace(/F$/, '');

  if (checkIfFractionalStock({ stockCode: stock })) return stock;

  return `${stock}F`;
};

export const getPositivePosition = (position, numberOfStocks) => {
  if (numberOfStocks === 0) return {};

  const stockCode = getStockCodeByAmount({ position, numberOfStocks });

  return {
    [stockCode]: {
      ...position,
      number_of_stocks: String(numberOfStocks),
      stock: stockCode,
    },
  };
};

export const getSplitPositionsByFullAndFractional = ({ positions }) => {
  const splitPostitions = positions.reduce((acc, currentPosition) => {
    const totalNumberStocks = Number(currentPosition.number_of_stocks);
    const fractionalNumberStocks = getStockFraction({
      numberOfStocks: totalNumberStocks,
      tradingLotSize: currentPosition.trading_lot_size,
    });
    const fullNumberStocks = totalNumberStocks - fractionalNumberStocks;

    const full = getPositivePosition(currentPosition, fullNumberStocks);
    const fractional = getPositivePosition(currentPosition, fractionalNumberStocks);

    return {
      ...acc,
      ...full,
      ...fractional,
    };
  }, {});

  return splitPostitions;
};

export const getPositionsWithLotSize = ({ positions, validStocks }) => {
  return positions.map((curPosition) => ({
    ...curPosition,
    trading_lot_size: validStocks[curPosition.stock]?.trading_lot_size,
  }));
};

export const renderFieldsPositionOrder = (hasOrderType = false, isExternalOrder) => {
  const basePositionFields = {
    stock: {
      label: 'Ativo',
      name: 'stock',
      tooltip: 'Código do ativo. Lote cheio e lote fracionário devem ser informados como um ativo único',
      value: '',
    },
    number_of_stocks: {
      label: 'Qnt.',
      name: 'number_of_stocks',
      tooltip: 'Quantidade de ações que a carteira deve gerenciar desse ativo',
      value: '',
    },
  };

  return isExternalOrder
    ? {
      ...basePositionFields,
      price: {
        label: `Preço ${hasOrderType ? 'execução' : 'custo'}`,
        name: 'price',
        tooltip: 'Preço médio do ativo. Usado para gerar o relatório passado da carteira',
        value: '',
      },
      date: {
        label: `Data ${hasOrderType ? 'execução' : 'compra'}`,
        name: 'date',
        tooltip: 'Data em que você comprou o ativo. Usado para gerar o relatório passado da carteira',
        value: '',
      },
    }
    : basePositionFields;
};

export const addDateTimeAndPriceToPositions = ({
  positions, candles, marketOperationPeriod, isHolidayOrWeekendDay,
}) => positions.forEach(
  (position, index) => {
    const { date, time } = getValidDateAndTime(marketOperationPeriod, isHolidayOrWeekendDay);
    position.price = candles[index].close;
    position.date = date;
    position.time = moment(time).format(BR_TIME_FORMAT);
  },
);

export const getWeightListFromPositions = (positions) => {
  return Object.values(positions).map((position) => position.weight);
};
