import { withStyles } from '@material-ui/core/styles';
import PasswordTips from './PasswordTips';

const styles = {
  passwordTip: {
    fontSize: 12,
    color: '#24bc71',
    marginBottom: 10,
    '@media(max-width: 768px)': {
      maxWidth: 300,
      marginBottom: 5,
      paddingLeft: 0,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start',
    },
  },
  valid: {
    color: '#24bc71',
  },
  invalid: {
    color: '#9b9a9f',
  },
};

export default withStyles(styles)(PasswordTips);
