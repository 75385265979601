import { isFollowingSomeInstance } from './instances';
import { IS_ERROR } from './constants';

const errorDialogMessage = `Para cancelar a assinatura da carteira é necessário que você pare de seguir a carteira antes.
<strong>Para isso vá em "Minhas carteiras" > "Mais detalhes" > "Configuração" > "Parar a Carteira".</strong>
<br>Depois de parada, você poderá cancelar sua assinatura. `;

export const CANCELLATION_REASONS = [
  {
    code: 'portfolio_result',
    id: 1,
    description: 'Eu não obtive o resultado que esperava.',
  },
  {
    code: 'platform_experience',
    id: 2,
    description: 'A plataforma não era o que eu esperava.',
  },
  {
    code: 'technical_problems',
    id: 3,
    description: 'Passei por problemas técnicos e/ou não obtive suporte.',
  },
  {
    code: 'other_investments',
    id: 4,
    description: 'Pretendo alocar meu capital em outros investimentos.',
  },
];

export const PLAN_INTERVAL_DESCRIBE = {
  1: 'Mensal',
  6: 'Semestral',
  12: 'Anual',
};

export const STRATEGY_PLAN_REGEX = /marketplace_strategy/;
export const IS_PLAN_CODE_REGEX = /free|lite|pro|expert|advanced|plus/;

export const isStatusActive = (status) => status === 'ACTIVE';

export const planRestriction = (
  plan,
  instanceParams,
  isReal,
) => {
  const {
    marketName,
    stockCode,
    numberOfStocksToTrade,
  } = instanceParams;
  const { limits } = plan.extra_data;
  if (marketName !== 'BMF') return false;

  if ((/^WIN/).test(stockCode) || (/^WDO/).test(stockCode)) {
    if (isReal && numberOfStocksToTrade > limits.constracts.mini) {
      return {
        errorMessage: `Seu plano não permite operar em conta real com ${numberOfStocksToTrade} mini contratos. Para operar com esta quantidade migre para um plano superior`,
      };
    }
    return {
      errorMessage: 'O limite para operar em conta simulada é de 100 mini contratos.',
    };
  }
  if ((/^IND/).test(stockCode) || (/^DOL/).test(stockCode)) {
    if (isReal && numberOfStocksToTrade > limits.constracts.full) {
      return {
        errorMessage: `Seu plano não permite operar em conta real com ${numberOfStocksToTrade} contratos cheios. Para operar com esta quantidade migre para um plano superior`,
      };
    }
    return {
      errorMessage: 'O limite para operar em conta simulada é de 20 mini contratos.',
    };
  }

  return false;
};

export const cancelPlan = async ({
  loadInstances,
  strategyId,
  openProgressDialog,
  redirect,
}) => {
  const { data: { instances } } = await loadInstances(strategyId);

  if (isFollowingSomeInstance(instances)) {
    openProgressDialog({
      state: IS_ERROR,
      title: 'Você está seguindo a carteira',
      message: errorDialogMessage,
      showButton: true,
    });

    return false;
  }

  redirect();
  return true;
};

export default null;
