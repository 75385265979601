import theme from 'common/theme';

export const gridWrapper = {
  display: 'grid',
  gridTemplateColumns: 'repeat(1, minmax(320px, 351px))',
  gridGap: '17px',
  '@media (max-width: 889px)': {
    justifyContent: 'center',
  },

  [`@media (min-width: calc(${theme.breakpoints.values.md}px + 1px))`]: {
    gridTemplateColumns: 'repeat(2, minmax(320px, 351px))',
  },
};

export const card = {
  borderRadius: '8px',
  boxShadow: 'none',
  overflow: 'hidden',
  display: 'flex',
  flex: 1,
  lineHeight: '1em',
};

export const cardContent = {
  borderRadius: '8px',
  border: '1px solid #caccd1',
  borderTopColor: '#333',
  borderTopWidth: '6px',
  display: 'flex',
  flex: 1,
  flexDirection: 'column',
  minHeight: '234px',
  padding: '0',
  position: 'relative',
  '&:last-child': {
    paddingBottom: 0,
  },
};

export const defaultPropsTriangle = {
  borderLeft: '5px solid transparent',
  borderRight: '8px solid transparent',
  borderBottom: '5px solid transparent',
  content: '""',
  display: 'inline-block',
  height: 0,
  position: 'absolute',
  top: -4,
  transform: 'rotate(45deg)',
  width: 0,
};

export const leftTriangle = {
  ...defaultPropsTriangle,
  borderBottomColor: '#333',
  left: -4,
  transform: 'rotate(-45deg)',
};

export const rightTriangle = {
  ...defaultPropsTriangle,
  borderBottomColor: '#333',
  borderLeft: '8px solid transparent',
  borderRight: '5px solid transparent',
  right: -4,
};

export default null;
