import { withStyles } from '@material-ui/core';
import { clearSearch, getStocks } from 'common/ducks/stocks';
import palette from 'common/palette';
import { connect } from 'react-redux';

import SearchStocks from './SearchStocks';

const styles = {
  cardContentItems: {
    '@media (min-width: 1200px)': {
      marginBottom: 20,
      paddingRight: 15,
    },
    marginBottom: 15,
  },
  cardContentTemplate: {
    paddingRight: 0,
  },
  limitItemsHeight: {
    '@media (min-width: 1200px)': {
      maxHeight: 565,
      overflow: 'hidden',
    },
  },
  emptyStocks: {
    display: 'flex',
    flexDirection: 'column',
    height: 150,
    justifyContent: 'space-between',
    paddingTop: 15,
    textAlign: 'center',
  },
  emptyStocksSearch: {
    justifyContent: 'flex-end',
  },
  justifyCenter: {
    justifyContent: 'center',
  },
  iconSearch: {
    marginBottom: 10,
  },
  stocksHeader: {
    borderBottom: `1px solid ${palette.primary.main}`,
    display: 'flex',
    justifyContent: 'space-between',
    paddingBottom: 5,
  },
  backButton: {
    color: palette.textSecondary.dark,
    paddingBottom: 5,
    paddingTop: 5,
  },
  searchBar: {
    marginBottom: 5,
    color: palette.textSecondary.main,
    fontSize: 16,
  },
  noResults: {
    width: '100%',
    paddingTop: 15,
    display: 'flex',
    justifyContent: 'center',
  },
  stockCards: {
    margin: '8px auto',
  },
};

const mapStateToProps = ({
  stocks,
  instances,
}, { instanceId }) => ({
  stocks,
  instance: instances.data[instanceId],
});

const mapDispatchToProps = (dispatch) => ({
  getStocks: (searchTerm, limitStocks) => dispatch(getStocks(searchTerm, limitStocks)),
  clearSearch: () => dispatch(clearSearch()),
});

export default connect(mapStateToProps, mapDispatchToProps)(
  withStyles(styles)(SearchStocks),
);
