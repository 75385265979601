import * as Yup from 'yup';
import { CPF } from 'cpf_cnpj';
import {
  SPECIAL_CHARACTER_ERROR,
  SPECIAL_CHARACTER_TEXT,
  INVALID_PASSWORD_TEXT,
  REQUIRED_FIELD_TEXT,
  INVALID_CPF_TEXT,
  PASSWORD_MIN_SIX_CHAR,
} from './constants';
import { validateSpecialCharacterPassword } from './validation';

Yup.addMethod(Yup.string, 'validateCPF', function testCpf(message) {
  return this.test('validateCPF', message, function cpfValidation(value) {
    const { path, createError } = this;
    return CPF.isValid(value) || createError({ path, message });
  });
});

const getPasswordYupValidation = () => {
  return Yup.string()
    .min(6, PASSWORD_MIN_SIX_CHAR)
    .matches(/^(.*[0-9].*)+$/, INVALID_PASSWORD_TEXT)
    .matches(/^(.*[a-z].*)+$/, INVALID_PASSWORD_TEXT)
    .matches(/^(.*[A-Z].*)+$/, INVALID_PASSWORD_TEXT)
    .test(
      SPECIAL_CHARACTER_TEXT,
      SPECIAL_CHARACTER_ERROR,
      validateSpecialCharacterPassword,
    )
    .required(REQUIRED_FIELD_TEXT);
};

const getEmailYupValidation = () => {
  return Yup.string()
    .email('E-mail inválido')
    .matches(/^(?!.*(?:á|à|â|ã|é|è|ê|í|ó|ô|õ|ö|ú|ç|ñ|Á|À|Â|Ã|É|È|Í|Ó|Ô|Õ|Ö|Ú|Ç|Ñ))/, 'E-mail inválido')
    .required(REQUIRED_FIELD_TEXT);
};

export const LoginSchema = Yup.object().shape({
  login: Yup.string().required(REQUIRED_FIELD_TEXT),
  password: Yup.string().required(REQUIRED_FIELD_TEXT),
});

export const RecoverPasswordSchema = Yup.object().shape({
  email: getEmailYupValidation(),
});

export const RedefinePasswordSchema = Yup.object().shape({
  password: getPasswordYupValidation(),
});

export const SignUpSchema = Yup.object().shape({
  name: Yup.string().trim().matches(
    /^[a-zA-ZáàâãéèêíóôõöúçñÁÀÂÃÉÈÍÓÔÕÖÚÇÑ]{2,}((?: [a-zA-ZáàâãéèêíóôõöúçñÁÀÂÃÉÈÍÓÔÕÖÚÇÑ]{2,})+)$/,
    'Nome inválido',
  )
    .required(REQUIRED_FIELD_TEXT),
  cpf: Yup.string()
    .length(14, INVALID_CPF_TEXT)
    .validateCPF(INVALID_CPF_TEXT)
    .required(REQUIRED_FIELD_TEXT),
  email: getEmailYupValidation(),
  password: getPasswordYupValidation(),
  termsOfUseCheckbox: Yup.bool().oneOf([true]),
  privacyPolicyCheckbox: Yup.bool().oneOf([true]),
});
