import { withStyles } from '@material-ui/styles';
import CancelOrderDialog from './CancelOrderDialog';

const styles = {
  title: {
    fontSize: 22,
    fontWeight: 700,
    paddingBottom: 0,
  },
  dialogContent: {
    paddingTop: 15,
    paddingBottom: 0,
  },
  textContent: {
    marginBottom: 5,
  },
};

export const CANCEL_ORDER_DIALOG_CODE = 'CANCEL_ORDER_DIALOG_CODE';
export default withStyles(styles)(CancelOrderDialog);
