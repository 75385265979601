import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { addDefaultLogo } from 'common/utils/functions';
import { getPositionCompany } from 'common/utils/positions';
import { getPublicImage } from 'common/utils/string';
import { COMPANY_IMAGES_PATH } from 'common/utils/constants';

const StockLogoFinder = ({
  classes,
  className,
  stock,
  position,
}) => {
  const { logo } = position ?? getPositionCompany(stock.code || stock.stock_code);
  const { company_name } = position ?? stock;

  const companyPathLogo = getPublicImage(logo, COMPANY_IMAGES_PATH);
  return (
    <img
      id="company-logo"
      className={classNames(
        classes.logo,
        className,
      )}
      onError={(e) => addDefaultLogo(e, `/${COMPANY_IMAGES_PATH}/DEFAULT.svg`)}
      src={companyPathLogo}
      alt={company_name}
    />
  );
};

StockLogoFinder.propTypes = {
  classes: PropTypes.object.isRequired,
  stock: PropTypes.object,
  className: PropTypes.string,
  position: PropTypes.object,
};

StockLogoFinder.defaultProps = {
  stock: null,
  className: '',
  position: null,
};

export default StockLogoFinder;
