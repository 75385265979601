import { withStyles } from '@material-ui/styles';
import withMobileDialog from '@material-ui/core/withMobileDialog';
import palette from 'common/palette';
import AnswerResearchDialog from './AnswerResearchDialog';

export const ANSWER_RESEARCH_DIALOG_CODE = 'ANSWER_RESEARCH_DIALOG_CODE';

const styles = {
  title: {
    maxWidth: 275,
    margin: '0 auto 12px auto',
  },
  button: {
    borderRadius: '31px',
    backgroundImage: 'linear-gradient(97deg, #0fbc9f 9%, #24bc71 110%)',
    fontSize: '23px',
    fontWeight: 500,
    height: '52px',
    width: '299.7px',
  },
  dialogContent: {
    maxWidth: 510,
    padding: '50px 30px',
    textAlign: 'center',
  },
  contentText: {
    marginBottom: 12,
    color: palette.textSecondary.main,
    fontSize: 18,
  },
};

export default withStyles(styles)(withMobileDialog()(AnswerResearchDialog));
