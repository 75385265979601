import {
  card,
  cardContent,
  leftTriangle,
  rightTriangle,
} from 'common/utils/styles/instancesStyles';
import palette from 'common/palette';
import { withStyles } from '@material-ui/styles';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { openDialog } from 'common/ducks/dialogs';
import { INITIAL_REPORT_STATE } from 'common/ducks/report';
import { PROGRESS_DIALOG_CODE } from '../../ProgressDialog';
import PlansCards from './PlansCards';

const styles = {
  gridWrapper: {
    display: 'grid',
    gridTemplateColumns: 'repeat(auto-fit, minmax(min(100%, 175px), 200px))',
    gap: '1rem',
    justifyContent: 'center',
  },
  card: {
    ...card,
  },
  content: {
    flex: 1,
    padding: '10px 20px 12px 20px',
    position: 'relative',
    overflow: 'hidden',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  cardContent: {
    ...cardContent,
    borderTopColor: palette.primary.main,
    minHeight: 204,
  },
  leftTriangle: {
    ...leftTriangle,
    borderBottomColor: palette.primary.main,
  },
  rightTriangle: {
    ...rightTriangle,
    borderBottomColor: palette.primary.main,
  },
  header: {
    fontSize: 16,
    fontWeight: 'bold',
    textAlign: 'center',
    color: palette.textSecondary.dark,
  },
  title: {
    fontSize: 14,
    fontWeight: 'bold',
    display: 'flex',
    justifyContent: 'center',
    paddingBottom: 5,
    color: palette.primary.main,
  },
  limit: {
    fontWeight: 'bold',
    display: 'flex',
    justifyContent: 'center',
    color: palette.primary.main,
  },
  divider: {
    marginBlock: 13,
    borderBottom: 'solid 1px rgba(0, 0, 0, 0.16)',
    width: '100%',
    height: 1,
  },
  monthlyValue: {
    textAlign: 'center',
  },
  value: {
    color: palette.textSecondary.dark,
    fontWeight: 'bold',
  },
  valueText: {
    fontSize: 14,
    fontWeight: 500,
    color: palette.textSecondary.dark,
  },
  button: {
    display: 'flex',
    justifyContent: 'center',
  },
};

const mapStateToProps = ({ auth: { logged }, report }, { instanceId }) => ({
  logged,
  instanceReport: report[instanceId] || INITIAL_REPORT_STATE,
});

const mapDispatchToProps = (dispatch) => ({
  openProgressDialog: (props) => dispatch(openDialog(PROGRESS_DIALOG_CODE, { ...props })),
});

export default connect(mapStateToProps, mapDispatchToProps)(
  withRouter(withStyles(styles)(PlansCards)),
);
