import { withStyles } from '@material-ui/core/styles';

import Container from './Container';

const styles = (theme) => ({
  container: {
    maxWidth: '100%',
    margin: '0 auto',
    paddingLeft: 16,
    paddingRight: 16,
    width: '100%',
    [`@media (min-width: ${theme.breakpoints.values.sm}px)`]: {
      width: '100%',
    },
    [`@media (min-width: ${theme.breakpoints.values.md}px)`]: {
      width: '90%',
    },
  },
  containerFluid: {
    maxWidth: '100%',
    margin: '0 auto',
    paddingLeft: 16,
    paddingRight: 16,
  },
});

export default withStyles(styles)(Container);
