import { withStyles } from '@material-ui/styles';
import BenchmarkLegends from './BenchmarkLegends';

const styles = {
  legend: {
    '&:not(:last-child)': {
      marginRight: 30,
    },
    marginBottom: 15,
    borderLeft: '4px solid',
    paddingLeft: 5,
    cursor: 'pointer',
  },
  inactive: {
    color: '#b7b7b7',
  },
};

export default withStyles(styles)(BenchmarkLegends);
