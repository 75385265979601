import React from 'react';
import PropTypes from 'prop-types';

import PersonIcon from '@material-ui/icons/Person';
import LogoutIcon from '@material-ui/icons/MeetingRoom';
import ClipboadCheckIcon from '@material-ui/icons/AssignmentTurnedIn';
import QuestionCircleIcon from '@material-ui/icons/Help';
import Divider from '@material-ui/core/Divider';

import MenuItem from '@material-ui/core/MenuItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';

import {
  PROFILE_URL,
  LOGIN_URL,
  SI_ZENDESK_CATEGORY,
  TERMS_OF_USE,
  PRIVACY_POLICY,
} from 'common/utils/constants';

const UserActionsList = ({
  history,
  logout,
  classes,
  disableActions,
  handleMenu,
  latestTermUrl,
  displayMyAccountOption,
  displayOnlyLogout,
  route,
}) => {
  const onLogoutUser = () => {
    history.replace(LOGIN_URL);
    if (!route.isPreventChangeRoute) {
      logout();
      handleMenu({ currentTarget: null });
    }
  };

  const redirectToProfileUrl = () => {
    handleMenu({ currentTarget: null });
    history.replace(PROFILE_URL);
  };

  return (
    <>
      {!disableActions
        ? (
          <>
            {displayMyAccountOption && (
              <MenuItem
                classes={{
                  root: classes.menuItem,
                }}
                id="user-actions-list-my_account"
                onClick={redirectToProfileUrl}
              >
                <ListItemIcon
                  classes={{
                    root: classes.listItemIcon,
                  }}
                >
                  <PersonIcon className={classes.icon} />
                </ListItemIcon>
                <ListItemText
                  classes={{
                    root: classes.listItemPadding,
                    primary: classes.listItemText,
                  }}
                  inset
                  primary="Minha Conta"
                />
              </MenuItem>
            )}
            {!displayOnlyLogout && (
              <>
                <MenuItem
                  classes={{
                    root: classes.menuItem,
                  }}
                  id="user-actions-list-terms"
                  component="a"
                  href={latestTermUrl}
                  target="_blank" // eslint-disable-line react/jsx-no-target-blank
                  onClick={() => handleMenu({ currentTarget: null })}
                >
                  <ListItemIcon
                    classes={{
                      root: classes.listItemIcon,
                    }}
                  >
                    <ClipboadCheckIcon className={classes.icon} />
                  </ListItemIcon>
                  <ListItemText
                    classes={{
                      root: classes.listItemPadding,
                      primary: classes.listItemText,
                    }}
                    inset
                    primary="Termos de Uso"
                  />
                </MenuItem>
                <MenuItem
                  classes={{
                    root: classes.menuItem,
                  }}
                  id="user-actions-list-terms"
                  component="a"
                  href={PRIVACY_POLICY}
                  target="_blank" // eslint-disable-line react/jsx-no-target-blank
                  onClick={() => handleMenu({ currentTarget: null })}
                >
                  <ListItemIcon
                    classes={{
                      root: classes.listItemIcon,
                    }}
                  >
                    <ClipboadCheckIcon className={classes.icon} />
                  </ListItemIcon>
                  <ListItemText
                    classes={{
                      root: classes.listItemPadding,
                      primary: classes.listItemText,
                    }}
                    inset
                    primary="Política de Privacidade"
                  />
                </MenuItem>
                <MenuItem
                  classes={{
                    root: classes.menuItem,
                  }}
                  id="user-actions-list-question"
                  component="a"
                  target="_blank"
                  href={SI_ZENDESK_CATEGORY}
                  onClick={() => handleMenu({ currentTarget: null })}
                >
                  <ListItemIcon
                    classes={{
                      root: classes.listItemIcon,
                    }}
                  >
                    <QuestionCircleIcon className={classes.icon} />
                  </ListItemIcon>
                  <ListItemText
                    classes={{
                      root: classes.listItemPadding,
                      primary: classes.listItemText,
                    }}
                    inset
                    primary="Ajuda"
                  />
                </MenuItem>
              </>
            )}
          </>
        ) : null}
      <Divider />
      <MenuItem
        classes={{
          root: classes.menuItem,
        }}
        id="user-actions-list-logout"
        onClick={onLogoutUser}
      >
        <ListItemIcon
          classes={{
            root: classes.listItemIcon,
          }}
        >
          <LogoutIcon color="error" className={classes.icon} />
        </ListItemIcon>
        <ListItemText
          classes={{
            root: classes.listItemPadding,
            primary: classes.listItemText,
          }}
          inset
          primary="Sair"
        />
      </MenuItem>
    </>
  );
};

UserActionsList.propTypes = {
  disableActions: PropTypes.bool,
  classes: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  logout: PropTypes.func.isRequired,
  handleMenu: PropTypes.func,
  displayMyAccountOption: PropTypes.bool,
  displayOnlyLogout: PropTypes.bool,
  latestTermUrl: PropTypes.string,
  route: PropTypes.shape({
    isPreventChangeRoute: PropTypes.bool,
  }).isRequired,
};

UserActionsList.defaultProps = {
  latestTermUrl: TERMS_OF_USE,
  displayMyAccountOption: true,
  disableActions: false,
  displayOnlyLogout: false,
  handleMenu: () => null,
};

export default UserActionsList;
