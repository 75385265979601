import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Box, Typography, Button } from '@material-ui/core';

import warnIcon from 'common/assets/images/icon-open-positioned.svg';
import { SI_ZENDESK_ARTICLES_URL } from 'common/utils/constants';
import {
  PARAMS_MOUNT_INSTANCE,
  NOT_REBALANCE_TEXT,
} from 'common/utils/constants/instance';
import { getSetupByCode } from 'common/utils/setup';
import { getDefaultOperationDate, getMountingNote } from 'common/utils/storeItem';

import DisplayNumber from 'common/components/DisplayNumber';
import ConfirmValues from 'common/components/ConfirmValues';
import ChipWithTypography from 'common/components/ChipWithTypography';
import Link from 'common/components/Link';
import GrayBox from 'common/components/SideDialogs/InvestorProfile/GrayBox';

const ConfirmFollowInstance = ({
  classes,
  dataSteps,
  brokerages,
  onSetStep,
  setups,
  setupCode,
  isWhiteBox,
  isHolidayOrWeekendDay,
  storeInstance,
  startFromZero,
  showWarnMessage,
  setShowWarnMessage,
}) => {
  const selectedSetup = getSetupByCode({ code: setupCode, setups });
  const {
    data: {
      name,
      equity,
      initial_capital: initialCapital,
      brokerage: brokerageId,
      selectedDateOption,
      rebalancePortfolio,
    },
  } = dataSteps;

  const isNotRebalance = !startFromZero && !rebalancePortfolio && isWhiteBox;

  const mountingDate = isNotRebalance
    ? NOT_REBALANCE_TEXT
    : getDefaultOperationDate({
      optionNowSelected:
          selectedDateOption === PARAMS_MOUNT_INSTANCE.mountNow.value,
      storeInstance,
      isHolidayOrWeekendDay,
    });

  const mountingDateComponent = (
    <div className={classes.wrapperDate}>
      <ChipWithTypography text={mountingDate} />
    </div>
  );

  const instanceValues = {
    name: {
      id: 'instance-name',
      label: 'Nome da carteira:',
      value: isWhiteBox ? name : storeInstance.name,
    },
    brokerage: {
      id: 'brokerage-name',
      label: 'Corretora:',
      value: brokerages[brokerageId].name,
    },
    setup: {
      id: 'setup-name',
      label: 'Custos operacionais:',
      value: selectedSetup.description,
    },
    initialCapital: {
      id: 'initial-capital',
      label: 'Valor investido:',
      value: (
        <DisplayNumber
          color="textPrimaryBold"
          colorful={false}
          currency
          scale={1.25}
          value={
            startFromZero
              ? parseFloat(initialCapital)
              : parseFloat(equity?.totalEquityContribution)
          }
        />
      ),
    },
    mountingDate: {
      id: 'mounting-date',
      label: `Data da ${startFromZero ? 'montagem' : 'rolagem'}:`,
      value: mountingDateComponent,
    },
  };

  const buttonColor = 'primary';

  const mountingNote = getMountingNote(mountingDate);

  return (
    <>
      {showWarnMessage && (
        <Box>
          <Typography className={classNames(classes.dialogTitle, classes.bold)}>
            Seguir uma carteira
          </Typography>
          <Box display="flex" alignItems="center" mt={2} mb={1}>
            <img src={warnIcon} alt="warn-icon" />
            <Typography
              className={classNames(classes.dialogSubtitle, classes.bold)}
            >
              Custo operacional muito alto
            </Typography>
          </Box>
          <Typography variant="h5" className={classes.dialogText}>
            A relação atual entre custo operacional, valor investido e o volume
            de ordens da carteira pode comprometer o resultado do investimento.
          </Typography>
          <Typography variant="h5" className={classes.dialogText}>
            Busque reduzir o custo operacional, aumentar o valor investido ou
            trocar por uma carteira com um menor volume de ordens.&nbsp;
            <Link
              color="primary"
              fontWeight={700}
              fontSize={16}
              path={`${SI_ZENDESK_ARTICLES_URL}/1500000903201`}
              target="_blank"
            >
              Saiba mais aqui.
            </Link>
          </Typography>
          <Typography variant="h5" className={classes.dialogText}>
            Caso queira, você também pode prosseguir e montar a carteira mesmo
            assim.
          </Typography>
          <Box mt={3} className={classes.buttonBox}>
            <Button
              color={buttonColor}
              variant="outlined"
              onClick={() => setShowWarnMessage(false)}
            >
              Continuar mesmo assim
            </Button>
            <Button
              color={buttonColor}
              variant="contained"
              onClick={() => {
                setShowWarnMessage(false);
                onSetStep(2);
              }}
            >
              Revisar
            </Button>
          </Box>
        </Box>
      )}
      {!showWarnMessage && (
        <>
          <ConfirmValues values={instanceValues} />
          <Typography variant="h5" color="textPrimary">
            <GrayBox fontSize={14}>{mountingNote}</GrayBox>
          </Typography>
        </>
      )}
    </>
  );
};

ConfirmFollowInstance.propTypes = {
  classes: PropTypes.object.isRequired,
  storeInstance: PropTypes.object,
  dataSteps: PropTypes.object.isRequired,
  brokerages: PropTypes.object.isRequired,
  onSetStep: PropTypes.func.isRequired,
  setups: PropTypes.array.isRequired,
  setupCode: PropTypes.string.isRequired,
  startFromZero: PropTypes.bool.isRequired,
  isWhiteBox: PropTypes.bool,
  isHolidayOrWeekendDay: PropTypes.func.isRequired,
  showWarnMessage: PropTypes.bool.isRequired,
  setShowWarnMessage: PropTypes.func.isRequired,
};

ConfirmFollowInstance.defaultProps = {
  storeInstance: {},
  isWhiteBox: false,
};

export default ConfirmFollowInstance;
