import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import { ThemeProvider, createGenerateClassName, jssPreset } from '@material-ui/styles';

import theme from 'common/theme';

import { JssProvider } from 'react-jss';
import { create } from 'jss';
import jssExtend from 'jss-plugin-extend';
import Pages from './Pages';

const jss = create({ plugins: [jssExtend(), ...jssPreset().plugins] });
const generateClassName = createGenerateClassName();

const App = () => (
  <JssProvider jss={jss} generateClassName={generateClassName}>
    <ThemeProvider theme={theme}>
      <BrowserRouter>
        <Pages />
      </BrowserRouter>
    </ThemeProvider>
  </JssProvider>
);

export default App;
