import { connect } from 'react-redux';
import get from 'lodash/get';
import { withStyles } from '@material-ui/core/styles';
import { withMobileDialog } from '@material-ui/core';

import { stopInstance } from 'common/ducks/instance';
import { eliminateInstancePositions } from 'common/ducks/positions';
import { PROGRESS_DIALOG_CODE } from 'common/components/Dialogs/ProgressDialog';
import { openDialog } from 'common/ducks/dialogs';

import palette from 'common/palette';
import SellAllDialog, { SELL_ALL_DIALOG_CODE } from './SellAllDialog';
import { PASSWORD_CONFIRM_DIALOG_CODE } from '../../PasswordConfirmDialog';

const styles = (theme) => ({
  [theme.breakpoints.down('sm')]: {
    buttons: {
      justifyContent: 'center',
    },
  },
  title: {
    color: palette.textPrimary.main,
    marginBottom: 10,
    fontWeight: 'bold',
    fontSize: 22,
  },
  text: {
    color: palette.textSecondary.main,
    fontSize: theme.typography.h6.fontSize,
    paddingTop: 10,
  },
  list: {
    paddingLeft: 10,
  },
  buttons: {
    padding: '15px 10px 0 0',
  },
  closeIcon: {
    position: 'absolute',
    right: '6px',
    top: '5px',
    color: 'black',
  },
});

const mapStatetoProps = ({ instances, user }, { instanceId }) => ({
  instance: instances.data[instanceId] || {},
  preferences: get(user.profile, 'data.preferences', {}),
});

const mapDispatchToProps = (dispatch, { instanceId, history }) => ({
  eliminateInstancePositions: (id, data) => dispatch(eliminateInstancePositions(id, data)),
  stopInstance: (options, data, config) => dispatch(
    stopInstance(instanceId, history, data, options, config),
  ),
  openPasswordConfirmDialog: (props) => dispatch(
    openDialog(PASSWORD_CONFIRM_DIALOG_CODE, { ...props }),
  ),
  openProgressDialog: (props) => dispatch(openDialog(PROGRESS_DIALOG_CODE, { ...props })),
});

export { SELL_ALL_DIALOG_CODE };
export default connect(mapStatetoProps, mapDispatchToProps)(
  withStyles(styles)(withMobileDialog()(SellAllDialog)),
);
