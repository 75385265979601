import React from 'react';
import PropTypes from 'prop-types';
import Card from '@material-ui/core/Card';

import { chartDateFormatter } from 'common/utils/functions';
import { getPerformanceChartZoneLabel } from 'common/utils/chart';
import DisplayNumber from 'common/components/DisplayNumber';

const FORMAT = 'LL';

const ChartTooltip = ({
  payload,
  classes,
  label,
  active,
  details,
  isStoreInstance,
  launchDate,
  group,
}) => {
  if (active) {
    const {
      symbol,
      symbolLocation,
      colorful,
      extraData,
    } = details;

    return (
      <Card className={classes.tooltip}>
        <div className={classes.label}>
          {isStoreInstance
            ? `${chartDateFormatter(label, FORMAT)}
              • ${getPerformanceChartZoneLabel({
              date: payload[0].payload.date,
              launchDate,
              group,
            })}`
            : chartDateFormatter(label, FORMAT)}
        </div>
        {payload.map((item) => (
          <div style={{ borderColor: item.color }} className={classes.content} key={item.name}>
            <div className={classes.key}>{item.name}</div>
            <DisplayNumber
              value={item.value}
              scale={0.8}
              symbol={symbol}
              symbolLocation={symbolLocation}
              colorful={colorful}
            />
          </div>
        ))}

        {extraData && (
          <div className={classes.extraData}>
            <div className={classes.key}>{extraData.key}</div>
            <DisplayNumber
              value={extraData.value(payload)}
              scale={0.8}
              symbol={extraData.symbol}
              symbolLocation={extraData.symbolLocation}
              colorful={extraData.colorful}
            />
          </div>
        )}
      </Card>
    );
  }
  return null;
};

ChartTooltip.propTypes = {
  payload: PropTypes.array.isRequired,
  classes: PropTypes.object.isRequired,
  label: PropTypes.string,
  active: PropTypes.bool.isRequired,
  details: PropTypes.object.isRequired,
  extraData: PropTypes.object,
  isStoreInstance: PropTypes.bool,
  launchDate: PropTypes.string,
  group: PropTypes.string,
};

ChartTooltip.defaultProps = {
  label: '',
  extraData: null,
  isStoreInstance: false,
  launchDate: null,
  group: null,
};

export default ChartTooltip;
