import { warning as notifyWarning } from 'react-notification-system-redux';
import { removeCheckedStocks, isStockInWallet } from 'common/utils/whitebox';
import { CODE_PARAM } from 'common/utils/constants/whitebox';
import { STOCK_MODES } from 'common/utils/constants';
import { getTotalItems, reduceObjectsArrayToObject } from 'common/utils/array';
import { LOGOUT_SUCCEED } from './auth';
import { clearSearch } from './stocks';

export const DUCK_NAME = 'whitebox';

export const INITIAL_STATE = {
  mode: STOCK_MODES.UPDATE,
  totalWeight: 0,
  stocks: {},
  cachedStocks: {},
};

export const SET_MODE = `${DUCK_NAME}/SET_MODE`;

export const setMode = (mode) => ({
  type: SET_MODE,
  mode,
});

export const SET_COMMIT_CHANGE = `${DUCK_NAME}/SET_COMMIT_CHANGE`;

export const setCommitChange = (commited) => ({
  type: SET_COMMIT_CHANGE,
  commited,
});

export const CLEAR_WHITEBOX_STOCKS = `${DUCK_NAME}/CLEAR_WHITEBOX_STOCKS`;

export const clearWhiteboxStocks = () => ({
  type: CLEAR_WHITEBOX_STOCKS,
});

export const LOAD_STOCKS = `${DUCK_NAME}/LOAD_STOCKS`;

export const loadStocks = (stocks, commited) => ({
  type: LOAD_STOCKS,
  stocks,
  commited,
});

export const ADD_STOCK = `${DUCK_NAME}/ADD_STOCK`;

export const addStock = (stocksList, stock) => (
  dispatch, getState,
) => {
  const whitebox = getState()[DUCK_NAME];
  const hasStocks = Object.keys(whitebox.stocks).length > 0;

  if (isStockInWallet(Object.values(stocksList), stock)) {
    dispatch(notifyWarning({
      title: 'Esse ativo já foi adicionado à sua carteira',
    }));
    return false;
  }

  const newStock = {
    ...stock,
    weight: 0,
    minWeight: 10,
    maxWeight: 40,
    checked: false,
  };

  const updateList = { ...stocksList, [newStock.code]: { ...newStock } };

  dispatch(setMode(STOCK_MODES.UPDATE));
  dispatch(clearSearch());

  return dispatch(loadStocks(updateList, !hasStocks));
};

export const REMOVE_STOCK = `${DUCK_NAME}/REMOVE_STOCK`;

export const removeStocks = (stocks, checkAll) => (dispatch) => {
  const updatedStockList = reduceObjectsArrayToObject(
    removeCheckedStocks(stocks, checkAll),
    CODE_PARAM,
  );

  return dispatch(loadStocks(updatedStockList, true));
};

export default (state = INITIAL_STATE, action) => {
  if (action.type === LOGOUT_SUCCEED) {
    return INITIAL_STATE;
  }
  switch (action.type) {
    case SET_MODE:
      return {
        ...state,
        commited: false,
        mode: action.mode,
      };
    case LOAD_STOCKS:
      return {
        ...state,
        totalWeight: getTotalItems({ items: Object.values(action.stocks), value: 'weight' }),
        stocks: action.stocks,
        cachedStocks: {
          ...state.cachedStocks,
          ...state.stocks,
          ...action.stocks,
        },
        commited: action.commited,
      };
    case CLEAR_WHITEBOX_STOCKS:
      return INITIAL_STATE;
    case SET_COMMIT_CHANGE:
      return {
        ...state,
        commited: action.commited,
      };
    default:
      return state;
  }
};
