import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { NavLink } from 'react-router-dom';

import {
  Avatar,
  Divider,
  Icon,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from '@material-ui/core';

import logo from 'common/assets/images/logo.svg';
import miniLogo from 'common/assets/images/mini-logo.svg';
import userBgIcon from 'common/assets/images/user-bg-icon.svg';
import UserActionsList from 'common/components/UserMenu/UserActionsList';
import UserMenu from 'common/components/UserMenu';
import UserMenuItem from 'common/components/UserMenu/UserMenuItem';
import { useStyles } from '.';

const Sidebar = ({
  dashboardState,
  setDashboardState,
  pageItems,
  isMobile,
  user,
}) => {
  const classes = useStyles();
  const [displayUserMenu, setDisplayUserMenu] = useState(null);

  const onToggleUserMenuClick = (event) => setDisplayUserMenu(event.currentTarget);

  const renderUserMenu = () => {
    return (
      <>
        <label htmlFor="dropdown-menu-toggle" className={classes.userMenuText}>
          <IconButton
            aria-owns={displayUserMenu ? 'dropdown-menu' : ''}
            aria-haspopup="true"
            id="dropdown-menu-toggle"
            aria-label="User"
            onClick={onToggleUserMenuClick}
            className={classes.userMenuIcon}
          >
            <Avatar
              alt="Meu Perfil"
              src={userBgIcon}
              className={classes.avatarImage}
            />
          </IconButton>
          <UserMenu
            menuAnchorEl={displayUserMenu}
            handleMenu={onToggleUserMenuClick}
          />
          {dashboardState && user.name_or_corporate_name}
        </label>
      </>
    );
  };

  return (
    <aside
      className={classNames(
        classes.dashboardSide,
        dashboardState && classes.open,
      )}
      onMouseOver={() => setDashboardState(true)}
      onMouseLeave={() => setDashboardState(false)}
      onFocus={() => {}}
    >
      <div className={classes.dashboardLogo}>
        {!dashboardState && (
          <img src={dashboardState ? logo : miniLogo} alt="" />
        )}
        {dashboardState && (
          <img
            className={classes.logo}
            src={dashboardState ? logo : miniLogo}
            alt=""
          />
        )}
      </div>
      {isMobile && (
        <>
          <UserMenuItem showIcon handleMenu={setDashboardState} />
          <Divider />
        </>
      )}
      <List
        className={classes.menuListItem}
        component="nav"
        aria-label="main mailbox folders"
      >
        {pageItems.map((page) => {
          return (
            page.isVisible && (
              <NavLink
                className={classes.itemLink}
                activeClassName={classes.itemLinkActive}
                to={page.href}
                key={page.name}
                onClick={() => {
                  if (isMobile) setDashboardState(false);
                }}
              >
                <ListItem button>
                  <ListItemIcon className={classes.listItemIcon}>
                    <Icon className={classes.menuIcon}>{page.icon}</Icon>
                  </ListItemIcon>
                  <span className={classes.dashboardListItem}>
                    <ListItemText
                      className={classNames(
                        classes.listItemText,
                        dashboardState && classes.listitemTextActive,
                      )}
                      primary={page.text}
                    />
                  </span>
                </ListItem>
              </NavLink>
            )
          );
        })}
      </List>
      {isMobile && (
        <>
          <Divider />
          <UserActionsList
            displayMyAccountOption={false}
            handleMenu={setDashboardState}
          />
        </>
      )}
      {!isMobile && <div className={classes.userMenu}>{renderUserMenu()}</div>}
    </aside>
  );
};

Sidebar.propTypes = {
  dashboardState: PropTypes.bool.isRequired,
  setDashboardState: PropTypes.func.isRequired,
  pageItems: PropTypes.array.isRequired,
  isMobile: PropTypes.bool.isRequired,
  user: PropTypes.object.isRequired,
};

export default Sidebar;
