import get from 'lodash/get';
import { DESC } from './constants';

export const reduceObjectsArrayToObject = (array, key) => array.reduce((object, current) => ({
  ...object,
  [get(current, key)]: current,
}), {});

export default null;

export const orderByString = (array, value) => {
  return array.sort((a, b) => a[value]?.localeCompare(b[value]));
};

export const sortByNumber = (key, order = 'asc') => (a, b) => {
  const number1 = Number(a[key]);
  const number2 = Number(b[key]);

  const comparison = number1 > number2 ? 1 : -1;

  return order === 'desc' ? comparison * -1 : comparison;
};

export const descendingComparator = (a, b, orderBy) => {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
};

export const getComparator = (order, orderBy) => {
  return order === DESC
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
};

export const stableSort = (array, comparator) => {
  const stabilizedThis = array.map((el, index) => [el, index]);

  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });

  return stabilizedThis.map((el) => el[0]);
};

export const getTotalItems = ({ items, value = 0 }) => {
  const isValueNumber = typeof value === 'number';

  return items.reduce((acc, current) => {
    const currentValue = isValueNumber ? current : current[value];

    return acc + currentValue;
  }, 0);
};

export const getMinValue = ({ items, value, initialValue = false }) => {
  return items.reduce((min, current) => (
    current[value] < min ? current[value] : min), initialValue ?? items[0][value]);
};

export const getMaxValue = ({ items, value, initialValue }) => {
  return items.reduce((max, current) => (
    current[value] > max ? current[value] : max), initialValue ?? items[0][value]);
};

export const getUniqueItemsArray = (array) => Array.from(new Set(array));

export const getArraysIntersection = (array1, array2) => {
  const setObj = new Set(array1);
  return array2.filter((item) => setObj.has(item));
};
