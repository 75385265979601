import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Route, Switch } from 'react-router-dom';

import LoadingGif from 'common/components/LoadingGif';
import NavigationPages from './NavigationPages';

const PrivatePages = ({
  match: { url },
  getHolidays,
  getUserStats,
  loadBrokerages,
  loadMarketOperationPeriod,
  loadPlans,
  loadStoreItems,
  loadStrategies,
  loadUserSetups,
  loadUserProfile,
  loadUserSubscriptions,
  validateUserRegistration,
}) => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const load = async () => {
      getUserStats();
      loadPlans();
      loadUserSubscriptions();
      loadStoreItems();
      loadUserSetups();
      getHolidays();
      loadMarketOperationPeriod();
      await loadStrategies();
      const profile = await loadUserProfile();
      const brokerages = await loadBrokerages();

      const params = {
        profile,
        brokerages: brokerages.data.brokerages || brokerages.data,
      };

      validateUserRegistration(params);

      setLoading(false);
    };

    load();
  }, [
    getHolidays,
    getUserStats,
    loadBrokerages,
    loadMarketOperationPeriod,
    loadPlans,
    loadStoreItems,
    loadStrategies,
    loadUserProfile,
    loadUserSetups,
    loadUserSubscriptions,
    validateUserRegistration,
  ]);

  return loading
    ? <LoadingGif />
    : (
      <Switch>
        <Route
          path={url}
          render={() => <NavigationPages />}
        />
      </Switch>
    );
};

PrivatePages.propTypes = {
  getHolidays: PropTypes.func.isRequired,
  getUserStats: PropTypes.func.isRequired,
  loadBrokerages: PropTypes.func.isRequired,
  loadMarketOperationPeriod: PropTypes.func.isRequired,
  loadPlans: PropTypes.func.isRequired,
  loadStoreItems: PropTypes.func.isRequired,
  loadStrategies: PropTypes.func.isRequired,
  loadUserProfile: PropTypes.func.isRequired,
  loadUserSetups: PropTypes.func.isRequired,
  loadUserSubscriptions: PropTypes.func.isRequired,
  match: PropTypes.shape({ url: PropTypes.string }).isRequired,
  validateUserRegistration: PropTypes.func.isRequired,
};

export default PrivatePages;
