export const setBackgroundOpacity = ({ backgroundColor = 'rgba(0, 0, 0, 0.56)', zIndex = 2 }) => ({
  backgroundColor,
  content: '""',
  display: 'block',
  height: '100%',
  left: 0,
  position: 'absolute',
  top: 0,
  width: '100%',
  zIndex,
});

export default null;
