import React from 'react';
import PropTypes from 'prop-types';
import { Typography } from '@material-ui/core';

const Disclaimer = ({ text, classes }) => (
  <>
    <Typography variant="h3" className={classes.disclaimerTitle}>
      Disclaimer
    </Typography>
    <div>
      {text.split('\n').map((paragraphText) => (
        <Typography
          paragraph
          role="paragraph"
          key={paragraphText}
          className={classes.disclaimerText}
        >
          {paragraphText}
        </Typography>
      ))}
    </div>
  </>
);

Disclaimer.propTypes = {
  text: PropTypes.string.isRequired,
  classes: PropTypes.object.isRequired,
};

export default Disclaimer;
