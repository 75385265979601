import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';

import { success, error } from 'react-notification-system-redux';
import { updateInvestment } from 'common/ducks/investments';
import { updateSetup, createSetup } from 'common/ducks/user/setups';

import OperationalCostsPanel from './OperationalCostsPanel';

const styles = ((theme) => (
  {
    expansionPanel: {
      boxShadow: 'none',
      width: '98%',
      '&:not(:first-of-type)': {
        marginTop: 10,
      },
      '&:before': {
        display: 'none',
      },
      '&::after': {
        display: 'none',
      },
      '&.Mui-expanded': {
        margin: '0 0 16px 0',
      },
    },
    expansionPanelMargin: {
      marginTop: 10,
    },
    expansionPanelDetails: {
      padding: 0,
    },
    expansionPanelIconButton: {
      width: 40,
      fontSize: 16,
    },
    expandIcon: {
      padding: '0',
      marginRight: 0,

      '&.Mui-expanded': {
        marginTop: 8,
      },
    },
    addCostIcon: {
      marginRight: 10,
    },
    selectedCostPanel: {
      backgroundColor: '#F7F7F7',
    },
    createSetupPanel: {
      alignItems: 'center',
      display: 'flex',
      fontWeight: 'bold',
      height: '100%',
      padding: '12px 0',
      '&:before': {
        backgroundColor: 'rgba(51, 51, 51, 0.28)',
        left: '-24px',
        width: 'calc(100% + 48px)',
        height: 1,
        content: '""',
        display: 'block',
        opacity: 1,
        position: 'absolute',
        right: 0,
        top: '-1px',
        transition: `opacity 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
                     background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms`,
      },
      '&::after': {
        height: 1,
        backgroundColor: 'rgba(51, 51, 51, 0.28)',
        content: '""',
        display: 'block',
        left: '-24px',
        opacity: 1,
        position: 'absolute',
        right: 0,
        bottom: '-1px',
        transition: `opacity 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
                     background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms`,
        width: 'calc(100% + 48px)',
      },
    },
    operationalCostFieldWrapper: {
      flex: 1,
    },
    fieldEditDescription: {
      '& label + .MuiInput-formControl': {
        [`@media(max-width: ${theme.breakpoints.values.sm}px)`]: {
          paddingTop: 10,
        },
      },
    },
    operationalCostField: {
      marginTop: 0,
    },
    setupDescription: {
      fontSize: 16,
      paddingLeft: 0,
      paddingRight: 0,
      minHeight: 'auto',

      '& > .Mui-expanded': {
        marginTop: 0,
        marginBottom: 0,
      },
    },
    editPanelSummary: {
      cursor: 'default !important',
      padding: 0,
    },
    panelIconGroup: {
      display: 'flex',
      alignItems: 'center',
    },
    circularProgress: {
      marginRight: 10,
    },
    expansionPanelSummaryContent: {
      margin: 0,
    },
    pulseAnimation: {
      background: 'transparent',
      borderRadius: '50%',
      margin: 10,
      height: 20,
      width: 20,
      boxShadow: '0 0 0 0 rgba(36, 188, 113, 1)',
      transform: 'scale(1)',
      animation: '$pulse 2s infinite',
    },
    '@keyframes pulse': {
      '0%': {
        transform: 'scale(0.95)',
        boxShadow: '0 0 0 0 rgba(36, 188, 113, 0.7)',
      },
      '70%': {
        transform: 'scale(1)',
        boxShadow: '0 0 0 10px rgba(36, 188, 113, 0)',
      },
      '100%': {
        transform: 'scale(0.95)',
        boxShadow: '0 0 0 0 rgba(36, 188, 113, 0)',
      },
    },
  }
));

const mapDispatchToProps = (dispatch) => ({
  notifySuccess: (props) => dispatch(success(props)),
  notifyError: (props) => dispatch(error(props)),
  updateSetup: (data) => dispatch(updateSetup(data)),
  createSetup: (data) => dispatch(createSetup(data)),
  updateInvestment: (data) => dispatch(updateInvestment(data)),
});

export default connect(
  null,
  mapDispatchToProps,
)(withStyles(styles)(OperationalCostsPanel));
