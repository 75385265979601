import { loadCategories } from 'common/ducks/categories';
import { loadStoreItems } from 'common/ducks/storeItems';
import { connect } from 'react-redux';
import PublicStoreItems from './PublicStoreItems';

const mapStateToProps = ({ storeItems }) => ({
  storeItems,
});

const mapDispatchToProps = (dispatch) => ({
  loadCategories: () => dispatch(loadCategories()),
  loadStoreItems: () => dispatch(loadStoreItems(true)),
});

export default connect(mapStateToProps, mapDispatchToProps)(PublicStoreItems);
