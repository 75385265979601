import capital from 'common/assets/images/brokerages/capital-min.png';
import clear from 'common/assets/images/brokerages/clear-min.png';
import genial from 'common/assets/images/brokerages/genial-min.png';
import guide from 'common/assets/images/brokerages/guide-min.png';
import mirae from 'common/assets/images/brokerages/mirae-min.png';
import modalMais from 'common/assets/images/brokerages/modalmais-min.png';
import novaFutura from 'common/assets/images/brokerages/nova-futura-min.png';
import BTGpactual from 'common/assets/images/brokerages/btgpactual-min.png';
import terra from 'common/assets/images/brokerages/terra-min.png';
import xp from 'common/assets/images/brokerages/xp-min.png';
import necton from 'common/assets/images/brokerages/necton.jpg';
import orama from 'common/assets/images/brokerages/orama.png';
import ativaInvestimentos from 'common/assets/images/brokerages/ativa-investimentos.png';
import toro from 'common/assets/images/brokerages/toro.png';

import {
  SIMULATED_BROKERAGE_LIST,
  BROKERAGE_NOT_PERMITTED_LIST,
  STEP_STATUS,
  DISABLED_BROKERAGES,
  BROKERAGE_STATUS,
  BTG_BROKERAGE_ID,
  MIRAE_BROKERAGE_ID,
} from './constants';
import { orderByString, reduceObjectsArrayToObject } from './array';
import { BROKERAGE_NOTICES, BROKERAGES_CHANGE_NAMES, NAME } from './constants/brokerages';
import { getAvailableBalance } from './positions';

const {
  APPROVED, NONE, PENDING, PROCESSING,
} = BROKERAGE_STATUS;

export const brokerageImages = {
  85: BTGpactual,
  15: guide,
  88: capital,
  93: novaFutura,
  107: terra,
  262: mirae,
  308: clear,
  1982: modalMais,
  3: xp,
  23: necton,
  147: ativaInvestimentos,
  3701: orama,
  173: genial,
  4090: toro,
  9995: genial,
  9996: genial,
  9997: genial,
};

export const filterBrokeragesByStrategy = (
  userBrokerages,
  strategy,
) => {
  const { brokerages } = strategy;
  if (brokerages === null) return userBrokerages;

  return userBrokerages
    .filter((userBrokerage) => brokerages.includes(Number.parseInt(userBrokerage.id, 10)));
};

export const getCorrectBrokerageId = (broker) => {
  return broker.id || broker.brokerage_id || broker;
};

export const renameBrokerages = (brokerages = []) => {
  return brokerages.map((item) => {
    const brokerageId = item.id || item.brokerage_id;

    return BROKERAGES_CHANGE_NAMES[brokerageId]
      ? {
        ...item,
        name: BROKERAGES_CHANGE_NAMES[brokerageId].name,
      }
      : item;
  });
};

export const getRealBrokerages = (brokerages) => {
  return brokerages.filter((brokerage) => {
    const brokerId = getCorrectBrokerageId(brokerage);

    return !SIMULATED_BROKERAGE_LIST.includes(parseInt(brokerId, 10));
  });
};

export const isSimulatedBrokerage = (id) => {
  return SIMULATED_BROKERAGE_LIST.includes(parseInt(id, 10));
};

export const getBrokeragesByStatus = ({ status = [], brokerages }) => {
  return brokerages.filter((brokerage) => status.filter((item) => item === brokerage.status));
};

export const getUserApprovedBrokerages = (brokerages) => brokerages.filter((brokerage) => brokerage.status === 'approved'
    && !SIMULATED_BROKERAGE_LIST
      .includes(parseInt(brokerage.id, 10)));

export const getUserRealBrokerages = (brokerages) => {
  const brokers = brokerages.filter((brokerage) => {
    const brokerageId = brokerage.id || brokerage.brokerage_id;

    return Boolean(brokerage.status) && !SIMULATED_BROKERAGE_LIST
      .includes(parseInt(brokerageId, 10));
  });

  return reduceObjectsArrayToObject(brokers, 'brokerage_id');
};

export const getUserRealApprovedBrokerages = (brokerages) => (
  getUserRealBrokerages(getUserApprovedBrokerages(brokerages))
);

export const getBrokerageStatus = (value = 'none') => {
  const listStatus = {
    none: STEP_STATUS.INCOMPLETE,
    pending: STEP_STATUS.ANALYSIS,
    approved: STEP_STATUS.COMPLETE,
  };

  return listStatus[value];
};

export const getUniqueBrokerageId = ({ allowedBrokerages, realUserBrokerages, allBrokerages }) => {
  const arrRealBrokerages = Object.values(realUserBrokerages);
  const hasUniqueAllowedBrokerage = allowedBrokerages.length === 1;
  const hasUniqueBrokerage = arrRealBrokerages.length === 1;

  if (hasUniqueAllowedBrokerage) return allBrokerages[allowedBrokerages[0]].brokerage_id;
  if (hasUniqueBrokerage) return arrRealBrokerages[0].brokerage_id;

  return null;
};

export const getUserRealBrokeragesOverall = (brokerages) => {
  const userHasRealBrokerages = Object.values(getUserRealBrokerages(brokerages)).length > 0;
  const hasApprovedBrokers = Object.values(getUserRealApprovedBrokerages(brokerages)).length > 0;

  if (userHasRealBrokerages) {
    return hasApprovedBrokers ? APPROVED : PENDING;
  }

  return NONE;
};

export const getFullDataBrokerages = ({ notFullBrokerages, fullBrokerages }) => {
  return notFullBrokerages.map((broker) => {
    const brokerId = getCorrectBrokerageId(broker);

    return fullBrokerages[brokerId];
  });
};

export const getGroupedBrokeragesByStatus = (brokerages) => {
  const approvedBrokerages = [];
  const availableBrokerages = [];
  const processingBrokerages = [];
  const pendingBrokerages = [];

  Object.values(brokerages).forEach((brokerage) => {
    if (!SIMULATED_BROKERAGE_LIST.includes(parseInt(brokerage.id, 10))
    && !BROKERAGE_NOT_PERMITTED_LIST.includes(parseInt(brokerage.id, 10))) {
      if (!brokerage.status) availableBrokerages.push(brokerage);
      if (brokerage.status === APPROVED) approvedBrokerages.push(brokerage);
      if (brokerage.status === PROCESSING) processingBrokerages.push(brokerage);
      if (brokerage.status === PENDING) pendingBrokerages.push(brokerage);
    }
  });

  return {
    availableBrokerages: orderByString(availableBrokerages, NAME),
    approvedBrokerages: orderByString(approvedBrokerages, NAME),
    processingBrokerages: orderByString(processingBrokerages, NAME),
    pendingBrokerages: orderByString(pendingBrokerages, NAME),
  };
};

export const getUserRealAndAllowedBrokerages = (clientBrokerages, strategyBrokerages) => {
  return Object.values(clientBrokerages).filter((clientBrokerage) => (
    strategyBrokerages.find((strategyBrokerage) => (
      strategyBrokerage === Number(clientBrokerage.brokerage_id)
      && !SIMULATED_BROKERAGE_LIST.includes(parseInt(clientBrokerage.brokerage_id, 10))
    ))
  ));
};

export const getMatchedUserBrokeragesWithStrategy = ({
  strategies,
  brokerages,
  clientBrokerages,
}) => {
  const matchedUserBrokeragesWithStrategy = {};

  Object.values(strategies.data).map((strategy) => {
    if (strategy.brokerages) {
      matchedUserBrokeragesWithStrategy[strategy.id] = getUserRealAndAllowedBrokerages(
        clientBrokerages, strategy.brokerages,
      )
        .map((item) => ({
          id: brokerages[item.brokerage_id].id,
          name: brokerages[item.brokerage_id].name,
          status: brokerages[item.brokerage_id].status,
        }));
    }
    return matchedUserBrokeragesWithStrategy;
  });

  return matchedUserBrokeragesWithStrategy;
};

export const brokeragesHasAtLeastOneStatus = ({ brokerages, status }) => {
  return brokerages.some((brokerage) => brokerage.status === status);
};

export const removeDisabledBrokerages = ({ brokerages }) => {
  return Object.values(brokerages).filter((brokerage) => {
    return !DISABLED_BROKERAGES.includes(
      parseInt(brokerage.id || brokerage.brokerage_id, 10),
    );
  });
};

export const checkHasBrokerageNotice = (brokerageId) => {
  return Object.keys(BROKERAGE_NOTICES).find((brokerId) => (
    brokerId === brokerageId
  ));
};

export const getAccountHelperText = (brokerage) => {
  const brokerageId = Number.parseInt(brokerage, 10);
  switch (brokerageId) {
    case BTG_BROKERAGE_ID:
      return `Favor desconsiderar os "000" da sua conta no BTG Pactual digital.
      Ex.: conta "000123456" = "123456".`;
    case MIRAE_BROKERAGE_ID:
      return `Insira o seu "Código Bovespa" da corretora com o dígito.
      ATENÇÃO: a conta 1106 não deve ser usada, ela representa a conta
      para realizar transferências para a corretora.`;
    default:
      return '';
  }
};

export const getInstanceByBrokerageId = (instances, brokerageId) => {
  return instances.find((instance) => Number(instance.brokerage_id) === Number(brokerageId));
};

export const getBrokeragesRunningInstances = ({
  realUserBrokerages,
  runningInstances,
}) => {
  return Object.keys(realUserBrokerages)
    .filter((brokerageId) => getInstanceByBrokerageId(runningInstances, brokerageId));
};

export const getInstanceSummaryByBrokerages = ({ instances, positions, report }) => {
  return instances.reduce((acc, instance) => {
    const brokerageObjData = acc[instance.brokerage_id];
    const equity = (brokerageObjData?.equity ?? 0) + report[instance.id]?.data.equity || 0;
    const totalFinancialVol = ((brokerageObjData?.totalFinancialVol ?? 0)
  + positions[instance.id]?.totalFinancialVol) || 0;
    const availableBalance = getAvailableBalance(totalFinancialVol, equity);

    return {
      ...acc,
      [instance.brokerage_id]: {
        equity,
        totalFinancialVol,
        availableBalance,
      },
    };
  }, {});
};
