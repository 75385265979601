import {
  IBOV_WALLET_ID,
  IFIX_WALLET_ID,
  SMLL_WALLET_ID,
  CDI_WALLET_ID,
} from '.';

export const BENCHMARKS = {
  ibov: {
    id: 'ibov',
    name: 'IBOV',
    color: '#747C88',
    active: false,
    instanceId: IBOV_WALLET_ID,
  },
  ifix: {
    id: 'ifix',
    name: 'IFIX',
    color: '#022354',
    active: false,
    instanceId: IFIX_WALLET_ID,
  },
  smll: {
    id: 'smll',
    name: 'SMLL',
    color: '#1c9865',
    active: false,
    instanceId: SMLL_WALLET_ID,
  },
  cdi: {
    id: 'cdi',
    name: 'CDI',
    color: '#d29a00',
    active: false,
    instanceId: CDI_WALLET_ID,
  },
};

export const BENCHMARK = 'benchmark';
