import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import {
  Dialog,
  DialogContent,
  IconButton,
  DialogTitle,
  Typography,
  Box,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';

import { DEFAULT_SETUP_DATA, EMPTY_SETUP_DATA, getSetupByCode } from 'common/utils/setup';
import { ERROR, TABLET_WIDTH_BREAKPOINT, SI_ZENDESK_ARTICLES_URL } from 'common/utils/constants';
import Link from 'common/components/Link';
import { getValidPositions, getPositionsWithLotSize } from 'common/utils/positions';
import { checkNextButtonIsDisable } from 'common/utils/dialog';
import { getCalculatedVFM } from 'common/utils/instance';
import { useSubmitExternalOrders } from 'common/hooks/orders';
import { useOperationalCosts } from 'common/hooks/instance';
import { DEFAULT_MIN_OPERATION_VALUE, PARAMS_MOUNT_INSTANCE } from 'common/utils/constants/instance';
import { SteppedDialogFooter } from '../../Steps';
import ChooseBrokerageForm from './ChooseBrokerageForm';
import CustomOptionsForm from './CustomOptionsForm';
import OperationalCostsPanel from '../OperationalCostsDialog/OperationalCostsPanel';
import ConfirmFollowInstance from './ConfirmFollowInstance';
import FillPositions from './FillPositions';
import FinancialValue from './FinancialValue';

const getValidateSubmittingForm = ({ currentStep, startFromZero }) => {
  if (currentStep !== 2 && currentStep !== 3) {
    return true;
  }

  if (currentStep === 3) {
    return startFromZero;
  }

  return false;
};

const FollowInstanceDialog = ({
  classes,
  strategyId,
  open,
  onClose,
  followInstance,
  history,
  userSetups,
  notifyError,
  allowedBrokerages,
  isWhiteBox,
  templateId,
  templateName,
  storedData,
  storedSubmitData,
  startFromZero,
  storeInstance,
  validStocks,
}) => {
  const form = React.createRef();
  const submitOrders = useSubmitExternalOrders();
  const {
    expanded,
    selectedSetup,
    updateSelectedSetup,
    handlePanelChange,
  } = useOperationalCosts({ storedSetup: storedSubmitData.setup_code });
  const [dataSteps, setDataSteps] = useState({
    currentStep: 1,
    numberOfSteps: startFromZero ? 4 : 6,
    data: {
      force_mount_portfolio: false,
      selectedDateOption: isWhiteBox
        ? PARAMS_MOUNT_INSTANCE.mountNow.value
        : PARAMS_MOUNT_INSTANCE.nextRollout.value,
      allowedBrokerages,
    },
    ...storedData,
  });

  const { currentStep, numberOfSteps } = dataSteps;

  const initialCapital = startFromZero
    ? dataSteps.data.initial_capital
    : dataSteps.data.equity?.totalEquityContribution;

  const [showWarnMessage, setShowWarnMessage] = useState(false);
  const [minOperationValue, setMinOperationValue] = useState(DEFAULT_MIN_OPERATION_VALUE);

  const isMobile = window.innerWidth <= TABLET_WIDTH_BREAKPOINT;
  const isLastStep = currentStep === numberOfSteps;

  const setStep = (step) => {
    setDataSteps({ ...dataSteps, currentStep: step });
  };

  useEffect(() => {
    if (!isWhiteBox) {
      const getFinalVFM = (value) => {
        const limit = Math.floor(0.02 * initialCapital);

        if (value >= limit && currentStep === (startFromZero ? 4 : 6)) {
          setShowWarnMessage(true);
          return limit;
        }

        return value > DEFAULT_MIN_OPERATION_VALUE ? value : DEFAULT_MIN_OPERATION_VALUE;
      };

      const setupObject = getSetupByCode({ code: selectedSetup, setups: userSetups });
      const brokerageTax = setupObject?.spot_absolute_brokerage_tax;
      const orderVolume = storeInstance.strategy.extra_data.tags.order_volume;
      const calculatedVFM = getCalculatedVFM({ brokerageTax, orderVolume });
      const finalVFM = getFinalVFM(calculatedVFM);
      setMinOperationValue(finalVFM);
    }
  }, [
    initialCapital,
    selectedSetup,
    storeInstance,
    currentStep,
    startFromZero,
    isWhiteBox,
    userSetups,
  ]);

  const handleChangeThirdStep = async (step) => {
    const isNextStep = step > currentStep;

    const { isValid, values, status } = await submitOrders.handleSubmit(
      form,
      !isNextStep,
    );

    if (!isValid && isNextStep) return false;

    let filteredPositions;

    if (!isNextStep) {
      const errorsCode = status.errors
        ? Object.keys(status.errors).map((item) => item)
        : [];

      filteredPositions = values.positions.length > 1
        ? getValidPositions(values.positions, errorsCode)
        : values.positions;
    }

    const positions = isNextStep ? values.positions : filteredPositions;
    const positionsWithLotSize = getPositionsWithLotSize({
      positions,
      validStocks,
    });

    return setDataSteps({
      ...dataSteps,
      data: {
        ...dataSteps.data,
        positions: positionsWithLotSize,
      },
      currentStep: step,
    });
  };

  const handleChangeStep = (step) => {
    const isNextStep = step > currentStep;

    if ((currentStep === 2) && isNextStep) {
      if (currentStep === 2 && selectedSetup === DEFAULT_SETUP_DATA.code) {
        return notifyError({
          title: ERROR,
          message: 'Defina o custo operacional dessa carteira.',
        });
      }
    }

    if ((currentStep === 3) && !startFromZero) {
      return handleChangeThirdStep(step);
    }

    return setStep(step);
  };

  const renderSetups = (array) => {
    return array.map((setup) => (
      <OperationalCostsPanel
        id={`${setup.code}-panel`}
        key={setup.code}
        expand={expanded === setup.code}
        isAssociated={selectedSetup === setup.code}
        setup={setup}
        updateSelectedSetup={updateSelectedSetup}
        handlePanelChange={handlePanelChange}
      />
    ));
  };

  const submitFollowInstance = async (data) => {
    if (currentStep !== numberOfSteps) {
      setDataSteps({
        ...dataSteps,
        data: { ...dataSteps.data, ...data },
        currentStep: currentStep + 1,
      });
    } else {
      const submitData = {
        brokerage_id: +dataSteps.data.brokerage,
        initial_capital: Number(initialCapital).toFixed(2),
        contribution: dataSteps.data.contribution,
        name: isWhiteBox ? dataSteps.data.name : dataSteps.data.storeInstance.name,
        setup_code: selectedSetup,
        strategy_id: dataSteps.data.storeInstance.strategy_id || strategyId,
        trading_system_code: 'default_trading_system',
      };

      followInstance({
        submitData,
        forcePortfolio: dataSteps.data.force_mount_portfolio,
        history,
        dataSteps,
        isWhiteBox,
        templateId,
        startFromZero,
        minOperationValue,
      });
    }
  };

  const renderConfirmFollowInstance = () => (
    <ConfirmFollowInstance
      setupCode={selectedSetup}
      strategyId={strategyId}
      dataSteps={dataSteps}
      onSetStep={handleChangeStep}
      onSubmit={submitFollowInstance}
      showWarnMessage={showWarnMessage}
      setShowWarnMessage={setShowWarnMessage}
      isWhiteBox={isWhiteBox}
      startFromZero={startFromZero}
    />
  );

  const renderCustomOptionsForm = () => (
    <CustomOptionsForm
      strategyId={strategyId}
      onSubmit={submitFollowInstance}
      dataSteps={dataSteps}
      setDataSteps={setDataSteps}
      isWhiteBox={isWhiteBox}
      templateId={templateId}
      startFromZero={startFromZero}
    />
  );

  const stepsMap = {
    1: {
      form: 'follow_action_choose_brokerage',
      component: (
        <ChooseBrokerageForm
          strategyId={strategyId}
          onSubmit={submitFollowInstance}
          initialValues={dataSteps}
          allowedBrokerages={allowedBrokerages}
          isWhiteBox={isWhiteBox}
          templateName={templateName}
        />
      ),
    },
    2: {
      form: 'follow_action_costs_form',
      component: (
        <div>
          <Typography color="textSecondary" variant="h6">
            Quais são os custos da sua corretora?
          </Typography>

          <Box my={2}>
            <Link
              color="primary"
              fontWeight={700}
              path={`${SI_ZENDESK_ARTICLES_URL}/4419885338391`}
              target="_blank"
            >
              Precisa de ajuda para preencher os custos?
            </Link>
          </Box>
          {(userSetups.length > 0 && dataSteps.currentStep === 2) && renderSetups(userSetups)}
          <OperationalCostsPanel
            create
            id="create-new-setup-panel"
            expand={expanded === 'new-setup'}
            setup={{
              description: '',
              ...EMPTY_SETUP_DATA,
            }}
            updateSelectedSetup={updateSelectedSetup}
            handlePanelChange={handlePanelChange}
            onSubmit={submitFollowInstance}
          />
        </div>
      ),
    },
    3: {
      form: 'follow_action_custom_options_form',
      component: startFromZero
        ? renderCustomOptionsForm()
        : (
          <>
            <Box mb={2.5}>
              <Typography variant="h6" color="textSecondary">
                Preencha os ativos que você já possui na corretora e quer que a
                SmarttInvest comece a gerenciar a partir de agora.
              </Typography>
            </Box>
            <Box mb={2.5}>
              <Typography variant="h6" color="textSecondary">
                Você deve preencher uma linha por cada ativo, incluindo o lote
                cheio e o lote fracionário na mesma linha.
              </Typography>
            </Box>

            <Box mb={2.5}>
              <Link
                color="primary"
                fontWeight={700}
                path={`${SI_ZENDESK_ARTICLES_URL}/4419885712919`}
                target="_blank"
                underlineDecoration
              >
                Precisa de ajuda para preencher as posições?
              </Link>
            </Box>

            <FillPositions
              ref={form}
              onSubmit={submitFollowInstance}
              dataSteps={dataSteps}
              setDataSteps={setDataSteps}
              strategyId={strategyId}
            />
          </>
        ),
    },
    4: {
      form: startFromZero ? 'follow_confirm_form' : 'financial_value_form',
      component: (
        startFromZero
          ? renderConfirmFollowInstance()
          : (
            <FinancialValue
              dataSteps={dataSteps}
              onSubmit={submitFollowInstance}
              setDataSteps={setDataSteps}
              strategyId={strategyId}
              templateId={templateId}
            />
          )
      ),
    },
    5: {
      form: 'follow_action_custom_options_form',
      component: renderCustomOptionsForm(),
    },
    6: {
      form: 'follow_confirm_form',
      component: renderConfirmFollowInstance(),
    },
  };

  const validateSubmittingForm = getValidateSubmittingForm({
    currentStep,
    startFromZero,
  });

  const disableNextButton = checkNextButtonIsDisable({
    startFromZero,
    currentStep,
    equity: dataSteps.data?.equity,
    storeInstance,
  });

  return (
    <Dialog
      disableBackdropClick
      className={classes.dialog}
      classes={{
        paper: classes.dialog,
      }}
      fullScreen={isMobile}
      onClose={onClose}
      aria-labelledby="customized-dialog-title"
      open={open}
    >
      {!isLastStep && (
      <DialogTitle disableTypography className={classes.dialogTitle}>
        Seguir uma carteira
      </DialogTitle>
      )}

      <DialogContent className={classes.dialogContent}>
        <IconButton
          size="small"
          className={classes.closeIcon}
          edge="start"
          color="inherit"
          onClick={onClose}
          aria-label="close"
        >
          <CloseIcon />
        </IconButton>

        {stepsMap[currentStep].component}
        {!showWarnMessage
          && (
          <SteppedDialogFooter
            onSetStep={handleChangeStep}
            submittingForm={validateSubmittingForm}
            formName={stepsMap[currentStep].form}
            StepsProps={{ currentStep, numberOfSteps }}
            onConfirmSteps={submitFollowInstance}
            disableNextButton={disableNextButton}
            displayPreviousStep={currentStep !== 1}
          />
          )}
      </DialogContent>
    </Dialog>

  );
};

FollowInstanceDialog.propTypes = {
  strategyId: PropTypes.number.isRequired,
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  followInstance: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  userSetups: PropTypes.array.isRequired,
  notifyError: PropTypes.func.isRequired,
  allowedBrokerages: PropTypes.array.isRequired,
  isWhiteBox: PropTypes.bool,
  storedData: PropTypes.object,
  storedSubmitData: PropTypes.object,
  templateId: PropTypes.number,
  templateName: PropTypes.string,
  startFromZero: PropTypes.bool,
  validStocks: PropTypes.object.isRequired,
  storeInstance: PropTypes.object.isRequired,
};

FollowInstanceDialog.defaultProps = {
  isWhiteBox: false,
  storedData: {},
  storedSubmitData: {},
  templateId: null,
  templateName: '',
  startFromZero: true,
};

export default FollowInstanceDialog;
