import logdown from 'logdown';

import api from 'common/api';
import { MARKET_AVAILABILITY_ERROR_MESSAGE } from 'common/utils/constants/positions';
import { LOGOUT_SUCCEED } from 'common/ducks/auth';
import { createTimeMoment } from 'common/utils/date';

export const DUCK_NAME = 'market';

export const INITIAL_STATE = {};

const logger = logdown(DUCK_NAME);

// Actions
export const LOAD_MARKET_OPERATION_PERIOD_STARTED = `${DUCK_NAME}/LOAD_MARKET_OPERATION_PERIOD_STARTED`;
export const LOAD_MARKET_OPERATION_PERIOD_SUCCEEDED = `${DUCK_NAME}/LOAD_MARKET_OPERATION_PERIOD_SUCCEEDED`;
export const LOAD_MARKET_OPERATION_PERIOD_FAILED = `${DUCK_NAME}/LOAD_MARKET_OPERATION_PERIOD_FAILED`;

export const loadMarketOperationPeriodStarted = () => ({
  type: LOAD_MARKET_OPERATION_PERIOD_STARTED,
});

export const loadMarketOperationPeriodSucceeded = (data) => ({
  data,
  type: LOAD_MARKET_OPERATION_PERIOD_SUCCEEDED,
});

export const loadMarketOperationPeriodFailed = (error) => ({
  error,
  type: LOAD_MARKET_OPERATION_PERIOD_FAILED,
});

export const loadMarketOperationPeriod = () => async (dispatch) => {
  logger.log('Load market operation period start');
  dispatch(loadMarketOperationPeriodStarted());

  try {
    const { data: operationTimes } = await api.market.getOperationsPeriod();
    logger.log('Load market operation period result:', operationTimes);
    const { begin, end } = operationTimes.BOVESPA.stocks.default;
    const operationPeriod = {
      openTime: createTimeMoment(begin),
      closeTime: createTimeMoment(end),
    };
    dispatch(loadMarketOperationPeriodSucceeded(operationPeriod));
  } catch (error) {
    logger.error('Load market operation period error:', error);
    dispatch(loadMarketOperationPeriodFailed(MARKET_AVAILABILITY_ERROR_MESSAGE));
  }
};

// Reducer
export default (state = INITIAL_STATE, action) => {
  // Logout cleaning
  if (action.type === LOGOUT_SUCCEED) {
    return INITIAL_STATE;
  }

  switch (action.type) {
    case LOAD_MARKET_OPERATION_PERIOD_STARTED:
      return {
        ...state,
        loading: true,
      };
    case LOAD_MARKET_OPERATION_PERIOD_SUCCEEDED:
      return {
        ...state,
        loading: false,
        operationPeriod: action.data,
      };
    case LOAD_MARKET_OPERATION_PERIOD_FAILED:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    default: return state;
  }
};
