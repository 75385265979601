import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';

import { loadPlans } from 'common/ducks/plans';
import { loadInstances } from 'common/ducks/instances';
import { openDialog } from 'common/ducks/dialogs';
import { PROGRESS_DIALOG_CODE } from '../ProgressDialog';

import CardRemoveConfirmDialog, { CARD_REMOVE_CONFIRM_DIALOG } from './CardRemoveConfirmDialog';

export { CARD_REMOVE_CONFIRM_DIALOG };

const styles = (theme) => ({
  title: {
    padding: '20px 10px 10px 20px',
    fontSize: '1.3125rem',
    fontWeight: 500,
  },
  messageContainer: {
    padding: '0 20px',
  },
  actionsWrapper: {
    margin: theme.spacing(1),
    position: 'relative',
  },
  buttonProgress: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
  actions: {
    paddingTop: 0,
  },
  loadingIcon: { position: 'absolute', right: '15px', float: 'right' },
});

const mapStateToProps = ({ plans, user: { subscriptions } }) => ({
  plans: plans.data,
  subscriptions,
});
const mapDispatchToProps = (dispatch) => ({
  loadPlans: (params) => dispatch(loadPlans(params)),
  loadInstances: (params) => dispatch(loadInstances(params)),
  openProgressDialog: (props) => dispatch(openDialog(
    PROGRESS_DIALOG_CODE,
    props,
  )),
});

const styledCardRemoveDialog = withStyles(styles)(CardRemoveConfirmDialog);
export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps,
)(styledCardRemoveDialog));
