import { withStyles } from '@material-ui/core/styles';
import PublicPagesButton from './PublicPagesButton';

const styles = {
  button: {
    padding: '5px 23px',
    margin: '20px 0',
  },
  loadingIcon: {
    marginLeft: 5,
    marginRight: -10,
  },
};

export default withStyles(styles)(PublicPagesButton);
