import { connect } from 'react-redux';
import { withStyles } from '@material-ui/styles';
import { getFormattedCurrency } from 'common/utils/string';
import { isHolidayOrWeekendDay } from 'common/ducks/holidays';
import { WHITEBOX_MIN_CAPITAL } from 'common/utils/constants/whitebox';
import CustomOptionsForm from './CustomOptionsForm';

const styles = {
  textUppercase: {
    textTransform: 'uppercase',
  },
};

const mapStateToProps = ({ storeItems, user: { subscriptions } },
  {
    onSubmit,
    strategyId,
    dataSteps,
    templateId,
  }) => {
  const storeInstance = storeItems.data.items[strategyId] || {};

  const initialCapital = templateId
    ? dataSteps.data.initial_capital || WHITEBOX_MIN_CAPITAL
    : dataSteps.data.initial_capital || storeInstance.extra_data.tags.minimum;

  return {
    storeInstance,
    subscriptions: subscriptions.data,
    initialValues: {
      initial_capital: getFormattedCurrency({ value: Number(initialCapital) }),
      storeInstance,
    },
    onSubmit,
  };
};

const mapDispatchToProps = (dispatch) => ({
  isHolidayOrWeekendDay: (date) => dispatch(isHolidayOrWeekendDay(date)),
});

export default connect(mapStateToProps, mapDispatchToProps)(
  withStyles(styles)(CustomOptionsForm),
);
