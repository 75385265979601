import {
  error as notifierError,
  success as notifierSuccess,
} from 'react-notification-system-redux';
import { connect } from 'react-redux';
import {
  reset, reduxForm, formValueSelector, untouch,
} from 'redux-form';

import { withStyles } from '@material-ui/core/styles';

import { addPaymentMethod } from 'common/ducks/user/paymentMethods';
import palette from 'common/palette';

import AddCreditCardDialog, { ADD_CREDIT_CARD_DIALOG_CODE } from './AddCreditCardDialog';

export { ADD_CREDIT_CARD_DIALOG_CODE };

const styles = (theme) => ({
  dialogPaper: {
    margin: '15px',
  },
  title: {
    fontSize: '1.3125rem',
    fontWeight: 500,
  },
  icon: {
    color: palette.default.dark,
    margin: '0 10px',
  },
  formControl: {
    padding: '10px 5px 5px 5px',
    width: '100%',
  },
  textField: {
    margin: theme.spacing(1),
    marginBottom: '10px',
  },
  cards: {
    '& .rccs__card--unknown > div': {
      background: `linear-gradient(25deg, ${palette.primary.dark}, ${palette.primary.main})`,
    },
  },
  wrapper: {
    margin: theme.spacing(1),
    position: 'relative',
  },
  buttonProgress: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
  loadingIcon: { position: 'absolute', right: '15px', float: 'right' },
  cancelButton: { color: palette.textSecondary.main },
  reCaptchaContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
});

const selector = formValueSelector('addCreditCard');

const mapStateToProps = (state) => ({
  formValueSelector: selector(state, 'number', 'name', 'expiry', 'cvc'),
});

const mapDispatchToProps = (dispatch) => ({
  resetForm: () => {
    dispatch(reset('addCreditCard'));
  },
  untouchNumberField: () => dispatch(untouch('addCreditCard', 'number')),
  addPaymentMethod: (data) => dispatch(addPaymentMethod(data)),
  notifySuccess: (data) => dispatch(notifierSuccess(data)),
  notifyError: (data) => dispatch(notifierError(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(reduxForm({
  form: 'addCreditCard',
})(withStyles(styles)(AddCreditCardDialog)));
