import React from 'react';
import PropTypes from 'prop-types';
import { Box, Button } from '@material-ui/core';
import logo from 'common/assets/images/logo.svg';
import { Link } from 'react-router-dom';
import { LOGIN_URL, SIGN_UP_URL } from 'common/utils/constants';
import Container from '../Container';

const PublicHeader = ({ classes }) => {
  return (
    <header className={classes.header}>
      <Container>
        <div className={classes.headerContent}>
          <img className={classes.logo} src={logo} alt="Logo SmarttInvest" />

          <Box display="flex" alignItems="center" gridGap={16}>
            <Link to={{ pathname: SIGN_UP_URL }}>
              <Button
                className={classes.button}
                color="primary"
                variant="contained"
              >
                CRIE SUA CONTA
              </Button>
            </Link>
            <Link to={{ pathname: LOGIN_URL }}>
              <Button className={classes.button}>LOGIN</Button>
            </Link>
          </Box>
        </div>
      </Container>
    </header>
  );
};

PublicHeader.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default PublicHeader;
