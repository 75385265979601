import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

// Material-UI
import CircularProgress from '@material-ui/core/CircularProgress';
import IconButton from '@material-ui/core/IconButton';
import Grid from '@material-ui/core/Grid';

// FontAwesome
import FontAwesomeIcon from '@fortawesome/react-fontawesome';
import faSave from '@fortawesome/fontawesome-free-solid/faSave';
import faPlusCircle from '@fortawesome/fontawesome-free-solid/faPlusCircle';

import { InputTextField } from 'common/components/ReduxForm';
import ButtonDialog from 'common/components/Buttons/ButtonDialog';

const CreateCostsPanelSummary = ({
  expand,
  loading,
  classes,
  togglePanel,
  description,
  handleChange,
  handleBlur,
  isValid,
}) => {
  const renderExpanded = (
    <InputTextField
      id="operational-costs-description-input"
      name="description"
      className={classes.operationalCostField}
      value={description.value}
      meta={{
        error: description.error,
        touched: description.touched,
      }}
      onChange={handleChange}
      onBlur={handleBlur}
      type="text"
      label="Nome do custo operacional"
    />
  );
  const renderNotExpanded = (
    <ButtonDialog
      align="left"
      weight="bold"
      showBorders
      onClick={togglePanel}
    >
      <FontAwesomeIcon
        className={classes.addCostIcon}
        icon={faPlusCircle}
        size="lg"
      />
      Adicionar novo custo operacional
    </ButtonDialog>
  );

  const isValidDescription = description.value && isValid;

  return (
    <Grid
      container
      justify="space-between"
      alignItems="center"
    >
      <Grid className={classes.operationalCostFieldWrapper} item>
        {expand ? renderExpanded : renderNotExpanded}
      </Grid>
      <Grid className={classes.panelIconGroup} item>
        {loading && (
          <CircularProgress
            className={classes.circularProgress}
            color="primary"
            size={20}
            thickness={7}
          />
        )}
        {expand
          && (
          <IconButton
            color={isValidDescription ? 'primary' : 'default'}
            disabled={!isValid}
            className={classNames(
              classes.expansionPanelIconButton,
              isValidDescription && classes.pulseAnimation,
            )}
            type="submit"
          >
            <FontAwesomeIcon icon={faSave} />
          </IconButton>
          )}
      </Grid>
    </Grid>
  );
};

CreateCostsPanelSummary.propTypes = {
  loading: PropTypes.bool.isRequired,
  expand: PropTypes.bool,
  // classes is received from OperationalCostPanel component
  classes: PropTypes.object.isRequired,
  togglePanel: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
  handleBlur: PropTypes.func.isRequired,
  description: PropTypes.object.isRequired,
  isValid: PropTypes.bool.isRequired,
};

CreateCostsPanelSummary.defaultProps = {
  expand: false,
};

export default CreateCostsPanelSummary;
