import { BUY, SELL } from 'common/utils/constants/stocks';

export const ORDER_VARIANT = {
  insert: 'insert',
  update: 'update',
};

export const ORDER_STATUS = {
  rejected: 'rejected',
};

export const ORDER_TYPE = {
  buy: {
    key: 0,
    name: BUY,
    color: 'primary',
    id: 'buy',
    entry_exit_or_reversal: 'entry',
  },
  sell: {
    key: 1,
    name: SELL,
    color: 'secondary',
    id: 'sell',
    entry_exit_or_reversal: 'exit',
  },
};

export const STEPS_MANUAL_OPERATION_INITIAL_STATE = {
  currentStep: 1,
  numberOfSteps: 3,
  selectedStock: null,
  payload: {
    isMarketOrder: 1,
    orderType: ORDER_TYPE.buy.id,
    value: null,
  },
  error: false,
};

export const MARKET_BOVESPA = 'BOVESPA';
export const ALERT_NUMBER_OF_STOCKS_LOT_SIZE_MSG = 'O sistema alterou a quantidade inserida para múltiplo do lote padrão';

export const EXTERNAL_ORDER_TEXT = 'Ordem externa';
export const CANCELING_ORDER_TITLE = 'Cancelando ordem';
export const CANCELED_ORDER_TITLE = 'Ordem cancelada';
export const CANCELED_ORDER_MSG = 'Você cancelou com sucesso a sua ordem enviada manualmente.';
export const OPEN_ORDERS_TEXT = 'Ordem aberta';
export const OPEN_ORDERS_WAIT_MSG = 'A sua carteira possui ordens em aberto. Aguarde até a execução das ordens para realizar alterações.';
export const SENT_ORDER_SUCCESS_TITLE = 'Ordem enviada';
export const SENT_ORDER_SUCCESS_MSG = 'A sua ordem foi enviada com sucesso. Acesse a lista de ordens para mais detalhes e informações.';
export const SENT_ORDER_REJECTED_TITLE = 'Ordem rejeitada';
export const SENT_ORDER_REJECTED_MSG_TOP = 'A sua ordem foi rejeitada pela sua corretora pelo seguinte motivo:';
export const SENT_ORDER_ERROR_MSG = 'Não foi possível enviar a ordem. Atualize a página e tente novamente. Se o erro persistir entre em contato com o nosso suporte.';
export const CHECK_ORDER = 'Ver ordem';
