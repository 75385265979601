import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const Link = ({
  children,
  classes,
  color,
  fontWeight,
  fontSize,
  handleClick,
  path,
  target,
  underlineDecoration,
}) => {
  return (
    <a
      className={classNames(
        classes.link,
        color && classes[color],
        underlineDecoration && classes.underlineDecoration,
      )}
      href={path}
      onClick={handleClick}
      style={{ fontWeight, fontSize }}
      target={target}
    >
      {children}
    </a>
  );
};

Link.propTypes = {
  children: PropTypes.any.isRequired,
  classes: PropTypes.object.isRequired,
  color: PropTypes.string,
  fontWeight: PropTypes.number,
  fontSize: PropTypes.number,
  handleClick: PropTypes.func,
  path: PropTypes.string,
  target: PropTypes.string,
  underlineDecoration: PropTypes.bool,
};

Link.defaultProps = {
  color: '',
  fontWeight: 400,
  fontSize: 14,
  handleClick: () => {},
  path: null,
  target: '_self',
  underlineDecoration: false,
};

export default Link;
