import moment from 'moment';
import { getDateWithTimeZero } from 'common/utils/date';
import { setValuesObject } from './object';
import {
  CHANGED_STOCKS_VARIANTS,
  UNAVAILABLE_STOCKS,
} from './constants/stocks';
import { getInstanceStocks, getStocksAndWeightsFromWhitebox } from './whitebox';
import { getWeightListFromPositions } from './positions';
import { getStocksAndWeightsListFromInstance } from './instance';
import { getStocksAndWeightsListFromStoreItem } from './storeItem';

export const createBacktest = (stocks = [], isHolidayOrWeekendDay) => {
  if (stocks.length === 0) return [];
  const dailyCumulativeData = stocks[0].data.map((item, indexStock) => {
    const dailyCumulativePerformance = stocks.reduce((accumulator, stock) => {
      const candle = stock.data[indexStock];
      const cumulativeWeight = candle.cumulative_return * (stock.weight / 100);

      return accumulator + cumulativeWeight;
    }, 1);

    const date = item.datetime.split(' ')[0].split(',')[0];

    return {
      date: item.datetime.split(' ')[0],
      dailyCumulativePerformance,
      isWorkday: !isHolidayOrWeekendDay(new Date(getDateWithTimeZero(date))),
    };
  });

  return dailyCumulativeData;
};

export const getDatesArray = (startDate, endDate) => {
  const now = startDate.clone();
  const dates = [];

  while (now.isSameOrBefore(endDate)) {
    dates.push(now.format('YYYY-MM-DD'));
    now.add(1, 'day');
  }

  return dates;
};

export const getEarliestDate = (data) => {
  let earliestDate = moment();
  Object.values(data).forEach((item) => {
    const { length } = item.data;
    const firstDate = moment(item.data[length - 1].datetime);
    if (firstDate.isBefore(earliestDate)) {
      earliestDate = firstDate.clone();
    }
  });

  return earliestDate;
};

export const getAdjustedStocksData = ({ data }) => {
  const startDate = getEarliestDate(data);
  const yesterday = moment().subtract(1, 'day');
  const datesArray = getDatesArray(startDate, yesterday);
  datesArray.reverse();

  return Object.keys(data).reduce((acc, stock) => {
    const item = data[stock];

    const currentStockDataArray = [...item.data];

    datesArray.forEach((date, index) => {
      if (currentStockDataArray[index]?.datetime.split(' ')[0] !== date) {
        const candleObject = { ...currentStockDataArray[0], datetime: date };
        let newCandle;
        if (!currentStockDataArray[index]) {
          newCandle = setValuesObject({
            obj: candleObject,
            ignoreFields: ['datetime'],
          });
        } else {
          newCandle = {
            ...currentStockDataArray[index],
            datetime: date,
          };
        }

        currentStockDataArray.splice(index, 0, newCandle);
      }
    });

    return {
      ...acc,
      [stock]: {
        ...item,
        data: currentStockDataArray,
      },
    };
  }, {});
};

export const getStocksWithoutZeroWeight = ({ stocks }) => {
  return Object.keys(stocks).reduce((acc, stock) => {
    const currentStock = stocks[stock];

    if (currentStock.weight === 0) return acc;

    return {
      ...acc,
      [stock]: stocks[stock],
    };
  }, {});
};

export const getStockFraction = (
  { numberOfStocks, tradingLotSize },
) => numberOfStocks % tradingLotSize;

export const checkIfFractionalStock = ({
  stockCode,
  numberOfStocks,
  tradingLotSize,
}) => {
  if (stockCode) {
    return stockCode.substr(stockCode.length - 1) === 'F';
  }
  return getStockFraction({ numberOfStocks, tradingLotSize }) !== 0;
};

export const getStatusChangedStock = (leftValue, rightValue) => {
  if (leftValue > 0 && leftValue < rightValue) {
    return CHANGED_STOCKS_VARIANTS.increased.status;
  }

  if (leftValue > 0 && leftValue === rightValue) {
    return CHANGED_STOCKS_VARIANTS.kept.status;
  }

  if (leftValue === 0 && rightValue > 0) {
    return CHANGED_STOCKS_VARIANTS.cameIn.status;
  }

  if (leftValue > 0 && rightValue > 0 && leftValue > rightValue) {
    return CHANGED_STOCKS_VARIANTS.decreased.status;
  }

  return CHANGED_STOCKS_VARIANTS.cameOut.status;
};

export const getRemovedStocks = ({ stocks, oldStocks }) => {
  return Object.values(oldStocks)
    .map((item) => ({
      ...item,
      weight: 0,
    }))
    .reduce((acc, oldStock) => {
      if (stocks[oldStock.code]) return acc;

      return {
        ...acc,
        [oldStock.code]: oldStock,
      };
    }, {});
};

const getStockParamName = (stock) => (stock.display_name ? 'display_name' : 'company_name');

export const orderStocksByName = (stocks) => {
  return stocks.sort((a, b) => {
    return a[getStockParamName(a)]?.localeCompare(b[getStockParamName(b)]);
  });
};

export const getStocksWithTradingLotSize = ({
  storeItemStocks,
  searchedStocks,
}) => {
  return Object.values(storeItemStocks).map((stock) => {
    const stockWithTradingLotSize = searchedStocks.find(
      (searchedStock) => stock.code === searchedStock.code,
    );

    if (stockWithTradingLotSize) {
      return {
        ...stock,
        trading_lot_size: stockWithTradingLotSize.trading_lot_size,
      };
    }

    return stock;
  });
};

export const getStocksText = (numberStocks) => {
  const parsedNumberStocks = Number(numberStocks);

  return `ativo${
    parsedNumberStocks > 1 || parsedNumberStocks === 0 ? 's' : ''
  }`;
};

export const removeUnavailableStocks = (stocks) => {
  return stocks.filter((stock) => !UNAVAILABLE_STOCKS.includes(stock.code));
};

export const getStocksAndWeightsList = (instance) => {
  if ('extra_data' in instance) return getStocksAndWeightsListFromStoreItem(instance);
  return getStocksAndWeightsListFromInstance(instance);
};

export const getStocksAndOldStocksFromPositions = ({
  positions,
  instance,
  whitebox = undefined,
}) => {
  const stockList = Object.keys(positions);
  const weightList = getWeightListFromPositions(positions);
  let stocksAndWeights;
  if (whitebox !== undefined) {
    stocksAndWeights = getStocksAndWeightsFromWhitebox(whitebox);
  } else {
    stocksAndWeights = getStocksAndWeightsList(instance);
  }

  const {
    stockList: oldStockList, weightList: oldWeightList,
  } = stocksAndWeights;

  const stocks = getInstanceStocks({
    stockList,
    weightList,
    oldStockList,
    oldWeightList,
  });

  const oldStocks = getInstanceStocks({
    stockList: oldStockList,
    weightList: oldWeightList,
    oldStockList,
    oldWeightList,
  });

  return { stocks, oldStocks };
};
