import { withStyles } from '@material-ui/core/styles';

import InvestorProfileQuestionnaire from './InvestorProfileQuestionnaire';

const styles = {
  questionnaireBox: {
    minHeight: 330,
  },
  optionLabel: {
    marginTop: 4,
  },
};

export default withStyles(styles)(InvestorProfileQuestionnaire);
