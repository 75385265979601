import { withStyles } from '@material-ui/core/styles';
import { INITIAL_REPORT_STATE } from 'common/ducks/report';
import palette from 'common/palette';
import { connect } from 'react-redux';
import ChangedStocksVolume from './ChangedStocksVolume';

const styles = {
  title: {
    alignItems: 'center',
    display: 'flex',
    fontSize: 22,
    fontWeight: 700,
    marginBottom: 16,
  },
  text: {
    fontWeight: 400,
  },
  warnIcon: {
    color: palette.warn.main,
    fontSize: 22,
    marginLeft: 5,
  },
};

const mapStateToProps = ({ report }, { instanceId }) => ({
  report: report[instanceId].data || INITIAL_REPORT_STATE,
});

export default connect(
  mapStateToProps,
)(withStyles(styles)(ChangedStocksVolume));
