import React from 'react';
import PropTypes from 'prop-types';

const ErrorMsg = (props) => {
  const { children, classes } = props;
  return (
    <div className={classes.errorMsg}>
      {children}
    </div>
  );
};

ErrorMsg.propTypes = {
  children: PropTypes.any.isRequired,
  classes: PropTypes.object.isRequired,
};

export default ErrorMsg;
