import Moment from 'moment';
import { memoize } from 'lodash';
import { extendMoment } from 'moment-range';
import { CPF, CNPJ } from 'cpf_cnpj';
import isEmptyArr from 'lodash/isEmpty';
import { currencyMask, numberMask } from './inputMasks';
import { onlyNumbers, formatMoney } from './string';
import api from '../api';
import { MSG_INVALID_DATE, LOGIN_CODE_ERROR, VARIANT_INSTANCES } from './constants';
import { ORDER_TYPE } from './constants/orders';

export const moment = extendMoment(Moment);

export const isEmpty = (value) => value === undefined || value === null || value === '';

export const required = memoize((value) => (isEmpty(value) ? 'Preencha esse campo.' : undefined));

export const isDuckAlreadyLoaded = (duck) => duck && duck.data && !duck.loading && !duck.error;

export const checkTermsRequired = (value) => (!value || isEmpty(value)
  ? 'Para prosseguir, você deve concordar com os Termos de Adesão.'
  : undefined);

export const nameOrCorporateName = (value) => (!(/^[a-zA-Z\x7f-\xff]+( [a-zA-Z\x7f-\xff]+)+$/).test(value) && !isEmpty(value)
  ? 'Nome inválido.'
  : undefined);

export const confirmPassword = (value, allValues) => (
  value !== allValues.password && allValues.password
    ? 'As senhas não correspondem.'
    : undefined
);

export const email = (value) => (!isEmpty(value) && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)
  ? 'E-mail inválido.'
  : undefined);

export const fieldRestrictions = (value) => ((/[()';$]/g).test(value)
  ? 'O campo não pode conter os caracteres ()\';$'
  : undefined);

export const passwordSteps = (value) => {
  let validationSteps = ' ';
  validationSteps = !isEmpty(value) && (/.{6,}/).test(value) // Ao menos 6 digitos
    ? validationSteps.concat('1')
    : validationSteps;
  validationSteps = !isEmpty(value) && (/\d{1,}/).test(value) // Um numero
    ? validationSteps.concat('2')
    : validationSteps;
  validationSteps = !isEmpty(value) && (/[a-z]{1,}/).test(value) // Uma letra minuscula
    ? validationSteps.concat('3')
    : validationSteps;
  validationSteps = !isEmpty(value) && (/[A-Z]{1,}/).test(value) // Uma letra maiuscula
    ? validationSteps.concat('4')
    : validationSteps;
  return validationSteps;
};

export const passwordRestrictions = (value) => (!isEmpty(value) && (/[;$]/g).test(value)
  ? 'A senha não pode conter os caracteres $ e ;'
  : undefined);

export const validateCPF = (value) => (
  CPF.isValid(CPF.strip(value))
    ? undefined
    : 'CPF Inválido.');

export const validateCNPJ = (value) => (
  CNPJ.isValid(CNPJ.strip(value))
    ? undefined
    : 'CNPJ Inválido.');

export const validateDate = (value, future = true, past = true) => {
  const date = moment(value, ['DD/MM/YYYY', moment.ISO_8601]);

  if (!date.isValid()) {
    return MSG_INVALID_DATE;
  }

  if (!future && date.isAfter(moment().hour(23))) {
    return 'Digite uma data igual ou anterior ao dia atual.';
  }

  if (!past && date.isBefore(moment().hour(0))) {
    return 'Digite uma data igual ou posterior ao dia atual.';
  }

  const range = moment.range(
    moment('01/01/1905', 'DD/MM/YYYY', true),
    moment('01/01/2100', 'DD/MM/YYYY', true),
  );

  if (!range.contains(date)) {
    return MSG_INVALID_DATE;
  }

  return undefined;
};

export const dateLessThanCurrent = (value) => {
  const date = moment(value, ['DD/MM/YYYY', moment.ISO_8601]);

  if (date.isAfter(moment().hour(23))) {
    return MSG_INVALID_DATE;
  }

  return null;
};

export const validateDateAfter = (initial) => (value) => {
  const date = moment(value, 'DD/MM/YYYY', true);

  if (!date.isValid()) {
    return 'A data deve estar no formato DD/MM/YYYY.';
  }

  let initialDate = null;
  if (initial) {
    initialDate = moment(initial, 'DD/MM/YYYY', true);
  } else {
    initialDate = moment();
  }

  const formattedDate = initialDate.format('DD/MM/YYYY');

  if (date.isBefore(moment(initialDate))) {
    return `A data deve ser posterior a ${formattedDate}.`;
  }
  return undefined;
};

export const validateDateBefore = (final) => (value) => {
  const date = moment(value, 'DD/MM/YYYY', true);

  if (!date.isValid()) {
    return 'A data deve estar no formato DD/MM/YYYY.';
  }

  if (moment().diff(date, 'years') >= 2) {
    return 'A data não pode ser mais antiga que 2 anos';
  }

  let finalDate = null;
  if (final) {
    finalDate = moment(final, 'DD/MM/YYYY', true);
  } else {
    finalDate = moment();
  }

  const formattedDate = finalDate.format('DD/MM/YYYY');

  if (date.isAfter(moment(finalDate))) {
    return `A data deve ser anterior a ${formattedDate}.`;
  }
  return undefined;
};

export const validateTimeInterval = (initial, final) => (value) => {
  const time = moment(value, 'HH:mm', true);
  if (!time.isValid()) {
    return 'O horário deve estar no formato HH:mm.';
  }
  const initialTime = moment(initial, 'HH:mm', true);
  const finalTime = moment(final, 'HH:mm', true);
  if (!time.isBetween(initialTime, finalTime, 'minute', '[]')) {
    const formattedInitialTime = initialTime.format('HH:mm');
    const formattedFinalTime = finalTime.format('HH:mm');
    return `A hora deve ser entre
      ${formattedInitialTime} e ${formattedFinalTime}.`;
  }
  return undefined;
};

export const validateStockCode = async (stockCode, dateString) => {
  let stockCodeError;
  let dateError;
  let expirationDateString;
  let trading_lot_size;
  try {
    const { data: [stockCodeData] } = await api.stock.getStocks({
      params: {
        stock_code: stockCode,
        market_name: 'BOVESPA',
        attributes: 'expiration_date',
      },
    });
    if (stockCodeData.stock_code !== stockCode) {
      stockCodeError = 'Ativo Inválido. Utilize o código completo.';
    } else if (stockCodeData.expiration_date) {
      const expirationDateObject = moment(
        stockCodeData.expiration_date,
        'YYYY-MM-DD HH:mm:ss',
        true,
      );
      const date = moment(dateString, 'DD/MM/YYYY', true);
      if (date.isValid()) {
        if (date.isAfter(expirationDateObject, 'day')) {
          expirationDateString = expirationDateObject.format('DD/MM/YYYY');
          dateError = `A data deve ser anterior a ${expirationDateString}
            para esse ativo.`;
        }
      }
    }
    trading_lot_size = stockCodeData.trading_lot_size;
  } catch (error) {
    stockCodeError = 'Ativo inválido.';
  }
  return ({
    trading_lot_size,
    stockCodeError,
    dateError,
    expirationDate: expirationDateString,
  });
};

export const createValidator = (rules) => {
  const errors = {};
  Object.keys(rules).forEach((fieldName) => {
    errors[fieldName] = rules[fieldName];
  });
  return errors;
};

export const validateCEP = (value) => {
  const onlyValue = onlyNumbers(value);

  if (onlyValue.length !== 8) return 'Digite um CEP que seja válido.';

  return undefined;
};

export const validatePhonecall = (value) => {
  const onlyValue = onlyNumbers(value);

  if (onlyValue.length < 10) return 'Digite um Telefone que seja válido.';

  return undefined;
};

export const maxLength = memoize((max) => (value) => (value && value.length > max ? `Deve ter no máximo ${max} caracteres.` : undefined));

export const minLength = memoize((min) => (value) => (value && value.length < min ? `Deve ter no mínimo ${min} caracteres.` : undefined));

export const validateCvv = (value) => {
  const onlyValue = onlyNumbers(value);

  if (onlyValue.length < 3 || onlyValue.length > 4) {
    return 'Digite o CVV que seja válido.';
  }
  return undefined;
};

export const validateLogin = (login) => {
  if (!/^([A-Za-z0-9])([\w@.çÇ]*)$/.test(login)) {
    return 'O login deve começar com letra ou número. Você pode usar números, ponto final, arroba e underline.';
  }

  return minLength(3)(login) || maxLength(30)(login);
};

export const validateExpiry = (value) => {
  const onlyValue = onlyNumbers(value);

  if (onlyValue.length < 6 || onlyValue.length > 6) {
    return 'Digite uma data de expiração que seja válida.';
  }

  const month = onlyValue.slice(0, 2);
  const year = onlyValue.slice(2);

  if (parseInt(month, 10) > 12) {
    return 'Digite um mês de expiração que seja válida.';
  }

  const dataExpiry = new Date(year, month);
  const dataActual = new Date();

  dataExpiry.setMonth(dataExpiry.getMonth() - 1);
  dataExpiry.setMonth(dataExpiry.getMonth() + 1, 1);

  if (dataActual > dataExpiry) {
    return 'Digite uma data de expiração que seja válida.';
  }

  return undefined;
};

export const validateCreditCard = (card) => (value) => {
  const {
    issuer, maxLength: cardMaxLength,
  } = card;

  const onlyValue = onlyNumbers(value);

  if (onlyValue.length < 10) {
    return 'Deve ter no mínimo 10 caracteres.';
  }

  if (onlyValue.length > cardMaxLength) {
    return `Deve ter no máximo ${cardMaxLength} caracteres.`;
  }
  if (issuer === 'unknown') {
    return 'Digite um cartão com uma bandeira válida.';
  }

  return undefined;
};

export const extraCreditCardValidation = (card, value) => {
  const { issuer, maxLength: cardMaxLength } = card;

  const onlyValue = onlyNumbers(value);

  if (onlyValue.length >= 10 && onlyValue.length <= cardMaxLength && issuer !== 'unknown') {
    return true;
  }

  return false;
};

export const maxValue = (max, currency = false) => (value) => (value && value > max
  ? `O valor máximo permitido é ${currency ? currencyMask().format(max) : numberMask().format(max)}.`
  : undefined);

export const minValue = (min, currency = false) => (value) => {
  return value !== undefined && value !== null && value < min
    ? `O valor mínimo permitido é ${currency ? currencyMask().format(min) : numberMask().format(min)}.`
    : undefined;
};

export const confirmEqual = (prop) => (value, allValues) => (
  value !== allValues[prop] && allValues[prop]
    ? 'Os valores não correspondem.'
    : undefined
);

export const validateNumber = (value, decimalSeparator = '.') => (
  (new RegExp(`([^0-9${decimalSeparator}])`, 'g')).test(value)
    ? 'Digite um número válido.'
    : undefined
);

// Specific fields validation
export const instanceNameValidation = [required, maxLength(30), fieldRestrictions];

// Validate fields from strategies
export const isDivisibleByFive = (num) => (num % 5 === 0);

export const hasValuesPositive = (arr) => arr.filter((index) => index < 1);

export const parseStrToArr = (str) => str.split(',').map((index) => parseInt(index, 10));

export const validateListStocks = (numStocks) => {
  const validateStock = hasValuesPositive(parseStrToArr(numStocks));
  if (isEmptyArr(validateStock)) {
    return false;
  }
  return true;
};

export const countNumberOfStocks = (numStocks) => parseStrToArr(numStocks).reduce(
  (prev, curr) => prev + curr, 0,
);

export const validateListPoints = (numStocks) => {
  const arrOrders = parseStrToArr(numStocks);
  for (let i = 0; i < arrOrders.length; i += 1) {
    if (arrOrders[i] <= 0 || (arrOrders[i] > arrOrders[i + 1])) {
      return true;
    }
  }
  return false;
};

export const validateFullContracts = (numStocks) => {
  const arrOrders = parseStrToArr(numStocks);

  return !arrOrders.every((num) => isDivisibleByFive(num));
};

export const isLoginError = (error) => LOGIN_CODE_ERROR.includes(error.code);

export const isInvestment = (type) => type === '0';

export const isVariantInstances = (variant) => variant === VARIANT_INSTANCES;

export const isEven = (number) => number % 2 === 0;

export const getMatchSubscriptionPlan = (planCode) => {
  const plan = planCode.match(/smarttinvest_\d+/) ?? [planCode];

  return plan[0];
};

export const isSamePlanCode = (planA, planB) => {
  const storeItemPlan = getMatchSubscriptionPlan(planA);
  const subscriptionPlan = getMatchSubscriptionPlan(planB);

  return storeItemPlan.toLowerCase() === subscriptionPlan.toLowerCase();
};

export const validateMinimumInvestment = (value, { storeInstance }) => {
  return value < storeInstance.minimum
    ? `O investimento inicial mínimo para essa carteira é ${formatMoney(storeInstance.minimum * 100, 2)}`
    : undefined;
};

export const validateLimitInvestment = (value, { storeInstance }) => {
  return value > storeInstance.planLimit
    ? `O limite do seu plano para essa carteira é ${formatMoney(storeInstance.planLimit * 100, 2)}`
    : undefined;
};

export const validateSpecialCharacterPassword = (value) => {
  return !(
    /[áàâãÁÀÂÃéèêẽÉÈÊẼíìîĩÍÌÎĨóòôõöÓÒÔÕÖúùũûüÚÙÛŨÜńǹñŃǸÑýỳŷỹÿÝỲỸŶŸśŝŚŜǵĝǴĜĥḧĤḦĵĴḱḰĺĹḿḾṕẃẁŵŴẀẂŕŔẗṔḉḈĉĈǘǜǗǛṽṼ#^()+~=`:";<>,./]/
      .test(value));
};

export const getErrorContributionValue = ({ value, equity, storeInstance }) => {
  const totalEquity = parseFloat(value) + parseFloat(equity);

  const minError = validateMinimumInvestment(totalEquity, { storeInstance });
  const limitError = validateLimitInvestment(totalEquity, { storeInstance });

  const error = minError || limitError;

  return error;
};

export const checkContributionHasError = (value, allValues,
  { storeInstance, equity }) => {
  const error = getErrorContributionValue({ value, equity, storeInstance });

  return !!error;
};

export const isBuyOrderType = (value) => value === ORDER_TYPE.buy.id;
