import React from 'react';
import PropTypes from 'prop-types';
import { Box, Typography } from '@material-ui/core';
import ChangedStocksListing from 'common/components/ChangedStocksListing';
import WarningIcon from '@material-ui/icons/Warning';
import Link from 'common/components/Link';
import { SI_ZENDESK_ARTICLES_URL } from 'common/utils/constants';
import { REBALANCE_CHANGED_STOCKS_VARIANTS } from 'common/utils/constants/stocks';

const ChangedStocksVolume = ({
  classes,
  stocks,
  oldStocks,
  report,
  transactionValue,
  isInvestment,
}) => {
  const newEquity = isInvestment
    ? report.equity + Number(transactionValue)
    : report.equity - Number(transactionValue);

  return (
    <>
      <Typography variant="h3" className={classes.title}>
        Mudanças na carteira
        <WarningIcon className={classes.warnIcon} />
      </Typography>

      <Box mb={2}>
        <Typography className={classes.text} variant="h6">
          Sua carteira apresenta uma diferença entre os pesos dos ativos atuais e
          da sua lista teórica. Essa movimentação ira acarretar em um
          rebalanceamento que fará com que sua carteira se aproxime dos valores
          apresentados na coluna &quot;Teórica&quot;. Caso não esteja de acordo
          com as mudanças, faça as alterações necessárias na aba “Configurações”
          da sua carteira.
        </Typography>
      </Box>

      <Box mb={1}>
        <ChangedStocksListing
          stocks={stocks}
          oldStocks={oldStocks}
          variants={REBALANCE_CHANGED_STOCKS_VARIANTS}
          leftLabel={{
            text: 'Atual',
            tooltip: (
              <Box>
                A carteira atual representa a composição de ativos
                (e seus respectivos pesos) existentes na sua carteira.
                {' '}
                <Link
                  color="white"
                  fontSize={14}
                  path={`${SI_ZENDESK_ARTICLES_URL}/4403743731351`}
                  target="_blank"
                  underlineDecoration
                >
                  Saiba mais
                </Link>
              </Box>
            ),
          }}
          rightLabel={{
            text: 'Teórica',
            tooltip: (
              <Box>
                A carteira teórica representa a composição de ativos (e seus
                respectivos pesos) definidos por você na aba &quot;Configurações&quot;.
                {' '}
                <Link
                  color="white"
                  fontSize={14}
                  path={`${SI_ZENDESK_ARTICLES_URL}/4403743731351`}
                  target="_blank"
                  underlineDecoration
                >
                  Saiba mais
                </Link>
              </Box>
            ),
          }}
          equity={report.equity}
          newEquity={newEquity}
          reverseColumns
          isCurrencyMode
        />
      </Box>

      <Typography className={classes.text} variant="h6">
        É importante ressaltar que os volumes indicados são apenas uma simulação
        considerando a cotação atual dos ativos.
        O volume final real pode variar de acordo com a cotação do momento de
        confirmação da movimentação.
      </Typography>
    </>
  );
};

ChangedStocksVolume.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  stocks: PropTypes.object.isRequired,
  oldStocks: PropTypes.object.isRequired,
  report: PropTypes.object.isRequired,
  isInvestment: PropTypes.bool.isRequired,
  transactionValue: PropTypes.number,
};

ChangedStocksVolume.defaultProps = {
  transactionValue: 0,
};

export default ChangedStocksVolume;
