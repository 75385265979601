import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

// Material UI
import { withStyles } from '@material-ui/styles';
import { TextField } from '@material-ui/core';

const styles = {
  field: {
    marginBottom: 0,
  },
};

const InputTextField = ({
  classes,
  input,
  meta: { error, touched },
  helperText,
  label,
  type,
  fullWidth,
  className,
  ...custom
}) => (
  <TextField
    {...custom}
    margin="normal"
    className={classNames([className, classes.field])}
    fullWidth={fullWidth}
    type={type}
    error={!!touched && Boolean(error)}
    {...input}
    label={label}
    helperText={helperText || (
      touched && (
        error || helperText
      )
    )}
  />
);

InputTextField.propTypes = {
  classes: PropTypes.object.isRequired,
  type: PropTypes.string,
  input: PropTypes.object,
  custom: PropTypes.object,
  meta: PropTypes.object,
  className: PropTypes.string,
  helperText: PropTypes.string,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  fullWidth: PropTypes.bool,
};
InputTextField.defaultProps = {
  input: {},
  custom: {},
  meta: {},
  type: 'text',
  helperText: '',
  label: '',
  className: '',
  fullWidth: true,
};

export default withStyles(styles)(InputTextField);
