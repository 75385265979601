const billingErrorInterpreter = (code) => {
  switch (code) {
    case 'billing0':
      return 'Documento é exigido.';
    case 'billing1':
      return 'Nome do plano inválido.';
    case 'billing2':
      return 'Você já é assinante deste plano.';
    case 'billing3':
      return 'Existe uma mudança de plano pendente em sua conta. Pague a fatura referente à solicitação de mudança de plano anterior ou entre em contato se quiser fazer esta nova alteração.';
    case 'billing4':
      return 'Código de desconto inválido.';
    case 'billing5':
      return 'Seu pagamento não pôde ser processado.';
    case 'billing6':
      return 'Seu pagamento não pôde ser processado.';
    case 'billing7':
      return 'Seu pagamento não pôde ser processado.';
    case 'billing8':
      return 'Seu pagamento não pôde ser processado.';
    case 'billing9':
      return 'Seu pagamento não pôde ser processado.';
    default:
      return null;
  }
};

export default billingErrorInterpreter;
