import { withStyles } from '@material-ui/styles';

import PublicPagesBody from './PublicPagesBody';

const styles = {
  wrapper: {
    display: 'flex',
    minHeight: '100vh',
    '@media screen and (max-width: 768px)': {
      flexDirection: 'column',
      backgroundColor: '#1b1e22',
    },
  },
  left: {
    width: '45%',
    backgroundColor: '#1b1e22',
    borderRadius: '0 10px 10px 0',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    '@media screen and (max-width: 768px)': {
      width: '100%',
      minHeight: 229,
    },
  },
  logo: {
    width: 280,
    '@media screen and (max-width: 768px)': {
      width: 213,
    },
  },
  text: {
    fontSize: 15,
    color: '#fff',
  },
  formsWrapper: {
    padding: '121px 0 0 78px',
    overflow: 'auto',
    flex: 1,
    '@media screen and (max-width: 768px)': {
      borderRadius: '25px 25px 0 0',
      backgroundColor: '#fff',
      padding: '43px 32px',
      flex: 1,
    },
  },
  title: {
    fontSize: 27,
    fontWeight: 'bold',
    color: '#363636',
    marginBottom: 10,
  },
  subtitle: {
    fontSize: 15,
    color: '#363636',
    marginBottom: 10,
  },
};

export default withStyles(styles)(PublicPagesBody);
