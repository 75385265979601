import moment from 'moment';
import logdown from 'logdown';

import categoriesJson from 'common/assets/mocks/store-categories.json';
import { LOGOUT_SUCCEED } from 'common/ducks/auth';
import { reduceObjectsArrayToObject } from 'common/utils/array';

export const DUCK_NAME = 'categories';
const logger = logdown(DUCK_NAME);

export const INITIAL_STATE = {
  lastUpdateDatetime: null,
  loading: false,
  data: {
    categories: {},
  },
  error: null,
};

export const LOAD_CATEGORIES_STARTED = `${DUCK_NAME}/LOAD_CATEGORIES_STARTED`;
export const LOAD_CATEGORIES_SUCCEED = `${DUCK_NAME}/LOAD_CATEGORIES_SUCCEED`;
export const LOAD_CATEGORIES_FAILED = `${DUCK_NAME}/LOAD_CATEGORIES_FAILED`;

export const loadCategoriesStarted = () => ({ type: LOAD_CATEGORIES_STARTED });
export const loadCategoriesSucceed = (data) => ({ type: LOAD_CATEGORIES_SUCCEED, data });
export const loadCategoriesFailed = (error) => ({ type: LOAD_CATEGORIES_FAILED, error });

export const loadCategories = (options = { reload: false }) => async (dispatch, getState) => {
  const state = getState()[DUCK_NAME];

  if (
    !options.reload
      && !state.error
      && !state.loading
      && Object.keys(state.data.categories).length > 0
  ) {
    return Promise.resolve(Object.values(state.data.categories));
  }
  dispatch(loadCategoriesStarted());

  try {
    const categories = categoriesJson;

    dispatch(loadCategoriesSucceed({ categories }));
    return Promise.resolve(categories);
  } catch (error) {
    logger.error('Could not get store.', error);
    dispatch(loadCategoriesFailed(error));
    return Promise.reject(error);
  }
};

const reducer = (state = INITIAL_STATE, action) => {
  const { type, error } = action;

  if (type === LOGOUT_SUCCEED) {
    return INITIAL_STATE;
  }

  switch (action.type) {
    case LOAD_CATEGORIES_STARTED:
      return {
        ...state,
        loading: true,
      };
    case LOAD_CATEGORIES_SUCCEED:
      return {
        ...state,
        loading: false,
        error: null,
        lastUpdateDatetime: moment().toISOString(),
        data: {
          ...state.data,
          categories: {
            ...reduceObjectsArrayToObject(action.data.categories, 'id'),
          },
        },
      };
    case LOAD_CATEGORIES_FAILED:
      return {
        ...state,
        error,
        loading: false,
      };
    default: return state;
  }
};

export default reducer;
