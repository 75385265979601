import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import { withMobileDialog } from '@material-ui/core';
import { insertBrokerage } from 'common/ducks/brokerages';
import palette from 'common/palette';
import { openDialog } from 'common/ducks/dialogs';
import { updateRegisterSteps } from 'common/ducks/registerSteps';
import RegisterBrokerageDialog from './RegisterBrokerageDialog';
import { CONFIRMATION_DIALOG_CODE } from '../ConfirmationDialog';

export const REGISTER_BROKERAGE_DIALOG_CODE = 'REGISTER_BROKERAGE_DIALOG_CODE';

const styles = (theme) => ({
  mainColor: {
    color: theme.palette.primary.main,
  },
  defaultColor: {
    color: theme.palette.secondary.main,
  },
  stepIcon: {
    color: theme.palette.primary.main,
    width: '1.4em !important',
  },
  itemButtonBase: {
    display: 'block',
    width: '100%',
  },
  stepsList: {
    width: 310,
    margin: '0 auto',
    marginBottom: 100,
    padding: 'unset',
  },
  dialogTitle: {
    textAlign: 'center',
  },
  counter: {
    width: '100%',
    textAlign: 'center',
  },
  topOffset: {
    marginTop: 20,
  },
  dialogPaper: {
    width: 581,
  },
  dialogContent: {
    '&:first-child': {
      paddingTop: 34,
    },
  },
  confirmButton: {
    fontWeight: 600,
    display: 'inline-block',
  },
  cancelButton: {
    marginRight: theme.spacing(1),
  },
  closeIcon: {
    position: 'absolute',
    right: '5px',
    top: '5px',
  },
  helpLink: {
    fontSize: 12,
    color: palette.links.blue,
    fontFamily: 'Roboto',
    fontWeight: 500,
  },
  footer: {
    marginTop: 20,
    float: 'right',
  },
  subtitle: {
    fontSize: 16,
    fontWeight: '500',
    lineHeight: 1.5,
  },
});

const mapStateToProps = ({ brokerages }) => ({
  brokerages,
});
const mapDispatchToProps = (dispatch, { registerSteps }) => ({
  insertBrokerage: (data) => dispatch(insertBrokerage(data)),
  openConfirmationDialog: (props) => dispatch(openDialog(
    CONFIRMATION_DIALOG_CODE,
    { ...props },
  )),
  openRegisterBrokerageDialog: (onConfirmSubmit, brokerageData) => dispatch(openDialog(
    REGISTER_BROKERAGE_DIALOG_CODE,
    {
      onConfirmSubmit,
      brokerageData,
    },
  )),
  updateStatusRegisterStep: (status, type) => {
    dispatch(updateRegisterSteps({
      ...registerSteps,
      [type]: {
        status,
      },
    }));
  },
});

export default
connect(
  mapStateToProps,
  mapDispatchToProps,
)(withStyles(styles)(withMobileDialog()(RegisterBrokerageDialog)));
