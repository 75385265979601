import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import classNames from 'classnames';
import {
  Box,
  Button,
  FormControl,
  Switch,
  Typography,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';

import palette from 'common/palette';
import iconHelp from 'common/assets/images/help-icon.svg';
import ArrowTooltip from 'common/components/ArrowTooltip';
import { validateOrderValueRange, getNumberOfStocksError } from 'common/utils/instance';

import { ALERT_NUMBER_OF_STOCKS_LOT_SIZE_MSG, ORDER_TYPE, STEPS_MANUAL_OPERATION_INITIAL_STATE } from 'common/utils/constants/orders';
import ButtonGroupField from 'common/components/ReduxForm/ButtonGroupField';
import StockCard from 'common/components/StockCard';
import CurrencyField from 'common/components/Forms/CurrencyField';

import { useGetPosition } from 'common/hooks/positions';
import { useStockPrice } from 'common/hooks/stocks';
import { isNegativeValue, onlyNumbers } from 'common/utils/string';
import {
  getFormattedValue,
  orderFormatters,
  getNumberStocksToBeAdded,
} from 'common/utils/orders';
import { NUMBER_OF_STOCKS } from 'common/utils/constants/tags';
import {
  ALERT_DEFAULT_LOT,
  DECREASE,
  ERROR_GET_LAST_TRADE_STOCK_MSG,
  INCREASE,
  RESERVED_BALANCE_INSTANCE,
  TOTAL_ORDER_VOLUME,
  STOCK_MODES,
} from 'common/utils/constants';
import { DEFAULT_LOT_SIZE } from 'common/utils/constants/stocks';
import { getAvailableBalance } from 'common/utils/positions';
import { InputTextField } from '../ReduxForm';
import PositionResult from '../PositionResult';
import DisplayNumber from '../DisplayNumber';
import Placeholder from '../Placeholder';

const StockOperationDetails = ({
  classes,
  instanceId,
  stock,
  report,
  positions,
  dataSteps,
  setDataSteps,
  notifyWarning,
  onBackButtonClick,
  onSubmit,
}) => {
  const firstPriceRender = useRef(true);
  const [price, setPrice] = useStockPrice();
  const [numberOfStocks, setNumberOfStocks] = useState(
    dataSteps?.payload.numberOfStocks
    || stock?.trading_lot_size
    || DEFAULT_LOT_SIZE,
  );
  const storedPriceValue = dataSteps?.payload.value || price.value;
  const [customOrderValue, setCustomOrderValue] = useState(storedPriceValue);
  const orderValue = customOrderValue || storedPriceValue;

  const [bestAvailablePriceToggled, setBestAvailablePriceToggled] = useState(
    dataSteps.payload.isMarketOrder === 1,
  );
  const stockCode = stock.code || stock.stock_code;
  const position = useGetPosition({ instanceId, stockCode });
  const balance = getAvailableBalance(positions[instanceId].totalFinancialVol, report.equity);
  const finalBasePrice = bestAvailablePriceToggled
    ? price.value
    : customOrderValue;
  const totalOrderVolume = Number(finalBasePrice) * numberOfStocks;
  const orderTypeValue = dataSteps.payload.orderType;

  useEffect(() => {
    if (firstPriceRender.current && price.value && !dataSteps.payload.value) {
      const hasError = getNumberOfStocksError({
        position,
        orderType: orderTypeValue,
        totalOrderVolume,
        balance,
        numberOfStocks,
      });

      setDataSteps({
        ...dataSteps,
        payload: {
          ...dataSteps.payload,
          value: price.value,
        },
        error: !!hasError,
      });

      if (firstPriceRender.current) {
        firstPriceRender.current = false;
      }
    }
  }, [
    numberOfStocks,
    position,
    orderTypeValue,
    totalOrderVolume,
    balance,
    price.value,
    setDataSteps,
    dataSteps,
  ]);

  const getValueNumberOfStocksField = (event, type) => {
    if (!type) {
      return event.target.value === ''
        ? ''
        : Number(onlyNumbers(event.target.value));
    }

    const numberStocks = Number(numberOfStocks);
    const valueToBeAdded = getNumberStocksToBeAdded({ numberOfStocks, stock, type });

    return type === INCREASE
      ? numberStocks + valueToBeAdded
      : numberStocks - valueToBeAdded;
  };

  const handleChangeNumberOfStocks = ({ event, type, setFieldValue }) => {
    const value = getValueNumberOfStocksField(event, type);

    if (isNegativeValue(value) || value === 0 || value > 1000000) return '';
    const formattedValue = getFormattedValue(value, NUMBER_OF_STOCKS);

    setFieldValue('numberOfStocks', formattedValue);
    return setNumberOfStocks(formattedValue);
  };

  const onBlurNumberOfStocks = () => {
    const { trading_lot_size: tradingLotSize } = stock;

    if (Number(numberOfStocks) > tradingLotSize && Number(numberOfStocks) % tradingLotSize !== 0) {
      const newValue = Math.round(numberOfStocks / tradingLotSize) * tradingLotSize;
      setNumberOfStocks(newValue);
      notifyWarning({
        title: ALERT_DEFAULT_LOT,
        message: ALERT_NUMBER_OF_STOCKS_LOT_SIZE_MSG,
      });
    }
  };

  return (
    <Box>
      <Box mb={2}>
        <StockCard
          key={stockCode}
          mode={STOCK_MODES.VIEW}
          stock={stock}
          customRightComponent={(
            <Box display="flex">
              <Box className={classes.numberOfStocksBox} data-testid="number-stock-box">
                <Typography className={classes.numberOfStocks}>Qnt. atual</Typography>
                <Typography variant="h4">
                  <b>
                    {position
                      ? position.number_of_stocks
                      : 0}
                  </b>
                </Typography>
              </Box>
              <Box
                className={classes.closeBox}
                data-testid="close-box"
                onClick={() => onBackButtonClick({
                  step: 1,
                  dataStepsParam: STEPS_MANUAL_OPERATION_INITIAL_STATE,
                })}
              >
                <CloseIcon className={classes.closeIcon} />
              </Box>
            </Box>
          )}
        />
      </Box>

      <Box>
        <Box mb={2}>
          <Typography variant="h6" color="textSecondary" component="div">
            <Box fontWeight={400}>Último negócio</Box>
          </Typography>

          <PositionResult
            id={instanceId}
            position={stock}
            onChangePositionPrice={setPrice}
          />
        </Box>

        <Formik
          validateOnChange
          validate={(values) => {
            const errors = validateOrderValueRange({
              values,
              currentStockPrice: price.value,
              position,
              orderType: orderTypeValue,
              totalOrderVolume,
              balance,
            });

            setDataSteps({
              ...dataSteps,
              error: !!Object.keys(errors).length,
            });

            return errors;
          }}
          initialValues={{
            value: orderValue,
            numberOfStocks,
          }}
          onSubmit={(values) => {
            const finalValues = {
              ...values,
              bestAvailablePriceToggled,
              totalOrderVolume,
              value: finalBasePrice,
            };
            onSubmit(finalValues);
          }}
          enableReinitialize
        >
          {({
            handleBlur,
            handleChange,
            handleSubmit,
            values,
            errors,
            touched,
            setFieldValue,
            setErrors,
          }) => {
            return (
              <form
                id="manual-operation-form"
                onSubmit={(e) => {
                  e.preventDefault();
                  handleSubmit();
                }}
              >
                <Box mt={2}>
                  <FormControl fullWidth>
                    <ButtonGroupField
                      input={{
                        value: orderTypeValue,
                        onChange: (value) => {
                          const allErrors = validateOrderValueRange({
                            values,
                            orderValue: dataSteps?.payload.value,
                            position,
                            orderType: value,
                            totalOrderVolume,
                            balance,
                          });

                          setErrors({ ...errors, numberOfStocks: '' });
                          setDataSteps({
                            ...dataSteps,
                            payload: {
                              ...dataSteps.payload,
                              orderType: value,
                            },
                            error: !!Object.keys(allErrors).length,
                          });
                        },
                        color: ORDER_TYPE[orderTypeValue].color,
                      }}
                      label="Compra/Venda"
                    >
                      {Object.values(ORDER_TYPE).map((item) => (
                        <Button
                          className={classes.button}
                          key={item.id}
                          value={item.id}
                        >
                          {item.name}
                        </Button>
                      ))}
                    </ButtonGroupField>
                  </FormControl>
                </Box>
                <Box mb={2} display="flex" alignItems="baseline" maxWidth={155} position="relative">
                  <InputTextField
                    inputProps={{ id: NUMBER_OF_STOCKS }}
                    label="Quantidade"
                    value={orderFormatters.number_of_stocks(values.numberOfStocks)}
                    onChange={(event) => handleChangeNumberOfStocks({ event, setFieldValue })}
                    onBlur={onBlurNumberOfStocks}
                    onKeyUp={(event) => {
                      if ([38, 40].includes(event.keyCode)) {
                        event.preventDefault();
                        const param = event.keyCode === 38
                          ? INCREASE
                          : DECREASE;
                        handleChangeNumberOfStocks({ event, type: param, setFieldValue });
                      }
                    }}
                    meta={{
                      error: getNumberOfStocksError({
                        position,
                        orderType: orderTypeValue,
                        totalOrderVolume,
                        balance,
                        numberOfStocks,
                      }) || errors.numberOfStocks,
                      touched: true,
                    }}
                    FormHelperTextProps={{ classes: { filled: classes.errorNumberOfStocks } }}
                  />
                  <Box marginLeft={-3} zIndex={1}>
                    <Box
                      data-testid="increase-arrow"
                      onClick={(event) => handleChangeNumberOfStocks({
                        event,
                        type: INCREASE,
                        setFieldValue,
                      })}
                      className={classNames(
                        classes.triangle,
                        classes.triangleTop,
                      )}
                    />
                    <Box
                      data-testid="decrease-arrow"
                      onClick={(event) => handleChangeNumberOfStocks({
                        event,
                        type: DECREASE,
                        setFieldValue,
                      })}
                      className={classNames(
                        classes.triangle,
                        classes.triangleBottom,
                      )}
                    />
                  </Box>
                </Box>

                <Box mb={2}>
                  <Box display="flex" alignItems="center">
                    <Typography variant="h6" color="textSecondary" component="div">
                      <Box fontWeight={400}>Buscar o melhor preço disponível</Box>
                    </Typography>
                    <Box ml={1}>
                      <ArrowTooltip
                        title={(
                          <div>
                            A ordem buscará a execução no melhor preço disponível
                            no livro de ofertas (ordem à mercado).
                          </div>
                        )}
                        disableFocusListener
                        disableTouchListener
                        interactive
                      >
                        <img src={iconHelp} alt="tooltip" data-testid="order-price-tooltip" />
                      </ArrowTooltip>
                    </Box>
                  </Box>
                  <Switch
                    checked={bestAvailablePriceToggled}
                    color="primary"
                    onClick={() => {
                      const newToggleValue = !bestAvailablePriceToggled;

                      const isMarketOrder = newToggleValue ? 1 : 0;
                      if (bestAvailablePriceToggled) {
                        const updatedCustomOrderValue = price.value
                          ? price.value
                          : dataSteps?.payload.value;

                        setCustomOrderValue(updatedCustomOrderValue);
                        setDataSteps({
                          ...dataSteps,
                          payload: {
                            ...dataSteps.payload,
                            isMarketOrder,
                            value: updatedCustomOrderValue,
                          },
                        });
                      } else {
                        const objErrors = validateOrderValueRange({
                          values: { value: storedPriceValue, numberOfStocks },
                          currentStockPrice: price.value,
                          position,
                          orderType: orderTypeValue,
                          totalOrderVolume: Number(price.value) * Number(numberOfStocks),
                          balance,
                        });

                        setCustomOrderValue(0);
                        setDataSteps({
                          ...dataSteps,
                          payload: {
                            ...dataSteps.payload,
                            isMarketOrder,
                          },
                          error: !!Object.keys(objErrors).length,
                        });
                      }

                      setBestAvailablePriceToggled(newToggleValue);
                    }}
                    data-testid="order-price-switch"
                  />
                  {!bestAvailablePriceToggled && (
                    <Box>
                      <Box>
                        <Box mb={1} maxWidth={155} position="relative">
                          <CurrencyField
                            meta={{
                              errors,
                              touched,
                            }}
                            label="Preço de envio"
                            handleBlur={handleBlur}
                            handleChange={(e) => {
                              handleChange(e);
                              setCustomOrderValue(Number(e.target.value));
                            }}
                            values={values}
                            helperTextProps={{ classes: { root: classes.helperText } }}
                          />
                        </Box>
                      </Box>
                    </Box>
                  )}
                </Box>

              </form>
            );
          }}
        </Formik>

        {!price.error && price.value === 0
          ? <Placeholder height={148} />
          : (
            <Box className={classes.valuesBox}>
              <Box mb={2}>
                <Typography color="textSecondary" variant="h6">
                  {TOTAL_ORDER_VOLUME}
                </Typography>
                {!price.error
                  ? (
                    <DisplayNumber
                      currency
                      colorful={false}
                      color="textPrimaryBold"
                      value={totalOrderVolume}
                      scale={1.25}
                      symbolScale={1}
                      valueScale={1}
                    />
                  )
                  : (
                    <Typography component="div" variant="h6">
                      <Box fontWeight="bold" color={palette.secondary.main}>
                        {ERROR_GET_LAST_TRADE_STOCK_MSG}
                      </Box>
                    </Typography>
                  )}
              </Box>

              <Box>
                <Typography color="textSecondary" variant="h6">
                  {RESERVED_BALANCE_INSTANCE}
                </Typography>
                <DisplayNumber
                  currency
                  colorful={false}
                  color="textPrimaryBold"
                  value={balance}
                  scale={1.25}
                  symbolScale={1}
                  valueScale={1}
                />
              </Box>
            </Box>
          )}
      </Box>
    </Box>
  );
};

StockOperationDetails.propTypes = {
  classes: PropTypes.object.isRequired,
  instanceId: PropTypes.number.isRequired,
  stock: PropTypes.object,
  report: PropTypes.object.isRequired,
  positions: PropTypes.object.isRequired,
  dataSteps: PropTypes.object.isRequired,
  setDataSteps: PropTypes.func.isRequired,
  notifyWarning: PropTypes.func.isRequired,
  onBackButtonClick: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

StockOperationDetails.defaultProps = {
  stock: {},
};

export default StockOperationDetails;
