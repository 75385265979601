import { LOGOUT_SUCCEED } from '../auth';

import profileReducer, {
  DUCK_NAME as PROFILE_DUCK_NAME,
  NAMESPACE as PROFILE_NAMESPACE,
  INITIAL_STATE as PROFILE_INITIAL_STATE,
} from './profile';

import tabsReducer, {
  DUCK_NAME as TABS_DUCK_NAME,
  NAMESPACE as TABS_NAMESPACE,
  INITIAL_STATE as TABS_INITIAL_STATE,
} from './tabs';

import subscriptionsReducer, {
  DUCK_NAME as SUBSCRIPTIONS_DUCK_NAME,
  NAMESPACE as SUBSCRIPTIONS_NAMESPACE,
  INITIAL_STATE as SUBSCRIPTIONS_INITIAL_STATE,
} from './subscriptions';

import setupsReducer, {
  DUCK_NAME as SETUPS_DUCK_NAME,
  NAMESPACE as SETUPS_NAMESPACE,
  INITIAL_STATE as SETUPS_INITIAL_STATE,
} from './setups';

import paymentMethodsReducer, {
  DUCK_NAME as PAYMENT_METHODS_DUCK_NAME,
  NAMESPACE as PAYMENT_METHODS_NAMESPACE,
  INITIAL_STATE as PAYMENT_METHODS_INITIAL_STATE,
} from './paymentMethods';

import accessReducer, {
  DUCK_NAME as ACCESS_DUCK_NAME,
  NAMESPACE as ACCESS_NAMESPACE,
  INITIAL_STATE as ACCESS_INITIAL_STATE,
} from './access';

import recurrencyReducer, {
  DUCK_NAME as RECURRENCY_DUCK_NAME,
  NAMESPACE as RECURRENCY_NAMESPACE,
  INITIAL_STATE as RECURRENCY_INITIAL_STATE,
} from './recurrency';

import statsReducer, {
  DUCK_NAME as STATS_DUCK_NAME,
  NAMESPACE as STATS_NAMESPACE,
  INITIAL_STATE as STATS_INITIAL_STATE,
} from './stats';

export const DUCK_NAME = 'user';

export const INITIAL_STATE = {
  [PROFILE_DUCK_NAME]: PROFILE_INITIAL_STATE,
  [TABS_DUCK_NAME]: TABS_INITIAL_STATE,
  [SUBSCRIPTIONS_DUCK_NAME]: SUBSCRIPTIONS_INITIAL_STATE,
  [SETUPS_DUCK_NAME]: SETUPS_INITIAL_STATE,
  [PAYMENT_METHODS_DUCK_NAME]: PAYMENT_METHODS_INITIAL_STATE,
  [ACCESS_INITIAL_STATE]: ACCESS_INITIAL_STATE,
  [RECURRENCY_DUCK_NAME]: RECURRENCY_INITIAL_STATE,
  [STATS_DUCK_NAME]: STATS_INITIAL_STATE,
};

// Reducer
const reducer = (state = INITIAL_STATE, action) => {
  // Logout cleaning
  if (action.type === LOGOUT_SUCCEED) {
    return INITIAL_STATE;
  }

  switch (true) {
    case new RegExp(PROFILE_NAMESPACE).test(action.type):
      return {
        ...state,
        [PROFILE_DUCK_NAME]: profileReducer(
          state[PROFILE_DUCK_NAME],
          action,
        ),
      };
    case new RegExp(SETUPS_NAMESPACE).test(action.type):
      return {
        ...state,
        [SETUPS_DUCK_NAME]: setupsReducer(
          state[SETUPS_DUCK_NAME],
          action,
        ),
      };
    case new RegExp(TABS_NAMESPACE).test(action.type):
      return {
        ...state,
        [TABS_DUCK_NAME]: tabsReducer(
          state[TABS_DUCK_NAME],
          action,
        ),
      };
    case new RegExp(SUBSCRIPTIONS_NAMESPACE).test(action.type):
      return {
        ...state,
        [SUBSCRIPTIONS_DUCK_NAME]: subscriptionsReducer(
          state[SUBSCRIPTIONS_DUCK_NAME],
          action,
        ),
      };
    case new RegExp(PAYMENT_METHODS_NAMESPACE).test(action.type):
      return {
        ...state,
        [PAYMENT_METHODS_DUCK_NAME]: paymentMethodsReducer(
          state[PAYMENT_METHODS_DUCK_NAME],
          action,
        ),
      };
    case new RegExp(ACCESS_NAMESPACE).test(action.type):
      return {
        ...state,
        [ACCESS_DUCK_NAME]: accessReducer(
          state[ACCESS_DUCK_NAME],
          action,
        ),
      };
    case new RegExp(RECURRENCY_NAMESPACE).test(action.type):
      return {
        ...state,
        [RECURRENCY_DUCK_NAME]: recurrencyReducer(
          state[RECURRENCY_DUCK_NAME],
          action,
        ),
      };
    case new RegExp(STATS_NAMESPACE).test(action.type):
      return {
        ...state,
        [STATS_DUCK_NAME]: statsReducer(
          state[STATS_DUCK_NAME],
          action,
        ),
      };
    default: return state;
  }
};

export default reducer;
