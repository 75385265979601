import { withStyles } from '@material-ui/core/styles';
import LoadingPage from './LoadingPage';

const styles = () => ({
  pageHeaderPaper: {
    paddingTop: 30,
    paddingBottom: 30,
    width: '100%',
  },
  container: {
    display: 'flex',
  },
  icon: {
    width: 40,
    marginRight: 10,
  },
  title: { width: 250 },
});

export default withStyles(styles)(LoadingPage);
