import React from 'react';
import PropTypes from 'prop-types';
import Icon from '@mdi/react';

import {
  Dialog,
  DialogContent,
  DialogTitle,
  Card,
  Chip,
  IconButton,
} from '@material-ui/core';

import { TABLET_WIDTH_BREAKPOINT } from 'common/utils/constants';
import CloseIcon from '@material-ui/icons/Close';

const ChoiceDialog = ({
  classes,
  onClose,
  title,
  choices,
  bottomText,
}) => {
  const isMobile = window.innerWidth <= TABLET_WIDTH_BREAKPOINT;

  const handleClick = (func) => {
    onClose();
    func();
  };

  return (
    <Dialog
      disableBackdropClick
      open
      onClose={onClose}
      maxWidth="md"
      fullWidth
      fullScreen={isMobile}
    >
      <DialogTitle
        disableTypography
        className={classes.title}
      >
        {title}
        <IconButton
          size="small"
          className={classes.closeIcon}
          edge="start"
          color="inherit"
          onClick={onClose}
          aria-label="close"
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>

      <DialogContent className={classes.content}>
        <div className={classes.cardContainer}>
          {choices.map((choice) => (
            <Card
              className={classes.card}
              key={choice.id}
              onClick={() => handleClick(choice.onClick)}
            >
              {choice.tag && (
                <Chip
                  className={classes.tag}
                  label={choice.tag.label}
                  style={{ color: choice.tag.color, backgroundColor: choice.tag.backgroundColor }}
                />
              )}
              <div
                className={classes.iconContainer}
                style={{ backgroundImage: choice.iconBackground }}
              >
                <Icon className={classes.icon} path={choice.icon} size="47px" />
              </div>
              <div className={classes.choiceTitle}>{choice.title}</div>
              <div className={classes.description}>{choice.description}</div>
            </Card>
          ))}
        </div>

        <Chip
          className={classes.chip}
          classes={{ label: classes.chipLabel }}
          label={bottomText}
        />
      </DialogContent>
    </Dialog>
  );
};

ChoiceDialog.propTypes = {
  classes: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  choices: PropTypes.array.isRequired,
  bottomText: PropTypes.any.isRequired,
};

export default ChoiceDialog;
