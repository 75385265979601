import React from 'react';
import PropTypes from 'prop-types';
import logdown from 'logdown';

import CircularProgress from '@material-ui/core/CircularProgress';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Radio from '@material-ui/core/Radio';
import Button from '@material-ui/core/Button';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import LabelAction from 'common/components/LabelAction';
import {
  getCreditCardImage,
  generateInstallments,
  makeAnInvoicePayment,
} from 'common/utils/paymentMethods';

export const INVOICE_PAYMENT_DIALOG = 'INVOICE_PAYMENT_DIALOG';

const logger = logdown('InvoicePaymentDialog');

class InvoicePaymentDialog extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      selectedCard: '',
      selectedInstallment: '',
      checkedAutomaticInvoices: false,
      installmentOptions: [],
    };
  }

  componentDidMount = () => {
    this.props.loadUserPaymentMethods();
  };

  handleChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  submit = async () => {
    this.setState({ loading: true });

    const {
      selectedCard,
      selectedInstallment,
      checkedAutomaticInvoices,
    } = this.state;

    const {
      invoice,
      notifySuccess,
      notifyError,
      onConfirmClick,
      onClose,
    } = this.props;

    try {
      const result = await makeAnInvoicePayment(
        invoice.code,
        selectedCard,
        selectedInstallment,
        checkedAutomaticInvoices,
      );

      logger.info('Pagamento efetuado com sucesso.', result);
      notifySuccess({
        title: 'Sucesso!',
        message: 'Pagamento da fatura efetuado.',
      });

      onClose();
    } catch (error) {
      logger.error('Falha ao registrar o pagamento.', error);
      notifyError({
        title: 'Erro!',
        message:
          error.codeMessage || 'Falha ao inserir um novo método de pagamento. Tente novamente.',
      });
    }

    onConfirmClick();
    this.setState({ loading: false });
  };

  renderCards = () => {
    const { classes, paymentMethods } = this.props;

    const { selectedCard } = this.state;

    if (!paymentMethods.data) return null;

    return paymentMethods.data.map((paymentMethod) => (
      <LabelAction
        key={`account-financier-cards-${paymentMethod.id}`}
        logo={(
          <img
            className={classes.logo}
            src={getCreditCardImage(paymentMethod.brand) || null}
            alt={`Bandeira do cartão de crédito ${paymentMethod.brand}`}
          />
        )}
        description={paymentMethod.display_number}
        action={(
          <Radio
            checked={selectedCard === paymentMethod.id}
            onChange={this.handleChange}
            color="primary"
            value={paymentMethod.id}
            name="selectedCard"
            id={`radio-button-card-${paymentMethod.id}`}
            aria-label={`Cartão de crédito de que contém: ${
              paymentMethod.display_number
            }`}
          />
        )}
      />
    ));
  };

  render = () => {
    const {
      classes,
      onCancelClick,
      open,
      onClose,
      confirmButtonContent,
      cancelButtonContent,
    } = this.props;

    const {
      loading,
      selectedInstallment,
      installmentOptions,
      checkedAutomaticInvoices,
    } = this.state;

    return (
      <Dialog open={open} onClose={onClose}>
        <DialogTitle
          className={classes.title}
          disableTypography
        >
          Pagar com cartão de crédito
        </DialogTitle>
        <DialogContent>
          <DialogContentText component="div">
            <div className={classes.paymentContainers}>
              Assinatura renovada automaticamente todo mês até ser
              cancelada pelo usuário. Mensalmente, será realizada a cobrança
              automática no cartão indicado e o recibo de cobrança será enviada
              para o seu e-mail. Realizando o pagamento, a renovação automática
              lhe garante que o valor de sua assinatura será mantido no caso de
              reajustes de preço.
            </div>
            <div className={classes.paymentContainers}>
              <h3>Cartões</h3>
              {this.renderCards()}
            </div>
            <div className={classes.paymentContainers}>
              <h3>Parcelas</h3>
              <Select
                id="invoice-payment-dialog-selected-installment"
                name="selectedInstallment"
                fullWidth
                value={selectedInstallment}
                onChange={this.handleChange}
              >
                {installmentOptions.map((item) => (
                  <MenuItem key={item.id} value={item.id}>
                    {item.name}
                  </MenuItem>
                ))}
              </Select>
            </div>
            <div className={classes.paymentContainers}>
              <FormControlLabel
                control={(
                  <Checkbox
                    name="checkedAutomaticInvoices"
                    checked={checkedAutomaticInvoices}
                    onChange={this.handleChange}
                    value="checkedAutomaticInvoices"
                    color="primary"
                  />
                )}
                label="Estou ciente de que as faturas de renovação desta assinatura
                  serão cobradas automaticamente no cartão selecionado.
                   Caso queira impedir a renovação automática a sua assinatura deve
                    ser cancelada na opção Meu plano."
                classes={{ label: classes.label }}
              />
            </div>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            id="invoice-payment-cancel-click"
            onClick={onCancelClick || onClose}
            color="secondary"
            autoFocus
          >
            {cancelButtonContent || 'Cancelar'}
          </Button>
          <div className={classes.wrapper}>
            <Button
              id="invoice-payment-confirm-click"
              variant="contained"
              disabled={loading}
              onClick={() => this.submit() || onClose}
              color="primary"
              autoFocus
            >
              {confirmButtonContent || 'Pagar'}
            </Button>
            {loading && (
              <CircularProgress size={24} className={classes.buttonProgress} />
            )}
          </div>
        </DialogActions>
      </Dialog>
    );
  };
}

InvoicePaymentDialog.getDerivedStateFromProps = (nextProps, prevState) => {
  const { invoice, paymentMethods } = nextProps;

  const installmentOptions = generateInstallments(
    invoice.total_cents,
    invoice.subscription_code
      ? invoice.plan.max_installments
      : invoice.installments,
  );

  return {
    installmentOptions,
    selectedCard:
      paymentMethods.data.length === 1
        ? paymentMethods.data[0].id
        : prevState.selectedCard,
    selectedInstallment:
      installmentOptions.length === 1 ? installmentOptions[0].id : '',
  };
};

InvoicePaymentDialog.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onCancelClick: PropTypes.func,
  onConfirmClick: PropTypes.func,
  cancelButtonContent: PropTypes.any,
  confirmButtonContent: PropTypes.any,
  loadUserPaymentMethods: PropTypes.func.isRequired,
  paymentMethods: PropTypes.object.isRequired,
  invoice: PropTypes.object.isRequired,
  notifySuccess: PropTypes.func.isRequired,
  notifyError: PropTypes.func.isRequired,
};

InvoicePaymentDialog.defaultProps = {
  onCancelClick: null,
  onConfirmClick: null,
  cancelButtonContent: null,
  confirmButtonContent: null,
};

export default InvoicePaymentDialog;
