import { withStyles } from '@material-ui/core';
import ConfirmValues from './ConfirmValues';

const styles = {
  valueText: {
    fontWeight: 700,
    textAlign: 'right',
  },
  dialogTitle: {
    fontSize: 24,
    fontWeight: 700,
    paddingBottom: 0,
  },
  itemValuesWrapper: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: 24,
  },
};

export default withStyles(styles)(ConfirmValues);
