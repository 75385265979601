import { withMobileDialog } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import palette from 'common/palette';
import RebalanceChangedStocksContent from './RebalanceChangedStocksContent';

const styles = {
  title: {
    alignItems: 'center',
    display: 'flex',
    fontSize: 22,
    fontWeight: 700,
    marginBottom: 16,
  },
  text: {
    fontWeight: 400,
  },
  warnIcon: {
    color: palette.warn.main,
    fontSize: 22,
    marginLeft: 5,
  },
};

export default withStyles(styles)(withMobileDialog()(RebalanceChangedStocksContent));
