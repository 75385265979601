import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import { Formik } from 'formik';

import { Box } from '@material-ui/core';

import {
  MAX_POSITIONS_FOLLOW_INSTANCE,
} from 'common/utils/constants/positions';

import { getPositionSchemaValidation } from 'common/utils/yup-validations/positions';
import { ORDER_VARIANT } from 'common/utils/constants/orders';
import { isInsertionMode } from 'common/utils/orders';
import SelectedItemsCounter from 'common/components/SelectedItemsCounter';
import FillPositionsForm from './FillPositionsForm';

const FillPositions = forwardRef(({
  dataSteps,
  initialValues,
  variant,
  instanceId,
  strategyId,
},
ref) => {
  const validationSchema = getPositionSchemaValidation(initialValues);
  const positionInitialValues = Object.keys(initialValues)
    .reduce((acc, key) => {
      const fieldOrderValue = initialValues[key];
      const initialEmptyValue = key === 'orderType' ? '0' : '';
      return {
        ...acc,
        [key]: fieldOrderValue || initialEmptyValue,
      };
    }, {});

  return (
    <Formik
      ref={ref}
      enableReinitialize
      validationSchema={validationSchema}
      initialValues={{
        positions: dataSteps.data.positions
        || [positionInitialValues],
      }}
      validateOnBlur={false}
      onSubmit={() => {}}
    >
      {({
        values,
        errors,
        handleSubmit,
        handleChange,
        resetForm,
        setStatus,
        status,
        setFieldValue,
      }) => {
        return (
          <>
            {isInsertionMode(variant) && (
            <Box display="flex" justifyContent="flex-end">
              <SelectedItemsCounter
                selectedItems={values.positions.length}
                maxItems={MAX_POSITIONS_FOLLOW_INSTANCE}
              />
            </Box>
            )}
            <FillPositionsForm
              positionBody={positionInitialValues}
              values={values}
              errors={errors}
              status={status}
              handleSubmit={handleSubmit}
              handleChange={handleChange}
              resetForm={resetForm}
              setStatus={setStatus}
              setFieldValue={setFieldValue}
              variant={variant}
              instanceId={instanceId}
              strategyId={strategyId}
              isExternalOrder={!dataSteps.numberOfSteps}
            />
          </>
        );
      }}
    </Formik>
  );
});

FillPositions.propTypes = {
  dataSteps: PropTypes.object,
  initialValues: PropTypes.object,
  variant: PropTypes.oneOf(Object.keys(ORDER_VARIANT)),
  instanceId: PropTypes.number.isRequired,
  strategyId: PropTypes.number.isRequired,
};

FillPositions.defaultProps = {
  dataSteps: { data: {} },
  initialValues: {
    stock: '',
    number_of_stocks: '',
  },
  variant: ORDER_VARIANT.insert,
};

export default FillPositions;
