import { LOWER_MOBILE, MOBILE, DESKTOP } from './constants';

export const getMaxWidthScreen = (value) => {
  return window.innerWidth <= value;
};

export const getCurrentScreen = ({ mobile = 768, lowerMobile = 370 }) => {
  const isCustomChartMobile = getMaxWidthScreen(mobile);
  const isCustomChartLowerMobile = getMaxWidthScreen(lowerMobile);

  const customScreens = {
    [LOWER_MOBILE]: LOWER_MOBILE,
    [MOBILE]: MOBILE,
    [DESKTOP]: DESKTOP,
  };

  if (isCustomChartMobile) {
    if (isCustomChartLowerMobile) return customScreens.lowerMobile;

    return customScreens.mobile;
  }

  return customScreens.desktop;
};

export default null;
