import { withStyles } from '@material-ui/styles';
import { connect } from 'react-redux';
import { updateUserProfile } from 'common/ducks/user/profile';
import { openDialog } from 'common/ducks/dialogs';
import { PASSWORD_CONFIRM_DIALOG_CODE } from 'common/components/Dialogs/PasswordConfirmDialog';
import { withMobileDialog } from '@material-ui/core';
import AccountInfoDialog from './AccountInfoDialog';

const styles = (() => ({
  closeIcon: {
    position: 'absolute',
    right: '10px',
    top: '5px',
  },
  dialog: {
    margin: '0 auto',
    maxWidth: '522px',
  },
  dialogTitle: {
    textAlign: 'center',
  },
  dialogSubtitle: {
    textAlign: 'center',
  },
  dialogContent: {
    '&:first-child': {
      paddingTop: 34,
    },
  },
  textField: {
    marginBottom: 0,
  },
  iconStep: {
    bottom: '10px',
    position: 'absolute',
    right: '10px',
  },
  buttonPositioning: {
    display: 'inline-block',
  },
}));

export const OPEN_ACCOUNT_INFO_DIALOG_CODE = 'OPEN_ACCOUNT_INFO_DIALOG_CODE';

const mapStateToProps = ({ user: { profile } }) => ({
  profile,
});
const mapDispatchToProps = (dispatch) => ({
  updateUserProfile: (data) => dispatch(updateUserProfile(data)),
  openPasswordConfirmDialog: (onConfirmClick) => dispatch(
    openDialog(PASSWORD_CONFIRM_DIALOG_CODE, { onConfirmClick }),
  ),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withStyles(styles)(withMobileDialog()(AccountInfoDialog)));
