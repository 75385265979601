import { connect } from 'react-redux';
import { closeDialog } from 'common/ducks/dialogs';
import Dialogs from './Dialogs';

const mapStateToProps = ({
  dialogs: { currentDialogOpened, dialogProps },
}) => ({ currentDialogOpened, dialogProps });

const mapDispatchToProps = (dispatch) => ({
  onDialogClose: () => dispatch(closeDialog()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Dialogs);
