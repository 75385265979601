import { connect } from 'react-redux';
import { closeSideDialog } from 'common/ducks/dialogs';
import SideDialogs from './SideDialogs';

const mapStateToProps = ({
  dialogs: { currentSideDialogOpened, sideDialogProps },
}) => ({ currentSideDialogOpened, sideDialogProps });

const mapDispatchToProps = (dispatch) => ({
  onSideDialogClose: () => dispatch(closeSideDialog()),
});

export default connect(mapStateToProps, mapDispatchToProps)(SideDialogs);
