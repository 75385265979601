import { withStyles } from '@material-ui/core/styles';
import ButtonGroupField from './ButtonGroupField';

const styles = {
  button: {
    boxShadow: 'none',
  },
  label: {
    display: 'inline-block',
    marginBottom: 10,
    fontSize: 14,
  },
};

export default withStyles(styles)(ButtonGroupField);
