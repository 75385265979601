import { withStyles } from '@material-ui/styles';
import ButtonDialog from './ButtonDialog';

const bordeBeforeAfter = {
  backgroundColor: 'rgba(51, 51, 51, 0.28)',
  content: '""',
  display: 'block',
  height: 1,
  left: '-24px',
  opacity: 1,
  position: 'absolute',
  right: 0,
  transition: `opacity 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
                   background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms`,
  width: 'calc(100% + 48px)',
};

const styles = {
  alignCenter: {
    textAlign: 'center',
    justifyContent: 'center',
  },
  alignLeft: {
    textAlign: 'left',
    justifyContent: 'left',
  },
  alignRight: {
    textAlign: 'right',
    justifyContent: 'right',
  },
  weightNormal: {
    fontWeight: 'normal',
  },
  weightMedium: {
    fontWeight: 500,
  },
  weightBold: {
    fontWeight: 'bold',
  },
  noneHover: {
    '&:hover': {
      background: 'none',
    },
  },
  borders: {
    paddingTop: 12,
    paddingBottom: 12,
    '&:before': {
      ...bordeBeforeAfter,
      top: '-1px',
    },
    '&::after': {
      ...bordeBeforeAfter,
      bottom: '-1px',
    },
  },
  button: {
    borderRadius: 'initial',
  },
};

export default withStyles(styles)(ButtonDialog);
