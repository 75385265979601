import { CAPTCHA_ERROR_MSG } from 'common/utils/constants';

const ssErrorInterpreter = (code) => {
  switch (code) {
    case 'ss_000001':
      return 'Mensagem com formato incorreto.';
    case 'ss_000002':
      return 'O parâmetro return_attributes está duplicado.';
    case 'ss_000003':
      return 'Você não possui permissão para usar essa função.';
    case 'ss_000004':
      return 'Você precisa estar logado na SmarttInvest para executar essa ação.';
    case 'ss_000005':
      return 'Número incorreto de parâmetros.';
    case 'ss_000006':
      return 'Um ou mais parâmetros estão duplicados.';
    case 'ss_000007':
      return 'Número incorreto de parâmetros obrigatórios.';
    case 'ss_000008':
      return 'Um ou mais parâmetros são inválidos.';
    case 'ss_000009':
      return 'Número incorreto de atributos de retorno.';
    case 'ss_000010':
      return 'Um ou mais atributos de retorno estão duplicados.';
    case 'ss_000011':
      return 'Um ou mais atributos de retorno são inválidos.';
    case 'ss_000012':
      return 'Você já está logado na SmarttInvest.';
    case 'ss_000013':
      return 'Login e/ou senha inválido(s).';
    case 'ss_000014':
      return 'Sua licença expirou: por favor contacte a SmarttInvest.';
    case 'ss_000015':
      return 'A SmarttInvest não pode tratar a requisição.';
    case 'ss_000016':
      return 'Este documento já está registrado no SmarttBot/SmarttInvest para outro usuário.';
    case 'ss_000017':
      return 'Email já registrado no SmarttBot/SmarttInvest.';
    case 'ss_000018':
      return 'Login já registrado no SmarttBot/SmarttInvest.';
    case 'ss_000019':
      return 'Documento é inválido.';
    case 'ss_000020':
      return 'Corretora já cadastrada para este usuário no SmarttBot/SmarttInvest.';
    case 'ss_000021':
      return 'Código de tabela de custos operacionais já registrado para este usuário no SmarttBot/SmarttInvest.';
    case 'ss_000022':
      return 'Código de trading system já registrados para este usuário no SmarttBot/SmarttInvest.';
    case 'ss_000023':
      return 'Código de investimento já registrado para este usuário no SmarttBot/SmarttInvest.';
    case 'ss_000024':
      return 'Limites disponíveis não informados pela corretora.';
    case 'ss_000025':
      return 'Conta já cadastrada no SmarttBot/SmarttInvest. Entre em contato através do e-mail contato@smarttinvest.com.';
    case 'ss_000026':
      return 'Conta já cadastrada no SmarttBot/SmarttInvest. Entre em contato através do e-mail contato@smarttinvest.com.';
    case 'ss_000027':
      return 'Caracter inválido na menssagem da requisição.';
    case 'ss_000028':
      return 'API Key inválida';
    case 'ss_000029':
      return 'Sua senha deve ser atualizada.';
    case 'ss_000030':
      return 'Senha utilizada recentemente. Digite uma senha diferente das suas últimas 6 senhas.';
    case 'ss_000031':
      return 'Senha inválida.';
    case 'ss_000032':
      return 'Função pertence a outro módulo.';
    case 'captcha0':
      return CAPTCHA_ERROR_MSG;
    case 'captcha1':
      return CAPTCHA_ERROR_MSG;

    default:
      return null;
  }
};

export default ssErrorInterpreter;
