import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment-timezone';
import {
  Dialog,
  DialogContent,
  IconButton,
  Typography,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';

import {
  TABLET_WIDTH_BREAKPOINT,
  TRANSACTION_LOADING_TITLE,
  DEFAULT_LOADING_MSG,
  IS_LOADING,
  IS_WARN,
  IS_SUCCESS,
  IS_ERROR,
  ERROR,
  TRANSACTION_ERROR_MESSAGE,
  OVER_LIMIT_TITLE,
  OVER_LIMIT_BUTTON,
  WAIT_TILL_TOMORROW_TITLE,
  WAIT_TILL_TOMORROW_MESSAGE,
  INVESTMENT_SUCCESS_TITLE,
  INVESTMENT_SUCCESS_MESSAGE,
  WITHDRAWAL_SUCCESS_TITLE,
  WITHDRAWAL_SUCCESS_MESSAGE,
  MARKET_TIMEZONE,
} from 'common/utils/constants';
import warnIcon from 'common/assets/images/progress_dialog/warn_icon.svg';
import { SteppedDialogFooter } from 'common/components/Steps';
import { getPlansByStrategy, getPlanCodeByType, getPlanLimit } from 'common/utils/plans';
import { ISO_DATE_FORMAT, REGEX_ISO_DATE_FORMAT } from 'common/utils/constants/date';
import { getOverLimitMessage, getTransactionDescription } from 'common/utils/investments';
import ConfirmValues from 'common/components/ConfirmValues';
import DisplayNumber from 'common/components/DisplayNumber';
import ChipWithTypography from 'common/components/ChipWithTypography';
import { useHandleOpenOrdersDialog } from 'common/hooks/orders';
import { onCheckForOpenOrders } from 'common/utils/orders';
import { getDefaultOperationDate } from 'common/utils/storeItem';
import { PARAMS_MOUNT_INSTANCE, NEXT_REBALANCE_TEXT } from 'common/utils/constants/instance';
import { WHITEBOX_STRATEGY_IDS } from 'common/utils/constants/whitebox';
import { getChangePortfolioDate, getDataInstanceParamName } from 'common/utils/whitebox';
import ChangedStocksVolume from 'common/components/ChangedStocksVolume';
import { getStocksAndOldStocksFromPositions } from 'common/utils/stocks';
import InvestTransaction from '../InvestTransaction';

const TransactionsDialog = ({
  classes,
  open,
  onClose,
  strategyId,
  instanceId,
  brokerageId,
  isInvestment,
  report,
  storeInstance,
  plans,
  subscriptions,
  positions,
  instance,
  openProgressDialog,
  insertFinancialTransaction,
  loadInstanceReport,
  openPlanSubscriptionDialog,
  isHolidayOrWeekendDay,
  restartUpdateInstance,
  openPasswordConfirmDialog,
}) => {
  const form = React.createRef();
  const [dataSteps, setDataSteps] = useState({
    currentStep: 1,
    numberOfSteps: 2,
    data: {},
  });
  const { currentStep, numberOfSteps } = dataSteps;
  const handleOpenOrdersDialog = useHandleOpenOrdersDialog();
  const isMobile = window.innerWidth <= TABLET_WIDTH_BREAKPOINT;
  const isWhiteboxInstance = WHITEBOX_STRATEGY_IDS.includes(strategyId);
  const isOptionNowSelected = useMemo(() => dataSteps.data.selectedDateOption
  === PARAMS_MOUNT_INSTANCE.mountNow.value, [dataSteps.data.selectedDateOption]);

  const operationDate = getDefaultOperationDate({
    optionNowSelected: isOptionNowSelected,
    storeInstance,
    isHolidayOrWeekendDay,
  });

  useEffect(() => {
    setDataSteps((oldDataSteps) => ({
      ...oldDataSteps,
      numberOfSteps: isOptionNowSelected ? 3 : 2,
    }));
  }, [isOptionNowSelected]);

  const setStep = (step) => {
    setDataSteps({ ...dataSteps, currentStep: step });
  };

  const handleChangeStepSubmitForm = (step, ...args) => {
    if (form.current) {
      form.current.handleSubmit(...args);
      const { values, errors } = form.current.getFormikBag();

      const hasErrors = Object.keys(errors).length > 0;

      if (!hasErrors) {
        setDataSteps((oldState) => ({
          ...oldState,
          currentStep: step,
          data: {
            ...oldState.data,
            value: values.value,
            description: values.description,
          },
        }));
      }
      return;
    }
    setStep(step);
  };

  const handleChangeStep = (step) => {
    if (currentStep !== numberOfSteps) {
      return handleChangeStepSubmitForm(step);
    }
    return setStep(step);
  };

  const insertTransaction = async (values) => {
    const datetime = moment.tz(MARKET_TIMEZONE);

    const [instanceStartDate] = instance.createdAt.match(REGEX_ISO_DATE_FORMAT) || [];

    const diffDays = moment(datetime.format(ISO_DATE_FORMAT))
      .diff(moment(instanceStartDate), 'days');
    if (diffDays === 0) {
      return openProgressDialog({
        state: IS_WARN,
        title: WAIT_TILL_TOMORROW_TITLE,
        message: WAIT_TILL_TOMORROW_MESSAGE,
        showButton: true,
        customIcon: warnIcon,
      });
    }

    const onConfirmClick = async (password) => {
      datetime.set({ hour: 5, minute: 0, second: 0 });
      datetime.toISOString();
      datetime.format();

      await onCheckForOpenOrders(instanceId, handleOpenOrdersDialog);

      const data = {
        brokerage_id: String(brokerageId),
        investment_code: `instance_${instanceId}`,
        datetime,
        contribution_or_withdrawal: isInvestment ? '0' : '1',
        value: values.value,
        operational_tax_cost: '0',
        description: getTransactionDescription({
          date: isOptionNowSelected
            ? datetime
            : moment(storeInstance.next_rollout),
          isWhiteboxInstance,
          isOptionNowSelected,
          operationDate,
        }),
      };

      openProgressDialog({
        state: IS_LOADING,
        title: TRANSACTION_LOADING_TITLE,
        message: DEFAULT_LOADING_MSG,
        showCloseIcon: false,
        onClose,
      });

      await insertFinancialTransaction(instanceId, data);
      await loadInstanceReport(instanceId);

      if (isOptionNowSelected) {
        const nextPortfolioDateChange = getChangePortfolioDate(isHolidayOrWeekendDay);
        const dataInstanceParamName = getDataInstanceParamName(nextPortfolioDateChange);
        const dataInstance = {
          params: {
            [dataInstanceParamName]: nextPortfolioDateChange,
          },
        };

        await restartUpdateInstance({
          id: instanceId,
          data: dataInstance,
          showSuccessDialog: false,
          password,
        });
      }

      openProgressDialog({
        state: IS_SUCCESS,
        title: isInvestment ? INVESTMENT_SUCCESS_TITLE : WITHDRAWAL_SUCCESS_TITLE,
        message: isInvestment ? INVESTMENT_SUCCESS_MESSAGE : WITHDRAWAL_SUCCESS_MESSAGE,
        showButton: true,
        onClose,
      });
    };

    try {
      openPasswordConfirmDialog({ open: true, onConfirmClick });
    } catch {
      openProgressDialog({
        state: IS_ERROR,
        title: ERROR,
        message: TRANSACTION_ERROR_MESSAGE,
        showButton: true,
        onClose,
      });
    }

    return true;
  };

  const validateBeforeInvestment = (values) => {
    const insertedValue = parseFloat(values.value);
    const currentPlans = getPlansByStrategy({ plans, strategyId });

    if (currentPlans.length > 0) {
      const { type } = currentPlans[0];
      const planCode = getPlanCodeByType({ type, subscriptions });

      const planLimit = getPlanLimit({ planCode, listPlans: currentPlans });

      if (!planLimit) {
        openProgressDialog({
          state: IS_ERROR,
          title: ERROR,
          message: TRANSACTION_ERROR_MESSAGE,
          showButton: true,
          onClose,
        });
      } else {
        const actionValue = report.equity + insertedValue;
        if (actionValue <= planLimit) {
          insertTransaction(values);
        } else {
          openProgressDialog({
            state: IS_WARN,
            title: OVER_LIMIT_TITLE,
            message: getOverLimitMessage(planLimit),
            showButton: true,
            buttonMessage: OVER_LIMIT_BUTTON,
            buttonClick: () => openPlanSubscriptionDialog({ strategyId, update: true }),
            customIcon: warnIcon,
          });
        }
      }
    }
  };

  const onSubmit = () => {
    if (isInvestment) {
      validateBeforeInvestment(dataSteps.data);
    } else {
      insertTransaction(dataSteps.data);
    }
  };

  const transactionsValues = {
    value: {
      id: 'value',
      label: 'Valor da movimentação:',
      value: <DisplayNumber
        color="textPrimaryBold"
        colorful={false}
        currency
        scale={1.25}
        value={parseFloat(dataSteps.data.value)}
      />,
    },
    operationDate: {
      id: 'operationDate',
      label: 'Data das operações:',
      value: isWhiteboxInstance && !isOptionNowSelected
        ? NEXT_REBALANCE_TEXT
        : <ChipWithTypography text={operationDate} />,
    },
  };

  const getStocksAndOldStocks = () => {
    if (isWhiteboxInstance) {
      return getStocksAndOldStocksFromPositions({
        positions,
        instance,
      });
    }
    return getStocksAndOldStocksFromPositions({
      positions,
      instance: storeInstance,
    });
  };

  const { stocks, oldStocks } = getStocksAndOldStocks();

  const renderConfirmValues = () => (
    <>
      <ConfirmValues values={transactionsValues} />

      {isOptionNowSelected && (
      <Typography variant="h6">
        ATENÇÃO: caso você tenha investimentos/resgates agendados, estes
        também serão executados agora.
      </Typography>
      )}
    </>
  );

  const getSecondStep = () => {
    if (isOptionNowSelected) {
      return {
        form: 'changed_stocks',
        component: (
          <ChangedStocksVolume
            stocks={stocks}
            oldStocks={oldStocks}
            transactionValue={Number(dataSteps?.data.value)}
            isInvestment={isInvestment}
            instanceId={instance.id}
          />
        ),
      };
    }

    return {
      form: 'follow_action_costs_form',
      component: renderConfirmValues(),
    };
  };

  const stepsMap = {
    1: {
      form: 'investTransaction',
      component: (
        <InvestTransaction
          ref={form}
          isInvestment={isInvestment}
          instanceId={instanceId}
          strategyId={strategyId}
          dataSteps={dataSteps}
          setDataSteps={setDataSteps}
          onSubmit={currentStep === numberOfSteps ? onSubmit : () => {}}
        />
      ),
    },
    2: getSecondStep(),
    3: {
      form: 'follow_action_costs_form',
      component: isOptionNowSelected
        ? renderConfirmValues()
        : null,
    },
  };

  return (
    <Dialog
      disableBackdropClick
      maxWidth="md"
      fullScreen={isMobile}
      onClose={onClose}
      aria-labelledby="customized-dialog-title"
      open={open}
    >
      <DialogContent className={classes.dialogContent}>
        <IconButton
          size="small"
          className={classes.closeIcon}
          edge="start"
          color="inherit"
          onClick={onClose}
          aria-label="close"
        >
          <CloseIcon />
        </IconButton>

        {stepsMap[currentStep].component}
        <SteppedDialogFooter
          onSetStep={handleChangeStep}
          formName={stepsMap[currentStep].form}
          StepsProps={{ currentStep, numberOfSteps }}
          onClickConfirm={onSubmit}
          displayPreviousStep={currentStep !== 1}
        />
      </DialogContent>
    </Dialog>
  );
};

TransactionsDialog.propTypes = {
  classes: PropTypes.object.isRequired,
  strategyId: PropTypes.number.isRequired,
  instanceId: PropTypes.number.isRequired,
  brokerageId: PropTypes.number.isRequired,
  isInvestment: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  report: PropTypes.object.isRequired,
  storeInstance: PropTypes.object.isRequired,
  plans: PropTypes.object.isRequired,
  subscriptions: PropTypes.array.isRequired,
  positions: PropTypes.object.isRequired,
  instance: PropTypes.object.isRequired,
  openProgressDialog: PropTypes.func.isRequired,
  insertFinancialTransaction: PropTypes.func.isRequired,
  loadInstanceReport: PropTypes.func.isRequired,
  openPlanSubscriptionDialog: PropTypes.func.isRequired,
  isHolidayOrWeekendDay: PropTypes.func.isRequired,
  restartUpdateInstance: PropTypes.func.isRequired,
  openPasswordConfirmDialog: PropTypes.func.isRequired,
};

export default TransactionsDialog;
