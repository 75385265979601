import {
  error as notifierError,
  success as notifierSuccess,
} from 'react-notification-system-redux';
import { connect } from 'react-redux';
import { reset, reduxForm } from 'redux-form';

import { withStyles } from '@material-ui/core/styles';
import { applyDiscount } from 'common/ducks/billing';
import { loadUserPaymentMethods } from 'common/ducks/user/paymentMethods';
import palette from 'common/palette';

import AddCouponDialog, { ADD_COUPON_DIALOG_CODE } from './AddCouponDialog';

export { ADD_COUPON_DIALOG_CODE };

const styles = (theme) => ({
  title: {
    fontSize: '1.3125rem',
    fontWeight: 500,
  },
  content: {
    paddingTop: 0,
  },
  cancelButton: {},
  icon: {
    color: palette.default.dark,
    margin: '0 10px',
  },
  formControl: {
    padding: '20px 20px 20px 20px',
    width: '100%',
  },
  textField: {
    margin: theme.spacing(1),
    marginBottom: '10px',
  },
  cards: {
    '& .rccs__card--unknown > div': {
      background: `linear-gradient(25deg, ${palette.primary.dark}, ${
        palette.primary.main
      })`,
    },
  },
  wrapper: {
    margin: theme.spacing(1),
    position: 'relative',
  },
  buttonProgress: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
  loadingIcon: { position: 'absolute', right: '15px', float: 'right' },
});

const mapDispatchToProps = (dispatch) => ({
  resetForm: () => {
    dispatch(reset('add-coupon-form'));
  },
  loadUserPaymentMethods: (params) => dispatch(loadUserPaymentMethods(params)),
  notifySuccess: (data) => dispatch(notifierSuccess(data)),
  notifyError: (data) => dispatch(notifierError(data)),
  applyDiscount: (code, data) => dispatch(applyDiscount(code, data)),
});

export default connect(null, mapDispatchToProps)(reduxForm({
  form: 'addCoupon',
})(withStyles(styles)(AddCouponDialog)));
