import React from 'react';
import PropTypes from 'prop-types';
import { reduxForm, Field } from 'redux-form';
import { Typography, Box, InputAdornment } from '@material-ui/core';
import DisplayNumber from 'common/components/DisplayNumber';
import TitleTooltip from 'common/components/TitleTooltip';
import Placeholder from 'common/components/Placeholder';
import { InputTextField } from 'common/components/ReduxForm';
import { formatNumber, parseNumber } from 'common/utils/string';
import {
  getErrorContributionValue,
  checkContributionHasError,
} from 'common/utils/validation';
import { REAL_SYMBOL } from 'common/utils/constants';

const FinancialValueForm = ({
  classes,
  contribution,
  storeInstance,
  equity,
  loadingEquity,
  dataSteps,
  setDataSteps,
}) => {
  const error = getErrorContributionValue({
    value: contribution,
    equity: equity.totalEquity,
    storeInstance,
  });

  const handleChangeField = (e) => {
    const parsedValue = parseNumber(e.target.value, 2) || 0;

    const data = {
      ...dataSteps.data,
      equity: {
        totalEquity: parseFloat(parsedValue) + parseFloat(equity.totalEquity),
        totalEquityContribution: parseFloat(parsedValue) + parseFloat(equity.totalEquity),
      },
    };
    setDataSteps({ ...dataSteps, data });
  };

  return (
    <>
      <Box pt={1}>
        {loadingEquity ? (
          <Placeholder height={50} />
        ) : (
          <Box data-testid="equity-current-positions">
            <Box mb={0.5}>
              <TitleTooltip
                label="As posições atuais da sua carteira são equivalentes a:"
                tooltip={{
                  text: 'Soma do valor financeiro de todas as posições preenchidas.',
                }}
                variant="h6"
              />
            </Box>
            <DisplayNumber
              currency
              value={parseFloat(equity.totalEquity)}
              scale={1}
              symbolScale={1}
              valueScale={1}
              color="textPrimary"
              colorful={false}
            />
          </Box>
        )}

        <Box className={classes.inputBox}>
          <InputAdornment position="start">
            {REAL_SYMBOL}
          </InputAdornment>
          <Field
            className={classes.field}
            type="text"
            name="contribution"
            component={InputTextField}
            format={(value) => formatNumber(value, 2)}
            normalize={(value) => {
              if (value === '') return '0.00';

              return parseNumber(value, 2);
            }}
            validate={checkContributionHasError}
            onChange={handleChangeField}
            label={(
              <TitleTooltip
                label="Quanto você deseja investir além do seus ativos?"
                tooltip={{
                  text: 'Caso o valor preenchido seja R$ 0,00, a carteira irá utilizar somente os valores dos ativos preenchidos',
                }}
                variant="h5"
              />
            )}
            autoFocus
          />
        </Box>

        {loadingEquity ? (
          <Placeholder height={100} />
        ) : (
          <Box data-testid="portfolio-total-equity">
            <Box mb={2}>
              <Box mb={0.5}>
                <TitleTooltip
                  label="Patrimônio total da carteira:"
                  tooltip={{
                    text: 'Soma do valor das posições preenchidas mais o aporte realizado.',
                  }}
                  variant="h6"
                />
              </Box>
              <DisplayNumber
                currency
                value={parseFloat(contribution || 0)
                  + parseFloat(equity.totalEquity)}
                scale={1.25}
                symbolScale={1}
                valueScale={1.25}
                color="textPrimary"
                colorful={false}
              />

              {error && (
              <Box data-testid="feedback-error" className={classes.error} mt={1}>
                {error}
              </Box>
              )}
            </Box>

            <Typography color="textSecondary" variant="h6">
              Esse valor representa o total que você deve possuir na corretora,
              somando ativos dessa carteira + saldo.
            </Typography>
          </Box>
        )}
      </Box>
    </>
  );
};

FinancialValueForm.propTypes = {
  classes: PropTypes.object.isRequired,
  dataSteps: PropTypes.object.isRequired,
  storeInstance: PropTypes.object.isRequired,
  contribution: PropTypes.string,
  equity: PropTypes.object.isRequired,
  loadingEquity: PropTypes.bool.isRequired,
  setDataSteps: PropTypes.func.isRequired,
};

FinancialValueForm.defaultProps = {
  contribution: '0,00',
};

export default reduxForm({ form: 'financial_value_form' })(FinancialValueForm);
