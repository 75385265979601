/**
 * This module is dedidated to configure the app to be displayed in different
 * languages. Here the currency, time and text library's are configured to work
 * with the desired language.
 *
 * @see http://www.lingoes.net/en/translator/langcode.htm
*/

import accounting from 'accounting';
import moment from 'moment';
import 'moment/locale/pt-br';

/**
 * This function changes the currency and number formatting of the library
 * accounting used widely on the app according to the given language code.
 *
 * Supported codes:
 * - 'pt-br'
 *
 * @see http://openexchangerates.github.io/accounting.js/#demo
 *
 * @param {String} code The language code
 */
export const changeNumberAndCurrencyFormatting = (code) => {
  switch (code.toLowerCase()) {
    case 'pt-br':
      accounting.settings = {
        currency: {
          symbol: 'R$',
          format: '%s%v',
          decimal: ',',
          thousand: '.',
          precision: 2,
        },
        number: {
          precision: 0,
          thousand: '.',
          decimal: ',',
        },
      };
      break;
    default:
  }
};

/**
 * This function changes the time formatting of the library MomentJS used widely
 * on the app according to the given language code.
 *
 * Supported codes:
 * - 'pt-br'
 *
 * @see http://openexchangerates.github.io/accounting.js/#demo
 *
 * @param {String} code The language code
 */
export const changeTimeLocaleFormatting = (code) => {
  moment.locale(code);
};

/**
 * This function set's up all the formatters available to work according to the
 * given language code.
 *
 * @param {String} code The language code
 */
const setupI18n = (code, changeFormattingMethods = [
  changeNumberAndCurrencyFormatting,
  changeTimeLocaleFormatting,
]) => {
  for (let i = 0; i < changeFormattingMethods.length; i += 1) {
    changeFormattingMethods[i](code);
  }
};

export default setupI18n;
