import React, {
  useState,
  useEffect,
  useMemo,
  useRef,
  memo,
} from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Card from '@material-ui/core/Card';
import CircularProgress from '@material-ui/core/CircularProgress';
import {
  Checkbox,
  FormControlLabel,
  Typography,
  Button,
  Input,
  InputAdornment,
  Box,
} from '@material-ui/core';

import DonutsChart from 'common/components/Graphics/DonutsChart';
import { getTotalItems, reduceObjectsArrayToObject } from 'common/utils/array';
import { getEqualizedItemsList } from 'common/utils/slider';
import {
  getUpdatedStocksList,
  getCheckAllValue,
  setWeightStock,
  getSelectedItems,
  shouldBeAbleToFollow,
  getIsStocksEquals,
} from 'common/utils/whitebox';
import {
  CHECK_ALL,
  EQUALIZE_TEXT,
  STOCK_MODES,
  WHITEBOX_INSTANCE_STRATEGY_ID,
  WHITEBOX_PLAN_CODES,
} from 'common/utils/constants';
import iconSearch from 'common/assets/images/icon-search.svg';

import { useHandleOpenOrdersDialog } from 'common/hooks/orders';
import { CODE_PARAM, WHITEBOX_MAX_STOCKS } from 'common/utils/constants/whitebox';
import { getYTextCenter } from 'common/utils/chart';
import { getCurrentScreen } from 'common/utils/screen';
import { checkInstanceIsTemplate } from 'common/utils/template';
import { SMALL } from 'common/utils/constants/size';
import { getSubscriptionByPlanCode } from 'common/utils/plans';
import lodashIsEmpty from 'lodash/isEmpty';
import StockCard from '../StockCard';
import PageAbandonment from '../PageAbandonment';
import RebalanceButton from '../Buttons/RebalanceButton';
import BlueButton from '../Buttons/BlueButton';
import SearchStocks from '../SearchStocks';
import EmptyState from '../EmptyState';
import SelectedItemsCounter from '../SelectedItemsCounter';

const WhiteBox = ({
  classes,
  instance,
  instanceId,
  whitebox,
  addStock,
  removeStocks,
  loadStocks,
  setMode,
  saveTemplate,
  saveLoading,
  openStartFollowInstance,
  subscriptions,
  hideChart,
  openPlanSubscriptionDialog,
  isSmartWeights,
  chosenSuggestedWallet,
  suggestedWallet,
  generateWallets,
  loadingSuggestedWallets,
  clearSearch,
  setCommitChange,
}) => {
  const [checkAll, setCheckAll] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const objValuesStocks = Object.values(whitebox.stocks);
  const [stockToSearch, setStockToSearch] = useState('');
  const isTemplate = checkInstanceIsTemplate(instance);
  const screen = getCurrentScreen({ mobile: 1200, lowerMobile: 370 });
  const totalWeights = getTotalItems({ items: objValuesStocks, value: 'weight' });
  const isTotalGreaterZero = totalWeights > 0;
  const isTotalNotHundred = totalWeights !== 100;
  const isTotalGreaterHundred = totalWeights > 100;
  const isAnyStockChecked = getSelectedItems(objValuesStocks).length >= 1;
  const hasTwoSelectedStocks = getSelectedItems(objValuesStocks).length >= 2;
  const isEmpty = objValuesStocks.length === 0;
  const handleOpenOrdersDialog = useHandleOpenOrdersDialog();

  const isSearchMode = whitebox.mode === STOCK_MODES.SEARCH;

  const chartTexts = [
    {
      id: 'totalText',
      y: getYTextCenter({
        value: 110,
        decreaseLowerMobile: 20,
        screen,
      }),
      className: classNames(
        classes.totalPercentText,
      ),
      text: 'Total:',
      isVisible: !isEmpty,
    },
    {
      id: 'totalValue',
      y: getYTextCenter({
        value: 140,
        decreaseLowerMobile: 20,
        screen,
      }),
      className: classNames(
        classes.totalPercentValue,
        isTemplate && isTotalNotHundred && classes.totalPercentError,
        !isTemplate && isTotalGreaterHundred && classes.totalPercentError,
      ),
      text: `${totalWeights}%`,
      isVisible: !isEmpty,
    },
  ];
  const firstRender = useRef(true);

  const handleInputSlider = ({
    event,
    currentStock,
    newValue,
    prop,
    commited = false,
    minValue,
    maxValue,
  }) => {
    const value = event.target.value ?? newValue;

    const listStocks = setWeightStock({
      stocks: objValuesStocks,
      currentStock,
      newValue: value,
      prop,
      minValue,
      maxValue,
    });

    loadStocks(reduceObjectsArrayToObject(listStocks, CODE_PARAM), false);

    setTimeout(() => setCommitChange(commited), 500);
  };

  const handleCheck = (e) => {
    const itemName = e.target.name;
    const { checked } = e.target;

    const listStocks = getUpdatedStocksList({ checked, itemName, listStocks: objValuesStocks });
    const checkAllValue = getCheckAllValue({ checked, itemName, listStocks });

    setCheckAll(checkAllValue);
    loadStocks(reduceObjectsArrayToObject(listStocks, CODE_PARAM));
  };

  const handleEqualizeStocks = () => {
    const selectedItems = getSelectedItems(objValuesStocks);

    const listStocks = getEqualizedItemsList({ items: objValuesStocks, selectedItems, value: 'weight' });

    setCheckAll(false);
    loadStocks(reduceObjectsArrayToObject(listStocks, CODE_PARAM), true);
  };

  const addToWallet = (stock) => {
    const result = addStock(whitebox.stocks, stock);
    if (result) {
      setStockToSearch('');
    }
  };

  const removeFromWallet = () => {
    removeStocks(objValuesStocks, checkAll);
    setCheckAll(false);
  };

  const handleStartFollowInstance = () => {
    const userHasWhiteboxSubscription = WHITEBOX_PLAN_CODES.some((planCode) => {
      return !lodashIsEmpty(getSubscriptionByPlanCode({
        subscriptions, planCode,
      }));
    });

    if (userHasWhiteboxSubscription) {
      openStartFollowInstance({
        strategyId: WHITEBOX_INSTANCE_STRATEGY_ID,
        isWhiteBox: true,
        templateId: instance.id,
        templateName: instance.name,
      });
    } else {
      openPlanSubscriptionDialog({
        strategyId: WHITEBOX_INSTANCE_STRATEGY_ID,
        isWhiteBox: true,
      });
    }
  };

  const hasDataChanges = useMemo(() => {
    return firstRender.current ? false : !getIsStocksEquals({
      instanceStocks: Object.values(instance.stocks),
      whiteboxStocks: objValuesStocks,
    });
  }, [objValuesStocks, instance]);

  useEffect(() => {
    if (firstRender.current) {
      firstRender.current = false;
      const hasSuggestedWallet = Object.keys(chosenSuggestedWallet).length > 0;

      const stockObjectList = hasSuggestedWallet && suggestedWallet
        ? JSON.parse(JSON.stringify(chosenSuggestedWallet))
        : JSON.parse(JSON.stringify(instance.stocks));

      loadStocks({ ...stockObjectList });
      setIsLoading(false);
    }
  }, [suggestedWallet, chosenSuggestedWallet, instance.stocks, loadStocks]);

  useEffect(() => {
    return () => {
      setStockToSearch('');
      clearSearch();
    };
  }, [clearSearch, setStockToSearch]);

  const actionButtons = (
    <>
      {isAnyStockChecked ? (
        <div className={classes.checkedActions}>
          {!isSmartWeights && hasTwoSelectedStocks && (
            <BlueButton
              className={classNames(classes.actionButtons)}
              onClick={handleEqualizeStocks}
              variant="contained"
              size="small"
            >
              {EQUALIZE_TEXT}
            </BlueButton>
          )}
          <Button
            className={classNames(classes.actionButtons)}
            onClick={() => removeFromWallet()}
            color="secondary"
            variant="contained"
            size="small"
          >
            Remover
          </Button>
        </div>
      ) : (
        <Box display="flex" alignItems="center">
          <Box mr={1}>
            <Button
              variant="outlined"
              color="primary"
              className={classes.addButton}
              onClick={() => setMode(STOCK_MODES.SEARCH)}
              disabled={objValuesStocks.length === WHITEBOX_MAX_STOCKS}
            >
              Adicionar +
            </Button>
          </Box>
          <SelectedItemsCounter
            color="textSecondary"
            selectedItems={objValuesStocks.length}
            maxItems={WHITEBOX_MAX_STOCKS}
          />
        </Box>
      )}
    </>
  );

  const walletMode = (
    <>
      <div className={classNames(
        classes.cardContentItems,
        classes.cardContentTemplate,
      )}
      >
        {isSmartWeights && (
          <Box mb={2.5}>
            <Typography variant="h6" color="textSecondary">
              Escolha os ativos e os pesos mínimo e o máximo que cada ativo pode
              representar na carteira. Depois clique em &quot;Gerar carteira&quot;
              para ver as opções de pesos inteligentes.
            </Typography>
          </Box>
        )}
        <div className={classes.stocksHeader}>
          <FormControlLabel
            classes={{ label: classes.selectAllText }}
            control={(
              <Checkbox
                inputProps={{ 'data-testid': 'check-all' }}
                className={classes.checkbox}
                color="primary"
                name={CHECK_ALL}
                onChange={handleCheck}
                checked={checkAll}
              />
            )}
            label="Selecionar tudo"
          />
          {actionButtons}
        </div>

        {isSmartWeights && (
        <Box className={classes.wrapperTitlesMinMax}>
          <Typography
            color="textSecondary"
            variant="h6"
          >
            Mínimo
          </Typography>
          <Typography
            className={classes.textMaximum}
            color="textSecondary"
            variant="h6"
          >
            Máximo
          </Typography>
        </Box>
        )}

        <div className={classes.stockMapWrapper}>
          {!isLoading && objValuesStocks.length > 0 && objValuesStocks.map(
            (stock, index) => (
              <StockCard
                key={stock.code}
                className={classes.stockCards}
                stock={stock}
                stockIndex={index}
                isTotalGreaterZero={isTotalGreaterZero}
                isTemplate={isTemplate}
                isSmartWeights={isSmartWeights}
                check={stock.checked}
                handleInputSlider={handleInputSlider}
                onClickCheck={handleCheck}
              />
            ),
          )}
          {objValuesStocks.length === 0 && (
            <div className={classNames(
              classes.emptyStocks,
              classes.justifyCenter,
            )}
            >
              <EmptyState
                icon={iconSearch}
                text="Pesquise e selecione os ativos para compor a sua carteira"
              />
            </div>
          )}
          {objValuesStocks.length > 5 && !isSmartWeights && (
            <div className={classes.cardShadowScroll}></div>
          )}
        </div>
      </div>
    </>
  );

  const searchMode = (
    <SearchStocks
      instanceId={instanceId}
      onStockClick={addToWallet}
      onClickBackButton={() => setMode(STOCK_MODES.UPDATE)}
      stockToSearch={stockToSearch}
      setStockToSearch={setStockToSearch}
      showBackButton
      textEmptyState="Pesquise e selecione os ativos para compor a sua carteira"
      limitStocks
      isStockClickButton
    />
  );

  const donutsChart = (
    <div>
      <DonutsChart
        chartTexts={chartTexts}
        data={objValuesStocks}
        totalWeights={totalWeights}
        size={SMALL}
      />
    </div>
  );

  const stocksMode = whitebox.mode === STOCK_MODES.SEARCH ? searchMode : walletMode;

  const totalMinWeights = getTotalItems({
    items: objValuesStocks,
    value: 'minWeight',
  });
  const totalMaxWeights = getTotalItems({
    items: objValuesStocks,
    value: 'maxWeight',
  });
  const hasSomeMinBiggerThanMax = objValuesStocks.some((stock) => {
    return stock.minWeight > stock.maxWeight;
  });
  const isTotalMinError = totalMinWeights >= 100;
  const isTotalMaxError = totalMaxWeights <= 100;
  const hasAtLeastThreeStocks = objValuesStocks.length >= 3;

  const weights = [
    {
      name: 'totalMinWeight',
      total: totalMinWeights,
    },
    {
      name: 'totalMaxWeight',
      total: totalMaxWeights,
    },
  ];

  const errorsSmartWeights = {
    isTotalMinError: {
      error: isTotalMinError,
      text: 'O total dos mínimos precisa ser menor que 100%',
    },
    isTotalMaxError: {
      error: isTotalMaxError,
      text: 'O total dos máximos precisa ser maior que 100%',
    },
    hasAtLeastThreeStocks: {
      error: !hasAtLeastThreeStocks,
      text: 'Insira no mínimo 3 ativos para gerar as carteiras',
    },
    hasSomeMinBiggerThanMax: {
      error: hasSomeMinBiggerThanMax,
      text: 'Nenhum mínimo pode ser maior que o respectivo máximo',
    },
  };

  const hasSomeErrorToGenerateWallet = Object.values(errorsSmartWeights)
    .some((itemError) => itemError.error);

  return (
    <Card
      data-testid="whitebox-card"
      className={classNames(classes.card)}
    >
      <div className={classNames(
        classes.cardContent,
      )}
      >
        {!hideChart && donutsChart}
        {stocksMode}

        {isTemplate && (
          <Box mb={2} alignItems="center" display="flex" justifyContent="flex-end">
            {!isSmartWeights
            && (
              <>
                <Button
                  className={classes.button}
                  variant="contained"
                  color="primary"
                  disabled={!shouldBeAbleToFollow(objValuesStocks, totalWeights) || saveLoading}
                  onClick={() => saveTemplate(Object.values(whitebox.stocks), instanceId)}
                  endIcon={saveLoading ? <CircularProgress size={16} /> : null}
                >
                  Salvar template
                </Button>

                <Button
                  className={classes.button}
                  variant="contained"
                  color="primary"
                  disabled={!shouldBeAbleToFollow(objValuesStocks, totalWeights)
                  || hasDataChanges}
                  onClick={handleStartFollowInstance}
                >
                  Montar a carteira
                </Button>
              </>
            )}

            {!isSearchMode && isSmartWeights && (
              <>
                <Box alignItems="center" display="flex" justifyContent="flex-end">
                  <Typography
                    className={classes.totalText}
                    color="textSecondary"
                    variant="h6"
                  >
                    Total:
                  </Typography>

                  {weights.map((weight) => (
                    <Input
                      key={`input${weight.name}`}
                      className={classNames(
                        classes.disabledInput,
                      )}
                      classes={{
                        input: classNames(
                          weight.name,
                          classes.totalInput,
                        ),
                      }}
                      value={weight.total}
                      type="text"
                      disabled
                      endAdornment={(
                        <InputAdornment
                          position="end"
                          classes={{ positionEnd: classes.inputAdornment }}
                          disableTypography
                        >
                          %
                        </InputAdornment>
                    )}
                    />
                  ))}
                </Box>
              </>
            )}
          </Box>
        )}

        {!isLoading && !isSearchMode && isSmartWeights && (
          <>
              {Object.keys(errorsSmartWeights).map((itemError) => {
                const currentError = errorsSmartWeights[itemError];

                if (!currentError.error) return false;

                return (
                  <Box key={itemError} textAlign="right">
                    <Typography variant="h6" className={classes.errorText}>
                      {currentError.text}
                    </Typography>
                  </Box>
                );
              })}

            <Box mt={2} textAlign="right">
              <Button
                data-testid="generate-wallet-button"
                className={classes.button}
                variant="contained"
                color="primary"
                disabled={loadingSuggestedWallets
                    || hasSomeErrorToGenerateWallet}
                onClick={() => generateWallets(whitebox.stocks, instanceId)}
              >
                {loadingSuggestedWallets
                  && (
                    <CircularProgress
                      size={20}
                      thickness={7}
                    />
                  )}
                Gerar carteiras
              </Button>
            </Box>
          </>
        )}
      </div>

      <Box
        my={2.5}
        display="flex"
        justifyContent="flex-end"
        className={classNames(
          classes.totalPercentText,
          classes.totalPercentError,
        )}
      >
        {isTemplate && !isSmartWeights && isTotalNotHundred && !isSearchMode && 'O total deve ser 100%'}
        {!isTemplate && isTotalGreaterHundred && !isSearchMode && 'O total deve ser no máximo 100%'}
      </Box>
      {!isTemplate && (
        <Box display="flex" justifyContent="flex-end">
          <RebalanceButton
            instanceId={instanceId}
            handleOpenOrdersDialog={handleOpenOrdersDialog}
          />
        </Box>
      )}
      {!isSmartWeights && (
        <PageAbandonment items={objValuesStocks} hasDataChanges={hasDataChanges} />
      )}
    </Card>
  );
};

WhiteBox.propTypes = {
  classes: PropTypes.object.isRequired,
  addStock: PropTypes.func.isRequired,
  removeStocks: PropTypes.func.isRequired,
  loadStocks: PropTypes.func.isRequired,
  setMode: PropTypes.func.isRequired,
  whitebox: PropTypes.shape({
    mode: PropTypes.string,
    stocks: PropTypes.object,
  }).isRequired,
  instanceId: PropTypes.number.isRequired,
  instance: PropTypes.object,
  saveTemplate: PropTypes.func.isRequired,
  saveLoading: PropTypes.bool.isRequired,
  openStartFollowInstance: PropTypes.func.isRequired,
  openPlanSubscriptionDialog: PropTypes.func.isRequired,
  clearSearch: PropTypes.func.isRequired,
  generateWallets: PropTypes.func.isRequired,
  setCommitChange: PropTypes.func.isRequired,
  hideChart: PropTypes.bool,
  isSmartWeights: PropTypes.bool,
  loadingSuggestedWallets: PropTypes.bool,
  chosenSuggestedWallet: PropTypes.object,
  suggestedWallet: PropTypes.string,
  subscriptions: PropTypes.array.isRequired,
};

WhiteBox.defaultProps = {
  instance: {},
  hideChart: false,
  isSmartWeights: false,
  loadingSuggestedWallets: false,
  chosenSuggestedWallet: {},
  suggestedWallet: '',
};

export default memo(WhiteBox);
