import { withStyles } from '@material-ui/core';
import palette from 'common/palette';

import BackButton from './BackButton';

const styles = {
  root: {
    textDecoration: 'none',
  },
  container: {
    display: 'block',
  },
  icon: {
    marginRight: '5px',
  },
  textPrimary: {
    color: palette.textPrimary.main,
  },
  textSecondary: {
    color: palette.textSecondary.main,
  },
  white: {
    color: palette.neutral.main,
  },
};

export default withStyles(styles)(BackButton);
