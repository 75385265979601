import React, { useState } from 'react';
import PropTypes from 'prop-types';
import logdown from 'logdown';
import moment from 'moment';
import { STEP_STATUS, STEP_TYPES } from 'common/utils/constants';

import {
  Dialog,
  DialogContent,
  Typography,
  IconButton,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';

import { onlyNumbers } from 'common/utils/string';
import { SteppedDialogFooter } from '../../Steps';
import AccountInfoPersonalForm from './AccountInfoPersonalForm';
import AccountInfoAddressForm from './AccountInfoAddressForm';

const logger = logdown('AccountInfoDialog');

const AccountInfoDialog = ({
  classes,
  open,
  onClose,
  openPasswordConfirmDialog,
  updateUserProfile,
  onConfirmSubmit,
  fullScreen,
}) => {
  const [dataSteps, setDataSteps] = useState({
    currentStep: 1,
    numberOfSteps: 2,
    data: {},
  });
  const { currentStep, numberOfSteps } = dataSteps;

  const setStep = (step) => {
    setDataSteps({ ...dataSteps, currentStep: step });
  };

  const submitProfileInfo = (data) => {
    if (currentStep === 1) {
      setDataSteps({ ...dataSteps, data, currentStep: 2 });
    } else {
      const submitData = {
        ...dataSteps.data,
        ...data,
      };

      delete submitData.document;
      delete submitData.name_or_corporate_name;

      submitData.postal_code = onlyNumbers(submitData.postal_code);
      submitData.birthday = moment(submitData.birthday, 'DD-MM-YYYY')
        .format('YYYY-MM-DD');

      const updateProfile = async (password) => {
        try {
          await updateUserProfile({
            ...submitData,
            s10i_password: password,
          });

          onConfirmSubmit(STEP_STATUS.COMPLETE, STEP_TYPES.ACCOUNT_INFO);
          onClose();
        } catch (error) {
          logger.log('error updating user profile');
        }
      };

      openPasswordConfirmDialog(updateProfile);
    }
  };

  const stepsMap = {
    1: (<AccountInfoPersonalForm
      classes={classes}
      onSubmit={submitProfileInfo}
      initialValues={dataSteps.data}
    />),
    2: <AccountInfoAddressForm
      classes={classes}
      onSubmit={submitProfileInfo}
    />,
  };

  return (
    <Dialog
      disableBackdropClick
      className={classes.dialog}
      classes={{
        paper: classes.dialog,
      }}
      fullScreen={fullScreen}
      onClose={onClose}
      aria-labelledby="customized-dialog-title"
      open={open}
    >
      <DialogContent className={classes.dialogContent}>
        <IconButton
          size="small"
          className={classes.closeIcon}
          edge="start"
          color="inherit"
          onClick={onClose}
          aria-label="close"
        >
          <CloseIcon />
        </IconButton>
        <Typography
          variant="h2"
          className={classes.dialogTitle}
          id="customized-dialog-title"
        >
          Complete o seu cadastro
        </Typography>
        <Typography className={classes.dialogSubtitle} color="textSecondary">Vamos lá!</Typography>
        <Typography className={classes.dialogSubtitle} color="textSecondary">
          Precisamos de mais algumas informações sobre você
        </Typography>

        {stepsMap[currentStep]}

        <SteppedDialogFooter
          onSetStep={setStep}
          submittingForm
          formName={
            currentStep === 1
              ? 'personal_info_form'
              : 'profile_address_form'
          }
          StepsProps={{ currentStep, numberOfSteps }}
          onConfirmSteps={submitProfileInfo}
          displayPreviousStep={currentStep !== 1}
        />
      </DialogContent>
    </Dialog>

  );
};

AccountInfoDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  updateUserProfile: PropTypes.func.isRequired,
  openPasswordConfirmDialog: PropTypes.func.isRequired,
  onConfirmSubmit: PropTypes.func,
  classes: PropTypes.object.isRequired,
  fullScreen: PropTypes.bool.isRequired,
};

AccountInfoDialog.defaultProps = {
  onConfirmSubmit: () => null,
};

export default AccountInfoDialog;
