import React from 'react';
import PropTypes from 'prop-types';

import Button from '@material-ui/core/Button';
import { getExternalBillingStatus } from 'common/utils/billing';
import {
  STEP_STATUS,
  START_CUSTOM,
  FOLLOW,
  SIGN,
  STORE_VISIBILITY,
  IS_WARN,
} from 'common/utils/constants';
import { isStoreInstanceFollowed } from 'common/utils/instances';
import { isStoreItemRisky } from 'common/utils/storeItem';
import attentionIcon from 'common/assets/images/progress_dialog/attention_icon.svg';

const ShowcaseInstanceButton = ({
  classes,
  storeInstance,
  isCustomStoreInstance,
  instances,
  accountInfo,
  history,
  openAccountInfoDialog,
  openPlanSubscriptionDialog,
  updateStatusRegisterStep,
  openStartFollowInstance,
  openProgressDialog,
  logged,
  userInvestorProfileId,
}) => {
  const isInstanceSigned = storeInstance.signed;
  const isInstanceClosed = storeInstance.extra_data?.visibility === STORE_VISIBILITY.CLOSED;
  const { strategy_id: strategyId } = storeInstance;
  const isFollowed = isStoreInstanceFollowed({ instances, storeInstance });
  const isExternalBillingInstance = getExternalBillingStatus(strategyId);

  const dispatchActionWithValidation = ({ callback }) => {
    if (logged && accountInfo.status === STEP_STATUS.INCOMPLETE) {
      return openAccountInfoDialog(updateStatusRegisterStep);
    }

    return callback();
  };

  const getTypeActionButton = () => {
    if (isCustomStoreInstance && isInstanceSigned) {
      return START_CUSTOM;
    }
    if (isInstanceSigned) {
      return FOLLOW;
    }
    if (isInstanceClosed) {
      return STORE_VISIBILITY.CLOSED;
    }
    return SIGN;
  };

  const handleSignUpClick = (id) => {
    if (isStoreItemRisky(storeInstance, userInvestorProfileId)) {
      const props = {
        state: IS_WARN,
        title: 'Atenção!',
        customIcon: attentionIcon,
        message: 'Você está assinando uma carteira com um nível de risco acima do recomendado para seu perfil de investidor.',
        buttonMessage: 'Estou ciente',
        buttonClick: () => openPlanSubscriptionDialog({ strategyId: id }),
        showButton: true,
      };
      openProgressDialog(props);
    } else {
      openPlanSubscriptionDialog({ strategyId: id });
    }
  };

  const typeActionButton = getTypeActionButton();

  const actions = {
    sign: {
      id: 'sign-button',
      color: 'primary',
      name: 'Assinar',
      disabled: isInstanceSigned || isExternalBillingInstance,
      onClick: () => dispatchActionWithValidation({
        callback: () => handleSignUpClick(strategyId),
      }),
    },
    follow: {
      id: 'follow-button',
      color: 'inherit',
      className: classes.blueButton,
      name: isFollowed ? 'Seguindo' : 'Seguir',
      disabled: isFollowed,
      onClick: () => openStartFollowInstance({ strategyId }),
    },
    startCustom: {
      id: 'start-custom-button',
      color: 'primary',
      name: isFollowed ? 'Seguindo' : 'Começar',
      disabled: isFollowed,
      onClick: () => dispatchActionWithValidation({
        callback: () => history.replace('/private/criador-de-carteira/meus-templates'),
      }),
    },
    closed: {
      id: 'closed-wallet-button',
      color: 'primary',
      name: 'Fechada',
      disabled: true,
    },
  };

  return (
    <Button
      data-testid="showcase-instance-button"
      variant="contained"
      {...actions[typeActionButton]}
    >
      {actions[typeActionButton].name}
    </Button>
  );
};

ShowcaseInstanceButton.propTypes = {
  classes: PropTypes.object.isRequired,
  storeInstance: PropTypes.object.isRequired,
  openAccountInfoDialog: PropTypes.func.isRequired,
  openPlanSubscriptionDialog: PropTypes.func.isRequired,
  openProgressDialog: PropTypes.func.isRequired,
  updateStatusRegisterStep: PropTypes.func.isRequired,
  instances: PropTypes.object.isRequired,
  accountInfo: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  isCustomStoreInstance: PropTypes.bool,
  openStartFollowInstance: PropTypes.func.isRequired,
  logged: PropTypes.bool.isRequired,
  userInvestorProfileId: PropTypes.number,
};

ShowcaseInstanceButton.defaultProps = {
  isCustomStoreInstance: false,
  userInvestorProfileId: 0,
};

export default ShowcaseInstanceButton;
