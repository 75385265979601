import { formatMoney } from 'accounting';
import { DATE_FORMAT, NOW_TEXT, TODAY_TEXT } from './constants';

export const getOverLimitMessage = (limit) => {
  return `O limite de valor financeiro do seu plano é R$ ${formatMoney(limit, '', 2)}.
    <strong>Faça o upgrade para realizar este investimento</strong>
    ou realize um investimento respeitando o limite do seu plano. `;
};

export const getTransactionDescription = ({
  date,
  isWhiteboxInstance,
  isOptionNowSelected,
  operationDate,
}) => {
  const formattedDate = date.format(DATE_FORMAT);

  if (isOptionNowSelected) {
    if (operationDate === NOW_TEXT || operationDate.startsWith(TODAY_TEXT)) {
      return `Operação agora: ${formattedDate}`;
    }

    return `Operação agora: agendado para ${operationDate}`;
  }

  return isWhiteboxInstance
    ? 'Movimentação no próximo rebalanceamento'
    : `Operação na próxima rolagem: ${formattedDate}`;
};
