import { withStyles } from '@material-ui/styles';
import theme from 'common/theme';
import PublicHeader from './PublicHeader';

const styles = {
  button: {
    fontSize: 12,
  },
  header: {
    boxShadow: '0 3px 6px 0 rgba(0, 0, 0, 0.16)',
    paddingBottom: 16,
    paddingTop: 16,
  },
  headerContent: {
    display: 'flex',
    justifyContent: 'space-between',

    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      gap: 16,
    },

    [theme.breakpoints.up('md')]: {
      alignItems: 'center',
    },
  },
  logo: {
    alignSelf: 'baseline',
    height: 33,
    width: 159,
  },
};

export default withStyles(styles)(PublicHeader);
