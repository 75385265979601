import { withStyles } from '@material-ui/styles';

import ChipWithTypography from './ChipWithTypography';

const styles = {
  bold: {
    fontWeight: 'bold',
  },
};

export default withStyles(styles)(ChipWithTypography);
