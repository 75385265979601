import React from 'react';
import PropTypes from 'prop-types';

import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';

const PublicPagesButton = ({
  children,
  handleClick,
  active,
  loading,
  classes,
  variant,
}) => (
  <div>
    <Button
      className={classes.button}
      type="submit"
      onClick={handleClick}
      disabled={!active || loading}
      color="primary"
      variant={variant}
    >
      { children }
      {loading && (
        <CircularProgress
          className={classes.loadingIcon}
          size={20}
        />
      )}
    </Button>
  </div>
);

PublicPagesButton.propTypes = {
  children: PropTypes.string.isRequired,
  className: PropTypes.string,
  handleClick: PropTypes.func,
  active: PropTypes.bool,
  loading: PropTypes.bool,
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  variant: PropTypes.string,
};

PublicPagesButton.defaultProps = {
  className: '',
  handleClick: () => {},
  active: true,
  loading: false,
  variant: 'contained',
};

export default PublicPagesButton;
