import { withStyles } from '@material-ui/core/styles';

import palette from 'common/palette';
import ProgressDialog from './ProgressDialog';

const styles = {
  content: {
    textAlign: 'center',
    minWidth: 150,
    maxWidth: '100%',
    padding: 30,
    '&:first-child': {
      paddingTop: 30,
    },
  },
  icon: {
    width: 50,
    height: 50,
  },
  iconLoading: {
    width: 110,
    height: 110,
  },
  title: { margin: '10px 0' },
  message: {
    color: palette.textPrimary.main,
    fontSize: '1rem',
    marginBottom: 5,
    '& > a': {
      color: palette.primary.main,
      cursor: 'pointer',
      textDecoration: 'underline',
    },
  },
  button: {
    marginTop: 24,
    minWidth: 94,
  },
  paper: {
    maxWidth: 475,
  },
};

export const PROGRESS_DIALOG_CODE = 'PROGRESS_DIALOG_CODE';
export default withStyles(styles)(ProgressDialog);
