import React, { Component } from 'react';
import PropTypes from 'prop-types';
import logdown from 'logdown';
import { Field } from 'redux-form';

import CircularProgress from '@material-ui/core/CircularProgress';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';

import { closeDialog } from 'common/ducks/dialogs';
import { InputTextField } from 'common/components/ReduxForm';
import { required } from 'common/utils/validation';

export const ADD_COUPON_DIALOG_CODE = 'ADD_COUPON_DIALOG_CODE';

const logger = logdown('AddCouponDialog');

class AddCouponDialog extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
    };
  }

  componentDidMount = () => {
    this.props.resetForm();
  };

  submit = () => async (values, dispatch) => {
    const {
      resetForm,
      notifySuccess,
      notifyError,
      strategy,
      applyDiscount,
    } = this.props;
    const { coupon } = values;
    const data = {
      discount_code: coupon,
    };
    try {
      this.setState({ loading: true });
      const result = await applyDiscount(
        strategy.subscription.code,
        data,
      );

      notifySuccess({
        title: 'Sucesso',
        message: `Cupom ${coupon} aplicado! Agora você pode prosseguir utilizando o desconto.`,
      });
      logger.info('Cupom de desconto inserido com sucesso.', result);
      dispatch(closeDialog());
      resetForm();
    } catch (error) {
      if (error.code && error.code === 'subscription_already_has_discount') {
        notifyError({
          title: 'Erro!',
          message:
            error.codeMessage || 'Esta estratégia já possui um desconto cadastrado.',
        });
      } else {
        notifyError({
          title: 'Erro!',
          message:
            error.codeMessage || 'Falha ao inserir um cupom de desconto. Tente novamente.',
        });
      }

      logger.error('Falha ao inserir um cupom de desconto.', error);
      this.setState({ loading: false });
    }
  };

  render = () => {
    const {
      classes,
      onCancelClick,
      open,
      onClose,
      confirmButtonContent,
      cancelButtonContent,
      handleSubmit,
      pristine,
      submitting,
      strategy,
    } = this.props;

    const { card, loading } = this.state;
    return (
      <Dialog open={open} onClose={onClose}>
        <DialogTitle
          className={classes.title}
          disableTypography
        >
          Adicionar Cupom de desconto em&nbsp;
          {strategy.name}
        </DialogTitle>
        <DialogContent className={classes.content}>
          <form name="add-coupon-form" className={classes.formControl}>
            <Field
              id="coupon"
              name="coupon"
              component={InputTextField}
              type="text"
              validate={[required]}
              label="Cupom"
            />
          </form>
        </DialogContent>
        <DialogActions className={classes.content}>
          <Button onClick={onCancelClick || onClose} color="primary" autoFocus>
            {cancelButtonContent || 'Cancelar'}
          </Button>
          <div className={classes.wrapper}>
            <Button
              type="submit"
              disabled={pristine || submitting || loading}
              onClick={handleSubmit(this.submit(card)) || onClose}
              color="primary"
              autoFocus
            >
              {confirmButtonContent || 'Cadastrar'}
            </Button>
            {loading && (
              <CircularProgress size={24} className={classes.buttonProgress} />
            )}
          </div>
        </DialogActions>
      </Dialog>
    );
  };
}

AddCouponDialog.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onCancelClick: PropTypes.func,
  cancelButtonContent: PropTypes.any,
  confirmButtonContent: PropTypes.any,
  handleSubmit: PropTypes.func.isRequired,
  pristine: PropTypes.any,
  submitting: PropTypes.any,
  resetForm: PropTypes.func.isRequired,
  strategy: PropTypes.object.isRequired,
  notifyError: PropTypes.func.isRequired,
  notifySuccess: PropTypes.func.isRequired,
  applyDiscount: PropTypes.func.isRequired,
};

AddCouponDialog.defaultProps = {
  onCancelClick: null,
  cancelButtonContent: null,
  confirmButtonContent: null,
  pristine: null,
  submitting: null,
};

export default AddCouponDialog;
