import { size } from 'lodash';
import { getUserRealBrokeragesOverall, getBrokerageStatus } from 'common/utils/brokerage';
import {
  updateRegisterSteps,
} from 'common/ducks/registerSteps';
import { STEP_STATUS } from 'common/utils/constants';

export const isProfileInfoComplete = (profile) => (
  profile
  && profile.main_phone
  && profile.birthday
  && profile.state
  && profile.city
  && profile.postal_code
  && profile.neighborhood
  && profile.number
  && profile.address
  && profile.s10i_login
  && profile.email
  && profile.document
  && profile.gender
  && profile.name_or_corporate_name
);

export const isDocumentRegistered = (profile) => (
  profile
  && profile.document
);

export const getProfileInfoStatus = (isProfileComplete) => (
  isProfileComplete ? STEP_STATUS.COMPLETE : STEP_STATUS.INCOMPLETE);

export const checkUserRegistration = (props, dispatch) => {
  const {
    profile,
    brokerages,
  } = props;

  if (!size(profile) || !size(brokerages)) {
    return false;
  }

  const isProfileComplete = isProfileInfoComplete(profile);
  const brokeragesOverall = getUserRealBrokeragesOverall(Object.values(brokerages));

  const brokerageStatus = getBrokerageStatus(brokeragesOverall);
  const profileStatus = getProfileInfoStatus(isProfileComplete);

  const registerSteps = {
    accountInfo: {
      status: profileStatus,
    },
    brokerage: {
      status: brokerageStatus,
    },
    isRegisterProfileCompleted: profileStatus === STEP_STATUS.COMPLETE
      && brokerageStatus === STEP_STATUS.COMPLETE,
  };

  dispatch(updateRegisterSteps(registerSteps));

  return registerSteps.isRegisterProfileCompleted;
};

export default null;
