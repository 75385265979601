import React from 'react';
import PropTypes from 'prop-types';

// Material UI
import IconButton from '@material-ui/core/IconButton';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';

const StepButton = ({
  onClick,
  action,
  className,
  numberOfSteps,
  submitting,
  currentStep,
  disableNextButton,
}) => {
  let onClickAction = null;
  const isPreviousAction = action === 'previous';

  if (submitting) {
    onClickAction = onClick;
  } else {
    onClickAction = isPreviousAction
      ? () => onClick(currentStep > 1
        ? currentStep - 1
        : 1)
      : () => onClick(currentStep < numberOfSteps
        ? currentStep + 1
        : numberOfSteps);
  }
  return (
    <IconButton
      disabled={disableNextButton}
      type="submit"
      role="button"
      aria-label={`${isPreviousAction ? 'previous' : 'next'}-button`}
      onClick={onClickAction}
      className={className}
      id={`${action}-step-button`}
    >
      {isPreviousAction ? <ArrowBackIcon color="primary" /> : <ArrowForwardIcon color={disableNextButton ? 'disabled' : 'primary'} />}
    </IconButton>
  );
};

StepButton.propTypes = {
  submitting: PropTypes.bool,
  numberOfSteps: PropTypes.number.isRequired,
  currentStep: PropTypes.number.isRequired,
  onClick: PropTypes.func.isRequired,
  className: PropTypes.string,
  action: PropTypes.oneOf(['previous', 'next']).isRequired,
  disableNextButton: PropTypes.bool,
};

StepButton.defaultProps = {
  className: '',
  submitting: false,
  disableNextButton: false,
};

export default StepButton;
