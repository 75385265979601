import React, { Component } from 'react';
import { Formik } from 'formik';
import PropTypes from 'prop-types';
import get from 'lodash/get';

import { Box } from '@material-ui/core';

import PublicPagesBody from 'common/components/PublicPagesBody';
import PublicPagesButton from 'common/components/Buttons/PublicPagesButton';
import LinkButton from 'common/components/Buttons/LinkButton';

import Field from 'common/components/FormFields/Field';
import Captcha from 'common/components/Captcha';
import ssErrorInterpreter from 'common/interceptor/error/ss';
import { RecoverPasswordSchema } from 'common/utils/YupValidations';
import { LOGIN_URL } from 'common/utils/constants';
import { apiVersionPath } from 'common/api';

class RecoverPassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      captchaRequired: false,
    };
  }

  componentDidMount = async () => {
    const { rateCheck } = this.props;
    const resp = await rateCheck(`${apiVersionPath}/auth/passwordReset/request`);
    this.setState({ captchaRequired: resp.data.captcha_required });
  };

  isButtonActive = (errors, values) => {
    if (values.email === '') {
      return false;
    }
    return !(typeof errors.email !== 'undefined');
  };

  onSuccess = () => {
    const {
      notifySuccess,
      history,
    } = this.props;
    notifySuccess({
      title: 'Sucesso!',
      message: 'Se o email informado estiver cadastrado, '
        + 'será enviado para o mesmo o caminho para a redefinição da sua senha.',
    });
    history.replace('/public/auth/login');
  };

  onError = (error) => {
    const { notifyError } = this.props;
    if (get(error, 'codeMessage', false)) {
      notifyError({
        title: 'Erro!',
        message: error.codeMessage,
      });
      this.setState({ captchaRequired: (/(captcha)+\d/i).test(error.code) });
    } else {
      this.setState({ captchaRequired: false });
      const message = ssErrorInterpreter(error.code);
      notifyError({
        title: 'Erro!',
        message: message || 'Erro!',
      });
    }
    this.setState({ captchaRequired: true });
  };

  recoverPassword = async (values, actions) => {
    const { recoverPassword } = this.props;
    actions.setSubmitting(true);
    try {
      await recoverPassword(values);
      actions.setSubmitting(false);
      this.onSuccess();
    } catch (error) {
      actions.setSubmitting(false);
      this.onError(error);
    }
  };

  render() {
    const { captchaRequired } = this.state;
    return (
      <>
        <PublicPagesBody
          title="Recuperar senha"
          subtitle="Para recuperar sua senha, insira o e-mail cadastrado na plataforma."
        >
          <Formik
            validationSchema={RecoverPasswordSchema}
            validateOnChange
            initialValues={{ email: '', recaptcha_response: '', recaptcha_version: 2 }}
            onSubmit={this.recoverPassword}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              setFieldValue,
              handleSubmit,
              isSubmitting,
            }) => (
              <form onSubmit={handleSubmit}>
                <Field
                  name="email"
                  type="email"
                  handleChange={handleChange}
                  handleBlur={handleBlur}
                  value={values.email}
                  error={errors.email}
                  touched={touched.email}
                  label="E-mail"
                />
                {captchaRequired && <Captcha setFieldValue={setFieldValue} />}
                <PublicPagesButton
                  active={this.isButtonActive(errors, values)}
                  loading={isSubmitting}
                >
                  Recuperar
                </PublicPagesButton>
                <Box mt={1.5}>
                  <LinkButton path={LOGIN_URL}>Voltar</LinkButton>
                </Box>
              </form>
            )}
          </Formik>
        </PublicPagesBody>
      </>
    );
  }
}

RecoverPassword.propTypes = {
  recoverPassword: PropTypes.func.isRequired,
  rateCheck: PropTypes.func.isRequired,
  notifySuccess: PropTypes.func.isRequired,
  notifyError: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
};

export default RecoverPassword;
