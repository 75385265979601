import logdown from 'logdown';
import { LOGOUT_SUCCEED } from 'common/ducks/auth';
import { stopInstanceUpdater } from 'common/ducks/ducks-modules/instanceUpdater';
import api from 'common/api';

import { PROGRESS_DIALOG_CODE } from 'common/components/Dialogs/ProgressDialog';
import { IS_ERROR, ERROR } from 'common/utils/constants';
import instanceReducer, {
  DUCK_NAME as INSTANCE_DUCK_NAME,
  DELETE_INSTANCE_SUCCEED,
} from './instance';

import { reduceObjectsArrayToObject } from '../utils/array';
import { openDialog } from './dialogs';

export const DUCK_NAME = 'instances';
export const INITIAL_STATE = {
  data: {},
  loading: false,
  error: null,
};

export const INSTANCES_RETURN_ATTRIBUTES = [
  'id',
  'archived',
  'strategy_id',
  'brokerage_id',
  'simulation_type',
  'state',
  'name',
  'positioned',
  'params.stockCode',
  'params.stockCodesList',
  'params.marketName',
  'params.numberOfStocksToTrade',
  'params.POS_numberOfStocksToTrade',
  'params._numberOfStocksToTrade',
];

export const BACKTESTS_RETURN_ATTRIBUTES = [
  'id',
  'archived',
  'strategy_id',
  'brokerage_id',
  'name',
  'state',
  'end',
  'start',
];

const logger = logdown('instances ducks');
// Actions

export const GET_INSTANCES_STARTED = `${DUCK_NAME}/GET_INSTANCES_STARTED`;
export const GET_INSTANCES_SUCCEED = `${DUCK_NAME}/GET_INSTANCES_SUCCEED`;
export const GET_INSTANCES_FAILED = `${DUCK_NAME}/GET_INSTANCES_FAILED`;
export const getInstancesStarted = () => ({
  type: GET_INSTANCES_STARTED,
});
export const getInstancesSucceed = (data) => ({
  type: GET_INSTANCES_SUCCEED,
  data,
});
export const getInstancesFailed = (error) => ({
  type: GET_INSTANCES_FAILED,
  error,
});

export const getInstances = (params = {}) => async (dispatch) => {
  dispatch(getInstancesStarted());
  try {
    const { data } = await api.strategies.instances.getInstances({
      params,
    });

    const result = { instances: data.instances, total: data.total };
    dispatch(getInstancesSucceed(result));
    return Promise.resolve(result);
  } catch (error) {
    dispatch(getInstancesFailed(error));
    return Promise.reject(error);
  }
};

export const LOAD_INSTANCES_STARTED = `${DUCK_NAME}/LOAD_INSTANCES_STARTED`;
export const LOAD_INSTANCES_SUCCEED = `${DUCK_NAME}/LOAD_INSTANCES_SUCCEED`;
export const LOAD_INSTANCES_FAILED = `${DUCK_NAME}/LOAD_INSTANCES_FAILED`;

export const loadInstancesStarted = () => ({ type: LOAD_INSTANCES_STARTED });
export const loadInstancesSucceed = (data, variant) => (
  { type: LOAD_INSTANCES_SUCCEED, data, variant });
export const loadInstancesFailed = (error) => (
  { type: LOAD_INSTANCES_FAILED, error });

export const loadInstances = (strategyId, variant = 'instances', commit = true) => async (dispatch) => {
  if (commit) {
    dispatch(loadInstancesStarted());
  }

  try {
    const response = await api.strategies.instances.getInstancesByStrategyId(strategyId);

    if (commit) {
      dispatch(loadInstancesSucceed({
        instances: response.data[variant],
        total: response.data.total,
      }, variant));
    }

    return Promise.resolve({
      data: {
        instances: response.data[variant],
        total: response.data.total,
      },
    });
  } catch (error) {
    if (commit) {
      dispatch(loadInstancesFailed(error));
    }
    return Promise.reject(error);
  }
};

export const CREATE_INSTANCE_STARTED = `${DUCK_NAME}/CREATE_INSTANCE_STARTED`;
export const CREATE_INSTANCE_SUCCEED = `${DUCK_NAME}/CREATE_INSTANCE_SUCCEED`;
export const CREATE_INSTANCE_FAILED = `${DUCK_NAME}/CREATE_INSTANCE_FAILED`;
export const createInstanceStarted = () => ({
  type: CREATE_INSTANCE_STARTED,
});
export const createInstanceSucceed = (id, data) => ({
  id,
  data,
  type: CREATE_INSTANCE_SUCCEED,
});
export const createInstanceFailed = () => ({
  type: CREATE_INSTANCE_FAILED,
});
export const createInstance = (data) => async (dispatch) => {
  dispatch(createInstanceStarted());

  try {
    const result = await api.strategies.instances.createInstance(data);
    logger.info('create Instance', result);
    dispatch(createInstanceSucceed(result.data.id, data));

    return Promise.resolve(result.data);
  } catch (error) {
    logger.error('Failed to create Instance', error);
    dispatch(createInstanceFailed());

    dispatch(openDialog(
      PROGRESS_DIALOG_CODE,
      {
        state: IS_ERROR,
        title: ERROR,
        message: error.codeMessage
          || 'Algo inesperado ocorreu na criação da carteira. Por favor, tente novamente',
        showButton: true,
      },
    ));

    return Promise.reject(error);
  }
};

// Reducer
export default (state = INITIAL_STATE, action) => {
  // Logout cleaning
  if (action.type === LOGOUT_SUCCEED) {
    Object.values(state.data).forEach(({ id }) => {
      stopInstanceUpdater(id, 'instances');
    });
    return INITIAL_STATE;
  }

  switch (true) {
    case (action.type === CREATE_INSTANCE_STARTED):
      return {
        ...state,
      };
    case (action.type === CREATE_INSTANCE_SUCCEED):
      return {
        ...state,
        data: {
          ...state.data,
          [action.id]: {
            ...action.data,
          },
        },
      };
    case (action.type === CREATE_INSTANCE_FAILED):
      return {
        loading: false,
        ...state,
      };
    case (action.type === LOAD_INSTANCES_STARTED || action.type === GET_INSTANCES_STARTED):
      return {
        ...state,
        loading: true,
      };
    case (action.type === LOAD_INSTANCES_SUCCEED || action.type === GET_INSTANCES_SUCCEED):
      return {
        ...state,
        data: {
          ...state.data,
          ...action.data.instances
            .reduce((c, i) => ({
              ...c,
              [i.id]: {
                ...state.data[i.id],
                ...i,
              },
            }), {}),
        },
        loading: false,
      };
    case (action.type === LOAD_INSTANCES_FAILED || action.type === GET_INSTANCES_FAILED):
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    case (action.type === DELETE_INSTANCE_SUCCEED):
      return {
        ...state,
        data: reduceObjectsArrayToObject(
          Object.values(state.data)
            .filter((instance) => instance.id !== action.id),
          'id',
        ),
      };
    case new RegExp(`${INSTANCE_DUCK_NAME}/`).test(action.type):
      return {
        ...state,
        data: {
          ...state.data,
          [action.id]: instanceReducer(state.data[action.id], action),
        },
      };
    default: return state;
  }
};
