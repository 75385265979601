import React from 'react';
import PropTypes from 'prop-types';

import AccountCircleIcon from '@material-ui/icons/AccountCircle';

import MenuItem from '@material-ui/core/MenuItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import Divider from '@material-ui/core/Divider';

import Placeholder from 'common/components/Placeholder';
import { MY_ACCOUNT_URL } from 'common/utils/constants';

export const ERROR_LOADING_PROFILE_TEXT = 'Erro ao carregar informações do usuário';

const UserMenuItem = ({
  showIcon, classes, history, profile, handleMenu,
}) => {
  let profileMenuItem = null;
  if (profile.loading) {
    profileMenuItem = <Placeholder height={45} />;
  } else if (profile.error) {
    profileMenuItem = (
      <ListItemText
        id="user-menu-item-profile-error"
        primary={ERROR_LOADING_PROFILE_TEXT}
      />
    );
  } else if (profile.data) {
    profileMenuItem = (
      <ListItemText
        id="user-menu-item-profile"
        classes={{
          primary: classes.userNameText,
          secondary: classes.secondaryUserNameText,
        }}
        primary={profile.data.name_or_corporate_name}
        secondary={profile.data.s10i_login}
      />
    );
  }
  return (
    <>
      <MenuItem
        id="user-menu-item"
        className={classes.menuItem}
        onClick={() => {
          handleMenu({ currentTarget: null });
          history.replace(MY_ACCOUNT_URL);
        }}
      >
        {profile.data && showIcon && (
          <ListItemIcon className={classes.listItemIcon}>
            <AccountCircleIcon classes={classes.accountIcon} />
          </ListItemIcon>
        )}
        {profileMenuItem}
      </MenuItem>
      <Divider />
    </>
  );
};

UserMenuItem.propTypes = {
  showIcon: PropTypes.bool,
  classes: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  profile: PropTypes.object.isRequired,
  handleMenu: PropTypes.func,
};
UserMenuItem.defaultProps = {
  handleMenu: () => null,
  showIcon: false,
};

export default UserMenuItem;
