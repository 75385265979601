import { STEP_STATUS } from 'common/utils/constants';
import { LOGOUT_SUCCEED } from './auth';

export const DUCK_NAME = 'registerSteps';

const { INCOMPLETE } = STEP_STATUS;

export const INITIAL_STATE = {
  accountInfo: {},
  brokerage: {
    status: INCOMPLETE,
  },
  isRegisterProfileCompleted: null,
};

const UPDATE_REGISTER_STEPS = `${DUCK_NAME}/UPDATE_REGISTER_STEPS`;
export const updateRegisterSteps = (registerSteps) => {
  return { type: UPDATE_REGISTER_STEPS, registerSteps };
};

export default (state = INITIAL_STATE, action) => {
  // Logout cleaning
  if (action.type === LOGOUT_SUCCEED) {
    return INITIAL_STATE;
  }

  switch (action.type) {
    case UPDATE_REGISTER_STEPS:
      return {
        ...state,
        ...action.registerSteps,
      };
    default: return state;
  }
};
