import React from 'react';
import PropTypes from 'prop-types';
import { Box, Typography } from '@material-ui/core';

const EmptyState = ({
  text,
  icon,
}) => (
  <>
    {icon && (
      <Box mb={1.2}>
        <img src={icon} alt={text} />
      </Box>
    )}
    <Typography
      color="textPrimary"
      variant="h6"
    >
      {text}
    </Typography>
  </>
);

EmptyState.propTypes = {
  text: PropTypes.string.isRequired,
  icon: PropTypes.node,
};

EmptyState.defaultProps = {
  icon: null,
};

export default EmptyState;
