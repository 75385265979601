import React from 'react';
import PropTypes from 'prop-types';
import MenuItem from '@material-ui/core/MenuItem';
import ListSubheader from '@material-ui/core/ListSubheader';

import { getAccountHelperText, getGroupedBrokeragesByStatus } from 'common/utils/brokerage';
import {
  XP_BROKERAGE_ID,
  CLEAR_BROKERAGE_ID,
  MODALMAIS_BROKERAGE_ID,
  MODALMAIS_CC,
  BROKERAGE_MAP_STATES,
} from 'common/utils/constants';
import { BROKERAGES_FORM_STATUS, NAME } from 'common/utils/constants/brokerages';

import { onlyNumbers } from 'common/utils/string';
import { required, confirmEqual } from 'common/utils/validation';
import { InputTextField, SelectField } from 'common/components/ReduxForm';
import { orderByString } from 'common/utils/array';

const RegisterBrokerageForm = ({
  brokerages,
  handleChange,
  handleBlur,
  values,
  meta: {
    errors,
    touched,
  },
}) => {
  const APPROVED = BROKERAGE_MAP_STATES.approved;
  const PROCESSING = BROKERAGES_FORM_STATUS.processing;
  const {
    approvedBrokerages,
    availableBrokerages,
    processingBrokerages,
    pendingBrokerages,
  } = getGroupedBrokeragesByStatus(brokerages);
  const pendingAndProcessingBrokerages = orderByString(
    [...processingBrokerages, ...pendingBrokerages],
    NAME,
  );

  const getBrokeragesAsDisabledMenuItems = ({ brokeragesList, group }) => {
    if (brokeragesList.length) {
      return brokeragesList.map((item) => (
        <MenuItem
          key={item.id}
          disabled
        >
          {item.name}
        </MenuItem>
      ));
    }
    return (
      <MenuItem
        key={group}
        disabled
        style={{
          fontStyle: 'italic',
        }}
      >
        Não há corretora
        {' '}
        {group.toLowerCase()}
      </MenuItem>
    );
  };

  return (
    <div>
      <SelectField
        id="register-brokerage-form-select_brokerage"
        name="brokerage"
        value={values.brokerage}
        onBlur={handleBlur}
        onChange={handleChange}
        meta={{
          error: errors.brokerage,
          touched: touched.brokerage,
        }}
        helperText={[
          XP_BROKERAGE_ID,
          CLEAR_BROKERAGE_ID,
        ].includes(Number.parseInt(values.brokerage, 10))
          ? `Apesar das corretoras XP, Clear e Rico fazerem parte do mesmo grupo,
          as contas de cada corretora são independentes. Caso seja informada a
          conta de uma das outras corretoras do grupo,
          sua conta não será habilitada.`
          : ''}
        label="Corretora"
      >
        <ListSubheader>Disponível</ListSubheader>
        {availableBrokerages.map((item) => (
          <MenuItem
            key={item.id}
            value={item.id}
          >
            {item.name}
          </MenuItem>
        ))}
        <ListSubheader>{PROCESSING}</ListSubheader>
        {getBrokeragesAsDisabledMenuItems({
          brokeragesList: pendingAndProcessingBrokerages,
          group: PROCESSING,
        })}
        <ListSubheader>{APPROVED}</ListSubheader>
        {getBrokeragesAsDisabledMenuItems({
          brokeragesList: approvedBrokerages,
          group: APPROVED,
        })}
      </SelectField>
      <InputTextField
        id="register-brokerage-form-brokerage_code"
        name="bmfcode"
        onBlur={handleBlur}
        onChange={(e) => {
          e.target.value = onlyNumbers(e.target.value);
          handleChange(e);
        }}
        value={values.bmfcode}
        meta={{
          error: errors.bmfcode,
          touched: touched.bmfcode,
        }}
        helperText={getAccountHelperText(values.brokerage)}
        label="Conta na corretora"
      />
      <InputTextField
        id="register-brokerage-form-brokerage_code_confirm"
        name="bmfcode_confirm"
        onBlur={handleBlur}
        onChange={
        (e) => {
          e.target.value = onlyNumbers(e.target.value);
          handleChange(e);
        }
      }
        onPaste={(e) => e.preventDefault()}
        value={values.bmfcode_confirm}
        meta={{
          error: errors.bmfcode_confirm,
          touched: touched.bmfcode_confirm,
        }}
        type="text"
        label="Confirme conta na corretora"
      />
    </div>
  );
};

export const registerBrokerageValidation = (values) => {
  const errors = {};
  const brokerageError = required(values.brokerage);
  let bmfcodeError = required(values.bmfcode);
  const bmfcodeConfirmError = required(values.bmfcode_confirm)
    || confirmEqual('bmfcode')(values.bmfcode_confirm, values);

  if (
    Number.parseInt(values.brokerage, 10) === MODALMAIS_BROKERAGE_ID
    && Number.parseInt(values.bmfcode, 10) === MODALMAIS_CC
  ) {
    bmfcodeError = `A conta digitada é a conta-corrente da corretora modalmais.
    Você encontra a sua conta da corretora na parte superior da página após
    logar no website da modalmais.`;
  }
  if (brokerageError) {
    errors.brokerage = brokerageError;
  }
  if (bmfcodeError) {
    errors.bmfcode = bmfcodeError;
  }
  if (bmfcodeConfirmError) {
    errors.bmfcode_confirm = bmfcodeConfirmError;
  }

  return errors;
};

RegisterBrokerageForm.propTypes = {
  brokerages: PropTypes.object.isRequired,
  handleChange: PropTypes.func.isRequired,
  handleBlur: PropTypes.func.isRequired,
  meta: PropTypes.object.isRequired,
  values: PropTypes.object.isRequired,
};

export default RegisterBrokerageForm;
