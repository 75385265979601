import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import {
  Box,
  Button,
  Typography,
} from '@material-ui/core';

import { INVESTOR_PROFILE_TYPES } from 'common/utils/constants/investorProfile';
import { SI_ZENDESK_ARTICLES_URL } from 'common/utils/constants';
import GrayBox from '../GrayBox';

const InvestorProfileResult = ({
  classes,
  type,
  setQuestionnaireInitialState,
}) => {
  const investorProfile = INVESTOR_PROFILE_TYPES[type];

  return (
    <Box>
      <Typography variant="h6" className={classes.fontNormal}>
        O seu perfil é
      </Typography>

      <Typography variant="h1" className={classes.resultProfile}>
        {investorProfile.name}
      </Typography>

      <Box mb={3}>
        {investorProfile.description.split('\n').map((text) => (
          <Typography
            key={text}
            variant="h6"
            paragraph
            className={classes.fontNormal}
          >
            {text}
          </Typography>
        ))}
      </Box>

      <GrayBox mb={3.2}>
        <Typography color="textSecondary" variant="h6" className={classes.fontNormal}>
          Trilha de conhecimento
        </Typography>

        <Box my={1}>
          <Typography variant="h6" className={classes.fontNormal}>
            Separamos uma seleção de conteúdos para você saber exatamente como o
            seu perfil deve investir através da SmarttInvest!
          </Typography>
        </Box>

        <Box display="flex" justifyContent="center" mt={2}>
          <Link
            to={{
              pathname: `${SI_ZENDESK_ARTICLES_URL}/${investorProfile.articleId}`,
            }}
            target="_blank"
          >
            <Button
              color="primary"
              variant="contained"
              size="small"
            >
              Acessar
            </Button>
          </Link>
        </Box>
      </GrayBox>

      <Box display="flex" justifyContent="flex-end">
        <Button
          color="primary"
          variant="outlined"
          size="small"
          onClick={setQuestionnaireInitialState}
        >
          Refazer teste
        </Button>
      </Box>
    </Box>
  );
};

InvestorProfileResult.propTypes = {
  classes: PropTypes.object.isRequired,
  type: PropTypes.number.isRequired,
  setQuestionnaireInitialState: PropTypes.func.isRequired,
};

export default InvestorProfileResult;
