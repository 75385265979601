import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/styles';

const BlueButton = withStyles(() => ({
  root: {
    backgroundColor: '#025ab9',
    color: '#fff',
    '&:hover': {
      backgroundColor: '#002f7d',
    },
    '&.Mui-disabled:hover': {
      backgroundColor: '#b4b6b8',
    },
  },
}))(Button);

export default BlueButton;
