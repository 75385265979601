import React from 'react';
import PropTypes from 'prop-types';

import {
  Dialog,
  DialogContent,
  DialogContentText,
  Button,
  Typography,
} from '@material-ui/core';

import CloseDialogButton from 'common/components/Buttons/CloseDialogButton';

const AnswerResearchDialog = ({
  classes,
  open,
  onClose,
  userEmail,
  ...other
}) => (
  <Dialog
    maxWidth="md"
    open={open}
    onClose={onClose}
    {...other}
  >
    <CloseDialogButton onClose={onClose} />
    <DialogContent className={classes.dialogContent}>
      <Typography variant="h1" className={classes.title}>
        Ganhe posições na lista de espera
      </Typography>
      <DialogContentText className={classes.contentText} component="div">
        Queremos saber um pouco mais sobre você!
        Responda a pesquisa abaixo e ganhe posições na fila!
      </DialogContentText>

      <Button
        className={classes.button}
        color="primary"
        variant="contained"
        onClick={() => {
          window.open(`https://docs.google.com/forms/d/e/1FAIpQLSdcJ1yuTUg22DlBqH2xCdihmuE9qlEl5yAzy9F-kjsjFqtIMw/viewform?entry.804247457=${userEmail}`, '_blank');
        }}
      >
        Responder agora
      </Button>
    </DialogContent>
  </Dialog>
);

AnswerResearchDialog.propTypes = {
  classes: PropTypes.object.isRequired,
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  userEmail: PropTypes.string.isRequired,
};

export default AnswerResearchDialog;
