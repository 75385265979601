import { withStyles } from '@material-ui/core';
import { connect } from 'react-redux';
import { openDialog } from 'common/ducks/dialogs';

import { rebalanceInstance } from 'common/ducks/instance';
import { CONFIRMATION_DIALOG_CODE } from 'common/components/Dialogs/ConfirmationDialog';
import { REBALANCE_STOCKS_DIALOG_CODE } from 'common/components/Dialogs/RebalanceStocksDialog';
import RebalanceButton from './RebalanceButton';

const styles = {
  iconButton: {
    marginRight: 10,
  },
  rebalanceConfirmDialog: {
    maxWidth: 550,
  },
};

const mapStateToProps = ({
  instances,
  whitebox,
  positions,
}, { instanceId }) => ({
  instance: instances.data[instanceId],
  whitebox,
  positions: positions[instanceId].data.mergedPositions || {},
});

const mapDispatchToProps = (dispatch, { instanceId, handleOpenOrdersDialog }) => ({
  openConfirmationDialog: (props) => dispatch(openDialog(
    CONFIRMATION_DIALOG_CODE,
    { ...props },
  )),
  rebalanceInstance: () => dispatch(
    rebalanceInstance({ instanceId, handleOpenOrdersDialog }),
  ),
  openRebalanceStocksDialog: (props) => dispatch(
    openDialog(REBALANCE_STOCKS_DIALOG_CODE, { ...props }),
  ),
});

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(RebalanceButton));
