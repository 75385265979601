import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Box, Typography } from '@material-ui/core';
import {
  AreaChart,
  Area,
  XAxis,
  YAxis,
  Legend,
  Tooltip,
  CartesianGrid,
  ReferenceDot,
  ReferenceLine,
  ResponsiveContainer,
} from 'recharts';

import palette from 'common/palette';
import { getStoreInstanceChartExtraData } from 'common/utils/chart';
import { getRoundedAndFormattedDate } from 'common/utils/date';
import { checkIfItsStoreItem } from 'common/utils/instance';
import {
  PRE_SI_PERFORMANCE_ZONE_GROUP_LABEL,
  POST_SI_PERFORMANCE_ZONE_GROUP_LABEL,
} from 'common/utils/constants/chart';
import TitleTooltip from '../TitleTooltip';
import ChartTooltip from './ChartTooltip';

const PerformanceChart = ({
  classes,
  height,
  className,
  data,
  XAxisDataKey,
  XAxisTickFormatter,
  tooltipSettings,
  gradient,
  area,
  storeInstance,
  instanceCumulativePerformance,
}) => {
  const isStoreInstance = checkIfItsStoreItem(storeInstance);
  const instanceGroup = storeInstance?.extra_data?.group;
  const netReturnGradient = gradient[0];
  const {
    formattedDate: formattedLaunchDate,
    roundedAndFormattedDate: roundedLaunchDate,
  } = isStoreInstance && getRoundedAndFormattedDate(new Date(storeInstance?.launch));
  const { launchDateRatio, launchMonthNetReturn, updatedChartData } = isStoreInstance
    && getStoreInstanceChartExtraData({
      chartData: data,
      instanceCumulativePerformance,
      launchDate: formattedLaunchDate,
      roundedLaunchDate,
    });

  const renderLegend = () => {
    const { label, tooltipText } = PRE_SI_PERFORMANCE_ZONE_GROUP_LABEL[instanceGroup];

    return (
      <Box className={classes.legend}>
        <Box
          mr={2}
          display="flex"
          alignItems="center"
          className={classes.mobile}
        >
          <Box
            mr={1}
            className={classes.legendColor}
            style={{ backgroundColor: palette.primary.main, opacity: 0.3 }}
          />
          <TitleTooltip
            tooltipSide="right"
            color="textPrimary"
            label={label}
            tooltip={{
              text: (
                <>
                  <b>{label}</b>
                  :
                  {' '}
                  {tooltipText}
                </>
              ),
            }}
            variant="inherit"
          />
        </Box>
        <Box display="flex" alignItems="center">
          <Box
            mr={1}
            className={classes.legendColor}
            style={{ backgroundColor: netReturnGradient.color, opacity: 0.5 }}
          />
          <Typography
            className={classes.mobile}
            color="textPrimary"
            variant="h6"
          >
            {POST_SI_PERFORMANCE_ZONE_GROUP_LABEL}
          </Typography>
        </Box>
      </Box>
    );
  };

  return (
    <div className={classNames(classes.container, className)}>
      <ResponsiveContainer height={height}>
        <AreaChart data={isStoreInstance ? updatedChartData : data}>
          <defs>
            {isStoreInstance
              ? (
                <>
                  <linearGradient
                    key={netReturnGradient.id}
                    id={netReturnGradient.id}
                    x1={0}
                    y1={0}
                    x2={1}
                    y2={0}
                  >
                    <stop
                      offset="0%"
                      stopColor={palette.primary.main}
                      stopOpacity={netReturnGradient.opacity1}
                    />
                    <stop
                      offset={launchDateRatio}
                      stopColor={palette.primary.main}
                      stopOpacity={0}
                    />
                    <stop
                      offset={launchDateRatio}
                      stopColor={netReturnGradient.color}
                      stopOpacity={netReturnGradient.opacity2}
                    />
                    <stop
                      offset="100%"
                      stopColor={netReturnGradient.color}
                      stopOpacity={netReturnGradient.opacity1}
                    />
                  </linearGradient>
                  {gradient.slice(1).map((grad) => (
                    <linearGradient
                      key={grad.id}
                      id={grad.id}
                      x1={0}
                      y1={0}
                      x2={0}
                      y2={1}
                    >
                      <stop
                        offset="5%"
                        stopColor={grad.color}
                        stopOpacity={grad.opacity1}
                      />
                      <stop
                        offset="95%"
                        stopColor={grad.color}
                        stopOpacity={grad.opacity2}
                      />
                    </linearGradient>
                  ))}
                </>
              )
              : gradient.map((grad) => (
                <linearGradient
                  key={grad.id}
                  id={grad.id}
                  x1={0}
                  y1={0}
                  x2={0}
                  y2={1}
                >
                  <stop
                    offset="5%"
                    stopColor={grad.color}
                    stopOpacity={grad.opacity1}
                  />
                  <stop
                    offset="95%"
                    stopColor={grad.color}
                    stopOpacity={grad.opacity2}
                  />
                </linearGradient>
              ))}
          </defs>
          <Tooltip
            content={(
              <ChartTooltip
                details={tooltipSettings}
                isStoreInstance={isStoreInstance}
                launchDate={formattedLaunchDate}
                group={instanceGroup}
              />
            )}
          />
          {area.map((areaLine) => (
            <Area
              key={areaLine.dataKey}
              name={areaLine.name}
              type="natural"
              strokeWidth={areaLine.strokeWidth}
              isAnimationActive
              dataKey={areaLine.dataKey}
              stroke={areaLine.stroke}
              fillOpacity={1}
              fill={areaLine.fill}
            />
          ))}
          <CartesianGrid
            stroke={palette.default.main}
            strokeDasharray="5 5"
            vertical={false}
          />
          <XAxis
            tickFormatter={XAxisTickFormatter}
            padding={{
              top: 0,
              bottom: 0,
              left: 0,
              right: 0,
            }}
            tick={{ fontSize: 11 }}
            dataKey={XAxisDataKey}
            minTickGap={20}
          />
          <YAxis domain={['dataMin', 'dataMax']} hide />
          {isStoreInstance
            && <Legend content={renderLegend} align="left" wrapperStyle={{ top: 10, left: 10 }} />}
          <ReferenceLine
            stroke={palette.primary.main}
            x={formattedLaunchDate}
            strokeDasharray="5 5"
          />
          <ReferenceDot
            fill={palette.primary.main}
            x={formattedLaunchDate}
            y={launchMonthNetReturn}
            r={5}
          />
        </AreaChart>
      </ResponsiveContainer>
    </div>
  );
};

PerformanceChart.propTypes = {
  classes: PropTypes.object.isRequired,
  XAxisDataKey: PropTypes.string.isRequired,
  XAxisTickFormatter: PropTypes.func,
  data: PropTypes.array.isRequired,
  className: PropTypes.string,
  height: PropTypes.number,
  gradient: PropTypes.array.isRequired,
  area: PropTypes.array.isRequired,
  tooltipSettings: PropTypes.object,
  storeInstance: PropTypes.object.isRequired,
  instanceId: PropTypes.number,
  instanceCumulativePerformance: PropTypes.array.isRequired,
};

PerformanceChart.defaultProps = {
  className: '',
  height: 150,
  tooltipSettings: {
    colorful: false,
    symbol: '',
    symbolLocation: 'left',
  },
  XAxisTickFormatter: (tick) => (tick),
  instanceId: null,
};

export default PerformanceChart;
