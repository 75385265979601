import React from 'react';
import PropTypes from 'prop-types';
import { RECAPTCHA_SITEKEY, TABLET_WIDTH_BREAKPOINT } from 'common/utils/constants';
import ReCAPTCHA from 'react-recaptcha';

const Captcha = ({
  setFieldValue,
}) => {
  const isMobile = window.innerWidth <= TABLET_WIDTH_BREAKPOINT;
  return (
    <div>
      <ReCAPTCHA
        sitekey={RECAPTCHA_SITEKEY}
        verifyCallback={(response) => {
          setFieldValue('recaptcha_response', response);
        }}
        size={isMobile ? 'compact' : 'normal'}
        hl="pt-BR"
      />
    </div>
  );
};

Captcha.propTypes = {
  setFieldValue: PropTypes.func.isRequired,
};

export default Captcha;
