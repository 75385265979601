import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { TextField, InputAdornment, IconButton } from '@material-ui/core';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import InputMask from 'react-input-mask';

const Field = ({
  classes,
  className,
  touched,
  mask,
  error,
  handleChange,
  handleBlur,
  type,
  name,
  value,
  startAdornment,
  ...rest
}) => {
  const [showPassword, setShowPassword] = useState(false);
  const shouldDisplayError = touched && Boolean(error);

  const showPasswordIcon = (
    <InputAdornment position="end">
      <IconButton
        aria-label="toggle password visibility"
        onClick={() => setShowPassword(!showPassword)}
        onMouseDown={(e) => e.preventDefault()}
      >
        {showPassword ? <Visibility /> : <VisibilityOff />}
      </IconButton>
    </InputAdornment>
  );

  const renderTextField = (
    <TextField
      onChange={handleChange}
      onBlur={handleBlur}
      name={name}
      value={value}
      type={showPassword ? 'text' : type}
      error={shouldDisplayError}
      className={classes.input}
      InputLabelProps={{ className: classes.inputLabel }}
      InputProps={{
        endAdornment: type === 'password' ? showPasswordIcon : null,
        startAdornment: startAdornment || null,
        className: classes.inputField,
      }}
      helperText={shouldDisplayError ? error : ''}
      FormHelperTextProps={{ classes: { root: classes.helperText } }}
      {...rest}
    />
  );

  if (mask) {
    return (
      <InputMask
        mask="999.999.999-99"
        {...rest}
        onChange={handleChange}
        onBlur={handleBlur}
        value={value}
      >
        {() => renderTextField}
      </InputMask>
    );
  }

  return renderTextField;
};

Field.propTypes = {
  classes: PropTypes.object.isRequired,
  className: PropTypes.string,
  mask: PropTypes.string,
  error: PropTypes.string,
  touched: PropTypes.bool,
  handleChange: PropTypes.func,
  handleBlur: PropTypes.func,
  type: PropTypes.string,
  name: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
};

Field.defaultProps = {
  className: '',
  mask: '',
  error: '',
  touched: false,
  type: 'text',
  handleChange: () => {},
  handleBlur: () => {},
};

export default Field;
