import { connect } from 'react-redux';
import { error, success } from 'react-notification-system-redux';
import { withMobileDialog } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

import { updateInstance } from 'common/ducks/instance';
import palette from 'common/palette';

import ShareWalletLinkDialog from './ShareWalletLinkDialog';

const styles = {
  buttonLoading: {
    marginLeft: 10,
  },
  checkboxesContainer: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  containerRoot: {
    display: 'flex',
    justifyContent: 'center',
    margin: '10px 0 20px',
  },
  contentText: {
    marginBottom: 12,
    color: palette.textPrimary.main,
    fontSize: 16,
  },
  dialog: {
    maxWidth: '500px',
  },
  dialogTitle: {
    fontSize: 24,
    fontWeight: 700,
    paddingBottom: 0,
    paddingTop: '24px',
  },
  textField: {
    flexGrow: 2,
    '& > div > input': {
      fontSize: '16px',
      padding: '10px 14px !important',
    },
    marginBottom: 0,
    marginRight: '10px',
  },
  formLabelRoot: {
    marginLeft: '-8px',
  },
  formLabelText: {
    fontSize: '16px',
    fontWeight: 400,
  },
};

export const SHARE_WALLET_LINK_DIALOG_CODE = 'SHARE_WALLET_LINK_DIALOG_CODE';

const mapStateToProps = ({ instances }, { instanceId }) => ({
  instance: instances.data[instanceId],
});

const mapDispatchToProps = (dispatch) => ({
  updateInstance: (instanceId, data) => dispatch(
    updateInstance(instanceId, data),
  ),
  notifyError: (props) => dispatch(error(props)),
  notifySuccess: (props) => dispatch(success(props)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withStyles(styles)(withMobileDialog()(ShareWalletLinkDialog)));
