import moment from 'moment';

export const addDefaultLogo = (e, path) => {
  e.target.src = path;
};

export const getFadeTimeoutByIndex = ({ index, divisor = 4, minMillisecond = 500 }) => {
  return minMillisecond + (index % divisor) * 100;
};

export const chartDateFormatter = (date, format) => {
  return moment(date).isValid() ? moment(date).format(format) : date;
};

export const getObjectKeysInArrayList = (items) => {
  return Object.keys(items).map((key) => key);
};

export const isNotUndefinedAndNotNull = (value) => value !== undefined && value !== null;

export const timeout = (ms) => {
  return new Promise((resolve) => setTimeout(resolve, ms));
};

export const concatenateListsWithoutRepetition = (listA, listB) => {
  return listA.concat(listB.filter((item) => listA.indexOf(item) < 0));
};

export const executeScroll = (element) => {
  if (element) {
    element.scrollIntoView({ behavior: 'smooth' });
  }
};
