import { LOGOUT_SUCCEED } from './auth';

export const DUCK_NAME = 'dialogs';
export const INITIAL_STATE = {
  currentDialogOpened: '',
  currentSideDialogOpened: '',
  dialogProps: {},
  sideDialogProps: {},
};

export const OPEN_DIALOG = `${DUCK_NAME}/OPEN_DIALOG`;
export const openDialog = (dialogCode, dialogProps = {}) => {
  return {
    type: OPEN_DIALOG,
    dialogCode,
    dialogProps,
  };
};

export const CLOSE_DIALOG = `${DUCK_NAME}/CLOSE_DIALOG`;
export const closeDialog = () => ({ type: CLOSE_DIALOG });

export const OPEN_SIDE_DIALOG = `${DUCK_NAME}/OPEN_SIDE_DIALOG`;
export const openSideDialog = (dialogCode, sideDialogProps = {}) => {
  return {
    type: OPEN_SIDE_DIALOG,
    dialogCode,
    sideDialogProps,
  };
};

export const CLOSE_SIDE_DIALOG = `${DUCK_NAME}/CLOSE_SIDE_DIALOG`;
export const closeSideDialog = () => ({ type: CLOSE_SIDE_DIALOG });

export default (state = INITIAL_STATE, action) => {
  // Logout cleaning
  if (action.type === LOGOUT_SUCCEED) {
    return INITIAL_STATE;
  }

  switch (action.type) {
    case OPEN_DIALOG:
      return {
        ...state,
        currentDialogOpened: action.dialogCode,
        dialogProps: action.dialogProps,
      };
    case CLOSE_DIALOG:
      return {
        ...state,
        currentDialogOpened: '',
      };

    case OPEN_SIDE_DIALOG:
      return {
        ...state,
        currentSideDialogOpened: action.dialogCode,
        sideDialogProps: action.sideDialogProps,
      };
    case CLOSE_SIDE_DIALOG:
      return {
        ...state,
        currentSideDialogOpened: '',
      };
    default: return state;
  }
};
