import moment from 'moment';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import { LOGOUT_SUCCEED } from 'common/ducks/auth';
import { STOPPED_STATES } from 'common/utils/constants';
import {
  generateChartData,
  adjustDataStartPosition,
} from 'common/utils/dailyCumulativePerformance';
import api from 'common/api';
import { getDefaultStartDates } from 'common/utils/date';
import { getStoreItemByInstanceId } from 'common/utils/storeItem';
import { isHolidayOrWeekendDay } from './holidays';

export const DUCK_NAME = 'dailyCumulativePerformance';

export const INITIAL_STATE = {
  // Expected structure:
  // [the instance id]: INSTANCE_DAILY_CUMULATIVE_PERFORMANCE_INITIAL_STATE,
};

export const INSTANCE_DAILY_CUMULATIVE_PERFORMANCE_INITIAL_STATE = {
  loading: false,
  isDataReady: false,
  error: null,
  data: [],
  lastUpdateDatetime: null,
  startDates: {},
};

// Actions
export const GET_START_DATES_ACTION = `${DUCK_NAME}/GET_START_DATES_ACTION`;

export const getStartDatesAction = (instanceId, data) => ({
  instanceId,
  data,
  type: GET_START_DATES_ACTION,
});

export const getStartDates = (instanceId) => (dispatch, getState) => {
  const startDates = getState()[DUCK_NAME][instanceId]?.startDates;

  if (startDates && !isEmpty(Object.keys(startDates))) return startDates;

  const dates = getDefaultStartDates((date) => dispatch(isHolidayOrWeekendDay(date)));
  dispatch(getStartDatesAction(instanceId, dates));
  return dates;
};

export const LOAD_INSTANCE_DAILY_CUMULATIVE_PERFORMANCE_STARTED = `${DUCK_NAME}/
LOAD_INSTANCE_DAILY_CUMULATIVE_PERFORMANCE_STARTED`;
export const LOAD_INSTANCE_DAILY_CUMULATIVE_PERFORMANCE_SUCCEED = `${DUCK_NAME}/
LOAD_INSTANCE_DAILY_CUMULATIVE_PERFORMANCE_SUCCEED`;
export const LOAD_INSTANCE_DAILY_CUMULATIVE_PERFORMANCE_FAILED = `${DUCK_NAME}/
LOAD_INSTANCE_DAILY_CUMULATIVE_PERFORMANCE_FAILED`;

// Actions Creators
export const loadInstanceDailyCumulativePerformanceStarted = (instanceId) => ({
  instanceId,
  type: LOAD_INSTANCE_DAILY_CUMULATIVE_PERFORMANCE_STARTED,
});
export const loadInstanceDailyCumulativePerformanceSucceed = (
  instanceId, data,
) => ({
  instanceId,
  data,
  type: LOAD_INSTANCE_DAILY_CUMULATIVE_PERFORMANCE_SUCCEED,
});
export const loadInstanceDailyCumulativePerformanceFailed = (
  instanceId, error,
) => ({
  instanceId,
  error,
  type: LOAD_INSTANCE_DAILY_CUMULATIVE_PERFORMANCE_FAILED,
});
export const getDataDailyCumulativePerformance = ({
  instanceId,
  isPublic,
  variant,
}) => async (_, getState) => {
  if (isPublic) {
    const { storeItems } = getState();

    const currentStoreItem = getStoreItemByInstanceId(
      instanceId,
      Object.values(storeItems.data.itemsWithPrivate),
    );
    return {
      data: {
        daily_cumulative_performance: currentStoreItem.instance.daily_cumulative_performance,
      },
    };
  }

  const response = await api.strategies.instances
    .getInstanceDailyCumulativePerformance(instanceId, {
      params: {
        return_attributes: 'daily_cumulative_performance',
      },
    }, variant);

  return response;
};
export const loadInstanceDailyCumulativePerformance = (
  instanceId,
  options = { reload: false },
  variant = 'instances',
  isPublic,
) => async (dispatch, getState) => {
  dispatch(getStartDates(instanceId));
  const dailyCumulativePerformance = getState()[DUCK_NAME];
  const instanceDailyCumulativePerformance = dailyCumulativePerformance[instanceId]
    ? dailyCumulativePerformance[instanceId]
    : {};
  const instanceState = get(getState().instances, `data.${instanceId}.state`);

  if (
    !options.reload
    && (
      (
        dailyCumulativePerformance
          && !instanceDailyCumulativePerformance.error
          && instanceDailyCumulativePerformance.data?.length
      ) || (
        dailyCumulativePerformance
          && instanceDailyCumulativePerformance.data?.length
          && instanceState
          && STOPPED_STATES.includes(instanceState)
      ) || (
        dailyCumulativePerformance
          && instanceDailyCumulativePerformance
          && instanceDailyCumulativePerformance.loading
      )
    )
  ) {
    return Promise.resolve(instanceDailyCumulativePerformance);
  }

  dispatch(loadInstanceDailyCumulativePerformanceStarted(instanceId));
  try {
    const response = await dispatch(getDataDailyCumulativePerformance({
      instanceId,
      isPublic,
      variant,
    }));

    const formattedResponse = generateChartData(response.data.daily_cumulative_performance);
    const adjustedResponse = adjustDataStartPosition(formattedResponse);
    dispatch(loadInstanceDailyCumulativePerformanceSucceed(
      instanceId,
      adjustedResponse,
    ));
    return Promise.resolve(response);
  } catch (error) {
    dispatch(loadInstanceDailyCumulativePerformanceFailed(
      instanceId,
      error,
    ));
    return Promise.reject(error);
  }
};

export const dailyCumulativePerformanceReducer = (
  state = INSTANCE_DAILY_CUMULATIVE_PERFORMANCE_INITIAL_STATE,
  action,
) => {
  switch (action.type) {
    case LOAD_INSTANCE_DAILY_CUMULATIVE_PERFORMANCE_STARTED:
      return {
        ...state,
        loading: true,
      };
    case LOAD_INSTANCE_DAILY_CUMULATIVE_PERFORMANCE_SUCCEED:
      return {
        ...state,
        lastUpdateDatetime: moment().toISOString(),
        data: action.data,
        error: null,
        loading: false,
        isDataReady: true,
      };
    case LOAD_INSTANCE_DAILY_CUMULATIVE_PERFORMANCE_FAILED:
      return {
        ...state,
        error: action.error,
        loading: false,
        isDataReady: true,
      };
    case GET_START_DATES_ACTION:
      return {
        ...state,
        startDates: action.data,
      };
    default: return state;
  }
};

export default (state = INITIAL_STATE, action) => {
  // Logout cleaning
  if (action.type === LOGOUT_SUCCEED) {
    return INITIAL_STATE;
  }

  const { instanceId } = action;

  if (instanceId) {
    return {
      ...state,
      [instanceId]: dailyCumulativePerformanceReducer(
        state[instanceId],
        action,
      ),
    };
  }

  return state;
};
