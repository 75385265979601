import React from 'react';
import PropTypes from 'prop-types';

import { Box, Typography } from '@material-ui/core';
import { NOW_TEXT } from 'common/utils/constants';
import { NOT_REBALANCE_TEXT } from 'common/utils/constants/instance';
import WrappedChip from '../WrappedChip';

const ChipWithTypography = ({
  classes,
  text,
  chipText,
}) => {
  const ignoreChipScheduling = [NOW_TEXT, NOT_REBALANCE_TEXT];

  return (
    <Box display="flex">
      {!ignoreChipScheduling.includes(text) && (
        <Box display="flex" mr={0.5}>
          <WrappedChip
            color="info"
            className={classes.attentionButton}
            label={chipText}
            size="small"
          />
        </Box>
      )}
      <Typography className={classes.bold} variant="h3">
        {text}
      </Typography>
    </Box>
  );
};

ChipWithTypography.propTypes = {
  classes: PropTypes.object.isRequired,
  text: PropTypes.string.isRequired,
  chipText: PropTypes.string,
};

ChipWithTypography.defaultProps = {
  chipText: 'agendado',
};

export default ChipWithTypography;
