import React from 'react';
import PropTypes from 'prop-types';
import { Box, Button, Typography } from '@material-ui/core';
import { getCapitalizedText } from 'common/utils/string';
import GrayBox from '../GrayBox';

const InvestorProfileStart = ({
  username,
  setStartQuestionnaire,
}) => {
  const firstName = username.split(' ')[0];

  return (
    <>
      <GrayBox mb={3}>
        <Typography variant="h5">
          <Box fontWeight="normal">
            <div>
              Olá,
              {' '}
              {getCapitalizedText(firstName)}
              !
            </div>
            <Box mb={2}>
              Para que a sua experiência seja a mais completa possível, separamos
              algumas perguntas para descobrirmos seu perfil de investidor.
            </Box>

            <Box mb={2}>
              Esse processo leva menos de 2 minutos e não se preocupe! Suas informações
              estão seguras e não serão compartilhadas.
            </Box>

            <div>
              Ressaltando que esse questionário não se caracteriza e nem
              substitui a análise de perfil de risco (suitability) que você deve
              fazer na sua corretora.
            </div>
          </Box>
        </Typography>
      </GrayBox>

      <Box display="flex" justifyContent="center">
        <Button
          onClick={() => setStartQuestionnaire(true)}
          color="primary"
          variant="contained"
        >
          Começar
        </Button>
      </Box>
    </>
  );
};

InvestorProfileStart.propTypes = {
  username: PropTypes.string.isRequired,
  setStartQuestionnaire: PropTypes.func.isRequired,
};

export default InvestorProfileStart;
