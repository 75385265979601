import React from 'react';
import PropTypes from 'prop-types';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Typography from '@material-ui/core/Typography';
import { URL_B3_PUMA_TRADING_HORARIO_NEGOCIACOES } from 'common/utils/constants';

export const WAIT_FOR_OPENING_DIALOG_CODE = 'WAIT_FOR_OPENING_DIALOG_CODE';

const WaitForOpeningDialog = ({
  classes,
  onClose,
  fullScreen,
}) => {
  return (
    <Dialog
      open
      onClose={onClose}
      maxWidth="md"
      fullScreen={fullScreen}
    >
      <DialogContent>
        <Typography variant="title" className={classes.title}>
          Aguarde até a abertura das negociações
        </Typography>
        <Typography className={classes.text}>
          Para encerrar a posição da sua carteira é necessário que você aguarde a abertura das
          negociações na B3 no próximo pregão.
        </Typography>
        <Typography className={classes.text}>
          Por questões de segurança não realizamos o encerramento fora do horário normal de
          pregão ou durante o leilão de fechamento do ativo negociado.
        </Typography>
        <Typography className={classes.text}>
          <a
            href={URL_B3_PUMA_TRADING_HORARIO_NEGOCIACOES}
            target="_blank"
            rel="noopener noreferrer"
            className={classes.link}
          >
            Confira aqui os horários oficiais de negociação da B3.
          </a>
        </Typography>
        <DialogActions className={classes.buttons}>
          <Button
            variant="contained"
            color="primary"
            className={classes.button}
            onClick={onClose}
          >
            Entendi
          </Button>
        </DialogActions>
      </DialogContent>
    </Dialog>
  );
};

WaitForOpeningDialog.propTypes = {
  classes: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired,
  fullScreen: PropTypes.bool.isRequired,
};

export default WaitForOpeningDialog;
