import React, { useState } from 'react';
import PropTypes from 'prop-types';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Typography from '@material-ui/core/Typography';
import { IconButton } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';

import ArrowTooltip from 'common/components/ArrowTooltip';
import LoadingButton from 'common/components/Buttons/LoadingButton';
import Link from 'common/components/Link';
import { getStockCodesAndNumber } from 'common/utils/positions';
import { SI_ZENDESK_ARTICLES_URL } from 'common/utils/constants';

export const CORRECT_POSITION_DIALOG_CODE = 'CORRECT_POSITION_DIALOG_CODE';

const CorrectPositionDialog = ({
  classes,
  onClose,
  fullScreen,
  openWaitForOpeningDialog,
  openSellAllDialog,
  openExternalOrderDialog,
  loadInstancePositions,
  notifyError,
}) => {
  const [isButtonLoading, setIsButtonLoading] = useState(false);

  const loadPositions = async () => {
    try {
      const { data: { originalPositions } } = await loadInstancePositions();

      return originalPositions;
    } catch (error) {
      notifyError({
        title: 'Erro!',
        message: 'Não foi possível atualizar as posições do robô. Tente novamente.',
      });
      return error;
    }
  };
  const openAddExternalOrderDialog = async () => {
    const positions = await loadPositions();

    positions.sort((a, b) => {
      if (a.stock_code < b.stock_code) { return -1; }
      if (a.stock_code > b.stock_code) { return 1; }
      return 0;
    });
    const position = positions[0];
    const options = {
      initialValues: {
        order_type: position.position_type === '0' ? '1' : '0',
        market_name: 'BOVESPA',
        stock_code: position.stock_code,
        number_of_stocks: position.number_of_stocks,
        price: '',
        entry_exit_or_reversal: 'exit',
        date: '',
        time: '',
        reason: '',
      },
      disabledFields: [],
    };
    openExternalOrderDialog(options);
  };

  const handleClick = async () => {
    setIsButtonLoading(true);
    await openAddExternalOrderDialog();
  };

  const onSellAllButtonClick = async () => {
    const positions = await loadPositions();

    if (positions[0].is_market_open === 0) {
      openWaitForOpeningDialog();
    } else {
      const stockCodesAndNumber = getStockCodesAndNumber(positions);

      openSellAllDialog(stockCodesAndNumber);
    }
  };

  const fullScreenCloseButton = () => (
    <IconButton
      size="small"
      className={classes.closeIcon}
      edge="start"
      color="inherit"
      onClick={onClose}
      aria-label="close"
    >
      <CloseIcon />
    </IconButton>
  );

  const cancelButton = () => (
    <Button
      onClick={onClose}
    >
      Cancelar
    </Button>
  );

  return (
    <Dialog
      open
      onClose={onClose}
      maxWidth="md"
      fullScreen={fullScreen}
      disableAutoFocus
    >
      <DialogContent>
        {fullScreen ? (fullScreenCloseButton()) : null}
        <Typography variant="title" className={classes.title}>
          Atenção! Carteira posicionada.
        </Typography>
        <Typography className={classes.text}>
          A sua carteira está parada mas possui posições abertas, sendo assim:
          <ul className={classes.list}>
            <li>
              A carteira não irá mais acompanhar o mercado e
              não irá enviar novas ordens de entrada ou saída.
            </li>
            <li>
              As posições abertas continuarão abertas;
            </li>
          </ul>
        </Typography>

        <Typography className={classes.text}>
          Caso tenha encerrado ou vá encerrar esta posição por outra plataforma ou
          o Home Broker da sua corretora,
          atualize manualmente a posição da carteira na plataforma.&nbsp;

          <Link
            color="primary"
            fontWeight={700}
            path={`${SI_ZENDESK_ARTICLES_URL}/4419871413015`}
            target="_blank"
          >
            Saiba mais sobre atualizar a posição de uma carteira.
          </Link>
        </Typography>

        <Typography className={classes.text}>
          Além disso, ao parar de seguir uma carteira, para segui-la novamente é necessário
          acessar &quot;Vitrine de Carteiras &gt; Carteiras Assinadas&quot;.&nbsp;
          <Link
            color="primary"
            fontWeight={700}
            path={`${SI_ZENDESK_ARTICLES_URL}/360041574293`}
            target="_blank"
          >
            Saiba mais aqui.
          </Link>
        </Typography>

        <Typography className={classes.text}>

          Selecione uma opção para continuar:
        </Typography>
        <DialogActions
          className={classes.buttons}
          disableSpacing={fullScreen}
        >
          {!fullScreen ? (cancelButton()) : null}
          <ArrowTooltip
            title="Serão enviadas ordens para a corretora para zerar a posição atual."
            placement="top"
          >
            <Button
              variant="contained"
              color="secondary"
              onClick={onSellAllButtonClick}
              className={classes.button}
            >
              Vender Tudo
            </Button>
          </ArrowTooltip>
          <ArrowTooltip
            title="Você será direcionado para atualizar manualmente a posição da sua carteira."
            placement="top"
          >
            <div>
              <LoadingButton
                variant="contained"
                color="secondary"
                loading={isButtonLoading}
                className={classes.button}
                confirmButtonContent="Atualizar Posição"
                onClick={handleClick}
              />
            </div>
          </ArrowTooltip>
        </DialogActions>
      </DialogContent>
    </Dialog>
  );
};

CorrectPositionDialog.propTypes = {
  classes: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired,
  fullScreen: PropTypes.bool.isRequired,
  openWaitForOpeningDialog: PropTypes.func.isRequired,
  openSellAllDialog: PropTypes.func.isRequired,
  openExternalOrderDialog: PropTypes.func.isRequired,
  loadInstancePositions: PropTypes.func.isRequired,
  notifyError: PropTypes.func.isRequired,
};

export default CorrectPositionDialog;
