import { withStyles } from '@material-ui/styles';

import palette from 'common/palette';
import StockOperationConfirmation from './StockOperationConfirmation';

const styles = {
  buy: {
    color: palette.gain.main,
  },
  sell: {
    color: palette.loss.main,
  },
  link: {
    color: palette.primary.main,
  },
};

export default withStyles(styles)(StockOperationConfirmation);
