import { QUESTIONNAIRE_DATA } from 'common/utils/constants/investorProfile';

export const calculateUserProfileScore = (answers) => {
  const sum = answers.reduce(
    (acc, answer, index) => acc + QUESTIONNAIRE_DATA[index + 1].answers.weight[answer], 0,
  );
  return sum / answers.length;
};

export const getUserProfileId = (score) => {
  if (score > 0 && score < 0.15) return 1;
  if (score >= 0.15 && score < 0.30) return 2;
  if (score >= 0.3 && score < 0.7) return 3;
  if (score >= 0.7 && score < 0.95) return 4;
  if (score >= 0.95 && score <= 1) return 5;
  return null;
};
