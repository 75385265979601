import React from 'react';
import Proptypes from 'prop-types';
import classNames from 'classnames';
import { Tabs as MaterialTabs, Tab, Badge } from '@material-ui/core';
import { isSecondary, isSmall, isStandard } from 'common/utils/general';
import SecondaryTab from './SecondaryTab';
import ToggleDisplayTab from './ToggleDisplayTab';

const getTabComponent = (isToggleDisplay, mode) => {
  if (isToggleDisplay) {
    return ToggleDisplayTab;
  }
  return isSecondary(mode) ? SecondaryTab : Tab;
};

const Tabs = ({
  classes,
  tabsMap,
  currentTab,
  setCurrentTab,
  textColor,
  indicatorColor,
  variant,
  mode,
  BadgeIcon,
  size,
  isToggleDisplay,
}) => {
  const TabComponent = getTabComponent(isToggleDisplay, mode);
  const handleTabChange = (e, value) => {
    if (value !== currentTab) {
      setCurrentTab(value);
    }
  };

  return (
    <MaterialTabs
      value={currentTab}
      onChange={handleTabChange}
      indicatorColor={textColor}
      textColor={indicatorColor}
      variant={variant}
      classes={{
        indicator: isSecondary(mode) && classes.indicatorOff,
      }}
    >
      {Object.keys(tabsMap).map((tabKey) => {
        const tab = tabsMap[tabKey];

        return (
          <TabComponent
            id={tabKey}
            key={tabKey}
            value={tabKey}
            className={classNames(
              isSmall(size) && classes.small,
              isStandard(variant) && isSmall(size) && classes.fitContent,
            )}
            label={(
              <Badge
                color="primary"
                className={classes.tabBadge}
              >
                {tab.badge && (
                  <BadgeIcon id="badgeIcon" className={classes.badgeIcon} />
                )}
                {tab.label?.toUpperCase()}
                {tab.icon && isToggleDisplay && (
                  <img
                    alt={tab.name}
                    id={`${tab.name}-icon`}
                    src={tab.icon}
                    className={classes.badgeIcon}
                  />
                )}
              </Badge>
            )}
          />
        );
      })}
    </MaterialTabs>
  );
};

Tabs.propTypes = {
  classes: Proptypes.object.isRequired,
  tabsMap: Proptypes.object.isRequired,
  currentTab: Proptypes.string.isRequired,
  setCurrentTab: Proptypes.func.isRequired,
  textColor: Proptypes.string,
  indicatorColor: Proptypes.string,
  variant: Proptypes.string,
  BadgeIcon: Proptypes.oneOfType([Proptypes.string, Proptypes.object]),
  mode: Proptypes.oneOf(['primary', 'secondary']),
  size: Proptypes.oneOf(['small', 'medium']),
  isToggleDisplay: Proptypes.bool,
};

Tabs.defaultProps = {
  textColor: 'primary',
  indicatorColor: 'primary',
  mode: 'primary',
  variant: 'scrollable',
  BadgeIcon: '',
  size: 'medium',
  isToggleDisplay: false,
};

export default Tabs;
