import React from 'react';
import PropTypes from 'prop-types';

import ProgressDialog, {
  PROGRESS_DIALOG_CODE,
} from './ProgressDialog';

import ConfirmationDialog, {
  CONFIRMATION_DIALOG_CODE,
} from './ConfirmationDialog';

import PendingOrdersDialog, {
  PENDING_ORDERS_DIALOG_CODE,
} from './InstanceDialogs/PendingOrdersDialog';

import CancelOrderDialog, {
  CANCEL_ORDER_DIALOG_CODE,
} from './InstanceDialogs/PendingOrdersDialog/CancelOrderDialog';

import FollowInstanceDialog, {
  OPEN_FOLLOW_INSTANCE_DIALOG_CODE,
} from './FollowInstanceDialog';

import AccountInfoDialog, {
  OPEN_ACCOUNT_INFO_DIALOG_CODE,
} from './AccountInfoDialog';

import PasswordConfirmDialog, {
  PASSWORD_CONFIRM_DIALOG_CODE,
} from './PasswordConfirmDialog';

import Token2FADialog, { TOKEN_2FA_DIALOG_CODE } from './Token2FADialog';

import RegisterBrokerageDialog, {
  REGISTER_BROKERAGE_DIALOG_CODE,
} from './RegisterBrokerageDialog';

import CorrectPositionDialog, {
  CORRECT_POSITION_DIALOG_CODE,
} from './InstanceDialogs/CorrectPositionDialog';

import WaitForOpeningDialog, {
  WAIT_FOR_OPENING_DIALOG_CODE,
} from './InstanceDialogs/WaitForOpeningDialog';

import SellAllDialog, {
  SELL_ALL_DIALOG_CODE,
} from './InstanceDialogs/SellAllDialog';

import InstanceOrderEvents, {
  INSTANCE_ORDER_EVENTS_DIALOG_CODE,
} from './InstanceDialogs/InstanceOrderEvents';

import ExternalOrderDialog, {
  EXTERNAL_ORDER_DIALOG_CODE,
} from './InstanceDialogs/ExternalOrderDialog';

import OperationalCostsDialog, {
  OPERATIONAL_COSTS_DIALOG,
} from './OperationalCostsDialog';

import PlanSubscriptionDialog, {
  PLAN_SUBSCRIPTION_DIALOG_CODE,
} from './PlanSubscriptionDialog';

import AddCreditCardDialog, {
  ADD_CREDIT_CARD_DIALOG_CODE,
} from './AddCreditCardDialog';

import CompletePersonalDataDialog, {
  COMPLETE_PERSONAL_DATA_DIALOG,
} from './CompletePersonalDataDialog';

import AlterPasswordDialog, {
  ALTER_PASSWORD_DIALOG_CODE,
} from './AlterPasswordDialog';

import AnswerResearchDialog, {
  ANSWER_RESEARCH_DIALOG_CODE,
} from './AnswerResearchDialog';

import AlterPlanPaymentDialog, {
  ALTER_PLAN_PAYMENT_DIALOG_CODE,
} from './AlterPlanPaymentDialog';

import AddCouponDialog, {
  ADD_COUPON_DIALOG_CODE,
} from './AddCouponDialog';

import InvoicePaymentDialog, {
  INVOICE_PAYMENT_DIALOG,
} from './InvoicePaymentDialog';

import CardRemoveConfirmDialog, {
  CARD_REMOVE_CONFIRM_DIALOG,
} from './CardRemoveConfirmDialog';

import CreateWalletDialog, {
  CREATE_TEMPLATE_DIALOG_CODE,
} from './InstanceDialogs/CreateTemplateDialog';

import ChoiceDialog, {
  CHOICE_DIALOG_CODE,
} from './ChoiceDialog';

import StartFollowInstance, {
  START_FOLLOW_INSTANCE_DIALOG_CODE,
} from './StartFollowInstanceDialog';

import TransactionsDialog, {
  OPEN_TRANSACTIONS_DIALOG_CODE,
} from './TransactionsDialog';

import StoreItemChangedStocksDialog, {
  STORE_ITEM_CHANGED_STOCKS_DIALOG_CODE,
} from './StoreItemChangedStocksDialog';

import RebalanceStocksDialog, {
  REBALANCE_STOCKS_DIALOG_CODE,
} from './RebalanceStocksDialog';

import ShareWalletLinkDialog, {
  SHARE_WALLET_LINK_DIALOG_CODE,
} from './ShareWalletLinkDialog';

const Dialogs = ({
  currentDialogOpened,
  dialogProps,
  onDialogClose,
}) => {
  const dialogs = {
    [PROGRESS_DIALOG_CODE]: ProgressDialog,
    [CONFIRMATION_DIALOG_CODE]: ConfirmationDialog,
    [PENDING_ORDERS_DIALOG_CODE]: PendingOrdersDialog,
    [CANCEL_ORDER_DIALOG_CODE]: CancelOrderDialog,
    [REGISTER_BROKERAGE_DIALOG_CODE]: RegisterBrokerageDialog,
    [OPEN_FOLLOW_INSTANCE_DIALOG_CODE]: FollowInstanceDialog,
    [OPEN_ACCOUNT_INFO_DIALOG_CODE]: AccountInfoDialog,
    [PASSWORD_CONFIRM_DIALOG_CODE]: PasswordConfirmDialog,
    [CORRECT_POSITION_DIALOG_CODE]: CorrectPositionDialog,
    [WAIT_FOR_OPENING_DIALOG_CODE]: WaitForOpeningDialog,
    [SELL_ALL_DIALOG_CODE]: SellAllDialog,
    [INSTANCE_ORDER_EVENTS_DIALOG_CODE]: InstanceOrderEvents,
    [EXTERNAL_ORDER_DIALOG_CODE]: ExternalOrderDialog,
    [OPERATIONAL_COSTS_DIALOG]: OperationalCostsDialog,
    [PLAN_SUBSCRIPTION_DIALOG_CODE]: PlanSubscriptionDialog,
    [ADD_CREDIT_CARD_DIALOG_CODE]: AddCreditCardDialog,
    [COMPLETE_PERSONAL_DATA_DIALOG]: CompletePersonalDataDialog,
    [ALTER_PASSWORD_DIALOG_CODE]: AlterPasswordDialog,
    [ANSWER_RESEARCH_DIALOG_CODE]: AnswerResearchDialog,
    [ALTER_PLAN_PAYMENT_DIALOG_CODE]: AlterPlanPaymentDialog,
    [ADD_COUPON_DIALOG_CODE]: AddCouponDialog,
    [INVOICE_PAYMENT_DIALOG]: InvoicePaymentDialog,
    [CARD_REMOVE_CONFIRM_DIALOG]: CardRemoveConfirmDialog,
    [CREATE_TEMPLATE_DIALOG_CODE]: CreateWalletDialog,
    [CHOICE_DIALOG_CODE]: ChoiceDialog,
    [START_FOLLOW_INSTANCE_DIALOG_CODE]: StartFollowInstance,
    [OPEN_TRANSACTIONS_DIALOG_CODE]: TransactionsDialog,
    [STORE_ITEM_CHANGED_STOCKS_DIALOG_CODE]: StoreItemChangedStocksDialog,
    [REBALANCE_STOCKS_DIALOG_CODE]: RebalanceStocksDialog,
    [SHARE_WALLET_LINK_DIALOG_CODE]: ShareWalletLinkDialog,
    [TOKEN_2FA_DIALOG_CODE]: Token2FADialog,
  };

  const OpenedDialog = dialogs[currentDialogOpened] || (() => null);

  return <OpenedDialog onClose={onDialogClose} open {...dialogProps} />;
};

Dialogs.propTypes = {
  currentDialogOpened: PropTypes.string,
  dialogProps: PropTypes.object.isRequired,
  onDialogClose: PropTypes.func.isRequired,
};

Dialogs.defaultProps = {
  currentDialogOpened: '',
};

export default Dialogs;
