import { ERROR_DEFAULT_MSG, NOW_TEXT } from '.';
import { WHITEBOX_STRATEGY_IDS } from './whitebox';

export const THEORETICAL_WALLET_ENABLED_STRATEGIES = [
  ...WHITEBOX_STRATEGY_IDS,
  666,
];
export const NEXT_ROLLOUT_TEXT = 'Próxima rolagem';
export const NEXT_REBALANCE_TEXT = 'Próximo rebalanceamento';
export const NEXT_ROLLOUT_REBALANCE_TEXT = 'Próxima rolagem ou rebalanceamento';
export const INSTANCE_DETAILS_TABS = {
  PERFORMANCE: 'desempenho',
  WALLET: 'carteira',
  SETTINGS: 'configuracoes',
  MOVEMENTS: 'movimentacoes',
};
export const INSTANCE = 'instance';

export const DEFAULT_CHANGE_PORTFOLIO_DATE = '2019-01-01';

export const CHANGE_PORTFOLIO_DATE = 'change_portfolio_date';
export const FORCE_CHANGE_PORTFOLIO_DATE = 'force_change_portfolio_date';

export const PARAMS_MOUNT_INSTANCE = {
  nextRollout: {
    id: 'nextRollout',
    text: NEXT_ROLLOUT_TEXT,
    forceMountPortfolio: false,
    change_portfolio_date: DEFAULT_CHANGE_PORTFOLIO_DATE,
    value: '0',
  },
  mountNow: {
    id: 'mountNow',
    text: NOW_TEXT.toUpperCase(),
    value: '1',
  },
};

export const NOT_REBALANCE_TEXT = 'Não rebalancear';

export const SECONDARY_TABS_MAP = {
  evolution: {
    name: 'evolution',
    label: 'Evolução',
  },
  detailedPosition: {
    name: 'detailedPosition',
    label: 'Posição detalhada',
  },
};

export const INSTANCE_CHANGED_SUCCESS_TITLE = 'Carteira alterada!';
export const INSTANCE_CHANGED_SUCCESS_MSG = 'Você alterou os parâmetros operacionais da sua carteira. Eles já serão utilizados nas próximas rolagens e rebalanceamentos.';
export const INSTANCE_CHANGED_LOADING_TITLE = 'Alterando a carteira';
export const INSTANCE_CHANGED_ERROR_MSG = `Não foi possível alterar a carteira. ${ERROR_DEFAULT_MSG}}`;

export const MIN_OPERATION_LABEL = 'Valor mínimo para operações (R$)';
export const DEFAULT_MIN_OPERATION_VALUE = 10;

export const INSTANCE_VISIBILITY = {
  PUBLIC: 'PUBLIC',
  PRIVATE: 'PRIVATE',
};
