import api from '../api';

export const DUCK_NAME = 'activeCampaign';

export const CACHE_TOLERANCE_TIME = { amount: 1, unit: 'day' };

export const INITIAL_STATE = {
  createContactLoading: false,
  createContactError: null,
  tagContactLoading: false,
  tagContactError: null,
  listContactLoading: false,
  listContactError: null,
  data: null,
};

export const CREATE_CONTACT_STARTED = `${DUCK_NAME}/CREATE_CONTACT_STARTED`;
export const CREATE_CONTACT_SUCCEED = `${DUCK_NAME}/CREATE_CONTACT_SUCCEED`;
export const CREATE_CONTACT_FAILED = `${DUCK_NAME}/CREATE_CONTACT_FAILED`;

export const createContactStarted = () => ({ type: CREATE_CONTACT_STARTED });
export const createContactSucceed = (data) => ({ type: CREATE_CONTACT_SUCCEED, data });
export const createContactFailed = (error) => ({ type: CREATE_CONTACT_FAILED, error });
export const createContact = (email) => async (dispatch) => {
  dispatch(createContactStarted());
  let profile = null;
  const contact = { email };
  try {
    ({ data: profile } = await api.activeCampaign.createContact(contact));
    dispatch(createContactSucceed(profile));
    return Promise.resolve(profile);
  } catch (error) {
    dispatch(createContactFailed(error));
    return Promise.reject(error);
  }
};

export const TAG_CONTACT_STARTED = `${DUCK_NAME}/TAG_CONTACT_STARTED`;
export const TAG_CONTACT_SUCCEED = `${DUCK_NAME}/TAG_CONTACT_SUCCEED`;
export const TAG_CONTACT_FAILED = `${DUCK_NAME}/TAG_CONTACT_FAILED`;

export const tagContactStarted = () => ({ type: TAG_CONTACT_STARTED });
export const tagContactSucceed = (data) => ({ type: TAG_CONTACT_SUCCEED, data });
export const tagContactFailed = (error) => ({ type: TAG_CONTACT_FAILED, error });
export const tagContact = (id, tag) => async (dispatch) => {
  dispatch(tagContactStarted());
  let profile = null;
  const contactTag = {
    contact: id,
    tag,
  };
  try {
    ({ data: profile } = await api.activeCampaign.tagContact(contactTag));
    dispatch(tagContactSucceed(profile));
    return Promise.resolve(profile);
  } catch (error) {
    dispatch(tagContactFailed(error));
    return Promise.reject(error);
  }
};

export const LIST_CONTACT_STARTED = `${DUCK_NAME}/LIST_CONTACT_STARTED`;
export const LIST_CONTACT_SUCCEED = `${DUCK_NAME}/LIST_CONTACT_SUCCEED`;
export const LIST_CONTACT_FAILED = `${DUCK_NAME}/LIST_CONTACT_FAILED`;
export const listContactStarted = () => ({ type: LIST_CONTACT_STARTED });
export const listContactSucceed = (data) => ({ type: LIST_CONTACT_SUCCEED, data });
export const listContactFailed = (error) => ({ type: LIST_CONTACT_FAILED, error });
export const listContact = (id, list) => async (dispatch) => {
  dispatch(listContactStarted());
  let profile = null;
  const contactList = {
    list,
    contact: id,
    status: 1,
  };
  try {
    ({ data: profile } = await api.activeCampaign.listContact(contactList));
    dispatch(listContactSucceed(profile));
    return Promise.resolve(profile);
  } catch (error) {
    dispatch(listContactFailed(error));
    return Promise.reject(error);
  }
};

const reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case CREATE_CONTACT_STARTED:
      return {
        ...state,
        createContactLoading: true,
        createContactError: null,
      };
    case CREATE_CONTACT_SUCCEED:
      return {
        ...state,
        createContactLoading: false,
        createContactError: null,
      };
    case CREATE_CONTACT_FAILED:
      return {
        ...state,
        createContactLoading: false,
        createContactError: action.error,
      };

    case TAG_CONTACT_STARTED:
      return {
        ...state,
        tagContactLoading: true,
        tagContactError: null,
      };
    case TAG_CONTACT_SUCCEED:
      return {
        ...state,
        tagContactLoading: false,
        tagContactError: null,
      };
    case TAG_CONTACT_FAILED:
      return {
        ...state,
        tagContactLoading: false,
        tagContactError: action.error,
      };
    case LIST_CONTACT_STARTED:
      return {
        ...state,
        listContactLoading: true,
      };
    case LIST_CONTACT_SUCCEED:
      return {
        ...state,
        listContactLoading: false,
        data: action.data,
      };
    case LIST_CONTACT_FAILED:
      return {
        ...state,
        listContactLoading: false,
        listContactError: action.error,
      };
    default: return state;
  }
};

export default reducer;
