import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Dialog,
  DialogContent,
  IconButton,
  Typography,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';

import { TABLET_WIDTH_BREAKPOINT } from 'common/utils/constants';
import { SteppedDialogFooter } from 'common/components/Steps';
import RebalanceStocksConfirmation from 'common/components/RebalanceStocksConfirmation';
import RebalanceChangedStocksContent from '../RebalanceChangedStocksContent';

const RebalanceStocksDialog = ({
  classes,
  open,
  onClose,
  stocks,
  oldStocks,
  rebalanceInstance,
}) => {
  const [dataSteps, setDataSteps] = useState({
    currentStep: 1,
    numberOfSteps: 2,
  });
  const isMobile = window.innerWidth <= TABLET_WIDTH_BREAKPOINT;
  const { currentStep, numberOfSteps } = dataSteps;

  const setStep = (step) => {
    setDataSteps({ ...dataSteps, currentStep: step });
  };

  const stepsMap = {
    1: {
      component: (
        <RebalanceChangedStocksContent
          stocks={stocks}
          oldStocks={oldStocks}
        />
      ),
    },
    2: {
      component: (
        <>
          <Typography className={classes.title}>Confirme alterações</Typography>

          <RebalanceStocksConfirmation />
        </>
      ),
    },
  };

  return (
    <Dialog
      disableBackdropClick
      maxWidth="md"
      fullScreen={isMobile}
      onClose={onClose}
      aria-labelledby="customized-dialog-title"
      open={open}
    >
      <DialogContent className={classes.dialogContent}>
        <IconButton
          size="small"
          className={classes.closeIcon}
          edge="start"
          color="inherit"
          onClick={onClose}
          aria-label="close"
        >
          <CloseIcon />
        </IconButton>

        {stepsMap[currentStep].component}
        <SteppedDialogFooter
          onSetStep={setStep}
          formName={stepsMap[currentStep].form}
          StepsProps={{ currentStep, numberOfSteps }}
          onClickConfirm={rebalanceInstance}
          displayPreviousStep={currentStep !== 1}
        />
      </DialogContent>
    </Dialog>
  );
};

RebalanceStocksDialog.propTypes = {
  classes: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  stocks: PropTypes.object.isRequired,
  oldStocks: PropTypes.object.isRequired,
  rebalanceInstance: PropTypes.func.isRequired,
};

export default RebalanceStocksDialog;
