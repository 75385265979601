import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

// Material UI
import { withStyles } from '@material-ui/styles';
import TextField from '@material-ui/core/TextField';

const styles = {
  field: { marginBottom: 0 },
};

const SelectField = ({
  classes,
  onChange,
  input,
  meta: { touched, error },
  children,
  className,
  helperText,
  ...custom
}) => (
  <TextField
    {...custom}
    onChange={onChange}
    {...input}
    margin="normal"
    select
    fullWidth
    onBlur={undefined}
    error={touched && Boolean(error)}
    helperText={(touched && error) || helperText}
    className={classNames([className, classes.field])}
  >
    {children}
  </TextField>
);

SelectField.propTypes = {
  classes: PropTypes.object.isRequired,
  children: PropTypes.node.isRequired,
  input: PropTypes.object,
  meta: PropTypes.object,
  custom: PropTypes.object,
  helperText: PropTypes.node,
  className: PropTypes.string,
  onChange: PropTypes.func,
};

SelectField.defaultProps = {
  input: {},
  meta: {},
  custom: {},
  className: '',
  helperText: null,
  onChange: () => null,
};

export default withStyles(styles)(SelectField);
