import React from 'react';
import PropTypes from 'prop-types';

import { Box, Typography } from '@material-ui/core';

const ConfirmValues = ({
  classes,
  hasTitle,
  values,
}) => {
  return (
    <>
      {hasTitle && <Typography className={classes.dialogTitle}>Confirmação</Typography>}
      <Box my={2}>
        {Object.values(values).map((item) => (
          <div
            data-value={item.id}
            id="item-value"
            data-testid={item.id}
            key={item.label}
            className={classes.itemValuesWrapper}
          >
            <Typography variant="h6" color="textSecondary">{item.label}</Typography>
            <Typography id={item.id} className={classes.valueText} variant="h3">
              {item.value}
            </Typography>
          </div>
        ))}
      </Box>
    </>
  );
};

ConfirmValues.propTypes = {
  classes: PropTypes.object.isRequired,
  hasTitle: PropTypes.bool,
  values: PropTypes.object.isRequired,
};

ConfirmValues.defaultProps = {
  hasTitle: true,
};

export default ConfirmValues;
