import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { Link } from 'react-router-dom';

const LinkButton = ({
  className,
  classes,
  path,
  target,
  children,
  fontWeight,
  color,
  inline,
  textDecoration,
  onClick,
  ...other
}) => (
  <Link
    {...other}
    rel="noopener noreferrer"
    to={path}
    href={path}
    onClick={onClick}
    target={target}
    style={{ fontWeight }}
    className={classNames([
      className,
      classes.button,
      color && classes[color],
      inline && classes.inline,
      textDecoration && classes.textDecoration,
    ])}
  >
    {children}
  </Link>
);

LinkButton.propTypes = {
  onClick: PropTypes.func,
  className: PropTypes.string,
  classes: PropTypes.object.isRequired,
  path: PropTypes.string.isRequired,
  children: PropTypes.string.isRequired,
  target: PropTypes.string,
  color: PropTypes.string,
  fontWeight: PropTypes.number,
  inline: PropTypes.bool,
  textDecoration: PropTypes.bool,
};

LinkButton.defaultProps = {
  onClick: () => {},
  target: '',
  className: '',
  color: 'textSecondary',
  fontWeight: 400,
  inline: false,
  textDecoration: true,
};

export default LinkButton;
