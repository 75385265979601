import { error as notifyError, success as notifySuccess } from 'react-notification-system-redux';
import moment from 'moment';
import { get } from 'lodash';

import api from 'common/api';
import { updateInstance, getInstance } from 'common/ducks/instance';
import {
  ERROR,
  DEFAULT_PARAMS_INSTANCE,
  SUCCESS,
  GENERATE_WALLETS_ERROR,
  SUGGESTED_WALLETS,
} from 'common/utils/constants';
import { getUpdateInstanceData } from 'common/utils/whitebox';
import { isFulfilledPromise, checkAllPromisesAreRejecteds } from 'common/utils/async';
import { DATETIME_LOCAL_FORMAT, DATETIME_ZERO_OBJECT } from 'common/utils/constants/date';
import { getFullSmartDataValues } from 'common/utils/smartWeights';
import { LOGOUT_SUCCEED } from './auth';
import { getCredentials, getStocks, setBacktestData } from './stocks';
import { isHolidayOrWeekendDay } from './holidays';

export const DUCK_NAME = 'template';

export const INITIAL_STATE = {
  id: 0,
  params: {},
  suggestedWallets: {},
  chosenSuggestedWallet: {},
  error: null,
  loading: false,
};

export const SAVE_TEMPLATE_STARTED = `${DUCK_NAME}/SAVE_TEMPLATE_STARTED`;
export const SAVE_TEMPLATE_SUCCEED = `${DUCK_NAME}/SAVE_TEMPLATE_SUCCEED`;
export const SAVE_TEMPLATE_FAILED = `${DUCK_NAME}/SAVE_TEMPLATE_FAILED`;
export const saveTemplateStarted = (id) => ({
  type: SAVE_TEMPLATE_STARTED,
  id,
});
export const saveTemplateSucceeded = (id, params) => ({
  type: SAVE_TEMPLATE_SUCCEED,
  id,
  params,
});
export const saveTemplateFailed = (id, error) => ({
  type: SAVE_TEMPLATE_FAILED,
  id,
  error,
});
export const saveTemplate = (stockList, instanceId) => async (dispatch) => {
  dispatch(saveTemplateStarted());
  const params = await getUpdateInstanceData({
    stockList,
    getStocks: (stock) => dispatch(getStocks(stock)),
    isHolidayOrWeekendDay: (date) => dispatch(isHolidayOrWeekendDay(date)),
  });

  try {
    await dispatch(updateInstance(instanceId, {
      params: {
        ...DEFAULT_PARAMS_INSTANCE,
        ...params,
        __types__: {
          ...DEFAULT_PARAMS_INSTANCE.__types__,
          change_portfolio_date: 'string',
        },
      },
    }));
    await dispatch(getInstance(instanceId, {}, { reload: true }));
    dispatch(notifySuccess({
      title: SUCCESS,
      message: 'A carteira foi atualizada',
    }));
    dispatch(saveTemplateSucceeded(params));
    return Promise.resolve(params);
  } catch (error) {
    dispatch(notifyError({
      title: ERROR,
      message: 'Não foi possível atualizar a carteira',
    }));
    dispatch(saveTemplateFailed());
    return Promise.reject(error);
  }
};

export const GENERATE_WALLETS_STARTED = `${DUCK_NAME}/GENERATE_WALLETS_STARTED`;
export const GENERATE_WALLETS_SUCCEED = `${DUCK_NAME}/GENERATE_WALLETS_SUCCEED`;
export const GENERATE_WALLETS_FAILED = `${DUCK_NAME}/GENERATE_WALLETS_FAILED`;
export const generateWalletsStarted = () => ({
  type: GENERATE_WALLETS_STARTED,
});
export const generateWalletsSucceed = (id, data) => ({
  type: GENERATE_WALLETS_SUCCEED,
  id,
  data,
});
export const generateWalletsFailed = (error) => ({
  type: GENERATE_WALLETS_FAILED,
  error,
});

export const generateWallets = (stocks, id) => async (dispatch, getState) => {
  dispatch(generateWalletsStarted());
  const profile = get(getState(), 'user.profile.data', {});
  const currentDate = moment();
  const startDate = currentDate.clone().subtract(2, 'year').startOf('month')
    .set(DATETIME_ZERO_OBJECT);
  const endDate = currentDate.clone().subtract(1, 'day')
    .set(DATETIME_ZERO_OBJECT);

  try {
    const credentials = await dispatch(getCredentials());
    const finalStocks = Object.values(stocks).map((stock) => {
      return {
        market: 'BOVESPA',
        stock_code: stock.code,
        upper_bound: stock.maxWeight / 100,
        lower_bound: stock.minWeight / 100,
      };
    });

    const data = {
      s10i_login: profile.s10i_login,
      initial_date: startDate.format(DATETIME_LOCAL_FORMAT),
      final_date: endDate.format(DATETIME_LOCAL_FORMAT),
      marketdata_expiration_date: credentials.expiration_date,
      marketdata_token: credentials.token,
      marketdata_data_type: 'realtime',
      symbols: finalStocks,
    };

    const leastRisk = api.suggestedWallets.leastRisk(data);
    const bestReturnRisk = api.suggestedWallets.bestReturnRisk(data);
    const greatestReturn = api.suggestedWallets.greatestReturn(data);
    let finalDataWeights;

    await Promise.allSettled([leastRisk, bestReturnRisk, greatestReturn])
      .then((values) => {
        const allPromisesRejected = checkAllPromisesAreRejecteds(values);

        finalDataWeights = SUGGESTED_WALLETS.reduce((acc, wallet, index) => {
          return {
            ...acc,
            [wallet.type]: isFulfilledPromise(values[index].status)
              ? getFullSmartDataValues({
                stocks,
                smartData: values[index].value.data,
              })
              : null,
          };
        }, {});

        dispatch(generateWalletsSucceed(id, finalDataWeights));

        if (allPromisesRejected) {
          throw new Error(GENERATE_WALLETS_ERROR);
        }

        dispatch(notifySuccess({
          title: SUCCESS,
          message: 'Carteiras geradas com sucesso!',
        }));
      })
      .catch((error) => {
        dispatch(notifyError({
          title: ERROR,
          message: error.message,
        }));
        dispatch(generateWalletsFailed(error));
      });

    return Promise.resolve(finalDataWeights);
  } catch (error) {
    dispatch(notifyError({
      title: ERROR,
      message: GENERATE_WALLETS_ERROR,
    }));
    dispatch(generateWalletsFailed(error));
    return Promise.reject(error);
  }
};

export const CHOOSE_SUGGESTED_WALLET_SUCCEED = `${DUCK_NAME}/CHOOSE_SUGGESTED_WALLET_SUCCEED`;

export const chooseSuggestedWalletSucceed = (id, suggestedWallet) => ({
  type: CHOOSE_SUGGESTED_WALLET_SUCCEED,
  id,
  suggestedWallet: suggestedWallet.data.weights,
});

export const chooseSuggestedWallet = (id, suggestedWallet) => (
  dispatch, getState,
) => {
  const { stocks } = getState();
  const currentBacktest = stocks.backtests[suggestedWallet.type].backtest;
  dispatch(setBacktestData({
    backtestType: 'simpleWeights',
    data: suggestedWallet.data.weights,
    backtest: currentBacktest,
  }));

  return dispatch(chooseSuggestedWalletSucceed(id, suggestedWallet));
};

export default (state = INITIAL_STATE, action) => {
  // Logout cleaning
  if (action.type === LOGOUT_SUCCEED) {
    return INITIAL_STATE;
  }

  switch (action.type) {
    case SAVE_TEMPLATE_STARTED:
      return {
        ...state,
        loading: true,
      };
    case SAVE_TEMPLATE_SUCCEED:
      return {
        ...state,
        loading: false,
        params: action.params,
      };
    case SAVE_TEMPLATE_FAILED:
      return {
        ...state,
        loading: false,
        error: action.error,
      };

    case GENERATE_WALLETS_STARTED:
      return {
        ...state,
        loadingSuggestedWallets: true,
      };
    case GENERATE_WALLETS_SUCCEED:
      return {
        ...state,
        loadingSuggestedWallets: false,
        suggestedWallets: {
          ...state.suggestedWallets,
          [action.id]: {
            data: action.data,
          },
        },
      };
    case GENERATE_WALLETS_FAILED:
      return {
        ...state,
        loadingSuggestedWallets: false,
      };
    case CHOOSE_SUGGESTED_WALLET_SUCCEED:
      return {
        ...state,
        chosenSuggestedWallet: action.suggestedWallet,
        suggestedWallets: {
          ...state.suggestedWallets,
          [action.id]: {
            data: {
              ...state.suggestedWallets[action.id].data,
              simpleWeights: action.suggestedWallet,
            },
          },
        },
      };
    default: return state;
  }
};
