import { withStyles } from '@material-ui/styles';

import ClosedMarket from './ClosedMarket';

const styles = {
  closedMarketStatus: {
    width: 18,
    height: 18,
    borderRadius: 6,
    backgroundColor: 'red',
  },
};

export default withStyles(styles)(ClosedMarket);
