import moment from 'moment';
import { LOGOUT_SUCCEED } from 'common/ducks/auth';
import api from 'common/api';

export const DUCK_NAME = 'strategies';

export const INITIAL_STATE = {
  lastUpdateDatetime: null,
  loading: false,
  data: {},
  error: null,
};

// Action Types
export const LOAD_STRATEGIES_STARTED = `${DUCK_NAME}/LOAD_STRATEGIES_STARTED`;
export const LOAD_STRATEGIES_SUCCEED = `${DUCK_NAME}/LOAD_STRATEGIES_SUCCEED`;
export const LOAD_STRATEGIES_FAILED = `${DUCK_NAME}/LOAD_STRATEGIES_FAILED`;

// Action Creators
export const loadStrategiesStarted = () => ({ type: LOAD_STRATEGIES_STARTED });
export const loadStrategiesSucceed = (data) => ({ type: LOAD_STRATEGIES_SUCCEED, data });
export const loadStrategiesFailed = (error) => ({ type: LOAD_STRATEGIES_FAILED, error });

export const loadStrategies = (options = { reload: false }) => async (
  dispatch, getState) => {
  const state = getState()[DUCK_NAME];

  if (
    !options.reload
      && state
      && !state.error
      && !state.loading
      && Object.keys(state.data).length > 0
  ) {
    const strategies = Object.values(state.data).map((strategy) => strategy);

    return Promise.resolve({ strategies });
  }

  dispatch(loadStrategiesStarted());

  try {
    const response = await api.strategies.getStrategies();

    dispatch(loadStrategiesSucceed(response.data));
    return Promise.resolve({ strategies: response.data.strategies });
  } catch (error) {
    dispatch(loadStrategiesFailed(error));
    return Promise.reject(error);
  }
};

// Reducer
const reducer = (state = INITIAL_STATE, action) => {
  // Logout cleaning
  if (action.type === LOGOUT_SUCCEED) {
    return INITIAL_STATE;
  }

  switch (action.type) {
    case LOAD_STRATEGIES_STARTED:
      return {
        ...state,
        loading: true,
      };
    case LOAD_STRATEGIES_SUCCEED:
      return {
        ...state,
        loading: false,
        lastUpdateDatetime: moment().toISOString(),
        data: {
          ...action.data.strategies.reduce((c, s) => ({ ...c, [s.id]: s }), {}),
        },
      };
    case LOAD_STRATEGIES_FAILED:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    default: return state;
  }
};

export default reducer;
