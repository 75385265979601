import { withStyles } from '@material-ui/core/styles';

import CloseDialogButton from './CloseDialogButton';

const styles = {
  absoluteContainer: {
    position: 'absolute',
    zIndex: 1,
    top: 5,
    right: 5,
  },
  iconButton: {
    width: 36,
    height: 36,
    fontSize: 16,
    color: '#707070',
  },
};

export default withStyles(styles)(CloseDialogButton);
