export const CHANGED_STOCKS_VARIANTS = {
  increased: {
    status: 'increased',
    color: '#025ab9',
    name: 'Aumentou',
    rotate: 'rotate(-45deg)',
    tagColor: 'blue',
  },
  decreased: {
    status: 'decreased',
    color: '#e8b21d',
    name: 'Reduziu',
    rotate: 'rotate(45deg)',
    tagColor: 'info',
  },
  cameIn: {
    status: 'cameIn',
    color: '#16bc8e',
    name: 'Entrou',
    rotate: 'rotate(-90deg)',
    tagColor: 'success',
  },
  cameOut: {
    status: 'cameOut',
    color: '#e93056',
    name: 'Saiu',
    rotate: 'rotate(90deg)',
    tagColor: 'error',
  },
  kept: {
    status: 'kept',
    color: '#a9a9a9',
    name: 'Manteve',
    rotate: 'rotate(0)',
    tagColor: 'default',
  },
};

export const REBALANCE_CHANGED_STOCKS_VARIANTS = {
  increased: {
    ...CHANGED_STOCKS_VARIANTS.increased,
    name: 'Aumento',
  },
  decreased: {
    ...CHANGED_STOCKS_VARIANTS.decreased,
    name: 'Redução',
  },
  cameIn: {
    ...CHANGED_STOCKS_VARIANTS.cameIn,
    name: 'Entrada',
  },
  cameOut: {
    ...CHANGED_STOCKS_VARIANTS.cameOut,
    name: 'Saída',
  },
  kept: {
    ...CHANGED_STOCKS_VARIANTS.kept,
    name: 'Mantém',
  },
};

export const DEFAULT_LOT_SIZE = 100;
export const BUY = 'Compra';
export const SELL = 'Venda';

export const UNAVAILABLE_STOCKS = ['BBAS11'];
