import { VOLUME_LIST_FILTER } from 'common/utils/constants/filter';
import { getCategoryById } from './categories';
import { generateChartData, adjustDataStartPosition } from './dailyCumulativePerformance';
import { getProfitabilityData, getInstanceItemYears } from './instance';
import { SHOWCASE_URL } from './constants';

export const getBestAndWorstMonths = (instance) => {
  const formattedPerformance = generateChartData(instance.daily_cumulative_performance);
  const instanceCumulativePerformance = adjustDataStartPosition(formattedPerformance);
  const yearsList = getInstanceItemYears({ extra_data: { start_date: instance.start_date } });

  const {
    bestMonthGeneral,
    worstMonthGeneral,
  } = getProfitabilityData({ data: instanceCumulativePerformance, yearsList });

  return { bestMonth: bestMonthGeneral * 100, worstMonth: worstMonthGeneral * 100 };
};

export const getScoreBoardInstancesData = (instance, categories) => {
  const { extra_data } = instance.strategy;
  const { tags } = extra_data;
  const category = getCategoryById(Object.values(categories), extra_data.category_id);
  const { bestMonth, worstMonth } = getBestAndWorstMonths(instance);

  return {
    id: instance.id,
    name: instance.strategy.name,
    logo: instance.strategy.extra_data.img,
    category: category.title,
    frequency: tags.frequency,
    minimumCapital: tags.minimum,
    stockNumber: {
      max_stocks: tags.max_stocks,
      number_of_stocks: tags.number_of_stocks,
    },
    bestMonth,
    worstMonth,
    annualizedReturn: instance.report.gross_annualized_return * 100,
    orderVolume: tags.order_volume,
    risk: tags.risk,
    detailsPage: `${SHOWCASE_URL}/${category.route}/${extra_data.store_item_id}`,
    visibility: extra_data.visibility,
    strategyId: instance.strategy.id,
  };
};

export const getBoardFilteredByParam = (board, paramList, paramName) => {
  return paramList.reduce((acc, param) => {
    return [...acc, ...board.filter((item) => item[paramName] === param.label)];
  }, []);
};

export const getGreaterScoreBoardValue = ({ board, value }) => {
  return board.reduce((max, current) => {
    const currentValue = current[value];

    return currentValue > max
      ? currentValue
      : max;
  }, 0);
};

export const getMinScoreBoardValue = ({ board, value }) => {
  return board.reduce((min, current) => {
    const currentValue = current[value];

    return currentValue < min
      ? currentValue
      : min;
  }, board[0][value]);
};

export const getBoardFilteredMinimum = (board, minimum) => {
  return board.filter((item) => item.minimumCapital <= minimum);
};

export const getBoardFilteredSignatures = (board, signatures) => {
  const signatureSet = new Set(Object.keys(signatures));
  return board.filter((item) => !signatureSet.has(`${item.strategyId}`));
};

export const getIndicatorLabel = (filledIndicators, totalIndicatorNumber) => {
  const rate = (filledIndicators / totalIndicatorNumber).toFixed(4);
  if (rate >= 0 && rate <= 0.3333) return VOLUME_LIST_FILTER.low.label;
  if (rate > 0.3333 && rate <= 0.6667) return VOLUME_LIST_FILTER.average.label;
  if (rate > 0.6667 && rate <= 1) return VOLUME_LIST_FILTER.high.label;
  return '';
};

export const getBoardFilteredByVolumeLabel = (board, paramList, paramName) => {
  return paramList.reduce((acc, param) => {
    return [...acc, ...board.filter((item) => {
      const filledIndicators = Math.ceil(item[paramName] * 6);
      const label = getIndicatorLabel(filledIndicators, 6);
      return label === param.label;
    })];
  }, []);
};

export default null;
