import { withStyles } from '@material-ui/styles';

import Disclaimer from './Disclaimer';

const styles = {
  disclaimerTitle: {
    fontWeight: 500,
    marginBottom: 10,
  },
  disclaimerText: {
    fontSize: 10,
  },
};

export default withStyles(styles)(Disclaimer);
