import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import get from 'lodash/get';

import { Box } from '@material-ui/core';

import PublicPagesBody from 'common/components/PublicPagesBody';
import PublicPagesButton from 'common/components/Buttons/PublicPagesButton';
import Field from 'common/components/FormFields/Field';
import PasswordTips from 'common/components/PasswordTips';
import Captcha from 'common/components/Captcha';
import ssErrorInterpreter from 'common/interceptor/error/ss';
import { RedefinePasswordSchema } from 'common/utils/YupValidations';
import { LOGIN_URL, TABLET_WIDTH_BREAKPOINT } from 'common/utils/constants';
import { apiVersionPath } from 'common/api';
import ErrorMsg from 'common/components/ErrorMsg';
import LinkButton from 'common/components/Buttons/LinkButton';

class RedefinePassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      captchaRequired: false,
      passwordExpired: false,
    };
  }

  componentDidMount = async () => {
    const {
      location,
      rateCheck,
    } = this.props;
    const query = new URLSearchParams(location.search);
    if (query.get('login')) {
      this.setState({ passwordExpired: true });
    } else {
      const resp = await rateCheck(`${apiVersionPath}/auth/passwordReset/request`);
      this.setState({ captchaRequired: resp.data.captcha_required });
    }
  };

  isButtonActive = (errors, values) => {
    if (values.password === '') {
      return false;
    }
    return !(typeof errors.password !== 'undefined');
  };

  resetSucceeded = () => {
    const {
      notifySuccess,
      history,
    } = this.props;
    notifySuccess({
      title: 'Sucesso!',
      message: 'Sua senha foi redefinida. '
        + 'Para acessar a plataforma, utilize seu login e sua nova senha.',
    });
    history.replace('/public/auth/login');
  };

  resetFailed = (error) => {
    const {
      notifyError,
      history,
    } = this.props;
    if (get(error, 'codeMessage', false)) {
      notifyError({
        title: 'Erro!',
        message: error.codeMessage,
      });

      this.setState({ captchaRequired: (/(captcha)+\d/i).test(error.code) });

      if (error.code === 'resetpassword0') {
        history.replace('/public/auth/login');
      }
    } else {
      this.setState({ captchaRequired: false });
      const message = ssErrorInterpreter(error.code);
      notifyError({
        title: 'Erro!',
        message: message || 'Erro!',
      });
    }
    this.setState({ captchaRequired: true });
  };

  redefPassword = async (values, actions) => {
    const {
      location,
      resetExpiredPassword,
      resetPassword,
    } = this.props;

    const query = new URLSearchParams(location.search);
    const resetPasswordData = {
      token: query.get('token'),
      recaptcha_response: values.recaptcha_response,
      recaptcha_version: 2,
      password: values.password,
    };
    actions.setSubmitting(true);
    if (query.get('login')) {
      try {
        await resetExpiredPassword(resetPasswordData);
        actions.setSubmitting(false);
        this.resetSucceeded();
      } catch (error) {
        actions.setSubmitting(false);
        this.resetFailed(error);
      }
    } else {
      try {
        await resetPassword(resetPasswordData);
        actions.setSubmitting(false);
        this.resetSucceeded();
      } catch (error) {
        actions.setSubmitting(false);
        this.resetFailed(error);
      }
    }
  };

  render() {
    const {
      captchaRequired,
      passwordExpired,
    } = this.state;
    const { classes } = this.props;
    const isMobile = window.innerWidth <= TABLET_WIDTH_BREAKPOINT;
    return (
      <>
        <PublicPagesBody
          title="Redefinição de senha"
          subtitle="Crie a sua nova senha"
        >
          {passwordExpired && (
            <ErrorMsg>
              Por exigência da B3&nbsp;
              <span>
                sua senha precisa ser redefinida a cada 45 dias. Para sua segurança,
                insira uma nova senha.
              </span>
            </ErrorMsg>
          )}
          <Formik
            validationSchema={RedefinePasswordSchema}
            validateOnChange
            initialValues={{
              password: '',
              recaptcha_response: '',
              recaptcha_version: 2,
            }}
            onSubmit={this.redefPassword}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              setFieldValue,
              isSubmitting,
            }) => (
              <form onSubmit={handleSubmit} className={classes.form}>
                <Field
                  name="password"
                  type="password"
                  handleChange={handleChange}
                  handleBlur={handleBlur}
                  value={values.password}
                  error={errors.password}
                  touched={touched.password}
                  label="Nova Senha"
                />
                <PasswordTips mobile={isMobile} values={values} />
                {captchaRequired && <Captcha setFieldValue={setFieldValue} />}
                <PublicPagesButton
                  active={this.isButtonActive(errors, values)}
                  loading={isSubmitting}
                >
                  Redefinir
                </PublicPagesButton>
              </form>
            )}
          </Formik>
          <Box mt={1.5}>
            <LinkButton path={LOGIN_URL}>Voltar</LinkButton>
          </Box>
        </PublicPagesBody>
      </>
    );
  }
}

RedefinePassword.propTypes = {
  resetPassword: PropTypes.func.isRequired,
  resetExpiredPassword: PropTypes.func.isRequired,
  rateCheck: PropTypes.func.isRequired,
  notifySuccess: PropTypes.func.isRequired,
  notifyError: PropTypes.func.isRequired,
  location: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
};

export default RedefinePassword;
