import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import moment from 'moment';

import CloseIcon from '@material-ui/icons/Close';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import Typography from '@material-ui/core/Typography';

import AttentionIcon from 'common/assets/images/progress_dialog/attention_icon.svg';
import { isWhiteboxInstance } from 'common/utils/instance';
import { getPositionsWithLotSize } from 'common/utils/positions';
import { isInvalidSellOperation, isInsertionMode } from 'common/utils/orders';

import {
  Box,
  Button,
  CircularProgress,
  IconButton,
} from '@material-ui/core';
import Link from 'common/components/Link';
import FillPositions from 'common/components/Dialogs/FollowInstanceDialog/FillPositions';
import {
  SI_ZENDESK_ARTICLES_URL,
  DATE_FORMAT,
  IS_WARN,
  INSTANCES_URL,
} from 'common/utils/constants';
import { INSTANCE_DETAILS_TABS } from 'common/utils/constants/instance';
import { ISO_DATE_FORMAT } from 'common/utils/constants/date';
import { ORDER_VARIANT, EXTERNAL_ORDER_TEXT, MARKET_BOVESPA } from 'common/utils/constants/orders';
import { BLOCKED_MOVEMENT, UPDATE_MY_INSTANCE_COMPOSITION } from 'common/utils/constants/positions';
import { useSubmitExternalOrders } from 'common/hooks/orders';
import { getBlockedMovementDialogText } from 'common/utils/dialog';

export const EXTERNAL_ORDER_DIALOG_CODE = 'EXTERNAL_ORDER_DIALOG_CODE';

const ExternalOrderDialog = ({
  classes,
  onClose,
  id,
  instance,
  positions,
  insertExternalOrdersAction,
  updateExternalOrder,
  variant,
  orderId,
  initialValues,
  fullScreen,
  title,
  validStocks,
  openProgressDialog,
}) => {
  const form = React.createRef();
  const history = useHistory();
  const submitOrders = useSubmitExternalOrders();
  const [loading, setLoading] = useState(false);

  const handleSubmit = async () => {
    setLoading(true);
    const { isValid, values } = await submitOrders.handleSubmit(form);

    const invalidSellItems = isInvalidSellOperation(
      { instance, positions, values: values.positions },
    );

    if (isWhiteboxInstance(instance?.strategy_id) && invalidSellItems) {
      setLoading(false);
      openProgressDialog({
        state: IS_WARN,
        title: BLOCKED_MOVEMENT,
        message: getBlockedMovementDialogText(invalidSellItems.join(', ')),
        showButton: true,
        buttonMessage: UPDATE_MY_INSTANCE_COMPOSITION,
        buttonClick: () => history.replace(`${INSTANCES_URL}/${instance.id}/${INSTANCE_DETAILS_TABS.SETTINGS}`),
        customIcon: AttentionIcon,
        closeAfterClick: true,
      });
      return false;
    }

    if (!isValid) {
      setLoading(false);
      return false;
    }

    const positionsWithLotSize = getPositionsWithLotSize({
      positions: values.positions,
      validStocks,
    });

    const data = {
      brokerageId: instance.brokerage_id,
      orderData: {
        reason: EXTERNAL_ORDER_TEXT,
      },
      positions: positionsWithLotSize,
    };

    try {
      if (isInsertionMode(variant)) {
        await insertExternalOrdersAction(id, data);
      } else {
        const [position] = values.positions;
        const date = moment(position.date, DATE_FORMAT, true);
        const formattedDate = date.format(ISO_DATE_FORMAT);

        const updateData = {
          brokerage_id: instance.brokerage_id,
          datetime: `${formattedDate} 10:00:00`,
          entry_exit_or_reversal: 'entry',
          instance_id: id,
          investment_code: `instance_${id}`,
          market_name: MARKET_BOVESPA,
          number_of_stocks: position.number_of_stocks,
          order_id: `${orderId}`,
          order_type: position.orderType,
          price: position.price,
          reason: EXTERNAL_ORDER_TEXT,
          stock_code: position.stock,
        };

        await updateExternalOrder(id, updateData);
      }
      setLoading(false);
      onClose();
    } catch (error) {
      setLoading(false);
    }

    return true;
  };

  return (
    <Dialog
      open
      onClose={onClose}
      maxWidth="md"
      fullScreen={fullScreen}
      classes={{ paper: classes.dialog }}
    >
      <DialogContent>
        <IconButton
          size="small"
          className={classes.closeIcon}
          edge="start"
          color="inherit"
          onClick={onClose}
          aria-label="close"
        >
          <CloseIcon />
        </IconButton>

        <Typography variant="h2" className={classes.title}>
          {title}
        </Typography>

        <Box mb={2}>
          <Typography component="div" className={classes.text}>
            Atualize as informações abaixo para corrigir as ordens da sua carteira que foram
            executadas pela sua corretora ou outra plataforma e que não foram registradas
            pela SmarttInvest.
          </Typography>
        </Box>

        <Typography className={classNames(classes.text, classes.textBold)}>
          Destacamos que este cadastro não envia uma nova ordem para a corretora.
        </Typography>

        <Box my={2}>
          <Link
            color="primary"
            fontWeight={700}
            path={`${SI_ZENDESK_ARTICLES_URL}/4419871413015`}
            target="_blank"
          >
            Precisa de ajuda para adicionar uma ordem externa?
          </Link>
        </Box>

        <FillPositions
          ref={form}
          onSubmit={handleSubmit}
          initialValues={{
            orderType: orderId ? initialValues.order_type : '0',
            stock: initialValues.stock_code,
            number_of_stocks: initialValues.number_of_stocks,
            price: initialValues.price,
            date: initialValues.date,
          }}
          instanceId={instance.id}
          strategyId={instance.strategy_id}
          variant={variant}
        />

        <Box display="flex" justifyContent="flex-end" pt={4}>
          <Box mr={1}>
            <Button
              onClick={onClose}
              disabled={loading}
            >
              Cancelar
            </Button>
          </Box>
          <Button
            variant="contained"
            color="primary"
            onClick={handleSubmit}
            disabled={loading}
          >
            {loading
            && (
              <CircularProgress
                size={20}
                thickness={7}
                className={classes.loading}
              />
            )}
            Confirmar
          </Button>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

ExternalOrderDialog.propTypes = {
  classes: PropTypes.object.isRequired,
  id: PropTypes.number.isRequired,
  onClose: PropTypes.func.isRequired,
  insertExternalOrdersAction: PropTypes.func.isRequired,
  updateExternalOrder: PropTypes.func.isRequired,
  variant: PropTypes.oneOf(Object.keys(ORDER_VARIANT)),
  orderId: PropTypes.number,
  fullScreen: PropTypes.bool.isRequired,
  instance: PropTypes.object.isRequired,
  positions: PropTypes.object.isRequired,
  title: PropTypes.string,
  validStocks: PropTypes.object.isRequired,
  initialValues: PropTypes.object.isRequired,
  openProgressDialog: PropTypes.func.isRequired,
};

ExternalOrderDialog.defaultProps = {
  variant: ORDER_VARIANT.insert,
  orderId: -1,
  title: 'Atualizar posição da carteira',
};

export default ExternalOrderDialog;
