import _ from 'lodash';
import moment from 'moment';
import logdown from 'logdown';
import { error as notifierError } from 'react-notification-system-redux';

// Common
import api from 'common/api';
import { reduceObjectsArrayToObject } from 'common/utils/array';
import { LOGOUT_SUCCEED } from 'common/ducks/auth';
import {
  getMatchedUserBrokeragesWithStrategy,
  removeDisabledBrokerages,
  getUserRealApprovedBrokerages,
  renameBrokerages,
  getRealBrokerages,
} from 'common/utils/brokerage';
import { PROGRESS_DIALOG_CODE } from 'common/components/Dialogs/ProgressDialog';
import {
  CONTINUE_TEXT,
  EXCLUSIVE_WALLET_TEXT,
  IS_SUCCESS,
  IS_WARN,
} from 'common/utils/constants';
import { getFormattedNamesByComma } from 'common/utils/string';
import exclusiveWalletIcon from 'common/assets/images/icon-exclusive-wallet.svg';
import { openDialog } from './dialogs';

export const DUCK_NAME = 'brokerages';
const logger = logdown(DUCK_NAME);
export const INITIAL_STATE = {
  lastUpdateDatetime: null,
  loading: false,
  data: {
    /*
     * The brokerage state should have these fields:
     * - id
     * - name
     * - corporate_name
     *
     * If the brokerage is active for the user, the following fields will be
     * also available:
     * - brokerage_id (which is the same as id)
     * - brokerage_office_id
     * - cblc_bovespa_code
     * - cblc_bmf_code
     * - insertion_datetime
     * - status
     * - approval_datetime
     */
  },
  error: null,
};

export const LOAD_BROKERAGES_STARTED = `${DUCK_NAME}/LOAD_BROKERAGES_STARTED`;
export const LOAD_BROKERAGES_SUCCEED = `${DUCK_NAME}/LOAD_BROKERAGES_SUCCEED`;
export const LOAD_BROKERAGES_FAILED = `${DUCK_NAME}/LOAD_BROKERAGES_FAILED`;

export const loadBrokeragesStarted = () => ({ type: LOAD_BROKERAGES_STARTED });
export const loadBrokeragesSucceed = (data) => ({ type: LOAD_BROKERAGES_SUCCEED, data });
export const loadBrokeragesFailed = (error) => ({ type: LOAD_BROKERAGES_FAILED, error });

export const GET_ACTIVATED_BROKERAGES_RETURN_ATTRIBUTES = 'id,name,corporate_name';
export const GET_CLIENT_BROKERAGES_RETURN_ATTRIBUTES = 'brokerage_id,brokerage_office_id,cblc_bovespa_code,cblc_bmf_code,insertion_datetime,status,approval_datetime';
export const loadBrokerages = (options = { reload: false }) => async (dispatch, getState) => {
  const state = getState()[DUCK_NAME];
  const { strategies } = getState();

  if (
    !options.reload
      && state
      && state.data
      && !state.error
      && !state.loading
      && Object.keys(state.data).length > 0
  ) {
    return Promise.resolve({ data: state.data });
  }

  dispatch(loadBrokeragesStarted());
  let activatedBrokeragesResult = null;
  let clientBrokeragesResult = null;
  try {
    [
      { data: activatedBrokeragesResult },
      { data: clientBrokeragesResult },
    ] = await Promise.all([
      api.brokerages.getActivatedBrokerages({
        params: {
          return_attributes: GET_ACTIVATED_BROKERAGES_RETURN_ATTRIBUTES,
        },
      }),
      api.brokerages.getClientBrokerages({
        params: {
          return_attributes: GET_CLIENT_BROKERAGES_RETURN_ATTRIBUTES,
        },
      }),
    ]);
  } catch (error) {
    dispatch(loadBrokeragesFailed(error));
    return Promise.reject(error);
  }

  activatedBrokeragesResult = reduceObjectsArrayToObject(
    renameBrokerages(Object.values(activatedBrokeragesResult)),
    'id',
  );
  clientBrokeragesResult = reduceObjectsArrayToObject(
    renameBrokerages(Object.values(clientBrokeragesResult)),
    'brokerage_id',
  );
  const allBrokerages = _.merge(activatedBrokeragesResult, clientBrokeragesResult);
  const activatedBrokerages = reduceObjectsArrayToObject(
    renameBrokerages(removeDisabledBrokerages({ brokerages: activatedBrokeragesResult })),
    'id',
  );
  const clientBrokerages = reduceObjectsArrayToObject(
    renameBrokerages(removeDisabledBrokerages({ brokerages: clientBrokeragesResult })),
    'brokerage_id',
  );

  const brokeragesWithoutBeingDisabled = _.merge(activatedBrokerages, clientBrokerages);

  const matchedUserBrokeragesWithStrategy = getMatchedUserBrokeragesWithStrategy(
    { strategies, brokerages: allBrokerages, clientBrokerages },
  );

  const realUserBrokerages = getUserRealApprovedBrokerages(Object.values(clientBrokerages));

  const data = {
    allBrokerages,
    brokerages: brokeragesWithoutBeingDisabled,
    activatedBrokerages,
    clientBrokerages,
    realUserBrokerages,
    matchedUserBrokeragesWithStrategy,
  };

  dispatch(loadBrokeragesSucceed(data));
  return Promise.resolve({ data: data.brokerages });
};

export const INSERT_BROKERAGE_STARTED = `${DUCK_NAME}/INSERT_BROKERAGE_STARTED`;
export const INSERT_BROKERAGE_SUCCEED = `${DUCK_NAME}/INSERT_BROKERAGE_SUCCEED`;
export const INSERT_BROKERAGE_FAILED = `${DUCK_NAME}/INSERT_BROKERAGE_FAILED`;

export const insertBrokerageStarted = () => ({
  type: INSERT_BROKERAGE_STARTED,
});

export const insertBrokerageSucceed = () => ({
  type: INSERT_BROKERAGE_SUCCEED,
});

export const insertBrokerageFailed = (error) => ({
  error,
  type: INSERT_BROKERAGE_FAILED,
});

export const insertBrokerage = (data) => async (dispatch) => {
  dispatch(insertBrokerageStarted());
  try {
    const result = await api.brokerages.insertClientBrokerage(data);

    logger.info('insert brokerage', result);
    dispatch(insertBrokerageSucceed());
    dispatch(loadBrokerages({ reload: true }));

    dispatch(openDialog(
      PROGRESS_DIALOG_CODE,
      {
        state: IS_SUCCESS,
        title: 'Conta adicionada!',
        message: `Agora iremos validar com as corretoras se as informações estão corretas.
          Esse processo poderá levar até 48 horas úteis.
          <br><br>
          <p>Você receberá no seu e-mail quando estiver tudo pronto!</p>`,
        showButton: true,
      },
    ));

    return Promise.resolve(data);
  } catch (error) {
    logger.error('Failed to insert profile.', error);
    dispatch(insertBrokerageFailed(error));

    dispatch(notifierError({
      title: 'Erro',
      message:
         error.codeMessage
         || 'Houve um erro ao cadastrar a corretora. Tente novamente.',
    }));

    return Promise.reject(error);
  }
};

export const checkExclusiveBrokerage = ({
  strategyId,
  customDialogProps = {},
} = {}) => (dispatch, getState) => {
  const brokerages = getState()[DUCK_NAME].data;
  const { strategies } = getState();

  const currentStrategy = strategies.data[strategyId];
  const allowedBrokerages = brokerages.matchedUserBrokeragesWithStrategy[strategyId];
  const hasAllowedBrokerages = allowedBrokerages.length > 0;

  if (!hasAllowedBrokerages) {
    const strategyAllowedBrokerages = getRealBrokerages(
      currentStrategy.brokerages,
    );
    const strategyBrokerageNames = strategyAllowedBrokerages.map(
      (brokerageId) => brokerages.allBrokerages[brokerageId].name,
    );

    const formattedBrokerageNames = getFormattedNamesByComma(
      strategyBrokerageNames,
    );

    const pluralLetter = strategyBrokerageNames.length > 1 ? 's' : '';

    dispatch(
      openDialog(PROGRESS_DIALOG_CODE, {
        state: IS_WARN,
        title: EXCLUSIVE_WALLET_TEXT,
        message: `Essa carteira é exclusiva para a${pluralLetter} corretora${pluralLetter} ${formattedBrokerageNames}.
            Dessa forma, <strong>você só poderá operar a carteira através de uma conta da${pluralLetter}
            corretora${pluralLetter} autorizada${pluralLetter}</strong>`,
        customIcon: exclusiveWalletIcon,
        showButton: true,
        buttonMessage: CONTINUE_TEXT,
        closeOnClickBackdrop: false,
        ...customDialogProps,
      }),
    );

    return true;
  }

  return false;
};

export default (state = INITIAL_STATE, action) => {
  // Logout cleaning
  if (action.type === LOGOUT_SUCCEED) {
    return INITIAL_STATE;
  }

  switch (action.type) {
    case LOAD_BROKERAGES_STARTED:
      return {
        ...state,
        loading: true,
      };
    case LOAD_BROKERAGES_SUCCEED:
      return {
        ...state,
        lastUpdateDatetime: moment().toISOString(),
        loading: false,
        data: {
          ...state.data,
          ...action.data,
        },
      };
    case LOAD_BROKERAGES_FAILED:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    case INSERT_BROKERAGE_STARTED:
      return {
        ...state,
        loading: true,
      };
    case INSERT_BROKERAGE_SUCCEED:
      return {
        ...state,
        loading: false,
      };
    case INSERT_BROKERAGE_FAILED:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    default: return state;
  }
};
