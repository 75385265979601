import { withStyles } from '@material-ui/core/styles';
import { withMobileDialog } from '@material-ui/core';
import palette from 'common/palette';

import WaitForOpeningDialog, { WAIT_FOR_OPENING_DIALOG_CODE } from './WaitForOpeningDialog';

const styles = (theme) => ({
  [theme.breakpoints.down('sm')]: {
    buttons: {
      justifyContent: 'center',
    },
  },
  title: {
    color: '#333333',
    marginBottom: 10,
    fontWeight: 'bold',
    fontSize: 27,
  },
  text: {
    color: '#747c88',
    fontSize: 13,
    paddingTop: 10,
  },
  link: {
    textDecoration: 'none',
    color: palette.primary.main,
    fontWeight: 'bold',
    fontSize: 12,
  },
  buttons: {
    padding: '15px 10px 0 0',
  },
  button: {
    backgroundColor: palette.primary.main,
    width: 138,
    color: 'white',
  },
});

export { WAIT_FOR_OPENING_DIALOG_CODE };
export default withStyles(styles)(withMobileDialog()(WaitForOpeningDialog));
