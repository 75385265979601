import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { createSimulatedInstance } from 'common/ducks/instance';
import CreateTemplateDialog, { CREATE_TEMPLATE_DIALOG_CODE } from './CreateTemplateDialog';

const styles = {
  dialog: {
    margin: '0 auto',
    maxWidth: '459px',
  },
  dialogTitle: {
    fontSize: 22,
    fontWeight: 700,
    paddingBottom: 0,
    paddingTop: '24px',
  },
  dialogContent: {
    paddingTop: 10,
  },
  button: {
    width: 100,
  },
};

const mapDispatchToProps = (dispatch) => ({
  createSimulatedInstance: (name, history) => dispatch(createSimulatedInstance(name, history)),
});

export { CREATE_TEMPLATE_DIALOG_CODE };

export default withRouter(connect(
  null,
  mapDispatchToProps,
)(withStyles(styles)(CreateTemplateDialog)));
