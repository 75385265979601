import React from 'react';
import PropTypes from 'prop-types';
import { BENCHMARKS } from 'common/utils/constants/benchmarks';
import { Box } from '@material-ui/core';
import { COMPARE_WITH } from 'common/utils/constants';
import GeneralFilter from '../GeneralFilter';

const BenchmarkFilter = ({
  onChange,
}) => {
  return (
    <Box
      data-testid="benchmark-filter"
      alignItems="center"
      display="flex"
      justifyContent="flex-end"
    >
      <GeneralFilter
        items={Object.values(BENCHMARKS)}
        initialItem={BENCHMARKS.ibov.id}
        label={COMPARE_WITH}
        onChange={onChange}
      />
    </Box>
  );
};

BenchmarkFilter.propTypes = {
  onChange: PropTypes.func.isRequired,
};

export default BenchmarkFilter;
