import { withStyles } from '@material-ui/core';
import GeneralFilter from './GeneralFilter';

const styles = {
  select: {
    minWidth: 75,
  },
  bold: {
    fontWeight: 'bold',
  },
};

export default withStyles(styles)(GeneralFilter);
