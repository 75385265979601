import { parseNumber } from 'common/utils/string';
import { connect } from 'react-redux';
import { formValueSelector } from 'redux-form';
import { withStyles } from '@material-ui/styles';
import palette from 'common/palette';
import FinancialValueForm from './FinancialValueForm';

const selector = formValueSelector('financial_value_form');

const styles = {
  error: {
    color: palette.danger.main,
  },
  field: {
    marginLeft: '-29px',

    '& .MuiInputBase-input': {
      paddingLeft: 27,
    },

    '& .MuiFormLabel-root': {
      lineHeight: '1.5rem',
      '@media (max-width: 455px)': {
        top: '-15px',
      },
    },
  },
  inputBox: {
    alignItems: 'baseline',
    display: 'flex',
    marginBottom: 15,
    '@media (max-width: 455px)': {
      marginTop: 15,
    },
  },
};

const mapStateToProps = (state, {
  onSubmit,
  dataSteps,
  storeInstance,
}) => {
  const { subscriptions } = state.user;
  const contribution = dataSteps.data.contribution || 0;

  const initialContribution = contribution === 0 || contribution === '0.00'
    ? '0.00'
    : parseNumber(Number(contribution) * 100, 2);

  return {
    initialValues: {
      contribution: initialContribution,
      storeInstance,
    },
    storeInstance,
    contribution: selector(state, 'contribution'),
    onSubmit,
    subscriptions: subscriptions.data,
  };
};

export default connect(mapStateToProps)(withStyles(styles)(FinancialValueForm));
