import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Formik } from 'formik';

import { Box } from '@material-ui/core';
import Field from 'common/components/FormFields/Field';
import CheckboxField from 'common/components/FormFields/CheckboxField';
import PublicPagesButton from 'common/components/Buttons/PublicPagesButton';
import PasswordTips from 'common/components/PasswordTips';
import Link from 'common/components/Link';
import { SignUpSchema } from 'common/utils/YupValidations';
import {
  LOGIN_URL,
  ERROR,
  TABLET_WIDTH_BREAKPOINT,
  TERMS_OF_USE,
  PRIVACY_POLICY,
  PRIVATE_URL,
  ACTIVE_CAMPAIGN_LIST,
  SUCCESS,
} from 'common/utils/constants';
import { apiVersionPath } from 'common/api';
import ssErrorInterpreter from 'common/interceptor/error/ss';
import LinkButton from 'common/components/Buttons/LinkButton';
import PublicPagesBody from 'common/components/PublicPagesBody';
import Captcha from 'common/components/Captcha';
import { onCallDataLayer } from 'common/utils/analytics';
import { REGISTRATION_COMPLETED } from 'common/utils/constants/analytics';

const rateCheckApiPath = '/auth/dregistration/register';

class SignUp extends Component {
  constructor(props) {
    super(props);
    this.state = {
      captchaRequired: false,
    };
  }

  componentDidMount = async () => {
    const { rateCheck } = this.props;
    const resp = await rateCheck(`${apiVersionPath}${rateCheckApiPath}`);
    this.setState({ captchaRequired: resp.data.captcha_required });
  };

  getRequiredFields = ({ fields, ignoredFieldsList }) => {
    const finalValues = { ...fields };
    ignoredFieldsList.map((value) => (
      delete finalValues[value]
    ));
    return finalValues;
  };

  hasSomeBlankField = (values) => {
    return Object.values(values).some((value) => !value);
  };

  hasSomeWrongField = (errors) => {
    return Object.values(errors).some((error) => !!error);
  };

  isButtonActive = (errors, fields) => {
    const fieldsToConsider = this.getRequiredFields({
      fields,
      ignoredFieldsList: ['recaptcha_response'],
    });

    return !(this.hasSomeBlankField(fieldsToConsider) || this.hasSomeWrongField(errors));
  };

  handleErrors = (error) => {
    const { notifyError } = this.props;
    if (!error) return false;

    const errorMessage = ssErrorInterpreter(error.code);

    return notifyError({
      title: ERROR,
      message: errorMessage,
    });
  };

  getNameAndLogin = (name, cpf) => {
    const name_or_corporate_name = name.trim()
      .replace(/\s(De|Do|Da|Dos|Das|E)\s/g, (v) => v.toLowerCase());

    const notAccentedName = name_or_corporate_name
      .toLowerCase()
      .normalize('NFD').replace(/[\u0300-\u036f]/g, '');

    const nameArray = notAccentedName.split(' ');
    const thirdLetter = nameArray.length > 2 ? nameArray[2][0] : '';

    const cpfArray = cpf.split('.');
    const trimmedCpf = `${cpfArray[0]}${cpfArray[1][0]}`;

    const s10i_login = `${nameArray[0]}_${nameArray[1][0]}${thirdLetter}_${trimmedCpf}`;

    return { name_or_corporate_name, s10i_login };
  }

  handleSubmit = async (values, actions) => {
    actions.setSubmitting(true);
    const {
      signUp,
      history,
      login,
      createContact,
      listContact,
      notifySuccess,
      location,
    } = this.props;
    const { name_or_corporate_name, s10i_login } = this.getNameAndLogin(
      values.name, values.cpf,
    );

    const authData = {
      recaptcha_response: values.recaptcha_response,
      recaptcha_version: 2,
    };

    const signUpData = {
      name_or_corporate_name,
      s10i_login,
      email: values.email.toLowerCase(),
      document: values.cpf.replace(/\D/g, ''),
      s10i_password: values.password,
      natural_person_or_legal_person: '0',
      ...authData,
    };

    try {
      await signUp(signUpData);
      onCallDataLayer(REGISTRATION_COMPLETED);
      notifySuccess({
        title: SUCCESS,
        message: 'Sua conta foi criada',
      });
      await login({
        ...authData,
        password: values.password,
        login: values.email.toLowerCase(),
      });
      const query = new URLSearchParams(location.search);
      const redirectQuery = query.get('redirect');

      history.replace(redirectQuery || PRIVATE_URL);
      actions.setSubmitting(false);
    } catch (error) {
      actions.setSubmitting(false);
      this.handleErrors(error);
      if (error) {
        this.setState({ captchaRequired: (/(captcha)+\d/i).test(error.code) });
      }
    }

    const { contact: { id } } = await createContact(values.email);
    await listContact(id, ACTIVE_CAMPAIGN_LIST);
  };

  render() {
    const { captchaRequired } = this.state;
    const { classes, location } = this.props;
    const isMobile = window.innerWidth <= TABLET_WIDTH_BREAKPOINT;

    const query = new URLSearchParams(location.search);
    const redirectQuery = query.get('redirect');

    return (
      <>
        <PublicPagesBody
          title="Crie sua conta"
          subtitle="Abrir a sua conta na SmarttInvest é 100% gratuito e não demora nem 1 minuto."
        >
          <Formik
            validationSchema={SignUpSchema}
            validateOnChange
            initialValues={{
              name: '',
              cpf: '',
              email: '',
              password: '',
              termsOfUseCheckbox: false,
              privacyPolicyCheckbox: false,
              recaptcha_response: '',
            }}
            onSubmit={this.handleSubmit}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              setFieldValue,
              isSubmitting,
            }) => (
              <form onSubmit={handleSubmit} className={classes.form}>
                <Field
                  name="name"
                  type="text"
                  handleChange={handleChange}
                  handleBlur={handleBlur}
                  value={values.name}
                  error={errors.name}
                  touched={touched.name}
                  label="Nome Completo"
                />
                <Field
                  name="cpf"
                  type="text"
                  handleChange={handleChange}
                  handleBlur={handleBlur}
                  value={values.cpf}
                  error={errors.cpf}
                  touched={touched.cpf}
                  mask="111.111.111-11"
                  label="CPF"
                />
                <Field
                  name="email"
                  type="email"
                  handleChange={handleChange}
                  handleBlur={handleBlur}
                  value={values.email}
                  error={errors.email}
                  touched={touched.email}
                  label="E-mail"
                />
                <Field
                  name="password"
                  type="password"
                  handleChange={handleChange}
                  handleBlur={handleBlur}
                  value={values.password}
                  error={errors.password}
                  touched={touched.password}
                  label="Senha"
                />
                <PasswordTips mobile={isMobile} values={values} />

                <CheckboxField
                  name="termsOfUseCheckbox"
                  value={values.termsOfUseCheckbox}
                  handleChange={handleChange}
                  label={(
                    <div>
                      <span>Li e aceito os </span>
                      <Link
                        color="primary"
                        path={TERMS_OF_USE}
                        underlineDecoration
                        target="_blank"
                      >
                        Termos de Uso
                      </Link>
                    </div>
                  )}
                />
                <CheckboxField
                  name="privacyPolicyCheckbox"
                  value={values.privacyPolicyCheckbox}
                  handleChange={handleChange}
                  label={(
                    <div>
                      <span>Li e aceito a </span>
                      <Link
                        color="primary"
                        path={PRIVACY_POLICY}
                        underlineDecoration
                        target="_blank"
                      >
                        Política de Privacidade
                      </Link>
                    </div>
                  )}
                />

                {captchaRequired && <Captcha setFieldValue={setFieldValue} />}
                <PublicPagesButton
                  active={this.isButtonActive(errors, values)}
                  loading={isSubmitting}
                >
                  Criar conta
                </PublicPagesButton>
              </form>
            )}
          </Formik>
          <Box mt={1}>
            <LinkButton
              path={redirectQuery
                ? `${LOGIN_URL}?redirect=${encodeURIComponent(`${redirectQuery}`)}`
                : LOGIN_URL}
            >
              Já tem conta? Acesse aqui
            </LinkButton>
          </Box>
        </PublicPagesBody>
      </>
    );
  }
}

SignUp.propTypes = {
  history: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  signUp: PropTypes.func.isRequired,
  rateCheck: PropTypes.func.isRequired,
  notifyError: PropTypes.func.isRequired,
  login: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
  createContact: PropTypes.func.isRequired,
  listContact: PropTypes.func.isRequired,
  notifySuccess: PropTypes.func.isRequired,
};

export default SignUp;
