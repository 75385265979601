import { useSelector, useDispatch } from 'react-redux';

import warnIcon from 'common/assets/images/progress_dialog/warn_icon.svg';
import { PROGRESS_DIALOG_CODE } from 'common/components/Dialogs/ProgressDialog';
import { openDialog } from 'common/ducks/dialogs';
import { IS_WARN } from 'common/utils/constants';
import { OPEN_ORDERS_TEXT, OPEN_ORDERS_WAIT_MSG } from 'common/utils/constants/orders';
import { instanceHasOpenOrders } from 'common/utils/orders';
import { getIsFormValid, checkPositionsAreValid } from 'common/utils/positions';

export const useSubmitExternalOrders = () => {
  const validStocks = useSelector((state) => state.stocks.validStocks);

  const handleSubmit = async (form, disableSubmit = false, ...args) => {
    const computedProps = form.current.getFormikComputedProps();
    if (!disableSubmit) {
      form.current.handleSubmit(...args);
    }

    const {
      values, errors, status, dirty,
    } = form.current.getFormikBag();

    const isValid = getIsFormValid({
      errors, dirty, values, computedProps, status,
    });
    const { setStatus } = form.current;

    let isPromiseResultValid = true;

    if (!disableSubmit) {
      isPromiseResultValid = await checkPositionsAreValid({
        values,
        status,
        setStatus,
        validStocks,
      });
    }

    return {
      isValid: isValid && isPromiseResultValid,
      values,
      status: status || {},
    };
  };

  return { handleSubmit };
};

export const useHasOpenOrders = ({ instanceId }) => {
  const instanceOrders = useSelector((state) => state.orders[instanceId].data.orders);
  return instanceHasOpenOrders(instanceOrders);
};

export const useHandleOpenOrdersDialog = () => {
  const dispatch = useDispatch();
  const handleOpenOrdersDialog = () => {
    const props = {
      state: IS_WARN,
      title: OPEN_ORDERS_TEXT,
      message: OPEN_ORDERS_WAIT_MSG,
      showButton: true,
      customIcon: warnIcon,
    };
    return dispatch(openDialog(PROGRESS_DIALOG_CODE, { ...props }));
  };
  return handleOpenOrdersDialog;
};

export default null;
