import React from 'react';
import PropTypes from 'prop-types';
import logdown from 'logdown';
import { assign } from 'lodash';

// Material UI
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TableBody from '@material-ui/core/TableBody';
import Button from '@material-ui/core/Button';
import { Box, Typography } from '@material-ui/core';

// Commons
import Link from 'common/components/Link';
import Placeholder from 'common/components/Placeholder';
import { SI_ZENDESK_ARTICLES_URL } from 'common/utils/constants';
import { orderEventStateMap, getTextOrderLegend, isOrderOpen } from 'common/utils/orders';

const logger = logdown('InstanceOrderEvents');

class InstanceOrderEvents extends React.Component {
  constructor() {
    super();
    this.state = {
      loading: true,
      data: null,
      error: null,
    };
  }

  componentDidMount = () => {
    this.loadOrderEvents();
  };

  loadOrderEvents = async () => {
    const {
      instanceId,
      orderId,
      loadInstanceOrderEvents,
    } = this.props;
    const { loading } = this.state;
    if (!loading) {
      this.setState({ loading: true });
    }

    try {
      const { data } = await loadInstanceOrderEvents(instanceId, orderId);
      this.setState({ data, loading: false });
    } catch (error) {
      logger.error(`Could not load instance #${instanceId} order #${orderId} events. Error:`, error);
      this.setState({ error, loading: false });
    }
  };

  renderLoader = () => [1, 2, 3, 4].map((id) => (
    <TableRow key={id}>
      <TableCell><Placeholder width="100%" height={14} /></TableCell>
      <TableCell><Placeholder width="100%" height={14} /></TableCell>
      <TableCell><Placeholder width="100%" height={14} /></TableCell>
      <TableCell><Placeholder width="100%" height={14} /></TableCell>
    </TableRow>
  ));

  renderEvents = (orderEvents) => {
    const { classes } = this.props;
    return orderEvents.map(({
      datetime,
      event_type: eventType,
      description,
      reason,
    }) => (
      <TableRow key={`${eventType}/${description}`}>
        <TableCell className={classes.tableCell}>{datetime}</TableCell>
        <TableCell className={classes.tableCell}>{orderEventStateMap[eventType] || '-'}</TableCell>
        <TableCell className={classes.tableCell}>{description}</TableCell>
        <TableCell className={classes.tableCell}>
          {reason === 'Portfolio Reset' ? 'Posição zerada' : reason}
        </TableCell>
      </TableRow>
    ));
  };

  renderTable = () => {
    const { classes } = this.props;
    const { loading, data } = this.state;
    return (
      <Table className={classes.table}>
        <TableHead>
          <TableRow>
            <TableCell className={classes.tableCell}>Data/Hora</TableCell>
            <TableCell className={classes.tableCell}>Evento</TableCell>
            <TableCell className={classes.tableCell}>Descrição</TableCell>
            <TableCell className={classes.tableCell}>Motivo</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {loading ? this.renderLoader() : this.renderEvents(data.orders_events)}
        </TableBody>
      </Table>
    );
  };

  renderError = () => {
    const { classes, instanceId } = this.props;
    return (
      <DialogContentText className={classes.errorMessage}>
        Houver um erro ao carregar as ordens do robô #
        {instanceId}
        <Button
          color="secondary"
          onClick={this.load}
        >
          Tentar novamente
        </Button>
      </DialogContentText>
    );
  };

  openUpdateExternalOrderDialog = (initialValues) => {
    const { openExternalOrderDialog, orderId } = this.props;
    const options = {
      title: 'Corrigir Ordem Externa',
      content: null,
      showInstanceCard: false,
      initialValues,
      variant: 'update',
      orderId,
    };
    openExternalOrderDialog(options);
  }

  render = () => {
    const {
      order,
      orderId,
      open,
      classes,
      onClose,
      external,
      isManualOrder,
      initialValues,
      cancelOrder,
    } = this.props;

    const { error } = this.state;

    let dialogContent = null;

    if (error) {
      dialogContent = this.renderError();
    } else {
      dialogContent = this.renderTable();
    }

    const initialValuesWithReason = assign(
      initialValues,
      {
        reason: this.state.data?.orders_events[this.state.data.total - 1].reason ?? '',
      },
    );

    const hasRejectedOrder = !!this.state.data?.orders_events.find((event) => event.event_type === 'order_rejected');

    return (
      <Dialog
        open={open}
        maxWidth={false}
        classes={{
          paper: classes.dialogPaper,
        }}
      >
        <DialogContent className={classes.content}>
          <DialogTitle>
            Eventos da ordem #
            {orderId}
&nbsp;
            {getTextOrderLegend({ isManualOrder, external })}
          </DialogTitle>
          {dialogContent}
        </DialogContent>
        {hasRejectedOrder && (
        <Box mt={2} pl={5}>
          <Typography variant="h6">
            <b>IMPORTANTE:</b>
            {' '}
            <Link
              color="primary"
              fontWeight={700}
              path={`${SI_ZENDESK_ARTICLES_URL}/4419884194327`}
              target="_blank"
              underlineDecoration
            >
              Entenda mais sobre como solucionar o erro da sua carteira
            </Link>
          </Typography>
        </Box>
        )}
        <DialogActions>
          {external && !isManualOrder && (
            <Button
              color="primary"
              onClick={() => this.openUpdateExternalOrderDialog(initialValuesWithReason)}
            >
              Corrigir Ordem
            </Button>
          )}

          {isOrderOpen(order) && (
          <Button
            color="secondary"
            onClick={cancelOrder}
            variant="outlined"
          >
            Cancelar ordem
          </Button>
          )}
          <Button
            onClick={onClose}
            color="primary"
          >
            Fechar
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

InstanceOrderEvents.propTypes = {
  instanceId: PropTypes.number.isRequired,
  order: PropTypes.object.isRequired,
  orderId: PropTypes.number.isRequired,
  classes: PropTypes.object.isRequired,
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  external: PropTypes.bool.isRequired,
  isManualOrder: PropTypes.bool.isRequired,
  initialValues: PropTypes.object.isRequired,
  loadInstanceOrderEvents: PropTypes.func.isRequired,
  openExternalOrderDialog: PropTypes.func.isRequired,
  cancelOrder: PropTypes.func.isRequired,
};

export default InstanceOrderEvents;
