import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Chip, createMuiTheme, ThemeProvider } from '@material-ui/core';

import DefaultTheme from 'common/theme';
import { CHIP_COLORS } from 'common/utils/constants/tags';

const WrappedChip = ({ color, ...props }) => {
  const customizedColor = CHIP_COLORS[color];

  const theme = useMemo(() => {
    if (customizedColor) {
      return createMuiTheme({
        ...DefaultTheme,
        palette: {
          ...DefaultTheme.palette,
          primary: customizedColor,
        },
      });
    }
    return DefaultTheme;
  }, [customizedColor]);

  return (
    <ThemeProvider theme={theme}>
      <Chip {...props} color="primary" />
    </ThemeProvider>
  );
};

WrappedChip.propTypes = {
  color: PropTypes.oneOf(Object.keys(CHIP_COLORS), 'primary', 'secondary'),
};

WrappedChip.defaultProps = {
  color: 'primary',
};

export default WrappedChip;
