import { withStyles } from '@material-ui/core/styles';

import { setBackgroundOpacity } from 'common/utils/styles/background';
import InternalPageHeader from './InternalPageHeader';

const styles = {
  header: {
    borderRadius: 8,
    color: '#fff',
    height: 187,
    padding: '26px 30px',
    position: 'relative',
    width: '100%',
    zIndex: 1,
    '&::after': {
      borderRadius: 8,
      ...setBackgroundOpacity({ backgroundColor: 'rgba(0, 0, 0, 0.56)', zIndex: '-1' }),
    },
  },
  headerContent: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    zIndex: 1,
  },
  title: {
    fontSize: 24,
  },
};

export default withStyles(styles)(InternalPageHeader);
