import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import ShowcaseInstancePage from 'App/Pages/PrivatePages/NavigationPages/ShowcasePage/ShowcaseInstancePage';
import Container from 'common/components/Container';
import LoadingGif from 'common/components/LoadingGif';
import PublicHeader from 'common/components/PublicHeader';

const PublicStoreItems = ({ loadStoreItems, loadCategories }) => {
  const [isLoading, setIsloading] = useState(true);

  useEffect(() => {
    const mount = async () => {
      loadCategories();
      await loadStoreItems();
      setIsloading(false);
    };

    mount();
  }, [loadCategories, loadStoreItems]);

  return (
    <>
      {isLoading && <LoadingGif />}
      {!isLoading && (
        <>
          <PublicHeader />

          <Container>
            <ShowcaseInstancePage isPublic />
          </Container>
        </>
      )}
    </>
  );
};

PublicStoreItems.propTypes = {
  loadStoreItems: PropTypes.func.isRequired,
  loadCategories: PropTypes.func.isRequired,
};

export default PublicStoreItems;
