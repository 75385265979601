import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import configureStore from 'common/ducks/store';
import intlSetup from 'common/i18n';
import interceptor from 'common/interceptor';
import 'common/assets/sass/main.scss';

import 'smarttbot-pagination';

import App from './App';

const store = configureStore();

const main = (target, language) => {
  // Intl setup
  intlSetup(language);

  // Axios config
  interceptor.createInterceptor(store);

  // App render
  ReactDOM.render(
    (
      <Provider store={store}>
        <App />
      </Provider>
    ), target,
  );
};

main(document.getElementById('root'), 'pt-br');
