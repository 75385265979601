import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

const Container = ({
  classes,
  children,
  className,
  fluid,
}) => (
  <div
    className={classNames(
      className,
      fluid ? classes.containerFluid : classes.container,
    )}
  >
    {children}
  </div>
);

Container.propTypes = {
  classes: PropTypes.object.isRequired,
  className: PropTypes.string,
  fluid: PropTypes.bool,
  children: PropTypes.node,
};

Container.defaultProps = {
  className: '',
  children: null,
  fluid: false,
};

export default Container;
