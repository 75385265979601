import { withStyles } from '@material-ui/styles';
import ErrorMsg from './ErrorMsg';

const styles = {
  errorMsg: {
    fontSize: 12,
    color: '#e93056',
    margin: '5px 0 5px 0',
  },
};

export default withStyles(styles)(ErrorMsg);
