import api from 'common/api';

export const INITIAL_STATE = { loading: false, data: null, error: null };

export const DUCK_NAME = 'access';
export const NAMESPACE = `user/${DUCK_NAME}`;

export const GET_ACCEESSES_START = `${NAMESPACE}/GET_ACCESSES_START`;
export const GET_ACCESSES_SUCCEED = `${NAMESPACE}/GET_ACCESSES_SUCCEED`;
export const GET_ACCEESES_FAILED = `${NAMESPACE}/GET_ACCESSES_FAILED`;

export const getAccessesStart = () => ({
  type: GET_ACCEESSES_START,
});
export const getAccessesSucceed = (data) => ({
  type: GET_ACCESSES_SUCCEED,
  data,
});
export const getAccessesFailed = (error) => ({
  type: GET_ACCEESES_FAILED,
  error,
});

export const getAccesses = (limit) => async (dispatch) => {
  dispatch(getAccessesStart());
  try {
    const { data } = await api.user.getUserAccess(limit);
    dispatch(getAccessesSucceed(data));
    return Promise.resolve(data);
  } catch (error) {
    dispatch(getAccessesFailed(error));
    return Promise.reject(error);
  }
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GET_ACCEESSES_START:
      return {
        ...state,
        loading: true,
      };
    case GET_ACCESSES_SUCCEED:
      return {
        ...state,
        loading: false,
        data: action.data,
      };
    case GET_ACCEESES_FAILED:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    default:
      return INITIAL_STATE;
  }
};
