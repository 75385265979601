import React from 'react';
import PropTypes from 'prop-types';

import { getIndicatorLabel } from 'common/utils/scoreBoard';

const createIndicators = (totalIndicatorNumber, filledIndicators, customClass, colors) => {
  const indicators = [];

  for (let i = 0; i < totalIndicatorNumber; i += 1) {
    const color = i < filledIndicators ? colors.filledColor : colors.emptyColor;
    indicators.push(
      <div
        key={i}
        className={customClass}
        style={{ backgroundColor: color }}
      >
      </div>,
    );
  }

  return indicators;
};

const FilledCircleIndicator = ({
  filledInRate,
  totalIndicatorNumber,
  filledColor,
  emptyColor,
  showLabel,
  classes,
}) => {
  const filledIndicators = Math.ceil(filledInRate * totalIndicatorNumber);
  const indicators = createIndicators(
    totalIndicatorNumber,
    filledIndicators,
    classes.circle,
    { filledColor, emptyColor },
  );

  return (
    <div>
      {showLabel && (
        <div className={classes.labelText}>
          {getIndicatorLabel(filledIndicators, totalIndicatorNumber)}
        </div>
      )}
      <div className={classes.circleRow} id="circleRow">{indicators}</div>
    </div>
  );
};

FilledCircleIndicator.propTypes = {
  filledInRate: PropTypes.number.isRequired,
  totalIndicatorNumber: PropTypes.number,
  filledColor: PropTypes.string,
  emptyColor: PropTypes.string,
  showLabel: PropTypes.bool,
  classes: PropTypes.object.isRequired,
};

FilledCircleIndicator.defaultProps = {
  totalIndicatorNumber: 6,
  filledColor: '#025ab9',
  emptyColor: '#bfbfbf',
  showLabel: true,
};

export default FilledCircleIndicator;
