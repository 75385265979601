import React from 'react';
import PropTypes from 'prop-types';
import { Box, Drawer, IconButton } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { RIGHT } from 'common/utils/constants';

const MainSideDialog = ({
  classes,
  children,
  onClose,
  open,
}) => {
  return (
    <Drawer
      classes={{ paper: classes.drawer }}
      anchor={RIGHT}
      open={open}
    >
      <Box display="flex" justifyContent="flex-end" position="relative">
        <IconButton
          data-testid="close-side-dialog"
          size="small"
          color="inherit"
          onClick={onClose}
        >
          <CloseIcon fontSize="large" />
        </IconButton>
      </Box>

      {children}
    </Drawer>
  );
};

MainSideDialog.propTypes = {
  classes: PropTypes.object.isRequired,
  children: PropTypes.node.isRequired,
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default MainSideDialog;
