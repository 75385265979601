import { createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import logger from 'redux-logger';
import rootReducer from './reducers';

export default () => {
  const store = createStore(
    rootReducer,
    compose(
      applyMiddleware(thunk),
      process.env.ENABLE_REDUX_LOGGER === 'true'
        ? applyMiddleware(logger)
        : (f) => f,
      window.devToolsExtension ? window.devToolsExtension() : (f) => f,
    ),
  );

  return store;
};
