import { withStyles } from '@material-ui/core/styles';
import palette from 'common/palette';
import StockCard from './StockCard';

const defaultBreakpointCard = '600px';

const styles = {
  container: {
    height: 70,
    boxShadow: '0 3px 6px 0 rgba(0, 0, 0, 0.16)',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    position: 'relative',
    borderTopRightRadius: 6,
    borderBottomRightRadius: 6,
    paddingLeft: 5,
    paddingRight: 15,
  },
  containerViewMode: {
    paddingLeft: 15,
  },
  containerDefault: {
    '&::after': {
      top: 0,
      right: 0,
      width: '6px',
      height: '100%',
      content: '""',
      display: 'inline-block',
      position: 'absolute',
      background: 'linear-gradient(to bottom, #333333, #767676)',
    },
  },
  containerSelectedStock: {
    border: '1px solid #EFEEEE',
    borderRight: '6px solid',
  },
  companyInfo: {
    display: 'flex',
    flex: 1,
    paddingRight: 20,
  },
  wrapperLogoCheck: {
    display: 'flex',
    marginRight: 5,
    [`@media screen and (max-width: ${defaultBreakpointCard})`]: {
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      width: 40,
    },
  },
  checkbox: {
    [`@media screen and (max-width: ${defaultBreakpointCard})`]: {
      padding: 5,
    },
  },
  companyLogo: {
    [`@media screen and (max-width: ${defaultBreakpointCard})`]: {
      height: 25,
      width: 25,
    },
  },
  wrapperNameSlider: {
    flex: 1,
    display: 'flex',
    position: 'relative',
    [`@media screen and (max-width: ${defaultBreakpointCard})`]: {
      flexDirection: 'column',
    },

    [`@media screen and (min-width: calc(${defaultBreakpointCard} + 1px))`]: {
      alignItems: 'center',
    },
  },

  wrapperNameSliderUpdate: {
    flexDirection: 'column',
    alignItems: 'flex-start',
  },
  companyNames: {
    display: 'flex',
    flexDirection: 'column',
    minWidth: 65,
    position: 'relative',
    [`@media screen and (max-width: ${defaultBreakpointCard})`]: {
    },
  },
  companyNamesUpdate: {
    flexDirection: 'row',
    alignItems: 'center',
    position: 'absolute',
    top: 0,
    [`@media screen and (max-width: ${defaultBreakpointCard})`]: {
      maxWidth: 240,
    },
  },
  namesSearchMobile: {
    [`@media screen and (max-width: ${defaultBreakpointCard})`]: {
      justifyContent: 'center',
    },
  },
  name: {
    color: palette.textSecondary.main,
    fontSize: 13,
    display: 'block',
    maxWidth: 220,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    [`@media screen and (max-width: ${defaultBreakpointCard})`]: {
      fontSize: 11,
    },
  },
  nameUpdate: {
    marginLeft: 5,
    order: 1,
  },
  ticker: {
    color: palette.textPrimary.main,
    fontSize: 16,
  },
  wrapperSliderLogo: {
    alignItems: 'center',
    display: 'flex',
    marginTop: 20,
    width: '100%',

    '& > img': {
      marginRight: 10,
    },
  },
  slider: {
    flex: 1,
    width: '88%',
    padding: '11px 0',
  },
  sliderThumb: {
    width: 18,
    height: 18,
    marginTop: -8,
  },
  sliderThumbActive: {
    boxShadow: 'none !important',
  },
  materialInput: {
    width: 50,
    '&:last-child': {
      marginLeft: 10,
    },
  },
  inputUpdate: {
    [`@media screen and (max-width: ${defaultBreakpointCard})`]: {
      marginTop: 10,
    },
  },
  input: {
    '&::-webkit-outer-spin-button, &::-webkit-inner-spin-button': {
      '-webkit-appearance': 'none',
      margin: 0,
    },
    '&[type=number]': {
      '-moz-appearance': 'textfield',
    },
    textAlign: 'end',
    padding: '6px 0 4px',
  },
  inputAdornment: {
    marginLeft: 5,
    marginTop: 3.5,
  },
  wrapperInput: {
    '&.Mui-disabled': {
      color: '#5e5e5e',
      fontWeight: 'bold',
      '&::after': {
        content: '',
        display: 'none',
      },
      '&::before': {
        content: '',
        display: 'none',
      },
    },
  },
  error: {
    color: palette.secondary.main,
    '&::after, &::before': {
      borderBottom: `2px solid ${palette.secondary.main}`,
    },
  },
  buttonRoot: {
    minWidth: 0,
    padding: 0,
  },
  rightAction: {
    minWidth: 35,
    maxHeight: 35,
    position: 'absolute',
    right: 28,
    borderRadius: 6,
  },
  horizontal: {
    height: 0,
    width: 19,
    borderBottom: '1px solid',
    position: 'absolute',
  },
  vertical: {
    height: 19,
    width: 0,
    margin: '8px 0 8px 0',
    borderRight: '1px solid',
  },
};

export default withStyles(styles)(StockCard);
