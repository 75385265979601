import api from 'common/api';

export const INITIAL_STATE = { loading: false, data: null, error: null };

export const DUCK_NAME = 'stats';
export const NAMESPACE = `user/${DUCK_NAME}`;

export const GET_USER_STATS_STARTED = `${NAMESPACE}/GET_USER_STATS_STARTED`;
export const GET_USER_STATS_SUCCEED = `${NAMESPACE}/GET_USER_STATS_SUCCEED`;
export const GET_USER_STATS_FAILED = `${NAMESPACE}/GET_USER_STATS_FAILED`;

export const getUserStatsStarted = () => ({
  type: GET_USER_STATS_STARTED,
});

export const getUserStatsSucceed = (data) => ({
  type: GET_USER_STATS_SUCCEED,
  data,
});

export const getUserStatsFailed = (error) => ({
  type: GET_USER_STATS_FAILED,
  error,
});

export const getUserStats = () => async (dispatch) => {
  dispatch(getUserStatsStarted());

  try {
    const { data } = await api.user.getUserStats();

    dispatch(getUserStatsSucceed(data));
    return Promise.resolve(data);
  } catch (error) {
    dispatch(getUserStatsFailed(error));
    return Promise.reject(error);
  }
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GET_USER_STATS_STARTED:
      return {
        ...state,
        loading: true,
      };
    case GET_USER_STATS_SUCCEED:
      return {
        ...state,
        loading: false,
        data: action.data,
      };
    case GET_USER_STATS_FAILED:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    default:
      return INITIAL_STATE;
  }
};
