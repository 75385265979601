import { withStyles } from '@material-ui/core/styles';
import palette from 'common/palette';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Token2FADialog from './Token2FADialog';

export { TOKEN_2FA_DIALOG_CODE } from './Token2FADialog';

const styles = {
  icon: {
    color: '#939798',
    margin: '0 0.62rem',
  },
  toolbar: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '0',
  },
  contentContainer: {
    marginTop: '1rem',
    marginBottom: '2rem',
  },
  dialogTitle: {
    padding: 0,
    marginBottom: '2rem',
  },
  title: {
    fontWeight: 600,
  },
  closeButton: {
    alignSelf: 'flex-start',
  },
  dialogContent: {
    padding: 0,
    marginBottom: '2rem',
  },
  cancelButton: {
    color: '#1BA39C',

    '&:hover': {
      color: '#1BA39C',
    },
  },
  confirmButton: {
    color: palette.neutral.main,
    backgroundColor: '#1BA39C',

    '&:hover': {
      color: palette.neutral.main,
      backgroundColor: '#1BA39C',
    },

    '&:disabled': {
      backgroundColor: '#C8CACD',
      color: '#959AA2',
      pointerEvents: 'all',

      '&:hover': {
        cursor: 'not-allowed',
        backgroundColor: '#C8CACD',
        color: '#959AA2',
      },
    },
  },
  formLabel: {
    '& span:last-of-type': {
      color: '#959AA2',
    },
  },
  dialogActions: {
    padding: 0,
  },
  resendTokenBtn: {
    color: '#959AA2',
    padding: '0.4rem',
    fontWeight: 400,
  },
  invalidTokenParagraph: {
    color: palette.danger.main,
    fontSize: '0.8rem',
  },
};

const mapStateToProps = ({ user: { profile } }) => ({
  email: profile.data.email,
});

const styledDialog = withStyles(styles)(Token2FADialog);

export default withRouter(connect(mapStateToProps, null)(styledDialog));
