import moment from 'moment';
import logdown from 'logdown';

import { loadUserSubscriptions } from 'common/ducks/user/subscriptions';
import { LOGOUT_SUCCEED } from 'common/ducks/auth';
import {
  getMappedStoreItems,
  getStoreItemsRollout,
  getStoreItemsWithStocks,
} from 'common/utils/storeItem';
import api from 'common/api';
import { reduceObjectsArrayToObject } from 'common/utils/array';
import storeItemReducer, { DUCK_NAME as STORE_ITEM_DUCK_NAME } from './storeItem';

export const DUCK_NAME = 'storeItems';
const logger = logdown(DUCK_NAME);

export const INITIAL_STATE = {
  lastUpdateDatetime: null,
  loading: false,
  data: {
    items: [],
    itemsWithPrivate: [],
  },
  error: null,
};

export const LOAD_STORE_ITEMS_STARTED = `${DUCK_NAME}/LOAD_STORE_ITEMS_STARTED`;
export const LOAD_STORE_ITEMS_SUCCEED = `${DUCK_NAME}/LOAD_STORE_ITEMS_SUCCEED`;
export const LOAD_STORE_ITEMS_FAILED = `${DUCK_NAME}/LOAD_STORE_ITEMS_FAILED`;

export const loadStoreItemsStarted = () => ({ type: LOAD_STORE_ITEMS_STARTED });
export const loadStoreItemsSucceed = (data) => ({ type: LOAD_STORE_ITEMS_SUCCEED, data });
export const loadStoreItemsFailed = (error) => ({ type: LOAD_STORE_ITEMS_FAILED, error });

export const loadStoreItems = (isPublic = false) => async (dispatch) => {
  dispatch(loadStoreItemsStarted());

  try {
    const { data: allStoreItems } = await api.store.getStoreItems(isPublic);

    const storeItems = getStoreItemsRollout({ storeItems: allStoreItems });
    const storeItemsWithStocks = getStoreItemsWithStocks(storeItems);

    if (!isPublic) {
      const subscriptions = await dispatch(loadUserSubscriptions());

      const mappedTabs = getMappedStoreItems({
        storeItems: storeItemsWithStocks,
        subscriptions,
      });

      dispatch(loadStoreItemsSucceed(mappedTabs));
      return Promise.resolve(mappedTabs);
    }

    const result = {
      itemsWithPrivate: reduceObjectsArrayToObject(storeItemsWithStocks, 'strategy.id'),
    };

    dispatch(loadStoreItemsSucceed(result));
    return Promise.resolve(result);
  } catch (error) {
    logger.error('Could not get store.', error);
    dispatch(loadStoreItemsFailed(error));
    return Promise.reject(error);
  }
};

const reducer = (state = INITIAL_STATE, action) => {
  const { type, error } = action;

  if (type === LOGOUT_SUCCEED) {
    return INITIAL_STATE;
  }

  switch (action.type) {
    case LOAD_STORE_ITEMS_STARTED:
      return {
        ...state,
        loading: true,
      };
    case LOAD_STORE_ITEMS_SUCCEED:
      return {
        ...state,
        loading: false,
        error: null,
        lastUpdateDatetime: moment().toISOString(),
        data: {
          ...state.data,
          ...action.data,
        },
      };
    case LOAD_STORE_ITEMS_FAILED:
      return {
        ...state,
        error,
        loading: false,
      };

    case new RegExp(`${STORE_ITEM_DUCK_NAME}/`).test(action.type):
      return {
        ...state,
        data: {
          ...state.data,
          [action.id]: storeItemReducer(state.data[action.id], action),
        },
      };
    default: return state;
  }
};

export default reducer;
